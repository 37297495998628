import { Box, Button, Flex, Grid, List, Text, Heading } from '@chakra-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useDispatch } from 'react-redux'
import { MARCA_BLANCA_CRUD_ADMIN } from '../../../helpers/permissions'
import { resetSupplierData } from '../../../redux/actions/suppliers-actions'
import NavigatorDrawer from '../../../components/common/drawer/navigator-drawer'
import { PageContainer } from '../../../components/common/helpers'
import { ListItemRoute } from '../../../components/common/router-components'
import CurrencyAdmin from './currency-admin'
import Exchange from './exchange'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { usePermissionChecker } from '../../hooks'
import '../../styles/currency/currency.css'
export default function Currency() {
  const { t } = useTranslation(['common', 'web-information', 'exchange'])
  const { path, url } = useRouteMatch()
  const dispatch = useDispatch()
  const location = useLocation()
  const { canDoThis } = usePermissionChecker()
  const canDefault = useMemo(
    () =>
      canDoThis(
        MARCA_BLANCA_CRUD_ADMIN,
        () => ({
          ishidden: true,
        }),
        () => ({
          ishidden: false,
        })
      ),
    [canDoThis]
  )

  const childrenRoutes = [
    {
      path: `${path}/exchange`,
      component: Exchange,
    },
    canDefault.ishidden && {
      path: `${path}/country-admin`,
      component: CurrencyAdmin,
    },
  ]

  const header = (onOpen, btnRef) => (
    <Grid templateColumns={{ base: '6fr 1fr', md: '7fr 0fr' }} gap={[3, 3, 0]}>
      <Box backgroundColor="" py="16px" color="#103059">
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {location.pathname === '/currency/exchange' && t('exchange:changeType')}
          {location.pathname === '/currency/country-admin' && t('common:navbar.currency')}
        </Heading>
      </Box>
      <Flex justify="flex-end">
        <Button
          display={{ base: 'inherit', md: 'none' }}
          mb="10px"
          ref={btnRef}
          variant="outline"
          variantColor="blue"
          onClick={onOpen}
        >
          <Box as={GiHamburgerMenu} display="inline" />
        </Button>
      </Flex>
    </Grid>
  )

  useEffect(() => {
    dispatch(resetSupplierData())
  }, [dispatch])

  return (
    <PageContainer>
      <NavigatorDrawer header={header} routes={childrenRoutes} spacing="16px">
        <List spacing={3}>
          <ListItemRoute to={`${url}/exchange`}>{t('exchange:changeType')}</ListItemRoute>
          {canDefault.ishidden && <ListItemRoute to={`${url}/country-admin`}>{t('common:navbar.currency')}</ListItemRoute>}
        </List>
      </NavigatorDrawer>
    </PageContainer>
  )
}
