import { SAVE_USER_DATA, LOG_OUT } from './actions-type'
import { loginRequest, getAppInformation, obtenerPermisosApp, getAllCountries } from '../../api/application'
import { getActiveTemplate } from '../../api/intranet/marca-blanca'
import { LOCAL_STORAGE_KEYS } from '../../constants/localStorageKeys'

const loginSuccess = (data) => {
  return ({
  type: SAVE_USER_DATA,
  payload: { ...data },
})}

export const logOut = () => ({
  type: LOG_OUT,
})

export const login = ({ user, pass }) => {
  return async (dispatch) => {
    try {
      const loginResponse = await loginRequest({ user, pass })

      if (loginResponse.data) {
        const { token, refreshToken } = loginResponse.data
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, token)
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)

        if (token && refreshToken) {
          const decodedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
          const { usuario_apellido, usuario_id, usuario_nombre, usuario_rol_id, usuario_rol_name, tipo_plicacion_id, pais_id, aplicacion_id, activa, nombre } = decodedToken.claims
          const information = {
            id: usuario_id,
            nombre: usuario_nombre,
            apellido: usuario_apellido,
            rol: { id: usuario_rol_id, name: usuario_rol_name },
            username: decodedToken.username,
            aplicacion: {
              id: aplicacion_id,
              paisId: pais_id,
              tipoAplicacionId: tipo_plicacion_id,
              applicationId: aplicacion_id
            }
          }

          const aplicacion = {
            tipoAplicacionId: tipo_plicacion_id,
            applicationId: aplicacion_id,
            paisId: pais_id,
            activa,
            nombre
          }

          const aplicacionId = aplicacion.applicationId
          const appInfo = await getAppInformation(aplicacionId, token)
          const rolId = information.rol.id
          const permisos = await obtenerPermisosApp({ rolId }, token)
          const countries = await getAllCountries(token)

          
          const { data } = await getActiveTemplate(aplicacionId, token)
          let template = []
          
          if (data.length) {
            template = {
              id: data[0].id,
              empresaId: aplicacionId,
              templateId: data[0].template.id,
              template: data[0].template,
            }
          }
          
          const userInf = appInfo.data
          
          const permissions = permisos.data.map((permiso) => {
            const { module, action, name } = permiso
            return `${module}:${action}${name ? ':' + name : ''}`
          })
          
          dispatch(
            loginSuccess({
              information,
              permissions,
              aplicacion,
              template,
              userInf,
              countries: countries.data
            })
          )
          window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESHED, 'false')
        } else {
          return Promise.reject(new Error('Usuario con sesión activa. Reintente en 15 minutos.'))
        }
      } else {
        return Promise.reject(new Error('Inicio fallido.'))
      }
    } catch (error) {
      const { status } = error.response
      if(status === 409){
        return Promise.reject(new Error('Usuario con sesión activa. Reintente en 15 minutos.'))
      } else if(status === 403){
        return Promise.reject(new Error('Por motivos de seguridad tu cuenta ha sido bloqueada. Hemos enviado un link de recuperación a tu direción de correo electrónico.'))
      } else if(status === 406){
        return Promise.reject(new Error('El usuario ha sido bloqueado por el administrador.'))
      }
      return Promise.reject(new Error('Usuario/Contraseña no válido.'))
    }
  }
}
