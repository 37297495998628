import React, { useState, useMemo, useEffect } from 'react'
import { useDisclosure, Stack, Select, Button, Box, SimpleGrid, Text, Spinner } from '@chakra-ui/core'
import ModalSimple from '../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import { DateRangePicker } from 'react-dates'
import { Heading } from '@chakra-ui/core'
import { PageContainer, Panel } from '../../common/helpers'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserApplicationId, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import Table from '../../common/table'
import { getConsultations, getStates, updateConsulta } from '../../../api/mundigea-gw'
import Details from './details'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import FormInput from '../../forms/form-input'
import SelectAsync from '../../common/select-async'
import { isSuccess } from '../../../helpers/utils'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './keys'
import Pagination from '../../common/pagination'
import { Controller, useForm } from 'react-hook-form'
import DetailsTable from './detailsTable'

export function Consultations({ onchangeDates, state }) {
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
    handleResetFilters,
  } = useSectionData()
  const { t } = useTranslation(['consultations', 'comunications', 'common'])
  const { control } = useForm()
  const userCountryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const rolId = useSelector(getUserRolId)
  const permisosUserCountry = rolId == 2 ? true : false
  const appId = useSelector(getUserApplicationId)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const [totalPages, setTotalPages] = useState(0)

  // DATE PICKER
  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: moment(),
    endDate: null,
  })

  const [focusedInput, setFocusedPicker] = useState(null)

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
      }))
    }
  }, [startDate, endDate])

  // CUSTOMER DATA AND STATES
  const [dataStates, setDataStates] = useState([])
  const [selectFecha, setSelectFecha] = useState('')
  const [{ general, packageInformation }, setDataConsultations] = useState({
    general: [],
    details: [],
    contactInformation: [],
    packageInformation: [],
  })

  // MODAL
  const modalDisclosure = useDisclosure()
  const [visualDetailsExchange, setDetailsExchange] = useState({
    visualDetailsExchange: true,
  })

  // SET DETAILS AND STATES
  function onDetailsClick(row) {
    modalDisclosure.onOpen()
    setDetailsExchange(false)
    console.log('mariano', row)
    let general = {
      esMapa: row.original.tipoConsulta === 'mapa' ?? false,
      nombre: row.original.nombre,
      apellido: row.original.apellido,
      email: row.original.email,
      telefono: row.original.telefono,
      destino: row.original.destino,
      comentarios: row.original.comentarios,
      totalAdultos: row.original.totalAdultos,
      totalNinos: row.original.totalNinos,
      totalDias: row.original.totalDias,
      mes: row.original.mes,
      year: row.original.year,
      banner:row.original.banner,
      purchaseNumber: row.original.numeroCompra,
      purchaseDate: row.original.fechaCompra,
      tipoConsulta: row.original.tipoConsulta,
      numeroPaquete: row.original.numeroPaquete,
      title: row.original.title,
      link: row.original.link,
      provider: row.original.provider
    }

    setDataConsultations(state => ({
      general: general,
    }))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('comunications:agency') + ' id',
        accessor: 'agenciaId',
      },
      {
        Header: t('consultations:date'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack>
            <Text>{new Date(cell.row.original.createdAt).toLocaleDateString()}</Text>
          </Stack>
        ),
      },
      {
        Header: t('consultations:from'),
        accessor: 'tipoConsulta',
      },
      {
        Header: t('consultations:agencyName'),
        accessor: 'nombreAgencia',
      },
      {
        Header: t('consultations:name'),
        accessor: 'nombre',
      },
      {
        Header: t('consultations:lastName'),
        accessor: 'apellido',
      },
      {
        Header: t('consultations:email'),
        accessor: 'email',
      },
      {
        Header: t('consultations:state'),
        accessor: null,
        Cell: ({ cell }) => (
          <Box key={cell.row.original.id}>
            <Controller
              isRequired
              control={control}
              name='estado'
              id={cell.row.original.id}
              defaultValue={cell.row.original.estado?.id}
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => {
                    return changeState(e.target.value, cell.row.original)
                  }}
                  name={name}
                  bg={cell.row.original.estado?.color}
                  value={cell.row.original.estado?.id}
                  id={cell.row.original.id}
                >
                  {dataStates.map(x => (
                    <option key={cell.row.original.id} style={{ backgroundColor: x.color }} value={x.id}>
                      {x.descripcion}
                    </option>
                  ))}
                </SelectAsync>
              )}
            />
          </Box>
        ),
      },
      {
        Header: t('consultations:details'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <Button width='70%' left='10%' colorScheme='blue' onClick={() => onDetailsClick(cell.row)}>
              <b>{t('consultations:view')}</b>
            </Button>
          </Stack>
        ),
      },
    ],
    [dataStates],
  )

  // GET CONSULTATIONS
  async function getConsultas(reset = null) {
    filters.paisId = userCountryId
    if (!permisosUserCountry) filters.agenciaId = appId

    const filtersAux = reset ? defaultFiltersValues : filters

    setLastFilters(filtersAux)

    handleSectionData(SECTION_DATA_KEYS.consultas, {
      ...sectionData.consultas,
      isLoading: true,
    })

    try {
      const responseConsultas = await getConsultations(filtersAux, authToken)
      let consultas = await responseConsultas.data.data

      setTotalPages(responseConsultas.data.totalPages)

      handleSectionData(SECTION_DATA_KEYS.consultas, {
        data: consultas,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function changeState(value, row) {
    try {
      const id = row.id
      const data = { estado: { id: parseInt(value) } }
      const response = await updateConsulta(id, data, authToken)

      const status = isSuccess(response.status)

      if (status) {
        getConsultas()
        return status
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getEstados() {
    try {
      const response = await getStates(authToken)
      let getEstados = response.data
      setDataStates(getEstados)
    } catch (error) {
      console.log(error)
    }
  }

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getConsultas()
      }
    }
  }

  useEffect(() => {
    getConsultas()
    getEstados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
    getConsultas(true)
    setSelectFecha('')
    setRangePicker({ startDate: moment(), endDate: null })
    filters.paisId = userCountryId
  }, [resetFilters])

  return (
    <PageContainer>
      <Box backgroundColor='' py='16px' mb='10px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {t('consultations:consultations')}
        </Heading>
      </Box>
      <Panel style={{ marginBottom: '1.5rem' }} rounded='md'>
        <SimpleGrid columns={{ sm: 1, md: 5 }} spacing='10px'>
          <Box>
            <DateRangePicker
              showDefaultInputIcon={true}
              isOutsideRange={() => false}
              startDate={startDate}
              startDateId='fecha_inicio'
              endDate={endDate}
              endDateId='fecha_fin'
              focusedInput={focusedInput}
              onDatesChange={({ startDate, endDate }) => {
                handleFilters(FILTER_KEYS.startDate, startDate ? moment(startDate).startOf('d').toDate() : null)
                handleFilters(FILTER_KEYS.endDate, endDate ? moment(endDate).endOf('d').toDate() : null)

                return setRangePicker({ startDate: startDate, endDate: endDate })
              }}
              onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
              small
              orientation='horizontal'
              displayFormat='DD/MM/YYYY'
            />
          </Box>

          <Box>
            <SelectAsync
              value={selectFecha}
              placeholder={t('consultations:date')}
              onChange={e => {
                setSelectFecha(e.target.value)
                handleFilters(FILTER_KEYS.startDate, moment().subtract(e.target.value, 'd').endOf('d').toDate())
                handleFilters(
                  FILTER_KEYS.endDate,
                  e.target.value !== '' ? moment(new Date()).endOf('d').toDate() : null,
                )

                return setRangePicker({
                  startDate: moment().subtract(e.target.value, 'd'),
                  endDate: e.target.value !== '' ? moment(new Date()) : null,
                })
              }}
            >
              <option value={10}>{t('consultations:last10Days')}</option>
              <option value={30}>{t('consultations:lastMonth')}</option>
              <option value={180}>{t('consultations:last6Months')}</option>
            </SelectAsync>
          </Box>

          {permisosUserCountry ? (
            <FormInput
              name='agencia'
              value={filters.nombreAgencia}
              onChange={e => handleFilters(FILTER_KEYS.nombreAgencia, e.target.value)}
              inputProps={{
                placeholder: `${t('consultations:agencyName')} (${t('common:optional')})`,
              }}
            />
          ) : (
            ''
          )}

          <Box>
            <SelectAsync
              value={filters.tipoConsulta}
              placeholder='Tipo Consulta'
              onChange={e => {
                return handleFilters(FILTER_KEYS.tipoConsulta, e.target.value)
              }}
            >
              {['Paquete', 'Mapa', 'Banners', 'Arrepentimiento'].map(item => {
                return <option>{item}</option>
              })}
            </SelectAsync>
          </Box>

          <Box style={{ display: 'flex', gap: '1rem' }}>
            <Button variantColor='blue' variant='outline' onClick={onFilter}>
              {t('common:filter')}
            </Button>

            <Button variantColor='blue' variant='outline' onClick={handleResetFilters}>
              {t('comunications:cleanFilter')}
            </Button>
          </Box>
        </SimpleGrid>
      </Panel>
      {!visualDetailsExchange ? (
        <ModalSimple
          boxShadow='xl'
          size='6xl'
          isOpen={modalDisclosure.isOpen}
          onClose={modalDisclosure.onClose}
          title={`${t('consultations:details')} ${t('consultations:consultation')}`}
        >
          <DetailsTable
            general={general}
            packageInformation={packageInformation}
            btnClose={modalDisclosure.onClose}
          ></DetailsTable>
        </ModalSimple>
      ) : null}

      {!sectionData.consultas.isLoading ? <Table columns={columns} data={sectionData.consultas.data} /> : null}

      {!sectionData.consultas.isLoading && totalPages ? (
        <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
      ) : (
        ''
      )}
      {sectionData.consultas.isLoading ? (
        <Box px='5' py='10' textAlign='center'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Box>
      ) : null}
    </PageContainer>
  )
}
