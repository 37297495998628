import React, { useState, useEffect, useMemo } from 'react'
import { PageContainer } from '../../common/helpers'
import { Box, Image, Link, Button, Flex, Spinner } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import { obtenerComunicados, obtenerCategorias, obtenerPermisosPorRolId } from '../../../api/communications'
import { useSelector } from 'react-redux'
import moment from 'moment'
import './../../styles/home/home.css'
import NewsSlider from '../../news/newsSlider'

function LastNews() {
  let history = useHistory()
  const { t } = useTranslation(['comunications'])
  const userCountryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId === 1 ? true : false

  // - - ❗ States ❗ - -

  const [events, setEvents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [allCategories, setAllCategories] = useState(false)

  // - - - -❗ View❗- - - -
  //Ir a leer la noticias
  function openNewsWindow(x) {
    if (x.comunicadoHtml === null || x.comunicadoHtml === '') {
      return null
    } else {
      history.push(`/newsGEA?${x.id}?h`)
    }
  }
  // ParseCategoria
  function createCategorias(idCat) {
    if (allCategories == false) {
      return null
    } else {
      let cat = allCategories.filter(x => x.id === idCat.categoriasComunicados?.id)
      return cat.color
    }
  }

  // - - - -📞❗ API CALLS ❗📞- - - -

  // Obtener Permisos de Usuario
  async function obtenerPermisos() {
    try {
      const resp = await obtenerPermisosPorRolId(userRolId, authToken)
      await getEvents(resp.data)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }
  // Obtener Noticias
  async function getEvents(permisos) {
    try {
      const searchCountry = permisosUser ? '' : userCountryId
      const resp = await obtenerComunicados(authToken, {
        paisId: searchCountry,
        tipoComunicadoId: 1,
        destacado: true,
        maxPerPage: 100,
      })

      //Filtro por Fecha Null
      let filterDate = resp.data.data
        .filter(x => x.fechaBaja == null)
        .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))

      let perm = permisos
      perm.filter(x => x.rolId === userRolId)

      // function filters(id) {
      //   let i = perm.map(x => x.comunicadosId === id)
      //   let k = i.filter(x => x === true)
      //   if (k.length === 0) {
      //     return false
      //   } else {
      //     return true
      //   }
      // }

      // let filterPermisos = filterDate.map(x => (filters(x.id) === true ? x : null))
      let permisosIds = permisos.map(x => x.comunicadosId)

      let filterPermisos = filterDate.filter(x => permisosIds.includes(x.id))

      let filterPermisos2 = filterPermisos.filter(x => x !== null)

      let filterLastDat = filterPermisos2.sort((datoPrev, datoNext) => {
        return (
          new Date(moment(datoPrev.fecha).format('YYYY-MM-DD')).getTime() <
          new Date(moment(datoNext.fecha).format('YYYY-MM-DD')).getTime()
        )
      })
      let today = moment().endOf('day')

      let destacados = filterLastDat.filter(x => x.destacado === true)
      let noDestacados = filterLastDat.filter(x => x.destacado === false)

      const concatEventsHome = destacados.concat(noDestacados)
      // .filter(evento => {
      //   let eventoFecha = moment(evento.fecha)
      //   return eventoFecha.isSameOrAfter(today, 'day')
      // })

      setEvents(concatEventsHome.sort((a, b) => moment(b.fecha).valueOf() - moment(a.fecha).valueOf()).slice(0, 9))
    } catch (err) {
      console.log(err)
    }
  }
  // Obtener Categorias de sNoticias
  async function obtenerCategoriasFunc() {
    try {
      const resp = await obtenerCategorias(userCountryId, authToken)
      let filterDate = resp.data.filter(x => x.fechaBaja === null)
      setAllCategories(filterDate)
    } catch (error) {
      console.log(error)
    }
  }

  // - - - -❗ Vistas Tarjetas ❗- - - -

  const newsListLength = (events && events.length) || 0

  useEffect(() => {
    setIsLoading(true)
    obtenerPermisos()
    obtenerCategoriasFunc()
  }, [])

  return (
    <Box style={{ padding: '0.5rem 0rem', background: 'white' }}>
      <PageContainer>
        <Box
          fontFamily={'Inter'}
          mx='2.5rem'
          mt='1'
          fontSize='2.25rem'
          fontWeight='700'
          as='h4'
          color='blue.700'
          lineHeight='normal'
        >
          {t('comunications:lastNews')}
          <Box className='divider-bar' />
        </Box>

        <Box mx='2rem' px='4px'>
          {isLoading && (
            <Flex justifyContent={'center'} mt={'2rem'}>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Flex>
          )}

          {!isLoading && (
            <Box className=''>
              <NewsSlider events={events} />
            </Box>
          )}
        </Box>
      </PageContainer>
    </Box>
  )
}

export default LastNews
