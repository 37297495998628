import { Box, Stack, useDisclosure } from '@chakra-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getAgreements } from '../../../../redux/actions/suppliers-actions'
import { Panel } from '../../../common/helpers'
import Table from '../../../common/table'
import { useAsyncStateWithAction, useAsyncToastNotification, usePermission } from '../../../hooks'
import { getAgreementsState, getAuthToken } from '../../../../redux/selectors'
import SupplierAgreementForm from '../../../forms/suppliers/supplier-agreement-form'
import { useSelector } from 'react-redux'
import { createAgreementSupplier, deleteAgreementById, updateAgreementById } from '../../../../api/intranet/requests'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import ModalSimple from '../../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import { SUPPLIERS_EDIT } from '../../../../helpers/permissions'
import Pagination from '../../../common/pagination'

export default function SupplierAgreemenst() {
  const { t } = useTranslation(['common', 'suppliers'])
  const { id: supplierId } = useParams()
  const [dataEditing, setDataEditing] = useState(false)

  const authToken = useSelector(getAuthToken)
  const maxPerPage = 12
  const [page, setPage] = useState(1)
  const dispatchedGetAgreenets = (dispatch) => dispatch(getAgreements(supplierId, maxPerPage, page))
  const { error, isLoading, items, update } = useAsyncStateWithAction(dispatchedGetAgreenets, getAgreementsState)

  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const { addPermissions } = usePermission()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })
  const onCloseDeleteAlert = () => setDeleteAlert((state) => ({ ...state, show: false }))

  const columns = useMemo(() => {
    function onClickEdit(data) {
      setDataEditing(data)
      onOpen()
    }
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('suppliers:product'),
        accessor: 'producto',
      },
      {
        Header: t('suppliers:commissionType'),
        accessor: 'moneda',
        Cell: ({ cell }) => cell.row.original.moneda ? cell.row.original.moneda.simbolo + " " + cell.row.original.moneda.descripcion : "%",
      },
      {
        Header: t('suppliers:commission'),
        accessor: 'comision',
      },
      {
        Header: t('suppliers:observation'),
        accessor: 'observaciones',
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => (
          <Stack spacing={2} isInline>
            <ButtonEdit onClick={() => onClickEdit(cell.row.original)} />
            <ButtonDelete onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ]
  }, [onOpen, t])

  function prepareDelete(data) {
    setDeleteAlert((state) => ({
      ...state,
      show: true,
      data,
    }))
  }

  async function onSubmit(data, e) {
    await asyncNotificationWrapper(async () => {
      data.comision = parseFloat(data.comision.replace(/,/g, '.'))
      const d = {
        ...data,
        activo: true,
        proveedor: { id: parseInt(supplierId) },
      }
      const datas = addPermissions(d, 'proveedores:editar')
      const res = await createAgreementSupplier(datas, authToken)

      if (res.status) {
        e.target.reset()
        update()
      }

      return res.status
    })
  }

  async function updateAgreement(formdata, agreementId) {
    formdata.comision = parseFloat(formdata.comision.replace(/,/g, '.'))
    const data = {
      ...formdata,
      activo: true,
      proveedor: { id: parseInt(supplierId) },
    }

    const res = await updateAgreementById(data, agreementId, authToken)

    if (res.status) {
      update()
    }

    return res.status
  }

  async function onSubmitUpdate(formdata, e) {
    await asyncNotificationWrapper(async () => {
      const d = { ...formdata, activo: true, proveedor: { id: parseInt(supplierId) } }

      const data = addPermissions(d, SUPPLIERS_EDIT)

      const res = await updateAgreement(data, dataEditing.id)

      if (res) {
        e.target.reset()
        update()
        onClose()
      }

      return res
    })
  }

  async function onDelete(id) {
    const res = await deleteAgreementById(id, authToken)
    if (res.status) {
      update()
    }
    onCloseDeleteAlert()
  }

  const deleteString = `${t('common:delete')} ${t('suppliers:agreements').toLowerCase()}`

  useEffect(() => {
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    if (items?.totalPages < page) {
      setPage(items.totalPages)
    }
  }, [items])

  return (
    <Box>
      <AlertDialogSimple isOpen={deleteAlert.show} onClose={onCloseDeleteAlert} onAccept={() => onDelete(deleteAlert.data.id)} title={deleteString} acceptLabel={t('common:delete')}>
        {t('common:deleting')} <br />
        <b>{deleteAlert.dataUser && ` ${t('suppliers:agreement')} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>
      <ModalSimple title={t('common:edit')} size='800px' onClose={onClose} isOpen={isOpen}>
        <SupplierAgreementForm key='edit-form' items={items} formData={dataEditing} onSubmit={onSubmitUpdate} onClose={onClose} />
      </ModalSimple>
      <Stack spacing='20px'>
        <Panel>
          <SupplierAgreementForm key='create-form' items={items} onSubmit={onSubmit} />
        </Panel>
        {!isLoading && !error && items && <Table columns={columns} data={items.data} />}
      </Stack>
      {!isLoading && items?.totalPages && (
          <Pagination currentPage={page} setPage={setPage} totalPages={items?.totalPages} />
        )}
    </Box>
  )
}
