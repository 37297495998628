import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/core'
import { Divider } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function ClaimsDetail(props) {
  const { t } = useTranslation(['forms', 'incentives', 'common', 'consultations'])

  return (
    <Tabs mt='5' mb='2' variant='enclosed'>
      <TabList>
        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Datos Personales</Tab>
        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Información General</Tab>
        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>{`${t('consultations:details')} ${t('common:del')} ${t(
          'common:navbar.claim',
        )}`}</Tab>
        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Datos Apoderado</Tab>
      </TabList>
      <TabPanels mt='2'>
        <TabPanel>
          <Box
            style={{
              border: '1px solid #E2E8F0',
              borderRadius: '0.35rem',
              padding: '2rem',
              display: 'flex',
              gap: '1.5rem',
              justifyContent: 'start',
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box>
                <b>{t('consultations:name')}s</b>: {props.general.datosPersonales.nombre || '--'}
              </Box>
              <Box>
                <b>{t('consultations:lastName')}s</b>:
                {` ${props.general.datosPersonales.apellido !== ' ' ? props.general.datosPersonales.apellido : '--'}`}
              </Box>
              <Box>
                <b>{t('consultations:docType')}</b>: {props.general.datosPersonales.tipoDocumento || '--'}
              </Box>
              <Box>
                <b>{t('consultations:docNumber')}</b>: {props.general.datosPersonales.numeroDocumento || '--'}
              </Box>
              <Box>
                <b> {t('consultations:email')}:</b> {props.general.datosPersonales.email || '--'}
              </Box>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box>
                <b>{t('forms:address')}</b>: {props.general.datosPersonales.domicilio || '--'}
              </Box>
              <Box>
                <b>{t('forms:department')}</b>: {props.general.datosPersonales.departamento || '--'}
              </Box>
              <Box>
                <b>{t('forms:province')}</b>: {props.general.datosPersonales.provincia || '--'}
              </Box>
              <Box>
                <b>{t('consultations:district')}</b>: {props.general.datosPersonales.distrito || '--'}
              </Box>
              <Box>
                <b>{`${t('forms:phone')} ${t('consultations:fix')}`}</b>:
                {` ${props.general.datosPersonales.telefonoFijo || '--'}`}
              </Box>
              <Box>
                <b>{`${t('forms:phone')} ${t('consultations:cell')}`}</b>:
                {` ${props.general.datosPersonales.telefonoCelular || '--'}`}
              </Box>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel>
          <Box
            style={{
              border: '1px solid #E2E8F0',
              borderRadius: '0.35rem',
              padding: '2rem',
              display: 'flex',
              gap: '1.5rem',
              justifyContent: 'start',
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box>
                <b>{t('consultations:goods')}</b>: {props.general.informacionGeneral.bienContratado || '--'}
              </Box>
              <Box>
                <b>{t('consultations:amount')}</b>:
                {` ${props.general.informacionGeneral.monto ? `$${props.general.informacionGeneral.monto}` : '--'}`}
              </Box>
              <Box>
                <b>{t('common:description')}</b>:
              </Box>
              <Box style={{ maxWidth: '38rem', maxHeight: '50vh', overflow: 'scroll' }}>
                {props.general.informacionGeneral.descripcion || '--'}
              </Box>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel>
          <Box
            style={{
              border: '1px solid #E2E8F0',
              borderRadius: '0.35rem',
              padding: '2rem',
              display: 'flex',
              gap: '1.5rem',
              justifyContent: 'start',
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box>
                <b>{t('consultations:claimType')}</b>: {props.general.detalleReclamo.tipoReclamo || '--'}
              </Box>
              <Box>
                <b>{t('consultations:details')}</b>:
              </Box>
              <Box style={{ maxWidth: '38rem', maxHeight: '50vh', overflow: 'scroll' }}>
                {props.general.detalleReclamo.detalleReclamo || '--'}
              </Box>
              <Divider style={{ borderColor: 'gray', width: '100%', margin: '1rem 0rem 1rem' }} />
              <Box>
                <b>{t('consultations:request')}</b>:
              </Box>
              <Box style={{ maxWidth: '38rem', maxHeight: '50vh', overflow: 'scroll' }}>
                {props.general.detalleReclamo.pedido || '--'}
              </Box>
            </Box>
          </Box>
        </TabPanel>

        <TabPanel>
          <Box
            style={{
              border: '1px solid #E2E8F0',
              borderRadius: '0.35rem',
              padding: '2rem',
              display: 'flex',
              gap: '1.5rem',
              justifyContent: 'start',
            }}
          >
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box>
                <b>Menor de Edad</b>: {props.general.datosApoderado.menorEdad || '--'}
              </Box>
              <Box>
                <b>{t('consultations:name')}s</b>: {props.general.datosApoderado.nombre || '--'}
              </Box>
              <Box>
                <b>{t('consultations:lastName')}s</b>:
                {` ${props.general.datosApoderado.apellido !== ' ' ? props.general.datosApoderado.apellido : '--'}`}
              </Box>
              <Box>
                <b>{t('consultations:docType')}</b>: {props.general.datosApoderado.tipoDocumento || '--'}
              </Box>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              <Box>
                <b>{t('consultations:docNumber')}</b>: {props.general.datosApoderado.numeroDocumento || '--'}
              </Box>
              <Box>
                <b> {t('consultations:email')}:</b> {props.general.datosApoderado.email || '--'}
              </Box>
              <Box>
                <b>
                  {t('forms:phone')} {t('consultations:fix')}
                </b>
                : {props.general.datosApoderado.telefonoFijo || '--'}
              </Box>
              <Box>
                <b>{`${t('forms:phone')} ${t('consultations:cell')}`}</b>:{' '}
                {props.general.datosApoderado.telefonoCelular || '--'}
              </Box>
            </Box>
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
