import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, PseudoBox, FormLabel } from '@chakra-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserCountryId, getUserInformation } from '../../../../redux/selectors'
import SelectAsync from '../../../common/select-async'
import { getDefaultEnlaceByParams, getDisabledLinksByParams } from '../../../../api/intranet/marca-blanca'

export default function DisabledLinksForm({ onSubmit, trigger, ...restProps }) {
  const { handleSubmit, formState, control, reset } = useForm()
  const { t } = useTranslation(['common', 'forms', 'banners'])
  const countryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const user = useSelector(getUserInformation)

  const [defaultItems, setDefaultItems] = useState({
    isLoading: false,
    data: [],
    error: false,
  })

  const [items, setItems] = useState({
    isLoading: false,
    data: [],
    error: false,
  })

  const [filteredItems, setFilteredItems] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getAllDefaultEnlace() {
    setDefaultItems(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getDefaultEnlaceByParams({ paisId: countryId }, authToken)
      setDefaultItems(state => ({
        ...state,
        isLoading: false,
        data: res.data || [],
      }))
    } catch (error) {
      setDefaultItems(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  async function getAllDisabledLinks(appId) {
    setItems(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getDisabledLinksByParams({ agenciaId: appId, paisId: countryId }, authToken)
      setItems(state => ({
        ...state,
        isLoading: false,
        data: res.data.filter(item => !item.activo) || [],
      }))
    } catch (error) {
      setItems(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  const handleFormSubmit = async data => {
    await onSubmit(data)
    getAllDefaultEnlace()
    getAllDisabledLinks(user.aplicacion.id)
    reset({ link: '' })
  }

  useEffect(() => {
    getAllDefaultEnlace(user.aplicacion.id)
    getAllDisabledLinks(user.aplicacion.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])

  useEffect(() => {
    if (items?.data && defaultItems.data) {
      setFilteredItems(state => ({
        ...state,
        items: defaultItems.data?.filter(itemDefault =>
          items.data.every(itemData => itemData.defaultEnlace?.id !== itemDefault.id),
        ),
        isLoading: true,
      }))
    }
  }, [items, defaultItems])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(handleFormSubmit)} {...restProps}>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
        {/* Links */}
        <Box>
          <FormLabel isRequired>{`${t('forms:links')}`}</FormLabel>
          <Controller
            isRequired
            control={control}
            name='link'
            render={({ onChange, value, name }) => (
              <SelectAsync
                isRequired
                onChange={e => onChange(e.target.value)}
                placeholder={`${t('forms:selectLink')}`}
                name={name}
                value={value}
                isLoading={!filteredItems.isLoading}
              >
                {filteredItems.isLoading && filteredItems.items.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.nombre}
                      </option>
                    ))}
              </SelectAsync>
            )}
          />
        </Box>

        {/* Desactivar Button */}
        <Box d='flex' alignItems='flex-end'>
          <Button variantColor='blue' variant='outline' isLoading={formState.isSubmitting} type='submit'>
            {t('common:deactivate')}
          </Button>
        </Box>
      </Grid>
    </PseudoBox>
  )
}
