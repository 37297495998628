import axios from 'axios'
import { getStorageToken } from '../helpers/utils'

export const config = {
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
}

export const configWithToken = authToken => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
  }
}

export const configWithParams = params => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
    params,
  }
}

// instances

const { REACT_APP_INTRANET_API_V2, REACT_APP_APPLICATIONS_API_V2, REACT_APP_GEO_API_V2 } = process.env
export const applicationInstance = axios.create({
  baseURL: REACT_APP_APPLICATIONS_API_V2,
})

// Endpoints

export const loginEndpoint = '/api/auth/login'

export const recoveryPassEndpoint = '/api/recovery-password/verify'

export const forgotPassEndpoint = '/api/recovery-password/forgot-password'

export const logoutUserEndpoint = '/api/auth/logout'

export const authEndpoint = dominio => `/api/auth/${dominio}`

export const aplicacionesEndpoint = '/api/aplicaciones'

export const aplicacionesGeaEndpoint = '/api/aplicaciones-gea'

export const proveedoresExtraEndpoint = '/api/proveedores-extra'

export const registrosTipoCambioEndpoint = '/api/registros-tipo-cambio'

export const permisosEndpoint = '/api/permisos'

export const paisesEndpoint = '/api/pais'

export const proveedoresEndpoint = '/api/proveedores'

export const aplicacionDetalleEndpoint = '/api/aplicaciones-detalle'

export const aplicacionDetalleAppIdEndpoint = '/api/aplicaciones-detalle/aplicacion'

export const usuariosEndpoint = '/api/usuarios'

export const getRolesEndpoint = '/api/roles'

export const aplicacionesSucursalesEndpoint = '/api/aplicaciones-sucursales'

export const aplicacionesSucursalesAppIdEndpoint = '/api/aplicaciones-sucursales/aplicacion'

export const monedasEndpoint = '/api/monedas'

export const monedasAdminEndpoint = '/api/moneda-admin'

export const cambioMonedasEndpoint = '/api/cambio-monedas'

export const rubrosEndpoint = '/api/rubro'

export const allRubrosEndpoint = '/api/rubro/all'

export const rubrosCountryEndpoint = '/api/rubro/pais'

export const comercialesEndpoint = '/api/comercial/all'

export const comercialesPaginatedEndpoint = '/api/comercial'

export const comercialesCountryEndpoint = '/api/comercial/pais'

export const setAplicacionesByIdEndpoint = id => `/api/aplicaciones/${id}`

// Auth

export const loginRequest = ({ user, pass }) =>
  axios.post(
    `${REACT_APP_APPLICATIONS_API_V2}${loginEndpoint}`,
    {
      username: user,
      password: pass,
    },
    config,
  )

export const recoveryPassRequest = ({ token, pass }) =>
  axios.post(
    `${REACT_APP_APPLICATIONS_API_V2}${recoveryPassEndpoint}`,
    {
      token,
      password: pass,
    },
    config,
  )

export const forgotPassRequest = username =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${forgotPassEndpoint}/${username}`, config)

export const logoutUser = token =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${logoutUserEndpoint}`, {}, configWithToken(token))

export const authPorDominio = dominio => axios.get(`${REACT_APP_APPLICATIONS_API_V2}${authEndpoint(dominio)}`, config)

// Permisos

export const obtenerPermisosApp = (data, token) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${permisosEndpoint}`, configWithParams(data, token))

// Paises

export const getAllCountries = token => axios.get(`${REACT_APP_GEO_API_V2}${paisesEndpoint}`, configWithToken(token))

export const getCountryById = (id, token) =>
  axios.get(`${REACT_APP_GEO_API_V2}${paisesEndpoint}/${id}`, configWithToken(token))

// Aplicaciones

export const createApp = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesEndpoint}`, data, configWithToken(token))

export const getApplications = (data, token) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesEndpoint}`, configWithParams(data, token))

export const getAppInformation = (appId, token) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesEndpoint}/${appId}`, configWithToken(token))

export const updateAppInformation = (data, appId, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesEndpoint}/${appId}`, data, configWithToken(token))

export const deactivateAppById = (appId, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesEndpoint}/${appId}`, configWithToken(token))

// Aplicaciones - Gea

export const createAppGea = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesGeaEndpoint}`, data, configWithToken(token))

export const getAppsGea = (token, data) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesGeaEndpoint}`, configWithParams(data, token))

export const updateAppGea = (geaInfoId, data, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesGeaEndpoint}/${geaInfoId}`, data, configWithToken(token))

export const deleteAppGea = (appId, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesGeaEndpoint}/${appId}`, configWithToken(token))

// Aplicaciones - Detalle

export const createAplicacionDetalle = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionDetalleEndpoint}`, data, configWithToken(token))

export const updateAplicacionDetalle = (id, data, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionDetalleEndpoint}/${id}`, data, configWithToken(token))

export const deleteDetalleByAppId = (id, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionDetalleAppIdEndpoint}/${id}`, configWithToken(token))

export const getAplicacionDetalleByAppId = (id, token) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionDetalleAppIdEndpoint}/${id}`, configWithToken(token))

// Aplicaciones - Sucursales

export const getAplicacionesSucursales = token =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesSucursalesEndpoint}`, configWithToken(token))

export const getBranchOfficesById = (id, token) => {
  return axios.get(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesSucursalesEndpoint}/${id}`, configWithToken(token))
}

export const getBranchOfficesByAppId = (data, token) => {
  return axios.get(
    `${REACT_APP_APPLICATIONS_API_V2}${aplicacionesSucursalesAppIdEndpoint}`,
    configWithParams(data, token),
  )
}

export const createBranchOffice = (data, token) => {
  return axios.post(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesSucursalesEndpoint}`, data, configWithToken(token))
}

export const updateBranchOfficesById = (id, data, token) => {
  return axios.put(
    `${REACT_APP_APPLICATIONS_API_V2}${aplicacionesSucursalesEndpoint}/${id}`,
    data,
    configWithToken(token),
  )
}

export const deleteBranchOffices = (id, token) => {
  return axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${aplicacionesSucursalesEndpoint}/${id}`, configWithToken(token))
}

export const deleteBranchOfficesByAppId = (appId, token) => {
  return axios.delete(
    `${REACT_APP_APPLICATIONS_API_V2}${aplicacionesSucursalesAppIdEndpoint}/${appId}`,
    configWithToken(token),
  )
}

// Aplicaciones - Monedas
export const createCurrencies = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${monedasEndpoint}`, data, configWithToken(token))

export const getCurrencies = token =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${monedasEndpoint}`, configWithToken(token))

export const editCurrencies = (data, id, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${monedasEndpoint}/${id}`, data, configWithToken(token))

export const deleteCurrencies = (id, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${monedasEndpoint}/${id}`, configWithToken(token))

// Aplicaciones - Monedas Admin
export const createMonedaAdmin = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${monedasAdminEndpoint}`, data, configWithToken(token))

export const getMonedasAdmin = token =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${monedasAdminEndpoint}`, configWithToken(token))

export const editMonedaAdmin = (data, id, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${monedasAdminEndpoint}/${id}`, data, configWithToken(token))

export const deleteMonedaAdmin = (id, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${monedasAdminEndpoint}/${id}`, configWithToken(token))

// Aplicaciones - Cambio Monedas

export const getExchange = token =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${cambioMonedasEndpoint}`, configWithToken(token))

export const createExchange = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${cambioMonedasEndpoint}`, data, configWithToken(token))

export const updateExchange = (data, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${cambioMonedasEndpoint}/${data.id}`, data, configWithToken(token))

export const deleteExchange = (id, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${cambioMonedasEndpoint}/${id}`, configWithToken(token))

// Aplicaciones - Comerciales

export const createComercial = data =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${comercialesPaginatedEndpoint}`, data, configWithToken())

export const getAllComerciales = () =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${comercialesEndpoint}`, configWithToken())

export const getAllComercialesByCountryId = countryId =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${comercialesCountryEndpoint}/${countryId}`, configWithToken())

export const getAllPaginatedComerciales = data =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${comercialesPaginatedEndpoint}`, configWithParams(data))

export const updateComercial = (id, data) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${comercialesPaginatedEndpoint}/${id}`, data, configWithToken())

export const deleteComercial = id =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${comercialesPaginatedEndpoint}/${id}`, configWithToken())

// Proveedores

export const getProviderByCountry = (data, token) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}`, configWithParams(data, token))

// Proveedores Extra

export const getProvidersExtra = token =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${proveedoresExtraEndpoint}`, configWithToken(token))

// Registros Tipo Cambio

export const getRegistrosTipoCambio = (data, token) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${registrosTipoCambioEndpoint}`, configWithParams(data, token))

export const getRegistroTipoCambioById = (id, token) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${registrosTipoCambioEndpoint}/${id}`, configWithToken(token))

export const createRegistrosTipoCambio = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${registrosTipoCambioEndpoint}`, data, configWithToken(token))

export const deleteRegistrosTipoCambio = (id, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${registrosTipoCambioEndpoint}/${id}`, configWithToken(token))

export const updateRegistrosTipoCambio = (id, data, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${registrosTipoCambioEndpoint}/${id}`, data, configWithToken(token))

// Usuarios

export const createUser = (data, token) =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${usuariosEndpoint}`, data, configWithToken(token))

export const getUsersByAppId = (token, data) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${usuariosEndpoint}`, configWithParams(data, token))

export const getAllUsers = (token, data) =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${usuariosEndpoint}`, configWithParams(data, token))

export const getUsersById = id =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${usuariosEndpoint}/${id}`, configWithToken())

export const updateUser = (id, data, token) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${usuariosEndpoint}/${id}`, data, configWithToken(token))

export const deleteUserById = (id, token) =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${usuariosEndpoint}/${id}`, configWithToken(token))

// Roles

export const getRoles = token =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${getRolesEndpoint}`, configWithToken(token))

// Rubros
export const createRubro = data =>
  axios.post(`${REACT_APP_APPLICATIONS_API_V2}${rubrosEndpoint}`, data, configWithToken())

export const getAllRubros = token =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${allRubrosEndpoint}`, configWithToken(token))

export const getAllRubrosByCountryId = countryId =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${rubrosCountryEndpoint}/${countryId}`, configWithToken())

export const getAllPaginatedRubros = data =>
  axios.get(`${REACT_APP_APPLICATIONS_API_V2}${rubrosEndpoint}`, configWithParams(data))

export const updateRubro = (id, data) =>
  axios.put(`${REACT_APP_APPLICATIONS_API_V2}${rubrosEndpoint}/${id}`, data, configWithToken())

export const deleteRubro = id =>
  axios.delete(`${REACT_APP_APPLICATIONS_API_V2}${rubrosEndpoint}/${id}`, configWithToken())

export const patchApplicationsEnableSocialNetworks = ({ id, ...data }, token) => {
  return axios.put(`${REACT_APP_APPLICATIONS_API_V2}${setAplicacionesByIdEndpoint(id)}`, data, configWithToken(token))
}
