import { Button, PseudoBox, Grid, useToast, FormLabel, Box } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getLinkTypeById } from '../../../api/web-information'
import { getCategoryDetails } from '../../../api/web-information'
import { useFormControls } from '../../hooks'
import { useSelector } from 'react-redux'
import FormInput from '../form-input'
import SelectAsync from '../../common/select-async'
import { getAuthToken } from '../../../redux/selectors'
import { toastCustomizedError } from '../../common/toast-default-config'

export default function TypeForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['forms'])
  const toast = useToast()
  const authToken = useSelector(getAuthToken)
  const { isSubmitBlocked } = useFormControls()
  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })
  const [nameValue, setNameValue] = useState()
  const [descriptionValue, setDescriptionValue] = useState()
  const [categoryValue, setCategoryValue] = useState()

  function loadValuesInputs(miLinkType) {
    setNameValue(miLinkType.nombre)
    setDescriptionValue(miLinkType.descripcion)
    setCategoryValue(miLinkType.categoria.id)
  }

  //// Load Company detail por id
  const [{ isLoading, linkType }, setLinkTypeRequest] = useState({
    isLoading: true,
    linkType: {},
  })

  async function getLinkType(compDetailId) {
    let miLinkType = {}

    setLinkTypeRequest(state => ({
      ...state,
      isLoading: true,
    }))

    await getLinkTypeById(compDetailId, authToken)
      .then(res => {
        miLinkType = res.data
        loadValuesInputs(miLinkType)
      })
      .catch(error => console.log('ErrorCompDetById', error))
    setLinkTypeRequest(state => ({
      ...state,
      isLoading: false,
      linkType: miLinkType,
    }))
  }

  ///On Guardar
  function onSubmitt(datos) {
    const data = {
      ...datos,
      categoria: { id: Number(datos.categoria.id) },
    }
    onSubmit(data)
  }

  const [categories, setCategories] = useState({
    data: [],
    isLoading: true,
    error: false,
  })

  const getCategories = async () => {
    setCategories(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getCategoryDetails(authToken)
      setCategories(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCategories(state => ({
        ...state,
        error: true,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }
  useEffect(() => {
    getCategories()
    getLinkType(restProps.idSectionn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(d => onSubmitt(d))} {...restProps}>
      {!isLoading && linkType && (
        <>
          <Grid
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
            gap={4}
            style={{ marginBottom: '1.5rem' }}
          >
            {/* Nombre */}
            <Box>
              <FormLabel htmlFor='nombre' isRequired>
                {t('forms:name')}
              </FormLabel>
              <FormInput
                isRequired
                maxLength='255'
                name='nombre'
                value={nameValue}
                onChange={e => {
                  const newNameValue = e.target.value
                  setNameValue(newNameValue)
                }}
                inputProps={{
                  placeholder: t('forms:name'),
                  ref: register,
                }}
              />
            </Box>
            {/* Descripcion */}
            <Box>
              <FormLabel htmlFor='descripcion' isRequired>
                {t('forms:description')}
              </FormLabel>
              <FormInput
                isRequired
                maxLength='255'
                name='descripcion'
                value={descriptionValue}
                onChange={e => {
                  const newDescriptionValue = e.target.value
                  setDescriptionValue(newDescriptionValue)
                }}
                inputProps={{
                  placeholder: t('forms:description'),
                  ref: register,
                }}
              />
            </Box>
            {/* Categoria */}
            <Box>
              <FormLabel htmlFor='categoria.id' isRequired>
                {t('forms:category')}
              </FormLabel>
              <SelectAsync
                name='categoria.id'
                isLoading={categories.isLoading}
                placeholder={t('forms:selectOption')}
                nativeProps={{ ref: register }}
                value={categoryValue}
                onChange={e => setCategoryValue(e.target.value)}
                isRequired
              >
                {categories.data.map(({ id, nombre }) => (
                  <option key={id} value={id}>
                    {t(`suppliers:${nombre}`)}
                  </option>
                ))}
              </SelectAsync>
            </Box>
          </Grid>
          <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
            {t('forms:save')}
          </Button>
        </>
      )}
    </PseudoBox>
  )
}
