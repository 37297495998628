import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, PseudoBox, FormLabel, Link } from '@chakra-ui/core'
import { Controller,useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  getTemplate,
  getUbicationsState,
} from '../../../../redux/selectors'
import SelectAsync from '../../../common/select-async'
import { useAsyncStateWithAction } from '../../../hooks'
import { listUbications } from '../../../../redux/actions/banners-actions.js'

export const UbicationForm = ({ onSubmit, combinedSections, formData = false, buttonLabel = false, ...restProps }) => {
  const template = useSelector(getTemplate)
  const [selectedUbication, setSelectedUbication] = useState(false)
  const [selectedSeccion, setSelectedSeccion] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)
  const { handleSubmit, formState, control, reset } = useForm({
    defaultValues: formData,
  })
  const { t } = useTranslation(['common', 'forms', 'banners', 'sections'])

  //TRAER UBICACIONES
  const dispatchedGetUbications = dispatch => dispatch(listUbications(template.id))
  const { isLoading: isLoadingUbication, items: itemsUbication } = useAsyncStateWithAction(
    dispatchedGetUbications,
    getUbicationsState,
  )

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ section: '' })
      setIsButtonEnabled(false)
      setSelectedSeccion(false)
    }
  }, [formState, reset])

  useEffect(() => {
    if (selectedSeccion && selectedUbication) setIsButtonEnabled(true)
  }, [selectedUbication, selectedSeccion])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(d => onSubmit(d))} {...restProps}>
      <Grid gap={6}>
        {/* Ubicaciones */}
        <Box>
          <FormLabel isRequired>
            {t('forms:ubication')}{' '}
            <Link color='gray.500' href='#'>
              {'(' + t('forms:seeLocation') + ')'}
            </Link>
          </FormLabel>
          <Controller
            isRequired={true}
            control={control}
            name='ubication'
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => {
                  onChange(e.target.value)
                  setSelectedUbication(true)
                }}
                placeholder={`${t('forms:selectOne')} ${t('forms:ubication')}`}
                name={name}
                value={value}
                isLoading={isLoadingUbication}
              >
                {itemsUbication &&
                  itemsUbication.map(ubication => {
                    if (ubication?.descripcion !== 'ubicacion-default') {
                      return (
                        <option key={ubication?.id} value={ubication?.id}>
                          {ubication?.nombre}
                        </option>
                      )
                    }
                  })}
              </SelectAsync>
            )}
          />
        </Box>
        <Box>
          <FormLabel>{t('forms:section')}</FormLabel>
          <Controller
            control={control}
            name='section'
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => {
                  onChange(e.target.value)
                  setSelectedSeccion(true)
                }}
                placeholder={`${t('forms:selectOne')} ${t('forms:section')}`}
                name={name}
                value={value}
                isDisabled={!selectedUbication}
              >
                {combinedSections &&
                  combinedSections.map((type, key) => {
                    return (
                      <option key={key} value={`${type.id}-${type.esDefault}`}>
                        {type.nombre ? type.nombre : type.descripcion}
                      </option>
                    )
                  })}
              </SelectAsync>
            )}
          />
        </Box>
        {/* Guardar Button */}
        <Box d='flex' alignItems='flex-end' justifyContent='center'>
          <Button
            variantColor='blue'
            variant='outline'
            isLoading={formState.isSubmitting}
            isDisabled={!isButtonEnabled}
            type='submit'
          >
            {t('common:save')}
          </Button>
        </Box>
      </Grid>
    </PseudoBox>
  )
}
