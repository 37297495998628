import React from "react";
import { Box, Link, MenuItem, PseudoBox } from "@chakra-ui/core";
import { Link as RouterLink } from "react-router-dom";
import Can from "../../../helpers/verify-permission";
import { useSelector } from "react-redux";
import { getAuthToken } from "../../../redux/selectors";

const LinkOffer = ({ children, href, ...restProps }) => {
  const authToken = useSelector(getAuthToken);
  const link = `${process.env.REACT_APP_OFFERS_FRONT}/ofertas?login=${authToken}`;
  return (
    <MenuItem {...restProps} href={link} target="_blank">
      {children}
    </MenuItem>
  );
};

const NavItem = ({ children, ...restProps }) => (
  <Box
    as="span"
    mt={{ base: 4, md: 0 }}
    mr={{ base: 0, md: 6 }}
    display="block"
    {...restProps}
  >
    {children}
  </Box>
);

const NavLink = ({ children, to, ...restProps }) => (
  <NavItem {...restProps}>
    <Link as={RouterLink} to={to}>
      {children}
    </Link>
  </NavItem>
);

const NavLinkAuthorized = ({ children, permission, ...restProps }) => (
  <Can
    permission={permission}
    yes={() => <NavLink {...restProps}>{children}</NavLink>}
  />
);

const MenuItemAuthorized = ({ children, permission, ...restProps }) => (
  <Can
    permission={permission}
    yes={() => <MenuItem {...restProps}>{children}</MenuItem>}
  />
);

export { NavItem, NavLink, NavLinkAuthorized, MenuItemAuthorized, LinkOffer };
