import React, { useEffect, useState } from 'react'
import { Box, Flex, List, Button, Grid, Heading } from '@chakra-ui/core'
import { PageContainer } from '../../common/helpers'
import { useRouteMatch } from 'react-router-dom'
import NoMatch from './../../no-march'
import { GiHamburgerMenu } from 'react-icons/gi'
import Information from './edit/index-information'
import { useTranslation } from 'react-i18next'
import NavigatorDrawer from '../../common/drawer/navigator-drawer'
import { ListItemRoute } from '../../common/router-components'
import { BranchOffices } from './edit/BranchOffices'
import { getAppInformation } from '../../../api/application'
import { useSelector } from 'react-redux'
import { getToken } from '../../../redux/selectors'
import { ExchangeAdminCountry } from '../currency/exchange-admin-country'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { UsersSection } from './users/users-section-data'
import { ComercialSection } from '../comercial/comercial-section-data'
import { RubroSection } from '../rubro/rubro-section-data'
import { Credentials } from './credentials'

export default function Edit() {
  const [appName, setAppName] = useState('...')
  const { appId } = useParams()
  const { path, url } = useRouteMatch()
  const { t } = useTranslation(['applications', 'common'])
  const token = useSelector(getToken)

  async function getProvider(appId) {
    try {
      const response = await getAppInformation(appId, token)
      setAppName(response.data.nombreFantasia)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getProvider(appId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!appId || appId === 0) {
    return <NoMatch />
  }

  const labelTitleTop = appName

  const childrenRoutes = [
    {
      exact: true,
      path: [`${path}/`, `${path}/information`],
      component: Information,
    },
    {
      path: `${path}/users`,
      component: UsersSection,
    },
    {
      path: `${path}/branch-offices`,
      component: BranchOffices,
    },
    {
      path: `${path}/credentials`,
      component: Credentials,
    },
    {
      path: `${path}/exchange`,
      component: ExchangeAdminCountry,
    },
    {
      path: `${path}/comercial`,
      component: ComercialSection,
    },
    {
      path: `${path}/rubro`,
      component: RubroSection,
    },
  ]

  const header = (onOpen, btnRef) => (
    <Grid templateColumns={{ base: '6fr 1fr', md: '7fr 0fr' }} gap={[3, 3, 0]}>
      <Box backgroundColor='' py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {labelTitleTop}
        </Heading>
      </Box>
      <Flex justify='flex-end'>
        <Button
          display={{ base: 'inherit', md: 'none' }}
          mb='10px'
          ref={btnRef}
          variant='outline'
          variantColor='blue'
          onClick={onOpen}
        >
          <Box as={GiHamburgerMenu} display='inline' />
        </Button>
      </Flex>
    </Grid>
  )

  return (
    <PageContainer>
      <NavigatorDrawer header={header} routes={childrenRoutes} spacing='16px'>
        <List spacing={3}>
          <ListItemRoute to={`${url}/information`}>{t('applications:mainData')}</ListItemRoute>
          <ListItemRoute to={`${url}/users`}>{t('applications:users')}</ListItemRoute>
          <ListItemRoute to={`${url}/branch-offices`}>{t('applications:branch-offices')}</ListItemRoute>
          <ListItemRoute to={`${url}/credentials`}>{t('applications:credentials')}</ListItemRoute>
        </List>
      </NavigatorDrawer>
    </PageContainer>
  )
}
