import React, { useState, useEffect } from 'react'
import { getUsersByAppId } from '../../../../api/application'
import { useSelector } from 'react-redux'
import { Box, Stack, Button, useDisclosure, useToast, Spinner } from '@chakra-ui/core'
import Table from '../../../common/table'
import { MdBuild } from 'react-icons/md'
import ModalSimple from '../../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError } from '../../../common/toast-default-config'
import AddCredentials from '../credentials/add-credentials'
import { getUserApplicationId, getUserRolName } from '../../../../redux/selectors'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useLocation } from 'react-router-dom'
import Pagination from '../../../common/pagination'
import CredentialsSearchForm from './credentials-search-form'
import { Panel } from '../../../common/helpers'
import { useSectionData } from '../../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './index'
import { allowedRolesToEdit } from '../../../../helpers/commons'

export default function UsersCredentials() {
  const { t } = useTranslation(['applications', 'common', 'forms'])
  const { appId } = useParams()
  const appId2 = useSelector(getUserApplicationId)
  const token = useSelector(state => state.appState.user.token)
  const modalEditDisclosure = useDisclosure()
  const [userId, setUserId] = useState(0)
  const location = useLocation()
  const isDatosCuenta = location.pathname.includes('my-applications')
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const [totalPages, setTotalPages] = useState(0)
  const userRolName = useSelector(getUserRolName)
  const appIdAgency = useSelector(getUserApplicationId)
  const maxPerPage = 10

  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const columns = React.useMemo(() => {
    function addUserData(userId) {
      setUserId(userId)
      modalEditDisclosure.onOpen()
    }
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('applications:user'),
        accessor: 'username',
      },
      {
        Header: t('common:rol'),
        accessor: 'rol.name',
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => (
          <Button
            leftIcon={MdBuild}
            variantColor='blue'
            variant='outline'
            size='xs'
            onClick={() => addUserData(cell.row.original.id)}
          >
            {t('applications:credentials')}
          </Button>
        ),
      },
    ]
  }, [modalEditDisclosure, t])

  const toast = useToast()

  const getUsers = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)

    handleSectionData(SECTION_DATA_KEYS.credentials, {
      ...sectionData.credentials,
      isLoading: true,
    })

    try {
      let response
      response = await getUsersByAppId(token, {
        ...filtersAux,
        aplicacionId: isDatosCuenta ? appId2 : appId
      })
      setTotalPages(response.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.credentials, {
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.credentials, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getUsers()
      }
    }
  }

  useEffect(() => {
    handleResetSectionData()
    getUsers(true)
  }, [resetFilters])

  const editUserString = `${t('common:add')} ${t('applications:credentials').toLowerCase()}`

  return (
    <Box flex='2' style={{ paddingRight: '1rem' }}>
      <ModalSimple {...modalEditDisclosure} title={editUserString} size='800px'>
        <AddCredentials userId={userId} />
      </ModalSimple>
      <Panel rounded='md' mb='1.5rem'>
          <CredentialsSearchForm onFilter={onFilter}/>
        </Panel>
      <Stack spacing={16}>
        <Box>
          {sectionData.credentials.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}

          {!sectionData.credentials.isLoading && sectionData.credentials.data !== null && <Table columns={columns} data={sectionData.credentials.data} />}

          {!sectionData.credentials.isLoading && totalPages > 0 && (
            <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
          )}
        </Box>
      </Stack>
    </Box>
  )
}
