import axios from 'axios'
import { getStorageToken } from '../helpers/utils'
import { configWithParams } from './application'

const { REACT_APP_INTRANET_API_V2, REACT_APP_MARCABLANCA_API_V2, REACT_APP_GEO_API_V2 } = process.env

export const config = {
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
}

export const configWithToken = (authToken) => {
  const tokenAux = getStorageToken()
  return ({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
  },
})}

// instances
let baseURLsection = REACT_APP_MARCABLANCA_API_V2
export const sectionInstance = axios.create({
  baseURL: baseURLsection,
})
export const iconInstance = axios.create({
  baseURL: baseURLsection,
})

// Endpoints

export const getAllCountriesEndpoint = '/api/pais'

export const getAllIconTypesEndpoint = () => '/api/iconos/'

export const getIconTypesEndpoint = '/api/iconos/'

export const getAllCustomSectionEndpoint = (token) => `/api/secciones-custom/${token}`

export const getAllDefaultSectionEndpoint = (token) => `api/secciones-default/${token}`

export const getSectionTypesEndpoint = () => '/api/tipo-secciones'

export const getSectionTypeEndpoint = '/api/tipo-secciones'

export const getAllCustomSectionsEndpoint = '/api/secciones-custom/all'

export const getCustomSectionsEndpoint = '/api/secciones-custom'

export const getAllDefaultSectionsEndpoint = '/api/secciones-default/all'

export const getDefaultSectionsEndpoint = '/api/secciones-default'

export const getCompaniesSectionsEndpoint = `/api/empresa-secciones`

export const getCustomSectionByIdEndpoint = (sectionId) => `/api/secciones-custom/${sectionId}`

export const getDefaultSectionByIdEndpoint = (sectionId) => `/api/secciones-default/${sectionId}`

export const createSectionEndpoint = '/api/secciones-custom'

export const createDefaultSectionEndpoint = '/api/secciones-default'

export const createCompanySectionsEndpoint = '/api/empresa-secciones/'

export const updateSupplierEndpoint = (supplierId) => `/api/proveedores/${supplierId}`

export const updateDefaultSectionEndpoint = (sectionId) => `/api/secciones-default/${sectionId}`

export const updateCustomSectionEndpoint = (sectionId) => `/api/secciones-custom/${sectionId}`

export const updateCompanySectionsEndpoint = (sectionId) => `/api/empresa-secciones/${sectionId}`

export const getCompSecByIdEndpoint = (compSecId) => `/api/categoria-detalle/${compSecId}`

export const deleteCustomSectionEndpoint = (sectionId) => `/api/secciones-custom/${sectionId}`

export const deleteDefaultSectionEndpoint = (sectionId) => `/api/secciones-default/${sectionId}`

export const deleteCompanySectionEndpoint = (appId) => `/api/empresa-secciones/${appId}`

// Requests

export const getIcons = () =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getIconTypesEndpoint}`, configWithToken())

export const getSectionTypes = () =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getSectionTypeEndpoint}`, configWithToken())

export const updateSupplier = (id, token) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${updateSupplierEndpoint(id)}`, configWithToken(token))

export const getAllCountries = (token) => axios.get(`${REACT_APP_GEO_API_V2}${getAllCountriesEndpoint}`, configWithToken(token))

export const getCustomSections = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCustomSectionsEndpoint}`, configWithParams(data))

export const getDefaultSections = (countryId, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getDefaultSectionsEndpoint}`, configWithParams(countryId, authToken))

export const getAllCustomSections = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllCustomSectionsEndpoint}`, configWithParams(data))

export const getAllDefaultSections = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllDefaultSectionsEndpoint}`, configWithParams(data))

export const getCompaniesSections = (data) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCompaniesSectionsEndpoint}`, configWithParams(data))

export const createSection = (data, authToken) =>
  axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createSectionEndpoint}`, data, configWithToken(authToken))

export const createDefaultSection = (data, authToken) =>
  axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createDefaultSectionEndpoint}`, data, configWithToken(authToken))

export const createCompanySections = (data, authToken) =>
  axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createCompanySectionsEndpoint}`, data, configWithToken(authToken))

export const getCustomSectionById = (sectionId, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCustomSectionByIdEndpoint(sectionId)}`, configWithToken(authToken))

export const getDefaultSectionById = (sectionId, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getDefaultSectionByIdEndpoint(sectionId)}`, configWithToken(authToken))

export const UpdateDefaultSection = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateDefaultSectionEndpoint(sectionId)}`, data, configWithToken(authToken))

export const UpdateCustomSection = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateCustomSectionEndpoint(sectionId)}`, data, configWithToken(authToken))

export const UpdateCompanySections = (sectionId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateCompanySectionsEndpoint(sectionId)}`, data, configWithToken(authToken))

export const DeleteCustomSection = (sectionId, authToken) =>
  axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteCustomSectionEndpoint(sectionId)}`, configWithToken(authToken))

export const DeleteDefaultSection = (sectionId, authToken) =>
  axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteDefaultSectionEndpoint(sectionId)}`, configWithToken(authToken))

export const DeleteCompanySection = (sectionId, authToken) =>
  axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteCompanySectionEndpoint(sectionId)}`, configWithToken(authToken))

export const getCompSecById = (compSecId, configAuth) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCompSecByIdEndpoint(compSecId)}`, configAuth)
