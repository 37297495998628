import React, { useState } from 'react'
import { PageContainer } from '../../common/helpers'
import { Heading, Button, Box, useDisclosure } from '@chakra-ui/core'
import ModalSimple from '../../common/modal-simple'
import List from './list/index'
import CreateApplicationHandle from './create'
import { useTranslation } from 'react-i18next'

export default function Apps() {
  const { t } = useTranslation(['common'])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [addRecordSuccess, setAddRecordSuccess] = useState(false)

  return (
    <>
      <ModalSimple
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        title={`${t('common:new')} ${t('common:navbar.applications')}`}
        size="4xl"
      >
        <CreateApplicationHandle onFinish={onClose} updateList={{ addRecordSuccess, setAddRecordSuccess }} />
      </ModalSimple>
      <PageContainer>
        <Box backgroundColor="" py="16px" color="#103059">
          <Heading style={{ fontSize: '1.8rem' }} mb={0}>
            {t('common:agency_configuration')}
          </Heading>
        </Box>
        <Button my={5} variantColor="blue" onClick={onOpen} type="submit">
          {t('common:createApplication')}
        </Button>
        <List addRecordSuccess={addRecordSuccess} />
      </PageContainer>
    </>
  )
}
