import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { persistStore } from "redux-persist";
import reducers from "./reducers/index-reducers";

const enhancer = composeWithDevTools(applyMiddleware(thunkMiddleware));

const store = createStore(reducers, enhancer);

const persistor = persistStore(store);

export { store, persistor };