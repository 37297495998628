import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createRubro, getAllCountries } from '../../../api/application'
import { Stack, Box, FormLabel, SimpleGrid, Button, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import FormInput from '../../forms/form-input'
import { isSuccess } from '../../../helpers/utils'
import SelectAsync from '../../common/select-async'
import { getCountriesState } from '../../../redux/selectors'
import { useSelector } from 'react-redux'

export default function CreateRubroForm({ onFinish }) {
  const { t } = useTranslation(['forms', 'applications', 'login', 'common', 'forms'])
  const [submitBlock, setSubmitBlock] = useState(false)
  const [countrySelected, setCountrySelected] = useState(true)
  const { handleSubmit, register, control } = useForm({})
  const toast = useToast()
  const [paisId, setPaisId] = useState('')
  const countries = useSelector(getCountriesState)

  const submitRubro = async data => {
    try {
      const response = await createRubro(data)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
      onFinish()
    } catch (error) {
      if (error.response) {
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  const submit = async data => {
    setSubmitBlock(true)
    await submitRubro(data)
    setSubmitBlock(false)
  }

  const handleChange = (newValue, onChange) => {
    onChange(newValue)
    setPaisId(newValue)
    setCountrySelected(false)
  }

  return (
    <form style={{ display: 'grid' }} autoComplete='off' onSubmit={handleSubmit(submit)}>
      <Stack autoComplete='off'>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
          <Box>
            <FormLabel htmlFor='pais_id' isRequired>
              {t('forms:country')}
            </FormLabel>
            <Controller
              control={control}
              name='paisId'
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => handleChange(e.target.value, onChange)}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={paisId}
                  isRequired
                >
                  {countries
                    ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
                    .map(({ id, nombre }) => (
                      <option key={id} value={id}>
                        {nombre}
                      </option>
                    ))}
                </SelectAsync>
              )}
            />
          </Box>
          <FormInput
            isRequired
            name='nombre'
            label={t('forms:name')}
            isDisabled={countrySelected}
            inputProps={{
              placeholder: t('forms:name'),
              ref: register,
              maxLength: 100,
            }}
          />
          <FormInput
            isRequired
            name='descripcion'
            label={t('forms:description')}
            isDisabled={countrySelected}
            inputProps={{
              placeholder: t('forms:description'),
              ref: register,
              maxLength: 100,
            }}
          />
        </SimpleGrid>
      </Stack>
      <Button
        style={{ width: 'fit-content', justifySelf: 'end' }}
        mt={6}
        variantColor='blue'
        variant='outline'
        type='submit'
        isLoading={submitBlock}
      >
        {t('common:create')}
      </Button>
    </form>
  )
}
