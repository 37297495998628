import { Box, Button, Grid, Select, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS } from './impuesto-section-data'
import FormInput from '../../forms/form-input'
import { getAllCountries } from '../../../api/application'
import { toastCustomizedError } from '../../common/toast-default-config'
import SelectAsync from '../../common/select-async'
import { getUserCountryId, getUserRolId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'

export default function ImpuestoSearchForm({ onFilter, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const { t } = useTranslation(['common', 'forms', 'consultations', 'comunications', 'packages'])
  const toast = useToast()
  const rolId = useSelector(getUserRolId)
  const permisosUserCountry = rolId == 2 ? true : false
  const userCountryId = useSelector(getUserCountryId)
  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
  })

  const enumOptions = [
    { label: t('packages:PORCENTAGE'), value: 'PORCENTAGE' },
    { label: t('forms:amount'), value: 'FIXED_AMOUNT' },
  ]

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries()
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const resetForm = () => {
    if(permisosUserCountry) {
      filters.taxName= ''
      filters.taxType= ''
      filters.countryId= userCountryId
      onFilter()
    }else{
      handleResetFilters()
    }
  }

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} gap={6} {...restProps}>
      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.taxName, e.target.value)}
        value={filters.taxName}
        name='nombre'
        inputProps={{
          placeholder: `${t('forms:name')}`,
        }}
      />

      <Box>
        <Select
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.taxType, target.value)
          }}
          placeholder={`${t('comunications:type')}`}
          value={filters.taxType}
        >
          {enumOptions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </Box>

    {!permisosUserCountry && <Box>
        <SelectAsync
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.countryId, target.value)
          }}
          placeholder={`${t('forms:countries')} - ${t('forms:all')}`}
          value={filters.countryId}
          isLoading={countries.isLoading}
        >
          {countries.data
            .sort((a, b) => a.nombre.localeCompare(b.nombre))
            .map(({ id, nombre }) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
        </SelectAsync>
      </Box>}
      <Box style={{ display: 'flex', gap: '1rem' }}>
        <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>

        <Button variantColor='blue' variant='outline' onClick={resetForm}>
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
