import axios from 'axios'
import { setInterceptors } from '../../helpers/interceptors'
import { getStorageToken } from '../../helpers/utils'

export const config = {
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
}

export const configWithAuth = () => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
  }
}

export const configWithParams = data => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
    params: data,
  }
}

const { REACT_APP_INTRANET_API_V2 } = process.env

export const packageInstance = axios.create({
  baseURL: process.env.REACT_APP_PACKAGES_REGIONAL,
})

// Endpoints

export const proveedoresEndpoint = '/api/proveedores'

export const officeEndpoint = '/api/oficina'

export const contactEndpoint = '/api/contacto'

export const agreementEndpoint = '/api/acuerdo'

export const allAgreementEndpoint = '/api/acuerdo/all'

export const categoriaEndpoint = '/api/categoria'

export const categoriaEndpointPorProveedor = '/api/categoria/proveedores'

export const hotelesEndpoint = '/api/hoteles'

export const hotelNamesEndpoint = `${hotelesEndpoint}/hotelname`

// Paquetes - ?

export const getAllSuppliersWithPackagesEndpoint = '/api/paquetes/obtener-proveedores-con-paquetes'

export const getAllPackagesByProviderEndpoint = aplicacionId =>
  `/api/paquetes/${aplicacionId ? '?applicationId='+aplicacionId : ''}`

export const getPackagesByQueryEndpoint = '/api/paquetes'

export const getPackagesByIdEndpoint = id => `/api/paquetes/${id}`

export const getTaxes = `/api/taxes`

export const createPackageEndpoint = '/api/paquetes/'

export const packageInformationEndpoint = '/api/paquetes-informacion/'

export const deletePackageEndpoint = id => `/api/paquetes/${id}`

export const deletePackageInformationEndpoint = id => `/api/paquetes-informacion/${id}`

export const updatePackageEndpoint = id => `/api/paquetes/${id}`

export const updatePackageEnableEndpoint = id => `/api/paquetes/${id}`

export const getDeletePackages = id => `/api/paquetes/${id}`

export const getIconsPackageEndpoint = '/api/iconos'

export const deletePriceInformationEndpoint = id => `/api/preciosInformacion/${id}`

// Hoteles - ?

export const getAllHotelsEndpoint = city => `/api/hoteles/destination/${city}`

export const getAllTransportsEndpoint = () => '/api/transport-types'

export const getAllTripTypesEndpoint = () => '/api/trip-types'

export const getAllBaseTypesEndpoint = () => '/api/baseTypes'

export const getAllTaxesEndpoint = () => '/api/taxes'

export const createHotelEndpoint = '/api/hoteles'

export const updateHotelEndpoint = id => `/api/hoteles/${id}`

export const imageHotelEndpoint = '/api/hoteles-imagenes/'

export const deleteHotelEndpoint = id => `/api/hoteles/${id}`

export const getHotelImagesByIdEndpoint = id => `/api/hoteles-imagenes/${id}`

export const getAllAplicationTypesEndpoint = '/api/tipos-aplicacion'

// Categories - ?

export const getAllCategoriesEndpoint = '/api/categories'

// Requests
export const getAllCategoriesOfSuppliers = () =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${categoriaEndpoint}`, configWithAuth())

export const getCategoriesOfSuppliers = paisId =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${categoriaEndpointPorProveedor}`, configWithParams({ paisId }))

export const getCategoriesPaisId = paisId =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${categoriaEndpoint}`, configWithParams({ paisId }))

export const getSuppliersByCountry = filters =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}`, configWithParams(filters))

export const getAgreements = filters =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}`, configWithParams(filters))

export const getAllAgreements = filters =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${allAgreementEndpoint}`, configWithParams(filters))

export const getProducts = filters =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}/productos`, configWithParams(filters))

export const getAgreementsRanking = filters =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}/ranking`, configWithParams(filters))

export const getAgreementsBySupplierId = proveedorId =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}`, configWithParams({ proveedorId }))

  export const getAgreementsBySupplierIdPaginated = data =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}`, configWithParams(data))

export const getContactsBySupplierId = data =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${contactEndpoint}`, configWithParams(data))

export const getOfficesBySupplierId = proveedorId =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${officeEndpoint}`, configWithParams({ proveedorId }))

export const getOfficesBySupplierIdPaginated = data =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${officeEndpoint}`, configWithParams(data))

setInterceptors(axios)
