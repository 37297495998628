import React, { useEffect, useState } from 'react'
import { SimpleGrid } from '@chakra-ui/core'
import EventsListItem from '../common/events-list-item'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/dist/css/splide.min.css'
import '../styles/events/upcomingEventsSlider.css'
import '../styles/common/custom-splide-styles.css'

const UpcomingEventsSlider = ({ events, perPage }) => {
  const [splidePages, setSplidePages] = useState([])

  const defaultOptions = {
    padding: '8px 0',
    arrows: false,
    classes: {
      pagination: 'splide__pagination custom-splide-pagination',
    },
  }

  const buildSplidePages = () => {
    if (!events) return

    if (events.length <= 6) return [events]

    let pagesArray = Array()
    while (events.length > 0) {
      const spliceTo = events.length > perPage ? perPage : events.length

      pagesArray.push(events.splice(0, spliceTo))
    }

    return pagesArray
  }

  useEffect(() => {
    setSplidePages(buildSplidePages())
  }, [])

  return (
    <Splide aria-label='Carrusel de eventos futuros' options={defaultOptions}>
      {splidePages.map((pageData, index) => {
        return <SplideSlideElement key={index} slideData={pageData} />
      })}
    </Splide>
  )
}

const SplideSlideElement = ({ slideData }) => {
  return (
    <SplideSlide aria-label='Varios eventos futuros'>
      <SimpleGrid columns={2} rowGap={'1rem'} columnGap={'1rem'} py={8}>
        {slideData.map(event => (
          <EventsListItem key={event.id} data={event} />
        ))}
      </SimpleGrid>
    </SplideSlide>
  )
}

export default UpcomingEventsSlider;
