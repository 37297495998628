import React, { useEffect, useState } from 'react'
import { getUsersById } from '../../../../api/application'
import { Box, PseudoBox, Spinner } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { Panel } from '../../../common/helpers'
import { getFullUser } from '../../../../redux/selectors'
import { useForm } from 'react-hook-form'
import EditUser from '../users/edit-user'

export default function MyAccount() {
  const userInfo = useSelector(getFullUser)
  const formData = userInfo
  const [user, setUser] = useState({
    data: {},
    isLoading: false,
    error: [],
  })

  const { handleSubmit } = useForm({
    defaultValues: formData,
  })

  async function submit(data) {
    console.log('')
  }

  const getUser = async (reset = false) => {
    setUser({
      data: {},
      isLoading: true,
    })

    try {
      let response
      response = await getUsersById(userInfo?.information?.id)

      setUser({
        data: response.data || {},
        isLoading: false,
        error: [],
      })
    } catch (error) {
      setUser({
        data: {},
        isLoading: false,
        error: error,
      })
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.information?.id) {
      getUser()
    }
  }, [userInfo])

  return (
    <Panel rounded='md' style={{ width: 'fit-content' }}>
      <PseudoBox as='form' onSubmit={handleSubmit(submit)}>
        {user.isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!user.isLoading && Object.keys(user.data).length && (
          <EditUser onFinish={() => getUser()} dataUser={user.data} isMyAccount={true} />
        )}
      </PseudoBox>
    </Panel>
  )
}
