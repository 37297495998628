import React, { useEffect, useMemo, useState } from 'react'
import { Box, Heading, Button, Grid } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAuthToken, getUserCountryId } from '../../../redux/selectors'
import { getRegistrosTipoCambio } from '../../../api/application'
import Table from '../../common/table'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { PageContainer, Panel } from '../../common/helpers'
import FormInput from '../../forms/form-input'
import Pagination from '../../common/pagination'

export const ExchangeAdmin = () => {
  const { t } = useTranslation(['common', 'forms', 'exchange', 'consultations', 'comunications'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const [registros, setRegistros] = useState([])
  const [nombre, setNombre] = useState('')
  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: null,
    endDate: null,
  })
  const [page, setPage] = useState(1)
  const maxPerPage = 15
  const [totalPages, setTotalPages] = useState(0)
  const [focusedInput, setFocusedPicker] = useState(null)

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
      }))
    }
  }, [startDate, endDate])

  async function getRegistros(data) {
    try {
      let res
      if (data) {
        res = await getRegistrosTipoCambio({ ...data, maxPerPage }, authToken)
      } else {
        res = await getRegistrosTipoCambio({ paisId: countryId, closeDay: true, maxPerPage, page }, authToken)
      }
      if (res) {
        setTotalPages(res.data.totalPages)
        setRegistros(res.data.data)
      }
    } catch (error) {
      if (error.message) {
        console.log(error.message)
      }
    }
  }

  async function filterList() {
    const data = {
      paisId: countryId,
      startDate: startDate ? moment(startDate).startOf('d').toDate() : null,
      endDate: endDate ? moment(endDate).endOf('d').toDate() : null,
      nombre,
    }

    getRegistros(data)
  }

  async function cleanFilters() {
    setNombre('')
    setRangePicker({
      startDate: null,
      endDate: null,
    })
    getRegistros()
  }

  useEffect(() => {
    getRegistros()
  }, [page])

  const registerColumns = useMemo(() => {
    const col = [
      {
        Header: t('common:Id'),
        accessor: 'id',
      },
      {
        Header: t('consultations:date'),
        accessor: 'fecha',
        Cell: ({ cell }) => {
          const formattedDate = moment.utc(cell.row.values.fecha).format('DD/MM/YYYY')
          return formattedDate
        },
      },
      {
        Header: t('forms:supplier'),
        accessor: 'proveedor.nombre',
      },
      {
        Header: t('exchange:dolar'),
        accessor: 'dolar',
      },
      {
        Header: t('exchange:euro'),
        accessor: 'euro',
      },
    ]
    return col
  }, [])

  return (
    <PageContainer style={{ padding: '1.2rem' }}>
      <Box>
        <Heading style={{ fontSize: '1.8rem', color: '#103059', marginBottom: '2rem' }}>
          {t('exchange:changeType')}
        </Heading>
        <Panel rounded='md' mb='1.5rem'>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} gap={6}>
            <DateRangePicker
              showDefaultInputIcon={true}
              isOutsideRange={() => false}
              required
              startDate={startDate}
              startDateId='fechaInicio'
              endDate={endDate}
              endDateId='fechaFin'
              focusedInput={focusedInput}
              onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
              onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
              small
              orientation='horizontal'
              displayFormat='DD/MM/YYYY'
            />

            <Box>
              <FormInput
                inputProps={{
                  type: 'text',
                  placeholder: `${t('forms:name')} ${t('forms:supplier')}`,
                }}
                id='nombre'
                name='nombre'
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              ></FormInput>
            </Box>
            <Box>
              <Button mr={4} onClick={filterList} variantColor='blue' variant='outline' type='submit'>
                {t('common:filter')}
              </Button>
              <Button
                flex='2'
                variant='outline'
                variantColor='blue'
                type='button'
                onClick={() => {
                  cleanFilters()
                }}
              >
                {t('comunications:cleanFilter')}
              </Button>
            </Box>
          </Grid>
        </Panel>
        <Table columns={registerColumns} data={registros} />
        {totalPages ? <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} /> : ''}
      </Box>
    </PageContainer>
  )
}