import { useState, useEffect } from "react";

export default function useFetch({ api, method, url, data = null, config = null}) {
	const [response, setResponse] = useState(null);
	const [error, setError] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		//console.log('PARAMETRO',url, config)
		const fetchData = async () => {
			try {
				if (method === "post") {
					api[method](url, data, config)
						.then((res) => {
							setResponse(res.data);
						})
						.finally(() => {
							setIsLoading(false);
						});

				} else {
					api[method](url, config, data)
						.then((res) => {
							//console.log('RES',res.data)
							setResponse(res.data);
						})
						.finally(() => {
							setIsLoading(false);
						});
					
				}
			} catch (err) {
				//console.log('ERROR',error)
				setError(err);
			}
		};
		fetchData();
	}, [api, method, url, data, config]);

	return { response, error, isLoading };
}