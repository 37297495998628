import axios from 'axios'
import { getStorageToken } from '../helpers/utils'

const { REACT_APP_INTRANET_API_V2 } = process.env

export const config = {
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
}

export const configWithToken = authToken => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
  }
}

export const configWithParams = params => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
    params,
  }
}

export const supplierEndpoint = '/api/supplier'
export const groupEndpoint = '/api/group'

export const getAllSupplier = () =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${supplierEndpoint}`, configWithToken())

export const getAllSupplierByGroup = () =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${supplierEndpoint}/group`, configWithToken())

export const getGroupPaginated = (data) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${groupEndpoint}`, configWithParams(data))

export const createGroup = (data) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${groupEndpoint}`, data, configWithToken())

export const updateGroup = (id, data) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${groupEndpoint}/${id}`, data, configWithToken())

export const deleteGroup = id =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${groupEndpoint}/${id}`, configWithToken())