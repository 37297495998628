import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Stack, Box, FormLabel, SimpleGrid, Button, useToast, Checkbox } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import FormInput from '../../forms/form-input'
import { isSuccess } from '../../../helpers/utils'
import SelectAsync from '../../common/select-async'
import { getAuthToken, getCountriesState } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { getProvinciasByPaisId, postLocalidad } from '../../../api/geo'

export default function CreateLocalidadForm({ onFinish }) {
  const { t } = useTranslation(['forms', 'applications', 'login', 'common'])
  const [submitBlock, setSubmitBlock] = useState(false)
  const authToken = useSelector(getAuthToken)
  const { handleSubmit, register, control } = useForm({})
  const toast = useToast()
  const [paisId, setPaisId] = useState('')
  const countries = useSelector(getCountriesState)
  const [provinciaId, setProvinciaId] = useState('')
  const [isOrigin, setIsOrigin] = useState(false)
  const [isDestination, setIsDestination] = useState(false)
  const [province, setProvince] = useState({
    data: [],
    isLoading: true,
  })

  const submitLocalidad = async data => {
    try {
      const transformedData = {
        ...data,
        isDestination,
        isOrigin,
        latitud: +data.latitud !== 0 ? +data.latitud : null,
        longitud: +data.longitud !== 0 ? +data.longitud : null,
        provincia: {
          id: parseInt(data.provinciaId),
        },
      }
      delete transformedData.provinciaId
      const response = await postLocalidad(transformedData, authToken)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
      onFinish()
    } catch (error) {
      if (error.response) {
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  const submit = async data => {
    setSubmitBlock(true)
    await submitLocalidad(data)
    setSubmitBlock(false)
  }

  const getProvincias = async () => {
    setProvince(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getProvinciasByPaisId({ paisId })
      setProvince(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setProvince(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getProvincias(paisId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paisId])

  return (
    <form style={{ display: 'grid' }} autoComplete='off' onSubmit={handleSubmit(submit)}>
      <Stack autoComplete='off'>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
          <Box>
            <FormLabel htmlFor='pais_id' isRequired>
              {t('forms:country')}
            </FormLabel>
            <Controller
              control={control}
              name='paisId'
              render={({ name }) => (
                <SelectAsync
                  onChange={e => setPaisId(e.target.value)}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={paisId}
                  isRequired
                >
                  {countries
                    ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
                    .map(({ id, nombre }) => (
                      <option key={id} value={id}>
                        {nombre}
                      </option>
                    ))}
                </SelectAsync>
              )}
            />
          </Box>
          <Box>
            <FormLabel htmlFor='provincia_id' isRequired>
              {t('forms:province')}
            </FormLabel>
            <Controller
              control={control}
              name='provinciaId'
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => {
                    const newValue = e.target.value
                    setProvinciaId(newValue)
                    onChange(newValue)
                  }}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={provinciaId}
                  isRequired
                  isDisabled={!paisId}
                >
                  {province?.data
                    ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
                    .map(({ id, nombre }) => (
                      <option key={id} value={id}>
                        {nombre}
                      </option>
                    ))}
                </SelectAsync>
              )}
            />
          </Box>
          <FormInput
            isRequired
            name='nombre'
            label={t('forms:location')}
            isDisabled={!provinciaId}
            inputProps={{
              placeholder: t('forms:location'),
              ref: register,
              maxLength: 100,
            }}
          />
          {/* LATITUD */}
          <FormInput
            name="latitud"
            label={t('forms:latitude')}
            inputProps={{
              type: 'tel',
              placeholder: t('forms:latitude'),
              ref: register,
            }}
          />

          {/* LONGITUD */}
          <FormInput
            name="longitud"
            label={t('forms:length')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:length'),
              ref: register,
            }}
          />
          <Box d='flex' alignItems='center'>
            <Box alignSelf='center'>
              <FormLabel htmlFor='isOrigin'>{t('forms:origin')}</FormLabel>
              <Checkbox isDisabled={!provinciaId} size='lg' onChange={() => setIsOrigin(!isOrigin)} />
            </Box>
            <Box alignSelf='center' style={{ marginLeft: '20px' }}>
              <FormLabel htmlFor='isDestination'>{t('forms:destino')}</FormLabel>
              <Checkbox isDisabled={!provinciaId} size='lg'  onChange={() => setIsDestination(!isDestination)} />
            </Box>
          </Box>
        </SimpleGrid>
      </Stack>
      <Button
        style={{ width: 'fit-content', justifySelf: 'end' }}
        mt={6}
        variantColor='blue'
        variant='outline'
        type='submit'
        isLoading={submitBlock}
      >
        {t('common:create')}
      </Button>
    </form>
  )
}
