import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys'
import { handleLogout, logOutBrowser } from './commons'

let isRefreshing = false // Variable para bloquear
let arrayRefreshQuerys = [] // Array para almacenar las solicitudes pendientes

export const setInterceptors = api => {
  api.interceptors.response.use(
    response => {
      return response
    },
    async error => {
      if (error.response?.status === 401) {
        if (error.request.responseURL.indexOf('refresh') === error.request.responseURL.indexOf('login')) {
          const originalRequest = error.config

          if (!isRefreshing) {
            isRefreshing = true

            try {
              const refreshToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
              const { data } = await api.post(
                process.env.REACT_APP_APPLICATIONS_API_V2 + '/api/auth/login/refresh',
                {},
                {
                  headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${refreshToken}`,
                  },
                },
              )

              window.localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, data.token)
              window.localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken)
              originalRequest.headers.Authorization = `Bearer ${data.token}`

              // Ejecutar las solicitudes pendientes con el nuevo token
              arrayRefreshQuerys.forEach(requestQueue => requestQueue(data.token))
              arrayRefreshQuerys = []
              return api.request(originalRequest)
            } catch (refreshError) {
              if (refreshError.response?.status !== 401 && refreshError.response?.status !== 403) {
                handleLogout()
              } else {
                logOutBrowser()
              }
              return Promise.reject(error)
            } finally {
              isRefreshing = false
            }
          } else {
            // Si se está realizando un refresco de token, agrego la solicitud a las solicitudes pendientes
            return new Promise(resolve => {
              arrayRefreshQuerys.push(token => {
                originalRequest.headers.Authorization = `Bearer ${token}`
                resolve(api.request(originalRequest))
              })
            })
          }
        } else {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    },
  )
  return api
}
