import React from 'react'
import TypeForm from '../../../forms/web-information/type'
import { getAuthToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { updateLinkType } from '../../../../api/web-information'
import { isSuccess } from '../../../../helpers/utils'

export default function CreateType({ getLinkType, onClose, ...restProps }) {
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()

  const handleSubmit = async data => {
    try {
      const response = await updateLinkType(restProps.idSectionn, data, authToken)
      if (isSuccess(response.status)) {
        getLinkType()
        onClose()
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  return (
    <div>
      <TypeForm onSubmit={handleSubmit} buttonLabel={t('common:create')} idSectionn={restProps.idSectionn} />
    </div>
  )
}
