import { Box, Stack, useDisclosure } from '@chakra-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createOfficeSupplier, deleteOfficesBySupplierId, updateOfficeById } from '../../../../api/intranet/requests'
import { getOffices } from '../../../../redux/actions/suppliers-actions'
import { getAuthToken, getOfficesState } from '../../../../redux/selectors'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import { Panel } from '../../../common/helpers'
import ModalSimple from '../../../common/modal-simple'
import Table from '../../../common/table'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import SupplierOfficesForm from '../../../forms/suppliers/supplier-offices-form'
import { useAsyncToastNotification, useAsyncStateWithAction, usePermission } from '../../../hooks'
import Pagination from '../../../common/pagination'

export default function SupplierOffices() {
  const { t } = useTranslation(['suppliers', 'common', 'forms'])
  const { id: supplierId } = useParams()
  const authToken = useSelector(getAuthToken)
  const [dataEditing, setDataEditing] = useState(false)
  const maxPerPage = 12
  const [page, setPage] = useState(1)
  const dispatchedGetOffices = dispatch => dispatch(getOffices(supplierId, maxPerPage, page))
  const { error, isLoading, items, update } = useAsyncStateWithAction(dispatchedGetOffices, getOfficesState)

  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const { addPermissions } = usePermission()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })
  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))

  const columns = useMemo(() => {
    function onClickEdit(data) {
      setDataEditing(data)
      onOpen()
    }
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:address'),
        accessor: 'direccion',
      },
      {
        Header: t('suppliers:attention_time'),
        accessor: 'horarioAtencion',
      },
      {
        Header: 'Web',
        accessor: 'web',
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => (
          <Stack spacing={2} isInline>
            <ButtonEdit onClick={() => onClickEdit(cell.row.original)} />
            <ButtonDelete onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ]
  }, [onOpen, t])

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      show: true,
      data,
    }))
  }

  async function updateOffice(formData, officeId) {
    const data = addPermissions(
      {
        ...formData,
        activo: true,
        proveedor: { id: parseInt(supplierId) },
      },
      'proveedores:editar',
    )

    return await updateOfficeById(data, officeId, authToken)
  }

  async function onSubmit(data, e) {
    await asyncNotificationWrapper(async () => {
      const d = {
        ...data,
        activo: true,
        proveedor: { id: parseInt(supplierId) },
      }

      const body = addPermissions(d, 'proveedores:editar')
      const res = await createOfficeSupplier(body, authToken)

      if (res.status) {
        e.target.reset()
        update()
      }

      return res.status
    })
  }

  async function onSubmitUpdate(formData, e) {
    await asyncNotificationWrapper(async () => {
      const data = { ...formData, activo: true, proveedor: { id: parseInt(supplierId) } }
      const res = await updateOffice(data, dataEditing.id)
      if (res.status) {
        e.target.reset()
        update()
      }

      onClose()
      return res.status
    })
  }

  async function onDelete() {
    const formData = { ...deleteAlert.data, activo: false, proveedor: { id: parseInt(supplierId) } }
    await asyncNotificationWrapper(async () => {
      await deleteOfficesBySupplierId(formData, formData.id)
      return true
    })
    update()
    onCloseDeleteAlert()
    setPage(1)
  }

  const deleteString = `${t('common:delete')} ${t('suppliers:office').toLowerCase()}`

  useEffect(() => {
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  return (
    <Box>
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        title={deleteString}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && ` ${t('suppliers:office')} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>
      <ModalSimple title={t('common:edit')} size='800px' onClose={onClose} isOpen={isOpen}>
        <SupplierOfficesForm key='edit-form' formData={dataEditing} onSubmit={onSubmitUpdate} />
      </ModalSimple>
      <Stack spacing='20px'>
        <Panel>
          <SupplierOfficesForm key='create-form' onSubmit={onSubmit} />
        </Panel>
        {!isLoading && items?.data.length !== 0 ? (
          <>
            <Table columns={columns} data={items?.data} />
          </>
        ) : (
          ''
        )}
      </Stack>
      {!isLoading && items?.totalPages !== 0 && (
        <Pagination currentPage={page} setPage={setPage} totalPages={items?.totalPages} />
      )}
    </Box>
  )
}
