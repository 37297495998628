import React from "react";
import { Box } from "@chakra-ui/core";

export function Container({children, ...rest}) {
	return (
		<Box maxWidth="1540px" margin="auto" pr="12px" pl="16px" {...rest}>
			{children}
		</Box>
	);
}

export function PageContainer({children, ...rest}) {
	return (
		<Box width="full" {...rest}>
			<Container>
				{children}
			</Container>
		</Box>
	);
}

export function Panel({children, ...rest}) {
	return (
		<Box bg="white" p={5} shadow="md" borderWidth="1px" {...rest}>
			{children}
		</Box>
	);
}