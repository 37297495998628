import React from 'react'
import OwnSectionForm from '../../../forms/sections/own-section'
import { getAuthToken, getToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { deconstructPermission } from '../../../../helpers/permissions-functions'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { UpdateCustomSection } from '../../../../api/section'
import { CUSTOM_SECTIONS_CREATE } from '../../../../helpers/permissions'

export default function CreateOwnSection({ onFinish = () => null, getSection, onClose, ...restProps }) {
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()

  const handleSubmit = async formData => {
    let sectionId = restProps.idSectionn
    try {
      const sesion = {
        token,
        ...deconstructPermission(CUSTOM_SECTIONS_CREATE),
      }
      const data = { sesion, ...formData }
      const response = await UpdateCustomSection(sectionId, data, authToken)
      getSection()
      onClose()
      toast(
        response.status
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    onFinish()
  }

  return (
    <div>
      <OwnSectionForm onSubmit={handleSubmit} buttonLabel={t('common:create')} idSectionn={restProps.idSectionn} />
    </div>
  )
}
