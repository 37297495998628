import axios from 'axios'
import { configWithAuth } from '..'

//Endpoints
export const getTemplateByIdEndpoint = (id) => `/api/templates/${id}`
export const getActiveTemplateEndpoint = (id) => `/api/empresa-template?empresaId=${id}&activo=true`
export const getAllTemplatesEndpoint = '/api/templates'
export const updateTemplateEndpoint = (id) => `/api/templates/${id}`
export const updateEmpresaTemplateEndpoint = '/api/empresa-template/'
export const createTemplateEndpoint = '/api/templates/'
export const getDefaultTemplateIdEndpoint = '/api/ubicacion-componente-template/default'
export const disabledLinksEndpoint = '/api/desactivarEnlace'
export const defaultEnlaceEndpoint = '/api/defaultEnlace'
export const getPackageByIdEndpoint =  `/api/package/numPackage`

const { REACT_APP_MARCABLANCA_API_V2, REACT_APP_PACKAGE_PROVIDERS } = process.env
//Request
export const getTemplateById = (empresaId, authToken) => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getTemplateByIdEndpoint(empresaId)}`, configWithAuth(authToken))
}

export const getActiveTemplate = (empresaId, authToken, paisId = null) => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getActiveTemplateEndpoint(empresaId)}${paisId ? '&paisId='+paisId : ''}`, configWithAuth(authToken))
}

export const getAllTemplates = (authToken, paisId = null) => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllTemplatesEndpoint}${paisId ? '?paisId='+paisId : ''}`, configWithAuth(authToken))
}

export const getDefaultTemplateId = (authToken) => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getDefaultTemplateIdEndpoint}`, configWithAuth(authToken))
}

export const createTemplate = (data, authToken) => {
  return axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createTemplateEndpoint}`, data, configWithAuth(authToken))
}

export const updateTemplate = (templateId, data, authToken) => axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateTemplateEndpoint(templateId)}`, data, configWithAuth(authToken))

export const updateEmpresaTemplate = (data, authToken) => axios.post(`${REACT_APP_MARCABLANCA_API_V2}${updateEmpresaTemplateEndpoint}`, data, configWithAuth(authToken))

export const getDisabledLinksByParams = (data, authToken) => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${disabledLinksEndpoint}?paisId=${data.paisId}&agenciaId=${data.agenciaId}`, configWithAuth(authToken))
}

export const getDefaultEnlaceByParams = (data, authToken) => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${defaultEnlaceEndpoint}?paisId=${data.paisId}`, configWithAuth(authToken))
}

export const createDisabledLink = (data, authToken) => {
  return axios.post(`${REACT_APP_MARCABLANCA_API_V2}${disabledLinksEndpoint}`, data, configWithAuth(authToken))
}

export const deleteDisabledLink = (appId, authToken) =>
  axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${disabledLinksEndpoint}/${appId}`, configWithAuth(authToken))

export const updateDisabledLink = (id, data, authToken) => axios.put(`${REACT_APP_MARCABLANCA_API_V2}${disabledLinksEndpoint}/${id}`, data, configWithAuth(authToken))

export const getPackageById = (packageIdId, authToken) => {
  return axios.get(`${REACT_APP_PACKAGE_PROVIDERS}${getPackageByIdEndpoint}?numeroPaquete=${packageIdId}`, configWithAuth(authToken))
}


