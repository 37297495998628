import { Box, Button, FormLabel, PseudoBox, Grid, useToast } from '@chakra-ui/core'
import React, { useMemo, useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAllCountries } from '../../../api/application'
import { SUPPLIERS_CRUD_SELECT_COUNTRY } from '../../../helpers/permissions'
import { getUserApplication, getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import { toastCustomizedError } from '../../common/toast-default-config'
import { useFormControls, usePermissionChecker } from '../../hooks'
import FormInput from '../form-input'
import ImageInput from '../../common/image-input'
import { getCategoriesPaisId } from '../../../api/intranet/index'
import { Checkbox } from '@material-ui/core'

export default function SupplierForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['forms', 'suppliers', 'applications'])
  const userApplication = useSelector(getUserApplication)
  const { canDoThis } = usePermissionChecker()
  const { isSubmitBlocked, submitWrapper } = useFormControls()
  const toast = useToast()
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const [countrySelected, setCountrySelected] = useState(true)
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId == 2 ? true : false
  const permisosUser = userRolId == 1 ? true : false
  const [paisId, setPaisId] = useState('')

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: formData,
  })

  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
  })
  const [categories, setCategories] = useState({
    data: [],
    isLoading: true,
  })

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries(authToken)
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getCategoriesPorPaisId = async id => {
    setCategories(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getCategoriesPaisId(id ? id : userCountryId)
      setCategories(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getCountries()
    getCategoriesPorPaisId()
    if (permisosUserCountry) setCountrySelected(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countrySelectorConfig = useMemo(() => {
    const formDataOrUseDafult = defaultValue => (formData ? formData['pais_id'] : defaultValue)
    return canDoThis(
      SUPPLIERS_CRUD_SELECT_COUNTRY,
      () => ({
        isHidden: false,
        defaultValue: formDataOrUseDafult(0),
      }),
      () => ({
        isHidden: true,
        defaultValue: formDataOrUseDafult(userApplication.paisId),
      }),
    )
  }, [canDoThis, formData, userApplication.paisId])

  function submit(data, e) {
    data.paisId = parseInt(data.paisId)
    data.categoria.id = parseInt(data.categoria.id)
    data.infoAdicional = formData ? formData.infoAdicional : ''
    submitWrapper(
      async () =>
        await onSubmit(
          {
            ...data,
            activo: true,
            tipoAplicacionId: 1,
          },
          e,
        ),
    )
  }

  const handleChange = (newValue, onChange) => {
    onChange(newValue)
    setPaisId(newValue)
    getCategoriesPorPaisId(newValue)
    setCountrySelected(false)
  }

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(submit)} {...restProps}>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
        <FormInput
          isRequired
          name='nombre'
          label={t('forms:name')}
          inputProps={{
            placeholder: t('forms:name'),
            ref: register,
            type: 'text',
          }}
        />
        <ImageInput
          isRequired
          name='imagen'
          label={t('forms:logo')}
          setValue={setValue}
          inputProps={{
            placeholder: `URL ${t('forms:logo')}`,
            ref: register,
            type: 'text',
          }}
        />
        <Box>
          <FormLabel htmlFor='pais_id' isRequired>
            {t('forms:country')}
          </FormLabel>
          <Controller
            control={control}
            name='paisId'
            defaultValue={countrySelectorConfig.defaultValue}
            render={({ onChange, value, name }) => (
              <SelectAsync
                // onChange={e => onChange(e.target.value)}
                onChange={e => handleChange(e.target.value, onChange)}
                placeholder={t('forms:selectOption')}
                name={name}
                value={permisosUser ? paisId : userCountryId}
                isLoading={countries.isLoading}
                isRequired
                isDisabled={!permisosUser}
              >
                {countries.data
                  .sort((a, b) => a.nombre.localeCompare(b.nombre))
                  .map(({ id, nombre }) => (
                    <option key={id} value={id}>
                      {nombre}
                    </option>
                  ))}
              </SelectAsync>
            )}
          />
        </Box>
        <Box>
          <FormLabel htmlFor='categoria_id' isRequired>
            {t('forms:category')}
          </FormLabel>
          <SelectAsync
            name='categoria.id'
            isLoading={categories.isLoading}
            placeholder={t('forms:selectOption')}
            nativeProps={{ ref: register }}
            isRequired
            isDisabled={countrySelected}
          >
            {categories.data.map(({ id, nombre }) => (
              <option key={id} value={id}>
                {t(`suppliers:${nombre}`)}
              </option>
            ))}
          </SelectAsync>
        </Box>
        <Box>
          <FormInput
            isRequired
            name='dominio'
            label={t('forms:domainLabel')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:domain'),
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormInput
            isRequired
            name='cuit'
            label={t('forms:taxpayer_identification')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:taxpayer_identification'),
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormInput
            isRequired
            name='razonSocial'
            label={t('applications:businessName')}
            inputProps={{
              type: 'text',
              placeholder: t('applications:businessName'),
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormInput
            isRequired
            name='legajo'
            label={t('applications:file')}
            inputProps={{
              type: 'text',
              placeholder: t('applications:file'),
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormInput
            isRequired
            name='web'
            label='Web'
            inputProps={{
              type: 'text',
              placeholder: 'Web',
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormInput
            isRequired
            name='titular'
            label={t('applications:holder')}
            inputProps={{
              type: 'text',
              placeholder: t('applications:holder'),
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormInput
            isRequired
            name='telefono'
            label={t('applications:phone')}
            inputProps={{
              type: 'text',
              placeholder: t('applications:phone'),
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormInput
            isRequired
            name='email'
            label={t('applications:email')}
            inputProps={{
              type: 'text',
              placeholder: t('applications:email'),
              ref: register,
            }}
          />
        </Box>
        <Box>
          <FormLabel>{t('applications:visibility')}</FormLabel>
          <Box d='flex' justifyContent='left' alignItems='center'>
            <Checkbox
              style={{ padding: '.5rem .5rem .5rem 0' }}
              isRequired
              name='soloAdmin'
              inputProps={{
                type: 'checkbox',
                placeholder: t('applications:onlyAdmin'),
                ref: register,
              }}
            />
            <p>{t('applications:onlyAdmin')}</p>
          </Box>
        </Box>
      </Grid>
      <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
        {buttonLabel || t('forms:create')}
      </Button>
    </PseudoBox>
  )
}
