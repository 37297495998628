import React, { useEffect, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, IconButton, Box } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Alert, AlertIcon, Button } from '@chakra-ui/core'
import './styles.css'
import { useTranslation } from 'react-i18next'
import CustomRowTable from './CustomRowTable'

const CustomTable = ({
  expandable,
  columns,
  dataSource,
  ifEmpty = (
    <Alert status='info'>
      <AlertIcon />
      No hay datos
    </Alert>
  ),
}) => {
  const { t } = useTranslation(['common'])
  const [expandedRows, setExpandedRows] = useState([])

  const toggleRow = rowIndex => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter(index => index !== rowIndex))
    } else {
      setExpandedRows([rowIndex])
    }
  }

  return !columns?.length ? (
    process.env.NODE_ENV === 'development' ? (
      <Alert status='error'>
        <AlertIcon />
        CustomTable needs "columns" prop settings
      </Alert>
    ) : (
      ''
    )
  ) : (
    <Table className='custom-table'>
      <Thead className='custom-table__thead'>
        <Tr className='custom-table__thead__tr'>
          {columns.map(({ title, key }) => (
            <Th className='custom-table__thead__tr__th' key={`${key}-custom-table-column`}>
              {title}
            </Th>
          ))}
          {!!expandable && <Th className='custom-table__thead__tr__th' />}
        </Tr>
      </Thead>
      <Tbody className='custom-table__tbody'>
        {!dataSource?.length ? (
          <Tr className='custom-table__tbody__details-tr'>
            <Td className='custom-table__tbody__details-tr__td' colSpan={columns.length + 1}>
              {ifEmpty}
            </Td>
          </Tr>
        ) : (
          dataSource.map((row, index) => (
            <CustomRowTable
              key={`${row.id}-custom-row`}
              index={index}
              row={row}
              columns={columns}
              expandable={expandable}
              toggleRow={toggleRow}
              expandedRows={expandedRows}
            />
          ))
        )}
      </Tbody>
    </Table>
  )
}

export default CustomTable
