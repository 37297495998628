import React from 'react'
import { Box, Tooltip } from '@chakra-ui/core'
import '../styles/common/news-card.css'
import { goTo } from '../../helpers/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const NewsCard = ({ date, imgSrc, url, label, id, html }) => {
  const { t } = useTranslation(['comunications'])
  let history = useHistory()

  const onClickGoTo = (url) => {
    if(!html){
      goTo(url)
    }else {
      history.push(`/newsGEA?${id}?h`)
    }  
  }

  return (
    <Box py={8}>
      <Tooltip label={t('comunications:seeMore')} placement='top-end'>
        <div onClick={() => onClickGoTo(url)} className='news-card'>
          <div className='news-card__img' style={{ backgroundImage: `url('${imgSrc}')` }} />
          <div className='news-card__info'>
            <div className='news-card__info__date'>{date}</div>
            <p>{label}</p>
          </div>
        </div>
      </Tooltip>
    </Box>
  )
}

export default NewsCard
