import React, { useMemo, useState } from 'react'
import { Box, Spinner, Stack, Text, useDisclosure, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import CreateMenu from './create-menu'
import Table from '../../common/table'
import { ButtonDelete, ButtonEdit } from '../../forms/buttons/edit'
import { DeleteCompanySection } from '../../../api/section'
import { useSelector } from 'react-redux'
import { getToken, getAuthToken } from '../../../redux/selectors'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import ModalSimple from '../../common/modal-simple'
import AlertDialogSimple from '../../../components/common/alert-dialog-simple'
import { isSuccess } from '../../../helpers/utils'
import Pagination from '../../common/pagination'

export default function RenderTable({ getCompaniesSection, isLoading, page, setPage, totalPages, ...restProps }) {
  const { companiesSections } = restProps

  restProps.companiesSections = companiesSections.map(section => {
    const { seccionDefault, seccionCustom } = section
    let seccionId
    let esDefault
    if (seccionDefault && seccionDefault.id) {
      seccionId = seccionDefault.id
      esDefault = true
    } else if (seccionCustom && seccionCustom.id) {
      seccionId = seccionCustom.id
      esDefault = false
    } else {
      seccionId = null
    }
    return {
      ...section,
      seccionId,
      esDefault,
    }
  })

  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)

  const toast = useToast()

  //Modal vbles
  const modalDisclosure = useDisclosure()

  function renderNameSection({ seccionId, esDefault }) {
    if (seccionId === null) {
      return <Text>---</Text>
    }
    if (!esDefault) {
      let miCS = restProps.CS.find(sectionC => sectionC.id === seccionId)
      if (!miCS) {
        return <Text>Sección eliminada</Text>
      } else {
        if (miCS.descripcion > 30) {
          return <Text>{miCS.descripcion.substring(0, 30)}...</Text>
        } else {
          return <Text>{miCS.descripcion}</Text>
        }
      }
    } else {
      let miDS = restProps.DS.find(sectionD => sectionD.id === seccionId)
      if (!miDS) {
        return <Text>Sección eliminada</Text>
      } else {
        if (miDS.nombre.length > 30) {
          return <Text>{miDS.nombre.substring(0, 30)}...</Text>
        } else {
          return <Text>{miDS.nombre}</Text>
        }
      }
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:section'),
        accessor: 'seccionId',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {renderNameSection(cell.row.values)}
          </Stack>
        ),
      },
      {
        accessor: 'seccionIdPadre',
      },
      {
        Header: t('forms:orden'),
        accessor: 'orden',
      },
      {
        Header: t('common:internal'),
        accessor: 'esUrl',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.esUrl ? <Text>SI</Text> : <Text>NO</Text>}
          </Stack>
        ),
      },
      {
        Header: t('forms:is_default'),
        accessor: 'esDefault',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.esDefault ? <Text>SI</Text> : <Text>NO</Text>}
          </Stack>
        ),
      },
      {
        Header: t('forms:active'),
        accessor: 'activo',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.activo ? <Text>SI</Text> : <Text>NO</Text>}
          </Stack>
        ),
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit mb={4} onClick={() => onEditClick(cell.row.values)} />
            {!cell.row.values.esDefault && <ButtonDelete onClick={() => prepareDelete(cell.row.values)} />}
          </Stack>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t, restProps.CS, restProps.DS],
  )

  const [dataEditParams, setDataEditParams] = useState({ dataEditParams: null })

  function onEditClick(data) {
    setDataEditParams(state => ({
      ...state,
      id: data.id,
      seccionId: data.seccionId,
      seccionIdPadre: data.seccionIdPadre,
      orden: data.orden,
      activo: !!data.activo,
      esUrl: !!data.esUrl,
      esDefault: !!data.esDefault,
    }))

    modalDisclosure.onOpen()
  }

  ///Delete functions
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
    nombre: '',
  })

  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))

  //---

  function prepareDelete(data) {
    let miSection = {}
    if (data.esDefault === false) {
      miSection = restProps.CS.find(section => section.id === data.seccionId)
    } else {
      miSection = restProps.DS.find(section => section.id === data.seccionId)
    }
    if (miSection === undefined) {
      setDeleteAlert(state => ({
        ...state,
        show: true,
        data: data,
        nombre: 'Sección eliminada',
      }))
    } else {
      setDeleteAlert(state => ({
        ...state,
        show: true,
        data: data,
        nombre: miSection.nombre ? miSection.nombre : miSection.descripcion,
      }))
    }
  }

  async function onDelete() {
    let sectionId = deleteAlert.data.id
    try {
      const response = await DeleteCompanySection(sectionId, authToken)
      if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        getCompaniesSection()
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    onCloseDeleteAlert()
  }

  return (
    <Box>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>
          {deleteAlert.data && `${t('common:menu')}: ${deleteAlert.nombre.substring(0, 30)} #${deleteAlert.data.id}`}
        </b>
        <br />
      </AlertDialogSimple>

      {/* Edit modal */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${t('common:menu')}`}
        size='lg'
      >
        <CreateMenu
          onFinish={() => null}
          dataEditParams={dataEditParams}
          getCompaniesSection={getCompaniesSection}
          onClose={modalDisclosure.onClose}
          isLoadingCS={restProps.isLoadingCS}
          CS={restProps.CS}
          isLoadingDS={restProps.isLoadingDS}
          DS={restProps.DS}
          combined={restProps.combined}
        />
      </ModalSimple>

      {!restProps.isLoadingDS &&
      restProps.DS.length >= 0 &&
      !restProps.isLoadingCS &&
      restProps.CS.length >= 0 &&
      !restProps.isLoading &&
      restProps.companiesSections.length >= 0 ? (
        <Table columns={columns} data={restProps.companiesSections} />
      ) : null}
      <Box>
        {totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
      </Box>
    </Box>
  )
}
