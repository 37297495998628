import React from "react";
import { Route, Switch } from "react-router-dom";
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Grid, Stack, useDisclosure } from "@chakra-ui/core";
import PropTypes from "prop-types";

// todo agregar breadcrum
function NavigatorDrawer({ routes, children, header, ...restProps }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();
	const drawerRoutes = routes.map(routeProps => (
		<Route key={routeProps.path} {...routeProps} />
	));

	return (
		<Stack {...restProps}>
			{header(onOpen, btnRef)}
			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader></DrawerHeader>
					<DrawerBody>
						{children}
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<Grid
				display={{ base: "block", md: "grid" }}
				templateColumns={{ base: "1fr", md: "1fr 7fr" }}
				gap={{ base: 0, md: 3 }}
			>
				<Box display={{ base: "none", xs: "none", md: "inherit" }}>
					{children}
				</Box>
				<Box>
					<Switch>
						{drawerRoutes}
					</Switch>
				</Box>
			</Grid>
		</Stack>
	);
}

NavigatorDrawer.propTypes = {
	routes: PropTypes.array.isRequired,
	children: PropTypes.node.isRequired,
	openDrawerButton: PropTypes.func,
};

export default NavigatorDrawer;