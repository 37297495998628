import React, { useState, useEffect } from 'react'
import {
  postLoginUnicos,
  getLoginUnicos,
  postCrearClave,
  getObtenerClaves,
  getParametros,
} from '../../../../api/loginUnico'
import { Box, Button, Spinner, useDisclosure } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { getAuthToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useToast } from '@chakra-ui/core'
import { updateSupplier } from '../../../../api/section'
import { Input } from 'antd'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import { deleteLoginUnico } from '../../../../api/loginUnico'
import { deleteClave } from '../../../../api/loginUnico'
import { isSuccess } from '../../../../helpers/utils'

function SupplierAutoLogin() {
  // - - - - GENERALS - - - -

  const { id: supplierId } = useParams()
  const toast = useToast()
  const { t } = useTranslation(['auto-login', 'incentives', 'suppliers', 'forms'])

  // - - - -❗ESTADOS❗- - - -

  const [leClave, setClave] = useState('')
  const [leClave2, setClave2] = useState('')
  const [leClave3, setClave3] = useState('')
  const [leClave4, setClave4] = useState('')
  const [loginUnico, setLoginUnico] = useState(false)
  const [nameProvider, setNameProvider] = useState('')
  const [loading, setLoading] = useState(false)
  const [parametros, setParametros] = useState([])
  const modalDisclosure = useDisclosure()

  // - - - -❗METHODS❗- - - -

  const handleClaves = ({ target: { value } }) => setClave(value)
  const handleClaves2 = ({ target: { value } }) => setClave2('+' + value)
  const handleClaves3 = ({ target: { value } }) => setClave3('+' + value)
  const handleClaves4 = ({ target: { value } }) => setClave4('+' + value)

  const authToken = useSelector(getAuthToken)

  // 🔴  - -📞 PROVEEDORES📞 - -

  async function getProvider() {
    try {
      const response = await updateSupplier(supplierId, authToken)
      setNameProvider(response.data.nombre)
    } catch (error) {
      console.log(error)
    }
  }
  // 🔴  - -📞 ENVIAR FUNCIONES AUTO-LOGIN 📞 - -

  async function sendAutoLoginFunction(e) {
    e.preventDefault()
    let data = {
      proveedor: { id: parseInt(supplierId) },
      url: leClave + leClave2 + leClave3 + leClave4,
      script: '',
    }
    try {
      const response = await postLoginUnicos(data, authToken)
      let and = response.data.id
      sendKey(and)

      if (isSuccess(response.status)) {
        getAutoLogins()
        toast(toastCustomizedSuccess(t('auto-login:loginSuccess')))
      } else {
        toast(toastCustomizedError(t('auto-login:wrong')))
      }
    } catch (error) {
      console.log(error)
    }
  }
  // 🔴  - -📞 ENVIAR CLAVES PARA AUTO-LOGIN 📞 - -

  async function sendKey(and) {
    let data = {
      proveedorLoginUnico: { id: and },
      clave: nameProvider,
    }
    try {
      await postCrearClave(data, authToken)
    } catch (error) {
      console.log(error)
    }
  }
  // 🔴 - -📞 OBTENER AUTO-LOGINS 📞 - -

  async function getAutoLogins() {
    try {
      setLoading(true)
      const response = await getLoginUnicos(authToken, { proveedor: supplierId })
      const data = response.data
      setLoginUnico(data)
      setLoading(false)
    } catch (error) {
      console.log('AUTO LOGIN ERROR', error)
    }
  }

  async function deleteAutologin() {
    try {
      const loginUnicoId = loginUnico[0].id
      const response = await getObtenerClaves(authToken, { proveedorLoginUnico: loginUnicoId })
      const claveId = response.data[0].id

      if (parametros.find(p => p.clave.id === claveId)) {
        toast(toastCustomizedError(t('auto-login:cannotDelete'), '', 4500))
        modalDisclosure.onClose()
      } else {
        const [delLogin, delClave] = await Promise.all([
          deleteLoginUnico(loginUnicoId, authToken),
          deleteClave(claveId, authToken)
        ])
        
        if (isSuccess(delLogin.status) && isSuccess(delClave.status)) {
          toast(toastCustomizedSuccess(t('forms:actionCompleted')))
          modalDisclosure.onClose()
          getAutoLogins()
        }
      }
    } catch (error) {
      console.log('AUTO LOGIN ERROR', error)
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  async function cargarParametros() {
    try {
      const response = await getParametros()

      setParametros(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProvider()
    cargarParametros()
  }, [])

  useEffect(() => {
    getAutoLogins()
  }, [nameProvider])

  return (
    <>
      <Box
        style={{
          margin: 'auto',
          backgroundColor: '#fff',
          padding: '2rem 6rem',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
          borderWidth: '1px',
          borderRadius: '0.25rem',
          textAlign: 'center',
        }}
      >
        {!loading ? (
          !loginUnico.length ? (
            <form id='login-unico'>
              <Box style={{ display: 'grid', alignItems: 'center', gridTemplateColumns: '1fr 1fr', padding: '2rem' }}>
                <Box
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <b>{t('auto-login:parameters')}</b>
                  <Input
                    placeholder='parámetro 1'
                    style={{
                      borderRadius: '4px',
                      width: '12rem',
                      height: '3rem',
                      backgroundColor: '#EEF2F9',
                      border: '1px solid black',
                    }}
                    onChange={handleClaves}
                  ></Input>

                  <Input
                    placeholder='parámetro 2'
                    style={{
                      borderRadius: '4px',
                      width: '12rem',
                      height: '3rem',
                      backgroundColor: '#EEF2F9',
                      border: '1px solid black',
                    }}
                    onChange={handleClaves2}
                  ></Input>

                  <Input
                    placeholder='parámetro 3'
                    style={{
                      borderRadius: '4px',
                      width: '12rem',
                      height: '3rem',
                      backgroundColor: '#EEF2F9',
                      border: '1px solid black',
                    }}
                    onChange={handleClaves3}
                  ></Input>

                  <Input
                    placeholder='parámetro 4'
                    style={{
                      borderRadius: '4px',
                      width: '12rem',
                      height: '3rem',
                      backgroundColor: '#EEF2F9',
                      border: '1px solid black',
                    }}
                    onChange={handleClaves4}
                  ></Input>
                  <Box style={{ marginTop: '1rem' }}>
                    <Button onClick={e => sendAutoLoginFunction(e)} variantColor='blue' variant='outline' type='submit'>
                      {t('auto-login:uploadAutoLogin')}
                    </Button>
                  </Box>
                </Box>
                <Box style={{ borderRadius: '4px', backgroundColor: 'white', width: '80%', textAlign: 'justify' }}>
                  <Box style={{ marginBottom: '1rem' }}>
                    <b>Instrucciones:</b>
                  </Box>
                  <Box>
                    <ul>
                      <li>
                        <p>
                          En los parámetros se cargan las variables que se necesitan para el login único, basándose en
                          la información brindada por el proveedor, ej: "username", "password".
                        </p>
                      </li>
                      <li>
                        <p>
                          Solamente se deben llenar los campos necesarios, si el login único posee 1 parámetro, dejar
                          los otros 3 vacíos.
                        </p>
                      </li>
                      <li>
                        <p>
                          Las claves para cada parámetro luego serán asignadas a cada Usuario por un Administrador en
                          Configuración {'->'} Agencia {'->'} Credenciales.
                        </p>
                      </li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </form>
          ) : (
            <Box
              style={{
                display: 'flex',
                gap: '2rem',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                minHeight: '15rem',
              }}
            >
              <h1>{t('auto-login:alreadyAutoLogin')}</h1>
              <Button variantColor='red' variant='outline' type='submit' onClick={() => modalDisclosure.onOpen()}>
                {' '}
                {t('incentives:delete')} {t('suppliers:auto-login')}
              </Button>
            </Box>
          )
        ) : (
          <Spinner />
        )}
        <AlertDialogSimple
          isOpen={modalDisclosure.isOpen}
          onClose={modalDisclosure.onClose}
          onAccept={deleteAutologin}
          title={`${t('common:delete')} ${t('suppliers:auto-login')}`}
          size='lg'
        >
          {' '}
          <p>{`${t('common:deletingLogin')}`}</p>
        </AlertDialogSimple>
      </Box>
    </>
  )
}

export default SupplierAutoLogin
