import { Box, Button, Flex, FormLabel, PseudoBox, SimpleGrid, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFormControls } from '../../hooks'
import FormInput from '../form-input'
import FormTextArea from '../form-textarea'
import CreatableSelect from 'react-select/creatable'
import { getAllAgreements } from '../../../api/intranet'
import { getAuthToken } from '../../../redux/selectors'
import { getUnitsMeasurement } from '../../../api/incentives'
import { useSelector } from 'react-redux'
import SelectAsync from '../../common/select-async'
import { unregister } from '../../../serviceWorker'

export default function SupplierAgreementForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['forms', 'suppliers'])
  const { isSubmitBlocked, submitWrapper } = useFormControls()
  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })
  const [allItems, setAllItems] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [filteredProducts, setFilteredProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState()
  const authToken = useSelector(getAuthToken)
  const [mesure, setMesure] = useState(false)
  const [moneda, setMoneda] = useState()
  const toast = useToast()

  const getSuppliersAgreements = async () => {
    try {
      const response = await getAllAgreements()
      setAllItems(() => response.data)
    } catch (error) {
      toast(error)
    }
  }

  async function getMeasurement() {
    try {
      const response = await getUnitsMeasurement(authToken)
      let mes = response.data
      mes.push({
        descripcion: 'Porcentaje',
        id: -1,
        simbolo: '%',
      })
      setMesure(mes)
    } catch (error) {
      console.log(error)
    }
  }

  const setInitialProducts = () => {
    const unique = [...new Set(allItems.map(item => item.producto))]
    const productos = unique.map(product => {
      return { value: product, label: product }
    })
    if (formData) {
      const productoActual = productos.find(producto => producto.value === formData.producto)
      setSelectedProduct(productoActual)
    }
    setAllProducts(productos)
  }

  function submit(data, e) {
    data = {
      ...data,
      producto: selectedProduct.value,
      ...(moneda >= 1 ? { moneda: { id: moneda } } : { moneda: null }),
    }
    submitWrapper(async () => {
      await onSubmit(data, e)
    })
  }

  async function formatInputs() {
    setAllProducts([])
  }

  const handleCreateOption = option => {
    const newProduct = { value: option, label: option }
    setAllProducts([...allProducts, newProduct])
    setSelectedProduct(newProduct)
  }

  const handleInputChange = (newValue, actionMeta) => {
    if (newValue !== '') {
      const filteredProducts = allProducts.filter(p => p.value.includes(newValue))
      setFilteredProducts(filteredProducts)
    }
    if (newValue === '') {
      setFilteredProducts(allProducts)
    }
  }

  const handleChange = (newValue, actionMeta) => {
    if (newValue !== '') {
      setSelectedProduct(newValue)
    }
  }

  const onChangeMesure = e => {
    setMoneda(parseInt(e))
  }

  useEffect(() => {
    if (restProps.items?.length) {
      formatInputs()
    }
    setSelectedProduct('')
  }, [restProps.items, allItems])

  useEffect(() => {
    getSuppliersAgreements()
    getMeasurement()
  }, [])

  useEffect(() => {
    setInitialProducts()
  }, [allItems])

  useEffect(() => {
    setFilteredProducts(allProducts)
  }, [allProducts])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(submit)} {...restProps}>
      <Flex wrap='wrap' justify='space-between' gap={4}>
        <Box width={{ base: '100%', md: '40%' }}>
          <label> {t('suppliers:product')}</label>
          <CreatableSelect
            inputProps={{
              ref: register,
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                marginTop: '0.25rem',
                marginBottom: '1rem',
                height: '2.5rem',
                borderRadius: '0.25rem',
                border: '1px solid',
                color: '#E2E8F0',
                backgroundColor: '#fff',
                appearance: 'none',
                fontSize: '1rem',
                outline: 'none',
              }),
              placeholder: baseStyles => ({
                ...baseStyles,
                color: '#9facc1',
              }),
            }}
            required
            name='producto'
            onChange={handleChange}
            onInputChange={handleInputChange}
            onCreateOption={handleCreateOption}
            isClearable
            placeholder={t('forms:searchEllipsis')}
            options={filteredProducts.length ? filteredProducts : []}
            value={selectedProduct}
          />
        </Box>
        <Box width={{ base: '100%', md: '25%' }}>
          <FormLabel htmlFor='mesure.id' isRequired>
            {t('suppliers:commissionType')}
          </FormLabel>
          <SelectAsync
            name='mesure.id'
            onChange={e => onChangeMesure(e.target.value)}
            isLoading={!mesure}
            placeholder={t('forms:selectOption')}
            isRequired
          >
            {mesure &&
              mesure.map(({ id, descripcion, simbolo }) => (
                <option key={id} value={id}>
                  {simbolo + ' ' + descripcion}
                </option>
              ))}
          </SelectAsync>
        </Box>
        <Box width={{ base: '100%', md: '25%' }}>
          <FormInput
            isRequired
            label={t('suppliers:commission')}
            name='comision'
            inputProps={{
              placeholder: '00.0',
              ref: register,
            }}
          />
        </Box>
      </Flex>

      <FormTextArea
        isRequired
        label={t('suppliers:observations')}
        name='observaciones'
        inputProps={{
          ref: register,
          placeholder: t('suppliers:observations'),
        }}
      />
      <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
        {buttonLabel || t('forms:save')}
      </Button>
    </PseudoBox>
  )
}
