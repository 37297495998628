import React, { useState, useMemo, useEffect } from 'react'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Input,
  InputGroup,
  Stack,
  Text,
  Divider,
  useDisclosure,
  useToast,
  NumberInput,
} from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'

//Material ui
import Table from '../../../common/table'
import ModalSimple from '../../../common/modal-simple'
import { HotelSelectorModal } from './Hotels/hotel-selector-modal'
import { toastCustomizedError } from '../../../common/toast-default-config'
import { MdBuild } from 'react-icons/md'

export const Hotels = ({ onChange, departureData, onDelete, trigerSave, cancel }) => {
  const { t } = useTranslation(['common', 'forms', 'packages', 'consultations'])
  const modalDisclosure = useDisclosure()
  const toast = useToast()

  const [editHotel, setEditHotel] = useState({
    totalNights: 0,
    regime: '',
    roomType: '',
  })
  const [idEdit, setIdEdit] = useState(-1)
  const [readOnly, setReadOnly] = useState(false)

  //MANEJO DE INPUTS
  function onChangeHotel(e, flg = null) {
    if (flg) {
      setEditHotel({ ...editHotel, [e.target.name]: Number(e.target.value) })
    } else {
      setEditHotel({ ...editHotel, [e.target.name]: e.target.value })
    }
  }

  function onChangeTotalNights(value) {
    setEditHotel({ ...editHotel, totalNights: Number(value)})
  }

  function handleAddHotel() {
    onChange(editHotel, idEdit)
    cleanForm()
  }

  function cleanForm() {
    setEditHotel({
      hotelId: 0,
      totalNights: 0,
      regime: '',
      roomType: '',
    })
    setIdEdit(-1)
    setReadOnly(false)
  }

  function onHotelEditClick(index, data) {
    setEditHotel({ ...data })
    setIdEdit(index)
    setReadOnly(data?.id ? false : true)
  }

  const columnsHotels = useMemo(() => [
    {
      Header: t('forms:name'),
      accessor: row => {
        return row.name
      },
    },
    {
      Header: t('common:category'),
      accessor: row => {
        return row.category
      },
    },
    {
      Header: t('forms:origin'),
      accessor: row => {
        return row.localidad.nombre
      },
    },
    {
      Header: t('common:actions'),
      accessor: null,
      Cell: ({ cell }) => (
        <Stack align={'center'} spacing='5px' isInline>
          <ButtonDelete
            isDisabled={readOnly}
            onClick={() => {
              setEditHotel({ ...editHotel, hotel: undefined })
            }}
          />
        </Stack>
      ),
    },
  ], [editHotel, readOnly])

  const columns = useMemo(() => [
    {
      Header: t('packages:typeRoom'),
      accessor: row => {
        return row.roomType
      },
    },
    {
      Header: t('packages:regime'),
      accessor: row => {
        return row.regime
      },
    },
    {
      Header: t('forms:totalNights'),
      accessor: row => {
        return row.totalNights
      },
    },
    {
      Header: t('forms:name'),
      accessor: row => {
        return row.hotel.name
      },
    },
    {
      Header: t('common:category'),
      accessor: row => {
        return row.hotel.category
      },
    },
    {
      Header: t('forms:origin'),
      accessor: row => {
        return row.hotel.localidad.nombre
      },
    },
    {
      Header: t('common:actions'),
      accessor: null,
      Cell: ({ cell }) => {
        return (
          <Stack align={'center'} spacing='5px' isInline d='flex' mx='1'>
            <ButtonEdit
              icon={cell.row.original.id ? MdBuild : undefined}
              customLabel={cell.row.original.id ? false : t('packages:seePreview')}
              onClick={() => {
                return onHotelEditClick(cell.row.index, cell.row.original)
              }}
            />
            <ButtonDelete
              onClick={() => {
                if (idEdit === -1 || readOnly) {
                  cleanForm()
                  onDelete(cell.row.index, 'hotelesInformacion')
                } else {
                  toast(toastCustomizedError(t('packages:notDelete')))
                }
              }}
            />
          </Stack>
        )
      },
    },
  ])

  useEffect(() => {
    cleanForm()
  }, [trigerSave, cancel])

  return (
    <Box mt='3'>
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={t('forms:hotels')}
        size='1000px'
      >
        <HotelSelectorModal editHotel={editHotel} setEditHotel={setEditHotel} onClose={modalDisclosure.onClose} />
      </ModalSimple>
      {!!departureData?.hotelesInformacion?.length && (
        <Box mt='20px' mb='20px'>
          <Table columns={columns} data={departureData?.hotelesInformacion} />
        </Box>
      )}
      {!departureData?.hotelesInformacion?.length && (
        <Box bg='white' mb='20px' mt='8'>
          <Text textAlign={'center'} color='gray.500' px='5' py='5'>
            {t('common:zeroItems')}
          </Text>
        </Box>
      )}

      <Divider />
      <Text fontWeight='bold' style={{ paddingBottom: '10px', paddingLeft: '5px', paddingTop: '10px' }}>
        {t('consultations:hotel')}
      </Text>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}>
        <Box p='1'>
          <FormLabel isRequired>{t('packages:typeRoom')}</FormLabel>
          <InputGroup>
            <Input
              isDisabled={readOnly}
              value={editHotel.roomType}
              onChange={e => onChangeHotel(e)}
              px={1}
              placeholder={t('packages:typeRoom')}
              name='roomType'
            />
          </InputGroup>
        </Box>
        <Box p='1'>
          <FormLabel isRequired>{t('packages:regime')}</FormLabel>
          <InputGroup>
            <Input
              isDisabled={readOnly}
              name='regime'
              value={editHotel.regime}
              onChange={e => onChangeHotel(e)}
              px={1}
              placeholder={t('packages:regime')}
            />
          </InputGroup>
        </Box>
        <Box p='1'>
          <FormLabel isRequired>{t('forms:totalNights')}</FormLabel>
          <NumberInput
            min={0}
            name='totalNights'
            value={editHotel.totalNights}
            defaultValue={0}
            px={1}
            onChange={e => onChangeTotalNights(e)}
          />
        </Box>
      </Grid>

      <Divider />

      <Box d='flex' mb='20px' mt='20px'>
        <Button isDisabled={readOnly} variantColor='blue' variant='outline' size='md' onClick={() => modalDisclosure.onOpen()}>
          {t('forms:selectedHotel')}
        </Button>
      </Box>
      <Box display='flex' mt='20px' mb='20px'>
        {editHotel?.hotel && <Table columns={columnsHotels} data={[editHotel.hotel]} />}
      </Box>

      <Divider />
      <Box style={{ display: 'flex', gap: '1rem' }} mt='20px' mb='20px'>
        <Button
          variantColor='blue'
          variant='outline'
          size='md'
          onClick={() => handleAddHotel()}
          isDisabled={!(
            editHotel.regime && 
            editHotel.roomType && 
            editHotel.totalNights && 
            editHotel.hotel?.id && 
            !readOnly
          )}
        >
          {idEdit === -1 ? t('common:addHotel') : t('common:editHotel')}
        </Button>
        <Button
          variantColor='red'
          variant='outline'
          onClick={() => {
            cleanForm()
          }}
        >
          {t('forms:discard')}
        </Button>
      </Box>
      {idEdit !== -1 && !readOnly && (
        <>
          <span style={{ color: 'red', fontSize: '0.8rem' }}>*</span>
          <span style={{ color: 'black', fontSize: '0.8rem' }}>{t('packages:confirmSave')}</span>
        </>
      )}
      <Divider />
    </Box>
  )
}
