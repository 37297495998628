import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Input, Tag, Text, FormLabel, Stack } from '@chakra-ui/core'
import { GridItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useConfigWithAuth } from '../../../../hooks'
import { iconServices } from './icons-services'
import { Panel } from '../../../../common/helpers'
import { Select, Space } from 'antd'
import { ButtonDelete } from '../../../../forms/buttons/edit'
import Table from '../../../../common/table'
const { Option } = Select

export const PackageServices = ({ onChangeServices, state }) => {
  const { configAuth } = useConfigWithAuth()
  const { t } = useTranslation(['common', 'forms', 'packages'])

  const [iconTypesRequest, setIconos] = useState({
    isLoading: false,
    response: null,
  })
  async function getIcons() {
    setIconos({
      isLoading: true,
      response: null,
    })
    try {
      const newReq = await iconServices(configAuth)
      setIconos({
        response: newReq.data,
        isLoading: false,
      })
    } catch (error) {
      return error
    }
  }
  useEffect(() => {
    getIcons()
  }, [])

  const [item, setItem] = useState(undefined)
  function onChangeSelect(value) {
    setItem(iconTypesRequest.response.find(icon => icon.id === value))
  }

  //MANEJO DE ICONOS
  const [newIcon, setNewIcon] = useState(undefined)
  const [icons, setIcon] = useState(state.paquetesIncluido)

  function onChange(value) {
    setNewIcon(value)
  }

  function onClick() {
    if (newIcon !== undefined && newIcon !== '') {
      const iconToAdd = {
        icon_id: item.id,
        description: newIcon,
      }
      const newArrayIcons = icons.concat(iconToAdd)
      onChangeServices(newArrayIcons)
      setIcon(newArrayIcons)
    }
    resetForm()
  }

  function resetForm() {
    setNewIcon(undefined)
    setItem(undefined)
  }

  function removeService(icon_id) {
    const newServices = icons.filter(item => item.icon_id !== icon_id)
    setIcon(newServices)
    resetForm()
    filteredOptions = iconTypesRequest?.response?.filter(i => !newServices.map(icon => icon.icon_id).includes(i.id))
    onChangeServices(newServices)
  }

  let filteredOptions = iconTypesRequest?.response?.filter(i => !icons.map(icon => icon.icon_id).includes(i.id))

  const columns = useMemo(
    () => [
      {
        Header: t('forms:icon'),
        accessor: row => {
          const iconImg = iconTypesRequest ? iconTypesRequest?.response?.find(icon => icon?.id === row.icon_id) : ''
          return (
            <Text>
              <i className={row.icon_id && iconImg && iconImg.id == row.icon_id ? `${iconImg?.codigo} fa-lg` : ''} />{' '}
              {iconImg.descripcion}
            </Text>
          )
        },
      },
      {
        Header: t('forms:description'),
        accessor: row => {
          let cellValue = row.description
          if (cellValue.length > 60) {
            cellValue = `${cellValue.substring(0, 60)}...`
          }
          return cellValue
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => {
          return <ButtonDelete onClick={() => removeService(cell.row.original.icon_id)} />
        },
      },
    ],
    [iconTypesRequest, icons],
  )

  return (
    <Panel mb='8'>
      <Tag width='100%' mb='3' p='3' bg='blue.500' color='white'>
        {t('packages:services')}
      </Tag>
      {!iconTypesRequest.isLoading && iconTypesRequest.response && (
        <Grid templateColumns={'repeat(2, 1fr)'} gap='0.5rem'>
          <GridItem colSpan={1}>
            <Grid templateColumns={'repeat(9, 1fr)'} gap='0.5rem'>
              <GridItem colSpan={4}>
                <FormLabel>{t('forms:icon')}</FormLabel>
                <Select
                  placeholder={t('forms:select_icon')}
                  style={{ width: '100%' }}
                  onChange={change => onChangeSelect(parseInt(change))}
                  value={item?.id || undefined}
                >
                  {filteredOptions.map(icono => {
                    return (
                      <Option value={icono.id} label={icono.descripcion}>
                        <Space>
                          <i className={`${icono.codigo} fa-lg`}></i>
                          <Text>{icono.descripcion}</Text>
                        </Space>
                      </Option>
                    )
                  })}
                </Select>
              </GridItem>
              <GridItem colSpan={5}>
                <FormLabel>{t('forms:description')}</FormLabel>
                <Grid templateColumns={'repeat(5, 1fr)'} gap='0.5rem'>
                  <GridItem colSpan={4}>
                    <Box flex={1} alignItems='center' minWidth='12rem'>
                      <Input
                        autoComplete='off'
                        name='descripcion'
                        placeholder={t('forms:description')}
                        type='text'
                        value={newIcon || ''}
                        onChange={e => onChange(e.target.value)}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Button
                      disabled={!item || !newIcon}
                      variantColor='blue'
                      variant='outline'
                      type='button'
                      onClick={onClick}
                    >
                      {t('common:add')}
                    </Button>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={1}>
            <Text fontSize='lg'>{t('packages:services')}</Text>
            <Box display='grid' height='300px'>
              <Table columns={columns} data={icons} />
            </Box>
          </GridItem>
        </Grid>
      )}
    </Panel>
  )
}
