import React from 'react'
import {
  Button,
  Box,
  SimpleGrid,
  Text,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionHeader,
} from '@chakra-ui/core'
import { GridItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

function SupplierIncentivesCard(props) {
  const { t } = useTranslation(['incentives'])

  return (
    <>
      <Accordion>
        <Box
          backgroundColor='gray.50'
          m='10px 0px'
          color='blue'
          style={{
            borderRadius: '5px',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
          }}
        >
          <AccordionItem style={{ borderRadius: '5px' }}>
            {/* AcordionHeader */}
            <AccordionHeader backgroundColor='gray.100' style={{ borderRadius: '5px' }}>
              <SimpleGrid
                columns={{ sm: 1, md: 13 }}
                style={{ textAlign: 'center', alignItems: 'center', padding: '.75rem 0rem', width: '100%' }}
                spacing={8}
              >
                <GridItem colSpan={1}>
                  <p ontSize='lg'>{props.x.id}</p>
                </GridItem>
                <GridItem colSpan={1}>
                  <Button
                    style={{ minWidth: '4rem' }}
                    variantColor='yellow'
                    variant='outline'
                    type='submit'
                    size='xs'
                    onClick={() => props.viewStatus(props.x.id, props.x.incentivoEscala.length > 0)}
                  >
                    {' '}
                    {t('incentives:seeStatus')}{' '}
                  </Button>
                </GridItem>
                <GridItem colSpan={2}>
                  <b>{props.x.nombre}</b>
                </GridItem>
                <GridItem colSpan={3}>
                  <p style={{ fontSize: '.95rem' }}>
                    {new Date(props.x.fechaInicio).toLocaleDateString('es-ES', { timeZone: 'UTC' })} -
                    {new Date(props.x.fechaFin).toLocaleDateString('es-ES', { timeZone: 'UTC' })}
                  </p>
                </GridItem>
                <GridItem colSpan={1}>
                  <p fontSize='md'>{props.x.unidadMedicion.descripcion}</p>
                </GridItem>
                <GridItem colSpan={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
                  <Button
                    variantColor='green'
                    variant='outline'
                    type='submit'
                    size='xs'
                    onClick={e => props.agregarEscalaClick(props.x.id)}
                  >
                    {' '}
                    {t('incentives:addScale')}{' '}
                  </Button>
                  <Button
                    variantColor='blue'
                    variant='outline'
                    type='submit'
                    size='xs'
                    onClick={() => props.editGeneral(props.x.id, props.x)}
                  >
                    {' '}
                    {t('incentives:edit')}{' '}
                  </Button>
                  <Button
                    variantColor='red'
                    variant='outline'
                    type='submit'
                    size='xs'
                    onClick={() => props.deleteItem('General', props.x.id)}
                  >
                    {' '}
                    {t('incentives:delete')}{' '}
                  </Button>
                </GridItem>
                  <Box style={{ width: '2rem' }}>
                    <AccordionIcon />
                  </Box>
              </SimpleGrid>
            </AccordionHeader>

            {/* AcordionPrincipalPanel */}
            <AccordionPanel pb={4}>
              <SimpleGrid
                h='3em'
                columns={{ sm: 1, md: 11 }}
                spacing={10}
                style={{ width: '98%', textAlign: 'center' }}
              >
                <Text fontSize='lg' style={{ textAlign: 'center', paddingTop: '0%' }}></Text>
                <GridItem colSpan={1} style={{ paddingTop: '0%' }}>
                  <Text fontSize='lg' style={{ textAlign: 'center', paddingTop: '0%' }}></Text>
                </GridItem>
                <GridItem colSpan={2} style={{ paddingTop: '10px' }}>
                  <b> {t('incentives:initialAmount')} </b>
                </GridItem>
                <GridItem colSpan={2} style={{ paddingTop: '10px' }}>
                  <b> {t('incentives:endAmount')}</b>
                </GridItem>
                <GridItem colSpan={1} style={{ paddingTop: '10px' }}>
                  <b> {t('incentives:incentive')}</b>
                </GridItem>
              </SimpleGrid>
              {/* AcordionRow 1 */}
              {props.x.incentivoEscala.length
                ? props.x.incentivoEscala
                    .slice(0)
                    .reverse()
                    .map(x => (
                      <Box key={x.escalaId}>
                        <SimpleGrid
                          h='3em'
                          columns={{ sm: 1, md: 11 }}
                          spacing={10}
                          style={{ width: '98%', textAlign: 'center' }}
                        >
                          <Text fontSize='lg' style={{ textAlign: 'center', paddingTop: '0%' }}></Text>
                          <GridItem colSpan={1} style={{ paddingTop: '0%' }}>
                            <Text fontSize='lg' style={{ textAlign: 'center', paddingTop: '0%' }}></Text>
                          </GridItem>
                          <GridItem colSpan={2} style={{ paddingTop: '10px' }}>
                            <p>{props.x.unidadMedicion.simbolo}{new Intl.NumberFormat('de-DE').format(x.montoInicial)}</p>
                          </GridItem>
                          <GridItem colSpan={2} style={{ paddingTop: '10px' }}>
                            <p>{props.x.unidadMedicion.simbolo}{new Intl.NumberFormat('de-DE').format(x.montoFinal)}</p>
                          </GridItem>
                          <GridItem colSpan={1} style={{ paddingTop: '10px' }}>
                            <p>{x.incentivoPorcentaje}%</p>
                          </GridItem>
                          <GridItem colSpan={3} style={{ paddingTop: '12px' }}>
                            <Button
                              variantColor='red'
                              variant='outline'
                              type='submit'
                              size='xs'
                              style={{ marginLeft: '4px' }}
                              onClick={() => props.deleteItem('deleteStatus', x.id)}
                            >
                              {' '}
                              {t('incentives:delete')}{' '}
                            </Button>
                          </GridItem>
                        </SimpleGrid>
                      </Box>
                    ))
                : null}
            </AccordionPanel>
          </AccordionItem>
        </Box>
      </Accordion>
    </>
  )
}

export default SupplierIncentivesCard
