import axios from 'axios'
import { getStorageToken } from '../helpers/utils'
import { configWithParams } from './application'

// Headers

export const configWithToken = authToken => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
  }
}

// Endpoints

export const getConsultationsEndpoint = '/api/consultas'

export const getReclamosEndpoint = '/api/reclamos'

export const getStatesEndpoint = '/api/estados'

export const updateConsultaEndpoint = id => `/api/consultas/${id}`

export const getCustomerEndpoint = (countryId, provinceId, localityID) =>
  `/api/ConsultasRegionalizacion?countryId=${countryId}&provinceId=${provinceId}&localityId=${localityID}`

export const getCustomerForRequestEndpoint = '/api/ConsultasRegionalizacion/buscar'

export const getDetailsStateCustomerEndpoint = '/api/ConsultasRegionalizacion/obtener-estados-datelles'

export const getUpdateStateCostumerEndpoint = '/api/ConsultasRegionalizacion/actualizar-estado-consulta'

export const getSearchAgencysEndpoint = '/api/Agencia/buscar'

export const putDeriveAgencyEndpoint = status => `/api/Agencia/?derivadora=${status}`

// Requests GET

export const getConsultations = (data, authToken) =>
  axios.get(`${process.env.REACT_APP_MARCABLANCA_API_V2}${getConsultationsEndpoint}`, configWithParams(data, authToken))

export const getCustomer = (countryId, provinceId, localityID, authToken) =>
  axios.get(
    `${process.env.REACT_APP_GATEWAY_V2}${getCustomerEndpoint(countryId, provinceId, localityID)}`,
    configWithToken(authToken),
  )

export const getClaims = (data, authToken) =>
  axios.get(`${process.env.REACT_APP_MARCABLANCA_API_V2}${getReclamosEndpoint}`, configWithParams(data, authToken))

export const getDetailsStateCustomer = authToken =>
  axios.get(`${process.env.REACT_APP_GATEWAY_V2}${getDetailsStateCustomerEndpoint}`, configWithToken(authToken))

export const getStates = authToken =>
  axios.get(`${process.env.REACT_APP_MARCABLANCA_API_V2}${getStatesEndpoint}`, configWithToken(authToken))

// Requests POST

export const getCustomerForRequest = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_GATEWAY_V2}${getCustomerForRequestEndpoint}`, data, configWithToken(authToken))

export const getUpdateStateCostumer = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_GATEWAY_V2}${getUpdateStateCostumerEndpoint}`, data, configWithToken(authToken))

export const getSearchAgencys = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_GATEWAY_V2}${getSearchAgencysEndpoint}`, data, configWithToken(authToken))

export const putDeriveAgency = (data, authToken, status) =>
  axios.put(`${process.env.REACT_APP_GATEWAY_V2}${putDeriveAgencyEndpoint(status)}`, data, configWithToken(authToken))

// Requests PUT

export const updateConsulta = (id, data, authToken) =>
  axios.put(
    `${process.env.REACT_APP_MARCABLANCA_API_V2}${updateConsultaEndpoint(id)}`,
    data,
    configWithToken(authToken),
  )
