import { getAgreementsBySupplierId, getContactsBySupplierId, getOfficesBySupplierId } from '../../api/intranet/requests'
import { SUPPLIERS_RESET_DATA, SUPPLIERS_UPDATE_KEY } from './actions-type'

const updateAgreements = (items, isLoading, error = false) => ({
  type: SUPPLIERS_UPDATE_KEY,
  payload: {
    agreements: {
      items,
      isLoading,
      error,
    },
  },
})

const updateContacts = (items, isLoading, error = false) => ({
  type: SUPPLIERS_UPDATE_KEY,
  payload: {
    contacts: {
      items,
      isLoading,
      error,
    },
  },
})

const updateOffices = (items, isLoading, error = false) => ({
  type: SUPPLIERS_UPDATE_KEY,
  payload: {
    offices: {
      items,
      isLoading,
      error,
    },
  },
})

export function resetSupplierData() {
  return (dispatch) => {
    dispatch({ type: SUPPLIERS_RESET_DATA })
  }
}

export function getAgreements(supplierId, maxPerPage, page) {
  return async (dispatch, getState) => {
    try {
      const data = {
        proveedorId: supplierId,
        page,
        maxPerPage,
      }
      const { appState } = getState()
      const response = await getAgreementsBySupplierId(data, appState.user.authtoken)

      dispatch(updateAgreements(response.data, false))
      return Promise.resolve()
    } catch (error) {
      dispatch(updateAgreements([], false, true))
      return Promise.reject(error)
    }
  }
}

export function getContacts(supplierId, maxPerPage, page) {
  return async (dispatch, getState) => {
    try {
      const data = {
        proveedorId: supplierId,
        page,
        maxPerPage,
      }
      const { appState } = getState()
      const response = await getContactsBySupplierId(data, appState.user.authtoken)

      dispatch(updateContacts(response.data, false))
      return Promise.resolve()
    } catch (error) {
      dispatch(updateContacts([], false, true))
      return Promise.reject(error)
    }
  }
}

export function getOffices(supplierId, maxPerPage, page) {
  return async (dispatch, getState) => {
    try {
      const data = {
        proveedorId: supplierId,
        page,
        maxPerPage,
      }
      const { appState } = getState()
      const response = await getOfficesBySupplierId(data, appState.user.authtoken)

      dispatch(updateOffices(response.data, false))
      return Promise.resolve()
    } catch (error) {
      dispatch(updateOffices([], false, true))
      return Promise.reject(error)
    }
  }
}
