import React from 'react'
import { DepartureList } from './DepartureList'
import { SectionDataProvider } from '../../../../context/SectionDataContext'

const FILTER_KEYS = {
    page: 'page',
    pageSize: 'pageSize',
    packageId: 'packageId'
}

const SECTION_DATA_KEYS = {
    departure: 'departure'
}

const defaultSectionDataValues = {
    departure: {
        data: [],
        isLoading: true,
        error: null
    },
}

const defaultFilters = {
    page: 1,
    pageSize: 5,
    packageId: null,
}

const defaultFiltersValues = { ...defaultFilters }

const DepartureSection = () => (
    <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
        <DepartureList/>
    </SectionDataProvider>
)

export {
    DepartureSection,
    FILTER_KEYS,
    SECTION_DATA_KEYS,
    defaultSectionDataValues,
    defaultFilters,
    defaultFiltersValues,
}