import {  PACKAGE_RESET_DATA, PACKAGE_UPDATE_KEY } from "../actions/actions-type";
import initialState from "./initial-state";
import { createReducer } from "./reducer-creator";

const updateKey = (state, action) => ({
	...state,
	...action.payload,
});

const resetPackageData = (state) => {
    return({
        ...state,
        packages: { isLoading: true, error: false, items: null },
    })
};

export default createReducer(initialState.packageState, {
	[PACKAGE_UPDATE_KEY]: updateKey,
	[PACKAGE_RESET_DATA]: resetPackageData
});