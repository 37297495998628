import React, { useState } from 'react'
import { Box, Button, Icon, PseudoBox } from '@chakra-ui/core'

const Rating = React.forwardRef(
  ({ inputName, size, icon, scale, fillColor, strokeColor, rating, setValue, defaultValue = 0 }, ref) => {
    const buttons = []

    const setInputState = value => {
      setValue(inputName, value)
    }

    const onClick = idx => {
      if (!isNaN(idx)) {
        // allow user to click first icon and set rating to zero if rating is already 1
        if (rating === 1 && idx === 1) {
          setInputState(defaultValue)
        } else {
          setInputState(idx)
        }
      }
    }

    const RatingIcon = ({ fill }) => {
      return (
        <Icon
          name={icon}
          size={`${size}px`}
          color={fillColor}
          stroke={strokeColor}
          onClick={onClick}
          fillOpacity={fill ? '100%' : '0'}
        />
      )
    }

    const RatingButton = ({ idx, fill }) => {
      return (
        <div
          style={{
            width: `${size}px`,
            height: `${size}px`,
            cursor: 'pointer'
          }}
          aria-label={`Rate ${idx}`}
          onClick={() => onClick(idx)}
          _focus={{ outline: 0 }}
        >
          <RatingIcon fill={fill} />
        </div>
      )
    }

    for (let i = 1; i <= scale; i++) {
      buttons.push(<RatingButton key={i} idx={i} fill={i <= rating} />)
    }

    return (
      <Box style={{ display: 'flex', alignItems: 'baseline', marginTop: '0.5rem', gap: '0.5rem' }}>
        {buttons}
        <div
          style={{ marginLeft: '1rem', padding: '0.35rem', fontWeight: 600, cursor: 'pointer', fontSize: '0.75rem' }}
          onClick={() => setInputState(0)}
        >
          Reset
        </div>
      </Box>
    )
  },
)

Rating.displayName = 'Rating'

export default Rating
