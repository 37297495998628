import React, { useState, useEffect } from 'react'
import { PageContainer, Panel } from '../../common/helpers'
import { Button, Box, Spinner, SimpleGrid, Grid, Divider, Image, Link, CloseButton, Switch, useToast } from '@chakra-ui/core'
import { GridItem } from '@chakra-ui/react'
import { ListItem, UnorderedList } from '@chakra-ui/react'
import { DateRangePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import ModalSimple from '../../common/modal-simple'
import CreateCategories from '../communications/createCategories'
import { getAuthToken, getToken, getUserCountryId, getUserApplication, getUserRolId } from '../../../redux/selectors'
import { useDisclosure } from '@chakra-ui/core'
import {
  obtenerComunicados,
  obtenerCategorias,
  actualizarComunicados,
  deleteComunicado,
  obtenerPermisosPorRol,
  eliminarPermisosPorRolId,
  crearPermisosPorRol,
  obtenerPermisosPorRolId,
} from '../../../api/communications'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { MdBuild } from 'react-icons/md'
import NewsForm from '../../forms/comunications/newsForm'
import AccessEdition from '../../forms/comunications/accessEdition'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import { COMUNICATIONS_CRUD } from '../../../helpers/permissions'
import { deconstructPermission } from '../../../helpers/permissions-functions'
import '../../styles/comunications/comunications.css'
import '../../styles/comunications/news.css'
import { isSuccess } from '../../../helpers/utils'

function News() {
  // - - 🖥️ URL-Params 🖥️ - -

  let history = useHistory()
  const toast = useToast()

  const { t } = useTranslation(['comunications'])
  const userCountryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const token = useSelector(getToken)
  const userApplication = useSelector(getUserApplication)
  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()
  const modalDisclosure3 = useDisclosure()
  const modalDisclosure4 = useDisclosure()
  const userRolId = useSelector(getUserRolId)
  let permisosUser = userRolId == 1 || userRolId == 2 ? true : false

  // - DATA PÌCKERS

  const [{ startDate, endDate }, setRangeDate] = useState({
    startDate: moment(),
    endDate: null,
  })

  const [focusedInput, setFocusedInput] = useState()
  function setFocusedPicker(focusedInput) {
    setFocusedInput(focusedInput)
  }
  function setRangePicker(startDate, endDate) {
    if (endDate) {
      setRangeDate((state) => ({
        startDate,
        endDate,
      }))
    }
  }

  // - - ❗ States ❗ - -

  const [spinnerState, setSpinnerState] = useState(false)
  const [eventsAll, setEventsAll] = useState([])
  const [categoriesEvents, setCategoriesEvents] = useState(false)
  const [formData, setFormData] = useState(false)
  const [permisos, setPermisos] = useState([])
  const [events, setEvents] = useState([])
  const [allCategories, setAllCategories] = useState(false)
  const [ids, setIds] = useState(false)
  const [rolls, setRollds] = useState(false)
  const [heading, setHeading] = useState(' ')

  // - - - -❗ METHODS ❗- - - -

  //Ir a Crear Noticia
  function goToCreate() {
    history.push('/communications?news')
  }

  //Ir a leer la noticias
  function openNewsWindow(x) {
    if (x.comunicadoHtml === null || x.comunicadoHtml === '') {
      return null
    } else {
      history.push(`/newsGEA?${x.id}?n`)
    }
  }

  // Eliminar Noticia
  function deleteComunitacion(id) {
    setIds(id)
    modalDisclosure4.onOpen()
  }

  // Acceder a los permisos
  function editAccess(id) {
    permisosPorId(id.id)
    setIds(id)
    modalDisclosure3.onOpen()
  }

  // Checks permisos
  function funcCheck(id) {
    if (rolls !== false) {
      return rolls.filter((x) => x.rolId == id)
    }
  }

  // Editar Noticia
  function goToEdit(x) {
    setFormData(x)
    modalDisclosure2.onOpen()
  }

  // Crear-Editar-Eliminar Categorias
  function categories() {
    modalDisclosure.onOpen()
  }

  //Cerrar Modal Permisos
  function closeModalPermisos() {
    modalDisclosure3.onClose()
    setRollds(false)
  }

  //Buscar Por Fecha
  function searchWithDate() {
    let fechaInicio = moment(startDate).format('YYYY-MM-DD')
    let fechaFin = moment(endDate).format('YYYY-MM-DD')
    setEvents([])
    setSpinnerState(false)

    obtenerPermisos(fechaInicio, fechaFin)
  }

  // ParseCategoria
  function createCategorias(idCat) {
    if (allCategories == false) {
      return null
    } else {
      let cat = allCategories.filter((x) => x.id == idCat.categoriasComunicados.id)
      return cat[0].color
    }
  }
  // Limpiar Filtros
  function cleanAll() {
    // Fecha inicio: Ultimos 3 meses
    let fechaInicio = moment()
      .subtract(3, 'month')
      .format('YYYY-MM-DD')
    // Fecha fin: Proximos 3 meses
    let fechaFin = moment()
      .add(3, 'month')
      .format('YYYY-MM-DD')
    setHeading(' ')

    obtenerPermisos(fechaInicio, fechaFin)
  }

  function seeOption(b) {
    function separar(i) {
      let o = i.split('/')
      return o[0]
    }
    let cat = allCategories.filter((x) => separar(x.descripcion) == b[0])
    let u = eventsAll.filter((x) => x.categoriasComunicados.id === cat[0].id)
    setEvents(u)
    let cat2 = cat[0].descripcion.split('/')
    setHeading(` | ${cat2[0]}`)
  }

  // - - - -📞❗ API CALLS ❗📞- - - -

  // Obtener Permisos de Usuario
  async function obtenerPermisos(fechaInicio, fechaFin) {
    try {
      const resp = await obtenerPermisosPorRol(authToken)
      setPermisos(resp.data)
      getEvents(resp.data, fechaInicio, fechaFin)
    } catch (err) {
      console.log(err)
    }
  }
  // Obtener Noticias
  async function getEvents(permisos, fechaInicio, fechaFin) {
    try {
      const resp = await obtenerComunicados(authToken)
      const filterCategory = resp.data.data.filter((x) => x.tipoComunicado.id === 1)
      let filterDate = filterCategory.filter((x) => x.fechaBaja == null && x.fecha >= fechaInicio && x.fecha <= fechaFin)

      let perm = permisos
      perm.filter((x) => x.rolId == userRolId)

      function filters(id) {
        let i = perm.map((x) => x.comunicados?.id == (id || null))
        let k = i.filter((x) => x == true)
        if (k == '') {
          return false
        } else {
          return true
        }
      }

      let filterPermisos = filterDate.map((x) => (filters(x.id) == true ? x : null))
      let filterPermisos2 = filterPermisos.filter((x) => x !== null)

      let filterLastDat = filterPermisos2.sort((datoPrev, datoNext) => {
        return (
          new Date(moment(datoPrev.fecha).format('YYYY-MM-DD')).getTime() <
          new Date(moment(datoNext.fecha).format('YYYY-MM-DD')).getTime()
        )
      })
      setSpinnerState(true)
      setEvents(filterLastDat)
      setEventsAll(filterLastDat)
    } catch (err) {
      console.log(err)
    }
  }

  // Obtener Permisos por Id
  async function permisosPorId(id) {
    try {
      const resp = await obtenerPermisosPorRolId(id, authToken)
      setRollds(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  // Obtener Categorias de Noticias
  async function obtenerCategoriasFunc() {
    try {
      const resp = await obtenerCategorias(userCountryId, authToken)
      let filterDate = resp.data.filter((x) => x.fechaBaja == null)
      setAllCategories(filterDate)
      let filterSplit = filterDate.map((x) => x.descripcion.split('/'))
      let filterCategorieEvents = filterSplit.filter((x) => x[1] == 'news')
      setCategoriesEvents(filterCategorieEvents)
    } catch (error) {
      console.log(error)
    }
  }

  // Destacar Noticia
  async function newsDestacada(x) {
    let destacado = x.destacado == true ? false : x.destacado == false ? true : null
    let id = x.id
    const sesion = {
      token,
      ...deconstructPermission(COMUNICATIONS_CRUD),
    }
    let data = {
      asunto: x.asunto,
      titulo: x.titulo,
      subtitulo: x.subtitulo,
      imagenUrl: x.imagenUrl,
      descripcion: x.descripcion,
      comunicadoHtml: x.comunicadoHtml,
      categoriasComunicados: { id: x.categoriasComunicados.id },
      destacado: destacado,
      tipoComunicado: { id: x.tipoComunicado.id },
      url: x.url,
      fecha: x.fecha,
      sesion,
    }

    try {
      const resp = await actualizarComunicados(id, data, authToken)

      if (isSuccess(resp.status)) {
        toast(toastCustomizedSuccess(`${t('comunications:editSucess')}`))
      } else {
        toast(toastCustomizedError(`${t('comunications:inputError')}`))
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Editar Noticia
  async function editFunc(x) {
    let id = formData.id
    const sesion = {
      token,
      ...deconstructPermission(COMUNICATIONS_CRUD),
    }
    let data = {
      asunto: x.asunto,
      titulo: x.titulo,
      subtitulo: x.subtitulo,
      imagenUrl: x.imagenUrl,
      descripcion: x.descripcion,
      comunicadoHtml: x.comunicadoHtml,
      categoriasComunicados: { id: x.categoriasComunicados.id },
      destacado: x.destacado,
      tipoComunicado: { id: x.tipoComunicado.id },
      url: x.url,
      fecha: x.fecha,
      sesion,
    }

    try {
      await actualizarComunicados(id, data, authToken)
      toast(toastCustomizedSuccess(`${t('comunications:editSucess')}`))
      modalDisclosure2.onClose()
      obtenerPermisos()
    } catch (err) {
      toast(toastCustomizedError(`${t('comunications:editError')}`))
      console.log(err)
    }
  }
  // Eliminar Noticia
  async function deleteNew() {
    try {
      const resp = await deleteComunicado(ids, authToken)
      if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
        toast(toastCustomizedSuccess(`${t('comunications:deleteSucess')}`))
      } else {
        toast(toastCustomizedError(`${t('comunications:deleteError')}`))
      }
      // Fecha inicio: Ultimos 3 meses
      let fechaInicio = moment()
        .subtract(3, 'month')
        .format('YYYY-MM-DD')
      // Fecha fin: Proximos 3 meses
      let fechaFin = moment()
        .add(3, 'month')
        .format('YYYY-MM-DD')
      obtenerPermisos(fechaInicio, fechaFin)
      modalDisclosure4.onClose()
    } catch (err) {
      toast(toastCustomizedError(`${t('comunications:deleteError')}`))
      console.log(err)
    }
  }
  // Crear / Eliminar Permiso
  async function newPermission(a, b) {
    const sesion = {
      token,
      ...deconstructPermission(COMUNICATIONS_CRUD),
    }
    if (b == false) {
      let data = {
        rolId: a,
        comunicados: { id: ids.id },
        sesion,
      }
      try {
        const resp = await crearPermisosPorRol(data, authToken)
        if (resp.status == 200) {
        } else {
          console.log('error')
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      let permisoId = rolls.filter((x) => x.rolId == a)

      if (permisoId.length) {
        let permisoDelete = permisoId[0].id
        try {
          const resp = await eliminarPermisosPorRolId(permisoDelete, authToken)

          if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
          } else {
            console.log('error')
          }
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  useEffect(() => {
    // Fecha inicio: Ultimos 3 meses
    let fechaInicio = moment()
      .subtract(3, 'month')
      .format('YYYY-MM-DD')
    // Fecha fin: Proximos 3 meses
    let fechaFin = moment()
      .add(3, 'month')
      .format('YYYY-MM-DD')
    obtenerPermisos(fechaInicio, fechaFin)
    obtenerCategoriasFunc()
  }, [])

  return (
    <>
      {/* Heading */}
      <PageContainer>
        <Grid templateColumns="repeat(2, 1fr)" gap={2} style={{ margin: '0rem', padding: '0' }}>
          <Box color="#103059">
            <h1 style={{ fontSize: '2.5rem', padding: '0', marginTop: '0.5rem' }}>
              {t('comunications:news')}
              {t(`comunications:${heading}`)}
            </h1>
          </Box>
          {permisosUser === true ? (
            <SimpleGrid
              className="dNone"
              columns={{ base: 1, md: 1 }}
              style={{ fontSize: '2.5rem', padding: '0', marginTop: '0.8rem' }}
              spacing={8}
            >
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Box>
                  <Button
                    style={{ width: '12rem' }}
                    onClick={() => {
                      goToCreate()
                    }}
                    variantColor="blue"
                    variant="outline"
                  >
                    {t('comunications:createNews')}
                  </Button>
                </Box>
                <Box>
                  <Button
                    style={{ width: '12rem' }}
                    variant="outline"
                    onClick={() => {
                      categories()
                    }}
                    variantColor="blue"
                  >
                    {t('comunications:createCategories')}
                  </Button>
                </Box>
              </div>
            </SimpleGrid>
          ) : null}
        </Grid>
      </PageContainer>

      {/* Panel Options */}
      {/* <Panel style={{margin:"0.5rem 2rem", height:"5rem"}}>
        <SimpleGrid columns={{ base: 1, md: 1}} spacing={8}>
        <div style={{display:'flex', justifyContent:'space-around'}}>
        <Box>
            <Button
            style={{width:"12rem"}}
                onClick={()=>{goToCreate()}}
                variantColor="blue"
                >{t("comunications:createNews")}</Button>
		</Box>
        <Box>
            <Button
                style={{width:"12rem"}}
                onClick={()=>{categories()}}
                variantColor="blue"
                >{t("comunications:createCategories")}</Button>
		</Box>
        </div>
        </SimpleGrid>
        </Panel>          */}

      {/* Modal create Categorias */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('comunications:createCategories')}`}
        size="lg"
      >
        <CreateCategories onClose={modalDisclosure.onClose}></CreateCategories>
      </ModalSimple>

      {/* Section Page News */}
      <PageContainer>
        <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5} style={{ margin: '2rem 1rem 10rem' }}>
          <GridItem className="dNone" colSpan={1} rowSpan={1} style={{ width: '90%', margin: '0 5%' }}>
            <Panel style={{ width: '100%' }}>
              <p style={{ fontSize: '1.7rem', color: '#103059', padding: '0%', margin: '1% 0% 5%' }}>
                <b>{t('comunications:filters')}</b>
              </p>
              <p style={{ fontSize: '1.2rem', color: '#103059' }}>{t('comunications:date')}:</p>
              <br></br>
              <Box style={{ width: '75%', margin: '0% 18%' }}>
                <DateRangePicker
                  showDefaultInputIcon={false}
                  isOutsideRange={() => false}
                  required
                  startDate={startDate}
                  startDateId="fecha_inicio"
                  endDate={endDate}
                  endDateId="fecha_fin"
                  focusedInput={focusedInput}
                  onDatesChange={({ startDate, endDate }) => setRangePicker(startDate, endDate)}
                  onFocusChange={(focusedInput) => setFocusedPicker(focusedInput)}
                  small
                  orientation="horizontal"
                  displayFormat="DD/MM/YYYY"
                />
              </Box>
              <Box>
                <Button
                  style={{ width: '62%', height: '2.2rem', margin: '5% 18% 10%', backgroundColor: '#103059', color: 'white' }}
                  onClick={() => {
                    searchWithDate()
                  }}
                >
                  {t('comunications:searchDate')}
                </Button>
              </Box>
              <p style={{ fontSize: '1.2rem', color: '#103059' }}>{t('comunications:categories')}:</p>

              <Box>
                <UnorderedList style={{ margin: '5% 6% 0% 15%' }}>
                  {categoriesEvents == false
                    ? null
                    : categoriesEvents.map((x) => (
                        <ListItem key={x} style={{ fontSize: '1rem', textTransform: 'uppercase' }}>
                          <Link
                            onClick={() => {
                              seeOption(x)
                            }}
                          >
                            {x[0]}
                          </Link>
                        </ListItem>
                      ))}
                </UnorderedList>
              </Box>
              <Box>
                <Button
                  style={{ width: '62%', height: '2.2rem', margin: '15% 18%', backgroundColor: '#103059', color: 'white' }}
                  onClick={() => {
                    cleanAll()
                  }}
                >
                  {t('comunications:cleanFilter')}
                </Button>
              </Box>
            </Panel>
          </GridItem>

          <GridItem colSpan={2} style={{ width: '100%', height: '80vh', overflow: 'auto' }}>
            {spinnerState == false ? (
              <Spinner style={{ margin: '15% 40%' }} thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            ) : null}

            {events.map((x) => (
              <Box key={x.id}>
                <Box style={{ display: 'flex' }}>
                  <Box className="dNone" borderWidth="1px" rounded="lg" overflow="hidden" style={{ width: '25%' }}>
                    {permisosUser === true ? (
                      <div className="dNone" style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '0.2rem', right: '0.2rem' }}>
                          <Switch
                            style={{ padding: '0.5rem' }}
                            defaultIsChecked={x.destacado}
                            onChange={(e) => newsDestacada(x)}
                          ></Switch>
                        </div>
                        <div style={{ position: 'absolute', top: '0.2rem', left: '0.2rem' }}>
                          <Button
                            style={{ background: 'rgba(0,0,0,0.2)', height: '2.1rem', padding: '-2' }}
                            onClick={() => {
                              editAccess(x)
                            }}
                            variantColor="blue"
                          >
                            {t('comunications:permits')}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                    <Image
                      onClick={() => openNewsWindow(x)}
                      style={{ width: '100%', height: '8.5rem' }}
                      objectFit="cover"
                      src={x.imagenUrl}
                    />
                    <Box style={{ height: '0.6rem', backgroundColor: `${createCategorias(x)}` }}></Box>
                  </Box>
                  <Box className="informationCardNews">
                    <Box className="dNoneReverse " mt="5px" borderWidth="1px" rounded="lg">
                      <Image
                        className="imageCardNews"
                        onClick={() => openNewsWindow(x)}
                        style={{ width: '100%', height: '8.5rem' }}
                        objectFit="cover"
                        src={x.imagenUrl}
                      />
                      <Box style={{ height: '0.6rem', backgroundColor: `${createCategorias(x)}` }}></Box>
                    </Box>
                    <div className="backgroundNews">
                      <div onClick={() => openNewsWindow(x)}>
                        <Box fontWeight="bold" className="titleCardNews">
                          <b>{x.titulo}</b>
                        </Box>
                      </div>
                      <div>
                        <Box
                          style={{
                            fontSize: '12pt',
                            padding: '0.5rem 1.2rem 0rem',
                            margin: '0 ',
                            color: 'gray',
                            lineHeight: '1.5rem',
                          }}
                        >
                          <b>{x.descripcion}</b>
                        </Box>
                      </div>
                      <Box
                        color="blue"
                        fontSize="1rem"
                        textTransform="uppercase"
                        style={{ color: ' #24428e', padding: '0.3rem 1.2rem 0rem', lineHeight: '1.5rem' }}
                      >
                        <Link href={x.url} isExternal>
                          {x.subtitulo}
                        </Link>
                      </Box>

                      <div style={{ position: 'relative', width: '100%' }}>
                        <Box fontSize="1.1rem" textTransform="uppercase" className="dateCardNews">
                          <b>
                            {moment(x.fecha).format('DD')} {moment(x.fecha).format('MMM')}
                          </b>
                        </Box>
                      </div>
                    </div>
                  </Box>

                  <Box style={{ width: '10%' }}>
                    {x.comunicadoHtml === null ? null : (
                      <div style={{ position: 'relative', width: '100%' }}>
                        <div className="dNone" style={{ position: 'absolute', top: '7rem', right: '10%' }}>
                          <div>
                            <Button
                              style={{ background: '#24428e', height: '2.1rem', padding: '-2' }}
                              onClick={() => openNewsWindow(x)}
                              variantColor="blue"
                            >
                              {t('comunications:goToNew')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}

                    {permisosUser === true ? (
                      <div className="dNone" style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '0.2rem', right: '0.2rem' }}>
                          <CloseButton
                            size="md"
                            style={{ color: '#A52422', backgroundColor: 'rgba(0,0,0,0.2' }}
                            onClick={() => {
                              deleteComunitacion(x.id)
                            }}
                            variantColor="blue"
                          >
                            {t('comunications:eliminar')}
                          </CloseButton>
                        </div>
                        <div style={{ position: 'absolute', top: '0rem', right: '3.5rem' }}>
                          <Button
                            onClick={() => {
                              goToEdit(x)
                            }}
                            rightIcon={MdBuild}
                            style={{ backgroundColor: 'transparent', color: 'gray', marginRight: '-1rem', paddingRight: 'px' }}
                          ></Button>
                        </div>
                      </div>
                    ) : null}
                  </Box>
                </Box>
                <Divider className="dNone" style={{ borderColor: 'blue', width: '100%', margin: '1.8% 0% 1.8%' }} />
              </Box>
            ))}
          </GridItem>
        </SimpleGrid>
      </PageContainer>

      {/* Modal Edicion */}
      {formData == false ? null : (
        <ModalSimple
          isOpen={modalDisclosure2.isOpen}
          onClose={modalDisclosure2.onClose}
          title={`${t('comunications:EditNew')}`}
          size="6xl"
        >
          <NewsForm padre={editFunc} formData={formData} section={'news'}></NewsForm>
        </ModalSimple>
      )}

      {/* Modal Delete */}
      <ModalSimple
        isOpen={modalDisclosure4.isOpen}
        onClose={modalDisclosure4.onClose}
        title={`${t('comunications:deleteItem')}`}
        size="xl"
      >
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            style={{ width: '12rem' }}
            onClick={() => {
              deleteNew()
            }}
            variantColor="red"
          >
            {t('comunications:Eliminar')}
          </Button>
          <Button style={{ width: '12rem' }} onClick={() => modalDisclosure4.onClose()} variant="outline" variantColor="blue">
            {t('comunications:Cancelar')}
          </Button>
        </div>
      </ModalSimple>

      {/* Modal Permisos */}
      {rolls !== false ? (
        <ModalSimple isOpen={modalDisclosure3.isOpen} title={`${t('comunications:permits')}`} size="xl">
          <ModalSimple isOpen={modalDisclosure3.isOpen} title={`${t('comunications:permits')}`} size="xl">
            <AccessEdition funcCheck={funcCheck} newPermission={newPermission}></AccessEdition>
            <div style={{ float: 'right', margin: '4% 0% 0%' }}>
              <Button
                style={{ height: '2.1rem', padding: '-2' }}
                onClick={() => {
                  closeModalPermisos()
                }}
                variantColor="blue"
              >
                {t('comunications:close')}
              </Button>
            </div>
            <div style={{ position: 'absolute', top: '0rem', right: '0.1rem' }}>
              <Box style={{ backgroundColor: 'white', width: '3rem', height: '3rem', zIndex: '22' }}></Box>
            </div>
          </ModalSimple>
        </ModalSimple>
      ) : null}
    </>
  )
}

export default News
