import { FormErrorMessage } from '@chakra-ui/core'
import { getCategories, getBanners, getIgnoreCategories, getUbication, getAllCategories, getAllBanners, getAllIgnoreCategories } from '../../api/intranet/marca-blanca/banner'
import { BANNERS_RESET_DATA, BANNERS_UPDATE_KEY } from './actions-type'

const updateCategories = (items, isLoading, error = false) => ({
  type: BANNERS_UPDATE_KEY,
  payload: {
    categories: {
      items,
      isLoading,
      error,
    },
  },
})

const updateAllCategories = (items, isLoading, error = false) => ({
  type: BANNERS_UPDATE_KEY,
  payload: {
    allCategories: {
      items,
      isLoading,
      error,
    },
  },
})

const updateIgnoreCategories = (items, isLoading, error = false) => ({
  type: BANNERS_UPDATE_KEY,
  payload: {
    ignoreCategories: {
      items,
      isLoading,
      error,
    },
  },
})

const updateBanners = (items, isLoading, error = false) => ({
  type: BANNERS_UPDATE_KEY,
  payload: {
    banners: {
      items,
      isLoading,
      error,
    },
  },
})

const updateAllBanners = (items, isLoading, error = false) => ({
  type: BANNERS_UPDATE_KEY,
  payload: {
    allBanners: {
      items,
      isLoading,
      error,
    },
  },
})

const updateUbications = (items, isLoading, error = false) => ({
  type: BANNERS_UPDATE_KEY,
  payload: {
    ubications: {
      items,
      isLoading,
      error,
    },
  },
})

export function resetBannersData() {
  return dispatch => {
    dispatch({ type: BANNERS_RESET_DATA })
  }
}

export function listUbications(templateId) {
  return async (dispatch, getState) => {
    try {
      const { appState } = getState()
      const response = await getUbication(appState.user.authtoken, templateId)
      dispatch(updateUbications(response.data, false))
      return Promise.resolve()
    } catch (error) {
      dispatch(updateUbications([], false, true))
      return Promise.reject(error)
    }
  }
}

// export function listCategories(appId, countryId) {
//   return async (dispatch, getState) => {
//     try {
//       const { appState } = getState()
//       const response = await getCategories(appState.user.authtoken, appId, countryId)

//       dispatch(updateCategories(response.data, false))
//       return Promise.resolve()
//     } catch (error) {
//       dispatch(updateCategories([], false, true))
//       return Promise.reject(error)
//     }
//   }
// }

export function listCategories(appId, countryId) {
  return async (dispatch, getState) => {
    try {
      const { appState } = getState()
      const response = await getCategories(appState.user.authtoken, appId, countryId)

      dispatch(updateCategories(response.data, false))
      return response.data
    } catch (error) {
      dispatch(updateCategories([], false, true))
      throw FormErrorMessage
    }
  };
}

export function listAllCategories(appId, paisId, page = 1, maxPerPage = 12) {
  return async (dispatch) => {
    try {
      const response = await getAllCategories({empresaId: appId, paisId, page, maxPerPage})

      dispatch(updateAllCategories(response.data, false))
      return response.data
    } catch (error) {
      dispatch(updateCategories([], false, true))
      throw FormErrorMessage
    }
  }
}

export function listIgnoreCategories(empresaId, paisId, page = 1, maxPerPage = 12) {
  return async (dispatch) => {
    try {
      const response = await getIgnoreCategories({ empresaId, paisId, page, maxPerPage})
      const { data } = response

      dispatch(updateIgnoreCategories(data, false))
      return Promise.resolve()
    } catch (error) {
      dispatch(updateIgnoreCategories([], false, true))
      return Promise.reject(error)
    }
  }
}

export function listBanners(id) {
  return async (dispatch, getState) => {
    try {
      const { appState } = getState()
      const response = await getBanners(appState.user.authtoken, id)
      const { data } = response

      dispatch(updateBanners(data, false))
      return Promise.resolve()
    } catch (error) {
      dispatch(updateBanners([], false, true))
      return Promise.reject(error)
    }
  }
}

export function listAllBanners(id, page = 1) {
  return async (dispatch) => {
    try {
      const response = await getAllBanners({ empresaId: id, maxPerPage: 12, page, typeRequest: 'ALL' })
      const { data } = response

      dispatch(updateAllBanners(data, false))
      return Promise.resolve()
    } catch (error) {
      dispatch(updateBanners([], false, true))
      return Promise.reject(error)
    }
  }
}
