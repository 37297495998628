import React, { useMemo, useState, useEffect } from 'react'
import { PageContainer, Panel } from '../../common/helpers'
import { Button, Box, Heading } from '@chakra-ui/core'
import { Grid, GridItem } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { SingleDatePicker } from 'react-dates'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserCountryId, getUserApplicationId } from '../../../redux/selectors'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import FormInput from '../form-input'
import SelectAsync from '../../common/select-async'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import { getAllCountries } from '../../../api/application'
import { obtenerCategorias } from '../../../api/communications'

function GeaHomeForm({ formData = false, padre, section }) {
  // - - General - -
  const token = useSelector((state) => state.appState.user.token)
  const [paises, setPaises] = useState([])
  const [categories, setCategories] = useState([])
  const [allCategories, setAllCategories] = useState(false)
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const applicationId = useSelector(getUserApplicationId)
  const { t } = useTranslation(['comunications'])
  const [country, setCountry] = useState('')

  // - - 🖥️ URL-Params 🖥️ - -

  let location = useLocation()
  let claveMagica = location.search.replace(/^./, '')

  // - - 📅 SingleDatePicker 📅 - -

  const [focusedInput, setFocusedInput] = useState(false)
  const [startDate, setStartDate] = useState(moment(formData.fecha))

  // - - 📋 Form 📋 - -

  const { handleSubmit, register, control, setValue, reset, formState } = useForm({
    defaultValues: formData,
  })

  // - - 📋 HTML 📋 - -

  const [contenidoValue, setContenidoV] = useState('')
  const [switchOptions, setSwitchOptions] = useState({
    destacado: ' ',
    borderColor: 'white',
  })

  // - - 🌥️ Cloudinary 🌥️- -

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_PRESET, REACT_APP_CLOUDINARY_FOLDER } = process.env

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(contenidoValue)))
  )
  function funcImg() {
    if (formData == false) {
      return 'https://res.cloudinary.com/travelit/image/upload/v1629216295/img-test/wbtbsdtjjv6cpz6cirki.jpg'
    } else {
      return formData.imagenUrl
    }
  }

  const [imagState, setImagState] = useState([{ image_url: funcImg() }])

  const myCropWidget = useMemo(() => {
    return window?.cloudinary?.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: REACT_APP_CLOUDINARY_PRESET,
        folder: REACT_APP_CLOUDINARY_FOLDER,
        cropping: true,
      },
      (error, result) => {
        if (error) {
          alert('Cloudinary service unavailable')
        }
        checkUploadResult(result)
      }
    )
  }, [])

  function checkUploadResult(resultEvent) {
    if (resultEvent.event === 'success') {
      const img = [
        {
          image_url: resultEvent.info.secure_url,
        },
      ]
      setImagState(img)
    }
  }
  function showWidget() {
    myCropWidget.open()
  }
  function setOnChange(e) {
    setCountry(e)
    if (!formData) {
      getComunicadosCategoria(e)
    }
  }
  // - - ❗ Inputs ❗ - -

  function onChangeTrip(e, flg = null) {
    setStartDate(e)
  }

  function onSubmit(data) {
    function idGen(dat) {
      if (typeof formData.categoriasComunicados?.id !== 'undefined') {
        if (formData.categoriasComunicados.id == data.categoriasComunicados.id) {
          return formData.categoriasComunicados.id
        } else {
          let devolver = allCategories.filter((x) => x.descripcion == dat)
          return devolver[0].id
        }
      } else {
        let devolver = allCategories.filter((x) => x.descripcion == dat)
        return devolver[0].id
      }
    }

    let idFInal = idGen(data.categoriasComunicados.id)

    let datas = {
      tipoEnvio: { id: 1 },
      tipoComunicado: { id: 3 },
      paisId: parseInt(country),
      aplicacionId: applicationId,
      fecha: startDate._d,
      url: '',
      asunto: 'html',
      titulo: data.titulo,
      subtitulo: data.subtitulo,
      imagenUrl: imagState[0].image_url,
      descripcion: '.',
      comunicadoHtml: '',
      categoriasComunicados: { id: idFInal },
      destacado: false,
      sesion: {
        token: token,
        modulo: '',
        accion: '',
        nombre: '',
      },
    }
    padre(datas, '/training')
  }

  async function getCountrys() {
    try {
      const resp = await getAllCountries(token)
      setPaises(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  async function getComunicadosCategoria(e) {
    try {
      const resp = await obtenerCategorias(e, authToken)
      let filterDate = resp.data.filter((x) => x.fechaBaja == null)
      setAllCategories(filterDate)
      let filterSplit = filterDate.map((x) => x.descripcion.split('/'))
      if (claveMagica == '') {
        let filterCategorie = filterSplit.filter((x) => x[1] == 'training')
        setCategories(filterCategorie)
      } else {
        let filterCategorie = filterSplit.filter((x) => x[1] == (claveMagica == 'calendar' ? 'events' : claveMagica))
        setCategories(filterCategorie)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCountrys()
    if (formData) {
      getComunicadosCategoria(formData.paisId)
    }

    if (formState.isSubmitSuccessful) {
      reset({})
    }
  }, [reset])

  function showWidget() {
    myCropWidget.open()
  }

  return (
    <>
      <PageContainer style={{ padding: '1.2rem' }}>
        <Panel rounded="md" style={{ borderColor: `${switchOptions.borderColor}` }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {/* Title */}
              <GridItem colSpan={3} rowSpan={1}>
                <Heading style={{ fontSize: '1.8rem', margin: '0% 0% 2%' }}> {'GEA Campus Virtual'}</Heading>
              </GridItem>

              {/* Pais */}
              <Box style={{ margin: '1%', padding: '1%' }}>
                <label style={{ margin: '2rem 0rem' }}> {t('comunications:selectCountry')}</label>
                <Controller
                  control={control}
                  name="paisId"
                  render={({ onChange, value, name }) => (
                    <SelectAsync
                      style={{ marginTop: '4px' }}
                      name={name}
                      onChange={(e) => setOnChange(e.target.value)}
                      placeholder={`${t('comunications:selectCountry')}`}
                      value={value}
                      isRequired={true}
                    >
                      {paises
                        .sort((a, b) => a.nombre.localeCompare(b.nombre))
                        .map(({ id, nombre }) => (
                          <option key={id} value={id}>
                            {nombre}
                          </option>
                        ))}
                    </SelectAsync>
                  )}
                />
              </Box>

              {/* Categoria Comunicado */}
              <Box style={{ margin: '1%', padding: '1%' }}>
                <label style={{ margin: '2rem 0rem' }}> {t('comunications:comunicationCategory')}</label>
                <Controller
                  control={control}
                  name="categoriasComunicados.id"
                  render={({ onChange, value, name }) => (
                    <SelectAsync
                      style={{ marginTop: '4px' }}
                      name={name}
                      onChange={(e) => onChange(e.target.value)}
                      placeholder={`${t('comunications:comunicationCategory')}`}
                      value={value}
                      isRequired={false}
                    >
                      {categories.map((x) => (
                        <option key={x[0]} value={x[0] + '/' + x[1]}>
                          {x[0]}
                        </option>
                      ))}
                    </SelectAsync>
                  )}
                />
              </Box>
              {/* imagen URL */}
              <GridItem colSpan={1} rowSpan={3}>
                <label> </label>
                <Box
                  style={{ margin: '3% 5%', height: '25vh' }}
                  backgroundImage={`url(${imagState[0].image_url})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  d="flex"
                  backgroundSize="cover"
                  height={400}
                  justifyContent="center"
                  //alignContent="flex-end"
                >
                  <Button size="lg" onClick={showWidget} variantColor="blue" alignSelf="center">
                    {t('comunications:uploadImage')}
                  </Button>
                </Box>
              </GridItem>

              {/* Titulo */}
              <FormInput
                style={{ margin: '1%', padding: '1%' }}
                isRequired
                name="titulo"
                label={t('comunications:namelink')}
                inputProps={{
                  type: 'tel',
                  placeholder: t('comunications:namelink'),
                  ref: register,
                }}
              />

              {/* descripcion video */}
              <FormInput
                style={{ margin: '1%', padding: '1%' }}
                isRequired
                name="subtitulo"
                label={t('comunications:videoLink')}
                inputProps={{
                  type: 'string',
                  placeholder: t('comunications:videoLink'),
                  ref: register,
                }}
              />
              {/* Bajada */}
              {/* <GridItem colSpan={2} rowSpan={1} >
        <FormInput
                style={{marginTop:"0% 1%", padding:"1%"}}
                isRequired
                name="descripcion"
                label={t("comunications:Bajada")}
                inputProps={{
                    type:'string',
                    placeholder: t("comunications:Bajada"),
                    ref: register,
                }}
            />
        </GridItem> */}
              {/* Fecha de salida */}
              <Box style={{ margin: '1% 1% 5%', padding: '1%' }}>
                <label style={{ margin: '4rem 0rem' }}> {t('comunications:date')}</label>

                <Box style={{ marginTop: '1%', width: '100%' }}>
                  <SingleDatePicker
                    isOutsideRange={() => false}
                    autoFocus={false}
                    small={true}
                    showClearDate={true}
                    showDefaultInputIcon={true}
                    displayFormat="YYYY-MM-DD"
                    numberOfMonths={1}
                    placeholder="YYYY-MM-DD"
                    date={startDate}
                    onDateChange={(date) => onChangeTrip(date, 'departure_date')}
                    focused={focusedInput}
                    onFocusChange={() => setFocusedInput(!focusedInput)}
                    id="departure_date"
                  />
                </Box>
              </Box>
              <GridItem colSpan={3} rowSpan={1}>
                {/* <input type="submit" >
            </input  > */}
                <div style={{ float: 'right', marginRight: '2%' }}>
                  <Button variantColor="blue" type="submit">
                    {t('comunications:createTraining')}
                  </Button>
                </div>
              </GridItem>
            </Grid>
          </form>
        </Panel>
      </PageContainer>
    </>
  )
}

export default GeaHomeForm
