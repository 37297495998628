import React from 'react'

import { SectionDataProvider } from '../../../context/SectionDataContext'
import { ListHotels } from './ListHotels'

const FILTER_KEYS = {
  paisId: 'paisId',
  localidadId: 'localidadId',
  name: 'name',
  page: 'page',
  pageSize: 'pageSize',
}

const SECTION_DATA_KEYS = {
  hotels: 'hotels',
}

const defaultSectionDataValues = {
  hotels: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFiltersValues = {
  page: 0,
  pageSize: 12,
  localidadId: '',
  paisId: '',
  name: '',
}

const Hotels = () => (
  <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
    <ListHotels />
  </SectionDataProvider>
)

export { Hotels, FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues, defaultFiltersValues }
