import React from 'react'
import { Rubro } from './rubro'
import { SectionDataProvider } from '../../../context/SectionDataContext'

const FILTER_KEYS = {
  nombre: 'nombre',
  paisId: 'paisId',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  rubro: 'rubro',
}

const defaultSectionDataValues = {
  rubro: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 15,
  nombre: '',
  paisId: '',
}

const defaultFiltersValues = { ...defaultFilters }

const RubroSection = () => (
  <SectionDataProvider defaultFilters={{ ...defaultFiltersValues }} defaultSectionData={defaultSectionDataValues}>
    <Rubro />
  </SectionDataProvider>
)

export {
  RubroSection,
  FILTER_KEYS,
  SECTION_DATA_KEYS,
  defaultSectionDataValues,
  defaultFiltersValues,
  defaultFilters,
}
