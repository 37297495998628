import React, {useState, useEffect} from 'react'
import { Flex, Spacer } from "@chakra-ui/react"
import { Button, Switch, } from "@chakra-ui/core";
import { useTranslation } from "react-i18next";
import DetailsCard from "./detailsCards/detailsCard"
import { objetoA, objetoB, objetoX } from "./detailsCards/convert"

function DetailsBooking(props) {

const { t } = useTranslation(["bookings"]);

const [ data , setData] = useState([])

function objetoAA(props){
    setData(objetoA(props))
}

function objetoBB(props){
    setData(objetoB(props))
}

function objetoXX(props){
    setData(objetoX(props))
}

useEffect(() => {

    switch ( typeof props.dataBookingsSelect.dataBookingsDetails.paquete !== 'undefined' ){
        case true:
            objetoAA(props)
            break
        case false:
            objetoBB(props)
            break   
        default:
            objetoXX(props)
    }

}, []); 


return (
        <div>
            <DetailsCard
                 data={data}
                >
            </DetailsCard>
                
            <Flex>
                <Spacer />
                <Button    
                mt="22px"
                w="333px"
                size="md"
                marginLeft="5px"
                variantColor="white"
                variant="outline"
                type="button"
                colorScheme="blue"
                onClick={() => props.btnClose()}
                    >
                    {t("bookings:close")}
                </Button>
                <Spacer />
            </Flex>

        </div>
    )
}

export default DetailsBooking
