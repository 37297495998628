export const APPS_LIST = "aplicaciones:lista";
export const APPS_EDIT = "aplicaciones:editar";
export const APPS_CREATE = "aplicaciones:crear";
export const APPS_CRUD_SELECT_COUNTRY = "aplicaciones:crud:seleccionar-pais";

export const SUPPLIERS_LIST = "proveedores:lista";
export const SUPPLIERS_EDIT = "proveedores:editar";
export const SUPPLIERS_CREATE = "proveedores:crear";
export const SUPPLIERS_CRUD_SELECT_COUNTRY = "proveedores:crud:seleccionar-pais";
export const SUPPLIERS_AGREEMENTS_VIEW = "proveedores:acuerdos-vista";
export const SUPPLIERS_INCENTIVES_VIEW = "proveedores:incentivos-vista"
export const SUPPLIERS_LOGIN_COUNTER = "proveedores:login-counter"

export const SECTIONS_CREATE = "secciones:crear";
export const MARCA_BLANCA_CRUD ="marca-blanca:crud";

export const MARCA_BLANCA_CRUD_ADMIN ="marca-blanca:crud:admin";
//banners
export const BANNERS_CREATE = "marca-blanca-banners:crud";
export const BANNERS_LIST = "marca-blanca-banners:lista";
export const BANNERS_EDIT = "marca-blanca-banners:editar";
export const CATEGORIES_DEFAULT = "marca-blanca-banners:crud:default";
export const OFFERS_LINK = "marca-blanca-banners:crud";

export const CUSTOM_SECTIONS_CREATE = "marca-blanca-secciones-custom:crud";
export const DEFAULT_SECTIONS_CREATE = "marca-blanca-secciones-default:crud";
export const DEFAULT_SECTIONS_CREATE_COUTRY =
  "marca-blanca-secciones-default:crud:seleccionar-pais";
export const DEFAULT_CHECK_FORMS =
  "marca-blanca-banners:crud:default";

export const COMPANY_SECTIONS_CREATE = "marca-blanca-empresa-seccion:crud";
export const COMPANY_DETAIL_CREATE = "marca-blanca-empresa-detalle:crud";
export const LINK_TYPE_CREATE = "marca-blanca-empresa-detalle-tipos-enlace:crud";

//Packages
export const PACKAGE_CREATE = "paquetes:crud"

//Intranet
export const CREATE_STATUS = ""

//Supplier
export const MASTER_DEV = "master-dev:editar"

//Auto-LoginUnico

export const CREATE_AUTOLOGIN = "login-unico:crud"

//Incentivos

export const INCENTIVES = "incentivos:crud"
export const INCENTIVES_LIST = "incentivos:lista";

//Comunicaciones

export const COMUNICATIONS_CRUD = "comunicaciones:crud"
export const COMUNICATIONS = "comunicaciones:lista"

export const CAPACITACIONES = "capacitaciones:lista"

export const COMUNICATIONS_NOTICIAS_CRUD = "comunicaciones-noticias:crud"
export const COMUNICATIONS_NOTICIAS = "comunicaciones-noticias:lista"

export const COMUNICATIONS_GEA_IN_HOME_CRUD = "comunicaciones-gea-en-casa:crud"
export const COMUNICATIONS_GEA_IN_HOME = "comunicaciones-gea-en-casa:lista"

export const COMUNICATIONS_GEA_ACADEMY_CRUD = "comunicaciones-gea-academy:crud"
export const COMUNICATIONS_GEA_ACADEMY = "comunicaciones-gea-academy:lista"

export const COMUNICATIONS_EVENTOS_CRUD = "comunicaciones-eventos:crud"
export const COMUNICATIONS_EVENTOS = "comunicaciones-eventos:lista"

export const COMUNICATIONS_MKT = "comunicaciones-mkt:lista"




