import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Login from './views/login'
import Layout from './layout'
import Home from './views/home'
import { Application } from './views/applications'
import NoMatch from './no-march'
import AppEdit from './views/applications/index-edit'
import MyAppEdit from './views/applications/my-index-edit'
import { Suppliers } from './views/suppliers'
import { Incentives } from './views/incentives'
import SuppliersEdit from './views/suppliers/edit'
import CheckPermission from './check-permissions'
import { SuppliersAgreements } from './views/suppliers-agreements'
//New VIews
import Menu from './views/menu'
import Sections from './views/sections'
import Banners from './views/banners'
import Currency from './views/currency'
import WebInformation from './views/web-information'
import AutoLogin from './views/auto-login'
import News from './views/news'
import Communications from './views/communications'
import GeaInHome from './views/gea-in-home'
import Calendar from './views/calendar'
import NewsVisor from './views/news/newsVisor'
import Events from './views/events'
import Marketing from './views/marketing'
import { PackageSection } from './views/packages/package-section-data'
import { PackageForm } from './views/packages/create/PackageForm'
import { HotelForm } from './forms/hotels/hotelForm'
import { Hotels } from './views/hotels'
import { Bookings } from './views/bookings'
import EditHome from './views/home/editHome.js'
import {
  APPS_EDIT,
  APPS_LIST,
  BANNERS_CREATE,
  SUPPLIERS_AGREEMENTS_VIEW,
  SUPPLIERS_EDIT,
  SUPPLIERS_LIST,
  COMPANY_SECTIONS_CREATE,
  CUSTOM_SECTIONS_CREATE,
  COMPANY_DETAIL_CREATE,
  COMUNICATIONS,
  MARCA_BLANCA_CRUD,
  PACKAGE_CREATE,
  SUPPLIERS_LOGIN_COUNTER,
} from '../helpers/permissions'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { getStorageToken } from '../helpers/utils'
import { ExchangeAdmin } from './views/currency/exchange-admin'
import { GrupoGea } from './views/administration/GrupoGea'
import { TravelGea } from './views/administration/TravelGea'
import { Consulta } from './views/consultations/keys'
import { Reclamo } from './views/claims/keys'
import { getUserRolId } from '../redux/selectors'
import { DepartureSection } from './views/packages/departures/departure-section-data'
import { GroupMenu } from './views/group'

export default function Root() {
  const userRolId = useSelector(getUserRolId)
  const routes = useSelector(state => state.appState.user && state.appState.user.permissions)

  const token = getStorageToken()

  if (!routes && !token) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_KEY}>
        <Login />
      </GoogleReCaptchaProvider>
    )
  }

  return (
    <Router>
      <Layout>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route
            path='/applications'
            exact={true}
            component={props => <CheckPermission render={Application} permission={APPS_LIST} {...props} />}
          />
          <Route
            path='/applications/edit/:appId'
            component={props => <CheckPermission render={AppEdit} permission={APPS_EDIT} {...props} />}
          />
          <Route
            path='/my-applications/edit/'
            component={props => <CheckPermission render={MyAppEdit} permission={MARCA_BLANCA_CRUD} {...props} />}
          />
          <Route
            path='/suppliers'
            exact={true}
            component={props => <CheckPermission render={Suppliers} permission={SUPPLIERS_LIST} {...props} />}
          />
          <Route
            path='/suppliers/edit/:id'
            component={props => <CheckPermission render={SuppliersEdit} permission={SUPPLIERS_EDIT} {...props} />}
          />
          <Route
            path='/incentives'
            component={props => <CheckPermission render={Incentives} permission={SUPPLIERS_EDIT} {...props} />}
          />
          <Route
            path='/suppliers-agreements'
            component={props => (
              <CheckPermission render={SuppliersAgreements} permission={userRolId === 5 ? SUPPLIERS_LOGIN_COUNTER : SUPPLIERS_AGREEMENTS_VIEW} {...props} />
            )}
          />
          <Route
            path='/suppliers-login'
            component={props => (
              <CheckPermission render={SuppliersAgreements} permission={userRolId === 5 ? SUPPLIERS_LOGIN_COUNTER : SUPPLIERS_AGREEMENTS_VIEW} {...props} />
            )}
          />
          <Route
            path='/exchange-type'
            component={props => <CheckPermission render={ExchangeAdmin} permission={COMUNICATIONS} {...props} />}
          />
          <Route
            path='/grupo-gea'
            component={props => <CheckPermission render={GrupoGea} permission={COMUNICATIONS} {...props} />}
          />
          <Route
            path='/travelgea'
            component={props => <CheckPermission render={TravelGea} permission={COMUNICATIONS} {...props} />}
          />
          {/* New routes */}
          <Route
            path='/menu'
            exact={true}
            component={props => <CheckPermission render={Menu} permission={COMPANY_SECTIONS_CREATE} {...props} />}
          />
          {/* Sections routes */}
          <Route
            path='/sections'
            component={props => <CheckPermission render={Sections} permission={CUSTOM_SECTIONS_CREATE} {...props} />}
          />

          {/* WebInformations routes */}
          <Route
            path='/web-information'
            component={props => (
              <CheckPermission render={WebInformation} permission={COMPANY_DETAIL_CREATE} {...props} />
            )}
          />
          <Route
            path='/currency'
            component={props => <CheckPermission render={Currency} permission={PACKAGE_CREATE} {...props} />}
          />
          {/* <Redirect exact from="/sections" to="/sections/customSections" />
					<Route path="/webData" exact={true} component={(props) =>
						<CheckPermission render={WebData} permission={COMPANY_DETAIL_CREATE} {...props} />
					} />
					<Route path="/type" exact={true} component={(props) =>
						<CheckPermission render={Type} permission={LINK_TYPE_CREATE} {...props} />
					} />
					<Route path="/category" exact={true} component={(props) =>
						<CheckPermission render={Category} permission={LINK_TYPE_CREATE} {...props} />
					} /> */}
          <Route
            path='/banners'
            /* exact={true} */ component={props => (
              <CheckPermission render={Banners} permission={BANNERS_CREATE} {...props} />
            )}
          />
          {/*
            <Route
              path='/template'
              /* exact={true}  component={props => (
                <CheckPermission render={Templates} permission={MARCA_BLANCA_CRUD} {...props} />
              )}
              /> */}
          <Route
            path='/packages'
            exact={true}
            component={props => <CheckPermission render={PackageSection} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route path="/packages/create" exact={true} component={(props) =>
						<CheckPermission render={PackageForm} permission={PACKAGE_CREATE} {...props}/>
					} />
          <Route
            path='/packages/edit/:packageId'
            component={props => <CheckPermission render={PackageForm} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/packages/departures/:packageId/:title'
            component={props => <CheckPermission render={DepartureSection} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/packages/departures/createhotel'
            component={props => <CheckPermission render={HotelForm} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/packages/departures/listHotel'
            component={props => <CheckPermission render={Hotels} permission={PACKAGE_CREATE} {...props} />}
          />
          {/*
          <Route
            path='/packages/bulk-upload'
            component={props => <CheckPermission render={BulkUpload} permission={PACKAGE_CREATE} {...props} />}
          />
        */}
          <Route
            path='/bookings'
            component={props => <CheckPermission render={Bookings} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/consultations'
            component={props => <CheckPermission render={Consulta} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/claims'
            component={props => <CheckPermission render={Reclamo} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/autoLogin'
            component={props => <CheckPermission render={AutoLogin} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/news'
            component={props => <CheckPermission render={News} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/events'
            component={props => <CheckPermission render={Events} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/communications'
            component={props => <CheckPermission render={Communications} permission={COMUNICATIONS} {...props} />}
          />
          <Route
            path='/training'
            component={props => <CheckPermission render={GeaInHome} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/calendar'
            component={props => <CheckPermission render={Calendar} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/newsGEA'
            component={props => <CheckPermission render={NewsVisor} permission={PACKAGE_CREATE} {...props} />}
          />

          <Route
            path='/marketing'
            component={props => <CheckPermission render={Marketing} permission={PACKAGE_CREATE} {...props} />}
          />
          <Route
            path='/editHome'
            component={props => <CheckPermission render={EditHome} permission={PACKAGE_CREATE} {...props} />}
          />
            <Route
            path='/group'
            exact={true}
            component={props => <CheckPermission render={GroupMenu} permission={userRolId === 5 ? SUPPLIERS_LOGIN_COUNTER : SUPPLIERS_AGREEMENTS_VIEW} {...props} />}
          />
          <Route component={NoMatch} />
        </Switch>
      </Layout>
    </Router>
  )
}
