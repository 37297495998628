import { Box, Button, Grid, Select, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useSectionData } from '../../../../hooks/useSectionData'
import { FILTER_KEYS } from './index'
import { getRoles } from '../../../../api/application'
import { getAuthToken } from '../../../../redux/selectors'
import { toastCustomizedError } from '../../../common/toast-default-config'
import FormInput from '../../../forms/form-input'

export default function CredentialsSearchForm({ onFilter, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const authToken = useSelector(getAuthToken)
  const initialRolesState = {
    data: null,
    isLoading: true,
    error: null,
  }
  const [roles, setRoles] = useState(initialRolesState)
  const { t } = useTranslation(['common', 'forms', 'consultations', 'comunications'])
  const toast = useToast()

  const rolesSetter = async () => {
    setRoles(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getRoles(authToken)
      setRoles({
        data: response.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setRoles(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    rolesSetter()
  }, [])

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} {...restProps}>
      <Box>
        {!roles.isLoading && roles.data !== null ? (
          <Select
            isRequired
            placeholder={`Roles - ${t('forms:all')}`}
            name='rolId'
            value={filters.rolId}
            onChange={e => handleFilters(FILTER_KEYS.rolId, e.target.value)}
          >
            {roles.data.map(rol => (
              <option key={rol.id} value={rol.id}>
                {rol.name}
              </option>
            ))}
          </Select>
        ) : (
          <Select placeholder={`${t('forms:loading')}...`}></Select>
        )}
      </Box>

      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.username, e.target.value)}
        value={filters.username}
        name='username'
        inputProps={{
          placeholder: `${t('forms:name')} (${t('common:optional')})`,
        }}
      />
      <Box style={{ display: 'flex', gap: '1rem' }}>
        <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>

        <Button variantColor='blue' variant='outline' onClick={handleResetFilters}>
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
