import React from 'react'
import Users from '.'
import { SectionDataProvider } from '../../../../context/SectionDataContext'

const FILTER_KEYS = {
  aplicacionId: 'aplicacionId',
  rolId: 'rolId',
  username: 'username',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  usuarios: 'usuarios'
}

const defaultSectionDataValues = {
  usuarios: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 15,
  aplicacionId: '',
  rolId: '',
  username: '',
}

const defaultFiltersValues = { ...defaultFilters }

const UsersSection = () => (
  <SectionDataProvider defaultFilters={{ ...defaultFiltersValues }} defaultSectionData={defaultSectionDataValues}>
    <Users />
  </SectionDataProvider>
)

export {
  UsersSection,
  FILTER_KEYS,
  SECTION_DATA_KEYS,
  defaultSectionDataValues,
  defaultFiltersValues,
  defaultFilters,
}
