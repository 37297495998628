import { Box, Spinner } from "@chakra-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Panel } from "./helpers";
import Table from "./table";

export default function TableAsync({ 
	isLoading, 
	columns = [], 
	data = [],
	errorMsg = false,
	...restProps
}) {
	const { t } = useTranslation(["common"]);
	return (
		<Box {...restProps}>
			<Table 
				columns={columns} 
				data={(!isLoading && data) || []} 
			/>
			{isLoading && (
				<Panel
					textAlign="center"
					color="gray.500"
				>
					<Spinner />
				</Panel>
			)}
			{!isLoading && !data && (
				<Panel
					textAlign="center"
					color="gray.500"
				>
					{errorMsg || t("common:zeroItems")}
				</Panel>
			)}
			{!isLoading && data && data.length === 0 && (
				<Panel
					textAlign="center"
					color="gray.500"
				>
					{t("common:zeroItems")}
				</Panel>
			)}
		</Box>
	);
}
