import { BANNERS_RESET_DATA, BANNERS_UPDATE_KEY, BANNERS_GET_CATEGORIES } from "../actions/actions-type";
import initialState from "./initial-state";
import { createReducer } from "./reducer-creator";

const updateKey = (state, action) => ({
	...state,
	...action.payload,
});

const resetBannerData = (state) => ({
	...state,
	categories: { isLoading: true, error: false, items: null },
	allCategories: { isLoading: true, error: false, items: null },
	banners: { isLoading: true, error: false, items: null },
	allBanners: { isLoading: true, error: false, items: null },
	ignoreCategories: { isLoading: true, error: false, items: null },
	ubications: { isLoading: true, error: false, items: null }
});

export default createReducer(initialState.bannersState, {
	[BANNERS_UPDATE_KEY]: updateKey,
	[BANNERS_RESET_DATA]: resetBannerData
});