import React, { useState, useEffect } from 'react'
import { Stack, Box, FormLabel, Button, PseudoBox, SimpleGrid, Checkbox, Text } from '@chakra-ui/core'
import FormInput from '../form-input'
import { useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelectAsync from '../../common/select-async'
import { getToken } from '../../../redux/selectors'
import { getProvinciasByPaisId, getLocalidadesByProvinciaId } from '../../../api/geo'
import { getAppInformation } from '../../../api/application'
import { useParams } from 'react-router-dom'

export const BranchOfficesForm = ({ onSubmit, formData = false, buttonLabel = false, ...restProps }) => {
  const { t } = useTranslation(['common', 'forms', 'applications'])
  const { appId } = useParams()
  const [submitBlock, setSubmitBlock] = useState(false)
  const token = useSelector(getToken)

  const { handleSubmit, register, control, setValue } = useForm({
    defaultValues: formData,
  })

  //Homologación de Tablas de provincias
  const [countryAppId, setcountryAppId] = useState({
    isLoading: false,
    data: undefined,
    error: false,
  })

  async function getCountryUserApp() {
    setcountryAppId((state) => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAppInformation(appId, token)
      setcountryAppId({
        isLoading: false,
        data: res.data.paisId,
        error: false,
      })
    } catch (error) {
      setcountryAppId((state) => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  useEffect(() => {
    getCountryUserApp()
    if(formData){
      getLocalidades(formData.provinciaId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //TRAER PROVINCIAS
  const [prov, setProv] = useState({
    isLoading: false,
    data: [],
    error: false,
  })

  async function getProvincias(paisId) {
    console.log(paisId)
    setProv((state) => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getProvinciasByPaisId({paisId: paisId})
      setProv((state) => ({
        ...state,
        isLoading: false,
        data: res.data || [],
      }))
    } catch (error) {
      setProv((state) => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }


  useEffect(() => {
    if (countryAppId.data) {
      getProvincias(countryAppId.data)
    }
  }, [countryAppId])

  //LOCALIDAD
  const [hiddenLocalidad, setHiddenLocalidad] = useState(true)
  const [loc, setLoc] = useState({
    isLoading: false,
    data: [],
    error: false,
  })
  async function getLocalidades(id) {
    setLoc((state) => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getLocalidadesByProvinciaId({provinciaId:id})
      setLoc((state) => ({
        ...state,
        isLoading: false,
        data: (res.data && res.data) || [],
      }))
    } catch (error) {
      setLoc((state) => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  const onChangePcia = (e) => {
    getLocalidades(e.target.value)
    if (e.target.value > 0) {
      setHiddenLocalidad(false)
    } else {
      setHiddenLocalidad(true)
    }
    return e.target.value
  }

  async function submit(data) {
    setSubmitBlock(true)
    await onSubmit(data)
    setSubmitBlock(false)
  }

  const [isCasaCentral, setCasaCentral] = useState(formData.casaCentral ? formData.casaCentral : false)
  const handleChange = () => {
    setCasaCentral(!isCasaCentral)
  }

  return (
    <form
      as="form"
      /* onSubmit={handleSubmit(submit)} */
      onSubmit={handleSubmit((d) =>
        submit({
          ...d,
          isCasaCentral,
        })
      )}
      {...restProps}
    >
      <Stack>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
          {/* PROVINCIA */}

          <Box /* hidden={countrySelectorConfig.ishidden} */>
            <FormLabel isRequired>{t('forms:province')}</FormLabel>
            <Controller
              control={control}
              name="provincia"
              defaultValue={formData !== false && formData.provinciaId}
              render={({ onChange, value, name }) => (
                <SelectAsync
                  //onChange={(e) => onChange(e.target.value)}
                  onChange={(e) => onChange(onChangePcia(e))}
                  placeholder={`${t('forms:selectOne')} ${t('forms:province')}`}
                  name={name}
                  value={value}
                  isRequired
                  isLoading={prov.isLoading}
                >
                  {prov.data &&
                    prov.data.map((prov) => (
                      <option key={prov.id} value={prov.id}>
                        {prov.nombre}
                      </option>
                    ))}
                </SelectAsync>
              )}
            />
          </Box>

          {/* LOCALIDAD */}
          <Box>
            <FormLabel isRequired>{t('forms:location')}</FormLabel>
            <Controller
              control={control}
              name="localidad"
              defaultValue={formData !== false && formData.localidadId}
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={(e) => onChange(e.target.value)}
                  placeholder={`${t('forms:selectOne')} ${t('forms:location')}`}
                  name={name}
                  value={value}
                  isRequired
                  isLoading={loc.isLoading}
                >
                  {loc.data &&
                    loc.data.map((loc) => {
                        return (
                          <option key={loc.id} value={loc.id}>
                            {loc.nombre}
                          </option>
                        )
                      })}
                </SelectAsync>
              )}
            />
          </Box>

          {/* DIRECCIÓN */}
          <FormInput
            isRequired
            name="direccion"
            label={t('forms:address')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:address'),
              ref: register,
            }}
          />

          {/* TELEFONO */}
          <FormInput
            isRequired
            name="telefono"
            label={t('forms:phone')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:phone'),
              ref: register,
            }}
          />

          {/* LATITUD */}
          <FormInput
            isRequired
            name="latitud"
            label={t('forms:latitude')}
            inputProps={{
              type: 'tel',
              placeholder: t('forms:latitude'),
              ref: register,
            }}
          />

          {/* LONGITUD */}
          <FormInput
            isRequired
            name="longitud"
            label={t('forms:length')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:length'),
              ref: register,
            }}
          />

          {/* HORARIO */}
          <FormInput
            isRequired
            name="horario"
            label={t('forms:openingHours')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:openingHours'),
              ref: register,
            }}
          />

          {/* EMAIL */}
          <FormInput
            isRequired
            name="email"
            label={t('forms:email')}
            inputProps={{
              type: 'email',
              placeholder: t('forms:email'),
              ref: register,
            }}
          />

          {/* NOMBRE */}
          <FormInput
            isRequired
            name="nombre"
            label={t('forms:name')}
            inputProps={{
              type: 'text',
              placeholder: t('forms:name'),
              ref: register,
            }}
          />

          {/* CasaCentral */}
          <Box d="flex" justifyContent="left" alignSelf="end">
            <Text>
              {' '}
              <b>{t('forms:centralHouse')}</b>
            </Text>
            <Checkbox isChecked={isCasaCentral} size="lg" ml="5" onChange={handleChange} />
          </Box>
        </SimpleGrid>
      </Stack>
      <Button mt={6} variantColor="blue" variant="outline" type="submit" isLoading={submitBlock}>
        {buttonLabel || t('common:create')}
      </Button>
    </form>
  )
}
