import React, { useEffect } from "react";
import { Box, Button, Flex, Grid, Heading, List } from "@chakra-ui/core";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import { useRouteMatch } from "react-router-dom";
import NavigatorDrawer from "../../common/drawer/navigator-drawer";
import { PageContainer } from "../../common/helpers";
import { ListItemRoute } from "../../common/router-components";

import Banner from "./banner";
import DefaultCategory from "./defaultCategory";
import {Categories} from "./category";
import { resetBannersData } from "../../../redux/actions/banners-actions";
import { useDispatch } from "react-redux";
import "../../styles/banner/banner.css";
import AOS from "aos";

export default function Banners() {
	const { t } = useTranslation(["banners", "common"]);
	const { path, url } = useRouteMatch();

	const childrenRoutes = [
		{
			exact: true,
			path: [`${path}/`, `${path}/banner`],
			component: Banner
		},
		{
			path: `${path}/default-categories`,
			component: DefaultCategory
		},
		{
			path: `${path}/categories`,
			component: Categories
		},
		/* {
			path: `${path}/ubications`,
			component: Ubication
		}, */

	];
	
	const labelTitleTop = `${t("common:navbar.banners")}`;

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(resetBannersData());
		AOS.init({
			duration: 1000
		});
		AOS.refresh();
	}, [dispatch]);

	const header = (onOpen, btnRef) => (
		<Grid templateColumns={{ base: "6fr 1fr", md: "7fr 0fr" }} gap={[3, 3, 0]}>
			<Box backgroundColor='' py="16px" color="#103059">
				<Heading style={{fontSize: "1.8rem"}} mb={0}>{labelTitleTop}</Heading>
			</Box>
			<Flex justify='flex-end'>
				<Button
					display={{ base: "inherit", md: "none" }}
					mb="10px"
					ref={btnRef}
					variant='outline'
					variantColor="blue"
					onClick={onOpen}
				>
					<Box as={GiHamburgerMenu} display="inline" />
				</Button>
			</Flex>
		</Grid>
	);

	return (
		<PageContainer>
			<NavigatorDrawer
				header={header}
				routes={childrenRoutes}
				spacing="16px"
			>
				<List spacing={3}>
					<ListItemRoute to={`${url}`}>
						{t("banners:principal")}
					</ListItemRoute>
					<ListItemRoute to={`${url}/default-categories`}>
						{t("banners:deactivate-categories")}
					</ListItemRoute>
					<ListItemRoute to={`${url}/categories`}>
						{t("banners:categories")}
					</ListItemRoute>
				</List>
			</NavigatorDrawer>
		</PageContainer>
	);
}