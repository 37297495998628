import React, { useMemo, useState, useEffect } from 'react'
import { PageContainer, Panel } from '../../common/helpers'
import { Button, Box, Heading, Switch } from '@chakra-ui/core'
import { Grid, GridItem } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import ModalSimple from '../../common/modal-simple'
import { SingleDatePicker } from 'react-dates'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserCountryId, getUserApplicationId } from '../../../redux/selectors'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import FormInput from '../form-input'
import FormTextArea from '../form-textarea'
import SelectAsync from '../../common/select-async'
import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { getAllCountries } from '../../../api/application'
import { obtenerCategorias } from '../../../api/communications'
import moment from 'moment'
import { useDisclosure } from '@chakra-ui/core'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser'
import JEditor from '../../views/sections/own-sections/new-editor'

function NewsForm({ formData = false, padre, section }) {
  // - - General - -

  const token = useSelector((state) => state.appState.user.token)
  const [paises, setPaises] = useState([])
  const [preview, setPreview] = useState(false)
  const [country, setCountry] = useState('')
  const [previewInf, setPreviewInf] = useState(false)
  const [categories, setCategories] = useState([])
  const [allCategories, setAllCategories] = useState(false)
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const applicationId = useSelector(getUserApplicationId)
  const { t } = useTranslation(['comunications'])
  const modalDisclosure4 = useDisclosure()

  // - - 🖥️ URL-Params 🖥️ - -

  let location = useLocation()
  let claveMagica = location.search.replace(/^./, '')

  // - - 📅 SingleDatePicker 📅 - -

  const [convertSw, setConvertSw] = useState(false)
  const [focusedInput, setFocusedInput] = useState(false)
  const [startDate, setStartDate] = useState(moment(formData.fecha))

  // - - 📋 Form 📋 - -

  const { handleSubmit, register, control, setValue, reset, formState } = useForm({
    defaultValues: formData,
  })

  // - - 📋 HTML 📋 - -

  const [contentValue, setContentValue] = useState('')
  const [resetEditor, setResetEditor] = useState(false)
  const [switchOptions, setSwitchOptions] = useState({
    destacado: ' ',
    borderColor: 'white',
  })

  // - - 🌥️ Cloudinary 🌥️- -

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_PRESET, REACT_APP_CLOUDINARY_FOLDER } = process.env

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(contentValue)))
  )
  function funcImg() {
    if (formData == false) {
      return 'https://res.cloudinary.com/travelit/image/upload/v1629216295/img-test/wbtbsdtjjv6cpz6cirki.jpg'
    } else {
      return formData.imagenUrl
    }
  }

  const [imagState, setImagState] = useState([{ image_url: funcImg() }])

  const myCropWidget = useMemo(
    () =>
      window?.cloudinary?.createUploadWidget(
        {
          cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
          uploadPreset: REACT_APP_CLOUDINARY_PRESET,
          folder: REACT_APP_CLOUDINARY_FOLDER,
          cropping: true,
        },
        (error, result) => {
          if (error) {
            alert('Cloudinary service unavailable')
          }
          checkUploadResult(result)
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  function checkUploadResult(resultEvent) {
    if (resultEvent.event === 'success') {
      const img = [
        {
          image_url: resultEvent.info.secure_url,
        },
      ]
      setImagState(img)
    }
  }

  function showWidget() {
    myCropWidget.open()
  }

  function setOnChange(e) {
    setCountry(e)
    if (!formData) {
      getComunicadosCategoria(e)
    }
  }
  // - - ❗ Inputs ❗ - -

  function onChangeTrip(e, flg = null) {
    setStartDate(e)
  }

  function seePreview() {
    setPreview(!preview)
  }

  function convert() {
    setConvertSw(!convertSw)
  }

  function onSubmit(data) {
    function idGen(dat) {
      if (typeof dat === 'number') {
        return formData.categoriasComunicados.id
      } else {
        let devolver = allCategories.filter((x) => x.descripcion == dat)
        return devolver[0].id
      }
    }
    let idFInal = idGen(data.categoriasComunicados.id)

    function comunicadoHtmlType() {
      if (formData == false) {
        if (convertSw !== true) {
          if (contentValue == '<p></p>\n') {
            return null
          } else {
            return contentValue
          }
        } else {
          return data.comunicadoHtml
        }
      } else {
        if (convertSw !== true) {
          if (contentValue == '<p></p>\n') {
            return formData.comunicadoHtml
          } else {
            return contentValue
          }
        } else {
          return data.comunicadoHtml
        }
      }
    }

    function asuntoType() {
      if (formData == false) {
        if (convertSw !== true) {
          return 'html'
        } else {
          return 'newsletter'
        }
      } else {
        return formData.asunto
      }
    }
    function esDestacado() {
      if (formData) {
        return formData.destacado
      } else {
        return false
      }
    }
    let datas = {
      aplicacionId: applicationId,
      asunto: asuntoType(),
      categoriasComunicados: { id: idFInal },
      tipoEnvio: { id: 1 },
      comunicadoHtml: comunicadoHtmlType(),
      descripcion: data.descripcion,
      destacado: esDestacado(),
      fecha: startDate._d,
      imagenUrl: imagState[0].image_url,
      paisId: parseInt(country),
      subtitulo: data.subtitulo,
      tipoComunicado: { id: 1 },
      titulo: data.titulo,
      url: data.url,
      sesion: {
        token: token,
        modulo: '',
        accion: '',
        nombre: '',
      },
    }
    if (preview == true) {
      setPreviewInf(comunicadoHtmlType())
      modalDisclosure4.onOpen()
    } else {
      //  console.log(datas)
      padre(datas, '/news')
    }
  }

  async function getCountrys() {
    try {
      const resp = await getAllCountries(token)
      setPaises(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  async function getComunicadosCategoria(a) {
    try {
      const resp = await obtenerCategorias(a, authToken)
      let filterDate = resp.data.filter((x) => x.fechaBaja == null)
      setAllCategories(filterDate)
      let filterSplit = filterDate.map((x) => x.descripcion.split('/'))
      if (claveMagica == '') {
        let filterCategorie = filterSplit.filter((x) => x[1] == (section == 'calendar' ? 'events' : section))
        setCategories(filterCategorie)
      } else {
        let filterCategorie = filterSplit.filter((x) => x[1] == (claveMagica == 'calendar' ? 'events' : claveMagica))
        setCategories(filterCategorie)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCountrys()
    if (formData) {
      getComunicadosCategoria(formData.paisId)
    }
    if (formState.isSubmitSuccessful) {
      reset({})
      setResetEditor(!resetEditor)
    }
  }, [reset])

  return (
    <>
      <PageContainer style={{ padding: '1.2rem' }}>
        <Panel rounded="md" style={{ borderColor: `${switchOptions.borderColor}` }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
              {/* Title */}
              <GridItem colSpan={3} rowSpan={1}>
                <Heading style={{ fontSize: '1.8rem', margin: '0% 0% 2%' }}>
                  {' '}
                  {claveMagica == 'news' ? 'Noticias' : 'Eventos'}
                  {claveMagica == 'n' ? `${switchOptions.destacado}` : `${switchOptions.destacado}`}
                </Heading>
              </GridItem>

              {/* Pais */}
              <GridItem colSpan={1} rowSpan={1}>
                <Box style={{ margin: '0% 1%', padding: '1%' }}>
                  <label style={{ margin: '2rem 0rem' }}> {t('comunications:country')}</label>
                  <Controller
                    control={control}
                    name="paisId"
                    render={({ onChange, value, name }) => (
                      <SelectAsync
                        style={{ marginTop: '4px' }}
                        name={name}
                        // onChange={(e) => onChange(e.target.value)}
                        onChange={(e) => setOnChange(e.target.value)}
                        placeholder={`${t('comunications:selectCountry')}`}
                        value={value}
                        isRequired={true}
                      >
                        {paises
                          .sort((a, b) => a.nombre.localeCompare(b.nombre))
                          .map(({ id, nombre }) => (
                            <option key={id} value={id}>
                              {nombre}
                            </option>
                          ))}
                      </SelectAsync>
                    )}
                  />
                </Box>
              </GridItem>

              {/* Categoria Comunicado */}
              <GridItem colSpan={1} rowSpan={1}>
                <Box style={{ margin: '0% 1%', padding: '1%' }}>
                  <label style={{ margin: '2rem 0rem' }}> {t('comunications:comunicationCategory')}</label>
                  <Controller
                    control={control}
                    name="categoriasComunicados.id"
                    render={({ onChange, value, name }) => (
                      <SelectAsync
                        style={{ marginTop: '4px' }}
                        name={name}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder={`${t('comunications:comunicationCategory')}`}
                        value={value}
                        isRequired={formData == false ? true : false}
                      >
                        {categories.map((x) => (
                          <option key={x[0]} value={x[0] + '/' + x[1]}>
                            {x[0]}
                          </option>
                        ))}
                      </SelectAsync>
                    )}
                  />
                </Box>
              </GridItem>

              {/* imagen */}
              <GridItem colSpan={1} rowSpan={3}>
                {/* <label>  {t("comunications:uploadImage")}</label> */}
                <Box
                  style={{ margin: '0% 5%', height: '30vh' }}
                  backgroundImage={`url(${imagState[0].image_url})`}
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  d="flex"
                  backgroundSize="cover"
                  height={400}
                  justifyContent="center"
                  //alignContent="flex-end"
                >
                  <Button size="lg" onClick={showWidget} variantColor="blue" alignSelf="center">
                    {t('comunications:uploadImage')}
                  </Button>
                </Box>
              </GridItem>

              {/* Titulo */}
              <FormInput
                style={{ margin: '0% 1%', padding: '1%' }}
                isRequired
                name="titulo"
                label={t('comunications:title')}
                inputProps={{
                  type: 'tel',
                  placeholder: t('comunications:title'),
                  ref: register,
                }}
              />

              {/* Subtitulo */}
              <FormInput
                style={{ margin: '0% 1%', padding: '1%' }}
                name="subtitulo"
                label={t('comunications:linkName')}
                inputProps={{
                  type: 'tel',
                  placeholder: t('comunications:linkName'),
                  ref: register,
                }}
              />

              {/* URL */}
              <GridItem colSpan={2} rowSpan={1}>
                <FormInput
                  style={{ marginTop: '0% 1%', padding: '1%' }}
                  name="url"
                  label={t('comunications:url')}
                  inputProps={{
                    type: 'string',
                    placeholder: t('comunications:url'),
                    ref: register,
                  }}
                />
              </GridItem>

              {/* Descripcion */}
              <GridItem colSpan={2} rowSpan={1}>
                <FormInput
                  isRequired
                  style={{ marginTop: '0% 1%', padding: '1%' }}
                  name="descripcion"
                  label={t('comunications:details')}
                  inputProps={{
                    type: 'string',
                    placeholder: t('comunications:details'),
                    ref: register,
                  }}
                />
              </GridItem>

              {/* Fecha de salida */}
              <Grid templateColumns="repeat(2, 1fr)" gap={0}>
                <Box style={{ marginLeft: '1.5rem', marginTop: '0.5rem', padding: '0.2rem', width: '100%' }}>
                  <label> {t('comunications:date')}</label>

                  <Box style={{ marginTop: '1%', width: '50%' }}>
                    <SingleDatePicker
                      isOutsideRange={() => false}
                      small={true}
                      showClearDate={true}
                      showDefaultInputIcon={false}
                      displayFormat="YYYY-MM-DD"
                      numberOfMonths={1}
                      placeholder="YYYY-MM-DD"
                      date={startDate}
                      onDateChange={(date) => onChangeTrip(date, 'departure_date')}
                      focused={focusedInput}
                      onFocusChange={() => setFocusedInput(!focusedInput)}
                      id="departure_date"
                    />
                  </Box>
                </Box>

                {/* Asunto - Color */}
                {/* <Box
        style={{width:"70%",marginTop:"0.5rem"}} >
            <label>  {t("comunications:Color")}</label>

                <Controller
                control={control}
                name="asunto"
                render={({ onChange, value, name }) => (
                    <SelectAsync
                        style={{marginTop:"4px"}}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder={`${t("comunications:Color")}`}
                        name={name}
                        value={value}
                        isRequired={formData === false ? true : false}
                    >
                        <option style={{backgroundColor:"#3fc5ea"}} value={"#3fc5ea"}>Cyan</option>
                        <option style={{backgroundColor:"#fcb617"}} value={"#fcb617"}>Amarillo</option>
                        <option style={{backgroundColor:"#9d2062"}} value={"#9d2062"}>Violeta</option>
                        <option style={{backgroundColor:"#28dd98"}} value={"#28dd98"}>Verde</option>

                    </SelectAsync>
                )}
            />
        </Box> */}
              </Grid>

              <GridItem colSpan={3} rowSpan={2}>
                <label> {t('comunications:comunicadoHtml')}</label>

                <Switch style={{ padding: '0.5rem', marginLeft: '61%' }} onChange={() => convert()}></Switch>
                {t('comunications:inputType')}
              </GridItem>

              {/* Comunicado HTML */}
              {convertSw == false ? (
                <GridItem colSpan={3} rowSpan={2}>
                  <Box
                    style={{
                      margin: '1%',
                      borderWidth: 0.5,
                      borderColor: '#e5e5e5',
                    }}
                  >
                    <JEditor content={contentValue} setContent={setContentValue} reset={resetEditor} />
                  </Box>

                  <div style={{ float: 'right', marginRight: '48%', marginTop: '2%' }}>
                    {t('comunications:seePreview')}

                    <Switch style={{ padding: '0.5rem', marginLeft: '61%' }} onChange={() => seePreview()}></Switch>
                  </div>
                </GridItem>
              ) : (
                <GridItem colSpan={3} rowSpan={5} style={{ height: '300%' }}>
                  <FormTextArea
                    name="comunicadoHtml"
                    label={t('comunications:HTMLdetails')}
                    inputProps={{
                      type: 'string',
                      placeholder: t('comunications:HTMLdetails'),
                      ref: register,
                    }}
                  />
                  <div style={{ float: 'right', marginRight: '48%', marginTop: '2%' }}>
                    {t('comunications:seePreview')}

                    <Switch style={{ padding: '0.5rem', marginLeft: '61%' }} onChange={() => seePreview()}></Switch>
                  </div>
                </GridItem>
              )}

              {/* Descripcion */}
              <GridItem colSpan={3} rowSpan={1}>
                <div style={{ float: 'right', marginRight: '2%' }}>
                  <Button variantColor="blue" type="submit">
                    {preview == false ? t('comunications:createNews') : t('comunications:seePreview')}
                  </Button>
                </div>
              </GridItem>

              {/* Preview */}
              <ModalSimple
                isOpen={modalDisclosure4.isOpen}
                onClose={modalDisclosure4.onClose}
                title={`${t('comunications:deleteItem')}`}
                size="6xl"
              >
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {previewInf === false ? null : <div>{ReactHtmlParser(previewInf)}</div>}
                </div>

                <div style={{ float: 'right', margin: '4% 0% 0%' }}>
                  <Button style={{ height: '2.1rem', padding: '-2' }} variantColor="blue">
                    {t('comunications:close')}
                  </Button>
                </div>
              </ModalSimple>
            </Grid>
          </form>
        </Panel>
      </PageContainer>
    </>
  )
}

export default NewsForm
