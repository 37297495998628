import React from 'react'

import { SectionDataProvider } from '../../../context/SectionDataContext'
import Apps from './Apps-content'

const FILTER_KEYS = {
  paisId: 'paisId',
  dominio: 'dominio',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  agencias: 'agencias',
}

const defaultSectionDataValues = {
  agencias: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFiltersValues = {
  page: 1,
  maxPerPage: 12,
  paisId: '',
  dominio: '',
}

const Application = () => (
  <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
    <Apps />
  </SectionDataProvider>
)

export { Application, FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues, defaultFiltersValues }
