import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/core'
import { Grid, GridItem } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSectionData } from '../../../hooks/useSectionData'

export default function SupplierAgreementsComparative(props) {
  const { sectionData } = useSectionData()
  const { t } = useTranslation(['suppliers', 'commons'])
  const styles = {
    borderWidth: 0.5,
    borderColor: '#e5e5e5',
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
  }

  const truncateString = (nombre) => {
    return nombre.length > 40
    ? nombre.substring(0, 37) + "..."
    : nombre
  } 

  return (
    <div>
      {!!sectionData.acuerdos.length ? (
        <Box
          mb='20px'
          {...props}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#EDF2F7',
            marginTop: '2rem',
            borderRadius: '10px',
            padding: '1.5rem 1.5em',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
          }}
        >
          <Grid
            templateColumns='20% 15% 15% 15% 15%'
            templateRows='repeat(3, 1fr)'
            gap={9}
            style={{
              width: '90vw',
            }}
          >
            <GridItem colStart={1} colEnd={1} rowStart={1} rowEnd={1}>
              <Box style={styles}>
                {' '}
                <b>{t('suppliers:product')}</b>
              </Box>
            </GridItem>
            <GridItem colStart={1} colEnd={1} rowStart={2} rowEnd={2}>
              <Box style={styles}>
                {' '}
                <b>{t('suppliers:supplier')}</b>
              </Box>
            </GridItem>
            <GridItem colStart={1} colEnd={1} rowStart={3} rowEnd={3}>
              <Box style={styles}>
                {' '}
                <b>{t('suppliers:commission')}</b>{' '}
              </Box>
            </GridItem>
            <GridItem colStart={2} colEnd={7} rowStart={1} rowEnd={1}>
              <Box style={styles}>{sectionData.acuerdos[0].producto}</Box>
            </GridItem>
            {sectionData.acuerdos.map(acuerdo => {
              return (
                <>
                  <GridItem rowStart={2} rowEnd={2} display='flex' gap={8}>
                    <Box
                      style={{
                        borderWidth: 0.5,
                        borderColor: '#e5e5e5',
                        backgroundColor: 'white',
                        height: '3.5rem',
                        width: '10rem',
                        margin: 'auto',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
                      }}
                      title={acuerdo.proveedor.nombre}
                    >
                      {acuerdo.proveedor.nombre ? truncateString(acuerdo.proveedor.nombre) : ''}
                    </Box>
                  </GridItem>
                  <GridItem rowStart={3} rowEnd={3} display='flex' gap={10}>
                    <Box
                      style={{
                        borderWidth: 0.5,
                        borderColor: '#e5e5e5',
                        backgroundColor: 'white',
                        height: '3.5rem',
                        width: '10rem',
                        margin: 'auto',
                        borderRadius: '10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)',
                      }}
                    >
                      {`${acuerdo.comision}%` || ''}
                    </Box>
                  </GridItem>
                </>
              )
            })}
          </Grid>
        </Box>
      ) : (
        ''
      )}
    </div>
  )
}
