import { Box, Button, Grid, Select, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectAsync from '../../common/select-async'
import FormInput from '../../forms/form-input'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserApplication, getUserRolId } from '../../../redux/selectors'
import { getAllCountries } from '../../../api/application'
import { toastCustomizedError } from '../../common/toast-default-config'
import { getIncentivesByCategory, getIncentivesByProvider } from '../../../api/incentives'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS } from '.'

export default function IncentivesSearchForm({ onFilter, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const userRolId = useSelector(getUserRolId)
  const { t } = useTranslation(['common', 'forms', 'incentives', 'comunications'])
  const permisosUser = userRolId == 1 ? true : false
  const { paisId: countryId } = useSelector(getUserApplication)
  const authToken = useSelector(getAuthToken)

  const toast = useToast()
  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
  })

  const [proveedor, setProveedor] = useState({
    data: [],
    isLoading: true,
  })

  const [categoria, setCategoria] = useState({
    data: [],
    isLoading: true,
  })

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries(authToken)
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getIncentiveProveedor = async (filters) => {
    setProveedor(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getIncentivesByProvider(filters, authToken)
      setProveedor(() => ({
        data: response.data.sort((a, b) => {
          if (a.label > b.label) return 1
          if (a.label < b.label) return -1
          return 0
        }),
        isLoading: false,
      }))
    } catch (error) {
      setProveedor(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getIncentiveCategoria = async (filters) => {
    setCategoria(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getIncentivesByCategory(filters, authToken)
      setCategoria(() => ({
        data: response.data.sort((a, b) => {
          if (a.label > b.label) return 1
          if (a.label < b.label) return -1
          return 0
        }),
        isLoading: false,
      }))
    } catch (error) {
      setCategoria(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const handleNameInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      onFilter();
    }
  }

  useEffect(() => {
    getCountries()
  }, [])

  useEffect(() => {
    if (!permisosUser) {
      handleFilters(FILTER_KEYS.paisId, countryId)
    }
  }, [countries])

  useEffect(() => {
    if (!!filters.paisId) {
      getIncentiveProveedor({paisId: filters.paisId, categoriaId: null})
      getIncentiveCategoria({paisId: filters.paisId, proveedorId: null})
    } else {
      setProveedor(state => ({
        ...state,
        data: [],
      }))
      setCategoria(state => ({
        ...state,
        data: [],
      }))
    }
    
    handleFilters(FILTER_KEYS.proveedorId, undefined)
    handleFilters(FILTER_KEYS.categoriaId, undefined)
  }, [filters.paisId])

  useEffect(() => {
    if (!filters.paisId) {
      return
    }

    getIncentiveCategoria({paisId: filters.paisId, proveedorId: filters.proveedorId})
  }, [filters.proveedorId, filters.paisId])

  useEffect(() => {
    if (!filters.paisId) {
      return
    }

    getIncentiveProveedor({paisId: filters.paisId, categoriaId: filters.categoriaId})
  }, [filters.categoriaId, filters.paisId])

  useEffect(() => {
    if (!categoria.data || categoria.data.length === 0) {
      return
    }

    if (categoria.data.length === 1){
      handleFilters(FILTER_KEYS.categoriaId, categoria.data[0].id)
    }

    if (categoria.data.length > 1){
      handleFilters(FILTER_KEYS.categoriaId, undefined)
    }
  }, [categoria.data])

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: `${permisosUser ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)'}` }}
      alignItems='flex-end'
      gap={6}
      {...restProps}
    >
      {permisosUser && (
        <Box>
          <Select
            onChange={({ target }) => {
              handleFilters(FILTER_KEYS.proveedor, undefined)
              handleFilters(FILTER_KEYS.paisId, target.value)
            }}
            placeholder={`${t('forms:countries')} - ${t('forms:all')}`}
            value={filters.paisId}
            isLoading={countries.isLoading}
          >
            {countries.data
              .sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {nombre}
                </option>
              ))}
          </Select>
        </Box>
      )}
      <Box>
        <SelectAsync
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.categoriaId, target.value)
          }}
          placeholder={`${t('forms:category')} - ${t('forms:all')}`}
          value={filters.categoriaId}
          isDisabled={!filters.paisId}
        >
          {categoria?.data?.map(item => (
            <option key={`categoria-combo-${item.id}`} value={item.id}>
              {item.label}
            </option>
          ))}
        </SelectAsync>
      </Box>
      <Box>
        <SelectAsync
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.proveedorId, target.value)
          }}
          placeholder={`${t('forms:supplier')} - ${t('forms:all')}`}
          value={filters.proveedorId}
          isDisabled={!filters.paisId}
        >
          {proveedor?.data?.map(item => (
            <option key={`proveedor-combo-${item.id}`} value={item.id}>
              {item.label}
            </option>
          ))}
        </SelectAsync>
      </Box>
      <Box>
        <Select
          onChange={e => handleFilters(FILTER_KEYS.status, e.target.value)}
          placeholder={`${t('incentives:status')} - ${t('forms:all')}`}
          name='status'
          value={filters.status}
          isDisabled={!filters.paisId}
        >
          <option value={true}>
            {t('incentives:status')} - {t('incentives:active')}
          </option>
          <option value={false}>
            {t('incentives:status')} - {t('incentives:inactive')}
          </option>
        </Select>
      </Box>
      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.nombre, e.target.value)}
        onKeyDown={handleNameInputKeyDown}
        name='nombre'
        value={filters.nombre}
        inputProps={{
          placeholder: `${t('incentives:incentive_name')} (${t('common:optional')})`,
        }}
      />
      <Box style={{display: 'flex', gap: '1rem' }}>
        <Button flex='2' maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>
        <Button flex='2' variant='outline' variantColor='blue' type='button' onClick={handleResetFilters}>
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
