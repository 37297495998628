import React, { useState, useEffect } from 'react'
import { Box, useDisclosure, useToast, Spinner, Text } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { getAppInformation } from '../../../api/application'
import Table from '../../common/table'
import { toastCustomizedError } from '../../common/toast-default-config'
import { useSelector } from 'react-redux'
import { getCountriesState, getToken, getUserApplicationId } from '../../../redux/selectors'

export const Ejecutivo = () => {
  const { t } = useTranslation(['applications', 'common', 'forms', 'login', 'comunications'])
  const modalEditDisclosure = useDisclosure()
  const toast = useToast()
  const UserApplicationId = useSelector(getUserApplicationId)
  const countries = useSelector(getCountriesState)
  const [editingComercialData, setEditingComercialData] = useState(null)
  const token = useSelector(getToken)
  const [comercial, setComercial] = useState({
    data: [],
    isLoading: false,
    error: null,
  })

  const columns = React.useMemo(() => {
    function prepareEditComerial(dataComercial) {
      setEditingComercialData(dataComercial)
      modalEditDisclosure.onOpen()
    }

    function truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }
    return [
      {
        Header: t('forms:imagen'),
        accessor: 'imagenContacto',
        maxWidth: 100,
        minWidth: 100,
        Cell: ({ cell }) => (
          <img src={cell.row.values.imagenContacto ? cell.row.values.imagenContacto : null} width={100} />
        ),
      },
      {
        Header: t('comunications:country'),
        accessor: 'paisId',
        Cell: ({ cell }) => <p>{countries?.find(c => c.id === cell.row.values.paisId)?.nombre}</p>,
      },
      {
        Header: t('forms:name'),
        accessor: 'nombre',
        Cell: ({ cell }) => <p>{truncateText(cell.row.values.nombre, 30)}</p>,
      },
      {
        Header: t('forms:lastname'),
        accessor: 'apellido',
        Cell: ({ cell }) => <p>{truncateText(cell.row.values.apellido, 30)}</p>,
      },
      {
        Header: t('applications:email'),
        accessor: 'emailContacto',
        Cell: ({ cell }) => (
          <p>{cell.row.values.emailContacto ? truncateText(cell.row.values.emailContacto, 30) : null}</p>
        ),
      },
      {
        Header: t('applications:phone'),
        accessor: 'telefonoContacto',
        Cell: ({ cell }) => (
          <p>{cell.row.values.telefonoContacto ? truncateText(cell.row.values.telefonoContacto, 30) : null}</p>
        ),
      },
      {
        Header: t('applications:cell'),
        accessor: 'celularContacto',
        Cell: ({ cell }) => (
          <p>{cell.row.values.celularContacto ? truncateText(cell.row.values.celularContacto, 30) : null}</p>
        ),
      },
    ]
  }, [modalEditDisclosure, t])

  const getComercial = async () => {
    setComercial({
        data: [],
        isLoading: true,
        error: null,
    })
    try {
      const response = await getAppInformation(UserApplicationId, token)
      setComercial({
        data: [response.data.comercial],
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setComercial({
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getComercial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box flex='2' style={{ paddingRight: '1rem' }}>
      <>
        <Box>
          {comercial.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}
          {!comercial.isLoading && comercial.data[0] !== null ? <Table columns={columns} data={comercial?.data} /> : ''}
          {!comercial.isLoading && comercial.data[0] === null && (
            <Text textAlign='center' color='gray.500' px='5' py='10' bg='white' borderRadius='5px'>
              {t('common:noExecutiveAttached')}
            </Text>
          )}
        </Box>
      </>
    </Box>
  )
}
