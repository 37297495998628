import React from 'react'
import { PageContainer } from "../../common/helpers";
import { Button, Box, SimpleGrid,Heading, Spinner } from "@chakra-ui/core";
import { useTranslation } from "react-i18next";

function Marketing() {

    const { t } = useTranslation(["comunications"]);

    return (
        <>
        <Box backgroundColor='' py="10px" pb="0.5%" mt="" color="#103059">
        <PageContainer data-aos={"fade-right"}>
            <Heading style={{fontSize:"1.8rem"}}>{t("comunications:marketing")}</Heading>
        </PageContainer>
        </Box>
        </>
    )
}

export default Marketing
