import { Box, Button, Flex, Grid, List, Text, Heading } from "@chakra-ui/core";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from "react-redux";
import { DEFAULT_SECTIONS_CREATE_COUTRY, MARCA_BLANCA_CRUD_ADMIN } from "../../../helpers/permissions";
import { useParams, useRouteMatch, useLocation } from "react-router-dom";
import { resetSupplierData } from "../../../redux/actions/suppliers-actions";
import NavigatorDrawer from "../../../components/common/drawer/navigator-drawer";
import { PageContainer } from "../../../components/common/helpers";
import { ListItemRoute } from "../../../components/common/router-components";
import DefaultSection from "./default-sections";
import CustomSection from "./own-sections";
import { useCheckSession, usePermissionChecker } from "../../hooks";
import AOS from "aos";
import "../../styles/sections/sections.css";
export default function SuppliersEdit() {

	const { t } = useTranslation(["sections", "common"]);
	const { path, url } = useRouteMatch();
	const dispatch = useDispatch();
	const location = useLocation();
	const { canDoThis } = usePermissionChecker();
	const canDefault = useMemo(() =>
		canDoThis(
			MARCA_BLANCA_CRUD_ADMIN,
			() => ({
				ishidden: true,
			}),
			() => ({
				ishidden: false,
			})
		), [canDoThis]);

	const childrenRoutes = [
		{
			exact: true,
			path: [`${path}/`, `${path}/customSections`],
			component: CustomSection
		},
		canDefault.ishidden && {
			path: `${path}/defaultSections`,
			component: DefaultSection
		},
	];

	const header = (onOpen, btnRef) => (
		<Grid templateColumns={{ base: "6fr 1fr", md: "7fr 0fr" }} gap={[3, 3, 0]}>
			<Box backgroundColor='' py="16px" color="#103059">
				{ (location.pathname === "/sections/defaultSections") ?
					<Heading style={{fontSize: "1.8rem"}} mb={0}>{t("sections:defaultSections")}</Heading> :
					<Heading style={{fontSize: "1.8rem"}} mb={0}>{t("sections:customSections")}</Heading>
				}
			</Box>
			<Flex justify='flex-end'>
				<Button
					display={{ base: "inherit", md: "none" }}
					mb="10px"
					ref={btnRef}
					variant='outline'
					variantColor="blue"
					onClick={onOpen}
				>
					<Box as={GiHamburgerMenu} display="inline" />
				</Button>
			</Flex>
		</Grid>
	);

	useEffect(() => {
		dispatch(resetSupplierData());

	}, [dispatch]);


	return (
		<PageContainer>
			<NavigatorDrawer
				header={header}
				routes={childrenRoutes}
				spacing="16px"
			>
				<List spacing={3}>
					<ListItemRoute to={`${url}/customSections`}>
						{t("sections:customSections")}
					</ListItemRoute>
					{canDefault.ishidden && (
						<ListItemRoute to={`${url}/defaultSections`}>
							{t("sections:defaultSections")}
						</ListItemRoute>
					)}
				</List>
			</NavigatorDrawer>
		</PageContainer>
	);
}

