import React from "react";
import { PageContainer } from "./common/helpers";
import { Heading } from "@chakra-ui/core";

export default function NoMatch ({ location }) { 
	return (
		<PageContainer py="30px">
			<Heading color="gray.400" textAlign="center"> No se encontro la ruta <code>{location.pathname}</code> </Heading>
		</PageContainer>
	);
}
