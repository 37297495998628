import React from 'react'
import { Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/core'

export default function ModalSimple({ title = false, isOpen, onClose, children, style, ...rest }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent style={style ?? {}}>
        {title && <ModalHeader textTransform='capitalize'>{title}</ModalHeader>}
        <ModalCloseButton />
        <ModalBody pb={6}>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
