import React, { useState, useEffect } from 'react'
import { PageContainer } from '../../common/helpers'
import { Button, Box, SimpleGrid, CloseButton, Text, Grid, Divider, Link } from '@chakra-ui/core'
import { ListItem, UnorderedList } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Panel } from '../../common/helpers'
import ModalSimple from '../../common/modal-simple'
import CreateCategories from '../communications/createCategories'
import { getAuthToken, getToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import { useToast, useDisclosure } from '@chakra-ui/core'
import { GridItem } from '@chakra-ui/react'
import {
  obtenerComunicados,
  obtenerCategorias,
  actualizarComunicados,
  eliminarPermisosPorRolId,
  obtenerPermisosPorRolId,
  obtenerPermisosPorRol,
  deleteComunicado,
  crearPermisosPorRol,
} from '../../../api/communications'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { MdBuild } from 'react-icons/md'
import AccessEdition from '../../forms/comunications/accessEdition'
import EventsForm from '../../forms/comunications/eventsForm'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import '../../styles/comunications/comunications.css'
import '../../styles/comunications/events.css'
import { isSuccess } from '../../../helpers/utils'

function Events() {
  // - - General - -

  const token = useSelector(getToken)
  const toast = useToast()
  const userCountryId = useSelector(getUserCountryId)
  const userRolId = useSelector(getUserRolId)

  let permisosUser = userRolId === 1 || userRolId === 2 ? true : false
  const authToken = useSelector(getAuthToken)
  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()
  const modalDisclosure3 = useDisclosure()
  const modalDisclosure4 = useDisclosure()
  const { t } = useTranslation(['comunications'])

  // - - 🖥️ URL-Params 🖥️ - -

  let history = useHistory()

  // - - ❗ States ❗ - -

  const [formData, setFormData] = useState(false)
  const [eventsAll, setEventsAll] = useState(false)
  const [categoriesEvents, setCategoriesEvents] = useState(false)
  const [heading, setHeading] = useState(' ')
  const [subHeading, setSubHeading] = useState(' ')
  const [permisos, setPermisos] = useState([])
  const [events, setEvents] = useState([])
  const [allCategories, setAllCategories] = useState(false)
  const [ids, setIds] = useState(false)
  const [rolls, setRollds] = useState(false)
  const [styleTitle, setStyleTitle] = useState({
    color: '#103059',
    fontSize: '1.2rem',
    margin: '2% 2% ',
  })
  const [styleTitle2, setStyleTitle2] = useState({
    color: '#103059',
    fontSize: '1.2rem',
    margin: '2% 2% ',
  })

  // - - ❗ Events ❗ - -

  function instrucciones() {
    modalDisclosure.onOpen()
  }

  function goToCreate() {
    history.push('/communications?events')
  }

  function onChangeSelect(data) {
    if (data === 'seeAlls') {
      setEvents(eventsAll)
    } else {
      let cat = allCategories.filter((x) => x.descripcion === data)
      let cat2 = eventsAll.filter((x) => x.categoriasComunicados.id === cat[0].id)
      setEvents(cat2)
    }
  }

  function seeOption(b) {
    function separar(i) {
      let o = i.split('/')
      return o[0]
    }
    let cat = allCategories.filter((x) => separar(x.descripcion) === b[0])
    let u = eventsAll.filter((x) => x.categoriasComunicados.id === cat[0].id)
    if (heading === ' | Events') {
      let w = u.filter((x) => x.tipoComunicado.id === 2)
      setEvents(w)
      let cat2 = cat[0].descripcion.split('/')
      setSubHeading(` | ${cat2[0]}`)
    } else if (heading === ' | Capacitations') {
      let w = u.filter((x) => x.tipoComunicado.id === 4)
      setEvents(w)
      let cat2 = cat[0].descripcion.split('/')
      setSubHeading(` | ${cat2[0]}`)
    } else {
      setEvents(u)
      let cat2 = cat[0].descripcion.split('/')
      setSubHeading(` | ${cat2[0]}`)
    }
  }

  function seeEvents() {
    setStyleTitle({
      color: '#c4c4c4',
      fontSize: '1.2rem',
    })
    setStyleTitle2({
      color: '#103059',
      fontSize: '1.2rem',
    })
    let cat = eventsAll.filter((x) => x.tipoComunicado.id === 2)
    setHeading(' | Events')

    setEvents(cat)
  }

  function seeComunications() {
    setStyleTitle({
      color: '#103059',
      fontSize: '1.2rem',
    })
    setStyleTitle2({
      color: '#c4c4c4',
      fontSize: '1.2rem',
    })
    let cat = eventsAll.filter((x) => x.tipoComunicado.id === 4)
    setHeading(' | Capacitations')
    setEvents(cat)
  }

  // Eliminar Noticia
  function deleteComunitacion(id) {
    setIds(id)
    modalDisclosure4.onOpen()
  }

  // Acceder a los permisos
  function editAccess(id) {
    categoriasPorId(id.id)
    setIds(id)
    modalDisclosure3.onOpen()
  }

  //Ir a leer la noticias
  function openNewsWindow(x) {
    if (x.comunicadoHtml === null || x.comunicadoHtml === '') {
      return null
    } else {
      history.push(`/newsGEA?${x.id}?e`)
    }
  }
  // - - - -📞 API CALLS 📞- - - -

  function createCategorias(idCat) {
    if (allCategories === false) {
      return null
    } else {
      let cat = allCategories.filter((x) => x.id === idCat)
      function finalSplit(x) {
        let categorie = x.split('/')
        return categorie[0]
      }
      return finalSplit(cat[0].descripcion)
    }
  }

  function eventsOrComunication(x) {
    if (x.tipoComunicado.id === 4) {
      return 'capacitations'
    } else {
      return 'event'
    }
  }

  // Checks permisos
  function funcCheck(id) {
    if (rolls !== false) {
      return rolls.filter((x) => x.rolId === id)
    }
  }

  //Cerrar Modal Permisos
  function closeModalPermisos() {
    modalDisclosure3.onClose()
    setRollds(false)
  }

  // Editar Noticia
  function goToEdit(x) {
    setFormData(x)
    modalDisclosure2.onOpen()
  }

  function cleanAll() {
    // Fecha inicio: Ultimos 3 meses
    let fechaInicio = moment()
      .subtract(3, 'month')
      .format('YYYY-MM-DD')
    // Fecha fin: Proximos 3 meses
    let fechaFin = moment()
      .add(3, 'month')
      .format('YYYY-MM-DD')

    obtenerPermisos(fechaInicio, fechaFin)
    setHeading(' ')
    setSubHeading(' ')

    setStyleTitle({
      color: '#103059',
      fontSize: '1.2rem',
    })
    setStyleTitle2({
      color: '#103059',
      fontSize: '1.2rem',
    })
  }

  // - - - -📞❗ API CALLS ❗📞- - - -

  async function editFunc(x) {
    let id = formData.id
    let data = {
      asunto: x.asunto,
      titulo: x.titulo,
      subtitulo: x.subtitulo,
      imagenUrl: x.imagenUrl,
      descripcion: x.descripcion,
      comunicadoHtml: x.comunicadoHtml,
      categoriasComunicados: { id: x.categoriasComunicados.id },
      destacado: x.destacado,
      tipoComunicado: { id: x.tipoComunicado.id },
      url: x.url,
      fecha: x.fecha,
      sesion: {
        token: token,
        modulo: '',
        accion: '',
        nombre: '',
      },
    }

    try {
      const resp = await actualizarComunicados(id, data, authToken)

      if (isSuccess(resp.status)) {
        toast(toastCustomizedSuccess(`${t('comunications:editSucess')}`))
        modalDisclosure2.onClose()
        obtenerPermisos()
      } else {
        toast(toastCustomizedError(`${t('comunications:inputError')}`))
      }
    } catch (err) {
      console.log(err)
    }
  }

  // Obtener Permisos de Usuario
  async function obtenerPermisos() {
    try {
      const resp = await obtenerPermisosPorRolId(userRolId, authToken)
      setPermisos(resp.data)
      getEvents(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  // Obtener Eventos
  async function getEvents(permisos) {
    // Fecha inicio: Hoy
    let fechaInicio = moment()
      .subtract(3, 'month')
      .format('YYYY-MM-DD')
    // Fecha fin: Proximos 3 meses
    let fechaFin = moment()
      .add(3, 'month')
      .format('YYYY-MM-DD')
    try {
      const respCalendar = await obtenerComunicados(authToken)
      const filterCategory = respCalendar.data.data.filter((x) => x.tipoComunicado.id === 4)
      let filterCalendar = filterCategory.filter((x) => x.fechaBaja === null)
      const respEvents = await obtenerComunicados(authToken)
      const filterCategorieEvents = respEvents.data.data.filter((x) => x.tipoComunicado.id === 2)
      let filterEvents = filterCategorieEvents.filter((x) => x.fechaBaja === null)

      const concatEventsCalendar = filterCalendar.concat(filterEvents)

      const uniqueEventsCalendar = []

      concatEventsCalendar.forEach((item) => {
        const i = uniqueEventsCalendar.findIndex((x) => x.id === item.id)
        if (i <= -1) {
          return uniqueEventsCalendar.push(item)
        }
      })

      let perm = permisos
      perm.filter((x) => x.rolId === userRolId)

      function filters(id) {
        let i = perm.map((x) => x.comunicadosId === id)
        let k = i.filter((x) => x === true)
        if (k === '') {
          return false
        } else {
          return true
        }
      }

      let filterPermisos = uniqueEventsCalendar.map((x) => (filters(x.id) === true ? x : null))
      let filterPermisos2 = filterPermisos.filter((x) => x !== null)

      let filterLastDat = filterPermisos2.sort((datoPrev, datoNext) => {
        return (
          new Date(moment(datoPrev.fecha).format('YYYY-MM-DD')).getTime() <
          new Date(moment(datoNext.fecha).format('YYYY-MM-DD')).getTime()
        )
      })
      setEvents(filterLastDat)
      setEventsAll(filterLastDat)
    } catch (err) {
      console.log(err)
    }
  }

  async function obtenerCategoriasFunc() {
    try {
      const resp = await obtenerCategorias(userCountryId, authToken)
      let filterDate = resp.data.filter((x) => x.fechaBaja === null)
      setAllCategories(filterDate)
      let filterSplit = filterDate.map((x) => x.descripcion.split('/'))
      let filterCategorieEvents = filterSplit.filter((x) => x[1] === 'events')

      setCategoriesEvents(filterCategorieEvents)
    } catch (error) {
      console.log(error)
    }
  }

  // Crear / Eliminar Permiso
  async function newPermission(a, b) {
    if (b === false) {
      let data = {
        rolId: a,
        comunicados: { id: ids.id },
        sesion: {
          token: token,
          modulo: '',
          accion: '',
          nombre: '',
        },
      }
      try {
        const resp = await crearPermisosPorRol(data, authToken)
        if (resp.status === 200 || resp.status === 201 || resp.status === 204) {
        } else {
          console.log('error')
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      let permisoId = rolls.filter((x) => x.rolId === a)

      let datax = {
        token: token,
        modulo: '',
        accion: '',
        nombre: '',
      }
      let permisoDelete = permisoId[0].id

      try {
        const resp = await eliminarPermisosPorRolId(permisoDelete, datax, authToken)

        if (resp.status === 200) {
        } else {
          console.log('error')
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  // Eliminar Noticia
  async function deleteNew() {
    try {
      await deleteComunicado(ids, authToken)

      getEvents(permisos)
      modalDisclosure4.onClose()
    } catch (err) {
      console.log(err)
    }
  }

  // Editar Noticia
  async function editFunc(x) {
    let id = formData.id
    let data = {
      asunto: x.asunto,
      titulo: x.titulo,
      subtitulo: x.subtitulo,
      imagenUrl: x.imagenUrl,
      descripcion: x.descripcion,
      comunicadoHtml: x.comunicadoHtml,
      categoriasComunicados: { id: x.categoriasComunicados.id },
      destacado: x.destacado,
      tipoComunicado: { id: x.tipoComunicado.id },
      url: x.url,
      fecha: x.fecha,
      sesion: {
        token: token,
        modulo: '',
        accion: '',
        nombre: '',
      },
    }

    try {
      await actualizarComunicados(id, data, authToken)
      toast(toastCustomizedSuccess(`${t('comunications:editSucess')}`))
      modalDisclosure2.onClose()
      obtenerPermisos()
    } catch (err) {
      toast(toastCustomizedError(`${t('comunications:editError')}`))

      console.log(err)
    }
  }

  // Obtener Categorias por Id
  async function categoriasPorId(id) {
    try {
      const resp = await obtenerPermisosPorRol(authToken)
      setRollds(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    obtenerPermisos()
    obtenerCategoriasFunc()
  }, [])

  return (
    <>
      {/* Heading */}
      <PageContainer>
        <Grid templateColumns="repeat(2, 1fr)" gap={2} style={{ margin: '0rem', padding: '0' }}>
          <Box color="#103059">
            <h1 style={{ fontSize: '2.5rem', padding: '0', marginTop: '0.5rem' }}>
              {t('comunications:calendar')}
              {t(`comunications:${heading}`)}
              {t(`comunications:${subHeading}`)}
            </h1>
          </Box>
          {permisosUser === true ? (
            <SimpleGrid
              className="dNone"
              columns={{ base: 1, md: 1 }}
              style={{ fontSize: '2.5rem', padding: '0', marginTop: '0.8rem' }}
              spacing={8}
            >
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Box>
                  <Button
                    style={{ width: '12rem' }}
                    onClick={() => {
                      goToCreate()
                    }}
                    variantColor="blue"
                    variant="outline"
                  >
                    {t('comunications:createNews')}
                  </Button>
                </Box>
                <Box>
                  <Button
                    style={{ width: '12rem' }}
                    variant="outline"
                    onClick={() => {
                      instrucciones()
                    }}
                    variantColor="blue"
                  >
                    {t('comunications:createCategories')}
                  </Button>
                </Box>
              </div>
            </SimpleGrid>
          ) : null}
        </Grid>
      </PageContainer>

      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('comunications:createCategories')}`}
        size="lg"
      >
        <CreateCategories onClose={modalDisclosure.onClose}></CreateCategories>
      </ModalSimple>

      {/* Section Page Events */}
      <PageContainer>
        <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={5} style={{ margin: '2rem 1rem 2rem', backgroundColor: 'transparent' }}>
          <GridItem className="dNone" colSpan={1} rowSpan={10} style={{ width: '90%', margin: '0% 5%' }}>
            <Panel style={{ width: '100%' }}>
              <p style={{ textAlign: '', fontSize: '1.7rem', color: '#103059', padding: '0%', margin: '1% 0% 5%' }}>
                <b>{t('comunications:filters')}</b>
              </p>
              <p style={{ fontSize: '1.2rem', color: '#103059' }}>{t('comunications:type')}:</p>
              {/* <label style={{padding:"1rem"}}>  {t("comunications:Filter")}</label> */}
              {/* <Box  style={{display:"flex", justifyContent:"space-around",padding:"1rem 0rem "}}> */}
              <UnorderedList style={{ padding: '1rem 0rem ' }}>
                {/* <Button
                    style={{width:"auto", marginLeft:"0", marginRight:"10%"}}
                        onClick={()=>{seeEvents()}}
                        variantColor="blue"
                        variant="outline"
                        >{t("comunications:events")}
                    </Button>  */}
                <ListItem
                  style={{ margin: `${styleTitle2.margin}`, fontSize: `${styleTitle2.fontSize}`, color: `${styleTitle2.color}` }}
                  // variant="outline"
                  onClick={() => {
                    seeEvents()
                  }}
                  // variantColor="blue"
                >
                  <Link _hover={{ fontWeight: 'bold', color: '#414141' }}>{t('comunications:events')}</Link>
                </ListItem>
                <p style={{ margin: `${styleTitle2.margin}`, fontSize: `${styleTitle2.fontSize}`, color: '#c4c4c4' }}>{'  '}</p>
                <ListItem
                  style={{ margin: `${styleTitle.margin}`, fontSize: `${styleTitle.fontSize}`, color: `${styleTitle.color}` }}
                  // variant="outline"
                  onClick={() => {
                    seeComunications()
                  }}
                  // variantColor="blue"
                >
                  <Link _hover={{ fontWeight: 'bold', color: '#414141' }}>{t('comunications:training')}</Link>
                </ListItem>
              </UnorderedList>

              <Box>
                <p style={{ fontSize: '1.2rem', color: '#103059' }}>{t('comunications:categories')}:</p>
                <UnorderedList style={{ margin: '5% 5% ', paddingBottom: '10%' }}>
                  {categoriesEvents === false
                    ? null
                    : categoriesEvents.map((x) => (
                        <ListItem key={x[1]} style={{ margin: '2% 1%' }}>
                          <Link
                            _hover={{ fontWeight: 'bold', color: '#414141' }}
                            style={{ fontSize: '1rem', textTransform: 'uppercase', color: '#103059' }}
                            onClick={() => {
                              seeOption(x)
                            }}
                          >
                            {x[0]}
                          </Link>
                        </ListItem>
                      ))}
                </UnorderedList>
              </Box>
              <Box>
                <Button
                  style={{ width: '100%', height: '2.2rem', margin: '-8% 0% 3%', backgroundColor: '#24428e', color: 'white' }}
                  onClick={() => {
                    cleanAll()
                  }}
                >
                  {t('comunications:cleanFilter')}
                </Button>
              </Box>
            </Panel>
          </GridItem>
          <GridItem colSpan={2} className="cardContainerEvent">
            {events.map((x) => (
              <Box key={x.id}>
                <div className="cardEvent">
                  <div
                    style={{
                      marginTop: '',
                      border: '2.5px solid #585859',
                      width: '7rem',
                      height: '7rem',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Box>
                      <Box style={{ display: 'grid', placeContent: 'center' }}>
                        <Text style={{ fontSize: '1.2rem', marginTop: '0.8rem', color: '#585859', textTransform: 'uppercase' }}>
                          {moment(x.fecha).format('MMM')}
                        </Text>
                      </Box>
                      <Box style={{ display: 'grid', placeContent: 'center' }}>
                        <p className="dateNumberCardEvent">
                          <b>{moment(x.fecha).format('DD')}</b>
                        </p>
                      </Box>
                    </Box>
                  </div>
                  <div style={{ position: 'relative', width: '90%' }}>
                    <Box style={{ marginLeft: '1rem' }}>
                      {/* <Badge  style={{fontSize:"0.9rem", marginTop:"0.1rem", color:"white"}}  > */}
                      <p className="categoryCardEvent">
                        <b>{t(`comunications:${eventsOrComunication(x)}`)}</b> | <b>{createCategorias(x.categoriasComunicados.id)}</b>
                      </p>
                      {/* </Badge> */}
                      <Box>
                        <p className="titleCardEvent">
                          <b>{x.titulo}</b>
                        </p>
                      </Box>

                      <Box>
                        {permisosUser === true ? (
                          <div style={{ position: 'absolute', top: '2%', right: '7%' }}>
                            <div className="dNone">
                              <Button
                                onClick={() => {
                                  goToEdit(x)
                                }}
                                rightIcon={MdBuild}
                                style={{ backgroundColor: 'transparent', color: 'gray', marginRight: '-1rem', paddingRight: 'px' }}
                              ></Button>
                            </div>
                          </div>
                        ) : null}

                        <div style={{ position: 'absolute', top: '5%', right: '6rem' }}>
                          {permisosUser === true ? (
                            <div className="dNone">
                              <div>
                                <Button
                                  style={{ background: 'rgba(0,0,0,0.2)', height: '2.1rem', padding: '-2' }}
                                  onClick={() => {
                                    editAccess(x)
                                  }}
                                  variantColor="blue"
                                >
                                  {t('comunications:permits')}
                                </Button>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        {permisosUser === true ? (
                          <div className="dNone" style={{ position: 'absolute', top: '5%', right: '1%' }}>
                            <CloseButton
                              size="md"
                              style={{ color: '#A52422', backgroundColor: 'rgba(0,0,0,0.1)' }}
                              onClick={() => {
                                deleteComunitacion(x.id)
                              }}
                              variantColor="blue"
                            >
                              {t('comunications:delete')}
                            </CloseButton>
                          </div>
                        ) : null}

                        <Box>
                          <p style={{ fontSize: '1.1rem', color: 'gray', marginTop: '-0.5rem', width: '100%' }}>{x.descripcion}</p>
                        </Box>

                        {x.url !== '' ? (
                          <div className="btnLinkEvent">
                            <Button style={{ background: '', height: '2.1rem', padding: '-2' }} variant="outline" variantColor="blue">
                              <Link href={x.url} isExternal>
                                {x.subtitulo}
                              </Link>
                            </Button>
                          </div>
                        ) : null}
                      </Box>
                    </Box>
                  </div>
                </div>
                {x.comunicadoHtml === null ? null : (
                  <div style={{ position: 'relative' }}>
                    <div className="btnSeeMore">
                      <Button
                        style={{ background: '#24428e', height: '2.1rem', padding: '-2' }}
                        onClick={() => openNewsWindow(x)}
                        variantColor="blue"
                      >
                        <p className="btnSeeMoreDetails">{t('comunications:seeMore')}</p>
                      </Button>
                    </div>
                  </div>
                )}
                <GridItem colSpan={2} rowSpan={1}>
                  <Divider style={{ borderColor: '#585859', width: '100%', margin: '2% 0% 2%' }} />
                </GridItem>
              </Box>
            ))}
            {eventsAll === false ? null : typeof events[0] !== 'undefined' ? null : (
              <div style={{ fontSize: '2.5rem', paddingTop: '2rem', margin: '0% 5%', color: '#c4c4c4' }}>
                <b>{t('comunications:dontFoundElement')}</b>
              </div>
            )}
          </GridItem>
        </SimpleGrid>
      </PageContainer>

      {/* Modal Edicion */}
      {formData === false ? null : (
        <ModalSimple
          isOpen={modalDisclosure2.isOpen}
          onClose={modalDisclosure2.onClose}
          title={`${t('comunications:EditNew')}`}
          size="6xl"
        >
          <EventsForm padre={editFunc} formData={formData} section={'news'}></EventsForm>
        </ModalSimple>
      )}

      {/* Modal Delete */}
      <ModalSimple
        isOpen={modalDisclosure4.isOpen}
        onClose={modalDisclosure4.onClose}
        title={`${t('comunications:deleteItem')}`}
        size="xl"
      >
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            style={{ width: '12rem' }}
            onClick={() => {
              deleteNew()
            }}
            variantColor="red"
          >
            {t('comunications:Eliminar')}
          </Button>
          <Button style={{ width: '12rem' }} onClick={() => modalDisclosure4.onClose()} variant="outline" variantColor="blue">
            {t('comunications:Cancelar')}
          </Button>
        </div>
      </ModalSimple>

      {/* Modal Permisos */}
      {rolls !== false ? (
        <ModalSimple isOpen={modalDisclosure3.isOpen} title={`${t('comunications:permits')}`} size="xl">
          <AccessEdition funcCheck={funcCheck} newPermission={newPermission}></AccessEdition>
          <div style={{ float: 'right', margin: '4% 0% 0%' }}>
            <Button
              style={{ height: '2.1rem', padding: '-2' }}
              onClick={() => {
                closeModalPermisos()
              }}
              variantColor="blue"
            >
              {t('comunications:close')}
            </Button>
          </div>
          <div style={{ position: 'absolute', top: '0rem', right: '0.1rem' }}>
            <Box style={{ backgroundColor: 'white', width: '3rem', height: '3rem', zIndex: '22' }}></Box>
          </div>
        </ModalSimple>
      ) : null}
    </>
  )
}

export default Events
