import React from 'react'
import { useToast } from '@chakra-ui/core'
import ApplicationForm from '../../../forms/application/application'
import { createAplicacionDetalle, createApp } from '../../../../api/application'
import { useSelector } from 'react-redux'
import { toastCustomizedSuccess } from '../../../common/toast-default-config'
import { getToken } from '../../../../redux/selectors'
import { isSuccess } from '../../../../helpers/utils'
import { toastCustomizedError } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'

export default function CreateApplicationHandle({ onFinish, updateList, ...rest }) {
  const toast = useToast()
  const { t } = useTranslation(['forms'])
  const token = useSelector(getToken)

  const handleSubmit = async data => {
    if (data.comercial.id === 0 || !data.comercial.id) {
      data.comercial = undefined
    } else {
      data.comercial = {
        id: parseInt(data.comercial.id),
      }
    }
    data.paisId = parseInt(data.paisId)

    try {
      const response = await createApp(
        {
          ...data,
        },
        token,
      )
      const dataDetalle = {
        ...data.detalle,
        aplicacion: {
          id: response.data.id,
        },
      }

      const responseDetalle = await createAplicacionDetalle(
        {
          ...dataDetalle,
        },
        token,
      )

      const status = isSuccess(responseDetalle.status)

      if (status) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        updateList.setAddRecordSuccess(!updateList.addRecordSuccess)
      }

      onFinish()
    } catch (error) {
      if (error.response) {
        toast(toastCustomizedError(t('forms:actionError')))
      }
    }
  }

  return <ApplicationForm onSubmit={handleSubmit} {...rest} />
}
