import { Box, Link, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/core'
import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getContactsBySupplierId, getOfficesBySupplierIdPaginated } from '../../../../api/intranet'
import { getSupplierById } from '../../../../api/intranet/requests'
import TableAsync from '../../../common/table-async'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../../redux/selectors'
import ReactHtmlParser from 'react-html-parser'
import { Panel } from '../../../common/helpers'
import Pagination from '../../../common/pagination'

export default function Information({ id }) {
  const { t } = useTranslation(['forms', 'suppliers', 'applications'])
  const [page, setPage] = useState({
    current: 1,
    tab: 'contacts'
  })
  const [totalPages, setTotalPages] = useState(0)
  const maxPerPage = 10

  const authToken = useSelector(getAuthToken)

  const [supplierContacts, setSupplierContacts] = useState({
    data: [],
    isLoading: true,
    error: null,
  })

  const [supplierOffices, setSupplierOffices] = useState({
    data: [],
    isLoading: true,
    error: null,
  })

  const [supplierAditionalInfo, setSupplierAditionalInfo] = useState({
    data: [],
    isLoading: true,
    error: null,
  })

  const handleSetPage = (page, tab) => {
    setPage({
      current: page,
      tab
    })
  }

  const handleChangeTab = (tabName, getterFn) => {
    handleSetPage(1, tabName)
    getterFn()
  }

  const getSupplier = async () => {
    setSupplierAditionalInfo(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getSupplierById(id, authToken)
      setSupplierAditionalInfo({
        data: response.data.infoAdicional || '',
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setSupplierAditionalInfo(() => ({
        data: [],
        isLoading: false,
        error: error,
      }))
      console.log(error)
    }
  }

  const getSupplierContacts = async () => {
    setSupplierContacts(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const data = {
        proveedorId: id,
        maxPerPage,
        page: page.current
      }
      const response = await getContactsBySupplierId(data)
      setTotalPages(response.data.totalPages)
      setSupplierContacts({
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setSupplierContacts(() => ({
        data: [],
        isLoading: false,
        error: error,
      }))
      console.log(error)
    }
  }

  const getSupplierOffices = async () => {
    setSupplierOffices(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const data = {
        proveedorId: id,
        maxPerPage,
        page: page.current
      }
      const response = await getOfficesBySupplierIdPaginated(data)
      setTotalPages(response.data.totalPages)
      setSupplierOffices({
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setSupplierOffices(() => ({
        data: [],
        isLoading: false,
        error: error,
      }))
      console.log(error)
    }
  }

  useEffect(() => {
    switch(page.tab) {
      case 'contacts':
        getSupplierContacts()
        break
      case 'offices':
        getSupplierOffices()
        break
      
      default:
        return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const columnsContacts = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: 'nombre',
      },
      {
        Header: t('suppliers:company_section'),
        accessor: 'area',
      },
      {
        Header: t('suppliers:position'),
        accessor: 'cargo',
      },
      {
        Header: t('forms:phone'),
        accessor: 'telefono',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
    ]
  }, [t])

  const columnsOffices = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:address'),
        accessor: 'direccion',
      },
      {
        Header: t('suppliers:attention_time'),
        accessor: 'horarioAtencion',
      },
      {
        Header: 'Web',
        accessor: 'web',
        Cell: e => (
          <Link href={e.value} color='blue.500' target='_blank'>
            {e.value}
          </Link>
        ),
      },
    ]
  }, [t])

  return (
    <Tabs mt='5' mb='2' variant='enclosed'>
      <TabList>
        <Tab
          onClick={() => handleChangeTab('contacts', getSupplierContacts)}
          _selected={{ color: 'white', bg: 'blue.500' }}
          >
            {t('suppliers:contacts')}
        </Tab>
        <Tab
          onClick={() => handleChangeTab('offices', getSupplierOffices)}
          _selected={{ color: 'white', bg: 'blue.500' }}
          >
            {t('suppliers:offices')}
        </Tab>
        <Tab
          onClick={() => handleChangeTab('supplier', getSupplier)}
          _selected={{ color: 'white', bg: 'blue.500' }}
          >
            {t('applications:additionalInformation')}
        </Tab>
      </TabList>
      <TabPanels mt='2'>
        <TabPanel>
          <TableAsync
            isLoading={supplierContacts.isLoading}
            columns={columnsContacts}
            data={!supplierContacts.isLoading && !supplierContacts.error && supplierContacts.data}
          />
          {!supplierContacts.isLoading && totalPages > 0 && (
            <Pagination currentPage={page.current} setPage={(page) => handleSetPage(page, 'contacts')} totalPages={totalPages} />
          )}
        </TabPanel>
        <TabPanel>
          <TableAsync
            isLoading={supplierOffices.isLoading}
            columns={columnsOffices}
            data={!supplierOffices.isLoading && !supplierOffices.error && supplierOffices.data}
          />
          {!supplierOffices.isLoading && totalPages > 0 && (
            <Pagination currentPage={page.current} setPage={(page) => handleSetPage(page, 'offices')} totalPages={totalPages} />
          )}
        </TabPanel>
        <TabPanel>
          {supplierAditionalInfo.isLoading ? (
            <Panel textAlign='center' color='gray.500'>
              <Spinner />
            </Panel>
          ) : (
            <>
              {supplierAditionalInfo.data.length !== 0 ? (
                <Box
                  style={{
                    border: '1px solid #E2E8F0',
                    borderRadius: '0.35rem',
                    padding: '2rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box>{ReactHtmlParser(supplierAditionalInfo.data)}</Box>
                </Box>
              ) : (
                <Box
                  style={{
                    border: '1px solid #E2E8F0',
                    borderRadius: '0.35rem',
                    padding: '3rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {t('suppliers:noInfo')}
                </Box>
              )}
            </>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
