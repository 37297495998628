import axios from 'axios'
import { getStorageToken } from '../helpers/utils'
import { configWithParams } from './application'

// Headers

export const configWithToken = () => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
  }
}

const { REACT_APP_INTRANET_API_V2 } = process.env

export const intranetInstance = axios.create({
  baseURL: REACT_APP_INTRANET_API_V2,
})

// - - - - - Endpoints - - - - -

export const getIncentivesEndpoint = '/api/incentivos'

export const getIncentivesProviderEndpoint = '/api/proveedores/incentivo'

export const getIncentivesCategoryEndpoint = '/api/proveedores/categoria'

export const getIncentivesEndpointTest =
  '/api/Incentivos/?paisId=1&proveedorId=0&fechaInicio=2000/01/01&fechaFin=2023/01/01'

export const getIncentivesIdEndpoint = Id => `/api/incentivos/${Id}`

export const getIncentivesProviderIdEndpoint = `/api/incentivos`

export const deleteIncentiveEnpoint = Id => `/api/incentivos/${Id}`

// Unidades de Medicion

export const getUnitsMeasurementEndpoint = '/api/unidad-medicion/'

export const getUnitsMeasurementIdEndpoint = Id => `/api/unidad-medicion/${Id}`

// Escalas

export const getScalesEndpoint = '/api/incentivos-escalas/'

export const getScalesIdEndpoint = Id => `/api/incentivos-escalas/${Id}`

export const getScalesProviderIdEndpoint = providerId => `/api/Incentivos/escalas/incentivo/${providerId}`

export const deleteScalesEnpoint = Id => `/api/incentivos-escalas/${Id}`

// Status

export const getStatusEndpoint = '/api/incentivos-status'

export const getStatusIdEndpoint = Id => `/api/incentivos-status/${Id}`

export const getStatusIncentiveIdEndpoint = IncentiveId => `/api/incentivos-status/incentivo/${IncentiveId}`

export const deleteGetStatusIdEndpoint = Id => `/api/incentivos-status/${Id}`

export const getStatusEscalaEndpoint = incentiveId => `/api/incentivos-status-escalas/incentivo/${incentiveId}`

// - - - - - - Requests GET - - - - - - -

export const getIncentives = data =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getIncentivesEndpoint}`, configWithParams(data))

export const getIncentivesByProvider = data => {
  const { paisId, categoriaId } = data
  return axios.get(`${REACT_APP_INTRANET_API_V2}${getIncentivesProviderEndpoint}`, configWithParams({paisId, categoriaId}))
}

export const getIncentivesByCategory = data => {
  const { paisId, proveedorId } = data
  return axios.get(`${REACT_APP_INTRANET_API_V2}${getIncentivesCategoryEndpoint}`, configWithParams({paisId, proveedorId}))
}

export const getIncentivesId = Id =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getIncentivesIdEndpoint(Id)}`, configWithToken())

export const getIncentivesProviderId = (data) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getIncentivesProviderIdEndpoint}`, configWithParams(data))

// Unidades de Medicion

export const getUnitsMeasurement = authToken =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getUnitsMeasurementEndpoint}`, configWithToken(authToken))

export const getUnitsMeasurementId = (Id, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getUnitsMeasurementIdEndpoint(Id)}`, configWithToken(authToken))

// Unidades de Escalas

export const getScales = authToken =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getScalesEndpoint}`, configWithToken(authToken))

export const getScalesId = (Id, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getScalesIdEndpoint(Id)}`, configWithToken(authToken))

// Status

export const getStatus = authToken =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getStatusEndpoint}`, configWithToken(authToken))

export const getStatusId = (Id, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getStatusIdEndpoint(Id)}`, configWithToken(authToken))

export const getStatusIncentiveId = (IncentiveId, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getStatusIncentiveIdEndpoint(IncentiveId)}`, configWithToken(authToken))

// - - - - - -  Requests POST- - - - - -

export const postScales = (data, authToken) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${getScalesEndpoint}`, data, configWithToken(authToken))

export const postIncentivesId = (data, authToken) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${getIncentivesEndpoint}`, data, configWithToken(authToken))

export const postStatus = (data, authToken) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${getStatusEndpoint}`, data, configWithToken(authToken))

export const getStatusEscala = (authToken, incentiveId) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${getStatusEscalaEndpoint(incentiveId)}`, configWithToken(authToken))

// - - - - - -  Requests PUT - - - - - -

export const deleteIncentive = (Id, authToken) =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${deleteIncentiveEnpoint(Id)}`, configWithToken(authToken))

export const putUpdateScales = (Id, data, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${getIncentivesIdEndpoint(Id)}`, data, configWithToken(authToken))

export const putUpdateStatus = (Id, data, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${getStatusIdEndpoint(Id)}`, data, configWithToken(authToken))

export const deleteScales = (Id, authToken) =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${deleteScalesEnpoint(Id)}`, configWithToken(authToken))

export const putScalesIdEndpoint = (Id, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${getScalesIdEndpoint(Id)}`, configWithToken(authToken))

export const deleteGetStatusId = (Id, authToken) =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${deleteGetStatusIdEndpoint(Id)}`, configWithToken(authToken))
