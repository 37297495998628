import React from 'react'
import { Text, Box, FormLabel, SimpleGrid } from "@chakra-ui/core";
import { Panel } from "../../../common/helpers";
import { useTranslation } from "react-i18next";
import moment from "moment";

function DetailsCard(props) {

    const { t } = useTranslation(["bookings"]);
    // console.log("props2",props.data)
    // let Bookings = props.props.dataBookingsSelect.dataBookings
    // let BookingsDetails = props.props.dataBookingsSelect.dataBookingsDetails

return (
    <div>
         <SimpleGrid columns={{sm: 1, md: 3}} spacing="40px">   
            <Panel p={4} padding="-2px">
                
                 <Box textAlign="center" backgroundColor='blue.600' py="20px" mt="-20px" color="white">
                        <FormLabel marginLeft="0">
                        <Text fontSize="xl"  as="em"><b>{t("bookings:dataBookings")}  
                        {" "}</b></Text>
                        </FormLabel>
                </Box>
                        {props.data.id !== "" ? (
                        <Text fontSize="lg" padding="3">
                        <b>{t("bookings:ID")}</b>: {" "}
                            {props.data.id}
                        </Text>
                        ) : null}
                        {props.data.email !== "" ? (
                        <Text fontSize="lg" padding="3" >
                        <b>{t("bookings:email")}</b>: {" "}
                            {props.data.email}
                        </Text>
                        ) : null}
                        {props.data.fechaCarga !== "" ? (
                        <Text fontSize="lg" padding="3" >
                            <b>{t("bookings:dateUpload")}</b>: {" "}
                            {moment(props.data.fechaCarga).format("YYYY-MM-DD")}
                        </Text>
                        ) : null}
                        {props.data.fechaSalida !== "" ? (
                        <Text fontSize="lg" padding="3" >
                        <b>{t("bookings:dateDeparture")}</b>:{" "}
                            {props.data.fechaSalida }
                        </Text>
                        ) : null}
                        {props.data.monto !== "" ? (
                        <Text fontSize="lg" padding="3" >
                        <b>{t("bookings:amount")}</b>: {" "}
                            {props.data.monto}
                        </Text>
                        ) : null}
                        
                        {props.data.estadoReserva !== "" ? (
                        <Text fontSize="lg" padding="3" >
                        <b>{t("bookings:reference")}</b>: {" "}
                            {props.data.referencia}
                        </Text>
                        ) : null}
                        {props.data.dominioOrigen !== "" ? (
                        <Text fontSize="lg" padding="3" >
                        <b>{t("bookings:sourceDomain")}</b>: {" "}
                            {props.data.dominioOrigen}
                        </Text>
                        ) : null}
            </Panel>
            
            <Panel p={4} padding="-2px">
                
                <Box textAlign="center" backgroundColor='blue.600' py="20px" mt="-20px" color="white">
                    <FormLabel marginLeft="0">
                    <Text fontSize="xl"  as="em"><b>{t("bookings:detailsBookints")} 
                    </b></Text>
                    </FormLabel>
                </Box>
                    {props.data.paqueteId !== "" ? (
                    <Text fontSize="lg" padding="3">
                        <b>{t("bookings:package")}{" "}ID</b>: {" "}
                        {props.data.paqueteId}
                    </Text>
                    ) : null}
                    {props.data.titulo !== "" ? (
                    <Text fontSize="lg" padding="3">
                        <b>{t("bookings:title")}</b>: {" "}
                        {props.data.titulo}
                    </Text>
                    ) : null}
                    {props.data.cantidadNoches !== "" ? (
                    <Text fontSize="lg" padding="3">
                        <b>{t("bookings:nights")}</b>: {" "}
                        {props.data.cantidadNoches}
                    </Text>
                    ) : null}
                    {props.data.disponibilidad !== "" ? (
                    <Text fontSize="lg" padding="3" >
                    <b>{t("bookings:availability")}</b>:{" "}
                    {props.data.disponibilidad !== true ? t("consultations:yes") : "No"}
                    </Text>
                    ) : null}
                    {props.data.edadMaximaMenores !== "" ? (
                    <Text fontSize="lg" padding="3" >
                    <b>{t("bookings:maximumAgeFfMinors")}</b>: {" "}
                        {props.data.edadMaximaMenores}
                    </Text>
                    ) : null}
                    {props.data.vigenciaDesde !== "" ? (
                    <Text fontSize="lg" padding="3" >
                    <b>{t("bookings:validity")}</b>: {" "}
                    </Text>
                    ) : null}
                    {props.data.vigenciaDesde !== "" ? (
                    <Text fontSize="lg" padding="3" marginLeft="6">
                    <b>{t("bookings:since")}</b>: {" "}
                        {props.data.vigenciaDesde}
                    </Text>
                    ) : null}
                    {props.data.vigenciaHasta !== "" ? (
                    <Text fontSize="lg" padding="3" marginLeft="6">
                    <b>{t("bookings:until")}</b>: {" "}
                        {props.data.vigenciaHasta}
                    </Text>
                    ) : null}
        </Panel>
        <Panel  padding="-2px">
                <Box textAlign="center" backgroundColor='blue.600' py="20px" mt="-20px" color="white">
                    <FormLabel marginLeft="0">
                    <Text fontSize="xl"  as="em"><b>{t("bookings:description")} 
                    {" "}</b></Text>
                    </FormLabel>
                </Box>
                    <Text fontSize="lg" padding="5" >
                        {props.data.descripcion}
                    </Text> 
        </Panel>
        </SimpleGrid>


        <SimpleGrid columns={{sm: 1, md: 2}} spacing="40px">
            
        <Panel  p={4} mt="22px" padding="-2px">
                <Box>
                <Box textAlign="center" backgroundColor='blue.400' py="5px"  color="white">
                         <FormLabel marginLeft="0">
                       <Text fontSize="xl"  as="em"><b>{t("bookings:price")} : 
                         </b></Text>
                         </FormLabel>
                </Box>
                         
   
                        {props.data.precioNetoDolares !== "" ? (
                        <div>
                    
                        <Text fontSize="lg" padding="3" >
                        <b>{t("bookings:netDolarPrice")}:  {" "}</b>
                            {props.data.precioNetoDolares}
                        </Text>
                        </div>
                        ) : null}
                        {props.data.impuestos !== "" ? (
                        <Text fontSize="lg" padding="3">
                            <b>{t("bookings:takes")}:  {" "}</b>
                            {props.data.impuestos}
                        </Text>
                        ) : null}
                        {props.data.impuestosDolares !== "" ? (
                        <Text fontSize="lg" padding="3" >
                            <b>{t("bookings:dolarTakes")}:  {" "}</b>
                            {props.data.impuestosDolares}
                        </Text>
                        ) : null}
                        {props.data.iva !== "" ? (
                        <Text fontSize="lg" padding="3" >
                             <b>{t("bookings:iva")}:  {" "}</b>
                            {props.data.iva}
                        </Text>
                        ) : null}
                            {props.data.ivaDolares !== "" ? (
                        <Text fontSize="lg" padding="3" >
                            <b>{t("bookings:ivaDolar")}:  {" "}</b>
                            {props.data.ivaDolares}
                        </Text>
                        ) : null}
                            {props.data.moneda !== "" ? (
                        <Text fontSize="lg" padding="3" >
                            <b> {t("bookings:currency")}:  {" "}</b>
                            {props.data.moneda}
                        </Text>
                        ) : null}
                </Box>   
                </Panel>    
                <Panel  p={4} mt="22px" padding="-2px">
                <Box>
                <Box textAlign="center" backgroundColor='blue.400' py="5px"  color="white">
                         <FormLabel marginLeft="0">
                             
                       <Text fontSize="xl"  as="em"><b>{t("bookings:departure")} : 
                         {" ID "}{props.data.idSalida}</b></Text>
                         </FormLabel>
                </Box>
                    
                        {props.data.fechaSalida !== "" ? (
                        <div>
                        <Text fontSize="lg" padding="3"><b>{t("bookings:date")}{" "}{t("bookings:departure")}</b>: </Text>
                        <Text fontSize="lg" padding="3" marginLeft="6">
                        <b> {t("bookings:since")}: {" "}</b> 
                            {moment(props.data.fechaSalida).format("YYYY-MM-DD")}
                        </Text>
                        </div>
                        ) : null}
                        {props.data.fechaLlegada !== "" ? (
                        <Text fontSize="lg" padding="3" marginLeft="6">
                           <b> {t("bookings:until")}: {" "}</b> 
                            {moment(props.data.fechaLlegada).format("YYYY-MM-DD")}
                        </Text>
                        ) : null}
                        {props.data.referenciaInterna !== "" ? (
                        <Text fontSize="lg" padding="3">
                        <b>{t("bookings:reference")}</b>: {" "}
                        </Text>
                        ) : null}
                        {props.data.referenciaInterna !== "" ? (
                        <Text fontSize="lg" padding="3" marginLeft="6">
                           <b> {t("bookings:internal")}: {" "}</b> 
                            {props.data.referenciaInterna}
                        </Text>
                        ) : null}
                </Box> 
                </Panel>
        </SimpleGrid>
    </div>
    )
}

export default DetailsCard
