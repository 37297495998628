import React, { useEffect, useState } from 'react'
import SupplierEditGeneralForm from '../../../../components/forms/suppliers/supplier-edit-general-form'
import { useParams } from 'react-router-dom'
import { getSupplierById } from '../../../../api/intranet/requests'
import { getAuthToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { Panel } from '../../../common/helpers'

const SupplierEditGeneral = () => {
  const { id: supplierId } = useParams()
  const authToken = useSelector(getAuthToken)
  const [formData, setFormData] = useState([])

  async function Updat() {
    try {
      const response = await getSupplierById(supplierId, authToken)
      let pass = response.data
      setFormData(pass)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('errorr')
    }
  }

  useEffect(() => {
    Updat()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Panel>{formData.length !== 0 ? <SupplierEditGeneralForm formData={formData} /> : null}</Panel>
}

export default SupplierEditGeneral
