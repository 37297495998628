import { getAllIconTypesEndpoint, iconInstance } from "../../../../../api/section";
import { useConfigWithAuth } from "../../../../hooks";


const fetchIcon = async (api, method, url, data, config) => {
	try {
		return  await api[method](url, data, config);
	} catch (err) {
		return err;
	}
};

export async function iconServices(configAuth) {
	//const { configAuth } = useConfigWithAuth();
	//const sesion = deconstructPermission(BANNERS_CREATE)
	const url = getAllIconTypesEndpoint();

	try{
		const res = await  fetchIcon(iconInstance, "get", url, /* sesion, */ configAuth);
		return res;
		//return await fetchIcon(iconInstance, "get", url, /* sesion, */ configAuth);
	}catch(error){
		return error;
	}
}