import { Box, Button, FormLabel, Input, InputGroup, InputRightElement } from '@chakra-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_PRESET, REACT_APP_CLOUDINARY_FOLDER } = process.env

export default function ImageInput({ name, id = null, inputProps, label, isRequired, setValue, setUrl = null, value, isDisabled, ...rest }) {
  const { t } = useTranslation('suppliers')

  const myCropWidget = useMemo(() => {
    return window.cloudinary.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: REACT_APP_CLOUDINARY_PRESET,
        folder: REACT_APP_CLOUDINARY_FOLDER,
        cropping: true,
      },
      (error, result) => {
        if (error) {
          alert('Cloudinary service unavailable')
        }
        checkUploadResult(result)
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function checkUploadResult(resultEvent) {
    if (resultEvent.event === 'success') {
      setValue(name, resultEvent.info.secure_url, { shouldValidate: true })
      if (setUrl) {
        setUrl(resultEvent.info.secure_url)
      }
    }
  }

  function showWidget() {
    myCropWidget.open()
  }

  return (
    <Box {...rest}>
      {label && (
        <FormLabel isRequired={isRequired} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <InputGroup size="md">
        <Input pr="8rem" id={id} name={name} value={value} isRequired={isRequired} isDisabled={isDisabled} {...inputProps} />
        <InputRightElement width="8rem">
          <div id="photo-form-container">
            <Button h="1.75rem" size="sm" onClick={showWidget} isDisabled={isDisabled}>
              {t('suppliers:uploadImage')}
            </Button>
          </div>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}
