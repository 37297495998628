import React, { useRef, useMemo } from 'react'
import JoditEditor from 'jodit-react'

export default function JEditor({ content, setContent }) {
  const editor = useRef(null)
  const config = {
    readonly: false,
    height: 400,
  }

  return useMemo(
    () => (
      <div>
        <JoditEditor ref={editor} value={content} config={config} onChange={content => setContent(content)} />
      </div>
    ),
    [],
  )
}
