import React from "react";
import { Image } from '@chakra-ui/core'
import "../styles/common/highlight-section-btn.css"

const HighlightSectionBtn = ({onClick, imgSrc, label}) => {
  return (
    <div className='highlight-section-btn' onClick={onClick}>
      <div className='highlight-section-btn__circle'>
        <Image className="button-image" src={imgSrc} />
      </div>
      <div className='highlight-section-btn__label'>
        {label}
      </div>
    </div>
  )
}

export default HighlightSectionBtn