import { Box, Button, FormLabel, PseudoBox, Stack, Checkbox, NumberInput } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { VisuallyHidden } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getUserApplicationId } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import { useFormControls } from '../../hooks'
import AlertDialogSimple from '../../../components/common/alert-dialog-simple'

export default function MenuForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['forms'])

  const appId = useSelector(getUserApplicationId)
  const { isSubmitBlocked } = useFormControls()

  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })

  ///Get All sections

  ////Const Vaules inputs - OnChanges

  const [seccionIdValue, setSeccionV] = useState()
  const [seccionIdPadreValue, setSeccionPV] = useState()
  //const [ordenValue, setOrdenV] = useState();

  const [urlChecked, setUrlChecked] = useState(false)
  const [defaultChecked, setDefaultChecked] = useState(false)
  // console.log("defaultChecked",defaultChecked)
  const [activoChecked, setActivoChecked] = useState(false)

  function loadValuesInputs() {
    if (restProps.dataEditParams.esDefault) {
      setDefaultChecked(true)
      //console.log('Es Default')

      //sectionId
      const miSectionId = restProps.combined.find((csection) => csection.section.id === restProps.dataEditParams.seccionId)
      setSeccionV(miSectionId.id)

      //sectionIdPadre
      if (restProps.dataEditParams.seccionIdPadre === null) {
        setSeccionPV(0)
      } else {
        setSeccionPV(restProps.dataEditParams.seccionIdPadre)
      }
    } else {
      setDefaultChecked(false)
      // console.log('Es Custom')

      //sectionId
      const miSectionId = restProps.combined.find((csection) => {
        return csection.section.id === restProps.dataEditParams.seccionId
      })
      setSeccionV(miSectionId.id)

      //sectionIdPadre
      if (restProps.dataEditParams.seccionIdPadre === null) {
        setSeccionPV(0)
      } else {
        setSeccionPV(restProps.dataEditParams.seccionIdPadre)
      }
    }
    //restProps.dataEditParams

    setOrden(restProps.dataEditParams.orden)
    setUrlChecked(restProps.dataEditParams.esUrl)
    setActivoChecked(restProps.dataEditParams.activo)
  }

  useEffect(() => {
  }, [restProps.CS, restProps.DS, restProps.combined])

  useEffect(() => {
    loadValuesInputs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  ///OnClose Control
  async function onClose() {
    document.getElementById('menu-form').reset()
    onCloseControlAlert()
  }

  ///On Guardar
  function onSubmitt(datos) {
    let miCS
    let miDS
    if (!defaultChecked) {
      miCS = restProps.combined.find((csection) => csection.id === Number(datos.sectionId))
    } else {
      miDS = restProps.combined.find((csection) => csection.id === Number(datos.sectionId))
    }

    // control CS
    if (!defaultChecked && miCS.section.id === Number(datos.Section)) {
      setControlAlert((state) => ({
        ...state,
        show: true,
        data: datos,
      }))
      setControl(true)
      return
    }
    // control DS
    if (defaultChecked && miDS.section.id === Number(datos.Section)) {
      setControlAlert((state) => ({
        ...state,
        show: true,
        data: datos,
      }))
      setControl(true)
      return
    }
    let formData = {
      esUrl: urlChecked,
      orden: orden,
      activo: activoChecked,
    }


    onSubmit(formData)
  }

  /// Hook Orden numerico
  const [orden, setOrden] = useState(0)
  const ordenChange = (value) => setOrden(value)

  //reset orden max
  useEffect(() => {
    if (orden > 99) {
      setOrden(99)
    }
  }, [orden])

  //// Hook control mismo option select
  const [controlAlert, setControlAlert] = useState({
    show: false,
    data: null,
  })
  const onCloseControlAlert = () => setControlAlert((state) => ({ ...state, show: false }))
  const [control, setControl] = useState(false)

  function onChangeSID(e) {
    setSeccionV(e.target.value)
    const selectedIndex = e.target.options.selectedIndex
    let key = e.target.options[selectedIndex].getAttribute('data-key')
    if (key <= restProps.CS.length) {
      setDefaultChecked(false)
    } else {
      setDefaultChecked(true)
    }
  }
  useEffect(() => {}, [defaultChecked])

  return (
    <PseudoBox as="form" onSubmit={handleSubmit((d) => onSubmitt(d))} {...restProps}>
      {/* Alert Control select */}
      <AlertDialogSimple
        control={control}
        isOpen={controlAlert.show}
        onClose={onCloseControlAlert}
        onAccept={onClose}
        acceptLabel={t('common:accept')}
      >
        {t('common:controlSelect')} <br />
      </AlertDialogSimple>

      <Stack>
        {/* Secciones custom + default */}
        <VisuallyHidden>
          <Box>
            <FormLabel htmlFor="sectionId" isRequired>
              {t('sections:chooseSection')}
            </FormLabel>
            <SelectAsync
              isRequired
              placeholder={t('sections:chooseSection')}
              name="sectionId"
              value={seccionIdValue}
              nativeProps={{ ref: register }}
              isLoading={restProps.isLoadingCS && restProps.isLoadingDS}
              onChange={(e) => {
                onChangeSID(e)
              }}
            >
              {!restProps.isLoadingCS &&
                !restProps.isLoadingDS &&
                restProps.combined &&
                restProps.combined.map((type, key) => (
                  <option key={key} data-key={type.id} value={type.id}>
                    {type.section.nombre ? type.section.nombre : type.section.descripcion}
                  </option>
                ))}
            </SelectAsync>
          </Box>
        </VisuallyHidden>
        {/* } */}

        {/* Seccion padre = custom o default*/}
        {defaultChecked ? (
          <VisuallyHidden>
            <Box>
              <FormLabel htmlFor="seccionIdPadre" isRequired>
                {t('sections:choosePrincipalSection')}
              </FormLabel>
              <SelectAsync
                placeholder={t('sections:choosePrincipalSection')}
                name="seccionIdPadre"
                value={seccionIdPadreValue}
                nativeProps={{ ref: register }}
                isLoading={restProps.isLoadingDS}
                onChange={(e) => {
                  setSeccionPV(e.target.value)
                }}
              >
                {!restProps.isLoadingDS &&
                  restProps.DS.map((type, key) => (
                    <option key={key} value={type.id}>
                      {type.nombre ? type.nombre : type.descripcion}
                    </option>
                  ))}
              </SelectAsync>
            </Box>
          </VisuallyHidden>
        ) : (
          <VisuallyHidden>
            <Box>
              <FormLabel htmlFor="seccionIdPadre" isRequired>
                {t('sections:choosePrincipalSection')}
              </FormLabel>
              <SelectAsync
                placeholder={t('sections:choosePrincipalSection')}
                name="seccionIdPadre"
                value={seccionIdPadreValue}
                nativeProps={{ ref: register }}
                isLoading={restProps.isLoadingCS}
                onChange={(e) => {
                  setSeccionPV(e.target.value)
                }}
              >
                {!restProps.isLoadingCS &&
                  restProps.CS.map((type, key) => (
                    <option key={key} value={type.id}>
                      {type.nombre ? type.nombre : type.descripcion}
                    </option>
                  ))}
              </SelectAsync>
            </Box>
          </VisuallyHidden>
        )}

        {/* Orden */}
        <Box>
          <FormLabel htmlFor="order" isRequired>
            {t('forms:order')}
          </FormLabel>
          <NumberInput
            isRequired={true}
            value={orden}
            onChange={ordenChange}
            defaultValue={0}
            min={0}
            max={99}
            keepWithinRange={true}
            clampValueOnBlur={true}
          />
        </Box>
        {/* } */}

        {/* Checkboxs */}
        <Box d="flex" alignSelf="center" justifyContent="space-between">
          {/* Es Url */}
          <Box d="flex" justifyContent="center" alignSelf="center">
            {t('common:internal')}
            <Checkbox ml="5" isChecked={urlChecked} onChange={(e) => setUrlChecked(e.target.checked)} />
          </Box>
          {/* Activo */}
          <Box d="flex" ml="5" justifyContent="center" alignSelf="center">
            Activo
            <Checkbox ml="5" isChecked={activoChecked} onChange={(e) => setActivoChecked(e.target.checked)} />
          </Box>
        </Box>
        {/*  } */}

        <Button mt={6} variantColor="blue" variant="outline" type="submit" isLoading={isSubmitBlocked}>
          {t('forms:save')}
        </Button>
      </Stack>
    </PseudoBox>
  )
}
