import React, { useEffect, useState /* , useEffect  */ } from 'react'
import { Panel } from '../../../common/helpers'
import { useTranslation } from 'react-i18next'
import { useAsyncStateWithAction, useAsyncToastNotification, usePermission } from '../../../hooks'
import Table from '../../../common/table'
import { useSelector } from 'react-redux'
import { getToken, getAuthToken, getIgnoreCategoriesState, getUserInformation, getUserCountryId } from '../../../../redux/selectors'
import { useToast, Stack, Box, Text, Spinner } from '@chakra-ui/core'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import { ButtonDelete } from '../../../forms/buttons/edit'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import { createIgnoreCategory, deleteIgnoreCategory } from '../../../../api/intranet/marca-blanca/banner'
import { useMemo } from 'react'
import { listIgnoreCategories } from '../../../../redux/actions/banners-actions'
import DefaultCategoryForm from './defaultCategory-form'
import { BANNERS_CREATE } from '../../../../helpers/permissions'
import Pagination from '../../../common/pagination'

export default function DefaultCategory() {
  const { addPermissions } = usePermission()
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const userCountryId = useSelector(getUserCountryId)
  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const user = useSelector(getUserInformation)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [refetchCategoriesTrigger, setRefetchCategoriesTrigger] = useState(0)
  const dispatchedGetIgnoreCategories = dispatch => dispatch(listIgnoreCategories(user.aplicacion.id, userCountryId, page))
  const { error, isLoading, items, update } = useAsyncStateWithAction(
    dispatchedGetIgnoreCategories,
    getIgnoreCategoriesState,
  )

  useEffect(() => {
    if (items) {
      setTotalPages(items?.totalPages)
    }
  }, [items])

  useEffect(() => {
    update()
  }, [page])

  async function submitIgnoreCategory(formData) {
    try {
      const response = await createIgnoreCategory(formData, authToken)
      if (response.status === 201) {
        update()
        setRefetchCategoriesTrigger(state => state + 1 )
      }
      toast(
        response.status === 201
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(error)
    }
  }

  function onSubmit(datos) {
    let formData = {
      empresaId: user.aplicacion.id,
      categoriaBanner: {
        id: parseInt(datos.category),
      },
    }
    submitIgnoreCategory(formData)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: row => {
          return row?.categoriaBanner?.nombre
        },
      },
      {
        Header: t('forms:description'),
        accessor: row => {
          return row?.categoriaBanner?.descripcion
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline textAlign='center' d='flex' justifyContent='center'>
            <ButtonDelete customLabel={t('common:delete')} onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ],
    [t],
  )

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })

  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
    setPage(1)
  }

  async function onDelete() {
    const data = addPermissions(
      {
        defaultCategory_id: parseInt(deleteAlert.data.id),
      },
      BANNERS_CREATE,
    )
    await asyncNotificationWrapper(async () => {
      const response = await deleteIgnoreCategory(deleteAlert.data.id, authToken)
      update()
      setRefetchCategoriesTrigger(state => state + 1 )
      return response.status
    })
    onCloseDeleteAlert()
  }

  return (
    <>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert?.data?.categoriaBanner?.descripcion} #${deleteAlert?.data?.id}`}</b>
      </AlertDialogSimple>

      {/* </Flex> */}

      {/* Formulario */}
      <Panel rounded='md'>
        <DefaultCategoryForm onSubmit={onSubmit} refetchCategoriesTrigger={refetchCategoriesTrigger} />
      </Panel>

      {/* Tabla */}
      <Box mt='8'>
        {!isLoading && items?.data && <Table columns={columns} data={items?.data} />}
        {isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!isLoading && !error && !items?.data && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!isLoading && !items?.data && error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
        {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
      </Box>
    </>
  )
}
