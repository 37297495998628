import { Box, Button, FormLabel, PseudoBox, Grid } from '@chakra-ui/core'
import React, { useMemo, useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { updateSupplier } from '../../../api/intranet/requests'
import { getAllCountries } from '../../../api/application'
import { getCategoriesPaisId } from '../../../api/intranet'
import { SUPPLIERS_CRUD_SELECT_COUNTRY } from '../../../helpers/permissions'
import { getToken, getUserApplication, getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import { useFormControls, usePermissionChecker } from '../../hooks'
import FormInput from '../form-input'
import ImageInput from '../../common/image-input'
import { useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedSuccess, toastCustomizedError } from '../../common/toast-default-config'
import { Checkbox } from 'antd'

export default function SupplierEditGeneralForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const toast = useToast()

  const { t } = useTranslation(['forms', 'suppliers', 'applications'])
  const token = useSelector(getToken)
  const userApplication = useSelector(getUserApplication)
  const { canDoThis } = usePermissionChecker()
  const { isSubmitBlocked } = useFormControls()
  const { id: supplierId } = useParams()
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId == 2 ? true : false
  const [edit, setEdit] = useState(false)
  const [countrySelected, setCountrySelected] = useState(true)
  const [paisId, setPaisId] = useState(formData ? formData.paisId : '')

  const { register, handleSubmit, control, setValue } = useForm({
    defaultValues: formData,
  })
  const [soloAdmin, setSoloAdmin] = useState(formData ? formData.soloAdmin : false)
  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
  })
  const [categories, setCategories] = useState({
    data: [],
    isLoading: true,
  })

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries(token)
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }
  const getSuppliersCategories = async id => {
    setCategories(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getCategoriesPaisId(id ? id : paisId)
      setCategories(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }
  useEffect(() => {
    getCountries()
    getSuppliersCategories()
    if (permisosUserCountry) setCountrySelected(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countrySelectorConfig = useMemo(() => {
    const formDataOrUseDafult = defaultValue => (formData ? formData.paisId : defaultValue)
    return canDoThis(
      SUPPLIERS_CRUD_SELECT_COUNTRY,
      () => ({
        isHidden: false,
        defaultValue: formDataOrUseDafult(0),
      }),
      () => ({
        isHidden: true,
        defaultValue: formDataOrUseDafult(userApplication.paisId),
      }),
    )
  }, [canDoThis, formData, userApplication.paisId])

  //--- --- --🦖
  async function Update(data) {
    let dates = {
      nombre: data.nombre,
      paisId: parseInt(data.paisId),
      imagen: data.imagen,
      categoria: { id: data.categoria.id ? parseInt(data.categoria.id) : parseInt(formData.categoria.id) },
      dominio: data.dominio,
      cuit: data.cuit,
      razonSocial: data.razonSocial,
      legajo: data.legajo,
      web: data.web,
      titular: data.titular,
      telefono: data.telefono,
      email: data.email,
      tipoAplicacionId: data.tipoAplicacionId,
      soloAdmin: soloAdmin,
      activo: true,
    }
    try {
      const response = await updateSupplier(supplierId, dates, authToken)
      setEdit(true)
      toast(
        response.status
          ? toastCustomizedSuccess(t('forms:editSuppliersSucess'))
          : toastCustomizedError('Error', 'Ocurrio un error al cargar el incentivo'),
      )
    } catch (error) {
      console.log('error')
    }
  }

  function submit(data, e) {
    Update(data)
  }

  useEffect(() => {
    setEdit(false)
  }, [])

  const handleChange = (newValue, onChange) => {
    onChange(newValue)
    setPaisId(newValue)
    getSuppliersCategories(newValue)
    setCountrySelected(false)
  }

  return (
    <>
      <PseudoBox as='form' onSubmit={handleSubmit(submit)} {...restProps}>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} width='100%'>
          <FormInput
            isRequired
            name='nombre'
            label={t('forms:name')}
            inputProps={{
              placeholder: t('forms:name'),
              ref: register,
              type: 'text',
            }}
          />
          <ImageInput
            isRequired
            name='imagen'
            label={t('forms:logo')}
            setValue={setValue}
            inputProps={{
              placeholder: `URL ${t('forms:logo')}`,
              ref: register,
              type: 'text',
            }}
          />
          <Box>
            <FormLabel htmlFor='pais_id' isRequired>
              {t('forms:country')}
            </FormLabel>
            <Controller
              control={control}
              name='paisId'
              defaultValue={countrySelectorConfig.defaultValue}
              render={({ onChange, value, name }) => (
                <SelectAsync
                  // onChange={e => onChange(e.target.value)}
                  onChange={e => handleChange(e.target.value, onChange)}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={paisId ? paisId : userCountryId}
                  isLoading={countries.isLoading}
                  isRequired
                  isDisabled={permisosUserCountry}
                >
                  {countries.data
                    .sort((a, b) => a.nombre.localeCompare(b.nombre))
                    .map(({ id, nombre }) => (
                      <option key={id} value={id}>
                        {nombre}
                      </option>
                    ))}
                </SelectAsync>
              )}
            />
          </Box>
          <Box>
            <FormLabel htmlFor='categoria_id' isRequired>
              {t('forms:category')}
            </FormLabel>
            <SelectAsync
              name='categoria.id'
              isLoading={categories.isLoading}
              placeholder={t('forms:selectOption')}
              nativeProps={{ ref: register }}
              isRequired
              isDisabled={permisosUserCountry}
            >
              {categories.data.map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {t(`suppliers:${nombre}`)}
                </option>
              ))}
            </SelectAsync>
          </Box>
          <Box>
            <FormInput
              name='dominio'
              isRequired
              label={t('forms:domainLabel')}
              inputProps={{
                type: 'text',
                placeholder: t('forms:domain'),
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormInput
              name='cuit'
              isRequired
              label={t('forms:taxpayer_identification')}
              inputProps={{
                type: 'text',
                placeholder: t('forms:taxpayer_identification'),
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormInput
              name='razonSocial'
              isRequired
              label={t('applications:businessName')}
              inputProps={{
                type: 'text',
                placeholder: t('applications:businessName'),
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormInput
              name='legajo'
              isRequired
              label={t('applications:file')}
              inputProps={{
                type: 'text',
                placeholder: t('applications:file'),
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormInput
              name='web'
              isRequired
              label='Web'
              inputProps={{
                type: 'text',
                placeholder: 'Web',
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormInput
              name='titular'
              isRequired
              label={t('applications:holder')}
              inputProps={{
                type: 'text',
                placeholder: t('applications:holder'),
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormInput
              name='telefono'
              isRequired
              label={t('applications:phone')}
              inputProps={{
                type: 'text',
                placeholder: t('applications:phone'),
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormInput
              name='email'
              isRequired
              label={t('applications:email')}
              inputProps={{
                type: 'text',
                placeholder: t('applications:email'),
                ref: register,
              }}
            />
          </Box>
          <Box>
            <FormLabel>{t('applications:visibility')}</FormLabel>
            <Box d='flex' justifyContent='left' alignItems='center'>
              <Checkbox
                style={{ padding: '.5rem .5rem .5rem 0' }}
                checked={soloAdmin}
                name='soloAdmin'
                onChange={e => setSoloAdmin(e.target.checked)}
              />
              <p>{t('applications:onlyAdmin')}</p>
            </Box>
          </Box>
        </Grid>
        <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
          {buttonLabel || t('forms:save')}
        </Button>
      </PseudoBox>
    </>
  )
}
