import React from "react";
import { Button } from "@chakra-ui/core";
import { MdBuild } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { IoMdPin, IoMdTrash } from "react-icons/io";

export {
	ButtonEdit,
	ButtonDelete,
	ButtonCustom,
	CommonButton,
	ButtonUbication,
};

function ButtonEdit({ onClick = () => { }, customLabel = false, ...restProps }) {
	const { t } = useTranslation(["common"]);
	return (
		<CommonButton
			icon={MdBuild}
			label={customLabel || t("common:edit")}
			onClick={onClick}
			{...restProps}
		/>
	);
}

function ButtonDelete({ onClick = () => { }, customLabel = false, ...restProps }) {
	const { t } = useTranslation(["common"]);
	return (
		<CommonButton
			icon={IoMdTrash}
			label={customLabel || t("common:delete")}
			onClick={onClick}
			variantColor="red"
			{...restProps}
		/>
	);
}

function ButtonCustom({ onClick = () => { }, label = false, ...restProps }) {
	return <CommonButton
		icon={IoMdTrash}
		label={label}
		onClick={onClick}
		{...restProps}
	/>;
}

function CommonButton({ icon, label = false, onClick, ...restProps}) {
	return (
		<Button
			leftIcon={icon}
			variantColor={"blue"}
			variant="outline"
			size="xs"
			onClick={onClick}
			{...restProps}
		>
			{label}
		</Button>
	);
}

function ButtonUbication({ onClick = () => { }, customLabel = false, ...restProps }) {
	const { t } = useTranslation(["common"]);
	return (
		<CommonButton
			icon={IoMdPin}
			label={customLabel || t("common:ubication")}
			onClick={onClick}
			variantColor="green"
			{...restProps}
		/>
	);
}