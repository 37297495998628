import React from 'react'
import DefaultSectionForm from '../../../forms/sections/default-section'
import { getAuthToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { UpdateDefaultSection } from '../../../../api/section'

export default function CreateDefaultSection({ onFinish = () => null, getSection, onClose, ...restProps }) {
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()

  const handleSubmit = async formData => {
    let sectionId = restProps.idSectionn
    try {
      const response = await UpdateDefaultSection(sectionId, formData, authToken)
      if (!response.data.statusCode) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        getSection()
        onClose()
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    onFinish()
  }

  return (
    <div>
      <DefaultSectionForm onSubmit={handleSubmit} buttonLabel={t('common:create')} idSectionn={restProps.idSectionn} />
    </div>
  )
}
