import React from 'react'
import { Box, Flex } from '@chakra-ui/core'
import SupplierItem from './supplier-item'

export const SupplierCollection = ({ items, handleOpenModal, parametros, ...restProps }) => (
    <Box mb='20px' {...restProps}>
      <Flex style={{ flexWrap: 'wrap', gap: '0.8rem' }}>
        {items?.map(item => (
          <SupplierItem key={item.id} data={item} handleOpenModal={handleOpenModal} parametros={parametros}/>
        ))}
      </Flex>
    </Box>
  )

export default SupplierCollection