import React from 'react'
import { Impuesto } from './impuesto'
import { SectionDataProvider } from '../../../context/SectionDataContext'

const FILTER_KEYS = {
  taxName: 'taxName',
  countryId: 'countryId',
  taxType: 'taxType',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  impuesto: 'impuesto',
}

const defaultSectionDataValues = {
  impuesto: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 15,
  taxName: '',
  taxType: '',
  countryId: 0,
}

const defaultFiltersValues = { ...defaultFilters }

const ImpuestoSection = () => (
  <SectionDataProvider defaultFilters={{ ...defaultFiltersValues }} defaultSectionData={defaultSectionDataValues}>
    <Impuesto />
  </SectionDataProvider>
)

export {
  ImpuestoSection,
  FILTER_KEYS,
  SECTION_DATA_KEYS,
  defaultSectionDataValues,
  defaultFiltersValues,
  defaultFilters,
}
