import React, { useEffect, useState /* , useEffect  */ } from 'react'
import { Panel } from '../../../common/helpers'
import { useTranslation } from 'react-i18next'
import { useAsyncToastNotification, usePermission } from '../../../hooks'
import Table from '../../../common/table'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserInformation, getUserCountryId } from '../../../../redux/selectors'
import { Stack, Box, Text, Spinner } from '@chakra-ui/core'
import { ButtonDelete } from '../../../forms/buttons/edit'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import { useMemo } from 'react'
import { BANNERS_CREATE } from '../../../../helpers/permissions'
import DisabledLinksForm from './disabledLinks-form'
import { createDisabledLink, deleteDisabledLink, getDefaultEnlaceByParams, getDisabledLinksByParams, updateDisabledLink } from '../../../../api/intranet/marca-blanca'
import './styles.css'

export default function DisabledLinks() {
  const { addPermissions } = usePermission()
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const user = useSelector(getUserInformation)
  const countryId = useSelector(getUserCountryId)
  const [trigger, setTrigger] = useState(0)

  const [items, setItems] = useState({
    isLoading: false,
    data: [],
    error: false,
  })
  const [defaultItems, setDefaultItems] = useState({
    isLoading: false,
    data: [],
    error: false,
  })

  async function getAllDefaultEnlace() {
    setDefaultItems(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getDefaultEnlaceByParams({ paisId: countryId }, authToken)
      setDefaultItems(state => ({
        ...state,
        isLoading: false,
        data: res.data.filter(item => !item.activo) || [],
      }))
    } catch (error) {
      setDefaultItems(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  async function getAllDisabledLinks(appId) {
    setItems(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getDisabledLinksByParams({ agenciaId: appId, paisId: countryId }, authToken)
      setItems(state => ({
        ...state,
        isLoading: false,
        data: res.data.filter(item => !item.activo) || [],
      }))
    } catch (error) {
      setItems(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  async function onSubmit(datos) {
    const linkDisabled = defaultItems.data.filter(item => item.id == datos.link) 
    await createDisabledLink({
      nombre: linkDisabled[0].nombre,
      agenciaId: user.aplicacion.id,
      paisId: countryId,
      defaultEnlaceId: linkDisabled[0].id
    }, authToken)
    getAllDisabledLinks(user.aplicacion.id)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: row => {
          return row?.nombre
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline textAlign='center' d='flex' justifyContent='center'>
            <ButtonDelete customLabel={t('common:delete')} onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ],
    [t],
  )

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })

  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
  }

  async function onDelete() {
    const data = addPermissions(
      {
        defaultCategory_id: parseInt(deleteAlert.data.id),
      },
      BANNERS_CREATE,
    )
    await asyncNotificationWrapper(async () => {
      const response = await  deleteDisabledLink(deleteAlert.data.id)
      setTrigger(state => state + 1 )
      getAllDisabledLinks(user.aplicacion.id)
      return response.status
    })
    onCloseDeleteAlert()
  }

  useEffect(() => {
    getAllDisabledLinks(user.aplicacion.id)
    getAllDefaultEnlace()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert?.data?.nombre} #${deleteAlert?.data?.id}`}</b>
      </AlertDialogSimple>

      {/* </Flex> */}

      {/* Formulario */}
      <Panel rounded='md'>
        <DisabledLinksForm onSubmit={onSubmit} trigger={trigger} />
      </Panel>

      {/* Tabla */}
      <Box mt='8'>
        {!items.isLoading && items?.data && <Table columns={columns} data={items?.data} />}
        {items.isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!items.isLoading && !items.error && !items?.data && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!items.isLoading && !items?.data && items.error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
      </Box>
    </>
  )
}
