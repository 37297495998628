import React from 'react'

import { SectionDataProvider } from '../../../context/SectionDataContext'
import Group from './group-content'

const FILTER_KEYS = {
  supplier: 'supplier',
  destination: 'destination',
  endDate: 'endDate',
  startDate: 'startDate',
  countryId: 'countryId',
  days: 'days',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  group: 'group',
}

const defaultSectionDataValues = {
  group: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFiltersValues = {
  page: 1,
  maxPerPage: 6,
  supplier: '',
  destination: '',
  days: '',
  endDate: '',
  startDate: '',
  countryId: ''
}

const GroupMenu = () => (
  <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
    <Group />
  </SectionDataProvider>
)

export { GroupMenu, FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues, defaultFiltersValues }
