import React from 'react'
import { Box, Checkbox } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'

const CustomCheckbox = ({ label = '', active = false, setActiveValue = () => {}, ...props }) => {
  const { t } = useTranslation(['forms'])

  return (
    <Box d='flex' alignItems='center' fontSize='lg' mr='4' {...props}>
      {label}
      <Checkbox name='active' ml='2' size='lg' isChecked={active} onChange={e => setActiveValue(e.target.checked)} />
    </Box>
  )
}

export default CustomCheckbox
