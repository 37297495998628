import React, { useEffect, useState } from 'react'
import { Box, Heading } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAuthToken, getUserApplicationId, getUserCountryId } from '../../../redux/selectors'
import { getAppsGea } from '../../../api/application'
import ReactHtmlParser from 'react-html-parser'
import { PageContainer } from '../../common/helpers'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

export const TravelGea = () => {
  const { t } = useTranslation(['TravelGea'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const [travelGea, setTravelGea] = useState([])

  async function getTravelGea() {
    try {
      const res = await getAppsGea(authToken, { paisId: countryId, nombre: 'travelgea' })
      if (res) setTravelGea(res.data)
    } catch (error) {
      if (error.message) {
        console.log(error.message)
      }
    }
  }

  useEffect(() => {
    getTravelGea()
  }, [])

  return (
    <Box py='10px' pb='0.5%' color='#103059'>
      <PageContainer>
        <Heading style={{ fontSize: '2rem', color: '#103059', marginBottom: '2rem' }}>
          {t('common:navbar.travelgea')}
        </Heading>
        <Box style={{ backgroundColor: 'white', padding: '3rem', borderRadius: '10px' }}>
          <ReactQuill value={travelGea.contenidoHtml} readOnly={true} theme={'bubble'} />
          {/* {ReactHtmlParser(travelGea.contenidoHtml)} */}
        </Box>
      </PageContainer>
    </Box>
  )
}
