import React from 'react'
import { Flex, Text } from '@chakra-ui/core'
import './styles.css'
import IncentiveDetailsTable from '../IncentiveDetailsTable'
import { useTranslation } from 'react-i18next'

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  timeZone: 'UTC',
}

const IncentiveDetailFieldData = ({ label, value }) => {
  return (
    <Flex className='incentive-details__top__data__field'>
      <Text className='incentive-details__top__data__field__label'>{label}</Text>
      <Text>{value}</Text>
    </Flex>
  )
}

const IncentiveDetails = props => {
  const { incentive, statusScala, filteredStatusScala } = props
  const { t } = useTranslation(['incentives', 'common'])
  return (
    <Flex className='incentive-details'>
      <Flex className='incentive-details__top'>
        <Text className='incentive-details__top__period'>
          {t('incentives:period')}: {new Date(incentive?.fechaInicio).toLocaleDateString('es-ES', options)}{' '}
          {t('common:to_text')} {new Date(incentive?.fechaFin).toLocaleDateString('es-ES', options)}
        </Text>
        <Flex className='incentive-details__top__data'>
          {[
            {
              label: t('incentives:cumulativeSale'),
              value: `${incentive?.unidadMedicion.simbolo}
            ${
              incentive?.incentivoStatus?.[0]?.montoParcial
                ? new Intl.NumberFormat('de-DE').format(incentive?.incentivoStatus?.[0]?.montoParcial)
                : '0'
            }`,
            },
            { label: t('incentives:numberOfMonths'), value: incentive?.incentivoStatus?.[0]?.mesesCargados ?? '---' },
            {
              label: t('incentives:annualProjection'),
              value: `${incentive?.unidadMedicion.simbolo}
            ${
              incentive?.incentivoStatus?.[0]?.montoParcial
                ? new Intl.NumberFormat('de-DE').format(
                    incentive.incentivoStatus?.[0].mesesCargados > 0
                      ? (
                          (Number(incentive.incentivoStatus?.[0].montoParcial) /
                            incentive.incentivoStatus?.[0].mesesCargados) *
                          incentive.incentivoStatus?.[0].cantidadMeses
                        ).toFixed(2)
                      : 0,
                  )
                : '0'
            }`,
            },
          ].map(item => (
            <IncentiveDetailFieldData {...item} key={`${incentive.id}-${item.label}`} />
          ))}
        </Flex>
      </Flex>
      <IncentiveDetailsTable data={{ ...props, statusScala }} />
    </Flex>
  )
}

export default IncentiveDetails
