import React, { useState, useEffect, useMemo } from "react";
import { Grid, useToast, FormLabel, Tag, Button, Box } from "@chakra-ui/core";
import { GridItem } from '@chakra-ui/react'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { getAllCategories } from "../../../../../api/intranet/requests";
import { getAuthToken } from "../../../../../redux/selectors";
import { toastCustomizedError } from "../../../../common/toast-default-config";
import { Panel } from "../../../../common/helpers";
import { Select } from "antd";
import { ButtonDelete } from "../../../../forms/buttons/edit";
import Table from "../../../../common/table";
const { Option } = Select;

export const PackageCategories = ({ onChange, state = false }) => {
    const { t } = useTranslation(["common", "forms", "packages"]);
    const authToken = useSelector(getAuthToken)
    const toast = useToast()

    const [categories, setCategories] = useState({
        data: [],
        isLoading: true,
    })

    const [selectedCategories, setSelectedCategories] = useState(state?.categories?.map(cat => { console.log('useState', cat); return { category: { id: cat?.category?.id, label: cat?.category?.description } } }))

    const [unaddedCategories, setUnaddedCategories] = useState([])

    const getCategories = async () => {
        setCategories(state => ({
            ...state,
            isLoading: true,
        }))
        try {
            const { data: response } = await getAllCategories(authToken)

            setCategories(() => ({
                data: response.data.map((c) => { return { id: c.id, description: c.description } }),
                isLoading: false,
            }))
        } catch (error) {
            console.log(error);
            toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
        }
    }

    useEffect(() => {
        getCategories()
    }, [])

    let filteredCategories = categories?.data?.filter(cat => !selectedCategories?.map(selected => selected.category.id).includes(cat.id))

    function onSelectedChanges(e) {
        setUnaddedCategories(e.map(cat => { return { id: cat.value, label: cat.label }}))
    }
    
    function addCategories() {
        const newSelected = selectedCategories.concat(unaddedCategories.map(cat => { return { category: { id: cat.id, label: cat.label }}}))
        setSelectedCategories(newSelected)
        setUnaddedCategories([])
        filteredCategories = categories?.data?.filter(cat => !newSelected?.map(selected => selected.category.id).includes(cat.id))
        const changes = newSelected.map((c) => { return { id: c.category.id }})
        onChange(changes)
    }

    function removeCategory(categoryId) {
        const newSelected = selectedCategories.filter(cat => cat.category.id !== categoryId)
        setSelectedCategories(newSelected)
        filteredCategories = categories?.data?.filter(cat => !newSelected?.map(selected => selected.category.id).includes(cat.id))
        const changes = newSelected.map((c) => { return { id: c.category.id }})
        onChange(changes)
    }

    const columns = useMemo(
        () => [
            {
                Header: t('packages:categories'),
                accessor: row => {
                    let cellValue = row.category.label
                    if (cellValue.length > 33) {
                        cellValue = `${cellValue.substring(0, 33)}...`
                    }
                    return cellValue
                }
            },
            {
                Header: t('common:actions'),
                accessor: null,
                Cell: ({ cell }) => {
                  return (
                    <ButtonDelete onClick={() => removeCategory(cell.row.original.category.id)} />
                  )
                }
            }
        ],
        [selectedCategories]
    )

    return (
        <Panel mb="8">
            <Tag width="100%" mb="3" p="3" bg="blue.500" color="white">{t("packages:categories")}</Tag>
            <Grid
                templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                gap={6}
            >
                {!categories.isLoading && categories.data.length && (
                    <Grid templateColumns={'repeat(5, 1fr)'} gap='0.5rem'>
                        <GridItem colSpan={4} display='grid' alignContent={'baseline'}>
                            <FormLabel htmlFor='categories'>{t('packages:categoriesLabel')}</FormLabel>
                            <Select
                                mode='multiple'
                                name='categories'
                                placeholder={t('forms:select')}
                                style={{display: 'block'}}
                                onChange={onSelectedChanges}
                                value={unaddedCategories.map((cat) => cat.id)}
                                labelInValue
                            >
                                {filteredCategories?.map(({id, description}) => (
                                    <Option key={id} value={id} label={description}>
                                        {description}
                                    </Option>
                                ))}
                            </Select>
                        </GridItem>
                        <GridItem colSpan={1} display='grid' pt='28px'>
                            <Button variantColor='blue' variant='outline' w='fit-content' onClick={() => addCategories()}>Agregar</Button>
                        </GridItem>
                    </Grid>
                    
                )}
                <GridItem colSpan={1}>
                <FormLabel>{t('packages:categories')}</FormLabel>
                        <Box display='grid' height='300px'>
                            <Table columns={columns} data={selectedCategories} />
                        </Box>
                </GridItem>
            </Grid>
        </Panel>
    );
};

