import React, { useMemo, useState, useEffect } from 'react'
import { Button, Box, Text, Input } from '@chakra-ui/core'
import { Grid, GridItem } from '@chakra-ui/react'
import { SingleDatePicker } from 'react-dates'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import { getAuthToken, getUserCountryId, getUserApplicationId, getUserRolId } from '../../../../redux/selectors'

import SelectAsync from '../../../common/select-async'

import { obtenerCategorias } from '../../../../api/communications'
import { getAllCountries } from '../../../../api/application'
import FormInput from '../../../forms/form-input'
import { readFile } from '../../../../helpers/utils'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

function CreateCommunications({ formData = false, padre, type, recordToEdit, getCommunications }) {
  // - - General - -
  const token = useSelector(state => state.appState.user.token)
  const [paises, setPaises] = useState([])
  const [categories, setCategories] = useState([])
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const applicationId = useSelector(getUserApplicationId)
  const { t } = useTranslation(['comunications'])
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId == 2 ? true : false
  const [country, setCountry] = useState(recordToEdit ? recordToEdit.paisId : '')
  const typesWithImgInput = ['geaHome', 'news']

  // - - 📅 SingleDatePicker 📅 - -

  const [focusedInput, setFocusedInput] = useState(false)
  const [startDate, setStartDate] = useState(moment(formData ? formData?.fecha : recordToEdit?.fecha))

  // - - 📋 Form 📋 - -

  const { handleSubmit, register, control, setValue, reset, formState } = useForm({
    defaultValues: formData ? formData : recordToEdit,
  })

  // - - 📋 HTML 📋 - -

  const emptyInput = t('forms:html_file')
  const [fileChargeStatusMsg, setFileChargeStatusMsg] = useState(emptyInput)

  const [contentFromFile, setContentFromFile] = useState(undefined)

  const [convertSw, setConvertSw] = useState(false)
  const [contentValue, setContentValue] = useState(recordToEdit ? recordToEdit.comunicadoHtml : '')
  const [switchOptions, setSwitchOptions] = useState({
    destacado: ' ',
    borderColor: 'white',
  })

  var toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  // - - 🌥️ Cloudinary 🌥️- -

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_PRESET, REACT_APP_CLOUDINARY_FOLDER } = process.env
  const [imagState, setImagState] = useState([
    recordToEdit
      ? recordToEdit?.imagenUrl
      : 'https://res.cloudinary.com/travelit/image/upload/v1629216295/img-test/wbtbsdtjjv6cpz6cirki.jpg',
  ])

  const myCropWidget = useMemo(() => {
    return window?.cloudinary?.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: REACT_APP_CLOUDINARY_PRESET,
        folder: REACT_APP_CLOUDINARY_FOLDER,
        cropping: true,
      },
      (error, result) => {
        if (error) {
          alert('Cloudinary service unavailable')
        }
        checkUploadResult(result)
      },
    )
  }, [])

  function checkUploadResult(resultEvent) {
    if (resultEvent.event === 'success') {
      const img = [resultEvent.info.secure_url]
      setImagState(img)
    }
  }

  function setOnChange(e) {
    setCountry(e)
    if (!formData) {
      getComunicadosCategoria(e)
    }
  }

  function onChangeTrip(e, flg = null) {
    setStartDate(e)
  }

  async function onSubmit(data) {
    let destacado = false
    if (recordToEdit) destacado = recordToEdit.destacado
    data = {
      aplicacionId: applicationId,
      asunto: contentFromFile ? 'newsletter' : 'html',
      categoriasComunicados: { id: parseInt(data.categoriasComunicados.id) },
      tipoEnvio: { id: 1 },
      comunicadoHtml: contentFromFile ? contentFromFile : contentValue,
      descripcion: data.descripcion || type.title,
      destacado: destacado,
      fecha: startDate._d,
      imagenUrl: imagState[0],
      paisId: permisosUserCountry ? parseInt(userCountryId) : parseInt(country),
      subtitulo: data.subtitulo,
      tipoComunicado: { id: type.id },
      titulo: data.titulo || '',
      url: data.url,
    }

    if (recordToEdit) {
      await padre(data, recordToEdit.id)
    } else {
      await padre(data)
    }
    getCommunications()
    reset({})
  }

  async function getCountrys() {
    try {
      const resp = await getAllCountries(token)
      setPaises(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  async function getComunicadosCategoria(e) {
    try {
      const res = await obtenerCategorias(e, authToken)
      const filterSplit = res?.data?.map(x => {
        const category = x.descripcion.split('/')
        return { id: x.id, descripcion: category[0], type: category[1], paisId: e }
      })
      const filterCategories = filterSplit.filter(category => {
        if (category.type === type.slug) {
          return category
        }
      })
      setCategories(filterCategories)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getCountrys()
    if (recordToEdit) {
      getComunicadosCategoria(recordToEdit.paisId)
    }
    if (formData) {
      getComunicadosCategoria(formData.paisId)
    }
    if (permisosUserCountry) {
      getComunicadosCategoria(userCountryId)
    }
    if (formState.isSubmitSuccessful) {
      reset({})
    }
  }, [reset])

  const handleImport = async () => {
    const htmlToImport = document.querySelector('#htmlToImport')
    htmlToImport.click()
    htmlToImport.onchange = e => {
      const { files } = e.target
      if (files) {
        setFileChargeStatusMsg(files[0].name)
        readFile(files[0])
          .then(data => {
            setContentFromFile(data)
          })
          .catch(err => {
            setContentFromFile(undefined)
            console.log(err)
          })
      } else if (files === null) {
        setFileChargeStatusMsg(emptyInput)
      }
    }
  }

  function showWidget() {
    myCropWidget.open()
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns='repeat(2, 1fr)' gap={6}>
        {/* Pais */}
        <GridItem colSpan={1} rowSpan={1}>
          <Box style={{ margin: '0% 1%', padding: '1%' }}>
            <label style={{ margin: '2rem 0rem' }}> {t('comunications:country')}</label>
            <Controller
              control={control}
              name='paisId'
              defaultValue={recordToEdit ? recordToEdit.paisId : ''}
              render={({ onChange, value, name }) => (
                <SelectAsync
                  style={{ marginTop: '4px' }}
                  name={name}
                  value={permisosUserCountry ? userCountryId : value}
                  onChange={e => {
                    setOnChange(e.target.value)
                    return onChange(e.target.value)
                  }}
                  placeholder={t('comunications:selectCountry')}
                  isRequired={true}
                  isDisabled={permisosUserCountry}
                >
                  {paises &&
                    paises
                      .sort((a, b) => a.nombre.localeCompare(b.nombre))
                      .map(({ id, nombre }) => (
                        <option key={id} value={id}>
                          {nombre}
                        </option>
                      ))}
                </SelectAsync>
              )}
            />
          </Box>
        </GridItem>

        {/* Categoria  */}
        <GridItem colSpan={1} rowSpan={1}>
          <Box style={{ margin: '0% 1%', padding: '1%' }}>
            <label style={{ margin: '2rem 0rem' }}> {t('comunications:comunicationCategory')}</label>
            <Controller
              control={control}
              name='categoriasComunicados.id'
              defaultValue={recordToEdit ? recordToEdit.categoriasComunicados.id : ''}
              render={({ onChange, value, name }) => {
                return (
                  <SelectAsync
                    style={{ marginTop: '4px' }}
                    name={name}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    placeholder={
                      categories.length
                        ? t('comunications:selectCategory')
                        : t('comunications:comunicationCategoryDisabled')
                    }
                    //disabled={recordToEdit ? false : !(categories.length > 0)}
                    isRequired={formData == false ? true : false}
                  >
                    {categories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.descripcion}
                      </option>
                    ))}
                  </SelectAsync>
                )
              }}
            />
          </Box>
        </GridItem>

        {/* Titulo */}
        {!typesWithImgInput.includes(type.slug) && (
          <GridItem colSpan={1} rowSpan={1}>
            <FormInput
              style={{ margin: '0% 1%', padding: '1%' }}
              isRequired
              name='titulo'
              label={t('comunications:title')}
              inputProps={{
                type: 'tel',
                placeholder: t('comunications:title'),
                ref: register,
              }}
              //value={recordToEdit ? recordToEdit.titulo : ''}
            />
          </GridItem>
        )}

        {/* Bajada */}
        {!typesWithImgInput.includes(type.slug) && (
          <GridItem colSpan={1} rowSpan={1}>
            <FormInput
              style={{ margin: '0', padding: '1%' }}
              isRequired
              name='descripcion'
              label={t('comunications:details')}
              inputProps={{
                type: 'string',
                placeholder: t('comunications:details'),
                ref: register,
              }}
            />
          </GridItem>
        )}

        {/* Subtitulo */}
        <GridItem colSpan={1} rowSpan={1}>
          <FormInput
            style={{ margin: '0% 1%', padding: '1%' }}
            name='subtitulo'
            label={t('comunications:namelink')}
            inputProps={{
              type: 'tel',
              placeholder: t('comunications:namelink'),
              ref: register,
            }}
          />
        </GridItem>

        {/* URL */}
        <FormInput
          style={{ marginTop: '0% 1%', padding: '1%' }}
          name='url'
          label={t('comunications:Link')}
          inputProps={{
            type: 'string',
            placeholder: t('comunications:Link'),
            ref: register,
          }}
        />

        {/* imagen URL */}
        {typesWithImgInput.includes(type.slug) && (
          <GridItem colSpan={1} rowSpan={3}>
            <label> </label>
            <Box
              style={{ margin: '3% 5%', height: '25vh' }}
              backgroundImage={`url(${imagState[0]})`}
              backgroundPosition='center'
              backgroundRepeat='no-repeat'
              d='flex'
              backgroundSize='cover'
              height={400}
              justifyContent='center'

              //alignContent="flex-end"
            >
              <Button name='imagenUrl' size='lg' onClick={showWidget} variantColor='blue' alignSelf='center'>
                {t('comunications:uploadImage')}
              </Button>
            </Box>
          </GridItem>
        )}

        {/* Fecha */}
        <GridItem colSpan={1} rowSpan={1}>
          <Box style={{ margin: '0% 1%', padding: '1%' }}>
            <label>{t('comunications:date')}</label>
            <Box style={{ marginTop: '1%' }}>
              <SingleDatePicker
                isOutsideRange={() => false}
                small={true}
                block={true}
                showClearDate={true}
                showDefaultInputIcon={false}
                displayFormat='DD-MM-YYYY'
                numberOfMonths={1}
                placeholder={t('forms:selectDate') + ' DD-MM-YYYY'}
                date={startDate}
                onDateChange={date => onChangeTrip(date, 'departure_date')}
                focused={focusedInput}
                onFocusChange={() => setFocusedInput(!focusedInput)}
                id='departure_date'
              />
            </Box>
          </Box>
        </GridItem>
      </Grid>
      {/* Btn Enviar */}
      <Button mt={6} mb={2} ml={2} variantColor='blue' variant='outline' type='submit'>
        {recordToEdit ? type.edit : type.label}
      </Button>
    </form>
  )
}

export default CreateCommunications
