import React, { useEffect, useState } from 'react'
import { Box, Button, useToast } from '@chakra-ui/core'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import { useSelector } from 'react-redux'
import { Panel } from '../../../common/helpers'
import { getToken, getUserCountryId } from '../../../../redux/selectors'
import { createAppGea, getAppsGea, updateAppGea } from '../../../../api/application'
import { useTranslation } from 'react-i18next'
import { isSuccess } from '../../../../helpers/utils'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
// import JEditor from '../../sections/own-sections/new-editor'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default function GrupoGea() {
  const token = useSelector(getToken)
  const { appId } = useParams()
  const userCountryId = useSelector(getUserCountryId)
  const { t } = useTranslation(['common', 'applications', 'forms'])
  const toast = useToast()
  const [geaInfo, setGeaInfo] = useState()
  const [contentValue, setContentValue] = useState('')
  const [resetEditor, setResetEditor] = useState(false)
  const [isLoaded, setLoaded] = useState(false)

  async function getGeaInfo() {
    try {
      const response = await getAppsGea(token, { nombre: 'Grupo Gea', paisId: userCountryId })
      if (response.data) {
        setGeaInfo(response.data)
        setContentValue(response.data.contenidoHtml)
      }
      setLoaded(true)
    } catch (error) {
      setGeaInfo(undefined)
      console.log(error)
    }
  }

  useEffect(() => {
    getGeaInfo()
  }, [])

  async function handleSubmit() {
    try {
      let response
      if (geaInfo) {
        response = await updateAppGea(geaInfo.id, { contenidoHtml: contentValue }, token)
      } else {
        response = await createAppGea(
          { contenidoHtml: contentValue, aplicacionId: appId, paisId: userCountryId, nombre: 'Grupo Gea' },
          token,
        )
      }
      if (response && isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      console.log(error)
    }
  }

  var toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  return (
    <Box flex='3'>
      <Panel rounded='md' style={{ padding: '2rem' }}>
        {/* {isLoaded && <JEditor content={contentValue} setContent={setContentValue} reset={resetEditor} />} */}
        {isLoaded && (
          <ReactQuill
            style={{ display: 'flex', flexDirection: 'column', height: '460px', marginBottom: '30px' }}
            className=''
            modules={{ toolbar }}
            value={contentValue}
            onChange={setContentValue}
          />
        )}
        <Button
          style={{ marginTop: '2rem' }}
          variantColor='blue'
          variant='outline'
          type='submit'
          onClick={handleSubmit}
        >
          {t('common:save')}
        </Button>
      </Panel>
    </Box>
  )
}
