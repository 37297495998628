import React from 'react'
import { useSelector } from 'react-redux'
import './../../styles/home/home.css'
import UpcomingEvents from './upcomingEvents'
import FavSections from './favSections'
import LastNews from './lastNews'
import { getUserCountryId } from '../../../redux/selectors'

export default function Dashboard() {
  const userCountryId = useSelector(getUserCountryId)

  return (
    <>
      {/* SECCIONES DESTACADAS */}
      {userCountryId !== 3 ? <FavSections /> : ''}

      {/* ULTIMAS NOTICIAS */}
      <LastNews />

      {/* PROXIMOS EVENTOS Y CAPACITACIONES */}
      <UpcomingEvents />
    </>
  )
}
