import React, { useEffect } from 'react'
import { Box, Button, InputGroup, Text } from '@chakra-ui/core'
import FormInput from '../form-input'
import { useTranslation } from 'react-i18next'

export function EditExchangeForm(props) {
  const { t } = useTranslation(['common', 'forms', 'exchange'])

  const currency = props.currency

  const monOrigen = props.monedaOrigen
  const monDestino = props.monedaDestino

  let monedaOrigen = changeCurr(monOrigen)
  let monedaDestino = changeCurr(monDestino)

  function changeCurr(monOrigen) {
    let koko = currency.map((cur) => (cur.id === monOrigen ? cur.code : ''))
    return koko
  }

  useEffect(() => {}, [])

  return (
    <div>
      <Text fontSize="lg" textAlign="center" color="gray.500" padding="0.6rem">
        {t('exchange:youWillChange')} {monedaOrigen} {t('common:to')} {monedaDestino}
      </Text>
      <Box>
        <InputGroup marginLeft="10%" size="70%" alignContent="center" padding="1rem">
          <FormInput
            name="exchangeValue"
            onChange={(e) => props.onSubmitUpdateValue(e.target.value)}
            inputProps={{
              isRequired: true,
              type: 'number',
              step: 'any',
              placeholder: `${t('exchange:CurrencyValue')}`,
              // ref: register/* ({ pattern: /^[A-Za-z]+$i }) */
            }}
          />
          <Button width="20%" marginLeft="3%" variantColor="blue" variant="outline" onClick={() => props.btnEnviar()}>
            {t('common:save')}
          </Button>
        </InputGroup>
      </Box>
    </div>
  )
}
