import { Button, PseudoBox, SimpleGrid, Stack } from "@chakra-ui/core";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFormControls } from "../../hooks";
import FormInput from "../form-input";

export default function SupplierContactForm({
	onSubmit,
	formData = false,
	buttonLabel = false,
	...restProps
}) {
	const { t } = useTranslation(["forms"]);
	const { isSubmitBlocked, submitWrapper } = useFormControls();
	const { register, handleSubmit } = useForm({
		defaultValues: formData
	});

	function submit(data, e) {
		submitWrapper(async () => await onSubmit(data, e));
	}

	return (
		<PseudoBox as='form' onSubmit={handleSubmit(submit)} {...restProps}>
			<SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
				<FormInput
					isRequired
					label={t("forms:name")}
					name="nombre"
					inputProps={{
						placeholder: t("forms:name"),
						ref: register,
					}}
				/>
				<FormInput
					isRequired
					label={t("forms:phone")}
					name="telefono"
					inputProps={{
						placeholder: t("forms:phone"),
						ref: register,
					}}
				/>
			</SimpleGrid>
			<SimpleGrid ccolumns={{ base: 1, md: 2 }} spacing={2}>
				<FormInput
					isRequired
					label={t("suppliers:company_section")}
					name="area"
					inputProps={{
						placeholder: t("suppliers:company_section"),
						ref: register,
					}}
				/>
				<FormInput
					isRequired
					label={t("suppliers:position")}
					name="cargo"
					inputProps={{
						placeholder: t("suppliers:position"),
						ref: register,
					}}
				/>
			</SimpleGrid>
			<FormInput
				isRequired
				label="Email"
				name="email"
				inputProps={{
					placeholder: "@",
					ref: register,
					type: "email"
				}}
			/>
			<Button
				mt={6}
				variantColor="blue"
				variant="outline"
				type="submit"
				isLoading={isSubmitBlocked}
			>
				{buttonLabel || t("forms:save")}
			</Button>
		</PseudoBox>
	);
}
