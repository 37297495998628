import React, { useMemo, useState, useEffect } from 'react'
import { PageContainer } from '../../common/helpers'
import { Box, Button, Heading, Link, Spinner, Stack, Text, useDisclosure, useToast } from '@chakra-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import Table from '../../common/table'
import { Panel } from '../../common/helpers'
import { getSuppliers, deleteSupplier } from '../../../api/intranet/requests'
import { useSelector } from 'react-redux'
import ModalSimple from '../../common/modal-simple'
import CreateSupplier from './create-supplier'
import { useTranslation } from 'react-i18next'
import { ButtonDelete, ButtonEdit } from '../../forms/buttons/edit'
import { getAuthToken, getCountriesState, getUserApplication, getUserRolId } from '../../../redux/selectors'
import { useLocation } from 'react-router-dom'
import { useAsyncToastNotification, usePermissionChecker } from '../../hooks'
import SupplierSearchForm from './supplier-search-form'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import Pagination from '../../common/pagination'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from '.'
import { toastError } from '../../common/toast-default-config'

function SuppliersSearch() {
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const location = useLocation()
  const [providerId, setProviderId] = useState(parseInt(location.search.replace('?', '')) || '')

  const { t } = useTranslation(['common', 'forms', 'incentives'])
  const { canDoThis } = usePermissionChecker()
  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const modalDisclosure = useDisclosure()
  const { paisId: countryId } = useSelector(getUserApplication)
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false
  const toast = useToast()
  const authToken = useSelector(getAuthToken)
  const countries = useSelector(getCountriesState)
  const [totalProviders, setTotalProviders] = useState(0)

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })

  const [totalPages, setTotalPages] = useState(0)

  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)

  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))

  const truncateString = (str) => {
    return str.length > 40 ? str.substring(0, 37) + "..." : str
  }

  const columns = useMemo(() => {
    const col = [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: 'nombre',
        Cell: ({ cell }) => (
          <p title={cell.row.original.nombre || ''}>
          {truncateString(cell.row.values.nombre)}
        </p>
        ),
      },
      {
        Header: t('forms:country'),
        accessor: 'paisId',
        Cell: ({ cell }) => <p>{countries?.find(c => c.id === cell.row.values.paisId)?.nombre}</p>,
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <Link as={RouterLink} to={`/suppliers/edit/${cell.row.values.id}/supplier-edit-general`}>
              <ButtonEdit />
            </Link>
            <ButtonDelete onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ]
    return col
  }, [canDoThis, t, countries])

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      show: true,
      data,
    }))
  }

  const getFilteredProviders = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)
    handleSectionData(SECTION_DATA_KEYS.suppliers, {
      ...sectionData.suppliers,
      isLoading: true,
    })
    try {
      let response
      // if (providerId !== '') {
      //   filtersAux.proveedorId = providerId
      // } else {
      //   filtersAux.proveedorId = ''
      // }
      response = await getSuppliers({
        ...filtersAux,
        paisId: permisosUser ? filtersAux.paisId : countryId,
      })
      setTotalPages(response.data.totalPages)
      setTotalProviders(response.data.data.length)
      handleSectionData(SECTION_DATA_KEYS.suppliers, {
        data: response.data.data,
        isLoading: false,
        error: null,
      })
      setProviderId('')
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.suppliers, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastError)
    }
  }

  useEffect(() => {
    getFilteredProviders()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getFilteredProviders()
      }
    }
  }

  useEffect(() => {
    handleResetSectionData()
    getFilteredProviders(true)
    if (!permisosUser) filters.paisId = countryId
  }, [resetFilters])

  async function onDelete() {
    await asyncNotificationWrapper(async () => {
      const response = await deleteSupplier(deleteAlert.data.id, authToken)

      return response.status
    })
    onCloseDeleteAlert()
    getFilteredProviders()
  }

  const handleOnFinish = () => {
    modalDisclosure.onClose()
    getFilteredProviders()
  }

  return (
    <>
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.nombre} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:new')} ${t('forms:suppliers')}`}
        size='900px'
      >
        <CreateSupplier onFinish={handleOnFinish} />
      </ModalSimple>
      <PageContainer>
        <Box backgroundColor='' py='16px' color='#103059'>
          <Heading style={{ fontSize: '1.8rem' }} mb={0}>
            {t('common:navbar.providers')}
          </Heading>
        </Box>
        <Button my={5} variantColor='blue' onClick={modalDisclosure.onOpen}>
          {t('common:createSuppliers')}
        </Button>
        <Panel rounded='md' mb='1.5rem'>
          <SupplierSearchForm onFilter={onFilter} reload={totalProviders} />
        </Panel>
        {!sectionData.isLoading ? (
          <>
            <Table columns={columns} data={sectionData.suppliers.data} />
          </>
        ) : (
          ''
        )}
        {!sectionData.isLoading && totalPages ? (
          <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
        ) : (
          ''
        )}
        <Box bg='white' mb='20px'>
          {sectionData.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}
          {!sectionData.isLoading && (
            <>
              {!sectionData.error && sectionData.suppliers && sectionData.suppliers.data.length === 0 && (
                <Text textAlign='center' color='gray.500' px='5' py='10'>
                  {t('common:zeroItems')}
                </Text>
              )}
              {sectionData.error && (
                <Text textAlign='center' color='gray.500' px='5' py='10'>
                  {t('common:errorGettingItems')}
                </Text>
              )}
            </>
          )}
        </Box>
      </PageContainer>
    </>
  )
}

export default SuppliersSearch
