import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import appReducer from "./app-reducer";
import suppliersReducer from "./suppliers-reducer";
import bannersReducer from './banners-reducer'
import packageReducer from "./package-reducer";

const clientPersistConfig = {
	key: "cache",
	storage: storage,
	whitelist: ["user"],
};

const reducers = combineReducers({
	appState: persistReducer(clientPersistConfig, appReducer),
	suppliersState: suppliersReducer,
	bannersState: bannersReducer,
	packageState:packageReducer, 
});

export default reducers;
