import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getAgreementsBySupplierIdPaginated } from '../../../../api/intranet'
import TableAsync from '../../../common/table-async'
import Pagination from '../../../common/pagination'

export default function Agreements({ id }) {
  const { t } = useTranslation(['common', 'suppliers', 'forms', 'bookings'])

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const maxPerPage = 10

  const [supplierAgreements, setSupplierAgreements] = useState({
    data: [],
    isLoading: true,
    error: null,
  })

  const getSupplierAgreements = async () => {
    setSupplierAgreements(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const data = {
        proveedorId: id,
        maxPerPage,
        page: page
      }
      const response = await getAgreementsBySupplierIdPaginated(data)
      setTotalPages(response.data.totalPages)
      setSupplierAgreements({
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      setSupplierAgreements(() => ({
        data: [],
        isLoading: false,
        error: error,
      }))
      console.log(error)
    }
  }

  useEffect(() => {
    getSupplierAgreements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getSupplierAgreements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('suppliers:product'),
        accessor: 'producto',
      },
      {
        Header: t('forms:typo'),
        accessor: 'moneda',
        Cell: ({ cell }) => cell.row.original.moneda ? cell.row.original.moneda.simbolo + " " + cell.row.original.moneda.descripcion : "%",
      },
      {
        Header: t('bookings:amount'),
        accessor: 'comision',
      },
      {
        Header: t('suppliers:observation'),
        accessor: 'observaciones',
        id: 'agreements_obs',
      },
    ]
  }, [t])

  return (
    <>
      <TableAsync
        isLoading={supplierAgreements.isLoading}
        columns={columns}
        data={!supplierAgreements.isLoading && !supplierAgreements.error && supplierAgreements.data}
      />
      {!supplierAgreements.isLoading && totalPages > 0 && (
        <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />
      )}
    </>
  )
}
