import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, Heading, Link, PseudoBox, Spinner, useToast } from '@chakra-ui/core'
import { PageContainer } from '../../../common/helpers'
import { GeneralInformation } from './components/general-information'
import { useSelector } from 'react-redux'
import { getAuthToken, getToken, getUserCountryId, getUserInformation } from '../../../../redux/selectors'
import { PackageImage } from './components/package-image'
import { PackageDescription } from './components/package-description'
import { PackageServices } from './components/package-services'
import { PackageItinerary } from './components/package-itinerary'
import { PackageCategories } from './components/package-categories'
import { useTranslation } from 'react-i18next'
import { createPackage, getPackagesById, updatePackage } from '../../../../api/intranet/requests'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Link as RouterLink } from 'react-router-dom'

import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { isSuccess } from '../../../../helpers/utils'
import { getCityByCode } from '../../../../api/geo'

export const PackageForm = () => {
  const user = useSelector(getUserInformation)
  const { t } = useTranslation(['common', 'forms', 'packages', 'comunications'])
  const userCountryId = useSelector(getUserCountryId)
  const toast = useToast()
  const { handleSubmit } = useForm({})
  const toastCustomizedErrorTitle = t('common:error-ocurred')
  const toastCustomizedErrorDescriptionSed = t('packages:sendError')
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const { packageId } = useParams()
  const history = useHistory();

  //EDICIÓN
  const [packageEdit, setPackage] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getPackageByProvider(packageId) {
    if (packageId) {
      setPackageIsLoading(true)
      setPackage((state) => ({
        ...state,
        isLoading: true,
      }))
      try {
        const res = await getPackagesById(token, packageId)
        if (res.status) {
          setSelectedDestinations(res.data.paqueteDestinos.map(item => item.destinationName))
          setPackage((state) => ({
            ...state,
            isLoading: false,
            items: {
              title: res.data.title,
              originCode: res.data.originCode,
              applicationId: res.data.applicationId,
              totalNights: res.data.totalNights,
              maxChildAge: res.data.maxChildAge,
              currency_id: res.data.currency_id,
              countryId: userCountryId,
              active: res.data.activo,
              dateSince: res.data.dateSince,
              dateUntil: res.data.dateUntil,
              paqueteInformacion: res.data?.paqueteInformacion?.map((i) => ({
                id: i.id,
                availability: i.availability,
                operationDate: i.operationDate,
                onlineSale: i.onlineSale ? i.onlineSale : 1,
                operationDateSince: i.operationDateSince ? i.operationDateSince : '0001-01-01T00:00:00',
                operationDateUntil: i.operationDateUntil ? i.operationDateUntil : '0001-01-01T00:00:00',
    
                hotelesInformacion: i.hotelesInformacion.map((i) => ({
                  hotelId: i.hotel.id,
                  id: i.id,
                  totalNights: i.totalNights,
                  regime: i.regime,
                  roomType: i.roomType,
                  hotel: i.hotel,
                })),
                precioInformacion: i.precioInformacion.map((i) => ({
                  id: i.id,
                  amount: i.amount,
                  baseTypeId: i.baseType.id,
                  taxes: i.taxes,
                  otherTaxes: i.otherTaxes,
                })),
                trips: i.trips.map((i) => ({
                  tripTypeId: i.tripType.id,
                  transportTypeId: i.transportType.id,
                  originLocalidadId: i.originLocalidadId,
                  detinationLocalidadId: i.detinationLocalidadId,
                  departureDate: i.departureDate,
                  arrivalDate: i.arrivalDate,
                  stops: 0,
                  id: i.id,
                  company: i.company,
                  travelCode: i.travelCode,
                  stopDetails: i.stopDetails,
                })),
              })),
              paqueteDestinos: res.data.paqueteDestinos.map((i) => ({
                destination_code: i.destinationCode,
                destinationImages: i.destinationImages,
                destinationName: i.destinationName,
                packageId: i.packageId,
                id: i.id
              })),
              paqueteDescripciones: res.data.paqueteDescripciones.map((descripcion) => ({
                included: descripcion.included,
                notIncluded: descripcion.notIncluded,
                observations: descripcion.observations,
                cancellationPolicy: descripcion.cancellationPolicy,
                description: descripcion.description,
                id: descripcion.id
              })),
              paqueteImagenes: res.data.paqueteImagenes.map((i) => ({
                imageUrl: i.imageUrl,
                packageId: i.packageId,
                id: i.id
              })),
              categories: res.data.categories,
              paquetesIncluido: res.data.paquetesIncluido.map((i) => ({
                icon_id: i.icon_id,
                description: i.description,
                id: i.id,
                packageId: i.packageId,
              })),
              itinerarioDetalles: res.data.itinerarioDetalles.map((i) => ({
                id: i.id,
                description: i.description,
                imageUrl: i.imageUrl,
                title: i.title,
                order: i.order,
                packageId: i.packageId,
              }))
            },
          }))
        }
        setState({
          title: res.data.title,
          originCode: res.data.originCode,
          applicationId: res.data.applicationId,
          totalNights: res.data.totalNights,
          maxChildAge: res.data.maxChildAge,
          currency_id: res.data.currency_id,
          countryId: userCountryId,
          active: res.data.activo,
          dateSince: res.data.dateSince,
          dateUntil: res.data.dateUntil,
          paqueteInformacion: res.data?.paqueteInformacion?.map((i) => ({
            id: i.id,
            availability: i.availability,
            operationDate: i.operationDate,
            onlineSale: i.onlineSale ? i.onlineSale : 1,
            operationDateSince: i.operationDateSince ? i.operationDateSince : '0001-01-01T00:00:00',
            operationDateUntil: i.operationDateUntil ? i.operationDateUntil : '0001-01-01T00:00:00',

            hotelesInformacion: i.hotelesInformacion.map((i) => ({
              hotelId: i.hotel.id,
              id: i.id,
              totalNights: i.totalNights,
              regime: i.regime,
              roomType: i.roomType,
              hotel: i.hotel,
            })),
            precioInformacion: i.precioInformacion.map((i) => ({
              id: i.id,
              amount: i.amount,
              baseTypeId: i.baseType.id,
              taxes: i.taxes,
              otherTaxes: i.otherTaxes,
            })),
            trips: i.trips.map((i) => ({
              tripTypeId: i.tripType.id,
              transportTypeId: i.transportType.id,
              originLocalidadId: i.originLocalidadId,
              detinationLocalidadId: i.detinationLocalidadId,
              departureDate: i.departureDate,
              arrivalDate: i.arrivalDate,
              stops: 0,
              id: i.id,
              company: i.company,
              travelCode: i.travelCode,
              stopDetails: i.stopDetails,
            })),
          })),
          paqueteDestinos: res.data.paqueteDestinos.map((i) => ({
            destination_code: i.destinationCode,
            destinationImages: i.destinationImages,
            destinationName: i.destinationName,
            packageId: i.packageId,
            id: i.id
          })),
          paqueteDescripciones: res.data.paqueteDescripciones.map((descripcion) => ({
            included: descripcion.included,
            notIncluded: descripcion.notIncluded,
            observations: descripcion.observations,
            cancellationPolicy: descripcion.cancellationPolicy,
            description: descripcion.description,
            id: descripcion.id
          })),
          paqueteImagenes: res.data.paqueteImagenes.map((i) => ({
            imageUrl: i.imageUrl,
            packageId: i.packageId,
            id: i.id
          })),
          categories: res.data.categories,
          paquetesIncluido: res.data.paquetesIncluido.map((i) => ({
            icon_id: i.icon_id,
            description: i.description,
            id: i.id,
            packageId: i.packageId,
          })),
          itinerarioDetalles: res.data.itinerarioDetalles.map((i) => ({
            id: i.id,
            description: i.description,
            imageUrl: i.imageUrl,
            title: i.title,
            order: i.order,
            packageId: i.packageId,
          }))
        })
      } catch (error) {
        console.log('error', error)
        setPackage((state) => ({
          ...state,
          isLoading: false,
          error: true,
        }))
      }
      setPackageIsLoading(false)
    }
  }

  const [state, setState] = useState({
    title: '',
    originCode: '',
    applicationId: user.aplicacion.id,
    totalNights: 0,
    maxChildAge: 0,
    currency_id: 0,
    countryId: userCountryId,
    active: false,
    dateSince: '',
    dateUntil: '',
    paqueteInformacion: [],
    paqueteDestinos: [],
    paqueteDescripciones: [{
      included: '',
      notIncluded: '',
      observations: '',
      cancellationPolicy: '',
      description: '',
    }],
    paqueteImagenes: [
      {
        imageUrl: '',
      },
    ],
    categories: [],
    paquetesIncluido: [],
    itinerarioDetalles: [],
  })

  //Estado de la información general
  function onChangeBasicData({ name, value }) {
    setState((state) => ({
      ...state,
      [name]: value,
    }))
  }

  const [selectedDestinations, setSelectedDestinations] = useState([])

  function onChangeBasicDatanDestinations(newDestinations) {
    setSelectedDestinations(newDestinations)
  }

  function onchangeDates({ dateSince, dateUntil }) {
    setState((state) => ({
      ...state,
      dateSince,
      dateUntil,
    }))
  }

  function onchangeImages(package_images) {
    setState((state) => {
      return {
        ...state,
        paqueteImagenes: package_images,
      }
    })
  }

  function onChangeDescription(e) {
    let paqueteDescripciones = [{
      ...state.paqueteDescripciones[0],
      [e.target.name]: e.target.value,
    }]
    setState((state) => ({
      ...state,
      paqueteDescripciones,
    }))
  }

  function onChangeCategories(e) {
    const categories = e.map((c) => { return { categoryId: c.id } })

    setState((state) => ({
      ...state,
      categories
    }))
  }

  function onChangeServices(icons) {
    setState({
      ...state,
      paquetesIncluido: icons,
    })
  }

  function onChangePackageItinerary(itinerarioDetalles) {
    setState((state) => ({
      ...state,
      itinerarioDetalles,
    }))
  }

  //CREAR / EDITAR PAQUETE
  async function onSubmit() {
    if (packageId) {
      try {
        if (selectedDestinations?.length && state?.originCode) {
          const destinationsAux = await Promise.all(selectedDestinations.map(async selectedDestination => {
            const oldDestination = state?.paqueteDestinos?.find((elem) => elem.destinationName === selectedDestination)
            if (!oldDestination) {
              const response = await getCityByCode(selectedDestination, authToken)
              if (isSuccess(response.status)) {
                return {
                  destination_code: response.data[0].codigo,
                  destinationName: response.data[0].nombre,
                  destinationImages: [],
                  packageId
                }
              }
            } else {
              return oldDestination
            }
          }))
          const data = { ...state, paqueteDestinos: destinationsAux }
          data.paqueteInformacion = packageEdit.items.paqueteInformacion

          async function OK() {
            const response = await updatePackage(packageId, data, token)
            if (isSuccess(response.status)) {
              toast(toastCustomizedSuccess(t('forms:actionCompleted')))
              history.push('/packages')
            } else {
              toastCustomizedError(
                toastCustomizedErrorTitle,
                `${toastCustomizedErrorDescriptionSed} - Server note: ${response.data.menssage}`
              )
            }
          }
          OK()
        } else {
          if (!selectedDestinations?.length && state?.originCode){
            toast(toastCustomizedError(toastCustomizedErrorTitle, t('packages:noDestinationSelected')))
          } else if (selectedDestinations?.length && !state?.originCode) {
            toast(toastCustomizedError(toastCustomizedErrorTitle, t('packages:noOriginSelected')))
          } else {
            toast(toastCustomizedError(toastCustomizedErrorTitle, t('packages:noDestinationOriginSelected')))
          }
        }
      } catch (error) {
        toast(toastCustomizedError(toastCustomizedErrorTitle, `${toastCustomizedErrorDescriptionSed} `))
      }
    } else {
      try {
        if (selectedDestinations?.length && state?.originCode) {
          const destinationsAux = await Promise.all(selectedDestinations.map(async selectedDestination => {
            const response = await getCityByCode(selectedDestination, authToken)
            if (isSuccess(response.status)) {
              return {
                destination_code: response.data[0].codigo,
                destinationName: response.data[0].nombre,
                destinationImages: [],
              }
            }
          }))
          async function OK() {
            const data = { ...state, paqueteDestinos: destinationsAux }
            const response = await createPackage(data, token)
            if (isSuccess(response.status)) {
              toast(toastCustomizedSuccess(t('forms:actionCompleted')))
              history.push('/packages')
            } else {
              toast(toastCustomizedError(toastCustomizedErrorTitle, `${toastCustomizedErrorDescriptionSed} `))
            }
          }
          OK()
        } else {
          if (!selectedDestinations?.length && state?.originCode){
            toast(toastCustomizedError(toastCustomizedErrorTitle, t('packages:noDestinationSelected')))
          } else if (selectedDestinations?.length && !state?.originCode) {
            toast(toastCustomizedError(toastCustomizedErrorTitle, t('packages:noOriginSelected')))
          } else {
            toast(toastCustomizedError(toastCustomizedErrorTitle, t('packages:noDestinationOriginSelected')))
          }
        }
      } catch (error) {
        console.log('error', error)
        toast(toastCustomizedError(toastCustomizedErrorTitle, `${toastCustomizedErrorDescriptionSed} `))
      }
    }
  }

  const [packageIsLoading, setPackageIsLoading] = useState(false)

  useEffect(() => {
    if (!packageId) {
      setPackageIsLoading(false)
    } else {
      getPackageByProvider(packageId)
    }
  }, [packageId])

  return (
    <PageContainer>
      <Box backgroundColor='' py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {!packageId ? t('packages:creation') : t('packages:update')}
        </Heading>
      </Box>
      <PseudoBox as="form" className='create-packages' onSubmit={handleSubmit(onSubmit)}>
        {packageIsLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <GeneralInformation
              onChangeBasicData={onChangeBasicData}
              onChangeBasicDatanDestinations={onChangeBasicDatanDestinations}
              state={state}
              setState={setState}
              selectedDestinations={selectedDestinations}
              packageId={packageId}
              onchangeDates={onchangeDates}
            />
            <PackageImage onchangeImages={onchangeImages} state={state} />
            <PackageServices onChangeServices={onChangeServices} state={state} />
            <PackageItinerary onChange={onChangePackageItinerary} itineraryList={state.itinerarioDetalles} />
            <PackageCategories onChange={onChangeCategories} state={state} />
            <PackageDescription onChangeDescription={onChangeDescription} state={state} />
            <Box style={{ display: 'flex', gap: '1rem' }}>
              <Button variantColor="blue" variant="outline" size="md" type="submit">
                {packageId ? t('packages:saveChanges') : t('packages:createPackage')}
              </Button>
              <Link as={RouterLink} to='/packages'>
                <Button variantColor="blue" variant="outline" size="md">
                  {t('comunications:getback')}
                </Button>
              </Link>
            </Box>
          </Fragment>
        )
        }
      </PseudoBox>
    </PageContainer>
  )
}
