import initialState from "./initial-state";
import { SAVE_USER_DATA, LOG_OUT } from "../actions/actions-type";
import { createReducer } from "./reducer-creator";

const saveUserData = (state, action) => ({
	...state,
	user: {
		...action.payload
	}
});
const logOut = (state) => ({
	...state,
	user: null,
}
);

export default createReducer(initialState.appState, {
	[SAVE_USER_DATA]: saveUserData,
	[LOG_OUT]: logOut,
});