import { Box, Button, Flex, Grid, List } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'
import { resetSupplierData } from '../../../../redux/actions/suppliers-actions'
import NavigatorDrawer from '../../../common/drawer/navigator-drawer'
import { PageContainer } from '../../../common/helpers'
import { ListItemRoute } from '../../../common/router-components'
import SupplierAgreements from './supplier-agreements'
import SupplierEditGeneral from './supplier-edit-general'
import SupplierAutoLogin from './supplier-auto-login'
import SupplierContacts from './supplier-contacts'
import SupplierOffices from './supplier-offices'
import SupplierIncentives from './supplier-incentives'
import { Heading } from '@chakra-ui/core'
import '../../../styles/suppliers/suppliers.css'
import { updateSupplier } from '../../../../api/section'
import { getAuthToken } from '../../../../redux/selectors'
import { NavLinkAuthorized } from '../../../layout/menu/menu-elements'
import { INCENTIVES, CREATE_AUTOLOGIN } from '../../../../helpers/permissions'
import SupplierAdditionalInfo from './supplier-additional-info'

export default function SuppliersEdit() {
  const { t } = useTranslation(['suppliers', 'common', 'applications'])
  const { id: supplierId } = useParams()
  const authToken = useSelector(getAuthToken)
  const [nameProvider, setNameProvider] = useState('...')

  const { path, url } = useRouteMatch()
  const dispatch = useDispatch()

  async function getProvider(supplierId) {
    try {
      const response = await updateSupplier(supplierId, authToken)
      setNameProvider(response.data.nombre)
    } catch (error) {
      console.log(error)
    }
  }

  const childrenRoutes = [
    {
      path: `${path}/supplier-edit-general`,
      component: SupplierEditGeneral,
    },
    {
      exact: true,
      path: `${path}/supplier-agreements`,
      component: SupplierAgreements,
    },
    {
      path: `${path}/supplier-contacts`,
      component: SupplierContacts,
    },
    {
      path: `${path}/supplier-offices`,
      component: SupplierOffices,
    },
    {
      path: `${path}/supplier-incentives`,
      component: SupplierIncentives,
    },
    {
      path: `${path}/supplier-auto-login`,
      component: SupplierAutoLogin,
    },
		{
      path: `${path}/supplier-additional-info`,
      component: SupplierAdditionalInfo,
    },
  ]

  const header = (onOpen, btnRef) => (
    <Grid templateColumns={{ base: '6fr 1fr', md: '7fr 0fr' }} gap={[3, 3, 0]}>
      <Box backgroundColor='' py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {nameProvider}
        </Heading>
      </Box>
      <Flex justify='flex-end'>
        <Button
          display={{ base: 'inherit', md: 'none' }}
          mb='10px'
          ref={btnRef}
          variant='outline'
          variantColor='blue'
          onClick={onOpen}
        >
          <Box as={GiHamburgerMenu} display='inline' />
        </Button>
      </Flex>
    </Grid>
  )

  useEffect(() => {
    dispatch(resetSupplierData())
    getProvider(supplierId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  return (
    <PageContainer>
      <NavigatorDrawer header={header} routes={childrenRoutes} spacing='16px'>
        <List spacing={3}>
          <ListItemRoute to={`${url}/supplier-edit-general`} url={url}>
            {t('suppliers:editSuppliers')}
          </ListItemRoute>
          <ListItemRoute to={`${url}/supplier-agreements`}>{t('suppliers:agreements')}</ListItemRoute>
          <ListItemRoute to={`${url}/supplier-contacts`}>{t('suppliers:contacts')}</ListItemRoute>
          <ListItemRoute to={`${url}/supplier-offices`}>{t('suppliers:offices')}</ListItemRoute>
          <NavLinkAuthorized permission={INCENTIVES} to={`${url}/supplier-incentives`}>
            <ListItemRoute style={{ fontSize: '1.1rem' }} to={`${url}/supplier-incentives`}>
              {t('suppliers:incentives')}
            </ListItemRoute>
          </NavLinkAuthorized>

          <NavLinkAuthorized
            style={{ fontSize: '1.1rem', marginTop: '0.6rem' }}
            permission={CREATE_AUTOLOGIN}
            to={`${url}/supplier-auto-login`}
          >
            <ListItemRoute to={`${url}/supplier-auto-login`}>{t('suppliers:auto-login')}</ListItemRoute>
          </NavLinkAuthorized>
          <ListItemRoute style={{ fontSize: '1.1rem', marginTop: '0.6rem' }} to={`${url}/supplier-additional-info`}>{t('applications:additionalInformation')}</ListItemRoute>
        </List>
      </NavigatorDrawer>
    </PageContainer>
  )
}
