export function objetoA(props){
    let info = []
    //DATOS RESERVA
    info.id = props.dataBookingsSelect.dataBookings.id || ""
    info.email = props.dataBookingsSelect.dataBookings.emailComprador || ""
    info.fechaCarga = props.dataBookingsSelect.dataBookings.fechaCarga || ""
    info.fechaSalida = props.dataBookingsSelect.dataBookings.fechaSalida || ""
    info.monto = props.dataBookingsSelect.dataBookings.monto || ""
    info.referencia = props.dataBookingsSelect.dataBookings.referenciaPaquete || ""
    info.dominioOrigen = props.dataBookingsSelect.dataBookings.dominioOrigen || ""
    info.estadoReserva = props.dataBookingsSelect.dataBookings.estadoReserva >= 4 ? "" : props.dataBookingsSelect.dataBookings.estadoReserva
    //DETALLES RESERVA
    info.paqueteId = props.dataBookingsSelect.dataBookingsDetails.paquete.id || ""
    info.titulo = props.dataBookingsSelect.dataBookingsDetails.titulo || ""
    info.cantidadNoches = props.dataBookingsSelect.dataBookingsDetails.cantidadNoches || ""
    info.disponibilidad = props.dataBookingsSelect.dataBookingsDetails.paquete.disponibilidad || ""
    info.cantidadNoches = props.dataBookingsSelect.dataBookingsDetails.paquete.cantidadNoches || ""
    info.edadMaximaMenores = props.dataBookingsSelect.dataBookingsDetails.paquete.edadMaximaMenores || ""
    info.vigenciaDesde = props.dataBookingsSelect.dataBookingsDetails.paquete.vigencia.desde || ""
    info.vigenciaHasta = props.dataBookingsSelect.dataBookingsDetails.paquete.disponibilidad || "" 
    info.disponibilidad = props.dataBookingsSelect.dataBookingsDetails.paquete.disponibilidad || ""
    info.descripcion = props.dataBookingsSelect.dataBookingsDetails.paquete.metaDescripcion || ""
    //SALIDA
    info.idSalida = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].id || ""
    //SALIDA PRECIOS
    info.precioNeto = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.precioNeto || ""
    info.precioNetoDolares = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.precioNetoDolares || ""
    info.impuestos = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.impuestos || ""
    info.impuestosDolares = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.impuestosDolares || ""
    info.iva = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.iva || ""
    info.ivaDolares = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.ivaDolares || ""
    info.moneda = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.moneda || ""
    info.tipoBase = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].precios.tipoBase || ""

    //SALIDA FECHA
    info.fechaSalida = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].fechaSalida || ""
    info.fechaLlegada = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].fechaLlegada || ""
    //SALIDA REFERENCIA INTERNA
    info.referenciaInterna = props.dataBookingsSelect.dataBookingsDetails.paquete.salidas[0].referencia.interna || ""
    return info
}


export function objetoB(props){
    let info = []
    //DATOS RESERVA
    info.id = props.dataBookingsSelect.dataBookings.id || ""
    info.email = props.dataBookingsSelect.dataBookings.emailComprador || ""
    info.fechaCarga = props.dataBookingsSelect.dataBookings.fechaCarga || ""
    info.fechaSalida = props.dataBookingsSelect.dataBookings.fechaSalida || ""
    info.monto = props.dataBookingsSelect.dataBookings.monto || ""
    info.referencia = props.dataBookingsSelect.dataBookings.referenciaPaquete || ""
    info.dominioOrigen = props.dataBookingsSelect.dataBookings.dominioOrigen || ""
    info.estadoReserva = props.dataBookingsSelect.dataBookings.estadoReserva >= 4 ? "" : props.dataBookingsSelect.dataBookings.estadoReserva

    //DETALLES RESERVA
    info.paqueteId = props.dataBookingsSelect.dataBookingsDetails.detallePaquete.datosAsesor.Id || ""
    info.titulo = props.dataBookingsSelect.dataBookingsDetails.detallePaquete.titulo || ""
    info.cantidadNoches =  ""
    info.disponibilidad =  ""
    info.cantidadNoches =  ""
    info.edadMaximaMenores = ""
    info.vigenciaDesde =  ""
    info.vigenciaHasta =   "" 
    info.disponibilidad =  ""
    info.descripcion = props.dataBookingsSelect.dataBookingsDetails.incluye || ""
    //SALIDA
    info.idSalida =  ""
    //SALIDA PRECIOS
    info.precioNeto =  ""
    info.precioNetoDolares =  ""
    info.impuestos =  ""
    info.impuestosDolares =  ""
    info.iva = ""
    info.ivaDolares = ""
    info.moneda = ""
    info.ivaDolares = ""
    info.tipoBase = ""
    //SALIDA FECHA
    info.fechaSalida =  ""
    info.fechaLlegada =  ""
    //SALIDA REFERENCIA INTERNA
    info.referenciaInterna =  ""
    return info

}


export function objetoX(props){
    let info = []
    //DATOS RESERVA
    info.id =  ""
    info.email =  ""
    info.fechaCarga =  ""
    info.fechaSalida =  ""
    info.monto =  ""
    info.referencia =  ""
    info.dominioOrigen =  ""
    info.estadoReserva =  "" 

    //DETALLES RESERVA
    info.paqueteId =  ""
    info.titulo =  ""
    info.cantidadNoches =  ""
    info.disponibilidad =  ""
    info.cantidadNoches =  ""
    info.edadMaximaMenores = ""
    info.vigenciaDesde =  ""
    info.vigenciaHasta =   "" 
    info.disponibilidad =  ""
    info.descripcion =  ""
    //SALIDA
    info.idSalida =  ""
    //SALIDA PRECIOS
    info.precioNeto =  ""
    info.precioNetoDolares =  ""
    info.impuestos =  ""
    info.impuestosDolares =  ""
    info.iva = ""
    info.ivaDolares = ""
    info.moneda = ""
    info.ivaDolares = ""
    info.tipoBase = ""
    //SALIDA FECHA
    info.fechaSalida =  ""
    info.fechaLlegada =  ""
    //SALIDA REFERENCIA INTERNA
    info.referenciaInterna =  ""
    return info

}