import React, { useState, useEffect } from 'react'
import { obtenerComunicadosPorId, obtenerPermisosPorRol } from '../../../api/communications'
import { useSelector } from 'react-redux'
import { PageContainer } from '../../common/helpers'
import { Button, Box, Heading, Spinner } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getAuthToken, getUserRolId } from '../../../redux/selectors'
import { useLocation } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

function NewsVisor() {
  let history = useHistory()
  let location = useLocation()

  const regexMatch = location.search.match(/[^&?]*/g)
  const id = regexMatch[1]
  const type = regexMatch[3]

  const userRolId = useSelector(getUserRolId)

  const { t } = useTranslation(['comunications'])
  const authToken = useSelector(getAuthToken)
  const [events, setEvents] = useState([])
  // Obtener Permisos de Usuario

  async function obtenerPermisos() {
    try {
      const resp = await obtenerPermisosPorRol(authToken)
      getNew(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  const backToNews = () => {
    type === 'h' ? history.push(`/`) : history.push(`/communications?${type}`)
  }

  async function getNew(permisos) {
    try {
      const resp = await obtenerComunicadosPorId(id, authToken)
      let filterDate = resp.data.fechaBaja !== 'null' ? resp.data : null
      let perm = permisos
      perm.filter((x) => x.rolId == userRolId)

      function filters(id) {
        let i = perm.map((x) => x.comunicadosId == id)

        let k = i.filter((x) => x == true)

        if (k == '') {
          return false
        } else {
          return true
        }
      }
      let filterPermisos = filters(filterDate.id) == true ? filterDate : null

      if (filterPermisos == null) {
        setEvents({ asunto: false })
      } else {
        setEvents(filterDate)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    obtenerPermisos()
  }, [])

  return (
    <>
      <Box backgroundColor="" py="10px" pb="0.5%" mt="" color="#103059">
        <PageContainer>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Heading style={{ fontSize: '2rem' }}>{events.tituloHtml ? events.tituloHtml : events.titulo}</Heading>
            <Button style={{ width: 'auto', marginRight: '2%' }} onClick={() => backToNews()} variantColor="blue" variant="outline">
              {t('comunications:getback')}
            </Button>
          </div>
        </PageContainer>
      </Box>

      <Box style={{ margin: '2rem'}} pb="0.5%" mt="" color="#103059">
        <PageContainer>
          <Heading style={{ fontSize: '1.5rem', marginBottom: '1.5rem' }}>{!events.tituloHtml ? events.descripcion : ''}</Heading>
          {events.asunto === 'newsletter' ? (
            <div style={{ position: 'relative' }}>
              <div style={{ backgroundColor: 'white', padding: '3rem', borderRadius: '10px' }}>
                {ReactHtmlParser(events.comunicadoHtml)}
              </div>
            </div>
          ) : events.asunto === 'html' ? (
            <div style={{ backgroundColor: 'white', padding: '3rem', borderRadius: '10px'  }}>{ReactHtmlParser(events.comunicadoHtml)}</div>
          ) : events.asunto == false ? (
            <div style={{ fontSize: '2.5rem', paddingTop: '2rem', margin: '0% 25%', color: '#c4c4c4' }}>
              <b>{t('comunications:dontPermission')}</b>
            </div>
          ) : (
            <Box px="5" py="10" textAlign="center">
              <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
            </Box>
          )}
        </PageContainer>
      </Box>
    </>
  )
}

export default NewsVisor
