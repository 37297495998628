import React, { useState, useMemo, useEffect } from 'react'
import { useDisclosure, Stack, Text, useToast, Button, Box } from '@chakra-ui/core'
import { Panel } from '../../common/helpers'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCurrencies, getExchange, createExchange, updateExchange, deleteExchange } from './../../../api/application'
import {
  getToken,
  getAuthToken,
  getUserApplicationId,
  getUserCountryId,
  getCountriesState,
} from '../../../redux/selectors'
import { ButtonDelete, ButtonEdit } from '../../forms/buttons/edit'
import Table from '../../common/table'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import ModalSimple from '../../common/modal-simple'
import { ExchangeForm } from './../../forms/exchange/exchangeForm'
import { EditExchangeForm } from './../../forms/exchange/editExchangeForm'
import { isSuccess } from '../../../helpers/utils'
import AlertDialogSimple from '../../common/alert-dialog-simple'

export default function Exchange() {
  const { t } = useTranslation(['common', 'forms', 'exchange'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const countryId = useSelector(getUserCountryId)
  const appId = useSelector(getUserApplicationId)
  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()
  const modalExchangeDisclosure = useDisclosure()
  const toastCustomizedErrorTitle = t('common:error-ocurred')
  const toastCustomizedErrorDescription = t('exchange:error-description')
  const countries = useSelector(getCountriesState)
  const [currency, setCurrency] = useState([])
  const [{ isloading, exchange }, setExchange] = useState({
    isLoading: false,
    exchange: [],
  })

  const [visualCreateExchange, setVisualCreateExchange] = useState({
    visualCreateExchange: true,
  })
  const [visualEditExchange, setEditExchange] = useState({
    visualEditExchange: true,
  })

  function btnOpenExchange() {
    setVisualCreateExchange(false)
    modalExchangeDisclosure.onOpen()
  }

  function onEditClick(dat, data) {
    SetIdExchange(dat)
    setEditExchange(false)
    modalDisclosure.onOpen()
  }

  async function getAllExchange() {
    setExchange(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const responses = await getCurrencies(authToken)
      setCurrency(responses.data)
      const rta = await getExchange(token)
      const response = rta.data.filter(exchange => exchange.aplicacion.id == appId)
      setExchange(state => ({
        exchange: response,
        isLoading: false,
      }))
    } catch (error) {
      toast(error)
    }
  }

  useEffect(() => {
    getAllExchange()
  }, [])

  async function onSubmit(data) {
    try {
      const response = await createExchange(data, authToken)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(
              toastCustomizedErrorTitle,
              `${toastCustomizedErrorDescription} - Server note: ${response.data.menssage}`,
            ),
      )
      getAllExchange()
      modalExchangeDisclosure.onClose()
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  //EDIT FUNCTIONS
  const [idExchange, SetIdExchange] = useState(0)
  const [valueExchange, SetValueExchange] = useState({})

  function onSubmitUpdateValue(value) {
    SetValueExchange(value)
  }

  function btnEditExchange() {
    onSubmitUpdate(idExchange)
  }

  async function onSubmitUpdate() {
    let data = {
      id: idExchange.id,
      aplicacion: { id: idExchange.aplicacion.id },
      producto: { id: 1 },
      paisId: countryId,
      origen: { id: idExchange.monedaOrigen },
      destino: { id: idExchange.monedaDestino },
      cambio: parseFloat(valueExchange),
      activo: false,
    }
    updateExchangeFunc(data)
  }

  async function updateExchangeFunc(data) {
    try {
      const response = await updateExchange(data, authToken)
      if (response.status) {
        getAllExchange(authToken)
        setEditExchange('')
        modalDisclosure.onClose()
      }
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(
              toastCustomizedErrorTitle,
              `${toastCustomizedErrorDescription} - Server note:${response.data.menssage}`,
            ),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  function changeFunction(data) {
    let finalData = currency.map(cur => (cur.id === data ? cur.codigo : ''))
    return finalData
  }

  async function deleteChange() {
    try {
      const response = await deleteExchange(idExchange, authToken)
      if (response.status) {
        getAllExchange()
        modalDisclosure2.onClose()
      }
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:aplicationId'),
        accessor: 'aplicacion',
        Cell: ({ cell }) => <Text>{cell.row.values.aplicacion.id}</Text>,
      },

      {
        Header: t('exchange:originCurrency'),
        accessor: 'origen',
        Cell: ({ cell }) => <Text>{changeFunction(cell.row.values.origen.id)}</Text>,
      },
      {
        Header: t('exchange:destinationCureency'),
        accessor: 'destino',
        Cell: ({ cell }) => <Text>{changeFunction(cell.row.values.destino.id)}</Text>,
      },
      {
        Header: t('exchange:CurrencyValue'),
        accessor: 'cambio',
      },
      {
        Header: t('exchange:currencyCountry'),
        accessor: 'paisId',
        Cell: ({ cell }) => <p>{countries?.find(c => c.id === cell.row.values.paisId)?.nombre}</p>,
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit mb={4} onClick={() => onEditClick(cell.row.values)}></ButtonEdit>
            <ButtonDelete
              mb={4}
              onClick={e => {
                SetIdExchange(cell.row.values.id)
                return modalDisclosure2.onOpen()
              }}
            ></ButtonDelete>
          </Stack>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [currency, countries],
  )

  return (
    <>
      <Box margin='0 0 2rem 0' minHeight='20rem'>
        <Button mb={5} variantColor='blue' onClick={() => btnOpenExchange()}>
          {t('exchange:createChange')}
        </Button>

        {!visualCreateExchange ? (
          <ModalSimple
            isOpen={modalExchangeDisclosure.isOpen}
            onClose={modalExchangeDisclosure.onClose}
            title={`${t('common:edit')} ${t('common:navbar.currency')}`}
            size='xl'
          >
            <Panel rounded='md'>
              <ExchangeForm onSubmit={onSubmit} />
            </Panel>
          </ModalSimple>
        ) : null}

        {!visualEditExchange ? (
          <ModalSimple
            isOpen={modalDisclosure.isOpen}
            onClose={modalDisclosure.onClose}
            title={`${t('common:edit')} ${t('common:navbar.currency')}`}
            size='xl'
          >
            <EditExchangeForm
              currency={currency}
              monedaOrigen={idExchange.origen.id}
              monedaDestino={idExchange.destino.id}
              nombreBoton={`${t('common:edit')}`}
              e='e'
              onSubmitUpdateValue={onSubmitUpdateValue}
              btnEnviar={btnEditExchange}
            ></EditExchangeForm>
          </ModalSimple>
        ) : null}

        <AlertDialogSimple
          isOpen={modalDisclosure2.isOpen}
          onClose={modalDisclosure2.onClose}
          onAccept={deleteChange}
          title={`${t('common:delete')} ${t('common:change')}`}
          size='lg'
        >
          {' '}
          <p>{`${t('common:changeQuestion')}`}</p>
        </AlertDialogSimple>
        <Table columns={columns} data={exchange} />
      </Box>
    </>
  )
}
