import { Button, Grid, PseudoBox, Box, FormLabel } from "@chakra-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FormInput from "../../../forms/form-input";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { VALUE_TYPE_KEYS } from "../../../../helpers/commons";
import SelectAsync from "../../../common/select-async";


export default function CategoryForm({ onSubmit, ...restProps }) {
	const { register, handleSubmit, formState } = useForm();
	const { t } = useTranslation(["forms"]);

	////Prop function OnSubmit

	function onSubmitt(data) {
		document.getElementById("category-form").reset();
		onSubmit(data);
	}

	return (
		<PseudoBox
			id="category-form"
			as="form"
			onSubmit={handleSubmit(d => onSubmitt(d))}
			{...restProps}
			style={{backgroundColor: "white", borderRadius: 10}}
		>
			<Grid
				templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
				gap={4}
				style={{marginBottom: "1.5rem"}}
			>
				{/* Nombre */}
				<Box>
					<FormLabel htmlFor="nombre" isRequired>{t("forms:name")}</FormLabel>
					<FormInput
						isRequired
						maxLength="255"
						w="100%"
						name="nombre"
						inputProps={{
							ref: register
						}}
					/>
				</Box>
				{/* Descripcion */}
				<Box>
					<FormLabel htmlFor="descripcion" isRequired>{t("forms:description")}</FormLabel>
					<FormInput
						isRequired
						maxLength="255"
						w="100%"
						name="descripcion"
						inputProps={{
							ref: register
						}}
					/>
				</Box>

				{/* Tipo de valor */}
				<Box>
					<FormLabel htmlFor="tipoValor" isRequired>{t("forms:value_type")}</FormLabel>
					<SelectAsync
						name="tipoValor"
						placeholder={t("forms:selectOption")}
						nativeProps={{ ref: register }}
						isRequired
					>
						{
							Object.values(VALUE_TYPE_KEYS).map(item => (
								<option
									key={item}
									value={item}
								>
									{t("forms:" + item.toLowerCase())}
								</option>
							))
						}
					</SelectAsync>
				</Box>
			</Grid>
			{/* Guardar Button */}
			<Button
				variantColor="blue"
				variant="outline"
				isLoading={formState.isSubmitting}
				type="submit"
			>
				{t("common:save")}
			</Button>
		</PseudoBox>
	);
}
