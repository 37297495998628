import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Grid, Select, useToast, Stack, FormLabel } from '@chakra-ui/core'
import SelectAsync from '../../common/select-async'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import './styles.css'
import { getAllSupplier } from '../../../api/groups-api'
import { toastCustomizedError } from '../../common/toast-default-config'
import FormInput from '../../forms/form-input'
import { getUserCountryId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { currencyOptions, destinationOptions } from './group-enum'
import FormInputNumber from '../../forms/form-input-number'

export default function GroupForm({ onSubmit, formData = false, onFinish }) {
  const { t } = useTranslation(['common', 'forms', 'applications'])
  const toast = useToast()
  const userCountryId = useSelector(getUserCountryId)
  const [focusedInput, setFocusedInput] = useState(false)
  const [startDate, setStartDate] = useState()
  const [country, setCountry] = useState()
  const [supplier, setSupplier] = useState()
  const [currency, setCurrency] = useState()
  const [supplierItems, setSupplierItems] = useState({
    data: [],
    isLoading: true,
  })
  const { handleSubmit, register, control, reset } = useForm({
    defaultValues: formData,
  })

  async function onSubmitt(data) {
    await onSubmit({
      ...data,
      price: data.price.toString(),
      departureDate: startDate._d,
      supplier: { id: supplier },
      destination: country,
      currency: currency,
      countryId: userCountryId,
    })
    onFinish()
    resetForm()
  }

  const getSupplier = async () => {
    setSupplierItems(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllSupplier()
      setSupplierItems(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setSupplierItems(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const handleChange = (newValue, name) => {
    if (name === 'supplier') {
      setSupplier(newValue)
    } else if (name === 'destination') {
      setCountry(newValue)
    } else if (name === 'currency') {
      setCurrency(newValue)
    }
  }

  const resetForm = () => {
    reset()
    setSupplier(undefined)
    setStartDate(undefined)
    setCurrency(undefined)
  }

  useEffect(() => {
    if (formData) {
      setCountry(formData.destination)
      setStartDate(moment(formData.departureDate))
      setSupplier(formData.supplier?.id)
      setCurrency(formData.currency)
    }
  }, [formData])

  useEffect(() => {
    getSupplier()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form style={{ display: 'grid' }} autoComplete='off' onSubmit={handleSubmit(onSubmitt)}>
      <Stack autoComplete='off'>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
          <Box>
            <FormLabel htmlFor='date' isRequired>
              Fecha de salida
            </FormLabel>
            <Box>
              <SingleDatePicker
                id='departure_date'
                date={startDate}
                onDateChange={date => setStartDate(date)}
                focused={focusedInput}
                onFocusChange={({ focused }) => setFocusedInput(focused)}
                isOutsideRange={() => false}
                numberOfMonths={1}
                showClearDate
                block
                placeholder='DD-MM-YYYY'
                showDefaultInputIcon
                inputIconPosition='after'
                hideKeyboardShortcutsPanel
              />
            </Box>
          </Box>
          <Box>
            <FormLabel htmlFor='suppliers' isRequired>
              {t('forms:suppliers')}
            </FormLabel>
            <Controller
              control={control}
              name='supplier'
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => handleChange(e.target.value, name)}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={supplier}
                  isRequired
                >
                  {supplierItems.data
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                </SelectAsync>
              )}
            />
          </Box>
          <FormInput
            name='name'
            label={t('forms:name')}
            inputProps={{
              placeholder: t('forms:name'),
              ref: register,
              maxLength: 100,
            }}
          />
          <Box>
            <FormLabel htmlFor='destination' isRequired>
              {t('forms:destination')}
            </FormLabel>
            <Controller
              control={control}
              name='destination'
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => handleChange(e.target.value, name)}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={country}
                  isRequired
                >
                  {destinationOptions.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectAsync>
              )}
            />
          </Box>
          <FormInput
            name='days'
            label='Dias'
            inputProps={{
              placeholder: 'Dias',
              ref: register,
              maxLength: 100,
            }}
          />
          <Box>
            <FormLabel htmlFor='currency' isRequired>
              {t('forms:currency')}
            </FormLabel>
            <Controller
              control={control}
              name='currency'
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => handleChange(e.target.value, name)}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={currency}
                  isRequired
                >
                  {currencyOptions.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectAsync>
              )}
            />
          </Box>
          <FormInputNumber
            name='price'
            label='Precio'
            inputProps={{
              placeholder: 'Precio',
              ref: register,
              maxLength: 100,
              type: 'number',
            }}
          />
          <FormInput
            name='linkName'
            label={t('comunications:linkName')}
            inputProps={{
              placeholder: t('comunications:linkName'),
              ref: register,
              maxLength: 100,
            }}
          />
          <FormInput
            name='link'
            label='Link'
            inputProps={{
              placeholder: 'Link',
              ref: register,
              maxLength: 100,
            }}
          />
        </Grid>
      </Stack>
      <Button
        style={{ width: 'fit-content', justifySelf: 'end' }}
        mt={4}
        variantColor='blue'
        variant='outline'
        type='submit'f
      >
        {formData ? t('forms:save') : t('common:create')}
      </Button>
    </form>
  )
}
