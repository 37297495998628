import React from 'react'
import { useTable, useSortBy } from 'react-table'
import { Box } from '@chakra-ui/core'
import { Table as TableMain, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table-elements/'
import LazyLoad from 'react-lazyload'

export default function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['seccionIdPadre'] },
    },
    useSortBy,
  )

  let toggleColor = true

  return (
    <>
      <TableMain bg='white' {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup, index) => (
            <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <TableHeader
                  className={headerGroup.headers.length === index + 1 ? 'actions-header' : ''}
                  key={index}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render('Header')}
                  <Box as='span'>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</Box>
                </TableHeader>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            const bg = toggleColor ? 'white' : 'gray.50'
            toggleColor = !toggleColor
            return (
              <TableRow bg={bg} key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <TableCell
                      className={row.cells.length === i + 1 ? 'actions-cell' : ''}
                      key={i}
                      color='gray.500'
                      {...cell.getCellProps()}
                    >
                      <LazyLoad height={19} offset={150} once={true}>
                        {cell.render('Cell')}
                      </LazyLoad>
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </TableMain>
    </>
  )
}
