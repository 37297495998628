import { Box } from "@chakra-ui/core";
import React from "react";

/**
 * The style of this component is inspired by Tailwind UI.
 * @see https://tailwindui.com/components/application-ui/tables/wide
 */

/**
 * Represents tabular data - that is, information presented in a
 * two-dimensional table comprised of rows and columns of cells containing
 * data. It renders a `<table>` HTML element.
 */
export function Table(props) {
	return (
		<Box
			shadow="md"
			rounded="lg"
			overflow="auto"
			borderRightWidth="1px"
			borderTopWidth="1px"
			borderTopColor="gray.200"
		>
			<Box as="table" width="full" {...props} />
		</Box>
	);
}

/**
 * Defines a set of rows defining the head of the columns of the table. It
 * renders a `<thead>` HTML element.
 */
export function TableHead(props) {
	return <Box as="thead" {...props} />;
}

/**
 * Defines a row of cells in a table. The row's cells can then be established
 * using a mix of `TableCell` and `TableHeader` elements. It renders a `<tr>`
 * HTML element.
 */
export function TableRow(props) {
	return <Box as="tr" {...props} />;
}

/**
 * Defines a cell as header of a group of table cells. It renders a `<th>` HTML
 * element.
 */
export function TableHeader(props) {
	return (
		<Box
			as="th"
			px="3"
			py="3"
			borderBottomWidth="1px"
			backgroundColor="gray.50"
			textAlign="left"
			fontSize="xs"
			color="gray.500"
			textTransform="uppercase"
			//letterSpacing="wider"
			lineHeight="1rem"
			//fontWeight="medium"
			{...props}
		/>
	);
}

/**
 * Encapsulates a set of table rows, indicating that they comprise the body of
 * the table. It renders a `<tbody>` HTML element.
 */
export function TableBody(props) {
	return <Box as="tbody" {...props} />;
}

/**
 * Defines a cell of a table that contains data. It renders a `<td>` HTML
 * element.
 */
export function TableCell(props) {
	return (
		<Box
			as="td"
			py="3"
			px="3"
			//lineHeight="1 rem"
			textAlign="left"
			fontSize="sm"
			//whiteSpace="nowrap"
			{...props}
			// {...console.log('props',props)}
			// { ...typeof props === "boolean" ? <Text>True</Text> : props}
		/>
	);
}
