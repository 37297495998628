import React from 'react'
import UserForm from '../../../forms/application/user'
import { useToast } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { updateUser } from '../../../../api/application'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { getToken } from '../../../../redux/selectors'

export default function EditUser({ onFinish, dataUser, isMyAccount }) {
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const toast = useToast()
  const dataUserAdapted = {
    ...dataUser,
    rol: isMyAccount ? { id: parseInt(dataUser.rol.id), name: dataUser.rol.name } : { id: parseInt(dataUser.rol.id) }
  }
  const handleSubmit = async data => {
    try {
      let updatedData

      if (data.username !== dataUser.username && !isMyAccount) {
        data.rol = isMyAccount ? { id: parseInt(data.rol.id), name: data.rol.name } : { id: parseInt(data.rol.id) }
        updatedData = { ...data, id: dataUser.id, activo: dataUser.activo }
      } else {
        updatedData = {
          activo: dataUser.activo,
          apellido: data.apellido,
          email: data.email,
          id: dataUser.id,
          nombre: data.nombre,
          password: data.password,
          rol: !isMyAccount ? { id: parseInt(data.rol.id) } : { id: parseInt(dataUser.rol.id) },
        }
      }

      const response = await updateUser(updatedData.id, updatedData, token)
      toast(
        response
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
      onFinish()
    } catch (error) {
      if (error.response) {
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  return (
    <UserForm
      onSubmit={handleSubmit}
      formData={dataUserAdapted}
      buttonLabel={t('common:save')}
      isMyAccount={isMyAccount}
    />
  )
}
