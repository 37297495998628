import React, { useState, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createComercial } from '../../../api/application'
import { Stack, Box, FormLabel, SimpleGrid, Button, useToast, Select, NumberInput, Input } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import FormInput from '../../forms/form-input'
import { isSuccess } from '../../../helpers/utils'
import SelectAsync from '../../common/select-async'
import { getCountriesState, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { createTaxes } from '../../../api/intranet/requests'
import './impuesto.css'
import { defaultSectionDataValues } from '../applications'

export default function CreateImpuestoForm({ onFinish }) {
  const { t } = useTranslation(['forms', 'applications', 'login', 'common', 'forms'])
  const [submitBlock, setSubmitBlock] = useState(false)
  const [countrySelected, setCountrySelected] = useState(true)
  const {
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({})
  const toast = useToast()
  const countries = useSelector(getCountriesState)
  const [selectedType, setSelectedType] = useState('')
  const [taxAmount, setTaxAmount] = useState(0)
  const rolId = useSelector(getUserRolId)
  const userCountryId = useSelector(getUserCountryId)
  const permisosUserCountry = rolId == 2 ? true : false
  const [paisId, setPaisId] = useState(permisosUserCountry ? userCountryId : '')
  
  const enumOptions = [
    { label: t('packages:PORCENTAGE'), value: 'PORCENTAGE' },
    { label: t('forms:amount'), value: 'FIXED_AMOUNT' },
  ]

  const submitImpuesto = async data => {
    const newData = {
      ...data,
      taxType: selectedType,
    }
    try {
      const response = await createTaxes(newData)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
      onFinish()
    } catch (error) {
      if (error.response) {
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  const submit = async data => {
    setSubmitBlock(true)
    const newData = {
      taxAmount: Math.floor(+taxAmount * 100) / 100,
      countryId: permisosUserCountry ? userCountryId : +data.countryId,
      taxName: data.taxName,
      taxType: data.taxType,
    }
    await submitImpuesto(newData)
    setSubmitBlock(false)
  }

  const handleChange = (newValue, onChange) => {
    onChange(newValue)
    setPaisId(newValue)
    setCountrySelected(false)
  }

  const handleChangeType = e => {
    setSelectedType(e.target.value)
    setTaxAmount(0)
  }

  function onChangeTaxAmount(value) {
    if (value.toString().length <= 11) {
      const numericValue = parseFloat(value)
      if (isNaN(numericValue) || numericValue <= 0) {
        // Si es menor que cero o no es un número, establece el mensaje de error.
        setError('taxAmount', {
          type: 'manual',
          message: 'No es un número valido o mayor a cero',
        });
      } else {
        // Si es un número válido, elimina el mensaje de error.
        clearErrors('taxAmount')
      }  
      setTaxAmount(value)
    }
  }

  return (
    <form style={{ display: 'grid' }} autoComplete='off' onSubmit={handleSubmit(submit)}>
      <Stack autoComplete='off'>
        <Box>
          <FormLabel htmlFor='pais_id' isRequired>
            {t('forms:country')}
          </FormLabel>
          <Controller
            control={control}
            name='countryId'
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => handleChange(e.target.value, onChange)}
                placeholder={t('forms:selectOption')}
                name={name}
                value={paisId}
                isRequired
                isDisabled={permisosUserCountry}
              >
                {countries
                  ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
                  .map(({ id, nombre }) => (
                    <option key={id} value={id}>
                      {nombre}
                    </option>
                  ))}
              </SelectAsync>
            )}
          />
        </Box>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
          <Box>
            <FormInput
              isRequired
              name='taxName'
              label={t('forms:name')}
              inputProps={{
                placeholder: t('forms:name'),
                ref: register,
                maxLength: 40,
              }}
            />
          </Box>
          <Box>
            <FormLabel htmlFor='type' isRequired>
              {t('comunications:type')}
            </FormLabel>
            <Select placeholder={`${t('comunications:type')}`} onChange={e => handleChangeType(e)} isRequired>
              {enumOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Box>
          <Box>
            <Box alignItems='center' mb='4'>
              <FormLabel htmlFor='taxAmount' isRequired>
                {t('packages:taxAmount')}
              </FormLabel>
              <NumberInput
                className='noArrows'
                max={99999999999}
                px={1}
                precision={2}
                name='amount'
                value={taxAmount}
                onChange={e => onChangeTaxAmount(e)}
              />
              {errors.taxAmount && <span style={{ color: 'red', fontSize: '0.8rem' }}>{errors.taxAmount.message}</span>}
            </Box>
          </Box>
          {/* <Box>{inputNumber()}</Box> */}
        </SimpleGrid>
      </Stack>
      <Button
        style={{ width: 'fit-content', justifySelf: 'end' }}
        isDisabled={!taxAmount}
        mt={4}
        variantColor='blue'
        variant='outline'
        type='submit'
        isLoading={submitBlock}
      >
        {t('common:create')}
      </Button>
    </form>
  )
}
