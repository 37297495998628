import React, { useEffect, useRef, useState } from "react" 
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/core" 
import { useTranslation } from "react-i18next" 

export default function AlertDialogSimple({
	isOpen,
	onClose,
	children,
	onAccept,
	acceptLabel = "Aceptar",
	acceptVariantColor = "red",
	title = false,
	control,
	renderNameSection,
	delayConfirmation = false,
	...rest
  }) {
	const cancelRef = useRef() 
	const [isBlocked, setIsBlocked] = useState(false) 
	const [delay, setDelay] = useState(delayConfirmation) 
	const { t } = useTranslation(['common', 'forms', 'currency', 'exchange']) 
  
	function handleAcceptAccept() {
	  if (delay) {
		setDelay(false) 
	  } else {
		setIsBlocked(true) 
	  }
	  onAccept() 
	}
  
	useEffect(() => {
	  setIsBlocked(false) 
	  return () => {
		// Cleanup function to reset delay on component unmount or renderNameSection change
		setDelay(delayConfirmation) 
	  } 
	}, [isOpen, renderNameSection, delayConfirmation]) 
  
	return (
	  <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} {...rest}>
		<AlertDialogOverlay />
		<AlertDialogContent>
		  <AlertDialogHeader fontSize="lg" fontWeight="bold">
			{title}
		  </AlertDialogHeader>
		  <AlertDialogBody>{children}</AlertDialogBody>
		  {!control ? (
			<AlertDialogFooter>
			  <Button ref={cancelRef} onClick={onClose} isDisabled={isBlocked}>
				{t('common:cancel')}
			  </Button>
			  <Button isLoading={isBlocked} variantColor={acceptVariantColor} onClick={handleAcceptAccept} ml={3}>
				{t('common:accept')}
			  </Button>
			</AlertDialogFooter>
		  ) : (
			<AlertDialogFooter>
			  <Button ref={cancelRef} onClick={onClose} isDisabled={isBlocked}>
				{t('common:accept')}
			  </Button>
			</AlertDialogFooter>
		  )}
		</AlertDialogContent>
	  </AlertDialog>
	) 
  }