import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncStateWithAction, useAsyncToastNotification, usePermission } from '../../../hooks'
import moment from 'moment'
import Table from '../../../common/table'
import { useSelector } from 'react-redux'
import {
  getToken,
  getAuthToken,
  getCategoriesState,
  getUserCountryId,
  getUserInformation,
  getUserApplicationId,
  getAllCategoriesState,
} from '../../../../redux/selectors'
import { useToast, Stack, Box, Text, Spinner, useDisclosure } from '@chakra-ui/core'
import { deconstructPermission } from '../../../../helpers/permissions-functions'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import { ButtonDelete, ButtonEdit, ButtonUbication } from '../../../forms/buttons/edit'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import { SimpleCategoryForm } from './SimpleCategoryForm'
import { listAllCategories } from '../../../../redux/actions/banners-actions'
import ModalSimple from '../../../common/modal-simple'
import {
  createCategory,
  deleteBanner,
  deleteCategory,
  deleteUbication,
  getBannersByCategory,
  getUbicationSeccion,
  updateCategoryById,
} from '../../../../api/intranet/marca-blanca/banner'
import { BANNERS_CREATE } from '../../../../helpers/permissions'
import Ubication from '../ubication'
import { Panel } from '../../../common/helpers'
import Pagination from '../../../common/pagination'

export const Categories = () => {
  const { addPermissions } = usePermission()
  const { t } = useTranslation(['common', 'forms', 'consultations'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const userCountryId = useSelector(getUserCountryId)
  const user = useSelector(getUserInformation)
  const appId = useSelector(getUserApplicationId)
  const [page, setPage] = useState(1)
  const [message, setMessage] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [dependencyBanners, setDependencyBanners] = useState([])
  const [dependencyUbications, setDependencyUbications] = useState([])
  const modalDisclosure = useDisclosure()
  const modalUbication = useDisclosure()
  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))
  const onCloseDeleteAlert2 = () => setDeleteAlert2(state => ({ ...state, show: false }))
  const dispatchedGetCategories = dispatch => dispatch(listAllCategories(user.aplicacion.id, userCountryId, page))
  const { error, isLoading, items, update } = useAsyncStateWithAction(dispatchedGetCategories, getAllCategoriesState)
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })
  const [deleteAlert2, setDeleteAlert2] = useState({
    show: false,
    data: null,
  })

  useEffect(() => {
    if (items) {
      setTotalPages(items?.totalPages)
    }
  }, [items])

  useEffect(() => {
    update()
  }, [page])

  // CREAR simpleCategory
  async function submitCategory(formData) {
    try {
      const sesion = {
        token,
        ...deconstructPermission(BANNERS_CREATE),
      }
      const data = { sesion, ...formData }
      const response = await createCategory(data, authToken)
      if (response.status === 201) {
        update()
      }
      toast(
        response.status === 201
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(error)
    }
  }

  function onSubmit(datos) {
    let formData = {
      default: datos.isDefault,
      activo: datos.isActive,
      empresaId: appId,
      nombre: datos.nombre ? datos.nombre : datos.titulo,
      descripcion: datos.descripcion ? datos.descripcion : datos.titulo,
      fechaInicio: String(moment(datos.startDate).format()),
      fechaFin: datos.endDate === null ? '' : String(moment(datos.endDate).format()),
      ofertaDinamicaId: datos.dinamicOffer ? Number(datos.dinamicOffer) : null,
      paisId: user.aplicacion.paisId,
      orden: Number(datos.orden),
    }
    submitCategory(formData)
  }

  //EDIT FUNCTIONS
  const [categoryId, setCategoryId] = useState(0)
  const [dataEdit, setDataEdit] = useState({})

  function onEditClick(id, data) {
    setCategoryId(id)
    setDataEdit(data)
    modalDisclosure.onOpen()
  }

  function onSubmitUpdate(formData) {
    const fechaFin = formData.endDate === null ? '' : String(moment(formData.endDate).format())

    let data = addPermissions(
      {
        default: formData.isDefault,
        activo: formData.isActive,
        empresaId: appId,
        nombre: formData.nombre ? formData.nombre : formData.titulo,
        descripcion: formData.descripcion ? formData.descripcion : formData.titulo,
        fechaInicio: String(moment(formData.startDate).format()),
        fechaFin,
        ofertaDinamicaId: formData.dinamicOffer ? Number(formData.dinamicOffer) : null,
        paisId: user.aplicacion.paisId,
        orden: Number(formData.orden),
      },
      BANNERS_CREATE,
    )
    updateCategory(data, categoryId)
  }

  async function updateCategory(data, categoryId) {
    try {
      const response = await updateCategoryById(categoryId, data, authToken)
      if (response.status === 200) {
        update()
        modalDisclosure.onClose()
      }
      toast(
        response.status === 200
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(error)
    }
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
  }

  async function onDelete() {
    await asyncNotificationWrapper(async () => {
      if (dependencyBanners && dependencyBanners.length) {
        dependencyBanners.forEach(async b => await deleteBanner(b.id))
      }

      if (dependencyUbications && dependencyUbications.length) {
        dependencyUbications.forEach(async u => await deleteUbication(u.id))
      }

      const response = await deleteCategory(deleteAlert.data.id, authToken)
      setPage(1)
      update()
      return response.status
    })
    onCloseDeleteAlert2()
  }

  async function checkDependencies() {
    try {
      const [responseBanner, responseUbicacion] = await Promise.all([
        getBannersByCategory(deleteAlert.data.id),
        getUbicationSeccion({ categoriaBannerId: deleteAlert.data.id }),
      ])

      if (responseBanner.data || responseUbicacion.data) {
        setMessage(
          <Box>
            <p>
              Esta categoría posee dependencias. Si elimina el registro se eliminarán todas las dependencias asociadas.
            </p>
            <p>¿Desea continuar?</p>
          </Box>,
        )
        setDeleteAlert2(state => ({
          ...state,
          show: true,
        }))
        setDependencyBanners(responseBanner.data || [])
        setDependencyUbications(responseUbicacion.data || [])
      } else {
        onDelete()
      }
    } catch (error) {
      console.log(error)
    }
  }

  //Modal de Ubicaciones
  function ubicationModal(id, name) {
    setBtnLoading(!btnLoading)

    setCategoryId(id)
    setDataEdit(name)
    modalUbication.onOpen()
  }

  const [btnLoading, setBtnLoading] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: 'nombre',
      },
      {
        Header: t('forms:description'),
        accessor: 'descripcion',
      },
      {
        Header: t('forms:active'),
        accessor: row => {
          if (row.activo === true) {
            return 'SI'
          } else {
            return 'NO'
          }
        },
      },
      {
        Header: t('forms:is_default'),
        accessor: row => {
          if (row.default === true) {
            return 'SI'
          } else {
            return 'NO'
          }
        },
      },
      {
        Header: t('forms:order'),
        accessor: 'orden',
      },
      {
        Header: t('forms:startDate'),
        accessor: 'fechaInicio',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.values.fechaInicio ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.values.fechaInicio).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:endDate'),
        accessor: 'fechaFin',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.values.fechaFin ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.values.fechaFin).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => {
          return (
            <Stack spacing='5px' isInline d='flex' justifyContent='center'>
              <ButtonUbication
                ml={1}
                isLoading={btnLoading}
                onClick={() => {
                  setBtnLoading(!btnLoading)
                  return ubicationModal(cell.row.values.id, cell.row.values.nombre)
                }}
                customLabel={t('common:locationGeo')}
              />

              <ButtonEdit
                mb={4}
                mx={1}
                onClick={() => {
                  return onEditClick(cell.row.values.id, cell.row.original)
                }}
              ></ButtonEdit>

              <ButtonDelete mr={1} onClick={() => prepareDelete(cell.row.original)} />
            </Stack>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t],
  )

  return (
    <>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={checkDependencies}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.nombre} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>

      {/* Alert delete dependencias */}
      <AlertDialogSimple
        isOpen={deleteAlert2.show}
        onClose={() => {
          onCloseDeleteAlert2()
          onCloseDeleteAlert()
        }}
        onAccept={() => {
          onCloseDeleteAlert()
          onDelete()
        }}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.nombre} #${deleteAlert.data.id}`}</b>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            fontSize: '1.2rem',
          }}
        >
          {message}
        </Box>
      </AlertDialogSimple>
      {/* Modal de Edición */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${dataEdit.nombre}`}
        size='lg'
      >
        <SimpleCategoryForm key='edit-form' formData={dataEdit} onSubmit={onSubmitUpdate} />
      </ModalSimple>

      {/* Modal Ubicacion */}
      <ModalSimple isOpen={modalUbication.isOpen} onClose={modalUbication.onClose} title={`${dataEdit}`} size='3xl'>
        <Ubication categoryId={categoryId} />
      </ModalSimple>

      {/* Formulario */}
      <Panel rounded='md'>
        <SimpleCategoryForm onSubmit={onSubmit} />
      </Panel>

      {/* Tabla */}
      <Box mt='8'>
        {!isLoading && items?.data && <Table columns={columns} data={items?.data} />}
        {isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!isLoading && !error && !items?.data && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!isLoading && !items?.data && error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
        {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
      </Box>
    </>
  )
}
