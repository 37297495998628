import React, { useMemo, useState, useEffect } from 'react'
import { useDisclosure, Stack, useToast, Box, Button, PseudoBox } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { deleteCurrencies, editCurrencies, getCurrencies, createCurrencies } from './../../../api/application'
import { getAuthToken } from '../../../redux/selectors'
import { ButtonDelete, ButtonEdit } from '../../forms/buttons/edit'
import Table from '../../common/table'
import { toastCustomizedSuccess, toastCustomizedError } from '../../common/toast-default-config'
import ModalSimple from '../../common/modal-simple'
import FormInput from '../../forms/form-input'
import { useForm } from 'react-hook-form'
import { isSuccess } from '../../../helpers/utils'
import AlertDialogSimple from '../../common/alert-dialog-simple'

export default function CurrencyAdmin() {
  const { t } = useTranslation(['common', 'forms', 'currency', 'exchange'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()
  const modalDisclosure3 = useDisclosure()
  const [formData, setFormData] = useState([])
  const { handleSubmit, register } = useForm({
    defaultValues: formData,
  })
  const [{ isLoading, currency }, setCurrency] = useState({
    isLoading: false,
    currency: [],
  })
  const [idExchange, setIdExchange] = useState(0)
  const [createExchange, setCreateExchange] = useState(false)
  const [currencyDescription, setcurrencyDescription] = useState('')

  useEffect(() => {
    async function getCountryCurrency() {
      setCurrency(state => ({
        ...state,
        isLoading: true,
      }))
      try {
        const response = await getCurrencies(authToken)
        setFormData(response.data)
        setCurrency(state => ({
          currency: response.data,
          isLoading: false,
        }))
      } catch (error) {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    }
    getCountryCurrency()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },

      {
        Header: t('common:code'),
        accessor: 'codigo',
      },
      {
        Header: t('common:description'),
        accessor: 'descripcion',
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit
              mb={4}
              onClick={e => {
                setIdExchange(cell.row.values.id)
                setcurrencyDescription(cell.row.values.codigo)
                return modalDisclosure.onOpen()
              }}
            ></ButtonEdit>
            <ButtonDelete
              mb={4}
              onClick={e => {
                setIdExchange(cell.row.values.id)
                setcurrencyDescription(cell.row.values.codigo)
                return modalDisclosure2.onOpen()
              }}
            ></ButtonDelete>
          </Stack>
        ),
      },
    ],
    [t],
  )

  async function submitUpdate(data) {
    const updatedData = {
      codigo: data.codigo,
      descripcion: data.descripcion,
    }

    try {
      setCreateExchange(false)
      const response = await editCurrencies(updatedData, idExchange, authToken)
      if (response.status) {
        const response = await getCurrencies(authToken)
        setFormData(response.data)
        setCurrency(state => ({
          currency: response.data,
          isLoading: false,
        }))
        modalDisclosure.onClose()
      }
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  async function submit(data) {
    const submitData = {
      codigo: data.codigo,
      descripcion: data.descripcion,
    }

    try {
      const response = await createCurrencies(submitData, authToken)
      if (response.status) {
        const response = await getCurrencies(authToken)
        setFormData(response.data)
        setCurrency(state => ({
          currency: response.data,
          isLoading: false,
        }))
        modalDisclosure3.onClose()
      }
      setCreateExchange(false)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  async function deleteCurrency() {
    try {
      setCreateExchange(false)
      const response = await deleteCurrencies(idExchange, authToken)
      if (response.status) {
        const response = await getCurrencies(authToken)
        setFormData(response.data)
        setCurrency(state => ({
          currency: response.data,
          isLoading: false,
        }))
        modalDisclosure2.onClose()
      }
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  return (
    <>
      <Box margin='0 0 2rem 0'>
        <Button
          mb={5}
          variantColor='blue'
          onClick={() => {
            setCreateExchange(true)
            return modalDisclosure3.onOpen()
          }}
        >
          {t('exchange:createCurrency')}
        </Button>

        {createExchange ? (
          <ModalSimple
            isOpen={modalDisclosure3.isOpen}
            onClose={modalDisclosure3.onClose}
            title={`${t('common:create')} ${t('common:navbar.currency')}: ${currencyDescription}`}
            size='lg'
          >
            <PseudoBox as='form' onSubmit={handleSubmit(submit)}>
              <Box>
                <FormInput
                  name='codigo'
                  inputProps={{
                    ref: register,
                    placeholder: 'Codigo',
                  }}
                />
              </Box>
              <Box margin='1rem 0'>
                <FormInput
                  name='descripcion'
                  inputProps={{
                    ref: register,
                    placeholder: t('forms:description'),
                  }}
                />
              </Box>
              <Button width='20%' variantColor='blue' variant='outline' type='submit'>
                {t('common:save')}
              </Button>
            </PseudoBox>
          </ModalSimple>
        ) : (
          <ModalSimple
            isOpen={modalDisclosure.isOpen}
            onClose={modalDisclosure.onClose}
            title={`${t('common:edit')} ${t('common:navbar.currency')}: ${currencyDescription}`}
            size='lg'
          >
            <PseudoBox as='form' onSubmit={handleSubmit(submitUpdate)}>
              <Box>
                <FormInput
                  name='codigo'
                  inputProps={{
                    ref: register,
                    placeholder: 'Codigo',
                  }}
                />
              </Box>
              <Box margin='1rem 0'>
                <FormInput
                  name='descripcion'
                  inputProps={{
                    ref: register,
                    placeholder: t('forms:description'),
                  }}
                />
              </Box>
              <Button width='20%' variantColor='blue' variant='outline' type='submit'>
                {t('common:save')}
              </Button>
            </PseudoBox>
          </ModalSimple>
        )}

        <AlertDialogSimple
          isOpen={modalDisclosure2.isOpen}
          onClose={modalDisclosure2.onClose}
          onAccept={deleteCurrency}
          title={`${t('common:delete')} ${t('common:navbar.currency')}: ${currencyDescription}`}
          size='lg'
        >
          {' '}
          <p>{`${t('common:deletingQuestion')}`}</p>
        </AlertDialogSimple>
        {!isLoading && currency.length >= 1 ? <Table columns={columns} data={currency} /> : null}
      </Box>
    </>
  )
}
