import React from 'react'
import { Button } from '@chakra-ui/core'
import '../styles/common/events-list-item.css'
import { goTo } from '../../helpers/utils'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import 'moment/locale/es'

const EventsListItem = ({ data }) => {
  let history = useHistory()
  const { fecha, titulo, url, subtitulo, id, comunicadoHtml } = data
  const { t } = useTranslation(['comunications'])

  const truncateSubtitle = (subtitle) => {
    const maxLength =  40;
    if (subtitle.length > maxLength) {
      return subtitle.slice(0, maxLength -  3) + '...'
    }
    return subtitle;
  }

  return (
    <div className='events-list-item'>
      <div className='events-list-item__date'>
        <div className='events-list-item__date__month'>{moment(fecha).format('MMMM').toUpperCase()}</div>
        <div className='events-list-item__date__day'>{moment(fecha).format('DD')}</div>
      </div>
      <div className='events-list-item__info'>
        <div className='events-list-item__info__title'>{titulo}</div>
        <div className='events-list-item__info__buttons'>
          {/* {comunicadoHtml !== '<p></p>\n' && (
            <Button w="7.5rem" background="#203F8F" style={{fontSize: "0.8rem"}} _hover={{background: '#284fb5'}} color="white" onClick={() => history.push(`/newsGEA?${id}?h`)} size='md' textTransform="uppercase">
              {t('comunications:seeMore')}
            </Button>
          )} */}
          {url && (
            <Button w="7.5rem" borderColor="#203F8F" style={{fontSize: "0.8rem", maxWidth: "320px"}} color="#203F8F" background="white" onClick={() => goTo(url)} variant='outline' size='md'>
              {truncateSubtitle(subtitulo) || 'Link'}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default EventsListItem
