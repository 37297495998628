import React from 'react'
import { Box, FormLabel, Input } from '@chakra-ui/core'

export default function FormInputNumber({
  name,
  isRequired = false,
  label = null,
  inputProps = {},
  value,
  maxLength,
  isDisabled = false,
  ...rest
}) {
  const handleKeyDown = e => {
    if (!/[0-9.]|Backspace|Delete|ArrowLeft|ArrowRight|Tab/.test(e.key)) {
      e.preventDefault()
    }

    if (e.key === '.' && e.target.value.includes('.')) {
      e.preventDefault()
    }
  }

  const handleInput = e => {
    const value = e.target.value
    const decimalIndex = value.indexOf('.')

    if (decimalIndex !== -1 && value.substring(decimalIndex + 1).length > 2) {
      e.target.value = parseFloat(value).toFixed(2)
    }
  }

  return (
    <Box {...rest}>
      {label && (
        <FormLabel isRequired={isRequired} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <Input
        style={{
          paddingRight: '0.1rem',
        }}
        isDisabled={isDisabled}
        isRequired={isRequired}
        name={name}
        value={value}
        maxLength={maxLength}
        {...inputProps}
        type='number'
        step='0.01' // Permitir hasta 2 decimales
        onKeyDown={handleKeyDown}
        onInput={handleInput}
      />
    </Box>
  )
}
