import React from "react";
import { Box, FormLabel, Textarea } from "@chakra-ui/core";

export default function FormTextArea({
	name,
	value,
	isRequired = false,
	label = null,
	inputProps = {},
	...restProps
}) {
	return (
		<Box {...restProps}>
			{label && (
				<FormLabel isRequired={isRequired} htmlFor={name}>{label}</FormLabel>
			)}
			<Textarea
				isRequired={isRequired}
				name={name}
				value={value}
				{...inputProps}
			/>
		</Box>
	);
}