import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button, FormLabel, Input, Text, Textarea } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

function FormItinerary({ handleDiscard, handleSaveItem, handleAddItem, edition, itineraryList, title, description }) {
  const { t } = useTranslation(['common', 'forms', 'packages', 'suppliers'])
  const { formState } = useForm({})

  const [itinerary, setItinerary] = useState({
    title: '',
    description: '',
    imageUrl: null,
    order: 0,
    id: 0,
  })

  useEffect(() => {
    if (edition.state) {
      setItinerary({
        ...itineraryList[edition.key],
      })
    }
  }, [edition])

  function resetForm() {
    setItinerary({ title: '', description: '', imageUrl: null, order: 0 })
  }

  function onChangeInput(e) {
    e.persist()
    setItinerary(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  function handleOnClickSaveItem(itineraryUpdated) {
    resetForm()
    handleSaveItem(itineraryUpdated)
  }
  function handleOnClickDiscard() {
    resetForm()
    handleDiscard()
  }

  function handleOnClickAddIten(e) {
    resetForm()
    handleAddItem(itinerary)
  }

  function handleUrl(url) {
    setItinerary(prevState => ({
      ...prevState,
      imageUrl: url.image_url,
    }))
  }

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_PRESET, REACT_APP_CLOUDINARY_FOLDER } = process.env

  const myCropWidget = useMemo(() => {
    return window.cloudinary.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: REACT_APP_CLOUDINARY_PRESET,
        folder: REACT_APP_CLOUDINARY_FOLDER,
        cropping: true,
      },
      (error, result) => {
        if (error) {
          alert('Cloudinary service unavailable')
        }
        checkUploadResult(result)
      },
    )
  }, [])

  function checkUploadResult(resultEvent) {
    if (resultEvent.event === 'success') {
      const img = {
        image_url: resultEvent.info.secure_url,
      }
      handleUrl(img)
    }
  }

  function showWidget() {
    myCropWidget.open()
  }

  return (
    <>
      <Box alignItems='center'>
        {/* Title */}
        <FormLabel>{t('forms:title')}</FormLabel>
        <Input
          type='text'
          id='title'
          name='title'
          autoComplete='off'
          value={itinerary.title}
          onChange={e => onChangeInput(e)}
        />
        {/* imagen URL */}
        <Box mt='4' mb='1' display='flex' gap='1rem' justifyContent='space-between' alignItems='center'>
          <Box display='flex' gap='1rem' alignItems='center'>
            <Button size='sm' onClick={showWidget} variantColor='blue' variant='outline' alignSelf='center'>
              {t('suppliers:uploadImage')}
            </Button>
            <Text ml='2' color={itinerary.imageUrl ? 'green.500' : 'gray.500'}>
              {itinerary.imageUrl ? t('packages:uploadImage') : t('packages:noImage')}
            </Text>
          </Box>
        </Box>

        {/* Descripción */}
        <FormLabel mt='1'>{t('forms:description')}</FormLabel>
        <Textarea
          id='description'
          name='description'
          cols='30'
          rows='10'
          onChange={e => onChangeInput(e)}
          value={itinerary.description}
        />
        {edition.state ? (
          <Box display='flex' alignItems='center'>
            <Button
              variantColor='blue'
              variant='outline'
              mt='2'
              mr='2'
              disabled={!itinerary.title || !itinerary.description}
              onClick={() => handleOnClickSaveItem(itinerary)}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <span style={{ marginRight: '0.5rem' }}>{t('forms:save')}</span> <i className='fa fa-check' />
            </Button>
            <Button
              variantColor='red'
              variant='outline'
              mt='2'
              onClick={() => handleOnClickDiscard()}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {t('forms:discard')} <i className='fa fa-close' />
            </Button>
          </Box>
        ) : (
          <Button
            variantColor='blue'
            variant='outline'
            mt='2'
            isLoading={formState.isSubmitting}
            onClick={e => handleOnClickAddIten(e)}
            disabled={!itinerary.title || !itinerary.description}
          >
            {t('common:add')}
          </Button>
        )}
      </Box>
    </>
  )
}

export default FormItinerary
