import { Box, Button, FormLabel, PseudoBox, Stack } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getCategoryById } from '../../../api/web-information'
import { useFormControls } from '../../hooks'
import FormInput from '../form-input'
import { VALUE_TYPE_KEYS } from '../../../helpers/commons'
import SelectAsync from '../../common/select-async'
import { useSelector } from 'react-redux'
import { getToken } from '../../../redux/selectors'

export default function CategoryForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['forms'])

  const token = useSelector(getToken)
  const { isSubmitBlocked, submitWrapper } = useFormControls()

  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })

  const [nameValue, setnameV] = useState()
  const [descriptionValue, setDescriptionV] = useState()
  const [typeValue, setTypeValue] = useState()

  function loadValuesInputs(miCategory) {
    setnameV(miCategory.nombre)
    setDescriptionV(miCategory.descripcion)
    setTypeValue(miCategory.tipoValor)
  }

  const [{ isLoading, category }, setCategoryRequest] = useState({
    isLoading: true,
    category: {},
  })

  async function getCategory(compDetailId) {
    let miCategory = {}

    setCategoryRequest((state) => ({
      ...state,
      isLoading: true,
    }))

    await getCategoryById(compDetailId, token)
      .then((res) => (miCategory = res.data))
      .catch((error) => console.log('ErrorCategoryById', error))
    setCategoryRequest((state) => ({
      ...state,
      isLoading: false,
      category: miCategory,
    }))
    loadValuesInputs(miCategory)
  }

  useEffect(() => {
    getCategory(restProps.idSectionn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  ///On Guardar
  function onSubmitt(datos) {
    onSubmit(datos)
  }

  return (
    <PseudoBox as="form" onSubmit={handleSubmit((d) => onSubmitt(d))} {...restProps}>
      <Stack>
        {/* Nombre */}
        <Box>
          <FormLabel htmlFor="nombre" isRequired>
            {t('forms:name')}
          </FormLabel>
          <FormInput
            isRequired
            maxLength="255"
            name="nombre"
            value={nameValue}
            onChange={(e) => {
              const newNameValue = e.target.value
              setnameV(newNameValue)
            }}
            inputProps={{
              ref: register,
            }}
          />
        </Box>

        {/* Descripcion */}
        <Box>
          <FormLabel htmlFor="descripcion" isRequired>
            {t('forms:description')}
          </FormLabel>
          <FormInput
            isRequired
            maxLength="255"
            name="descripcion"
            value={descriptionValue}
            onChange={(e) => {
              const newDescriptionValue = e.target.value
              setDescriptionV(newDescriptionValue)
            }}
            inputProps={{
              ref: register,
            }}
          />
        </Box>

        {/* Tipo de valor */}
        <Box>
          <FormLabel htmlFor="tipoValor" isRequired>
            {t('forms:value_type')}
          </FormLabel>
          <SelectAsync
            name="tipoValor"
            placeholder={t('forms:selectOption')}
            nativeProps={{ ref: register }}
            value={typeValue}
            onChange={(e) => setTypeValue(e.target.value)}
            isRequired
          >
            {Object.values(VALUE_TYPE_KEYS).map((item) => (
              <option key={item} value={item}>
                {t('forms:' + item.toLowerCase())}
              </option>
            ))}
          </SelectAsync>
        </Box>
        <Button mt={6} variantColor="blue" variant="outline" type="submit" isLoading={isSubmitBlocked}>
          {t('forms:save')}
        </Button>
      </Stack>
    </PseudoBox>
  )
}
