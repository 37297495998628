import { Button, PseudoBox, SimpleGrid, Stack } from '@chakra-ui/core'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFormControls } from '../../hooks'
import FormInput from '../form-input'

export default function SupplierOfficesForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['forms', 'suppliers'])
  const { isSubmitBlocked, submitWrapper } = useFormControls()
  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })

  function submit(data, e) {
    submitWrapper(async () => await onSubmit(data, e))
  }

  return (
    <PseudoBox as="form" onSubmit={handleSubmit(submit)} {...restProps}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
        <FormInput
          isRequired
          label={t('forms:address')}
          name="direccion"
          inputProps={{
            ref: register,
          }}
        />
        <FormInput
          isRequired
          label="Email"
          name="email"
          inputProps={{
            placeholder: '@',
            ref: register,
            type: 'email',
          }}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
        <FormInput
          isRequired
          label={t('forms:phone')}
          name="telefono"
          inputProps={{
            placeholder: '',
            ref: register,
          }}
        />
        <FormInput
          isRequired
          label={t('suppliers:emergency_phone')}
          name="telefonoEmergencia"
          inputProps={{
            placeholder: '',
            ref: register,
          }}
        />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={2}>
        <FormInput
          isRequired
          label={t('suppliers:attention_time')}
          name="horarioAtencion"
          inputProps={{
            placeholder: '',
            ref: register,
          }}
        />
        <FormInput
          isRequired
          label="Web"
          name="web"
          inputProps={{
            placeholder: 'www.',
            ref: register,
          }}
        />
      </SimpleGrid>
      <Button mt={6} variantColor="blue" variant="outline" type="submit" isLoading={isSubmitBlocked}>
        {buttonLabel || t('forms:save')}
      </Button>
    </PseudoBox>
  )
}
