import React from 'react'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { getAuthToken, getToken } from '../../../../redux/selectors'
import { deconstructPermission } from '../../../../helpers/permissions-functions'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { UpdateCompanyDetail } from '../../../../api/web-information'
import { COMPANY_DETAIL_CREATE } from '../../../../helpers/permissions'
import WebDataForm from '../../../forms/web-information/WebDataForm'

const CreateWebData = ({ onFinish, onClose, idSection, ...restProps }) => {
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()

  const handleSubmit = async formData => {
    if (!idSection) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      return
    }

    const sesion = {
      token,
      ...deconstructPermission(COMPANY_DETAIL_CREATE),
    }

    const requestData = { sesion, ...formData }

    try {
      const response = await UpdateCompanyDetail(idSection, requestData, authToken)
      if (response.status) {
        onClose()
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      console.error('Error updating company detail:', error)
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }

    onFinish()
  }

  return (
    <div>
      <WebDataForm onSubmit={handleSubmit} buttonLabel={t('common:create')} idSection={idSection} {...restProps} />
    </div>
  )
}

export default CreateWebData
