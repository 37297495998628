import React, { useMemo, useState, useEffect } from 'react'
import { useDisclosure, Stack, Box, Spinner, Text } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useConfigWithAuth } from '../../../hooks'
import DefaultSectionForm from './default-sections-form'
import moment from 'moment'

import Table from '../../../common/table'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserCountryId } from '../../../../redux/selectors'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import {
  iconInstance,
  getAllIconTypesEndpoint,
  createDefaultSection,
  DeleteDefaultSection,
  getAllDefaultSections,
  DeleteCompanySection,
  getCompaniesSections,
} from '../../../../api/section'
import { Panel } from '../../../common/helpers'
import CreateDefaultSection from './create-default-section'
import ModalSimple from '../../../common/modal-simple'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import useFetch from '../../../../api/useFetch'
import Pagination from '../../../common/pagination'
import {
  deleteBanner,
  deleteCategory,
  deleteUbication,
  getBannersByCategory,
  getUbicationSeccion,
} from '../../../../api/intranet/marca-blanca/banner'
import { isSuccess } from '../../../../helpers/utils'

export default function DefaultSection() {
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const { configAuth } = useConfigWithAuth()
  const [idSectionn, setIdSection] = useState(0)
  const userCountryId = useSelector(getUserCountryId)
  const toast = useToast()
  const [message, setMessage] = useState('')
  const onCloseDeleteAlert2 = () => setDeleteAlert2(state => ({ ...state, show: false }))
  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))
  const modalDisclosure = useDisclosure()
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [{ isLoading, sections }, setSectionsRequest] = useState({
    isLoading: false,
    sections: [],
  })
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })
  const [deleteAlert2, setDeleteAlert2] = useState({
    show: false,
    data: null,
  })
  const [resetPicker, setResetPicker] = useState(false)
  const [resetCountry, setResetCountry] = useState(false)

  const iconTypesRequest = useFetch({
    api: iconInstance,
    method: 'get',
    url: getAllIconTypesEndpoint(),
    config: configAuth,
  })

  async function getSection() {
    try {
      setSectionsRequest(state => ({
        ...state,
        isLoading: true,
      }))

      const res = await getAllDefaultSections({ paisId: userCountryId, page })

      setTotalPages(res.data.totalPages)

      setSectionsRequest(state => ({
        ...state,
        isLoading: false,
        sections: res.data.data,
      }))
    } catch (error) {
      console.log(error)

      setSectionsRequest(state => ({
        ...state,
        isLoading: false,
      }))
    }
  }

  useEffect(() => {
    getSection()
  }, [page])

  ///Create section
  async function onSubmit(datos) {
    try {
      setSectionsRequest(state => ({
        ...state,
        isLoading: true,
      }))
      const formData = {
        paisId: Number(datos.country),
        nombre: datos.nameSection,
        descripcion: datos.nameSection,
        fechaInicio: datos.startDate._d,
        fechaFin: datos.endDate === null ? null : datos.endDate._d,
        icon: { id: Number(datos.icon) },
        url: datos.url,
        activo: true,
        fechaAlta: datos.startDate._d,
        tipoSeccion: Number(datos.tipoSeccion.id),
        contenido: datos.contenido,
      }

      const response = await createDefaultSection(formData, authToken)

      if (response.status === 201) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        document.getElementById('defaultSection-form').reset()
        setResetPicker(!resetPicker)
        setResetCountry(!resetCountry)
        getSection(userCountryId)
        setSectionsRequest(state => ({
          ...state,
          isLoading: false,
        }))
      } else {
        setSectionsRequest(state => ({
          ...state,
          isLoading: false,
        }))
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      setSectionsRequest(state => ({
        ...state,
        isLoading: false,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('forms:name')}`,
        accessor: 'nombre',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.nombre.length > 26 ? (
              <Text>{cell.row.values.nombre.substring(0, 26)}...</Text>
            ) : (
              <Text>{cell.row.values.nombre}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:startDate'),
        accessor: 'fechaInicio',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.original.fechaInicio ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.original.fechaInicio).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:endDate'),
        accessor: 'fechaFin',
        Cell: ({ cell }) => {
          return (
            <Stack spacing='5px' isInline>
              {!cell.row.original.fechaFin ? (
                <Text style={{ textAlign: 'center' }}>--</Text>
              ) : (
                <Text>{moment(cell.row.original.fechaFin).format('DD-MM-YYYY')}</Text>
              )}
            </Stack>
          )
        },
      },
      {
        Header: t('forms:icon'),
        accessor: 'icon',
        Cell: ({ cell }) => {
          return (
            <Stack spacing='5px' isInline>
              <p>{cell.row.values.icon.descripcion}</p>
            </Stack>
          )
        },
      },
      {
      Header: t('forms:typeSection'),
      accessor: 'tipoSeccion',
      Cell: ({ cell }) => (
        <Stack spacing='5px' isInline>
          <Text alignContent='center'>{cell.row.values.tipoSeccion.nombre}</Text>
        </Stack>
      ),
    },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit mb={4} onClick={() => onEditClick(cell.row.values.id)}></ButtonEdit>
            <ButtonDelete onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ],
    [t, iconTypesRequest],
  )

  function onEditClick(idSectionEdit) {
    let idSection = idSectionEdit
    setIdSection(idSection)
    modalDisclosure.onOpen()
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      show: true,
      data,
    }))
  }

  async function onDelete() {
    let sectionId = deleteAlert.data.id
    try {
      const responseUbicacion = await getUbicationSeccion({ seccionId: sectionId })

      if (responseUbicacion.data && responseUbicacion.data.length) {
        responseUbicacion.data.forEach(async u => {
          const banners = await getBannersByCategory(u.categoriaBanner.id)
          if (banners.data && banners.data.length) banners.data.forEach(async b => await deleteBanner(b.id))

          return await Promise.all([deleteUbication(u.id), deleteCategory(u.categoriaBanner.id)])
        })
      }

      const response = await DeleteDefaultSection(sectionId, authToken)
      const status = isSuccess(response.status)

      if (status) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        onCloseDeleteAlert2()
        onCloseDeleteAlert()
        getSection(userCountryId)
      } else {
        onCloseDeleteAlert2()
        onCloseDeleteAlert()
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    getSection(userCountryId)
    onCloseDeleteAlert()
  }

  async function checkDependencies() {
    try {
      const responseDependecie = await getCompaniesSections({ idSeccionDefault: deleteAlert.data.id, all: true })

      if (responseDependecie?.data?.length >= 1) {
        setMessage(
          <Box>
            <p>
              Esta sección posee dependencias. Si elimina el registro se eliminarán todas las dependencias asociadas.
            </p>
            <p>¿Desea continuar?</p>
          </Box>,
        )
        setDeleteAlert2(state => ({
          ...state,
          show: true,
        }))
      } else {
        onDelete()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={checkDependencies}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.nombre} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>

      {/* Alert delete dependencias */}
      <AlertDialogSimple
        isOpen={deleteAlert2.show}
        onClose={() => {
          onCloseDeleteAlert()
          onCloseDeleteAlert2()
        }}
        onAccept={() => {
          onCloseDeleteAlert()
          onDelete()
        }}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.descripcion} #${deleteAlert.data.id}`}</b>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            fontSize: '1.2rem',
          }}
        >
          {message}
        </Box>
      </AlertDialogSimple>

      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${t('sections:defaultSection')}`}
        size='lg'
      >
        <CreateDefaultSection
          onFinish={() => null}
          idSectionn={idSectionn}
          getSection={getSection}
          onClose={modalDisclosure.onClose}
        />
      </ModalSimple>
      <Panel rounded='md' mb='1.5rem'>
        <DefaultSectionForm onSubmit={onSubmit} resetPicker={resetPicker} resetCountry={resetCountry} />
      </Panel>

      <Box>
        {!isLoading && sections?.length >= 1 ? <Table columns={columns} data={sections} /> : null}
        {isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!isLoading && sections?.length === 0 && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!isLoading && !sections && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
        {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
      </Box>
    </Box>
  )
}
