import React from 'react'

import { SectionDataProvider } from '../../../context/SectionDataContext'
import SuppliersAgreementsContent from './supplier-agreements-content'

const FILTER_KEYS = {
  paisId: 'paisId',
  categoria: 'categoria',
  producto: 'producto',
  page: 'page',
  maxPerPage: 'maxPerPage',
  nombre: 'nombre',
  soloAdmin: 'soloAdmin',
}

const SECTION_DATA_KEYS = {
  productos: 'productos',
  suppliers: 'suppliers',
  acuerdos: 'acuerdos',
}

const defaultSectionDataValues = {
  productos: [],
  acuerdos: [],
  suppliers: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 12,
  paisId: '',
  nombre: '',
  categoria: '',
}

const defaultFiltersValues = { ...defaultFilters }

const SuppliersAgreements = () => (
  <SectionDataProvider defaultFilters={{ ...defaultFiltersValues }} defaultSectionData={defaultSectionDataValues}>
    <SuppliersAgreementsContent />
  </SectionDataProvider>
)

export {
  SuppliersAgreements,
  FILTER_KEYS,
  SECTION_DATA_KEYS,
  defaultSectionDataValues,
  defaultFiltersValues,
  defaultFilters,
}
