import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { PageContainer, Panel } from '../../common/helpers'
import { Button, useDisclosure, Stack, useToast, Box, Heading, Text, Spinner } from '@chakra-ui/core'
import ModalSimple from '../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import { ButtonDelete, ButtonEdit } from '../../forms/buttons/edit'
import Table from '../../common/table'
import GroupSearchForm from './group-search-form'
import GroupForm from './create-group'
import { createGroup, deleteGroup, getGroupPaginated, updateGroup } from '../../../api/groups-api'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import { getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import Pagination from '../../common/pagination'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from '.'
import moment from 'moment'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import { getAllCountries } from '../../../api/application'

export default function Group() {
  const { t } = useTranslation(['common', 'forms', 'packages', 'bookings'])
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    filters,
    handleFilters,
    sectionData,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()
  const [totalPages, setTotalPages] = useState(0)
  const [idGroup, setIdGroup] = useState()
  const userRolId = useSelector(getUserRolId)
  const userCountryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const permisosUserCountry = userRolId === 2 ? true : false
  const [countries, setCountries] = useState(null)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const [groups, setGroups] = useState({
    data: [],
    isLoading: true,
  })
  const [dataEdit, setDataEdit] = useState()
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })
  const [updateSearch, setUpdateSearch] = useState(false) // Estado para actualizar el formulario de búsqueda

  const columns = useMemo(() => {
    const capitalizeTitle = str => {
      if (!str) return ''
      return str.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase())
    }
    const baseColumns = [
      {
        Header: `${t('common:departureDate')}`,
        accessor: 'departureDate',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <div
              style={{
                border: '2.5px solid #585859',
                width: '5rem',
                height: '5rem',
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.25rem',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  height: '100%',
                  padding: '4px',
                  fontWeight: '700',
                }}
              >
                <Text
                  style={{
                    fontSize: '0.75rem',
                    color: '#585859',
                    textTransform: 'uppercase',
                  }}
                >
                  {moment(cell.row.values.departureDate).format('MMM')}
                </Text>
                <p className='dateNumberCardCommunication' style={{ fontSize: '1.75rem', margin: '0' }}>
                  <b>{moment(cell.row.values.departureDate).format('DD')}</b>
                </p>
                <Text
                  style={{
                    fontSize: '0.75rem',
                    color: '#585859',
                    fontWeight: '700',
                  }}
                >
                  {moment(cell.row.values.departureDate).format('YYYY')}
                </Text>
              </Box>
            </div>
          </Stack>
        ),
      },
      {
        Header: `${t('forms:name')}`,
        accessor: 'name',
      },
      {
        Header: `${t('forms:destination')}`,
        accessor: 'destination',
        Cell: ({ cell }) => <p>{capitalizeTitle(cell.row.values.destination)}</p>,
      },
      {
        Header: `${t('common:days')}`,
        accessor: 'days',
      },
      {
        Header: `${t('forms:currency')}`,
        accessor: 'currency',
      },
      {
        Header: `${t('bookings:price')}`,
        accessor: 'price',
        Cell: ({ cell }) => {
          const formattedPrice = new Intl.NumberFormat('es-ES', {
            style: 'decimal',
            minimumFractionDigits: 2,
          }).format(cell.row.values.price)
          return <p>{formattedPrice}</p>
        },
      },
      {
        Header: `${t('forms:link')}`,
        accessor: 'linkName',
        Cell: ({ cell }) => {
          const handleLinkClick = () => {
            window.open(cell.row.original.link, '_blank', 'noopener,noreferrer')
          }

          return (
            <Box
              as='button'
              onClick={handleLinkClick}
              style={{
                color: '#3182ce',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              <p>{cell.row.original.linkName}</p>
            </Box>
          )
        },
      },
      {
        Header: `${t('forms:suppliers')}`,
        accessor: 'supplier',
        Cell: ({ cell }) => <p>{cell.row.values.supplier?.name}</p>,
      },
    ]

    if (permisosUserCountry) {
      baseColumns.push({
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit
              mb={4}
              onClick={() => {
                onEditClick(cell.row.original)
              }}
            />
            <ButtonDelete
              onClick={() => {
                return prepareDelete(cell.row.original)
              }}
            />
          </Stack>
        ),
      })
    }

    if (userRolId === 1) {
      baseColumns.push({
        Header: `${t('forms:country')}`,
        accessor: 'countryId',
        Cell: ({ cell }) => {
          const country = countries?.find(c => c.id == cell.row.values.countryId)
          return <p>{country ? country.nombre : null}</p>
        },
      })
    }

    return baseColumns
  }, [t, groups, permisosUserCountry, countries, userRolId])

  async function onSubmit(formData) {
    if (!dataEdit) {
      try {
        const response = await createGroup(formData)
        toast(
          response.status === 201
            ? toastCustomizedSuccess(t('forms:actionCompleted'))
            : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
        )
      } catch (error) {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } else {
      try {
        const response = await updateGroup(idGroup, formData)
        toast(
          response.status === 204
            ? toastCustomizedSuccess(t('forms:actionCompleted'))
            : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
        )
        setIdGroup(undefined)
      } catch (error) {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
        setIdGroup(undefined)
      }
    }
    getGroups()
    setUpdateSearch(prev => !prev) // Actualizar el estado para desencadenar la actualización en GroupSearchForm
  }

  const getCountries = async () => {
    try {
      const response = await getAllCountries(authToken)
      setCountries(() => response.data)
    } catch (error) {
      console.log(error)
    }
  }

  function onEditClick(data) {
    setDataEdit(data)
    setIdGroup(data.id)
    onOpen()
  }

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getGroups()
      }
    }
  }

  const getGroups = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    if (userRolId !== 1) {
      filtersAux.countryId = userCountryId
    }
    setLastFilters(filtersAux)

    handleSectionData(SECTION_DATA_KEYS.group, {
      ...sectionData.group,
      isLoading: true,
    })

    setGroups(state => ({
      ...state,
      isLoading: true,
    }))

    const { startDate, endDate, ...restFilters } = filtersAux
    const effectiveFilters = {
      ...restFilters,
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
    }

    try {
      const response = await getGroupPaginated(effectiveFilters)
      setGroups(() => ({
        data: response.data,
        isLoading: false,
      }))
      setTotalPages(response.data.totalPages)
    } catch (error) {
      setGroups(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      show: true,
      data,
    }))
  }

  const onCloseAlertDelete = () => setDeleteAlert(state => ({ ...state, show: false }))

  const handleDeleteGroup = async () => {
    const { data } = deleteAlert
    try {
      await deleteGroup(data.id)
      getGroups()
      toast({
        title: t('forms:actionCompleted'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      setUpdateSearch(prev => !prev) // Actualizar el estado para desencadenar la actualización en GroupSearchForm
    } catch (error) {
      alert('Error getting data')
    }
    setDeleteAlert(state => ({
      ...state,
      show: false,
      data: null,
    }))
  }

  useEffect(() => {
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
    getGroups(true)
  }, [resetFilters])

  useEffect(() => {
    getGroups()
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseAlertDelete}
        onAccept={handleDeleteGroup}
        title={`${t('common:delete')} Grupo`}
        acceptLabel={t('common:delete')}
        acceptVariantColor='red'
      >
        <p>{deleteAlert.data && `${deleteAlert.data.name} `}</p>
      </AlertDialogSimple>
      <ModalSimple
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          setDataEdit(undefined)
          onClose()
        }}
        title={dataEdit ? t('common:editGroup') : `${t('common:createGroup')}`}
        size='4xl'
      >
        <GroupForm onSubmit={onSubmit} formData={dataEdit} onFinish={onClose} />
      </ModalSimple>
      <PageContainer>
        <Box backgroundColor='' py='16px' color='#103059'>
          <Heading style={{ fontSize: '1.8rem' }}>{`${t('bookings:departures')} ${t('common:navbar.group')}`}</Heading>
        </Box>
        {permisosUserCountry && (
          <Button my={5} variantColor='blue' onClick={onOpen} type='submit'>
            {t('common:createGroup')}
          </Button>
        )}
        <Panel rounded='md' mb='1.5rem'>
          <GroupSearchForm onFilter={onFilter} updateSearch={updateSearch} /> {/* Pasar el estado de actualización como prop */}
        </Panel>
        <Box mt='8'>
          {groups.isLoading ? (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          ) : (
            <>
              {groups.data.data.length > 0 ? (
                <>
                  <Table columns={columns} data={groups.data.data} />
                  {totalPages && (
                    <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
                  )}
                </>
              ) : (
                <Text textAlign='center' color='gray.500' px='5' py='10'>
                  {t('common:zeroItems')}
                </Text>
              )}
            </>
          )}
        </Box>
      </PageContainer>
    </>
  )
}
