import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  FormLabel,
  useToast,
  Image,
  PseudoBox,
  Spinner,
  Input,
  Textarea,
  Icon,
  Tag,
} from '@chakra-ui/core'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getAuthToken, getUserCountryId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { getAllCities, getLocalityById } from '../../../api/geo'
import { PageContainer } from '../../common/helpers'
import { toastCustomized, toastCustomizedSuccess, toastCustomizedError } from '../../common/toast-default-config'
import { createHotel, getHotel, getHotelNames, updateHotel } from '../../../api/intranet/requests'
import Rating from './rating'
import theme from '../../../theme'
import { Row, Select } from 'antd'
import ImageInputV2 from '../../common/image-input-v2'
import { checkEditedData, isSuccess } from '../../../helpers/utils'
import { IoMdTrash } from 'react-icons/io'

export const HotelForm = ({ idHotel = undefined, onClose, refetch, ...restProps }) => {
  const { t } = useTranslation(['common', 'forms', 'hotels'])
  const authToken = useSelector(getAuthToken)
  const paisId = useSelector(getUserCountryId)
  const toast = useToast()
  const [availableName, setAvailableName] = useState(undefined)
  const [almostVerified, setAlmostVerified] = useState(false)
  const [verifying, setVerifying] = useState(false)

  const { register, handleSubmit, formState, control, setValue, getValues, watch, reset } = useForm()
  const hotelCategory = watch('category')

  let lenguaje
  switch (paisId) {
    case 1:
      lenguaje = 'ar'
      break
    case 2:
      lenguaje = 'pe'
      break
    case 3:
      lenguaje = 'br'
      break
    case 4:
      lenguaje = 'po'
      break
    default:
      lenguaje = 'en'
      break
  }

  const [localidadIsSelected, setLocalidadIsSelected] = useState(false)
  const [paisDestinoSelected, setPaisDestinoSelected] = useState()

  const [hotelImagenes, setHotelImagenes] = useState([])

  const handleEventInput = ({ target }) => {
    setValue(target.name, target.value)
  }
  const resetForm = () => {
    setNamesToDisplay(undefined)
    setHotelImagenes([])
    setAlmostVerified(false)
    setAvailableName(undefined)
  }

  const handleLocalidadId = value => {
    if (getValues('localidadId') !== value) {
      setValue('localidadId', value)
      if (value) {
        setLocalidadIsSelected(true)
        setPaisDestinoSelected(cityDestinations.items[0].provincia.pais.id)
      }
      if (idHotel) {
        handleMustRevalidate()
        if (value !== hotel.data.localidadId) {
          handleExistenceVerify()
        }
      } else {
        resetForm()
      }
    }
  }
  const handleMustRevalidate = () => {
    const { name, localidadId } = hotel.data
    if (name !== getValues('name') || localidadId !== getValues('localidadId')) {
      setAlmostVerified(false)
      setAvailableName(false)
    } else {
      setAlmostVerified(true)
      setAvailableName(true)
      setNamesToDisplay(undefined)
    }
  }
  const handleNameInput = event => {
    event.stopPropagation()
    handleEventInput(event)
    if (!!idHotel) {
      handleMustRevalidate()
    } else {
      resetForm()
    }
  }

  const hotelDefaultState = {
    isLoading: !!idHotel,
    data: undefined,
    error: false,
  }
  const [hotel, setHotel] = useState(hotelDefaultState)

  function onSubmit(formData) {
    let data = {
      ...formData,
      category: hotelCategory,
      hotelImagenes,
      paisId,
      paisDestinoId: paisDestinoSelected,
      lenguaje,
    }
    submitHotel(data)
  }

  async function submitHotel(formData) {
    if (almostVerified && availableName && !namesToDisplay) {
      try {
        let response = undefined
        if (idHotel) {
          response = await updateHotel(idHotel, formData, authToken)
        } else {
          response = await createHotel(formData, authToken)
        }
        if (response) {
          if (isSuccess(response.status)) {
            reset()
            resetForm()
            onClose()
            toast(toastCustomizedSuccess(t('forms:actionCompleted')))
            refetch()
          } else {
            toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
          }
        }
      } catch (error) {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } else {
      toast(toastCustomized(t('hotels:mustVerify'), t('hotels:verifyToContinue'), 'warning', 2300))
    }
  }
  const [image, setImage] = useState('')

  const [cityDestinations, setCityDestinations] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getCityDestinations(city) {
    setCityDestinations(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCities(city, authToken)
      setCityDestinations(() => ({
        error: false,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setCityDestinations(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }
  function handleSearchDestinations(value) {
    if (value.length > 2) {
      getCityDestinations(value)
    } else {
      setCityDestinations({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  async function getHotelLocality(localityId) {
    setCityDestinations(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getLocalityById(localityId, authToken)
      setCityDestinations(() => ({
        error: false,
        isLoading: false,
        items: [res.data] || [],
      }))
    } catch (error) {
      setCityDestinations(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }

  async function getHotelRequest() {
    setHotel(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      await getHotel(idHotel, authToken).then(async ({ data }) => {
        setAlmostVerified(true)
        setAvailableName(true)
        if (data.localidadId) {
          setLocalidadIsSelected(true)
          await getHotelLocality(data.localidadId)
        }
        if (data.hotelImagenes?.length) {
          setHotelImagenes(data.hotelImagenes.map(({ imageUrl }) => imageUrl))
        }
        setHotel({
          error: false,
          isLoading: false,
          data: data,
        })
      })
    } catch (error) {
      setHotel({
        data: undefined,
        isLoading: false,
        error: true,
      })
    }
  }
  const { Option } = Select
  useEffect(() => {
    if (idHotel) {
      getHotelRequest()
    }
  }, [idHotel])

  useEffect(() => {
    if (hotel && hotel.data) {
      Object.entries(hotel.data)
        .filter(([key]) => key !== 'hotelImagenes')
        .forEach(([key, value]) => setValue(key, value))
    }
  }, [hotel])

  const getHotelNameList = async () => {
    try {
      const response = await getHotelNames({
        name: getValues('name'),
        localidadId: getValues('localidadId'),
        lenguaje,
      })
      if (isSuccess(response.status)) {
        return response.data.length ? response.data : []
      }
    } catch {
      return undefined
    }
  }

  const [namesToDisplay, setNamesToDisplay] = useState(undefined)

  const handleExistenceVerify = async () => {
    if (getValues('name') && getValues('localidadId')) {
      if (
        (!!idHotel &&
          (getValues('name') !== hotel.data?.name || getValues('localidadId') !== hotel.data?.localidadId)) ||
        !idHotel
      ) {
        setVerifying(true)
        try {
          const hotelNameList = await getHotelNameList()
          if (hotelNameList) {
            if (hotelNameList.length) {
              if (hotelNameList.map(name => name.toLowerCase()).includes(getValues('name').toLowerCase())) {
                setAvailableName(false)
              } else {
                setNamesToDisplay(hotelNameList)
                setAvailableName(true)
              }
            } else {
              setAvailableName(true)
            }
          } else {
            setAvailableName(undefined)
          }
          setAlmostVerified(true)
          setVerifying(false)
        } catch {
          toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
          setAvailableName(undefined)
          setAlmostVerified(false)
          setVerifying(false)
        }
      } else {
        setAlmostVerified(true)
        setAvailableName(true)
        setNamesToDisplay(undefined)
      }
    }
  }

  const addImage = img => {
    setHotelImagenes(prevState => [...prevState, img])
  }

  const removeImageList = ({ target }) => {
    setHotelImagenes(hotelImagenes.filter(imageUrl => imageUrl !== target.id))
  }

  return (
    <PageContainer>
      <FormProvider>
        <PseudoBox as='form' className='create-packages' onSubmit={handleSubmit(onSubmit)}>
          {hotel.isLoading ? (
            <Spinner />
          ) : (
            <>
              {/* Destino */}
              <Row style={{ color: '#1f408f', justifyContent: 'end', fontSize: '1rem' }}>{t('forms:sugerenceMsg')}</Row>
              <Box mb='4'>
                <FormLabel isRequired mr='1'>
                  {t('forms:destination')}
                </FormLabel>
                <Controller
                  name='localidadId'
                  control={control}
                  render={() => (
                    <Select
                      style={{ display: 'block' }}
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                      id='localidadId'
                      name='localidadId'
                      onChange={handleLocalidadId}
                      defaultValue={hotel.data?.localidadId || undefined}
                      showSearch
                      placeholder={t('forms:search')}
                      filterOption={false}
                      onSearch={handleSearchDestinations}
                      notFoundContent={null}
                      ref={register}
                    >
                      {cityDestinations.items?.map(({ id, nombre, provincia }) => {
                        const label = `${provincia.pais.nombre} | ${
                          provincia && provincia.nombre ? provincia.nombre : 'Unknown'
                        } | ${nombre}`
                        return (
                          <Option key={id} value={id}>
                            {label}
                          </Option>
                        )
                      })}
                    </Select>
                  )}
                />
              </Box>

              <Row style={{ alignItems: 'flex-end', gap: '1rem' }}>
                <Box mb={4} flex={1}>
                  <FormLabel isRequired mr='1'>
                    {t('forms:name')}
                  </FormLabel>
                  <Controller
                    name='name'
                    control={control}
                    render={() => (
                      <Input
                        style={{
                          paddingRight: '0.1rem',
                        }}
                        isDisabled={!localidadIsSelected}
                        type='text'
                        isRequired={true}
                        name='name'
                        onChange={handleNameInput}
                        placeholder={t('forms:name')}
                        ref={register}
                      />
                    )}
                  />
                </Box>
                {((idHotel && (!almostVerified || !availableName || !!namesToDisplay)) || !idHotel) && (
                  <Button
                    mb={4}
                    minWidth='13rem'
                    variantColor={
                      verifying
                        ? 'blue'
                        : almostVerified
                        ? availableName
                          ? !!namesToDisplay
                            ? 'orange'
                            : 'green'
                          : 'red'
                        : 'blue'
                    }
                    onClick={handleExistenceVerify}
                    variant='outline'
                    isDisabled={!localidadIsSelected}
                    size='md'
                    isLoading={formState.isSubmitting}
                  >
                    <span style={{ marginRight: '0.5rem' }}>
                      {verifying
                        ? t('forms:verifying')
                        : almostVerified
                        ? availableName
                          ? !!namesToDisplay
                            ? t('forms:warning')
                            : t('forms:available')
                          : t('forms:notAvailable')
                        : t('forms:existenceVerify')}
                    </span>
                    {verifying ? (
                      <Spinner size='sm' />
                    ) : almostVerified ? (
                      availableName ? (
                        !!namesToDisplay ? (
                          <Icon name='warning' />
                        ) : (
                          <Icon name='check' />
                        )
                      ) : (
                        <Icon name='not-allowed' />
                      )
                    ) : (
                      <Icon name='search' />
                    )}
                  </Button>
                )}
              </Row>
              {almostVerified && (
                <>
                  {availableName ? (
                    <>
                      {namesToDisplay?.length && (
                        <>
                          <p style={{ fontWeight: 600 }}>{`${t('hotels:similarHotelsFoundedMsg')} ${
                            namesToDisplay.length
                          } ${t('hotels:similarHotelsMsg')}`}</p>
                          <ul
                            style={{
                              overflow: 'auto',
                              margin: '0.5rem 0rem',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '0.5rem',
                              maxHeight: '16.5rem',
                              padding: '0.5rem',
                              boxShadow: 'inset 0px 0px 0.25rem #00000050',
                              borderRadius: '0.25rem',
                            }}
                          >
                            {namesToDisplay.map((hotelName, index) => (
                              <Tag key={hotelName + index} style={{ overflowX: 'auto' }}>
                                - {hotelName}
                              </Tag>
                            ))}
                          </ul>
                          {!!idHotel ? (
                            <p style={{ textAlign: 'center' }}>{t('hotels:ifCreatedMsgOnEdit')}</p>
                          ) : (
                            <>
                              <p style={{ textAlign: 'center' }}>{t('hotels:ifCreatedMsg')}</p>
                              <p style={{ textAlign: 'center' }}>{t('hotels:editReminderMsg')}</p>
                            </>
                          )}
                          <Row style={{ justifyContent: 'center', gap: '2rem', marginTop: '2rem' }}>
                            <Button
                              mb={4}
                              minWidth={'12rem'}
                              variantColor='blue'
                              variant='outline'
                              onClick={() => setNamesToDisplay(undefined)}
                              size='md'
                            >
                              {t('forms:noMatchContinue')}
                            </Button>
                            <Button
                              mb={4}
                              minWidth='12rem'
                              variantColor='red'
                              variant='outline'
                              onClick={onClose}
                              size='md'
                            >
                              {t('forms:alreadyRegisteredCancel')}
                            </Button>
                          </Row>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <b>{t('forms:notAvailableMsg')}</b>
                      <br />
                      {t('forms:sugerenceMsg')}
                    </>
                  )}
                </>
              )}
              {(!!idHotel || (!idHotel && almostVerified && availableName && !namesToDisplay)) && (
                <>
                  <Box mb='4'>
                    <FormLabel>{t('forms:category')}</FormLabel>
                    <Controller
                      control={control}
                      name='category'
                      render={() => (
                        <Rating
                          inputName='category'
                          size={24}
                          icon='star'
                          scale={5}
                          fillColor={theme.colors.blue[500]}
                          strokeColor={theme.colors.blue[500]}
                          rating={hotelCategory}
                          setValue={setValue}
                          defaultValue={hotel.data?.category || undefined}
                        />
                      )}
                    />
                  </Box>
                  <Box alignItems='center' mb='4'>
                    <FormLabel isRequired mr='1'>
                      {t('forms:description')}
                    </FormLabel>
                    <Controller
                      control={control}
                      name='description'
                      render={() => (
                        <Textarea
                          isRequired
                          placeholder={t('forms:description')}
                          name='description'
                          onChange={handleEventInput}
                          ref={register}
                          maxLength={1000}
                          defaultValue={hotel.data?.description || ''}
                        />
                      )}
                    />
                  </Box>
                  <Box alignItems='center' mb={4}>
                    <Controller
                      name='hotelImages'
                      control={control}
                      render={() => (
                        <>
                          <ImageInputV2
                            buttonDisabled={hotelImagenes.length >= 5}
                            name='hotelImages'
                            label={t('forms:image')}
                            setValue={(_name, url) => {
                              addImage(url)
                            }}
                            value={image || undefined}
                            onChange={value => setImage(value)}
                            inputProps={{
                              placeholder: `URL ${t('forms:image')}`,
                              ref: register,
                              type: 'text',
                            }}
                          />
                          <Box pt={4}>
                            <Grid templateColumns='repeat(5, 1fr)' gap={6}>
                              {hotelImagenes?.map(hotelImagen => (
                                <Box key={hotelImagen} style={{ position: 'relative' }}>
                                  <Image
                                    size='100px'
                                    objectFit='cover'
                                    src={hotelImagen}
                                    style={{ position: 'relative' }}
                                  />
                                  <Button
                                    id={hotelImagen}
                                    style={{
                                      position: 'absolute',
                                      right: '1rem',
                                      top: '0.25rem',
                                      zIndex: 1,
                                      padding: '0.25rem',
                                      opacity: '0.8',
                                    }}
                                    onClick={removeImageList}
                                  >
                                    <IoMdTrash style={{ pointerEvents: 'none' }} onClick={e => e.stopPropagation()} />
                                  </Button>
                                </Box>
                              ))}
                            </Grid>
                          </Box>
                        </>
                      )}
                    />
                  </Box>
                  <Button
                    variantColor='blue'
                    variant='outline'
                    size='lg'
                    isLoading={formState.isSubmitting}
                    type='submit'
                  >
                    {idHotel ? t('forms:saveChanges') : t('hotels:createHotel')}
                  </Button>
                </>
              )}
              {/* Guardar Button */}
            </>
          )}
        </PseudoBox>
      </FormProvider>
    </PageContainer>
  )
}
