import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Flex, Heading, Stack, useDisclosure, useToast } from '@chakra-ui/core'
import { PageContainer } from '../../common/helpers'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../common/helpers'
import SupplierCollection from './supplier-collection'
import { getSuppliersByCountry } from '../../../api/intranet'
import { useSelector } from 'react-redux'
import { getUserApplication, getUserInformation, getUserRolId } from '../../../redux/selectors'
import ModalSimple from '../../common/modal-simple'
import Agreements from './modal-view/agreements'
import Information from './modal-view/information'
import { toastCustomizedError } from '../../common/toast-default-config'
import AgreementsSearchForm from './agreements-search-form'
import SupplierAgreementsComparative from './supplier-agreements-comparative'
import Pagination from '../../common/pagination'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from '.'
import { SUPPLIERS_LOGIN_COUNTER } from '../../../helpers/permissions'
import { getParametros } from '../../../api/loginUnico'

const SuppliersAgreementsContent = () => {
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()
  const userInfo = useSelector(getUserInformation)
  const [dataCounter, setDataCounter] = useState([])
  const [parametros, setParametros] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userRolId = useSelector(getUserRolId)
  const { t } = useTranslation(['suppliers', 'commons', 'forms', 'auto-login'])
  const permisosUser = userRolId == 1 ? true : false
  const { paisId: countryId } = useSelector(getUserApplication)
  const [modalConfig, setModalConfig] = useState({
    title: undefined,
    type: undefined,
    id: undefined,
  })
  const toast = useToast()
  const [totalPages, setTotalPages] = useState(0)

  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)

  const removeQueryParameter = param => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete(param)

    const newUrl = window.location.pathname
    window.history.replaceState(null, '', newUrl)
  }

  const cargarParametros = async () => {
    try {
      if (SUPPLIERS_LOGIN_COUNTER) {
        const response = await getParametros()
        if (response.data) setParametros(response.data)
      }
    } catch (error) {
      setParametros(undefined)
      console.log(error)
    }
  }

  const getSuppliers = async (reset = false) => {
    const urlParams = new URLSearchParams(window.location.search)

    let nombre = filters.nombre
    if (urlParams.size) {
      nombre = urlParams.get('name')
      removeQueryParameter('name')
    }

    const filtersAux = reset ? { ...defaultFiltersValues } : { ...filters, nombre }
    setLastFilters(filtersAux)
    handleSectionData(SECTION_DATA_KEYS.suppliers, {
      ...sectionData.suppliers,
      isLoading: true,
    })
    try {
      let response

      response = await getSuppliersByCountry({
        ...filtersAux,
        maxPerPage: 20,
        paisId: permisosUser ? filtersAux.paisId : countryId,
        soloAdmin: permisosUser ? null : false,
      })
      setTotalPages(response.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.suppliers, {
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.suppliers, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getSuppliers()
      }
    }
  }

  useEffect(() => {
    getSuppliers()
  }, [filters.page])

  function handleOpenModal(config) {
    setModalConfig(config)
  }

  useEffect(() => {
    if (modalConfig.id) {
      onOpen()
    }
  }, [modalConfig])

  useEffect(() => {
    if (resetFilters !== 0) {
      handleResetSectionData()
      getSuppliers(true)
    }
    if (!permisosUser) filters.paisId = countryId
  }, [resetFilters])

  useEffect(() => {
    if (userRolId === 5) {
      const filteredCounter = parametros?.filter(p => p.usuarioId === userInfo.id)
      setDataCounter(filteredCounter)
    }
  }, [parametros])

  useEffect(() => {
    cargarParametros()
  }, [sectionData.suppliers])

  return (
    <>
      {!!isOpen && (
        <ModalSimple isOpen={isOpen} onOpen={onOpen} onClose={onClose} title={modalConfig.title} size='6xl'>
          {modalConfig.type === 'agreements' ? <Agreements id={modalConfig.id} /> : ''}
          {modalConfig.type === 'information' ? <Information id={modalConfig.id} /> : ''}
        </ModalSimple>
      )}
      <PageContainer>
        <Box backgroundColor='' py='16px' color='#103059'>
          <Heading style={{ fontSize: '1.8rem' }}>{t('common:navbar.providers')}</Heading>
        </Box>
        <Flex style={{ flexDirection: 'column' }}>
          <Panel rounded='md' mb='1.5rem'>
            <AgreementsSearchForm onFilter={onFilter} />
          </Panel>
          <Stack spacing='40px'>
            {!!sectionData.suppliers.isLoading && userRolId !== 5 ? (
              <Flex align='center' justifyContent='center' mt='40px'>
                <CircularProgress isIndeterminate />
              </Flex>
            ) : (
              <>
                {!!sectionData.suppliers.data && userRolId !== 5 && (
                  <>
                    <SupplierCollection items={sectionData.suppliers.data} handleOpenModal={handleOpenModal} parametros={parametros}/>
                    <SupplierAgreementsComparative items={sectionData.suppliers.data} />
                  </>
                )}
                {!!sectionData.suppliers.error && (
                  <Heading as='h3' size='lg' color='gray.500' mt='40px'>
                    {t('common:errorGettingItems')}
                  </Heading>
                )}
              </>
            )}

            {!!sectionData.suppliers.isLoading && userRolId === 5 ? (
              <Flex align='center' justifyContent='center' mt='40px'>
                <CircularProgress isIndeterminate />
              </Flex>
            ) : (
              <>
                {SUPPLIERS_LOGIN_COUNTER &&
                  userRolId === 5 &&
                  dataCounter?.length > 0 &&
                  sectionData?.suppliers?.data?.filter(s =>
                    dataCounter?.find(d => d?.proveedorLoginUnico?.proveedor?.id === s.id),
                  ) && (
                    <>
                      <SupplierCollection
                        items={sectionData.suppliers.data.filter(s =>
                          dataCounter.find(d => d?.proveedorLoginUnico?.proveedor?.id === s.id),
                        )}
                        handleOpenModal={handleOpenModal}
                        parametros={parametros}
                      />
                    </>
                  )}
                {SUPPLIERS_LOGIN_COUNTER &&
                  userRolId === 5 &&
                  !sectionData?.suppliers?.data.filter(s =>
                    dataCounter?.find(d => d?.proveedorLoginUnico?.proveedor?.id === s.id),
                  ).length && (
                    <Flex align='center' justifyContent='center' mt='40px'>
                      {t('auto-login:noAutoLogin')}
                    </Flex>
                  )}
              </>
            )}
          </Stack>
        </Flex>
        {!!totalPages && <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />}
      </PageContainer>
    </>
  )
}

export default SuppliersAgreementsContent
