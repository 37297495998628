//App
export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const LOG_OUT = "LOG_OUT";

//Banners
export const BANNERS_GET_CATEGORIES = "BANNERS_GET_CATEGORIES"
export const BANNERS_RESET_DATA = "BANNERS_RESET_DATA";
export const BANNERS_UPDATE_KEY = "BANNERS_UPDATE_KEY"; 

//Package
export const PACKAGE_GET = "PACKAGE_GET"
export const PACKAGE_RESET_DATA = "PACKAGE_RESET_DATA";
export const PACKAGE_UPDATE_KEY = "PACKAGE_UPDATE_KEY"; 

//Suppliers
export const SUPPLIERS_UPDATE_KEY = "SUPPLIERS_UPDATE_KEY";
export const SUPPLIERS_RESET_DATA = "SUPPLIERS_RESET_DATA";