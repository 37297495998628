import React, { useState, useEffect } from 'react'
import { useMemo } from 'react'
import { PageContainer, Panel } from '../../common/helpers'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAuthToken, getToken, getUserInformation } from '../../../redux/selectors'
import { PackageSearchForm } from './PackageSearchForm'
import { Box, Button, Heading, Link, Spinner, Stack, Text, useToast } from '@chakra-ui/core'
import { updatePackageEnable, deletePackage, getPackagesByQuery } from '../../../api/intranet/requests'
import { ButtonDelete, ButtonEdit, CommonButton } from '../../forms/buttons/edit'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import { PACKAGE_CREATE } from '../../../helpers/permissions'
import { deconstructPermission } from '../../../helpers/permissions-functions'
import { Link as RouterLink } from 'react-router-dom'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import moment from 'moment'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './package-section-data'

import Pagination from '../../common/pagination'
import Table from '../../common/table'
import { isSuccess } from '../../../helpers/utils'
import { useSectionData } from '../../../hooks/useSectionData'

export const Package = () => {
  const { t } = useTranslation(['common', 'forms', 'packages', 'consultations'])
  const authToken = useSelector(getAuthToken)
  const token = useSelector(getToken)
  const toast = useToast()
  const user = useSelector(getUserInformation)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const [totalPages, setTotalPages] = useState(0)

  const {
    filters,
    handleFilters,
    sectionData,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  async function getAllPackagesByQuery(reset = false) {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)
    handleSectionData(SECTION_DATA_KEYS.package, {
      ...sectionData.package,
      isLoading: true,
    })
    try {
      const res = await getPackagesByQuery(filtersAux)

      setTotalPages(res.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.package, {
        data: res.data.data,
        isLoading: false,
        error: null
      })
    } catch (error) {
      console.log('error', error)
      handleSectionData(SECTION_DATA_KEYS.package, {
        isLoading: false,
        error: true
      })
    }
  }

  //HABILITAR PAQUETE
  async function handleEnablePackage(datos, id) {
    try {
      const sesion = {
        token,
        ...deconstructPermission(PACKAGE_CREATE),
      }
      const data = {
        sesion,
        activo: !datos,
      }
      const response = await updatePackageEnable(id, data, authToken)
      toast(
        response.status
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
      getAllPackagesByQuery()
    } catch (error) {
      console.log('error', error)
      toast(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:agency'),
        accessor: row => {
          return row.applicationId
        },
      },
      {
        Header: t('forms:title'),
        accessor: row => {
          return row.title?.length > 65 ? `${row.title.substring(0, 65)}...` : row.title
        }
      },
      {
        Header: t('forms:origin'),
        accessor: 'originCode',
      },
      {
        Header: t('forms:destination'),
        accessor: row => {
          return row.paqueteDestinos.length === 1 ? row.paqueteDestinos[0].destinationName : `${row.paqueteDestinos.length} destinos`
        },
      },
      {
        Header: t('forms:validSince'),
        accessor: 'dateSince',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.values.dateSince ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.values.dateSince).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:validUntil'),
        accessor: 'dateUntil',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.values.dateUntil ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.values.dateUntil).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:active'),
        //accessor:"active"
        accessor: row => {
          if (row.activo === true) {
            return 'sí'
          } else {
            return 'no'
          }
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => {
          let label = ''
          if (cell.row.original.activo === true) {
            label = t('common:deactivate')
          } else {
            label = t('common:activate')
          }

          return (
            <Stack spacing='5px' isInline d='flex' justifyContent='left' mx='1'>
              <Link
                as={RouterLink}
                to={`/packages/departures/${cell.row.values.id}/${cell.row.original.title}/package`}
              >
                <CommonButton label={t('consultations:view') + ' ' + t('forms:departures')} variantColor='green' />
              </Link>

              <Link as={RouterLink} to={`/packages/edit/${cell.row.values.id}`}>
                <ButtonEdit />
              </Link>

              <ButtonDelete onClick={() => prepareDelete(cell.row.original)} />

              <CommonButton variantColor='orange' variant='outline' label={label} onClick={() => handleEnablePackage(cell.row.original.activo, cell.row.values.id)} />
            </Stack>
          )
        },
      },
    ],
    [t],
  )

  //DELETE FUNCTIONS
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
    dependencyFlag: false,
  })

  const onCloseDeleteAlert = () => {
    setDeleteAlert(state => ({ ...state, show: false, dependencyFlag: false }))
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
  }

  async function onDelete() {
    if (!deleteAlert.dependencyFlag && deleteAlert.data?.paqueteDestinos?.length) {
      setDeleteAlert({ ...deleteAlert, dependencyFlag: true })
      return
    }
    let sesion = deconstructPermission(PACKAGE_CREATE)
    sesion = {
      ...sesion,
      token: `${token}`,
    }

    try {
      const res = await deletePackage(deleteAlert.data.id, token)
      if (isSuccess(res.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        toastCustomizedError(
          t('common:error-ocurred'),
          `${t('packages:sendError')} - Server note: ${res.data.menssage}`
        )
      }
      getAllPackagesByQuery()
    } catch (error) {
      console.log(error)
      toast(toastCustomizedError(`${t('common:deleteError')}`, error))
    }
    onCloseDeleteAlert()
  }

  //FILTRAR
  function onFilter() {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getAllPackagesByQuery()
      }
    }
  }

  useEffect(() => {
    getAllPackagesByQuery()
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
    getAllPackagesByQuery(true)
  }, [resetFilters])

  return (
    <PageContainer>
      <Box backgroundColor='' py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {t('common:navbar.packages')}
        </Heading>
      </Box>
      <Link as={RouterLink} _hover={{ textDecoration: 'none' }} to='/packages/create'>
        <Button my={5} mr={3} variantColor='blue'>
          {t('packages:createPackage')}
        </Button>
      </Link>
      <>
        {/* Alert delete */}
        <AlertDialogSimple
          isOpen={deleteAlert.show}
          onClose={onCloseDeleteAlert}
          onAccept={onDelete}
          acceptLabel={t('common:delete')}
          delayConfirmation={true}
        >
          {t('common:deleting')} <br />
          <b>{deleteAlert.data && `${deleteAlert.data.title} #${deleteAlert.data.id}`}</b>
          {!!deleteAlert.data?.paqueteDestinos?.length && deleteAlert.dependencyFlag && <p>{t("common:deleteConfirmation")}</p>}
        </AlertDialogSimple>

        {/* Formulario */}
        <Panel rounded='md' mb='1.5rem'>
          <PackageSearchForm onFilter={onFilter} />
        </Panel>
        
        {!sectionData?.package?.isLoading && !!sectionData?.package?.data.length && <Table columns={columns} data={sectionData.package.data} />}
        {!sectionData?.package?.isLoading && !!totalPages && (
          <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} selectPage={false} />
        )}
        <Box bg='white' mb='20px' mt='8'>
          {sectionData.package.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}
          {!sectionData.package.isLoading && !sectionData.package.error && !sectionData.package.data?.length && (
            <Text textAlign='center' color='gray.500' px='5' py='10'>
              {t('common:zeroItems')}
            </Text>
          )}
          {sectionData.package.isLoading && !sectionData.package.items?.length && sectionData.package.error && (
            <Text textAlign='center' color='gray.500' px='5' py='10'>
              {t('common:errorGettingItems')}
            </Text>
          )}
        </Box>
      </>
    </PageContainer>
  )
}
