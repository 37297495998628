import axios from 'axios'
import { getStorageToken } from '../helpers/utils'
import { configWithParams } from './intranet'

// Headers

export const configWithToken = (authToken) => {
  const tokenAux = getStorageToken()
  return ({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
  },
})}

// - - - - - Endpoints General - - - - -
export const registroLoginEndpoint = '/api/registroLogin'

export const loginUnicoEndpoint = '/api/login-unico'

export const loginUnicoIdEndpoint = (id) => `/api/login-unico/${id}`

// - - - - - Endpoints Parametros - - - - -

export const obtenerParametrosEndpoint = '/api/parametros-login-unico/'

export const ObtenerParametrosIdEndpoint = (Id) => `/api/parametros-login-unico/${Id}`

// - - - - - Endpoints Claves - - - - -

export const clavesEndpoint = '/api/claves'

export const clavesIdEndpoint = (Id) => `/api/claves/${Id}`

// - - - - - - Requests GET - - - - - - -

export const getLoginUnicos = (authToken, data) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${loginUnicoEndpoint}`, configWithParams(data, authToken))

export const getLoginUnicosId = (id, authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${loginUnicoIdEndpoint(id)}`, configWithToken(authToken))

export const getParametros = (authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${obtenerParametrosEndpoint}`, configWithToken(authToken))

export const getParametrosId = (id, authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${ObtenerParametrosIdEndpoint(id)}`, configWithToken(authToken))

export const getObtenerClaves = (authToken, data) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${clavesEndpoint}`, configWithParams(data, authToken))

export const getObtenerClavesId = (id, authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${clavesIdEndpoint(id)}`, configWithToken(authToken))

// - - - - - - Requests POST - - - - - - -

export const postLoginUnicos = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${loginUnicoEndpoint}`, data, configWithToken(authToken))

export const postCrearParametro = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${obtenerParametrosEndpoint}`, data, configWithToken(authToken))

export const postCrearClave = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${clavesEndpoint}`, data, configWithToken(authToken))

export const postLoginNezasa = (url, data) =>
  axios.post(`${url}`, data, configWithToken())

export const postRegistroLogin = (data) =>
  axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${registroLoginEndpoint}`, data, configWithToken())

// - - - - - - Requests PUT - - - - - - -

export const putLoginUnicos = (id, data, authToken) =>
  axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${loginUnicoIdEndpoint(id)}`, data, configWithToken(authToken))

export const putActualizarParametro = (id, data, authToken) =>
  axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${ObtenerParametrosIdEndpoint(id)}`, data, configWithToken(authToken))

export const putActualizarClave = (id, data, authToken) =>
  axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${clavesIdEndpoint(id)}`, data, configWithToken(authToken))

// - - - - - - Requests DELETE - - - - - - -

export const deleteLoginUnico = (id, authToken) =>
  axios.delete(`${process.env.REACT_APP_INTRANET_API_V2}${loginUnicoIdEndpoint(id)}`, configWithToken(authToken))

export const deleteClave = (id, authToken) =>
  axios.delete(`${process.env.REACT_APP_INTRANET_API_V2}${clavesIdEndpoint(id)}`, configWithToken(authToken))

export const deleteParametro = (id, authToken) =>
  axios.delete(`${process.env.REACT_APP_INTRANET_API_V2}${ObtenerParametrosIdEndpoint(id)}`, configWithToken(authToken))