import React, { useEffect, useState } from 'react'
import FormInput from '../form-input'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Button, PseudoBox, SimpleGrid, Box, FormLabel } from '@chakra-ui/core'
import SelectAsync from '../../common/select-async'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../redux/selectors'
import { getUnitsMeasurement } from '../../../api/incentives'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'

function SupplierIncentivesForm({ formData = false, buttonLabel = false, submitForm }) {
  // - - - ⭕ GENERALS - - -

  const { t } = useTranslation(['forms', 'exchange'])
  const authToken = useSelector(getAuthToken)
  const [mesure, setMesure] = useState(false)

  // - - - 📥 HOOKS 📥- - -

  const { register, handleSubmit, control, formState, reset } = useForm({
    defaultValues: formData,
  })

  // - - -📆 COMBO DATA PICKER 📆- - -

  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: moment(),
    endDate: null,
  })

  const [focusedInput, setFocusedPicker] = useState(null)

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
      }))
    }
  }, [startDate, endDate])

  // - - - -📞 API CALLS 📞- - - -

  async function getMeasurement() {
    try {
      const response = await getUnitsMeasurement(authToken)
      let mes = response.data
      setMesure(mes)
    } catch (error) {
      console.log(error)
    }
  }

  // - - - -👨‍👧 INFO SEND - PADRE 👨‍👧- - - -

  function onSubmit(date) {
    let start = moment(startDate).format('YYYY-MM-DD')
    let end = moment(endDate).format('YYYY-MM-DD')
    submitForm(date, start, end)
  }

  // - - - - 🏁 USE EFFECTS 🏁 -  - - -

  useEffect(() => {
    getMeasurement()
  }, [])

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ nombre: '', unidadMedicion: { id: 0 } })
      setRangePicker({ startDate: moment(), endDate: null })
    }
  }, [reset, formState])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
        {/* Nombre */}
        <FormInput
          isRequired
          label={t('forms:name')}
          name='nombre'
          inputProps={{
            placeholder: t('forms:name'),
            ref: register,
          }}
        />
        {/* Rango de Fecha */}
        <Box alignItems='center'>
          <FormLabel isRequired>{t('forms:validity')}</FormLabel>
          <Box>
            <DateRangePicker
              showDefaultInputIcon={true}
              isOutsideRange={() => false}
              required
              startDate={startDate}
              startDateId='fechaInicio'
              endDate={endDate}
              endDateId='fechaFin'
              focusedInput={focusedInput}
              onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
              onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
              small
              orientation='horizontal'
              displayFormat='DD/MM/YYYY'
            />
          </Box>
        </Box>
        {/* Medicion */}
        <Box alignItems='center'>
          <FormLabel isRequired>{t('forms:measurementMethod')}</FormLabel>
          <Controller
            control={control}
            name='unidadMedicion.id'
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => onChange(e.target.value)}
                placeholder={`${t('exchange:currency')}`}
                name={name}
                value={value}
                isRequired={true}
              >
                {mesure !== false
                  ? mesure.map(x => (
                      <option key={x.id} value={x.id}>
                        {x.descripcion} {x.simbolo}
                      </option>
                    ))
                  : null}
              </SelectAsync>
            )}
          />
        </Box>
        <Box style={{ display: 'flex', justifyContent: 'start', alignItems: 'end' }}>
          <Button variantColor='blue' variant='outline' type='submit'>
            {t('forms:save')}
          </Button>
        </Box>
      </SimpleGrid>
    </PseudoBox>
  )
}

export default SupplierIncentivesForm
