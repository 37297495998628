import { Box, Button, Flex, Icon } from '@chakra-ui/core'
import React, { useState } from 'react'
import FormInput from '../forms/form-input'
import { useTranslation } from 'react-i18next'
import { checkUrlValidity } from '../../helpers/utils'

function MediaCreateForm({ onCreate }) {
  const { t } = useTranslation(['comunications', 'common', 'forms'])
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')

  async function handleCreate() {
    if (!checkUrlValidity(inputValue)) return


    setIsLoading(true)
    await onCreate(inputValue)
    setInputValue('')
    setIsLoading(false)
  }

  const actions = {
    handleCreate,
  }

  return (
    <Flex justifyContent='space-between' mb='2rem'>
      <Box flex={3}>
        <FormInput
          style={{ marginRight: '4px' }}
          name='link'
          inputProps={{
            type: 'text',
            placeholder: `${t('comunications:url')} ${t('common:media')}`,
            value: inputValue,
            onChange: (e) => setInputValue(e.target.value)
          }}
        />
      </Box>

      <Box flex={1} display={'flex'} flexDirection={'horizontal'}>
        <ActionButtons actions={actions} isLoading={isLoading}/>
      </Box>
    </Flex>
  )
}

function ActionButtons({ actions, isLoading }) {
  
    return (
      <Button variantColor='blue' onClick={actions.handleCreate} ml={2} isDisabled={isLoading}>
        <Icon name='add' />
      </Button>
    )
}

export default MediaCreateForm
