import React, { createContext, useEffect, useState } from 'react'

const defaultValues = {
  resetFilters: 0,
  resetData: 0,
}

const SectionDataContext = createContext(defaultValues)

const SectionDataProvider = ({ children, defaultFilters, defaultSectionData }) => {
  const [filters, setFilters] = useState(defaultFilters)
  const [sectionData, setSectionData] = useState(defaultSectionData)
  const [resetFilters, setResetFilters] = useState(defaultValues.resetFilters)
  const [resetSectionData, setResetSectionData] = useState(defaultValues.resetData)
  const [lastFilters, setLastFilters] = useState(defaultFilters)

  const handleFilters = (key, value) => {
    setFilters(state =>
      value ? { ...state, [key]: value } : Object.fromEntries(Object.entries(state).filter(item => item[0] !== key)),
    )
  }
  const handleSectionData = (key, value) => {
    setSectionData(state =>
      value ? { ...state, [key]: value } : Object.fromEntries(Object.entries(state).filter(item => item[0] !== key)),
    )
  }

  const handleResetFilters = () => setResetFilters(state => state + 1)
  const handleResetSectionData = () => setResetSectionData(state => state + 1)

  useEffect(() => {
    if (!!resetFilters) {
      setFilters(defaultFilters || defaultValues.filters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFilters])

  useEffect(() => {
    if (!!resetSectionData) {
      setSectionData(defaultSectionData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSectionData])

  const values = {
    filters,
    setFilters,
    sectionData,
    setSectionData,
    handleFilters,
    handleSectionData,
    handleResetFilters,
    handleResetSectionData,
    resetFilters,
    resetSectionData,
    lastFilters,
    setLastFilters,
  }

  return <SectionDataContext.Provider value={values}>{children}</SectionDataContext.Provider>
}

export { SectionDataContext, SectionDataProvider }
