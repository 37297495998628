import { logoutUser } from '../api/application'
import { logOut } from '../redux/actions/app-actions'

const VALUE_TYPE_KEYS = {
  COLOR: 'COLOR',
  IMAGE: 'IMAGE',
  VALUE: 'VALUE',
  LINK: 'LINK',
  PAQUETE: 'PAQUETE'
}

const allowedRolesToEdit = ['Super Admin', 'Admin Country']

const logOutBrowser = () => {
  logOut()
  window.localStorage.clear()
  window.location.replace('/')
}

const handleLogout = async () => {
  await logoutUser()
  logOutBrowser()
}

function sketchPickerChangeColor(e, setColor, setValueValue) {
  setColor(e.rgb)
  const ColorString = `${e.rgb.r},${e.rgb.g},${e.rgb.b}`
  setValueValue(ColorString)
}

const handleInputState = (key, value, prevState, setState) => {
  setState({ ...prevState, [key]: value || null })
}

export { VALUE_TYPE_KEYS, sketchPickerChangeColor, allowedRolesToEdit, handleLogout, logOutBrowser, handleInputState }
