import React, { useEffect, useState } from 'react'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { useTranslation } from 'react-i18next'
import { FormLabel } from '@chakra-ui/core'
import moment from 'moment'
import { GridItem } from '@chakra-ui/react'


export const PublicationDates = ({ onchangeDates, packageId, state }) => {
  const { t } = useTranslation(['common', 'forms', 'packages'])

  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: packageId ? (state.dateSince ? moment(state.dateSince) : moment()) : moment(),
    endDate: packageId ? (state.dateUntil ? moment(state.dateUntil) : undefined) : undefined,
  })

  useEffect(() => {
    if (startDate === null) {
      setRangePicker((state) => ({
        ...state,
        startDate: moment(),
      }))
    }

    onchangeDates({
      dateSince: moment(startDate).toISOString(),
      dateUntil: moment(endDate).toISOString(),
    })
  }, [startDate, endDate])

  const [focusedInput, setFocusedInput] = useState(null)

  function setFocusedPicker(focusedInput) {
    setFocusedInput(() => focusedInput)
  }

  return (
    <GridItem display="flex" flexDirection="column" colSpan={1}>
      {/* Vigencia (Data Picker) */}
        <FormLabel isRequired  mr="1">{t('forms:validity')}</FormLabel>
        <DateRangePicker
          required
          startDate={startDate}
          startDateId="fechaInicio"
          endDate={endDate}
          endDateId="fechaFin"
          focusedInput={focusedInput}
          onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
          onFocusChange={(focusedInput) => setFocusedPicker(focusedInput)}
          small
          orientation="horizontal"
          displayFormat="DD/MM/YYYY"
        />
    </GridItem>
  )
}
