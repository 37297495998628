import axios from 'axios'
import { getStorageToken } from '../helpers/utils'

export const configWithToken = (authToken) => {
  const tokenAux = getStorageToken()
  return ({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
  },
})}

export const configWithParams = (data, token) => {
  const tokenAux = getStorageToken()
  return ({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
  },
  params: data,
})}

// Endpoints
// Comunicados
export const comunicadosEndpoint = '/api/comunicados'

export const comunicadosEndpointAll = '/api/comunicados/all'

export const comunicadosEndpointComunications = '/api/comunicados/communications'

// Permisos
export const permisosEndpoint = '/api/permisos'

// Categorias
export const categoriasComunicadosEndpoint = '/api/categorias-comunicados'

// URL Video
export const urlVideoEndpoint = '/api/url-video'
export const mediaEndpoint = '/api/comunicados-media'

// - - - - - - Comunicados - - - - - - -

export const obtenerComunicados = (authToken, data) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${comunicadosEndpoint}`, configWithParams(data, authToken))

  export const obtenerComunicadosAll = (authToken, data) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${comunicadosEndpointAll}`, configWithParams(data, authToken))


export const obtenerComunicadosFiltered = (authToken, data) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${comunicadosEndpointComunications}`, configWithParams(data, authToken))

export const obtenerComunicadosPorId = (id, authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${comunicadosEndpoint}/${id}`, configWithToken(authToken))

export const crearComunicados = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${comunicadosEndpoint}`, data, configWithToken(authToken))

export const actualizarComunicados = (id, data, authToken) =>
  axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${comunicadosEndpoint}/${id}`, data, configWithToken(authToken))

export const deleteComunicado = (id, authToken) =>
  axios.delete(`${process.env.REACT_APP_INTRANET_API_V2}${comunicadosEndpoint}/${id}`, configWithToken(authToken))

// - - - - - - Permisos por Rol - - - - - - -

export const obtenerPermisosPorRol = (authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${permisosEndpoint}`, configWithToken(authToken))

export const obtenerPermisosPorRolId = (rolId, authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${permisosEndpoint}/rol/${rolId}`, configWithToken(authToken))

export const crearPermisosPorRol = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${permisosEndpoint}`, data, configWithToken(authToken))

export const actualizarPermisosPorRolId = (id, data, authToken) =>
  axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${permisosEndpoint}/${id}`, data, configWithToken(authToken))

export const eliminarPermisosPorRolId = (id, authToken) =>
  axios.delete(`${process.env.REACT_APP_INTRANET_API_V2}${permisosEndpoint}/${id}`, configWithToken(authToken))

// - - - - - - Categorias - - - - - - -

export const obtenerCategorias = (paisId, authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${categoriasComunicadosEndpoint}`, configWithParams({ paisId }, authToken))

export const obtenerCategoriasPorId = (id, authToken) =>
  axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${categoriasComunicadosEndpoint}/${id}`, configWithToken(authToken))

export const crearCategoria = (data, authToken) =>
  axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${categoriasComunicadosEndpoint}`, data, configWithToken(authToken))

export const actualizarCategoria = (id, data, authToken) =>
  axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${categoriasComunicadosEndpoint}/${id}`, data, configWithToken(authToken))

export const deleteCategoria = (id, authToken) =>
  axios.delete(`${process.env.REACT_APP_INTRANET_API_V2}${categoriasComunicadosEndpoint}/${id}`, configWithParams({ id }, authToken))

// - - - - - - URL Video - - - - - - - 

export const crearUrlVideo = (data, authToken) =>
axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${urlVideoEndpoint}`, data, configWithToken(authToken))

export const obtenerUrlVideo = (paisId, authToken) =>
axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${urlVideoEndpoint}/pais/${paisId}`, configWithToken(authToken))

export const actualizarUrlVideo = (id, data, authToken) =>
axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${urlVideoEndpoint}/${id}`, data, configWithToken(authToken))

export const obtenerListaDeMedia = (paisId, authToken) =>
axios.get(`${process.env.REACT_APP_INTRANET_API_V2}${mediaEndpoint}/pais/${paisId}`, configWithToken(authToken))

export const crearMedia = (data, authToken) =>
axios.post(`${process.env.REACT_APP_INTRANET_API_V2}${mediaEndpoint}/`, data, configWithToken(authToken))

export const actualizarMedia = (mediaId, data, authToken) =>
axios.put(`${process.env.REACT_APP_INTRANET_API_V2}${mediaEndpoint}/${mediaId}`, data, configWithToken(authToken))

export const eliminarMedia = (mediaId, authToken) =>
axios.delete(`${process.env.REACT_APP_INTRANET_API_V2}${mediaEndpoint}/${mediaId}`, configWithToken(authToken))


