import { Box, Button, Grid, Select, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS } from './rubro-section-data'
import FormInput from '../../forms/form-input'
import { getAllCountries } from '../../../api/application'
import { toastCustomizedError } from '../../common/toast-default-config'
import SelectAsync from '../../common/select-async'

export default function RubroSearchForm({ onFilter, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const { t } = useTranslation(['common', 'forms', 'consultations', 'comunications'])
  const toast = useToast()
  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
  })

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries()
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} {...restProps}>
      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.nombre, e.target.value)}
        value={filters.nombre}
        name='nombre'
        inputProps={{
          placeholder: `${t('forms:name')} (${t('common:optional')})`,
        }}
      />

      <Box>
        <SelectAsync
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.paisId, target.value)
          }}
          placeholder={`${t('forms:countries')} - ${t('forms:all')}`}
          value={filters.paisId}
          isLoading={countries.isLoading}
        >
          {countries.data
            .sort((a, b) => a.nombre.localeCompare(b.nombre))
            .map(({ id, nombre }) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
        </SelectAsync>
      </Box>
      <Box style={{ display: 'flex', gap: '1rem' }}>
        <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>

        <Button variantColor='blue' variant='outline' onClick={handleResetFilters}>
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
