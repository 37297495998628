import React, { useMemo, useState, useEffect } from 'react'
import { PageContainer } from '../../../common/helpers'
import { useTranslation } from 'react-i18next'
import { useAsyncToastNotification, usePermission } from '../../../hooks'
import Table from '../../../common/table'
import { useSelector } from 'react-redux'
import {
  getToken,
  getAuthToken,
  getUserCountryId,
  getUserInformation,
  getUserApplicationId,
  getUserRolId,
} from '../../../../redux/selectors'
import { useToast, Stack, Box, Text, Spinner, Flex } from '@chakra-ui/core'
import { deconstructPermission } from '../../../../helpers/permissions-functions'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import { ButtonDelete } from '../../../forms/buttons/edit'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import {
  getUbicationById,
  createUbication,
  deleteUbication,
  patchCategoriaUbicacionSeccionShowState,
} from '../../../../api/intranet/marca-blanca/banner'
import { BANNERS_CREATE } from '../../../../helpers/permissions'
import { UbicationForm } from './UbicationForm'
import { useParams } from 'react-router-dom'
import { getCustomSections, getDefaultSections } from '../../../../api/section'
import { isSuccess } from '../../../../helpers/utils'
import { Switch } from 'antd'
import RemoteSwitch from '../../../common/remote-switch'

export default function Ubication({ categoryId }) {
  const { addPermissions } = usePermission()
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const userCountryId = useSelector(getUserCountryId)
  const user = useSelector(getUserInformation)
  const appId = useSelector(getUserApplicationId)
  const [items, setItems] = useState()
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId === 2 ? true : false

  //TRAER TODAS LAS UBICACIONES
  const [{ isLoadingUbications, ubications }, setUbications] = useState({
    isLoadingUbications: false,
    ubications: [],
  })
  const [errorUbi, setErrorUbi] = useState(false)

  async function getAllUbications() {
    try {
      setUbications(state => ({
        ...state,
        isLoadingUbications: true,
      }))

      const res = await getUbicationById(authToken, categoryId)
      setUbications(state => ({
        ...state,
        isLoadingUbications: false,
        ubications: res.data,
      }))
    } catch (error) {
      setErrorUbi(true)
      setUbications(state => ({
        ...state,
        isLoadingUbications: false,
      }))
    }
  }

  //TRAER TODAS LAS SECCIONES
  const [{ combinedSections }, setCombSections] = useState({
    isLoadingCombS: false,
    combinedSections: [],
  })

  async function getAllSections() {
    let customSectionsList = []
    let defaultSectionsList = []

    setCombSections(state => ({
      ...state,
      isLoadingCombS: true,
    }))

    await getCustomSections({ empresaId: appId })
      .then(res => {
        customSectionsList = res.data.filter(s => s.tipoSeccion.nombre === 'BANNER')
      })
      .catch(error => console.log('ErrorSesion', error))

    if (permisosUserCountry) {
      await getDefaultSections({ paisId: userCountryId, authToken })
        .then(res => {
          defaultSectionsList = res.data
            .map(i => {
              return { ...i, esDefault: true }
            })
            .filter(s => s.tipoSeccion.nombre === 'BANNER')
        })
        .catch(error => console.log('ErrorSesion', error))
    }

    setCombSections(state => ({
      ...state,
      isLoadingCombS: false,
      combinedSections: permisosUserCountry ? [...customSectionsList, ...defaultSectionsList] : customSectionsList,
    }))
  }
  useEffect(() => {
    getAllSections()
    getAllUbications()
  }, [])

  // AGREGO DESCRIPCION DE SECCIONES
  const [dataReady, setDataReady] = useState(false)

  useEffect(() => {
    if (ubications.length > 0 && combinedSections.length > 0) {
      setDataReady(true)
    }
  }, [ubications, combinedSections])

  useEffect(() => {
    if (dataReady) {
      setItems(
        ubications?.map(ubi => {
          const desc_sec = combinedSections?.find(i => i.id === parseInt(ubi.seccionId))
          return { ...ubi, desc_seccion: desc_sec?.descripcion !== undefined ? desc_sec.descripcion : '' }
        }),
      )
    }
  }, [dataReady, ubications])

  //CREAR UBICACION
  async function submitUbication(formData) {
    try {
      const sesion = {
        token,
        ...deconstructPermission(BANNERS_CREATE),
      }
      const data = { sesion, ...formData }
      const response = await createUbication(data, authToken)

      if (isSuccess(response.status)) {
        getAllUbications()
        getAllSections()
      }
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(error)
    }
  }

  function onSubmit(datos) {
    let formData
    if (datos.section) {
      const section = datos.section.split('-')
      formData = {
        categoriaBanner: { id: categoryId },
        default: section[1] === 'true' ? true : false,
        paisId: user.aplicacion.paisId,
        seccionId: parseInt(section[0]) || null,
        ubicacion: { id: parseInt(datos.ubication) },
        showHome: true,
      }
    } else {
      formData = {
        categoriaBanner: { id: categoryId },
        default: 0,
        paisId: user.aplicacion.paisId,
        seccionId: 0,
        ubicacion: { id: parseInt(datos.ubication) },
        showHome: true,
      }
    }

    submitUbication(formData)
  }

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })

  const onCloseDeleteAlert = () => {
    setDeleteAlert(state => ({ ...state, show: false }))
    getAllUbications()
    getAllSections()
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
  }

  async function onDelete() {
    const data = addPermissions(
      {
        Category_id: parseInt(deleteAlert.data.id),
      },
      BANNERS_CREATE,
    )
    await asyncNotificationWrapper(async () => {
      const response = await deleteUbication(deleteAlert.data.id, authToken)
      getAllUbications()
      return response.status
    })
    onCloseDeleteAlert()
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'ubicacion.id',
      },
      {
        Header: t('common:ubication'),
        accessor: row => row.ubicacion?.nombre,
      },
      {
        Header: t('common:section'),
        accessor: row => row.desc_seccion,
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ row }) => {
          const { createdAt, ...switchData } = row.original
          return (
            <Stack spacing='1rem' isInline d='flex' alignItems='center'>
              <ButtonDelete onClick={() => prepareDelete(row.original)} />
              <RemoteSwitch
                apiCall={patchCategoriaUbicacionSeccionShowState}
                propertyKey='showHome'
                data={switchData}
                initialState={!!switchData.showHome}
              />
            </Stack>
          )
        },
      },
    ],
    [t],
  )

  return (
    <PageContainer>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.ubicacion?.descripcion} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>

      {/* Formulario */}
      <UbicationForm onSubmit={onSubmit} combinedSections={combinedSections} />

      {/* Tabla */}
      {!isLoadingUbications && ubications && items && (
        <Box mt='8'>
          <Table
            columns={columns}
            //data={ubications}
            data={items}
          />
        </Box>
      )}

      <Box bg='white' mb='20px'>
        {isLoadingUbications && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!isLoadingUbications && !errorUbi && !ubications && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!isLoadingUbications && !ubications && errorUbi && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
      </Box>
    </PageContainer>
  )
}
