import React from 'react'
import { SectionDataProvider } from '../../../context/SectionDataContext'
import { Consultations } from '.'

const FILTER_KEYS = {
  paisId: 'paisId',
  agenciaId: 'agenciaId',
  nombreAgencia: 'nombreAgencia',
  startDate: 'startDate',
  endDate: 'endDate',
  page: 'page',
  maxPerPage: 'maxPerPage',
  tipoConsulta: 'tipoConsulta'
}

const SECTION_DATA_KEYS = {
  consultas: 'consultas',
}

const defaultSectionDataValues = {
  consultas: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFiltersValues = {
  paisId: '',
  agenciaId: '',
  nombreAgencia: '',
  startDate: null,
  endDate: null,
  page: 1,
  maxPerPage: 20,
  tipoConsulta: ''
}

const Consulta = () => (
  <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
    <Consultations />
  </SectionDataProvider>
)

export { Consulta, FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues, defaultFiltersValues }
