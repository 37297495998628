import React, { useEffect, useState } from 'react'
import { Box, Flex, List, Button, Grid, Heading } from '@chakra-ui/core'
import { PageContainer } from '../../common/helpers'
import { useParams } from 'react-router-dom'
import NoMatch from './../../no-march'
import { GiHamburgerMenu } from 'react-icons/gi'
import Information from './edit/index-information'
import { useTranslation } from 'react-i18next'
import NavigatorDrawer from '../../common/drawer/navigator-drawer'
import { ListItemRoute } from '../../common/router-components'
import { BranchOffices } from './edit/BranchOffices'
import {
  getToken,
  getUserApplicationId,
  getUserCountryId,
  getUserRolId,
  getUserRolName,
} from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { updateSupplier } from '../../../api/section'
import { getAuthToken } from '../../../redux/selectors'
import { allowedRolesToEdit } from '../../../helpers/commons'
import TravelGea from './edit/TravelGea'
import GrupoGea from './edit/GrupoGea'
import { ExchangeAdminCountry } from '../currency/exchange-admin-country'
import { UsersSection } from './users/users-section-data'
import { ComercialSection } from '../comercial/comercial-section-data'
import { RubroSection } from '../rubro/rubro-section-data'
import MyAccount from './my-account/my-account'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { LocalidadSection } from '../localidad/localidad-section-data'
import { Ejecutivo } from '../comercial/ejecutivo'
import { ImpuestoSection } from '../impuesto/impuesto-section-data'

export default function MyAppEdit() {
  const appId = useSelector(getUserApplicationId)
  const userCountryId = useSelector(getUserCountryId)
  const { t } = useTranslation(['applications', 'common', 'exchange', 'forms', 'consultations'])
  const rolId = useSelector(getUserRolId)
  const permisosUser = rolId == 1 ? true : false
  const permisosUserCountry = rolId == 2 ? true : false
  const permisosListCreateUser = rolId === 1 || rolId === 2 || rolId === 3 ? true : false
  const permisosEjecutivo = rolId === 3 || rolId === 4 || rolId === 5 || rolId === 2 ? true : false
  const userRolName = useSelector(getUserRolName)
  const location = useLocation()

  if (!appId || appId === 0) {
    return <NoMatch />
  }

  const appIdSetter = () => (allowedRolesToEdit.includes(userRolName) ? '/:appId' : '')

  const childrenRoutes = [
    {
      exact: true,
      path: `/my-applications/edit/:appId/my-account`,
      component: MyAccount,
    },
    {
      exact: true,
      path: ['/my-applications/edit/', `/my-applications/edit/:appId/information`],
      component: Information,
    },
    {
      path: `/my-applications/edit${appIdSetter()}/users`,
      component: UsersSection,
    },
    {
      path: `/my-applications/edit/:appId/branch-offices`,
      component: BranchOffices,
    },
    // {
    //   path: `/my-applications/edit/${appId}/credentials`,
    //   component: Credentials,
    // },
    {
      path: `/my-applications/edit/:appId/grupo-gea`,
      component: GrupoGea,
    },
    {
      path: `/my-applications/edit/:appId/travel-gea`,
      component: TravelGea,
    },
    { path: `/my-applications/edit/${appId}/exchange`, component: ExchangeAdminCountry },
    {
      path: `/my-applications/edit${appIdSetter()}/comercial`,
      component: ComercialSection,
    },
    {
      path: `/my-applications/edit${appIdSetter()}/rubro`,
      component: RubroSection,
    },
    {
      path: `/my-applications/edit/${appId}/crear-localidad`,
      component: LocalidadSection,
    },
    {
      path: `/my-applications/edit/${appId}/ejecutivo`,
      component: Ejecutivo,
    },
    {
      path: `/my-applications/edit/${appId}/impuestos`,
      component: ImpuestoSection,
    },
  ]

  function getTitle(path) {
    if (path.includes('information')) {
      return t('applications:mainData')
    } else if (path.includes('my-account')) {
      return t('common:myAccount')
    } else if (path.includes('users')) {
      return t('applications:users')
    } else if (path.includes('branch-offices')) {
      return t('applications:branch-offices')
    } else if (path.includes('rubro')) {
      return t('applications:item')
    } else if (path.includes('comercial')) {
      return t('applications:comercial')
    } else if (path.includes('exchange')) {
      return t('exchange:changeType')
    } else if (path.includes('grupo-gea')) {
      return t('Grupo Gea')
    } else if (path.includes('travel-gea')) {
      return t('Travelgea')
    } else if (path.includes('crear-localidad')) {
      return t('forms:locations')
    } else if (path.includes('ejecutivo')) {
      return t('forms:businessman')
    } else if (path.includes('impuestos')) {
      return t('consultations:taxes')
    } else {
      return ''
    }
  }

  const header = (onOpen, btnRef) => (
    <Grid templateColumns={{ base: '6fr 1fr', md: '7fr 0fr' }} gap={[3, 3, 0]}>
      <Box backgroundColor='' py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {getTitle(location.pathname)}
        </Heading>
      </Box>
      <Flex justify='flex-end'>
        <Button
          display={{ base: 'inherit', md: 'none' }}
          mb='10px'
          ref={btnRef}
          variant='outline'
          variantColor='blue'
          onClick={onOpen}
        >
          <Box as={GiHamburgerMenu} display='inline' />
        </Button>
      </Flex>
    </Grid>
  )

  return (
    <PageContainer>
      <NavigatorDrawer header={header} routes={childrenRoutes} spacing='30px'>
        <List spacing={3}>
          <ListItemRoute to={`/my-applications/edit/${appId}/my-account`}>{t('common:myAccount')}</ListItemRoute>
          <ListItemRoute to={`/my-applications/edit/${appId}/information`}>{t('applications:mainData')}</ListItemRoute>
          {permisosListCreateUser && (
            <ListItemRoute
              to={`/my-applications/edit${allowedRolesToEdit.includes(userRolName) ? '/' + appId : ''}/users`}
            >
              {t('applications:users')}
            </ListItemRoute>
          )}
          <ListItemRoute to={`/my-applications/edit/${appId}/branch-offices`}>
            {t('applications:branch-offices')}
          </ListItemRoute>
          {permisosUser && (
            <ListItemRoute to={`/my-applications/edit/${appId}/comercial`}>{t('applications:comercial')}</ListItemRoute>
          )}
          {permisosUser && (
            <ListItemRoute to={`/my-applications/edit/${appId}/rubro`}>{t('applications:item')}</ListItemRoute>
          )}
          {permisosUser && (
            <ListItemRoute to={`/my-applications/edit/${appId}/crear-localidad`}>{t('forms:locations')}</ListItemRoute>
          )}
          {permisosUserCountry && (
            <ListItemRoute to={`/my-applications/edit/${appId}/grupo-gea`}>{t('Grupo Gea')}</ListItemRoute>
          )}

          {permisosUserCountry && userCountryId !== 3 && (
            <ListItemRoute to={`/my-applications/edit/${appId}/travel-gea`}>{t('Travelgea')}</ListItemRoute>
          )}

          {permisosUserCountry && userCountryId !== 3 && userCountryId !== 2 && (
            <ListItemRoute to={`/my-applications/edit/${appId}/exchange`}>{t('exchange:changeType')}</ListItemRoute>
          )}
          {permisosEjecutivo && (
            <ListItemRoute to={`/my-applications/edit/${appId}/ejecutivo`}>{t('forms:businessman')}</ListItemRoute>
          )}
          {(permisosUser || permisosUserCountry) && (
            <ListItemRoute to={`/my-applications/edit/${appId}/impuestos`}>{t('consultations:taxes')}</ListItemRoute>
          )}
        </List>
      </NavigatorDrawer>
    </PageContainer>
  )
}
