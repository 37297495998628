import React, { useState, useEffect } from 'react'
import { useMemo } from 'react'
import { PageContainer } from '../../../common/helpers'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../../redux/selectors'
import { StarIcon } from '@chakra-ui/icons'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import moment from 'moment'
import { Box, Button, Menu, MenuButton, MenuList, Stack, useToast, Text, Spinner, Heading } from '@chakra-ui/core'
import { getPackageInformationById, deletePackageInformation, createPackageInformation, updatePackageInformation } from '../../../../api/intranet/requests'
import { ButtonDelete, ButtonEdit, CommonButton } from '../../../forms/buttons/edit'
import { MenuItemAuthorized, NavItem } from '../../../layout/menu/menu-elements'
import { PACKAGE_CREATE } from '../../../../helpers/permissions'
import { DeparturesForm } from './Departures-Form'
import { useParams } from 'react-router-dom'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import { useSectionData } from '../../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS } from './departure-section-data'
import Table from '../../../common/table'
import Pagination from '../../../common/pagination'

export const DepartureList = () => {
  const { t } = useTranslation(['common', 'forms', 'packages', 'bookings'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const {
    filters,
    handleFilters,
    sectionData,
    setLastFilters,
    handleSectionData,
  } = useSectionData()

  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const [totalPages, setTotalPages] = useState(0)
  const [isEdit, setIsEdit] = useState(false)

  //REQUEST DEL PAQUETE
  const { packageId } = useParams()

  async function getPackagesInformationById() {
    filters.packageId = packageId
    setLastFilters(filters)
    handleSectionData(SECTION_DATA_KEYS.departure, {
      ...sectionData.departure,
      isLoading: true,
    })
    try {
      const res = await getPackageInformationById(filters)

      setTotalPages(res.data.lastPage)
      handleSectionData(SECTION_DATA_KEYS.departure, {
        data: res.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      console.log('error', error)
      handleSectionData(SECTION_DATA_KEYS.departure, {
        isLoading: false,
        error: true,
      })
    }
  }

  useEffect(() => {
    handleFilters(FILTER_KEYS.packageId, packageId)
    getPackagesInformationById()
  }, [packageId, filters.page])

  function dateFuction(dat) {
    let datOperationSince = moment(dat.original.operationDateSince).format('DD/MM/YYYY')
    let datOperationUntil = moment(dat.original.operationDateUntil).format('DD/MM/YYYY')
    return datOperationSince + ' - ' + datOperationUntil
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:departures'),
        accessor: 'operationDate',
        Cell: ({ cell }) => (
          <Stack>
            <Text>{dateFuction(cell.row)}</Text>
          </Stack>
        ),
      },

      {
        Header: t('forms:availability'),
        accessor: 'availability',
      },
      {
        Header: t('forms:hotels'),
        accessor: 'hotels',
        Cell: ({ cell }) => (
          <Menu>
            <MenuButton isDisabled={!cell.row.original.hotelesInformacion?.length} as={Button} rightIcon='chevron-down'>
              <NavItem>{t('forms:hotels')}</NavItem>
            </MenuButton>
            <MenuList placement='bottom-start'>
              {cell.row.original.hotelesInformacion.map(h => {
                return (
                  h.hotel?.name !== undefined && (
                    <MenuItemAuthorized key={h.hotelId} permission={PACKAGE_CREATE}>
                      {h.hotel?.category} <StarIcon /> - {h.hotel?.name}
                    </MenuItemAuthorized>
                  )
                )
              })}
            </MenuList>
          </Menu>
        ),
      },
      {
        Header: t('forms:prices'),
        accessor: 'prices',
        Cell: ({ cell }) => (
          <Menu>
          <MenuButton as={Button} rightIcon='chevron-down'>
            <NavItem>{t('forms:prices')}</NavItem>
          </MenuButton>
          <MenuList placement='bottom-start'>
            {cell.row.original?.precioInformacion?.map(p => {
              let resultAmount = parseFloat(p.amount);
              let taxTotal = p.taxes.reduce((accumulator, tax) => {
                switch(tax.taxType) {
                  case 'FIXED_AMOUNT':
                    return accumulator + parseFloat(tax.taxAmount);
                  case 'PORCENTAGE':
                    return accumulator + resultAmount * (parseFloat(tax.taxAmount) / 100);
                  default:
                    console.error(`Unknown tax type: ${tax.taxType}`);
                    return accumulator;
                }
              }, 0);
              resultAmount += taxTotal;
              return (
                <React.Fragment key={p.id}>
                  <MenuItemAuthorized key={p.id} permission={PACKAGE_CREATE}>
                    <div>
                     {p.baseType?.description} | {p.currency?.descripcion} | ${resultAmount.toFixed(2)} 
                    </div>
                  </MenuItemAuthorized>
                </React.Fragment>
              );
            })}
          </MenuList>
        </Menu>
      ),
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack align={'center'} spacing='5px' isInline d='flex' mx='1'>
            <CommonButton label={t('consultations:viewPackage')} variantColor='green' isDisabled />
            <ButtonEdit
              onClick={() => {
                return onEditClick(cell.row.values.id, cell.row.original)
              }}
            />
            <ButtonDelete onClick={() => prepareDelete(cell.row.values.id)}/>
          </Stack>
        ),
      },
    ],
    [t],
  )

  //CREAR
  const [newDeparture, setNewDeparture] = useState({
    availability: 0,
    operationDateSince: moment(),
    operationDateUntil: moment(),
    onlineSale: 1,
    hotelesInformacion: [],
    precioInformacion: [],
    trips: [],
  })

  //EDITAR
  const [idDeparturEdit, setIdDeparturEdit] = useState()
  const [dataEdit, setDataEdit] = useState({})

  function onEditClick(id, data) {
    setDataEdit({ ...data })
    setIdDeparturEdit(id)
    setIsEdit(true)
  }
  //ELIMINAR
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: [],
  })

  const onCloseDeleteAlert = () => {
    setDeleteAlert(state => ({ ...state, show: false }))
  }

  function prepareDelete(data) {
      setDeleteAlert(state => ({
        ...state,
        data,
        show: true,
      }))
      handleSetPage(1)
  }

  async function onDelete() {
    if(!isEdit){
    let itemIndex = sectionData.departure.data?.findIndex(i => i.id === deleteAlert.data)
    try {
      const deleteResponse = await deletePackageInformation(sectionData.departure.data[itemIndex].id, authToken)
      toast(
        deleteResponse.status
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
      onCloseDeleteAlert()
      getPackagesInformationById()
    } catch (error) {
      toast(error && toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      getPackagesInformationById()
    }
  }else {
    onCloseDeleteAlert()
    toast(
      toastCustomizedError(t('packages:notDelete'))
    )
  }
  }

  const onDiscard = () => {
    setDataEdit({})
    setIdDeparturEdit('')
    setIsEdit(false)
    setNewDeparture({
      availability: 0,
      operationDateSince: moment(),
      operationDateUntil: moment(),
      onlineSale: 1,
      hotelesInformacion: [],
      precioInformacion: [],
      trips: [],
    })
    getPackagesInformationById()
  }

  //REQUEST CREAR O EDITAR
  async function addDeparture(obj) {
    const cleanDeparture = { ...obj, trips: [], precioInformacion: [], hotelesInformacion: [] }
    obj.trips.forEach(trip => {
      const stopDetails = []
      trip.stopDetails.forEach(stopover => {
        stopDetails.push({
          localidadId: stopover.localidadId,
          waitingHours: stopover.waitingHours,
          waitingMinutes: stopover.waitingMinutes,
          id: stopover.id,
          tripId: trip.id
        })
      })
      cleanDeparture.trips.push({
        stopDetails, 
        departureDate: trip.departureDate, 
        arrivalDate: trip.arrivalDate,
        company: trip.company,
        travelCode: trip.travelCode,
        tripTypeId: trip.tripType.id,
        originLocalidadId: trip.originLocalidadId,
        detinationLocalidadId: trip.detinationLocalidadId,
        transportTypeId: trip.transportType.id,
        id: trip.id
      })
    })
    obj.precioInformacion.forEach(precio => {
      cleanDeparture.precioInformacion.push({
        amount: precio.amount,
        baseTypeId: precio.baseType.id,
        currencyId: precio?.currency?.id,
        taxes: precio.taxes,
        id: precio.id,
        taxFree: precio.taxFree,
        taxBase: precio.taxBase,
        taxName: precio.taxName,
        taxTotal: precio.taxTotal,
        totalAmount: precio.totalAmount
      })
    })
    obj.hotelesInformacion.forEach(hotel => {
      cleanDeparture.hotelesInformacion.push({
        hotelId: hotel.hotel.id,
        regime: hotel.regime,
        roomType: hotel.roomType,
        totalNights: hotel.totalNights,
        id: hotel.id
      })
    })
    if (idDeparturEdit) {
      //modificar salida
      let itemIndex = sectionData.departure.data.findIndex(i => i.id === idDeparturEdit)
      sectionData.departure.data.splice(itemIndex, 1, cleanDeparture)
      handleSectionData(SECTION_DATA_KEYS.departure, {
        ...sectionData.departure,
        data: sectionData.departure.data
      })
    } else {
      //Agregar nueva salida
      let itemIndex = sectionData.departure.data.length
      let updateDepartures = (sectionData.departure.data[itemIndex] = cleanDeparture)
      
      handleSectionData(SECTION_DATA_KEYS.departure, {
        ...sectionData.departure,
        data: [...sectionData.departure.data, updateDepartures]
      })
    }
    await onSubmit()
  }

  async function onSubmit() {
    try {
      if (!idDeparturEdit) {
        const newDeparture = sectionData.departure.data.filter((pi) => !pi?.id)
        newDeparture[0].trips.map(trip => {
          trip.arrivalDate = moment(trip.arrivalDate) 
          trip.departureDate = moment(trip.departureDate)
        })
        await Promise.all(newDeparture.map((departure) => {
          createPackageInformation({ paqueteId: packageId, ...departure })
        }))
      } else {
        const departureEdit = sectionData.departure.data.find((pi) => pi.id === idDeparturEdit)
        departureEdit.trips.map(trip => {
          trip.arrivalDate = moment(trip.arrivalDate) 
          trip.departureDate = moment(trip.departureDate)
        })
        await updatePackageInformation(dataEdit.id, { ...departureEdit, paqueteId: packageId }, authToken)
      }
      toast(toastCustomizedSuccess(t('forms:actionCompleted')), t('forms:support'))
      await getPackagesInformationById()
      setIsEdit(false)
    } catch (error) {
      toast(error && toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      getPackagesInformationById()
      setIsEdit(false)
    }
  }

  return (
    <PageContainer p='2'>
      <Box py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {t('packages:departures')}
        </Heading>
      </Box>

      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `Salida #${deleteAlert.data}`}</b>
      </AlertDialogSimple>

      {!sectionData?.departure?.isLoading && !!sectionData?.departure?.data.length && <Table columns={columns} data={sectionData.departure.data} />}
      {!sectionData?.departure?.isLoading && !!totalPages && (
        <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} selectPage={false} />
      )}
      <Box bg='gray.50'>
        {sectionData.departure.isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!sectionData.departure.isLoading && !sectionData.departure.error && !sectionData.departure.data?.length && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {sectionData.departure.isLoading && !sectionData.departure.data?.length && sectionData.departure.error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
      </Box>
      <Box mt='4'>
        <DeparturesForm
          departureData={idDeparturEdit ? dataEdit : newDeparture}
          setDeparture={idDeparturEdit ? setDataEdit : setNewDeparture}
          addDeparture={addDeparture}
          isEdit={isEdit}
          onDiscard={onDiscard}
        />
      </Box>
    </PageContainer>
  )
}
