import React from 'react'
import { Package } from './Package'
import { SectionDataProvider } from '../../../context/SectionDataContext'

const FILTER_KEYS = {
  originCode: 'originCode',
  destinationCode: 'destinationCode',
  fromDate: 'fromDate',
  untilDate: 'untilDate',
  applicationId: 'applicationId',
  page: 'page',
  maxPerPage: 'maxPerPage'
}

const SECTION_DATA_KEYS = {
  package: 'package'
}

const defaultSectionDataValues = {
  package: {
    data: [],
    isLoading: true,
    error: null
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 10,
  originCode: [],
  destinationCode: [],
  fromDate: undefined,
  untilDate: undefined,
  applicationId: '',
}

const defaultFiltersValues = { ...defaultFilters }

const PackageSection = () => (
  <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
    <Package/>
  </SectionDataProvider>
)

export {
  PackageSection,
  FILTER_KEYS,
  SECTION_DATA_KEYS,
  defaultSectionDataValues,
  defaultFilters,
  defaultFiltersValues,
}
