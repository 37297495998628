import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/dist/css/splide.min.css'
import NewsCard from '../common/news-card'
import '../styles/events/upcomingEventsSlider.css'
import '../styles/common/custom-splide-styles.css'
import moment from 'moment'
import { Flex } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'

const NewsSlider = ({ events }) => {
  const { t } = useTranslation(['comunications'])

  const defaultOptions = {
    padding: {
      left: 12,
      right: 12,
    },
    perPage: 3,
    gap: '2rem',
    arrows: false,
    classes: {
      pagination: 'splide__pagination custom-splide-pagination',
    },
  }

  if (events.length == 0)
    return (
      <Flex justifyContent='center' alignItems='center'>
        {t('comunications:noNews')}
      </Flex>
    )

  return (
    <Splide aria-label='My Slider' options={defaultOptions}>
      {events.map(event => {
        return (
          <SplideSlide key={event.id}>
            {!event.tituloHtml ? (
              <NewsCard
                date={moment(event.fecha).format('l').toUpperCase()}
                imgSrc={event.imagenUrl}
                url={event.url}
                label={event.subtitulo}
                key={event.id}
              />
            ) : (
              <NewsCard
                date={moment(event.fecha).format('l').toUpperCase()}
                imgSrc={event.imagenUrl}
                url=''
                label={event.tituloHtml}
                key={event.id}
                id={event.id}
                html= {true}
              />
            )}
          </SplideSlide>
        )
      })}
    </Splide>
  )
}

export default NewsSlider
