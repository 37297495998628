import { Box, Button, Flex, Icon } from '@chakra-ui/core'
import React, { useState } from 'react'
import FormInput from '../forms/form-input'
import { useTranslation } from 'react-i18next'
import { checkUrlValidity } from '../../helpers/utils'

function MediaEditForm({ media, onDelete, onUpdate, onCreate }) {
  const { t } = useTranslation(['comunications', 'common', 'forms'])
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [inputValue, setInputValue] = useState()

  function startEditing() {
    setIsEditing(true)
  }

  async function handleDelete() {
    setIsLoading(true)
    await onDelete(media.id)

    setIsLoading(false)
  }

  async function handleUpdate() {
    if (!isEditing) return

    if (!checkUrlValidity(inputValue)) return

    await onUpdate(media.id, inputValue)
    setIsEditing(false)
  }

  async function handleCreate() {
    await onCreate(inputValue)
  }

  const actions = {
    startEditing,
    handleCreate,
    handleUpdate,
    handleDelete,
  }

  return (
    <Flex justifyContent='space-between' mb='2rem'>
      <Box flex={3}>
        <FormInput
          style={{ marginRight: '4px' }}
          name='link'
          inputProps={{
            type: 'text',
            placeholder: `${t('comunications:url')} ${t('common:media')}`,
            defaultValue: `${media.link ? media.link : ''}`,
          }}
          isDisabled={!isEditing}
          onChange={e => {setInputValue(e.target.value)}}
        />
      </Box>

      <Box flex={1} display={'flex'} flexDirection={'horizontal'}>
        <ActionButtons link={media.link} isEditing={isEditing} actions={actions} isLoading={isLoading}/>
      </Box>
    </Flex>
  )
}

function ActionButtons({ link, isEditing, actions, isLoading }) {
  if (link) {
    return (
      <>
        {isEditing ? (
          <Button variantColor='blue' onClick={actions.handleUpdate} ml={2} isDisabled={isLoading}>
            <Icon name='check' />
          </Button>
        ) : (
          <Button variantColor='blue' onClick={actions.startEditing} ml={2} isDisabled={isLoading}>
            <Icon name='edit' />
          </Button>
        )}
        <Button variantColor='red' onClick={actions.handleDelete} ml={2} isDisabled={isLoading}>
          <Icon name='delete' />
        </Button>
      </>
    )
  }

  if (!link) {
    return (
      <Button variantColor='blue' onClick={actions.handleCreate} ml={2} isDisabled={isLoading}>
        <Icon name='add' />
      </Button>
    )
  }
}

export default MediaEditForm
