import React from 'react'
import { useTranslation } from 'react-i18next'
import { getStatusEscala } from '../../../../api/incentives'
import { useDisclosure } from '@chakra-ui/core'
import { getAuthToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import IncentiveDetails from '../components/IncentiveDetails'

const usePrepareIncentiveRow = () => {
  const { t } = useTranslation()
  const modalDisclosure = useDisclosure()
  const authToken = useSelector(getAuthToken)

  const prepareIncentive = async incentive => {
    let statusScala
    let filteredStatusEscala
    try {
      const response = await getStatusEscala(authToken, incentive.id)
      statusScala = [...response.data]
      const anyProyeccionGreaterOne = statusScala.some(item => item.proyeccion > 1)

      if (anyProyeccionGreaterOne) {
        filteredStatusEscala = response.data
          .filter(item => item.proyeccion > 1)
          .sort((a, b) => b.incentivoPorcentaje - a.incentivoPorcentaje)[0]
      } else {
        filteredStatusEscala = response.data.sort((a, b) => Math.abs(1 - a.proyeccion) - Math.abs(1 - b.proyeccion))[0]
      }
    } catch (error) {
      console.log(error)
    }

    return {
      ...incentive,
      statusScala,
      filteredStatusEscala,
      details: (
        <IncentiveDetails incentive={incentive} statusScala={statusScala} filteredStatusEscala={filteredStatusEscala} />
      ),
    }
  }

  return prepareIncentive
}

export default usePrepareIncentiveRow
