import React, { useState, useEffect, Fragment } from 'react'
import { Button, Box, FormLabel } from '@chakra-ui/core'
import { Grid, GridItem, Text, Input } from '@chakra-ui/react'
import { SingleDatePicker } from 'react-dates'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-dates/lib/css/_datepicker.css'

import { getAuthToken, getUserCountryId, getUserApplicationId, getUserRolId } from '../../../../redux/selectors'

import SelectAsync from '../../../common/select-async'

import { obtenerCategorias } from '../../../../api/communications'
import { getAllCountries } from '../../../../api/application'
import { getSectionTypes } from '../../../../api/section'
import FormInput from '../../../forms/form-input'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import ImageInput from '../../../common/image-input'
import { readFile } from '../../../../helpers/utils'
import { TextField } from '@material-ui/core'

function CreateNews({ formData = false, padre, type, recordToEdit, getCommunications }) {
  // - - General - -
  const token = useSelector(state => state.appState.user.token)
  const [paises, setPaises] = useState([])
  const [categories, setCategories] = useState([])
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const applicationId = useSelector(getUserApplicationId)
  const { t } = useTranslation(['comunications', 'forms', 'sections'])
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId == 2 ? true : false
  const [country, setCountry] = useState(recordToEdit ? recordToEdit.paisId : '')
  const [selectedSectionType, setSelectedSectionType] = useState(recordToEdit ? (recordToEdit?.comunicadoHtml ? 'HTML' : 'URL') : undefined)
  const [disabledHtml, setDisabledHtml] = useState(false)
  const [sectionTypesRequest, setSectionTypesRequest] = useState({
    isLoading: false,
    data: [],
  })

  const emptyInput = t('forms:html_file')
  const [fileChargeStatusMsg, setFileChargeStatusMsg] = useState(emptyInput)

  // - - 📅 SingleDatePicker 📅 - -

  const [startDate, setStartDate] = useState(moment(formData ? formData?.fecha : recordToEdit?.fecha))

  // - - 📋 Form 📋 - -

  const { handleSubmit, register, control, reset, formState, setValue } = useForm({
    defaultValues: formData ? formData : recordToEdit,
  })

  // - - 📋 HTML 📋 - -

  const [contentFromFile, setContentFromFile] = useState('')

  const [contentValue, setContentValue] = useState(recordToEdit ? recordToEdit.comunicadoHtml : '')

  const quillToolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  const handleFileImport = async () => {
    const fileInput = document.querySelector('#htmlToImport')
    fileInput.click()
    fileInput.onchange = e => {
      const { files } = e.target
      if (files.length) {
        setFileChargeStatusMsg(files[0].name)
        readFile(files[0])
          .then(data => {
            setContentFromFile(data)
            setDisabledHtml(false)
          })
          .catch(err => {
            setContentFromFile(undefined)
            console.log(err)
          })
      } else {
        setFileChargeStatusMsg(emptyInput)
      }
    }
  }

  function handleQuillChange(change) {
    setContentValue(change)
  }

  function onChangeCountrySelector(e) {
    setCountry(e)
    if (!formData) {
      getComunicadosCategoria(e)
    }
  }

  function onChangeDate(e) {
    setStartDate(e)
  }

  async function onSubmit(data) {
    let destacado = false
    if (recordToEdit) destacado = recordToEdit.destacado
    data = {
      aplicacionId: applicationId,
      asunto: contentFromFile ? 'newsletter' : 'html',
      categoriasComunicados: { id: parseInt(data.categoriasComunicados.id) },
      tipoEnvio: { id: 1 },
      comunicadoHtml: selectedSectionType === 'HTML' ? (contentFromFile ? contentFromFile : contentValue) : '',
      descripcion: data.descripcion || type.title,
      destacado: destacado,
      fecha: startDate._d,
      imagenUrl: data.imagenUrl || 'https://res.cloudinary.com/travelit/image/upload/v1629216295/img-test/wbtbsdtjjv6cpz6cirki.jpg',
      paisId: permisosUserCountry ? parseInt(userCountryId) : parseInt(country),
      subtitulo: data.subtitulo || '',
      tipoComunicado: { id: type.id },
      titulo: data.titulo || '',
      tituloHtml: data.tituloHtml || '',
      url: data.url || '',
    }

    if (recordToEdit) {
      await padre(data, recordToEdit.id)
    } else {
      await padre(data)
    }
    getCommunications()
    reset({})
    setContentFromFile(undefined)
    setContentValue('')
    setSelectedSectionType(undefined)
  }

  async function getCountrys() {
    try {
      const resp = await getAllCountries(token)
      setPaises(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  async function getComunicadosCategoria(e) {
    try {
      const res = await obtenerCategorias(e, authToken)
      const filterSplit = res?.data?.map(x => {
        const category = x.descripcion.split('/')
        return { id: x.id, descripcion: category[0], type: category[1], paisId: e }
      })
      const filterCategories = filterSplit.filter(category => {
        if (category.type === type.slug) {
          return category
        }
      })
      setCategories(filterCategories)
    } catch (err) {
      console.log(err)
    }
  }

  async function getAllSectionTypes() {
    setSectionTypesRequest({
      isLoading: true,
    })
    try {
      const response = await getSectionTypes()

      if (response.data) {
        setSectionTypesRequest({
          isLoading: false,
          data: response.data.filter(s=> s.nombre !== 'BANNER'),
        })
      }
    } catch (error) {
      setSectionTypesRequest({
        isLoading: false,
        data: [],
      })
      console.log(error)
    }
  }

  useEffect(() => {
    if(selectedSectionType === 'HTML'){
      if(!contentValue){
        setDisabledHtml(true)
      }else{
        setDisabledHtml(false)
      }
    }else {
      setDisabledHtml(false)
    } 
  }, [selectedSectionType, contentValue])

  useEffect(() => {
    getCountrys()
    getAllSectionTypes()
    if (recordToEdit) {
      getComunicadosCategoria(recordToEdit.paisId)
    }
    if (formData) {
      getComunicadosCategoria(formData.paisId)
    }
    if (permisosUserCountry) {
      getComunicadosCategoria(userCountryId)
    }
    if (formState.isSubmitSuccessful) {
      reset({})
    }
  }, [reset])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns='repeat(2, 1fr)' gap={6}>
        {/* Pais */}
        <GridItem colSpan={1} rowSpan={1}>
          <Box style={{ margin: '0% 1%', padding: '1%' }}>
            <FormLabel isRequired mr='1'>
              {t('comunications:country')}
            </FormLabel>
            <Controller
              control={control}
              name='paisId'
              defaultValue={recordToEdit ? recordToEdit.paisId : ''}
              render={({ onChange, value, name }) => (
                <SelectAsync
                  style={{ marginTop: '4px' }}
                  name={name}
                  value={permisosUserCountry ? userCountryId : value}
                  onChange={e => {
                    onChangeCountrySelector(e.target.value)
                    return onChange(e.target.value)
                  }}
                  placeholder={t('comunications:selectCountry')}
                  isRequired={true}
                  isDisabled={permisosUserCountry}
                >
                  {paises &&
                    paises
                      .sort((a, b) => a.nombre.localeCompare(b.nombre))
                      .map(({ id, nombre }) => (
                        <option key={id} value={id}>
                          {nombre}
                        </option>
                      ))}
                </SelectAsync>
              )}
            />
          </Box>
        </GridItem>

        {/* Categoria  */}
        <GridItem colSpan={1} rowSpan={1}>
          <Box style={{ margin: '0% 1%', padding: '1%' }}>
            <FormLabel isRequired mr='1'>
              {t('comunications:comunicationCategory')}
            </FormLabel>
            <Controller
              control={control}
              name='categoriasComunicados.id'
              defaultValue={recordToEdit ? recordToEdit.categoriasComunicados.id : ''}
              render={({ onChange, value, name }) => {
                return (
                  <SelectAsync
                    style={{ marginTop: '4px' }}
                    name={name}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    placeholder={
                      categories.length
                        ? t('comunications:selectCategory')
                        : t('comunications:comunicationCategoryDisabled')
                    }
                    isRequired={formData == false ? true : false}
                  >
                    {categories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.descripcion}
                      </option>
                    ))}
                  </SelectAsync>
                )
              }}
            />
          </Box>
        </GridItem>

        {/* Imagen */}
        <GridItem colSpan={1} rowSpan={1}>
          <ImageInput
              style={{ margin: '0% 1%', padding: '1%' }}
              name='imagenUrl'
              label={t('forms:image')}
              setValue={setValue}
              inputProps={{
                  type: 'text',
                  placeholder: `URL ${t('forms:image')}`,
                  ref: register,
              }}
          />
        </GridItem>

        {/* Fecha */}
        <GridItem colSpan={1} rowSpan={1}>
          <Box style={{ margin: '0% 1%', padding: '1%' }}>
            <FormLabel isRequired mr='1'>
              {t('comunications:date')}
            </FormLabel>
              <Box pr='5px'>
              <TextField
                id='departure_date'
                type='date'
                size='small'
                variant='outlined'
                fullWidth
                value={moment(startDate).format('YYYY-MM-DD')}
                onChange={(e) => onChangeDate(moment(e.target.value, 'YYYY-MM-DD'))}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={(e) => e.preventDefault()}
                readOnly
              />
              </Box>
          </Box>
        </GridItem>

        {/* Tipo de seccion */}
        <Box alignItems='center' style={{ margin: '0% 1%', padding: '1%'}}>
          <FormLabel mr='1'>
            {t('sections:typeSection')}
          </FormLabel>
          <SelectAsync
            isRequired
            placeholder={t('sections:typeSection')}
            name='sectionType'
            onChange={e => {
              setContentFromFile('')
              setContentValue(recordToEdit ? recordToEdit.comunicadoHtml : '')
              setFileChargeStatusMsg(emptyInput)
              setSelectedSectionType(e.target.value)
            }}
            nativeProps={{ ref: register }}
            isLoading={sectionTypesRequest.isLoading}
            value={selectedSectionType}
          >
            {sectionTypesRequest?.data?.length &&
              sectionTypesRequest.data.map(section => (
                <option key={section.id} value={section.nombre}>
                  {section?.descripcion}
                </option>
              ))}
          </SelectAsync>
        </Box>

        {/* URL */}
        {selectedSectionType === 'URL' && (
          <Fragment>
            {/* Subtitulo */}
            <GridItem colSpan={1} rowSpan={1}>
              <FormInput
                style={{ margin: '0% 1%', padding: '1%' }}
                name='subtitulo'
                isRequired
                label={t('comunications:namelink')}
                inputProps={{
                  type: 'tel',
                  placeholder: t('comunications:namelink'),
                  ref: register,
                }}
              />
            </GridItem>

            {/* URL */}
            <FormInput
              style={{ marginTop: '0% 1%', padding: '1%' }}
              name='url'
              label={t('comunications:Link')}
              isRequired
              inputProps={{
                type: 'string',
                placeholder: t('comunications:Link'),
                ref: register,
              }}
            />
          </Fragment>
        )}
        {selectedSectionType === 'HTML' && (
          <Fragment>
            <FormInput
                style={{ margin: '0% 1%', padding: '1%' }}
                name='tituloHtml'
                isRequired
                label={t('comunications:nameHtml')}
                inputProps={{
                  type: 'tel',
                  placeholder: t('comunications:nameHtml'),
                  ref: register,
                }}
              />
            {/* Editor HTML */}
            <Box d='flex' alignItems='left' flexDirection='column'>
              <Text mb='3'>HTML</Text>
              <Box mb='5' w='100%' d='flex' flexDirection='row'>
                <Button w='auto' mr='16px' onClick={handleFileImport}>
                  {t('forms:browse')}
                </Button>
                <Input isRequired w='100%' value={fileChargeStatusMsg} disabled />
              </Box>
              <input type='file' accept='text/html' id='htmlToImport' name='htmlToImport' multiple={false} hidden />
            </Box>
            {contentFromFile ? (
              ''
            ) : (
              <GridItem
                style={{
                  borderWidth: 0.5,
                  borderColor: '#e5e5e5',
                }}
                colSpan={2}
              >
                <ReactQuill
                  style={{ display: 'flex', flexDirection: 'column', height: '450px', marginBottom: '68px' }}
                  modules={{ toolbar: quillToolbar }}
                  value={contentValue}
                  onChange={(e) => handleQuillChange(e)}
                />
              </GridItem>
            )}
          </Fragment>
        )}
      </Grid>
      {/* Btn Enviar */}
      <Button mt={6} mb={2} ml={2} variantColor='blue' variant='outline' type='submit' isDisabled={disabledHtml}>
        {recordToEdit ? type.edit : type.label}
      </Button>
    </form>
  )
}

export default CreateNews
