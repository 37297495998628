import { Box, Button, Grid, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAllCities } from '../../../api/geo'
import FormInput from '../../forms/form-input'
import { getAuthToken, getUserCountryId } from '../../../redux/selectors'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS } from '.'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import SelectAsync from '../../common/select-async'
import { getAllCountries } from '../../../api/application'
import { toastCustomizedError } from '../../common/toast-default-config'

export default function HotelsSearchForm({ onFilter, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const { t } = useTranslation(['common', 'forms', 'suppliers', 'comunications'])
  const countryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()

  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
  })

  const [loc, setLoc] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')

  let language
  switch (countryId) {
    case 1:
      language = 'es'
      break
    case 3:
      language = 'pt'
      break
    default:
      language = 'en'
      break
  }

  async function getLocalidades(city = null) {
    setLoc(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCities(city)
      setLoc(state => ({
        ...state,
        isLoading: false,
        items: (res.data && res.data) || [],
      }))
    } catch (error) {
      setLoc(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries(authToken)
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  function handleChangeCity(event, value, name) {
    setValue(value)
    if (value !== null) {
      handleFilters(FILTER_KEYS.localidadId, value.id)
    } else {
      handleFilters(FILTER_KEYS.localidadId, '')
    }
  }

  function handleSearch(e) {
    if (e.target.value.length > 2) {
      getLocalidades(e.target.value)
    } else {
      setLoc({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  useEffect(() => {
    getCountries()
  }, [])

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
      alignItems='flex-end'
      gap={6}
      {...restProps}
    >
      <Box>
        <SelectAsync
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.paisId, target.value)
          }}
          placeholder={`${t('forms:countries')} - ${t('forms:all')}`}
          value={filters.paisId}
          isLoading={countries.isLoading}
        >
          {countries.data
            .sort((a, b) => a.nombre.localeCompare(b.nombre))
            .map(({ id, nombre }) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
        </SelectAsync>
      </Box>
      {/* Destino */}
      <Box>
        <Autocomplete
          id='destinationCode'
          name='destinationCode'
          options={loc.items}
          getOptionLabel={option => option.nombre || ''}
          style={{ width: '100%' }}
          size='small'
          value={value}
          onChange={(event, newValue) => handleChangeCity(event, newValue)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          renderInput={params => (
            <TextField onChange={e => handleSearch(e)} {...params} label={t('forms:destination')} variant='outlined' />
          )}
        />
      </Box>
      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.name, e.target.value)}
        value={filters.name}
        name='clue'
        inputProps={{
          placeholder: `${t('forms:name')} (${t('common:optional')})`,
        }}
      />
      <Box style={{ display: 'flex', gap: '1rem' }}>
        <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>

        <Button
          variantColor='blue'
          variant='outline'
          onClick={() => {
            setValue('')
            setInputValue('')
            return handleResetFilters()
          }}
        >
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
