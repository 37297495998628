import React from 'react'
import UsersCredentials from '../../applications/credentials/users-credentials'
import { SectionDataProvider } from '../../../../context/SectionDataContext'

  
const FILTER_KEYS = {
  rolId: 'rolId',
  username: 'username',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  credentials: 'credentials',
}

const defaultSectionDataValues = {
  credentials: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 12,
  rolId: '',
  username: '',
}

const defaultFiltersValues = { ...defaultFilters }

const Credentials = () => (
  <SectionDataProvider defaultFilters={{ ...defaultFiltersValues }} defaultSectionData={defaultSectionDataValues}>
    <UsersCredentials />
  </SectionDataProvider>
)

export {
  Credentials,
  FILTER_KEYS,
  SECTION_DATA_KEYS,
  defaultSectionDataValues,
  defaultFiltersValues,
  defaultFilters,
}