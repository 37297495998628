import axios from 'axios'
import { configWithAuth } from './intranet'
import { getStorageToken } from '../helpers/utils'

export const config = {
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
}

export const configWithParams = params => {
  const tokenAux = getStorageToken()
  return {
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
      ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
    },
    params,
  }
} 

const { REACT_APP_GEO_API_V2 } = process.env
// instances
export const geoInstance = axios.create({
  baseURL: process.env.REACT_APP_GEO_API_V2,
})

export const getProvinces = '/api/provincia'
export const getLocalities = '/api/localidad'

//Endpoints
export const getAllPaisesEndpoint = '/api/pais'
export const getAllProvinciasEndpoint = '/api/provincia'
export const getAllProvinciasByPaisId = '/api/provincia/pais'
export const deleteUpdateLocalidad = (id) => `/api/localidad/${id}`
export const getAllLocalidadByProvinciaId = '/api/localidad/provincia'
export const getAllCitiesEndpoint = (city) => `/api/localidad?search=${city}`
export const getAllCitiesQueryEndpoint = '/api/localidad'
export const getCityByCodeEndpoint = (cityCode) => `/api/localidad?nombre=${cityCode}`
export const getLocalityByIdEndpoint = (localityId) => `${getLocalities}/${localityId}`

//Request
export const getAllPaises = (authToken) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getAllPaisesEndpoint}`, configWithAuth(authToken))
}

export const getAllProvincias = (authToken) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getAllProvinciasEndpoint}`, configWithAuth(authToken))
}

export const getProvinciasByPaisId = (data) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getAllProvinciasByPaisId}`, configWithParams(data))
}

export const getAllLocalidades = (authToken) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getLocalities}`, configWithAuth(authToken))
}
export const getAllLocalidadesPaginado = (data) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getLocalities}/paginated`, configWithParams(data))
}

export const getLocalidadesByProvinciaId = (data) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getAllLocalidadByProvinciaId}`, configWithParams(data))
}

export const getAllCities = (city, authToken) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getAllCitiesEndpoint(city)}`, configWithAuth(authToken))
}
export const getAllCitiesQuery = (data) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getAllCitiesQueryEndpoint}`, configWithParams(data))
}
export const getCityByCode = (cityCode, authToken) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getCityByCodeEndpoint(cityCode)}`, configWithAuth(authToken))
}
export const getLocalityById = (localityId, authToken) => {
  return axios.get(`${REACT_APP_GEO_API_V2}${getLocalityByIdEndpoint(localityId)}`, configWithAuth(authToken))
}
export const postLocalidad = (data, authToken) => {
  return axios.post(`${REACT_APP_GEO_API_V2}${getLocalities}`, data, configWithAuth(authToken))
}

export const putLocalidad = (id, data, authToken) =>
  axios.put(`${REACT_APP_GEO_API_V2}${deleteUpdateLocalidad(id)}`, data, configWithAuth(authToken))
export const deleteLocalidadId = (id, authToken) =>
  axios.delete(`${REACT_APP_GEO_API_V2}${deleteUpdateLocalidad(id)}`, configWithAuth(authToken))