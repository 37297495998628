import React, { useEffect, useMemo, useState } from 'react'
import { Box, Spinner, Text, Link, Stack, useToast } from '@chakra-ui/core'
import {
  deactivateAppById,
  deleteBranchOfficesByAppId,
  deleteDetalleByAppId,
  getApplications,
  patchApplicationsEnableSocialNetworks,
} from '../../../../api/application'
import { useSelector } from 'react-redux'
import Table from '../../../common/table'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import { useAsyncToastNotification } from '../../../hooks'
import ApplicationSearchForm from '../application-search-form'
import { getCountriesState, getToken, getUserApplication, getUserRolId } from '../../../../redux/selectors'
import Pagination from '../../../common/pagination'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from '..'
import { toastError } from '../../../common/toast-default-config'
import { useSectionData } from '../../../../hooks/useSectionData'
import { Panel } from '../../../common/helpers'
import { patchCategoriaUbicacionSeccionShowState } from '../../../../api/intranet/marca-blanca/banner'
import RemoteSwitch from '../../../common/remote-switch'
import { getActiveTemplate } from '../../../../api/intranet/marca-blanca'

export default function Applications({ addRecordSuccess }) {
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const { t } = useTranslation(['common', 'forms', 'applications'])
  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const token = useSelector(getToken)
  const userRolId = useSelector(getUserRolId)
  const { paisId: countryId } = useSelector(getUserApplication)
  const permisosUser = userRolId == 1 ? true : false
  const toast = useToast()
  const countries = useSelector(getCountriesState)
  const [totalPages, setTotalPages] = useState(0)

  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)

  const [deactivateAlert, setDeactivateAppAlert] = useState({
    show: false,
    appData: null,
  })

  const truncateString = str => {
    return str.length > 40 ? str.substring(0, 37) + '...' : str
  }

  const columns = useMemo(() => {
    const col = [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: 'nombreFantasia',
        Cell: ({ cell }) => (
          <p title={cell.row.original.nombreFantasia || ''}>{truncateString(cell.row.values.nombreFantasia)}</p>
        ),
      },
      {
        Header: t('forms:country'),
        accessor: 'paisId',
        Cell: ({ cell }) => <p>{countries?.find(c => c.id === cell.row.values.paisId)?.nombre}</p>,
      },
      {
        Header: t('forms:domain'),
        accessor: 'dominio',
      },
      {
        Header: <span className='center'>{t('common:social_networks')}</span>,
        accessor: 'enableSocialNetwork',
        Cell: ({ row }) => {
          const { createdAt, ...switchData } = row.original
          return (
            <RemoteSwitch
              apiCall={patchApplicationsEnableSocialNetworks}
              propertyKey='enableSocialNetworks'
              data={switchData}
              initialState={!!switchData.enableSocialNetworks}
            />
          )
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) =>
          cell.row.original.activa ? (
            <Stack isInline spacing='4px'>
              <Link as={RouterLink} to={`/applications/edit/${cell.row.values.id}`}>
                <ButtonEdit />
              </Link>
              <ButtonDelete
                isDisabled={!cell.row.original.activa}
                onClick={() => prepareDeactivateApp(cell.row.original)}
              />
            </Stack>
          ) : (
            <Box fontWeight='bold' color='red.600'>
              {t('common:deleted')}
            </Box>
          ),
      },
    ]
    return col
  }, [t, countries])

  const hideAlert = () => setDeactivateAppAlert(state => ({ ...state, show: false }))

  function prepareDeactivateApp(appData) {
    setDeactivateAppAlert(state => ({
      ...state,
      show: true,
      appData,
    }))
  }

  async function deactivateApp() {
    const { appData } = deactivateAlert
    await asyncNotificationWrapper(async () => {
      const res = await deactivateAppById(appData.id, token)

      try {
        await deleteDetalleByAppId(appData.id, token)
        await deleteBranchOfficesByAppId(appData.id, token)
      } catch (error) {
        console.log(error)
      }

      return res.status
    })

    setDeactivateAppAlert(state => ({
      ...state,
      show: false,
      dataUser: null,
    }))

    getFilteredApp()
  }

  const getFilteredApp = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)
    handleSectionData(SECTION_DATA_KEYS.agencias, {
      ...sectionData.agencias,
      isLoading: true,
    })

    try {
      let response
      response = await getApplications({
        ...filtersAux,
        paisId: permisosUser ? filtersAux.paisId : countryId,
      })
      let res = []
      for (const app of response.data.data) {
        const template = await getActiveTemplate(app.id, token)
        const appWithTemplate = { ...app, template }
        res.push(appWithTemplate)
      }
      setTotalPages(response.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.agencias, {
        data: res,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.agencias, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastError)
    }
  }

  useEffect(() => {
    getFilteredApp()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, addRecordSuccess])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getFilteredApp()
      }
    }
  }

  const deleteUserString = `${t('common:delete')} ${t('applications:application').toLowerCase()}`

  useEffect(() => {
    handleResetSectionData()
    getFilteredApp(true)
    if (!permisosUser) filters.paisId = countryId
  }, [resetFilters])

  return (
    <>
      <AlertDialogSimple
        isOpen={deactivateAlert.show}
        onClose={hideAlert}
        onAccept={deactivateApp}
        title={deleteUserString}
        acceptLabel={t('common:delete')}
        acceptVariantColor='red'
      >
        {t('common:deleting')} <br />
        <b>{deactivateAlert.appData && `${deactivateAlert.appData.nombreFantasia} (#${deactivateAlert.appData.id})`}</b>
      </AlertDialogSimple>
      <Panel rounded='md' mb='1.5rem'>
        <ApplicationSearchForm onFilter={onFilter} />
      </Panel>
      {!sectionData.isLoading && <Table columns={columns} data={sectionData.agencias.data} />}

      {!sectionData.isLoading && totalPages ? (
        <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
      ) : (
        ''
      )}
      <Box bg='white' mb='20px'>
        {sectionData.isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!sectionData.isLoading && !sectionData.agencias.data && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!sectionData.isLoading && sectionData.error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
      </Box>
    </>
  )
}
