import React, { useState, useEffect } from 'react'
import { deleteUserById, getAllUsers, updateUser } from '../../../../api/application'
import { useSelector } from 'react-redux'
import { Box, Stack, Button, useDisclosure, useToast, Spinner, FormLabel } from '@chakra-ui/core'
import { Panel } from '../../../common/helpers'
import Table from '../../../common/table'
import { MdBuild, MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { IoMdTrash, IoMdUnlock } from 'react-icons/io'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import CreateUser from './create-user'
import ModalSimple from '../../../common/modal-simple'
import EditUser from './edit-user'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useParams } from 'react-router-dom'
import { getUserApplicationId, getUserInformation, getUserRolId, getUserRolName } from '../../../../redux/selectors'
import { allowedRolesToEdit } from '../../../../helpers/commons'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './users-section-data'
import { useSectionData } from '../../../../hooks/useSectionData'
import Pagination from '../../../common/pagination'
import UserSearchForm from './user-search-form'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'

export default function Users() {
  const { t } = useTranslation(['applications', 'common', 'forms', 'login'])
  const appIdAgency = useSelector(getUserApplicationId)
  const { appId } = useParams()
  const token = useSelector(state => state.appState.user.token)
  const modalEditDisclosure = useDisclosure()
  const modalEditDisclosure2 = useDisclosure()
  const modalEditDisclosure3 = useDisclosure()
  const [appID, setAppID] = useState(undefined)
  const [newPassword, setNewPassword] = useState(undefined)
  const [totalPages, setTotalPages] = useState(0)
  const userRolName = useSelector(getUserRolName)
  const userInfo = useSelector(getUserInformation)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId === 1 ? true : false
  const permisosUserCountry = userRolId === 2 ? true : false
  const toast = useToast()
  const [inputType, setInputType] = useState('password')
  const [message, setMessage] = useState('')
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/
  const [editingUserData, setEditingUserData] = useState(null)
  const [deleteUserAlert, setDeleteUserAlert] = useState({
    show: false,
    dataUser: null,
  })
  const [blockUserAlert, setBlockUserAlert] = useState({
    show: false,
    dataUser: null,
  })
  const [passwordUserAlert, setPasswordUserAlert] = useState({
    show: false,
    dataUser: null,
  })
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const onCloseAlertDelete = () => setDeleteUserAlert(state => ({ ...state, show: false }))
  const onCloseAlertBlock = () => setBlockUserAlert(state => ({ ...state, show: false }))

  const columns = React.useMemo(() => {
    function prepareEditUser(dataUser) {
      setEditingUserData(dataUser)
      modalEditDisclosure.onOpen()
    }
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('applications:user'),
        accessor: 'username',
      },
      {
        Header: t('common:rol'),
        accessor: 'rol.name',
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => {
          return (
            <Stack
              style={{ display: 'grid', gap: '.5rem', gridTemplateColumns: 'repeat(4, 1fr)', maxWidth: '28rem' }}
              spacing='4px'
              isInline
            >
              <Button
                leftIcon={MdBuild}
                variantColor='blue'
                variant='outline'
                size='xs'
                onClick={() => prepareEditUser(cell.row.original)}
              >
                {t('common:edit')}
              </Button>
              <Button
                leftIcon={IoMdTrash}
                variantColor='red'
                variant='outline'
                size='xs'
                onClick={() => prepareDeleteUser(cell.row.original)}
              >
                {t('common:delete')}
              </Button>
              {permisosUser || permisosUserCountry ? (
                <Button
                  leftIcon={IoMdUnlock}
                  variantColor='green'
                  variant='outline'
                  size='xs'
                  onClick={() => prepareBlockUser(cell.row.original)}
                >
                  {cell.row.original.activo ? t('common:block') : t('common:unblock')}
                </Button>
              ) : (
                ''
              )}
              {permisosUser || permisosUserCountry ? (
                <Button
                  leftIcon={IoMdUnlock}
                  variantColor='orange'
                  variant='outline'
                  size='xs'
                  onClick={() => preparePasswordUser(cell.row.original)}
                >
                  {'Password'}
                </Button>
              ) : (
                ''
              )}
            </Stack>
          )
        },
      },
    ]
  }, [modalEditDisclosure, t])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getUsers()
      }
    }
  }

  const getUsers = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)

    handleSectionData(SECTION_DATA_KEYS.usuarios, {
      ...sectionData.usuarios,
      isLoading: true,
    })

    try {
      let response
      response = await getAllUsers(token, {
        ...filtersAux,
        aplicacionId: allowedRolesToEdit.includes(userRolName) ? parseInt(appId) : appIdAgency,
      })
      setTotalPages(response.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.usuarios, {
        data: response.data.data.filter((u) => u.username.toLowerCase() !== userInfo.username.toLowerCase()),
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.usuarios, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    setAppID(allowedRolesToEdit.includes(userRolName) ? parseInt(appId) : appIdAgency)
  }, [])

  function prepareDeleteUser(dataUser) {
    setDeleteUserAlert(state => ({
      ...state,
      show: true,
      dataUser,
    }))
  }

  function prepareBlockUser(dataUser) {
    setBlockUserAlert(state => ({
      ...state,
      show: true,
      dataUser,
    }))
  }

  function preparePasswordUser(dataUser) {
    modalEditDisclosure3.onOpen()
    setPasswordUserAlert(state => ({
      ...state,
      show: true,
      dataUser,
    }))
  }

  // const handleDeleteUser = async () => {
  //   const { dataUser } = deleteUserAlert
  //   try {
  //     await deleteUserById(dataUser.id, token)
  //     getUsers()
  //   } catch (error) {
  //     alert('Error getting data')
  //   }
  //   setDeleteUserAlert(state => ({
  //     ...state,
  //     show: false,
  //     dataUser: null,
  //   }))
  // }

  const handleDeleteUser = async () => {
    const { dataUser } = deleteUserAlert
    try {
      await deleteUserById(dataUser.id, token)
      getUsers()
      toast({
        title: t('forms:actionCompleted'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      alert('Error getting data')
    }
    setDeleteUserAlert(state => ({
      ...state,
      show: false,
      dataUser: null,
    }))
  }

  const handleBlockUser = async () => {
    const { dataUser } = blockUserAlert
    try {
      let data = {}
      if (dataUser.activo) {
        data = { activo: false, adminBlock: true, username: dataUser.username }
      } else {
        data = {
          activo: true,
          adminBlock: false,
        }
      }
      await updateUser(dataUser.id, data, token)
      toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      getUsers()
    } catch (error) {
      alert('Error getting data')
    }
    setBlockUserAlert(state => ({
      ...state,
      show: false,
      dataUser: null,
    }))
  }

  const handlePasswordUser = async () => {
    const { dataUser } = passwordUserAlert
    try {
      await updateUser(dataUser.id, { password: newPassword }, token)
      toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      setNewPassword(undefined)
      getUsers()
    } catch (error) {
      alert('Error getting data')
    }
    setPasswordUserAlert(state => ({
      ...state,
      show: false,
      dataUser: null,
    }))
  }

  function onFinishEditUser() {
    getUsers()
    modalEditDisclosure.onClose()
  }

  useEffect(() => {
    getUsers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
    getUsers(true)
  }, [resetFilters])

  useEffect(() => {
    if (!regex.test(newPassword)) {
      setMessage(t('forms:passwordValidation'))
    } else {
      setMessage('')
    }
  }, [newPassword])

  const deleteUserString = `${t('common:delete')} ${t('applications:user').toLowerCase()}`
  const editUserString = `${t('common:edit')} ${t('applications:user').toLowerCase()}`
  const createUserString = `${t('common:create')} ${t('applications:user').toLowerCase()}`

  return (
    <Box flex='2' style={{ paddingRight: '1rem' }}>
      <>
        <AlertDialogSimple
          isOpen={blockUserAlert.show}
          onClose={onCloseAlertBlock}
          onAccept={handleBlockUser}
          title={`${
            blockUserAlert.dataUser && blockUserAlert.dataUser.activo ? t('common:block') : t('common:unblock')
          } ${t('applications:user')}`}
          acceptLabel={
            blockUserAlert.dataUser && blockUserAlert.dataUser.activo ? t('common:block') : t('common:unblock')
          }
          acceptVariantColor={blockUserAlert.dataUser && blockUserAlert.dataUser.activo ? 'red' : 'blue'}
        >
          <p>{blockUserAlert.dataUser && `${blockUserAlert.dataUser.username} (#${blockUserAlert.dataUser.id})`}</p>
        </AlertDialogSimple>

        <AlertDialogSimple
          isOpen={deleteUserAlert.show}
          onClose={onCloseAlertDelete}
          onAccept={handleDeleteUser}
          title={deleteUserString}
          acceptLabel={t('common:delete')}
          acceptVariantColor='red'
        >
          <p>{deleteUserAlert.dataUser && `${deleteUserAlert.dataUser.username} (#${deleteUserAlert.dataUser.id})`}</p>
        </AlertDialogSimple>

        <ModalSimple
          {...modalEditDisclosure3}
          title={`${t('common:edit')} ${t('login:password').toLowerCase()}`}
          size='400px'
          style={{ borderRadius: '5px' }}
        >
          <Box style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <b>
              {passwordUserAlert.dataUser &&
                `${passwordUserAlert.dataUser.username} (#${passwordUserAlert.dataUser.id})`}
            </b>

            <Box>
              <FormLabel htmlFor='rol.id' isRequired>
                {t('login:password')}
              </FormLabel>
              <TextField
                onChange={e => setNewPassword(e.target.value)}
                type='text'
                variant='outlined'
                id='password'
                size='small'
                name='password'
                style={{ borderColor: '#E2E8F0' }}
                InputProps={{
                  style: {
                    fontFamily: inputType,
                  },
                  endAdornment: (
                    <InputAdornment className='hide-password' position='end' style={{ marginLeft: '6rem' }}>
                      <IconButton
                        onClick={() =>
                          inputType === 'password' ? setInputType(`'Nunito', sans-serif`) : setInputType('password')
                        }
                      >
                        {inputType === 'password' ? (
                          <MdVisibility style={{ color: '#1f418f' }} />
                        ) : (
                          <MdVisibilityOff style={{ color: '#1f418f' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Box>

            {message && newPassword && (
              <Box
                style={{
                  marginTop: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '.25rem',
                  fontWeight: '600',
                  justifyContent: 'center',
                  border: '1px solid #E53E3B',
                  color: '#E53E3B',
                  fontSize: '.85rem',
                  padding: '.5rem',
                  width: '95%',
                  borderRadius: '5px',
                  alignSelf: 'center',
                }}
              >
                <Box>{t('forms:passwordValidation')}</Box>
                <Box>{t('forms:specialCh')}</Box>
              </Box>
            )}

            <Box style={{ display: 'flex', gap: '1rem', justifyContent: 'center', marginTop: '1.5rem' }}>
              <Button onClick={() => modalEditDisclosure3.onClose()} variantColor='red' variant='outline'>
                {t('common:cancel')}
              </Button>
              <Button
                isDisabled={message || !newPassword}
                variantColor='blue'
                variant='outline'
                type='submit'
                onClick={() => {
                  modalEditDisclosure3.onClose()
                  return handlePasswordUser()
                }}
              >
                {t('forms:save')}
              </Button>
            </Box>
          </Box>
        </ModalSimple>

        <ModalSimple style={{ borderRadius: '5px' }} {...modalEditDisclosure} title={editUserString} size='800px'>
          <EditUser onFinish={onFinishEditUser} dataUser={editingUserData} />
        </ModalSimple>

        <ModalSimple
          isOpen={modalEditDisclosure2.isOpen}
          onClose={modalEditDisclosure2.onClose}
          title={createUserString}
          size='800px'
          style={{ borderRadius: '5px' }}
        >
          <CreateUser
            appId={appID}
            onFinish={() => {
              modalEditDisclosure2.onClose()
              return getUsers()
            }}
          />
        </ModalSimple>

        <Button mb={6} variantColor='blue' onClick={() => modalEditDisclosure2.onOpen()}>
          {t('common:create')} {t('applications:user').toLowerCase()}
        </Button>
        <Panel rounded='md' mb='1.5rem'>
          <UserSearchForm onFilter={onFilter} />
        </Panel>
        <Box>
          {sectionData.usuarios.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}
          {!sectionData.usuarios.isLoading && sectionData.usuarios.data !== null ? (
            <Table columns={columns} data={sectionData?.usuarios?.data} />
          ) : (
            ''
          )}
          {!sectionData.usuarios.isLoading && totalPages ? (
            <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
          ) : (
            ''
          )}
        </Box>
      </>
    </Box>
  )
}
