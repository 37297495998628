import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, FormLabel, useToast, Text } from '@chakra-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FormInput from '../form-input'
import { getAuthToken, getUserCountryId, getUserApplicationId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { PageContainer } from '../../common/helpers'
import { toastCustomizedError } from '../../common/toast-default-config'
import SelectAsync from '../../common/select-async'
import { getCurrencies } from './../../../api/application'

export function ExchangeForm({ formData = false, getAllExchange = false, onSubmit = false, ...restProps }) {
  const { t } = useTranslation(['common', 'forms', 'exchange', 'banners'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const applicationId = useSelector(getUserApplicationId)
  const toast = useToast()

  const { register, handleSubmit, formState, control, setValue } = useForm({
    defaultValues: formData,
  })

  const [{ isLoading, currency }, setCurrency] = useState({
    isLoading: false,
    currency: [],
  })

  async function getCountryCurrency() {
    setCurrency(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getCurrencies(authToken)
      setCurrency(state => ({
        currency: response.data,
        isLoading: false,
      }))
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getCountryCurrency()
  }, [])

  function Submit(datos) {
    let data = {
      paisId: countryId,
      aplicacion: { id: applicationId },
      origen: { id: Number(datos.originCurrency) },
      destino: { id: Number(datos.destinationCurrency) },
      cambio: parseFloat(datos.exchangeValue),
      activo: true,
      producto: { id: 1 },
    }

    onSubmit(data)
  }

  return (
    <PageContainer as='form' onSubmit={handleSubmit(Submit)} {...restProps}>
      <Grid
        templateColumns={
          formData === false
            ? { base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }
            : { base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }
        }
        gap={6}
      >
        <Box>
          <FormLabel isRequired mr='1'>
            {t('exchange:originCurrency')}
          </FormLabel>
          <Controller
            isRequired
            control={control}
            name='originCurrency'
            defaultValue={formData !== false && formData.categoria_id}
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => onChange(e.target.value)}
                placeholder={`${t('exchange:currency')}`}
                name={name}
                value={value}
                isRequired={true}
              >
                {currency &&
                  currency.map(curr => (
                    <option key={curr.id} value={curr.id}>
                      {curr.codigo}
                    </option>
                  ))}
              </SelectAsync>
            )}
          />
        </Box>
        <Box>
          <FormLabel isRequired mr='1'>
            {t('exchange:destinationCureency')}
          </FormLabel>
          <Controller
            isRequired
            control={control}
            name='destinationCurrency'
            defaultValue={formData !== false && formData.categoria_id}
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => onChange(e.target.value)}
                placeholder={`${t('exchange:currency')}`}
                name={name}
                value={value}
                isRequired={true}
              >
                {currency &&
                  currency.map(curr => (
                    <option key={curr.id} value={curr.id}>
                      {curr.codigo}
                    </option>
                  ))}
              </SelectAsync>
            )}
          />
        </Box>
        {/* Nombre */}
        <Box alignItems='center' mb='4'>
          <FormLabel isRequired mr='1'>
            {t('exchange:value')}
          </FormLabel>
          <FormInput
            name='exchangeValue'
            inputProps={{
              isRequired: true,
              type: 'number',
              step: 'any',
              placeholder: `${t('exchange:value')}`,
              ref: register,
            }}
          />
        </Box>

        {/* Guardar Button */}
        <Box d='flex' alignItems='flex-end' justifyContent='center'>
          <Button variantColor='blue' variant='outline' isLoading={formState.isSubmitting} type='submit'>
            {t('common:save')}
          </Button>
        </Box>
      </Grid>
      <Text padding='0.1rem' marginTop='2rem' color='gray.500' fontSize='sm' textAlign='center'>
        {t('banners:important')}
      </Text>
    </PageContainer>
  )
}
