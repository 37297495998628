import React, { useMemo } from "react";
import { Box, Button, Grid, Text, Tag } from "@chakra-ui/core";
import { useTranslation } from "react-i18next";
import { Panel } from "../../../../common/helpers";

export const PackageImage = ({
	onchangeImages,
	state,
	...restProps
}) => {

	const { t } = useTranslation(["common", "forms", "packages", "suppliers"]);
	//console.log('state en package-image',state)

	//Cloudinary
	const {
		REACT_APP_CLOUDINARY_CLOUD_NAME,
		REACT_APP_CLOUDINARY_PRESET,
		REACT_APP_CLOUDINARY_FOLDER,
	} = process.env;

	const myCropWidget = useMemo(() => {
		//console.log('entré a useMemo')
		return (
			window?.cloudinary?.createUploadWidget({
				cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
				uploadPreset: REACT_APP_CLOUDINARY_PRESET,
				folder: REACT_APP_CLOUDINARY_FOLDER,
				cropping: true
			}, (error, result) => {
				if (error) {
					alert("Cloudinary service unavailable");
				}
				checkUploadResult(result);
			})
		);
	}, []);

	//console.log('myCropWidget',myCropWidget)

	function checkUploadResult(resultEvent) {
		if (resultEvent.event === "success") {
			const img = [{
				...state.paqueteImagenes[0],
				imageUrl: resultEvent.info.secure_url,
			}];
			onchangeImages(img);
		}
	}

	function showWidget() {
		myCropWidget.open();
	}

	return (
		<Panel mb="8">
			<Tag width="100%" mb="3" p="3" bg="blue.500" color="white">
				{t("packages:mainPicture")}
			</Tag>
			<Grid
				templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
				gap={6}
			>
				{/* imagen URL */}
				<img
					src={
						state.paqueteImagenes[0].imageUrl
							? state.paqueteImagenes[0].imageUrl
							: 'https://res.cloudinary.com/travelit/image/upload/v1516125474/paquete-detalle-default_oraso7.jpg'
					}
					style={{ maxWidth: "680px", maxHeight: '300px', borderRadius: '0.5rem' }}
				/>
				<Box className="col-md-6" overflow="auto">
					<Text>{t("packages:recommendedWidthAndHeight")}:</Text>
					<Text color="gray.500" fontSize="sm">680x300</Text>
					<Text>{t("packages:maximumImageUpload")}:</Text>
					<Text color="gray.500" fontSize="sm">1 MB</Text>
				</Box>
			</Grid>
			<Button
				size="sm"
				onClick={showWidget}
				variantColor="blue"
				variant="outline"
				alignSelf="center"
				mt={2}
			>
				{t("suppliers:uploadImage")}
			</Button>
		</Panel>
	);
};
