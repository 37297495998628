import React, { useState, useEffect } from 'react'
import { MdBuild } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import { Box, Stack, Button, useDisclosure, useToast, Spinner, Text } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './localidad-section-data'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import ModalSimple from '../../common/modal-simple'
import Table from '../../common/table'
import { toastCustomizedError } from '../../common/toast-default-config'
import { useSectionData } from '../../../hooks/useSectionData'
import { Panel } from '../../common/helpers'
import Pagination from '../../common/pagination'
import { getAuthToken, getCountriesState } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { deleteLocalidadId, getAllLocalidadesPaginado } from '../../../api/geo'
import LocalidadSearchForm from './localidad-search-form'
import CreateLocalidadForm from './create-localidad'
import EditLocalidad from './edit-localidad'
import { FaCheck } from 'react-icons/fa'

export const Localidad = () => {
  const { t } = useTranslation(['applications', 'common', 'forms', 'login', 'comunications'])
  const modalEditDisclosure = useDisclosure()
  const modalEditDisclosure2 = useDisclosure()
  const [totalPages, setTotalPages] = useState(0)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const toast = useToast()
  const countries = useSelector(getCountriesState)
  const [editingLocalidadData, setEditingLocalidadData] = useState(null)
  const [dataLocalidadAlert, setDeleteLocalidadAlert] = useState({
    show: false,
    dataRubro: null,
  })

  const {
    filters,
    handleFilters,
    sectionData,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const onCloseAlertDelete = () => setDeleteLocalidadAlert(state => ({ ...state, show: false }))

  const columns = React.useMemo(() => {
    function prepareEditLocalidad(dataRubro) {
      setEditingLocalidadData(dataRubro)
      modalEditDisclosure.onOpen()
    }

    function truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }

    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('comunications:country'),
        accessor: 'pais',
        Cell: ({ cell }) => <p>{truncateText(cell.row.values.provincia.pais.nombre)}</p>,
      },
      {
        Header: t('forms:province'),
        accessor: 'provincia',
        Cell: ({ cell }) => <p>{truncateText(cell.row.values.provincia.nombre, 30)}</p>,
      },
      {
        Header: t('forms:location'),
        accessor: 'nombre',
        Cell: ({ cell }) => <p>{truncateText(cell.row.values.nombre, 30)}</p>,
      },
      {
        Header: t('forms:origin'),
        accessor: 'isOrigin',
        Cell: ({ cell }) => <p>{cell.row.values.isOrigin ? <FaCheck size={20} color="#5ccb5f" /> : '-'}</p>
      },
      {
        Header: t('forms:destino'),
        accessor: 'isDestination',
        Cell: ({ cell }) => <p>{cell.row.values.isDestination ? <FaCheck size={20} color="#5ccb5f" /> : '-'}</p>
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => {
          return (
            <Stack
              style={{ display: 'grid', gap: '.5rem', gridTemplateColumns: 'repeat(2, 1fr)', maxWidth: '10rem' }}
              spacing='4px'
              isInline
            >
              <Button
                leftIcon={MdBuild}
                variantColor='blue'
                variant='outline'
                size='xs'
                onClick={() => prepareEditLocalidad(cell.row.original)}
              >
                {t('common:edit')}
              </Button>
              <Button
                leftIcon={IoMdTrash}
                variantColor='red'
                variant='outline'
                size='xs'
                onClick={() => prepareDeleteLocalidad(cell.row.original)}
              >
                {t('common:delete')}
              </Button>
            </Stack>
          )
        },
      },
    ]
  }, [modalEditDisclosure, t])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getLocalidad()
      }
    }
  }
  
  const getLocalidad = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)

    handleSectionData(SECTION_DATA_KEYS.localidad, {
      ...sectionData.localidad,
      isLoading: true,
    })

    try {
      const response = await getAllLocalidadesPaginado(filtersAux)

      setTotalPages(response.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.localidad, {
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.localidad, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getLocalidad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function prepareDeleteLocalidad(dataLocalidad) {
    setDeleteLocalidadAlert(state => ({
      ...state,
      show: true,
      dataLocalidad,
    }))
  }

  const handleDeleteLocalidad = async () => {
    const { dataLocalidad } = dataLocalidadAlert
    try {
      await deleteLocalidadId(dataLocalidad.id)
      getLocalidad()
      toast({
        title: t('forms:actionCompleted'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      alert('Error getting data')
    }
    setDeleteLocalidadAlert(state => ({
      ...state,
      show: false,
      dataRubro: null,
    }))
  }

  function onFinishEditLocalidad() {
    getLocalidad()
    modalEditDisclosure.onClose()
  }

  useEffect(() => {
    getLocalidad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
     getLocalidad(true)
  }, [resetFilters])

  const deleteLocalidadString = `${t('common:delete')} ${t('applications:location')}`
  const editLocalidadString = `${t('common:edit')} ${t('applications:location')}`
  const createLocalidadString = `${t('common:create')} ${t('applications:location')}`

  return (
    <Box flex='2' style={{ paddingRight: '1rem' }}>
      <>
        <AlertDialogSimple
          isOpen={dataLocalidadAlert.show}
          onClose={onCloseAlertDelete}
          onAccept={handleDeleteLocalidad}
          title={deleteLocalidadString}
          acceptLabel={t('common:delete')}
          acceptVariantColor='red'
        >
          <p>
            {dataLocalidadAlert.dataRubro &&
              `${dataLocalidadAlert.dataRubro.nombre}`}
          </p>
        </AlertDialogSimple>

        <ModalSimple style={{ borderRadius: '5px' }} {...modalEditDisclosure} title={editLocalidadString} size='800px'>
          <EditLocalidad onFinish={onFinishEditLocalidad} formData={editingLocalidadData} />
        </ModalSimple>

        <ModalSimple
          isOpen={modalEditDisclosure2.isOpen}
          onClose={modalEditDisclosure2.onClose}
          title={createLocalidadString}
          size='800px'
          style={{ borderRadius: '5px' }}
        >
          <CreateLocalidadForm
            onFinish={() => {
              modalEditDisclosure2.onClose()
              return getLocalidad()
            }}
          />
        </ModalSimple>

        <Button mb={6} variantColor='blue' onClick={() => modalEditDisclosure2.onOpen()}>
          {t('common:create')} {t('applications:location')}
        </Button>
        <Panel rounded='md' mb='1.5rem'>
          <LocalidadSearchForm onFilter={onFilter} />
        </Panel>
        <Box>
          {sectionData?.localidad?.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}
          {!sectionData?.localidad?.isLoading && sectionData.localidad.data.length > 0 ? (
            <Table columns={columns} data={sectionData?.localidad?.data} />
          ) : (
            ''
          )}
          {!sectionData?.localidad?.isLoading && totalPages ? (
            <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} selectPage={false}/>
          ):''} 
          {!sectionData?.localidad?.isLoading && sectionData.localidad.data.length === 0 ?(
            <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
          ) : null }
        </Box>
      </>
    </Box>
  )
}
