import React, { useEffect, useMemo, useState } from 'react'
import { Box, FormLabel, useToast, Button, Heading, Grid, Stack, useDisclosure } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAuthToken, getUserCountryId } from '../../../redux/selectors'
import { Panel } from './../../common/helpers'
import FormInput from '../../forms/form-input'

import {
  createRegistrosTipoCambio,
  deleteRegistrosTipoCambio,
  getProvidersExtra,
  getRegistrosTipoCambio,
  updateRegistrosTipoCambio,
} from '../../../api/application'
import Table from '../../common/table'
import { ButtonDelete, ButtonEdit } from '../../forms/buttons/edit'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import Pagination from '../../common/pagination'
import MultiSelectMenu from '../../common/multiselect-dropdown'
import ModalSimple from '../../common/modal-simple'

export const ExchangeAdminCountry = () => {
  const { t } = useTranslation(['common', 'forms', 'exchange', 'consultations', 'comunications'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const [euro, setEuro] = useState('')
  const [dolar, setDolar] = useState('')
  const [date, setDate] = useState('')
  const [dateEdit, setDateEdit] = useState('')
  const [euroEdit, setEuroEdit] = useState('')
  const [dolarEdit, setDolarEdit] = useState('')
  const [value, setValue] = useState('')
  const modalDisclosure = useDisclosure()
  const [selectedOptions, setSelectedOption] = useState([])
  const [registros, setRegistros] = useState([])
  const [editProviderId, setEditProviderId] = useState('')
  const [nombre, setNombre] = useState('')
  const [created, setCreated] = useState(false)
  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: null,
    endDate: null,
  })
  const [focusedInput, setFocusedPicker] = useState(null)
  const [proveedoresExtra, setProveedoresExtra] = useState([])
  const toast = useToast()
  const [page, setPage] = useState(1)
  const maxPerPage = 15
  const [totalPages, setTotalPages] = useState(0)

  async function getRegistros(data) {
    try {
      let res
      if (data) {
        res = await getRegistrosTipoCambio({ ...data, maxPerPage }, authToken)
      } else {
        res = await getRegistrosTipoCambio({ paisId: countryId, maxPerPage, page }, authToken)
      }
      if (res) {
        setTotalPages(res.data.totalPages)
        setRegistros(res.data.data)
      }
    } catch (error) {
      if (error.message) {
        console.log(error.message)
      }
    }
  }

  async function getProveedoresExtra() {
    try {
      const res = await getProvidersExtra(authToken)
      if (res) setProveedoresExtra(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function deleteRegister(id) {
    try {
      await deleteRegistrosTipoCambio(id, authToken)
      toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      setPage(page)
      getRegistros()
    } catch (error) {
      console.log(error)
    }
  }

  async function editRegister() {
    try {
      const data = {
        dolar: parseFloat(dolarEdit),
        euro: parseFloat(euroEdit),
        paisId: countryId,
        fecha: new Date(dateEdit),
      }
      await updateRegistrosTipoCambio(editProviderId, data, authToken)
      toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      modalDisclosure.onClose()
      getRegistros()
      setPage(page)
      setEditProviderId('')
    } catch (error) {
      console.log(error)
    }
  }

  async function onSubmit(e) {
    e.preventDefault()
    let data
    if (selectedOptions.length) {
      data = selectedOptions.map(proveedor => {
        return {
          dolar: parseFloat(dolar),
          euro: parseFloat(euro),
          proveedor: { id: parseInt(proveedor.id) },
          paisId: countryId,
          fecha: new Date(date),
        }
      })

      try {
        data.forEach(async d => {
          return await createRegistrosTipoCambio(d, authToken)
        })
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        setEuro('')
        setDolar('')
        setDate('')
        setSelectedOption([])
        setProveedoresExtra([])
        setCreated(true)
        setValue('')
        getRegistros()
        getProveedoresExtra()
      } catch (error) {
        console.log(error)
      }
    } else {
      toast(toastCustomizedError(t('forms:selectProvider'), ''))
    }
  }

  async function filterList() {
    const data = {
      paisId: countryId,
      startDate: startDate ? moment(startDate).startOf('d').toDate() : null,
      endDate: endDate ? moment(endDate).endOf('d').toDate() : null,
      nombre,
    }

    getRegistros(data)
  }

  async function cleanFilters() {
    setNombre('')
    setRangePicker({
      startDate: null,
      endDate: null,
    })
    getRegistros()
  }

  async function onChangeSelect(e) {
    const filter = e.filter(p => p.id !== '')
    setSelectedOption(filter)
  }

  useEffect(() => {
    getRegistros()
  }, [page])

  useEffect(() => {
    getRegistros()
    setPage(1)
  }, [created])

  useEffect(() => {
    getProveedoresExtra()
  }, [])

  const registerColumns = useMemo(() => {
    const col = [
      {
        Header: t('common:Id'),
        accessor: 'id',
      },
      {
        Header: t('consultations:date'),
        accessor: 'fecha',
        Cell: ({ cell }) => {
          const formattedDate = moment.utc(cell.row.values.fecha).format('DD/MM/YYYY')
          return (
            <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '.5rem' }}>
              <Box style={{ margin: '0rem' }}>{formattedDate}</Box>
            </Box>
          );
        },
      },
      {
        Header: t('forms:supplier'),
        accessor: 'proveedor.nombre',
      },
      {
        Header: t('exchange:dolar'),
        accessor: 'dolar',
      },
      {
        Header: t('exchange:euro'),
        accessor: 'euro',
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => {
          return (
            <Box style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '.5rem' }}>
              <ButtonEdit
                onClick={() => {
                  setEditProviderId(cell.row.values.id)
                  return modalDisclosure.onOpen()
                }}
              ></ButtonEdit>
              <ButtonDelete onClick={() => deleteRegister(cell.row.values.id)} />
            </Box>
          )
        },
      },
    ]
    return col
  }, [])

  return (
    <Box>
      <Heading style={{ fontSize: '1.8rem', color: '#103059', marginBottom: '2rem' }}>
        {t('exchange:changeType')}
      </Heading>
      <Panel as='form' onSubmit={onSubmit} rounded='md' mb='1rem'>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}
          gap={6}
          style={{ alignItems: 'center' }}
        >
          <Box style={{ marginBottom: '.4rem' }}>
            <FormLabel isRequired>{t('exchange:dolar')}</FormLabel>
            <FormInput
              inputProps={{
                type: 'number',
              }}
              style={{ height: '2.6rem', marginBottom: '.2rem' }}
              onChange={e => setDolar(e.target.value)}
              id='dolar'
              name='dolar'
              value={dolar}
              isRequired
            ></FormInput>
          </Box>
          <Box style={{ marginBottom: '.4rem' }}>
            <FormLabel isRequired>{t('exchange:euro')}</FormLabel>
            <FormInput
              style={{ height: '2.6rem', marginBottom: '.2rem' }}
              onChange={e => setEuro(e.target.value)}
              id='euro'
              inputProps={{
                type: 'number',
              }}
              name='euro'
              isRequired
              value={euro}
            ></FormInput>
          </Box>

          <Box style={{ marginBottom: '.4rem' }}>
            <FormLabel isRequired>{t('comunications:date')}</FormLabel>
            <FormInput
              style={{ height: '2.6rem', marginBottom: '.2rem' }}
              onChange={e => setDate(e.target.value)}
              id='date'
              inputProps={{
                type: 'date',
              }}
              name='date'
              isRequired
              value={date}
            ></FormInput>
          </Box>

          <Box style={{ marginBottom: '.6rem' }}>
            <FormLabel isRequired>{t('forms:supplier')}</FormLabel>
            <Stack position='relative'>
              <MultiSelectMenu
                change={e => onChangeSelect(e)}
                name='proveedoresExtra'
                value={selectedOptions}
                label={t('forms:select')}
                options={proveedoresExtra}
                selectedOptionsAux={selectedOptions}
              />
            </Stack>
          </Box>

          <Box>
            <Button variantColor='blue' variant='outline' type='submit' style={{ marginTop: '1rem' }}>
              {t('common:save')}
            </Button>
          </Box>
        </Grid>
      </Panel>

      <Box>
        <Panel rounded='md' mb='1rem'>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }} gap={6}>
            <Box style={{ gridColumn: '1/4', display: 'grid', gap: '1.5rem', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <DateRangePicker
                showDefaultInputIcon={true}
                isOutsideRange={() => false}
                required
                startDate={startDate}
                startDateId='fechaInicio'
                endDate={endDate}
                endDateId='fechaFin'
                focusedInput={focusedInput}
                onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
                onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
                small
                orientation='horizontal'
                displayFormat='DD/MM/YYYY'
              />

              <FormInput
                inputProps={{
                  type: 'text',
                  placeholder: `${t('forms:name')} ${t('forms:supplier')}`,
                }}
                id='nombre'
                name='nombre'
                value={nombre}
                onChange={e => setNombre(e.target.value)}
              ></FormInput>
            </Box>

            <Box style={{ gridColumn: '4/6', display: 'flex', gap: '1.5rem' }}>
              <Button onClick={filterList} variantColor='blue' variant='outline' type='submit'>
                {t('common:filter')}
              </Button>
              <Button
                variant='outline'
                variantColor='blue'
                type='button'
                onClick={() => {
                  cleanFilters()
                }}
              >
                {t('comunications:cleanFilter')}
              </Button>
            </Box>
          </Grid>
        </Panel>
        <Table columns={registerColumns} data={registros} />
        {totalPages ? <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} /> : ''}
      </Box>

      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${t('exchange:changeType')}`}
        size='lg'
      >
        <Panel>
          <Box style={{ display: 'flex', gap: '1rem' }}>
            <Box style={{ marginBottom: '.4rem' }}>
              <FormLabel isRequired>{t('exchange:dolar')}</FormLabel>
              <FormInput
                inputProps={{
                  type: 'number',
                }}
                style={{ height: '2.6rem', marginBottom: '.2rem' }}
                onChange={e => setDolarEdit(e.target.value)}
                id='dolar'
                name='dolar'
                isRequired
                value={dolarEdit}
              ></FormInput>
            </Box>
            <Box style={{ marginBottom: '.4rem' }}>
              <FormLabel isRequired>{t('exchange:euro')}</FormLabel>
              <FormInput
                inputProps={{
                  type: 'number',
                }}
                style={{ height: '2.6rem', marginBottom: '.2rem' }}
                onChange={e => setEuroEdit(e.target.value)}
                id='euro'
                name='euro'
                isRequired
                value={euroEdit}
              ></FormInput>
            </Box>
            <Box style={{ marginBottom: '.4rem' }}>
              <FormLabel isRequired>{t('comunications:date')}</FormLabel>
              <FormInput
                style={{ height: '2.6rem', marginBottom: '.2rem' }}
                onChange={e => setDateEdit(e.target.value)}
                id='date'
                inputProps={{
                  type: 'date',
                }}
                name='date'
                isRequired
                value={dateEdit}
              ></FormInput>
            </Box>
          </Box>
          <Box>
            <Button
              variantColor='blue'
              variant='outline'
              type='submit'
              style={{ marginTop: '1rem' }}
              onClick={editRegister}
            >
              {t('common:save')}
            </Button>
          </Box>
        </Panel>
      </ModalSimple>
    </Box>
  )
}
