import { SUPPLIERS_RESET_DATA, SUPPLIERS_UPDATE_KEY } from "../actions/actions-type";
import initialState from "./initial-state";
import { createReducer } from "./reducer-creator";

const updateKey = (state, action) => ({
	...state,
	...action.payload,
});

const resetSupplierData = (state) => ({
	...state,
	agreements: { isLoading: true, error: false, items: null },
	contacts: { isLoading: true, error: false, items: null },
	offices: { isLoading: true, error: false, items: null },
});

export default createReducer(initialState.suppliersState, {
	[SUPPLIERS_UPDATE_KEY]: updateKey,
	[SUPPLIERS_RESET_DATA]: resetSupplierData
});