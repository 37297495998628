import React, { useState, useEffect } from 'react'
import { PageContainer } from '../../common/helpers'
import { useTranslation } from 'react-i18next'
import { Heading, Image, Spinner, useDisclosure, useToast } from '@chakra-ui/core'
import { Box, SimpleGrid, Flex } from '@chakra-ui/core'
import { Panel } from '../../common/helpers'
import { getIncentives, getStatusEscala } from '../../../api/incentives'
import { getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import IncentivesSearchForm from './incentives-search-form'
import Pagination from '../../common/pagination'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from '.'
import { toastError } from '../../common/toast-default-config'
import CustomTable from '../../common/custom-table'
import ProbabilityCell from './ProbabilityCell'
import ModalSimple from '../../common/modal-simple'
import usePrepareIncentiveRow from './hooks/usePrepareIncentiveRow'

function IncentivesContent() {
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  // - - - -❗GENERAL❗- - - -

  const { t } = useTranslation(['comunications', 'common', 'forms', 'incentives'])
  const countryId = useSelector(getUserCountryId)
  const userRolId = useSelector(getUserRolId)
  const imageViewDisclosure = useDisclosure()
  const permisosUser = userRolId == 1 ? true : false
  const toast = useToast()
  const prepareIncentive = usePrepareIncentiveRow()

  // - - - -❗STATES❗- - - -

  const [totalPages, setTotalPages] = useState(0)

  const [selectedImg, setSelectedImg] = useState(undefined)

  const onCloseImageModal = () => {
    if (selectedImg) {
      setSelectedImg(undefined)
    }
    imageViewDisclosure.onClose()
  }

  // - - - - - 📞 API CALLS 📞 - - - - -

  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)

  const removeQueryParameter = param => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.delete(param)

    const newUrl = window.location.pathname
    window.history.replaceState(null, '', newUrl)
  }

  const getFilteredIncentives = async (reset = false) => {
    const urlParams = new URLSearchParams(window.location.search)

    let proveedorId = filters.proveedorId
    let categoriaId = filters.categoriaId

    if (urlParams.size) {
      proveedorId = urlParams.get('proveedorId') || filters.proveedorId
      categoriaId = urlParams.get('categoriaId') || filters.categoriaId
      removeQueryParameter('proveedorId')
      removeQueryParameter('categoriaId')
    }

    const filtersAux = reset ? { ...defaultFiltersValues } : { ...filters, proveedorId, categoriaId }
    setLastFilters(filtersAux)
    handleSectionData(SECTION_DATA_KEYS.incentivos, {
      ...sectionData.incentivos,
      isLoading: true,
    })
    try {
      let response

      response = await getIncentives({
        ...filtersAux,
        paisId: permisosUser ? filtersAux.paisId : countryId,
        sinEscalas: true,
      })
      setTotalPages(response.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.incentivos, {
        data: await Promise.all(response.data.data.map(prepareIncentive)),
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.incentivos, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastError)
    }
  }

  useEffect(() => {
    getFilteredIncentives()
  }, [filters.page])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getFilteredIncentives()
      }
    }
  }

  useEffect(() => {
    if (resetFilters !== 0) {
      handleResetSectionData()
      getFilteredIncentives(true)
    }
    if (!permisosUser) filters.paisId = countryId
  }, [resetFilters])

  useEffect(() => {
    if (selectedImg) {
      imageViewDisclosure.onOpen()
    }
  }, [selectedImg])

  return (
    <>
      <PageContainer>
        <Box backgroundColor='' py='10px' pb='0.5%' mt='' color='#103059'>
          <Heading style={{ fontSize: '1.8rem' }}>{t('incentives:incentives')}</Heading>
        </Box>
        <Flex style={{ flexDirection: 'column' }}>
          <Panel rounded='md' mb='1.5rem'>
            <IncentivesSearchForm onFilter={onFilter} />
          </Panel>
          {sectionData.incentivos.isLoading ? (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          ) : (
            <CustomTable
              expandable
              columns={[
                {
                  title: '',
                  dataIndex: 'imagenUrl',
                  render: ({ proveedor }) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Image
                          objectFit='contain'
                          style={{
                            width: '12rem',
                            height: '5rem',
                            alignContent: 'right',
                            borderRadius: '0.25rem',
                            cursor: 'zoom-in',
                          }}
                          onClick={() => setSelectedImg(proveedor.imagen)}
                          src={proveedor?.imagen}
                        />
                      </div>
                    )
                  },
                  key: 'supplierLogo',
                },
                {
                  title: <div style={{ textAlign: 'left' }}>{t('incentives:supplier')}</div>,
                  dataIndex: 'proveedor.nombre',
                  render: ({ proveedor }) => {
                    return <div style={{ textAlign: 'left' }}>{proveedor?.nombre}</div>
                  },
                  key: 'name',
                },
                {
                  title: t('incentives:incentive_name'),
                  dataIndex: 'nombre',
                  key: 'incentiveName',
                },
                {
                  title: t('incentives:amountReached'),
                  key: 'percentage',
                  render: ({ filteredStatusEscala }) => `${(filteredStatusEscala?.avanceDeBarra * 100).toFixed(2)}%`,
                },
                {
                  title: t('incentives:probabilityOfAchievement'),
                  key: 'probability',
                  render: row => <ProbabilityCell {...row} />,
                },
                {
                  title: t('incentives:incentive'),
                  key: 'incentivoProcentaje',
                  render: ({ filteredStatusEscala }) => `${filteredStatusEscala.incentivoPorcentaje}%`,
                },
              ]}
              dataSource={sectionData.incentivos.data?.sort((a, b) =>
                a.proveedor?.nombre.localeCompare(b.proveedor?.nombre),
              )}
              ifEmpty={
                <Panel rounded='md' mb='1.5rem' fontSize='1.25rem' width='100%'>
                  {t('incentives:noResults')}
                </Panel>
              }
            />
          )}
        </Flex>

        {!!totalPages && <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />}
      </PageContainer>
      <ModalSimple isOpen={imageViewDisclosure.isOpen} onClose={onCloseImageModal} size='3xl'>
        <Image
          style={{
            margin: '3rem auto 0 auto',
            borderRadius: '.25rem',
            height: 'auto',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
          src={selectedImg}
        />
      </ModalSimple>
    </>
  )
}

export default IncentivesContent
