import React, { useState, useEffect } from 'react'
import { MdBuild } from 'react-icons/md'
import { IoMdTrash } from 'react-icons/io'
import { Box, Stack, Button, useDisclosure, useToast, Spinner } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './comercial-section-data'
import { deleteComercial, getAllPaginatedComerciales } from '../../../api/application'
import EditComercial from './edit-comercial'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import ModalSimple from '../../common/modal-simple'
import Table from '../../common/table'
import { toastCustomizedError } from '../../common/toast-default-config'
import { useSectionData } from '../../../hooks/useSectionData'
import CreateComercialForm from './create-comercial'
import ComercialSearchForm from './comercial-search-form'
import { Panel } from '../../common/helpers'
import Pagination from '../../common/pagination'
import { useSelector } from 'react-redux'
import { getCountriesState } from '../../../redux/selectors'

export const Comercial = () => {
  const { t } = useTranslation(['applications', 'common', 'forms', 'login', 'comunications'])
  const modalEditDisclosure = useDisclosure()
  const modalEditDisclosure2 = useDisclosure()
  const [totalPages, setTotalPages] = useState(0)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const toast = useToast()
  const countries = useSelector(getCountriesState)
  const [editingComercialData, setEditingComercialData] = useState(null)
  const [dataComercialAlert, setDeleteComercialAlert] = useState({
    show: false,
    dataComercial: null,
  })

  const {
    filters,
    handleFilters,
    sectionData,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const onCloseAlertDelete = () => setDeleteComercialAlert(state => ({ ...state, show: false }))

  const columns = React.useMemo(() => {
    function prepareEditComerial(dataComercial) {
      setEditingComercialData(dataComercial)
      modalEditDisclosure.onOpen()
    }

    function truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    }    
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('comunications:country'),
        accessor: 'paisId',
        Cell: ({ cell }) => <p>{countries?.find(c => c.id === cell.row.values.paisId)?.nombre}</p>,
      },
      {
        Header: t('forms:name'),
        accessor: 'nombre',
        Cell: ({ cell }) => <p>{truncateText(cell.row.values.nombre, 30)}</p>,
      },
      {
        Header: t('forms:lastname'),
        accessor: 'apellido',
        Cell: ({ cell }) => <p>{truncateText(cell.row.values.apellido, 30)}</p>,
      },
      {
        Header: t('applications:email'),
        accessor: 'emailContacto',
        Cell: ({ cell }) => (
          <p>{cell.row.values.emailContacto ? truncateText(cell.row.values.emailContacto, 30) : null}</p>
        ),
      },
      {
        Header: t('applications:phone'),
        accessor: 'telefonoContacto',
        Cell: ({ cell }) => (
          <p>{cell.row.values.telefonoContacto ? truncateText(cell.row.values.telefonoContacto, 30) : null}</p>
        ),
      },
      {
        Header: t('applications:cell'),
        accessor: 'celularContacto',
        Cell: ({ cell }) => (
        <p>{cell.row.values.celularContacto ? truncateText(cell.row.values.celularContacto, 30): null }</p>),
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => {
          return (
            <Stack
              style={{ display: 'grid', gap: '.5rem', gridTemplateColumns: 'repeat(2, 1fr)', maxWidth: '10rem' }}
              spacing='4px'
              isInline
            >
              <Button
                leftIcon={MdBuild}
                variantColor='blue'
                variant='outline'
                size='xs'
                onClick={() => prepareEditComerial(cell.row.original)}
              >
                {t('common:edit')}
              </Button>
              <Button
                leftIcon={IoMdTrash}
                variantColor='red'
                variant='outline'
                size='xs'
                onClick={() => prepareDeleteComercial(cell.row.original)}
              >
                {t('common:delete')}
              </Button>
            </Stack>
          )
        },
      },
    ]
  }, [modalEditDisclosure, t])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getComercial()
      }
    }
  }

  const getComercial = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)

    handleSectionData(SECTION_DATA_KEYS.comercial, {
      ...sectionData.comercial,
      isLoading: true,
    })

    try {
      const response = await getAllPaginatedComerciales(filtersAux)

      setTotalPages(response.data.totalPages)
      handleSectionData(SECTION_DATA_KEYS.comercial, {
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.comercial, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getComercial()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function prepareDeleteComercial(dataComercial) {
    setDeleteComercialAlert(state => ({
      ...state,
      show: true,
      dataComercial,
    }))
  }

  const handleDeleteComercial = async () => {
    const { dataComercial } = dataComercialAlert
    try {
      await deleteComercial(dataComercial.id)
      getComercial()
      toast({
        title: t('forms:actionCompleted'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      alert('Error getting data')
    }
    setDeleteComercialAlert(state => ({
      ...state,
      show: false,
      dataComercial: null,
    }))
  }

  function onFinishEditComercial() {
    getComercial()
    modalEditDisclosure.onClose()
  }

  useEffect(() => {
    getComercial()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
    getComercial(true)
  }, [resetFilters])

  const deleteComercialString = `${t('common:delete')} ${t('applications:comercial')}`
  const editComercialString = `${t('common:edit')} ${t('applications:comercial')}`
  const createComercialString = `${t('common:create')} ${t('applications:comercial')}`

  return (
    <Box flex='2' style={{ paddingRight: '1rem' }}>
      <>
        <AlertDialogSimple
          isOpen={dataComercialAlert.show}
          onClose={onCloseAlertDelete}
          onAccept={handleDeleteComercial}
          title={deleteComercialString}
          acceptLabel={t('common:delete')}
          acceptVariantColor='red'
        >
          <p>
            {dataComercialAlert.dataComercial &&
              `${dataComercialAlert.dataComercial.nombre} ${dataComercialAlert.dataComercial.apellido}`}
          </p>
        </AlertDialogSimple>

        <ModalSimple style={{ borderRadius: '5px' }} {...modalEditDisclosure} title={editComercialString} size='800px'>
          <EditComercial onFinish={onFinishEditComercial} formData={editingComercialData} />
        </ModalSimple>

        <ModalSimple
          isOpen={modalEditDisclosure2.isOpen}
          onClose={modalEditDisclosure2.onClose}
          title={createComercialString}
          size='800px'
          style={{ borderRadius: '5px' }}
        >
          <CreateComercialForm
            onFinish={() => {
              modalEditDisclosure2.onClose()
              return getComercial()
            }}
          />
        </ModalSimple>

        <Button mb={6} variantColor='blue' onClick={() => modalEditDisclosure2.onOpen()}>
          {t('common:create')} {t('applications:comercial')}
        </Button>
        <Panel rounded='md' mb='1.5rem'>
          <ComercialSearchForm onFilter={onFilter} />
        </Panel>
        <Box>
          {sectionData.comercial.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}
          {!sectionData.comercial.isLoading && sectionData.comercial.data !== null ? (
            <Table columns={columns} data={sectionData?.comercial?.data} />
          ) : (
            ''
          )}
          {!sectionData.comercial.isLoading && totalPages ? (
            <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
          ) : (
            ''
          )}
        </Box>
      </>
    </Box>
  )
}
