import React from 'react'
import { Localidad } from './localidad'
import { SectionDataProvider } from '../../../context/SectionDataContext'

const FILTER_KEYS = {
  nombre: 'nombre',
  paisId: 'paisId',
  provinciaId: 'provinciaId',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  localidad: 'localidad',
}

const defaultSectionDataValues = {
  localidad: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 20,
  nombre: '',
  paisId: '',
  provinciaId: ''
}

const defaultFiltersValues = { ...defaultFilters }

const LocalidadSection = () => (
  <SectionDataProvider defaultFilters={{ ...defaultFiltersValues }} defaultSectionData={defaultSectionDataValues}>
    <Localidad />
  </SectionDataProvider>
)

export {
  LocalidadSection,
  FILTER_KEYS,
  SECTION_DATA_KEYS,
  defaultSectionDataValues,
  defaultFiltersValues,
  defaultFilters,
}
