import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, FormLabel, Grid, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, Text, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import SelectAsync from '../../../common/select-async'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import { getAllTransports, getAllTripTypes } from '../../../../api/intranet/requests'
import { getAuthToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { getAllCitiesQuery } from '../../../../api/geo'
import FormInput from '../../../forms/form-input'

//Material ui
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

//for date picker
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import Table from '../../../common/table'
import { toastCustomizedError } from '../../../common/toast-default-config'
import { MdBuild } from 'react-icons/md'

export const Trips = ({ onChange, departureData, onDelete, trigerSave, cancel }) => {
  const { t } = useTranslation(['common', 'forms', 'packages'])
  const authToken = useSelector(getAuthToken)

  const [editTrip, setEditTrip] = useState({
    stopDetails: [],
    departureDate: moment().format('YYYY-MM-DDTHH:mm'),
    arrivalDate: moment().format('YYYY-MM-DDTHH:mm'),
    transportType: { id: undefined },
    tripType: { id: undefined },
    company: '',
    travelCode: '',
    originLocalidad: { provincia: { nombre: '' }, nombre: '' },
    destinationLocalidad: { provincia: { nombre: '' }, nombre: '' },
  })
  const [idEdit, setIdEdit] = useState(-1)
  const [readOnly, setReadOnly] = useState(false)

  const [enableStopDetails, setEnableStopDetails] = useState(false)
  const toast = useToast()

  //SELECTOR HORA
  function generarOpciones(max) {
    let opciones = []
    for (let num = 0; num <= max; num++) {
      let cero = '0'
      if (num >= 10) {
        cero = ''
      }
      opciones.push(<option value={cero + num}> {cero + num}</option>)
    }
    return opciones
  }

  function onChangeTrip(e, flg = null) {
    switch (flg) {
      case 'string':
        setEditTrip({ ...editTrip, [e.target.name]: e.target.value })
        break
      case 'arrivalDate':
        setEditTrip({ ...editTrip, [flg]: e })
        break
      case 'departureDate':
        setEditTrip({ ...editTrip, [flg]: e })
        break
      default:
    }
  }

  function onChangeTransportType(event) {
    setEditTrip({
      ...editTrip,
      transportTypeId: Number(event.target.value),
      transportType: transport.items.find(t => t.id.toString() === event.target.value.toString()),
    })
  }

  function onChangeTripType(event) {
    setEditTrip({
      ...editTrip,
      tripTypeId: Number(event.target.value),
      tripType: tripTypes.items.find(t => t.id.toString() === event.target.value.toString()),
    })
  }

  //TRAER LOCALIDADES / DESTINOS
  const [loc, setLoc] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getLocalidades(city = null) {
    setLoc(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCitiesQuery({ search: city, destOrigin: true })
      setLoc(state => ({
        ...state,
        isLoading: false,
        items: (res.data && res.data) || [],
      }))
    } catch (error) {
      setLoc(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  const [cityOrigins, setCityOrigins] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  const [cityDestinations, setCityDestinations] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getCityOrigins(city) {
    setCityOrigins(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCitiesQuery({ search: city, origins: true })
      setCityOrigins(() => ({
        error: false,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setCityOrigins(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }
  async function getCityDestinationas(city) {
    setCityDestinations(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCitiesQuery({ search: city, destinations: true })
      setCityDestinations(() => ({
        error: false,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setCityDestinations(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }

  function handleSearchStopover(e) {
    if (e.target.value.length > 2) {
      getLocalidades(e.target.value)
    } else {
      setLoc({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  function handleSearchOrigins(e) {
    if (e.target.value.length > 2) {
      getCityOrigins(e.target.value)
    } else {
      setCityOrigins({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }
  function handleSearchDestination(e) {
    if (e.target.value.length > 2) {
      getCityDestinationas(e.target.value)
    } else {
      setCityDestinations({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  function handleChangeCity(value, name, idx = -1) {
    if (value) {
      switch (name) {
        case 'originLocalidadId':
          setEditTrip({ ...editTrip, [name]: value.id, originLocalidad: value })
          break
        case 'detinationLocalidadId':
          setEditTrip({ ...editTrip, [name]: value.id, destinationLocalidad: value })
          break
        case 'stopoverCityId':
          editTrip.stopDetails.splice(idx, 1, {
            ...editTrip.stopDetails[idx],
            localidadId: value.id,
            stopoverCity: value,
          })
          setEditTrip({ ...editTrip })
          break
        default:
          break
      }
    }
  }

  //TIPO DE TRANSPORTE
  const [transport, setTransport] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getTransports() {
    setTransport({ ...transport, isLoading: true })
    try {
      const { data } = await getAllTransports(authToken)
      setTransport({ ...transport, isLoading: false, items: data.data || [] })
    } catch (error) {
      setTransport({ ...transport, isLoading: false, error: true })
    }
  }

  //Tipo de viaje (Ida-vuelta)
  const [tripTypes, setTripTypes] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getTripTypes() {
    setTripTypes({ ...tripTypes, isLoading: true })
    try {
      const { data } = await getAllTripTypes(authToken)
      setTripTypes({ ...tripTypes, isLoading: false, items: data.data || [] })
    } catch (error) {
      setTransport({ ...tripTypes, isLoading: false, error: true })
    }
  }
  useEffect(() => {
    getTransports()
    getTripTypes()
  }, [])
  let filteredTripTypes = {
    items: tripTypes.items.filter(i => !departureData?.trips?.map(trip => trip?.tripType?.id).includes(i.id)),
  }

  function handleAddTrip() {
    onChange(editTrip, 'trips', idEdit)
    cleanForm()
  }

  function cleanForm() {
    setEditTrip({
      stopDetails: [],
      departureDate: moment().format('YYYY-MM-DDTHH:mm'),
      arrivalDate: moment().format('YYYY-MM-DDTHH:mm'),
      transportType: { id: undefined },
      tripType: { id: undefined },
      company: '',
      travelCode: '',
      originLocalidad: { provincia: { nombre: '' }, nombre: '' },
      destinationLocalidad: { provincia: { nombre: '' }, nombre: '' },
    })
    filteredTripTypes = { items: tripTypes.items }
    setIdEdit(-1)
    setReadOnly(false)
  }

  function onTripEditClick(index, data) {
    const formattedDepartureDate = moment(data.departureDate).format('YYYY-MM-DDTHH:mm')
    const formattedArrivalDate = moment(data.arrivalDate).format('YYYY-MM-DDTHH:mm')
    setEditTrip({
      ...data,
      departureDate: formattedDepartureDate,
      arrivalDate: formattedArrivalDate,
      stopDetails: data.stopDetails.map(detail => {
        return {
          ...detail,
          waitingHours: detail.waitingHours <= 9 ? `0${Number(detail.waitingHours)}` : detail.waitingHours,
          waitingMinutes: detail.waitingMinutes <= 9 ? `0${Number(detail.waitingMinutes)}` : detail.waitingMinutes,
        }
      }),
    })
    setIdEdit(index)
    setReadOnly(data?.id ? false : true)
  }

  function onChangeStopoverCount(value) {
    if (editTrip?.stopDetails?.length < value) {
      // more stopovers, concat to the array
      const newStopovers = []
      for (let i = 0; i < value - editTrip.stopDetails.length; i++) {
        newStopovers.push({ localidadId: undefined, waitingHours: 0, waitingMinutes: 0 })
      }
      setEditTrip({ ...editTrip, stopDetails: editTrip.stopDetails.concat(newStopovers) })
    } else {
      // less stopovers, slice the array, if value = 0 clean the whole array
      setEditTrip({ ...editTrip, stopDetails: value ? editTrip.stopDetails.slice(0, value) : [] })
      // TODO: make a list of stopDetails to delete and send them to backend on save
    }
  }

  function onChangeWaitingTime(value, index, name) {
    if (name === 'hours') {
      editTrip.stopDetails.splice(index, 1, { ...editTrip.stopDetails[index], waitingHours: value })
    } else if (name === 'minutes') {
      editTrip.stopDetails.splice(index, 1, { ...editTrip.stopDetails[index], waitingMinutes: value })
    }
    setEditTrip({ ...editTrip })
  }

  const columns = useMemo(() => [
    {
      Header: t('packages:tripType'),
      accessor: row => {
        return row.tripType?.description
      },
    },
    {
      Header: t('forms:origin'),
      accessor: row => {
        return `${row.originLocalidad.provincia?.pais.nombre} - ${row.originLocalidad.provincia.nombre} - ${row.originLocalidad.nombre}`
      },
    },
    {
      Header: t('forms:destination'),
      accessor: row => {
        return `${row.destinationLocalidad.provincia?.pais.nombre} - ${row.destinationLocalidad.provincia.nombre} - ${row.destinationLocalidad.nombre}`
      },
    },
    {
      Header: t('packages:stopover'),
      accessor: row => {
        return row.stopDetails.length
      },
    },
    {
      Header: t('common:actions'),
      accessor: null,
      Cell: ({ cell }) => {
        return (
          <Stack align={'center'} spacing='5px' isInline d='flex' mx='1'>
            <ButtonEdit
              icon={cell.row.original.id ? MdBuild : undefined}
              customLabel={cell.row.original.id ? false : t('packages:seePreview')}
              onClick={() => {
                return onTripEditClick(cell.row.index, cell.row.original)
              }}
            />
            <ButtonDelete
              onClick={() => {
                if (idEdit === -1 || readOnly) {
                  cleanForm()
                  onDelete(cell.row.original.tripType.id, 'trips')
                } else {
                  toast(toastCustomizedError(t('packages:notDelete')))
                }
              }}
            />
          </Stack>
        )
      },
    },
  ])

  useEffect(() => {
    cleanForm()
  }, [trigerSave, cancel])

  useEffect(() => {
    if (editTrip.stopDetails.length) {
      setEnableStopDetails(
        editTrip.stopDetails.every(objeto => {
          return (
            objeto.localidadId !== undefined && objeto.waitingHours !== undefined && objeto.waitingMinutes !== undefined
          )
        }),
      )
    }
  }, [editTrip])

  return (
    <form>
      {!!departureData?.trips?.length && (
        <Box mt='20px'>
          <Table columns={columns} data={departureData?.trips} />
        </Box>
      )}
      {!departureData?.trips?.length && (
        <Box bg='white' mb='20px' mt='8'>
          <Text textAlign={'center'} color='gray.500' px='5' py='5'>
            {t('common:zeroItems')}
          </Text>
        </Box>
      )}

      <Divider />

      {(idEdit > -1 || !!filteredTripTypes?.items?.length) && (
        <>
          <Text fontWeight='bold' style={{ paddingBottom: '10px', paddingLeft: '5px', paddingTop: '10px' }}>
            {t('packages:destinationAndDate')}
          </Text>
          <Box px='1'>
            <SelectAsync
              onChange={e => {
                onChangeTripType(e)
              }}
              isDisabled={departureData?.trips.length === 2 || readOnly}
              placeholder={`${t('forms:selectOption')}`}
              name='tripTypeId'
              value={editTrip.tripType?.id | undefined}
              width={'25%'}
            >
              {idEdit < 0 &&
                filteredTripTypes.items &&
                filteredTripTypes.items?.map(t => (
                  <option key={t.id} value={t.id}>
                    {t.description}
                  </option>
                ))}
              {idEdit > -1 &&
                tripTypes.items &&
                tripTypes.items?.map(t => (
                  <option key={t.id} value={t.id}>
                    {t.description}
                  </option>
                ))}
            </SelectAsync>
          </Box>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: '33% 66%' }} mb='2' mt='1' gap={2}>
            {/* Origen */}
            <Box d='flex' alignItems='center' alignItems='flex-end' px='1' py='20px'>
              <Autocomplete
                id='originLocalidadId'
                name='originLocalidadId'
                options={cityOrigins.items}
                getOptionLabel={option =>
                  option.nombre ? `${option.provincia.pais.nombre} - ${option.provincia.nombre} - ${option.nombre}` : ''
                }
                style={{ width: '100%' }}
                size='small'
                value={editTrip.originLocalidad}
                onChange={(event, newValue) => {
                  handleChangeCity(newValue, 'originLocalidadId')
                }}
                disabled={readOnly}
                renderInput={params => (
                  <TextField
                    onChange={e => {
                      handleSearchOrigins(e)
                    }}
                    {...params}
                    required
                    label={t('forms:origin')}
                    variant='outlined'
                  />
                )}
              />
            </Box>
            {/* Fecha de salida */}
            <Box alignItems='center' display='flex'>
              <FormLabel isRequired width='127px'>
                {t('forms:departureDate')}:
              </FormLabel>
              <Box pr='5px'>
                <TextField
                  id='departureDate'
                  type='datetime-local'
                  value={editTrip.departureDate}
                  size='small'
                  defaultValue={editTrip.departureDate}
                  variant='outlined'
                  disabled={readOnly}
                  onChange={e => {
                    onChangeTrip(e.target.value, 'departureDate')
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: '33% 66%' }} mb='2' mt='1' gap={2}>
            {/* Destino */}
            <Box d='flex' alignItems='center' alignItems='flex-end' px='1' py='20px'>
              <Autocomplete
                id='detinationLocalidadId'
                name='detinationLocalidadId'
                options={cityDestinations.items}
                getOptionLabel={option =>
                  option.nombre ? `${option.provincia.pais.nombre} - ${option.provincia.nombre} - ${option.nombre}` : ''
                }
                size='small'
                style={{ width: '100%' }}
                value={editTrip.destinationLocalidad}
                onChange={(event, newValue) => {
                  handleChangeCity(newValue, 'detinationLocalidadId')
                }}
                disabled={readOnly}
                renderInput={params => (
                  <TextField
                    onChange={e => {
                      handleSearchDestination(e)
                    }}
                    {...params}
                    required
                    label={t('forms:destino')}
                    variant='outlined'
                  />
                )}
              />
            </Box>
            {/* Fecha de llegada */}
            <Box alignItems='center' display='flex'>
              <FormLabel isRequired>{t('forms:arrivalDate')}:</FormLabel>
              <Box pr='5px'>
                <TextField
                  id='arrivalDate'
                  type='datetime-local'
                  size='small'
                  defaultValue={editTrip.arrivalDate}
                  value={editTrip.arrivalDate}
                  variant='outlined'
                  disabled={readOnly}
                  onChange={e => {
                    onChangeTrip(e.target.value, 'arrivalDate')
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Box>
          </Grid>

          <Divider />

          <Text fontWeight='bold' style={{ paddingBottom: '10px', paddingLeft: '5px', paddingTop: '10px' }}>
            {t('packages:transportation')}
          </Text>
          <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={2} mb='2' mt='1'>
            <Box px='1'>
              {/* Tipo de Transporte */}
              <FormLabel isRequired>{t('packages:transportationType')}</FormLabel>
              <SelectAsync
                onChange={e => {
                  onChangeTransportType(e)
                }}
                placeholder={`${t('forms:selectOption')}`}
                name='transportTypeId'
                value={editTrip.transportType?.id | undefined}
                isRequired
                isDisabled={readOnly}
              >
                {transport.items &&
                  transport.items?.map(t => (
                    <option key={t.id} value={t.id}>
                      {t.description}
                    </option>
                  ))}
              </SelectAsync>
            </Box>

            {/* Empresa */}
            <Box alignItems='center'>
              <FormLabel mr='1' isRequired>
                {t('packages:company')}
              </FormLabel>
              <FormInput
                name='company'
                onChange={e => onChangeTrip(e, 'string')}
                value={editTrip.company}
                isRequired
                isDisabled={readOnly}
                inputProps={{
                  type: 'text',
                  placeholder: `${t('packages:company')}`,
                }}
              />
            </Box>

            {/* Código de transporte */}
            <Box alignItems='center'>
              <FormLabel mr='1'>{t('packages:transportCode')}</FormLabel>
              <FormInput
                name='travelCode'
                onChange={e => onChangeTrip(e, 'string')}
                value={editTrip.travelCode}
                isDisabled={readOnly}
                inputProps={{
                  type: 'text',
                  placeholder: `${t('packages:transportCode')}`,
                }}
              />
            </Box>
          </Grid>

          <Divider />
          <Text fontWeight='bold' style={{ paddingBottom: '10px', paddingLeft: '5px', paddingTop: '10px' }}>
            {t('packages:stopover')}
          </Text>
          <Grid>
            {/* Cantidad escalas */}
            <Box>
              <FormLabel style={{ paddingLeft: '5px' }}>{t('packages:stopoverCount')}</FormLabel>
              <NumberInput
                px={1}
                min={0}
                max={500}
                width={'15%'}
                mb={'20px'}
                name='stopoverCount'
                value={editTrip.stopDetails.length}
                onChange={e => onChangeStopoverCount(e)}
                isDisabled={readOnly}
              >
                <NumberInputField isReadOnly/>
                <NumberInputStepper>
                  <NumberIncrementStepper/>
                  <NumberDecrementStepper/>
                </NumberInputStepper>
              </NumberInput>
            </Box>
            <Box maxHeight={'400px'} overflowY={'scroll'}>
              {!!editTrip?.stopDetails?.length &&
                editTrip.stopDetails.map((stopover, idx) => (
                  <Fragment>
                    <Grid
                      templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
                      mb='2'
                      mt='1'
                      pl='5px'
                      gap={2}
                    >
                      <FormLabel>
                        {t('packages:stopoverNumber')} {idx + 1}
                      </FormLabel>
                      <FormLabel>{t('packages:stopoverDuration')}</FormLabel>
                    </Grid>
                    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} mb='2' mt='1' gap={2}>
                      <Box d='flex' alignItems='center' alignItems='flex-end' px='1' py='5px'>
                        <Autocomplete
                          id='stopoverCityId'
                          name='stopoverCityId'
                          options={loc.items}
                          getOptionLabel={option =>
                            option.nombre
                              ? `${option.provincia.pais.nombre} - ${option.provincia.nombre} - ${option.nombre}`
                              : ''
                          }
                          style={{ width: '100%' }}
                          size='small'
                          value={stopover.stopoverCity}
                          onChange={(event, newValue) => {
                            handleChangeCity(newValue, 'stopoverCityId', idx)
                          }}
                          disabled={readOnly}
                          renderInput={params => (
                            <TextField
                              onChange={e => {
                                handleSearchStopover(e)
                              }}
                              {...params}
                              required
                              label={t('packages:stopoverCity')}
                              variant='outlined'
                            />
                          )}
                        />
                      </Box>
                      <Box alignItems='center' display='flex'>
                        {/* Hora llegada */}
                        <Box width='fit-content' pr='5px'>
                          <SelectAsync
                            onChange={e => onChangeWaitingTime(e.target.value, idx, 'hours')}
                            name={`stopoverHours${idx}`}
                            value={stopover.waitingHours}
                            isDisabled={readOnly}
                          >
                            {generarOpciones(23)}
                          </SelectAsync>
                        </Box>
                        <FormLabel isRequired>{t('forms:hour')}</FormLabel>
                        {/* Minutos llegada */}
                        <Box width='fit-content' pr='5px'>
                          <SelectAsync
                            onChange={e => onChangeWaitingTime(e.target.value, idx, 'minutes')}
                            name={`stopoverMinutes${idx}`}
                            value={stopover.waitingMinutes}
                            defaultValue={0}
                            isDisabled={readOnly}
                          >
                            {generarOpciones(59)}
                          </SelectAsync>
                        </Box>
                        <FormLabel isRequired>{t('forms:minutes')}</FormLabel>
                      </Box>
                    </Grid>
                  </Fragment>
                ))}
            </Box>
          </Grid>

          <Divider />
          <Box style={{ display: 'flex', gap: '1rem', marginTop: '20px', marginBottom: '20px' }}>
            <Button
              variantColor='blue'
              variant='outline'
              size='md'
              onClick={() => handleAddTrip()}
              isDisabled={
                !(
                  editTrip.tripType?.id &&
                  editTrip.originLocalidad?.id &&
                  editTrip.destinationLocalidad?.id &&
                  editTrip.transportType?.id &&
                  editTrip.company &&
                  (editTrip.stopDetails.length > 0 && enableStopDetails
                    ? true
                    : editTrip.stopDetails.length === 0
                    ? true
                    : false) &&
                  !readOnly
                )
              }
            >
              {idEdit === -1 ? t('common:addTrip') : t('common:editTrip')}
            </Button>
            <Button
              variantColor='red'
              variant='outline'
              onClick={() => {
                cleanForm()
              }}
            >
              {t('forms:discard')}
            </Button>
          </Box>
          {idEdit !== -1 && !readOnly && (
            <>
              <span style={{ color: 'red', fontSize: '0.8rem' }}>*</span>
              <span style={{ color: 'black', fontSize: '0.8rem' }}>{t('packages:confirmSave')}</span>
            </>
          )}
        </>
      )}
      <Divider />
    </form>
  )
}
