import { Box, Button, Grid } from '@chakra-ui/core'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getCountriesState, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import SelectAsync from '../../common/select-async'
import FormInput from '../../forms/form-input'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS } from '.'

export default function ApplicationSearchForm({ onFilter, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const userCountryId = useSelector(getUserCountryId)
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false
  const { t } = useTranslation(['common', 'forms', 'consultations', 'comunications'])
  const countries = useSelector(getCountriesState)

  const handleNameInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      onFilter();
    }
  }

  useEffect(() => {
    if (!permisosUser) {
      handleFilters(FILTER_KEYS.paisId, userCountryId)
    }
  }, [countries])

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} {...restProps}>
      {permisosUser && (
        <Box>
          <SelectAsync
            onChange={({ target }) => {
              handleFilters(FILTER_KEYS.paisId, target.value)
            }}
            placeholder={`${t('forms:countries')} - ${t('forms:all')}`}
            value={filters.paisId}
          >
            {countries
              ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {nombre}
                </option>
              ))}
          </SelectAsync>
        </Box>
      )}
      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.dominio, e.target.value)}
        onKeyDown={handleNameInputKeyDown}
        value={filters.dominio}
        name='clue'
        inputProps={{
          placeholder: `${t('forms:name')} (${t('common:optional')})`,
        }}
      />
      <Box style={{ display: 'flex', gap: '1rem' }}>
        <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>

        <Button variantColor='blue' variant='outline' onClick={handleResetFilters}>
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
