import { Box, Button, Grid, PseudoBox, Text, Input } from '@chakra-ui/core'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getIcons, getSectionTypes } from '../../../../api/section'
import { readFile } from '../../../../helpers/utils'
import SelectAsync from '../../../common/select-async'
import FormInput from '../../../forms/form-input'
import FormTextArea from '../../../forms/form-textarea'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

export default function OwnSectionForm({ onSubmit, ...restProps }) {
  const { register, handleSubmit, formState } = useForm()
  const { t } = useTranslation(['sections', 'forms'])
  const [selectedSectionType, setSelectedSectionType] = useState(undefined)
  const [contentValue, setContentValue] = useState('')
  const [contentFromFile, setContentFromFile] = useState(undefined)
  const [resetEditor, setResetEditor] = useState(false)
  const [controlAlert, setControlAlert] = useState({
    show: false,
  })
  const [iconTypesRequest, setIconTypesRequest] = useState({
    isLoading: false,
    data: [],
  })
  const [sectionTypesRequest, setSectionTypesRequest] = useState({
    isLoading: false,
    data: [],
  })

  const onCloseControlAlert = () => setControlAlert(state => ({ ...state, show: false }))

  async function getAllIcons() {
    setIconTypesRequest({
      isLoading: true,
    })
    try {
      const response = await getIcons()

      if (response.data) {
        setIconTypesRequest({
          isLoading: false,
          data: response.data,
        })
      }
    } catch (error) {
      setIconTypesRequest({
        isLoading: false,
        data: [],
      })
      console.log(error)
    }
  }

  async function getAllSectionTypes() {
    setSectionTypesRequest({
      isLoading: true,
    })
    try {
      const response = await getSectionTypes()

      if (response.data) {
        setSectionTypesRequest({
          isLoading: false,
          data: response.data,
        })
      }
    } catch (error) {
      setSectionTypesRequest({
        isLoading: false,
        data: [],
      })
      console.log(error)
    }
  }

  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: moment(),
    endDate: null,
  })
  const emptyInput = t('forms:html_file')
  const [fileChargeStatusMsg, setFileChargeStatusMsg] = useState(emptyInput)
  const [focusedInput, setFocusedPicker] = useState(null)

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
      }))
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (restProps.resetPicker) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
        endDate: null,
      }))
    }
  }, [restProps.resetPicker])

  function onSubmitt(data) {
    const tipoSeccionSeleccionada = sectionTypesRequest.data?.find(section => section.nombre === selectedSectionType)
    let datos = {
      ...data,
      startDate: startDate,
      endDate: endDate,
      tipoSeccion: { id: tipoSeccionSeleccionada.id },
      contenido: selectedSectionType === 'HTML' ? contentFromFile || contentValue : data.contenido,
    }
    setContentValue('')
    setContentFromFile(undefined)
    setFileChargeStatusMsg(emptyInput)
    setSelectedSectionType(undefined)
    setResetEditor(!resetEditor)

    onSubmit(datos)
  }

  async function onClose() {
    document.getElementById('customSection-form').reset()
    onCloseControlAlert()
  }

  var toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  const handleImport = async () => {
    const htmlToImport = document.querySelector('#htmlToImport')
    htmlToImport.click()
    htmlToImport.onchange = e => {
      const { files } = e.target
      if (files) {
        setFileChargeStatusMsg(files[0].name)
        readFile(files[0])
          .then(data => {
            setContentFromFile(data)
          })
          .catch(err => {
            setContentFromFile(undefined)
            console.log(err)
          })
      } else if (files === null) {
        setFileChargeStatusMsg(emptyInput)
      }
    }
  }

  useEffect(() => {
    getAllIcons()
    getAllSectionTypes()
  }, [])

  return (
    <PseudoBox
      id='customSection-form'
      as='form'
      onSubmit={handleSubmit(d => onSubmitt(d))}
      {...restProps}
      style={{
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
      }}
    >
      {/* Alert Control select */}
      <AlertDialogSimple
        control
        isOpen={controlAlert.show}
        onClose={onCloseControlAlert}
        onAccept={onClose}
        acceptLabel={t('common:accept')}
      >
        {t('common:emptyContent')} <br />
      </AlertDialogSimple>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={6}
        style={{
          alignItems: 'center',
        }}
      >
        {/* Tipo de seccion */}
        <Box alignItems='center'>
          <SelectAsync
            isRequired
            placeholder={t('sections:typeSection')}
            name='sectionType'
            onChange={e => {
              setSelectedSectionType(e.target.value)
            }}
            nativeProps={{ ref: register }}
            isLoading={sectionTypesRequest.isLoading}
          >
            {sectionTypesRequest.data &&
              sectionTypesRequest.data?.length > 0 &&
              sectionTypesRequest.data.map(section => (
                <option key={section.id} value={section.nombre}>
                  {section?.descripcion}
                </option>
              ))}
          </SelectAsync>
        </Box>
        {/* Nombre */}
        <Box d='flex' alignItems='center' style={{}}>
          <FormInput
            isRequired
            maxLength='255'
            w='100%'
            name='sectionName'
            inputProps={{
              placeholder: `${t('sections:description')}`,
              ref: register,
            }}
          />
        </Box>
      </Grid>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        gap={6}
        mt='5'
        style={{
          alignItems: 'center',
        }}
      >
        {/* Icono */}
        <Box alignItems='center'>
          <SelectAsync
            isRequired
            readOnly={true}
            placeholder={t('sections:icon')}
            name='icon'
            //className="far"
            nativeProps={{ ref: register }}
            isLoading={iconTypesRequest.isLoading}
          >
            {iconTypesRequest.data &&
              iconTypesRequest.data?.length > 0 &&
              iconTypesRequest.data.map(type => (
                <option key={type.id} value={type.id}>
                  {type.descripcion}
                </option>
              ))}
          </SelectAsync>
        </Box>
        {/* data picker */}
        <Box d='flex' alignItems='center' justifyContent='space-between'>
          <Box w='auto' mr='16px'>
            <Text>{t('forms:rangePicker')}</Text>
          </Box>
          <Box flex={1}>
            <DateRangePicker
              startDateId='fechaInicio'
              endDateId='fechaFin'
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
              onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
              small
              displayFormat='DD/MM/YYYY'
            />
          </Box>
        </Box>
      </Grid>
      {/* URL */}
      {selectedSectionType === 'URL' && (
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} mt='5' mb='5'>
          <Box d='flex' alignItems='left' flexDirection='column'>
            <Text mb='3'>URL</Text>
            <FormInput
              isRequired
              readOnly={true}
              name='contenido'
              inputProps={{
                placeholder: 'Url',
                ref: register,
              }}
            />
          </Box>
        </Grid>
      )}
      {selectedSectionType === 'HTML' && (
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap={6} mt='5' mb='5'>
          {/* Editor HTML */}
          <Box d='flex' alignItems='left' flexDirection='column'>
            <Text mb='3'>HTML</Text>
            <Box mb='5' w='100%' d='flex' flexDirection='row'>
              <Button w='auto' mr='16px' onClick={handleImport}>
                {t('forms:browse')}
              </Button>
              <Input w='100%' value={fileChargeStatusMsg} disabled />
            </Box>
            <input type='file' accept='text/html' id='htmlToImport' name='htmlToImport' hidden />
            {contentFromFile ? (
              ''
            ) : (
              <Box
                style={{
                  borderWidth: 0.5,
                  borderColor: '#e5e5e5',
                }}
              >
                <ReactQuill
            style={{ display: 'flex', flexDirection: 'column', height: '460px', marginBottom: '30px' }}
            className=''
            modules={{ toolbar }}
            value={contentValue}
            onChange={setContentValue}
          />
              </Box>
            )}
          </Box>
        </Grid>
      )}
      {/* Script */}
      {selectedSectionType === 'SCRIPT' && (
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }} gap={6} mt='5' mb='5'>
          <Box d='flex' alignItems='left' flexDirection='column'>
            <Text mb='3'>Script</Text>
            <FormTextArea
              readOnly={true}
              isRequired
              w='100%'
              name='contenido'
              inputProps={{
                placeholder: 'Script',
                ref: register,
              }}
            />
          </Box>
        </Grid>
      )}

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
        gap={6}
        mt='5'
        mb='5'
        d='flex'
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        {/* Guardar Button */}
        <Box style={{}}>
          <Button variantColor='blue' variant='outline' isLoading={formState.isSubmitting} type='submit'>
            {t('common:save')}
          </Button>
        </Box>
      </Grid>

      {!iconTypesRequest.isLoading && iconTypesRequest.data && (
        <>
          <Text>{t('common:references')}:</Text>
          <Grid
            templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(6, 1fr)' }}
            gap={6}
            mt='5'
            mb='5'
            style={{ justifyContent: 'space-between' }}
          >
            {iconTypesRequest.data.map((icono, key) => {
              return (
                <Box key={key} d='flex' style={{ justifyContent: 'left', alignItems: 'center' }}>
                  <i className={`${icono.codigo} fa-lg`} style={{ marginRight: '8px' }}></i>
                  <Text mr='5'>{icono.descripcion}</Text>
                </Box>
              )
            })}
          </Grid>
        </>
      )}
    </PseudoBox>
  )
}
