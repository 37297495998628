import React, { useEffect, useMemo, useState } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, IconButton, Box } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/core'
import './styles.css'
import { useTranslation } from 'react-i18next'
import ProbabilityCell from '../../ProbabilityCell'

const IncentiveDetailsTable = ({ data }) => {
  const { t } = useTranslation(['common', 'incentives'])

  const columns = useMemo(
    () => [
      {
        title: t('incentives:initial_goals'),
        key: 'initialGoal',
        render: (row, index) => {
          const incentivoEscala = data.incentive?.incentivoEscala?.find(({ id }) => id === row.escalaId)
          const montoInicial = `${data.incentive?.unidadMedicion.simbolo}
              ${
                incentivoEscala?.montoInicial
                  ? new Intl.NumberFormat('de-DE').format(incentivoEscala?.montoInicial)
                  : '0'
              }`
          return montoInicial
        },
      },
      {
        title: t('incentives:final_goals'),
        key: 'finalGoal',
        render: (row, index) => {
          const incentivoEscala = data.incentive?.incentivoEscala?.find(({ id }) => id === row.escalaId)
          const montoFinal = `${data.incentive?.unidadMedicion.simbolo}
              ${incentivoEscala?.montoFinal ? new Intl.NumberFormat('de-DE').format(incentivoEscala?.montoFinal) : '0'}`
          return montoFinal
        },
      },
      {
        title: t('incentives:incentive'),
        key: 'incentive',
        render: (row, index) => {
          const incentivoEscala = data.incentive?.incentivoEscala?.find(({ id }) => id === row.escalaId)
          return `${incentivoEscala?.incentivoPorcentaje ?? '---'}${
            incentivoEscala?.incentivoPorcentaje.includes('%') ? '' : '%'
          }`
        },
      },
      {
        title: t('incentives:projection'),
        key: 'production',
        render: (row, index) => {
          return `${(row.proyeccion * 100).toFixed(2) ?? '---'}%`
        },
      },
      {
        title: t('incentives:probabilityOfAchievement'),
        key: 'goalProbability',
        render: (row, index) => (
          <ProbabilityCell
            filteredStatusEscala={row}
            fechaFin={data.incentive.fechaFin}
            boxProps={{ style: { justifyContent: 'flex-start' } }}
          />
        ),
      },
      {
        title: t('incentives:achieved'),
        key: 'achievedAmount',
        render: (row, index) => {
          return `${(row.avanceDeBarra * 100).toFixed(2) ?? '---'}%`
        },
      },
    ],
    [data],
  )

  return (
    <Table className='incentive-details-table'>
      <Thead className='incentive-details-table__thead'>
        <Tr className='incentive-details-table__thead__tr'>
          {columns.map(({ title, key }) => (
            <Th className='incentive-details-table__thead__tr__th' key={key}>
              {title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody className='incentive-details-table__tbody'>
        {data.statusScala?.map((row, index) => (
          <React.Fragment key={`${row.id}-${index}-details`}>
            <Tr className='incentive-details-table__tbody__tr'>
              {columns.map(({ dataIndex, render, key }) => (
                <Td className='incentive-details-table__tbody__tr__td' key={`${row.id}-${key}-details-cell`}>
                  {render ? render(row, index) : row[dataIndex]}
                </Td>
              ))}
            </Tr>
            <Tr className='incentive-details-table__tbody__tr'>
              <Td className='incentive-details-table__row-divider' colSpan={columns.length + 1} />
            </Tr>
          </React.Fragment>
        ))}
      </Tbody>
    </Table>
  )
}

export default IncentiveDetailsTable
