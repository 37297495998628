import { useSelector } from "react-redux";

const check = (permissions, actions) => {
	if (Array.isArray(actions)) {
		return actions.some(action => permissions.includes(action));
	} else {
		if (permissions.includes(actions)) {
			// action is in user's permissions
			return true;
		}
	}
	// no coincidence
	return false;
};

const Can = ({ permission = null, yes = () => null, no = () => null }) => {
	const permissions = useSelector(state => state.appState.user.permissions);
	return check(permissions, permission)
		? yes()
		: no();
};

export default Can;