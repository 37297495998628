import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys'

const isSuccess = (status) => status >= 200 && status < 300 ? true : false;

const convertBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
};

const readFile = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsText(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
	});
};

const goTo = (url) => {
	window.open(url, '_blank')
}

const getStorageToken = () => window.localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)

const checkEditedData = (dataToCheck, dataToCompare) => {
	const editedData = Object.fromEntries(
		Object.entries(dataToCheck).filter(([key, value]) => JSON.stringify(value) !== JSON.stringify(dataToCompare[key])),
	)

	return editedData
}

const checkUrlValidity = (url) => {
	return (/(http(s?)):\/\//i.test(url))
}

const youtubeParser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}

export { isSuccess, convertBase64, readFile, goTo, getStorageToken, checkEditedData, checkUrlValidity, youtubeParser };