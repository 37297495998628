import React, { useMemo, useState, useEffect } from 'react'
import { useDisclosure, Text, Spinner, Box, Stack } from '@chakra-ui/core'
import { Panel } from '../../../common/helpers'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import ModalSimple from '../../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import OwnSectionForm from './own-sections-form'
import {
  createSection,
  iconInstance,
  DeleteCustomSection,
  getAllIconTypesEndpoint,
  getAllCustomSections,
  getCompaniesSections,
  DeleteCompanySection,
} from '../../../../api/section'
import Table from '../../../common/table'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserApplicationId } from '../../../../redux/selectors'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import moment from 'moment'
import CreateOwnSection from './create-own-section'
import AlertDialogSimple from '../../../../components/common/alert-dialog-simple'
import { useConfigWithAuth } from '../../../hooks'
import useFetch from '../../../../api/useFetch'
import { isSuccess } from '../../../../helpers/utils'
import Pagination from '../../../common/pagination'
import {
  deleteBanner,
  deleteCategory,
  deleteUbication,
  getBannersByCategory,
  getUbicationSeccion,
} from '../../../../api/intranet/marca-blanca/banner'

export default function OwnSection() {
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const { configAuth } = useConfigWithAuth()
  const appId = useSelector(getUserApplicationId)
  const toast = useToast()
  const [page, setPage] = useState(1)
  const [message, setMessage] = useState('')
  const [dependencies, setDependencies] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const onCloseDeleteAlert2 = () => setDeleteAlert2(state => ({ ...state, show: false }))
  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))
  const modalDisclosure = useDisclosure()
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })
  const [deleteAlert2, setDeleteAlert2] = useState({
    show: false,
    data: null,
  })
  const iconTypesRequest = useFetch({
    api: iconInstance,
    method: 'get',
    url: getAllIconTypesEndpoint(),
    config: configAuth,
  })
  const [{ isLoading, sections }, setSectionsRequest] = useState({
    isLoading: false,
    sections: [],
  })

  async function getSection() {
    try {
      setSectionsRequest(state => ({
        ...state,
        isLoading: true,
      }))

      const res = await getAllCustomSections({ empresaId: appId, activo: true, page })

      setTotalPages(res.data.totalPages)

      if (res.data.totalPages < page) setPage(1)
      setSectionsRequest(state => ({
        ...state,
        isLoading: false,
        sections: res.data.data,
      }))
    } catch (error) {
      console.log(error)

      setSectionsRequest(state => ({
        ...state,
        isLoading: false,
      }))
    }
  }

  useEffect(() => {
    getSection()
  }, [page])

  async function createSections(formData) {
    try {
      const response = await createSection(formData, authToken)
      getSection()

      const status = isSuccess(response.status)
      if (status) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        document.getElementById('customSection-form').reset()
        setResetPicker(!resetPicker)
        getSection()
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  function onSubmit(datos) {
    let formData = {}
    if (Number(datos.icon) !== 0) {
      formData = {
        empresaId: appId,
        descripcion: String(datos.sectionName),
        fechaInicio: datos.startDate._d,
        fechaFin: datos.endDate === null ? null : datos.endDate._d,
        fechaAlta: datos.startDate._d,
        icon: { id: Number(datos.icon) },
        tipoSeccion: datos.tipoSeccion,
        activo: true,
        contenido: datos.contenido,
      }
      createSections(formData)
    } else {
      toast(toastCustomizedError(t('forms:iconFormError')))
    }
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      show: true,
      data,
    }))
  }

  async function onDelete() {
    let sectionId = deleteAlert.data.id
    try {
      const responseUbicacion = await getUbicationSeccion({ seccionId: sectionId })

      if (dependencies && dependencies.length) {
        dependencies.forEach(d => DeleteCompanySection(d.id))
      }

      if (responseUbicacion.data && responseUbicacion.data.length) {
        responseUbicacion.data.forEach(async u => {
          const banners = await getBannersByCategory(u.categoriaBanner.id)
          if (banners.data && banners.data.length) banners.data.forEach(async b => await deleteBanner(b.id))

          return await Promise.all([deleteUbication(u.id), deleteCategory(u.categoriaBanner.id)])
        })
      }

      const response = await DeleteCustomSection(sectionId, authToken)

      const status = isSuccess(response.status)

      if (status) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        onCloseDeleteAlert2()
        onCloseDeleteAlert()
        getSection()
      } else {
        onCloseDeleteAlert2()
        onCloseDeleteAlert()
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      console.log(error)
      onCloseDeleteAlert2()
      onCloseDeleteAlert()
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    onCloseDeleteAlert2()
    onCloseDeleteAlert()
  }

  async function checkDependencies() {
    try {
      const responseDependecie = await getCompaniesSections({ idSeccionCustom: deleteAlert.data.id, all: true })
      const responseUbicacion = await getUbicationSeccion({ seccionId: deleteAlert.data.id })

      if (responseDependecie?.data?.length >= 1 || responseUbicacion?.data?.length >= 1) {
        setMessage(
          <Box>
            <p>
              Esta sección posee dependencias. Si elimina el registro se eliminarán todas las dependencias asociadas.
            </p>
            <p>¿Desea continuar?</p>
          </Box>,
        )
        setDeleteAlert2(state => ({
          ...state,
          show: true,
        }))
        setDependencies(responseDependecie.data || [])
      } else {
        onDelete()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('forms:description')}`,
        accessor: 'descripcion',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.descripcion.length > 26 ? (
              <Text>{cell.row.values.descripcion.substring(0, 26)}...</Text>
            ) : (
              <Text>{cell.row.values.descripcion}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:startDate'),
        accessor: 'fechaInicio',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.values.fechaInicio ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.values.fechaInicio).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:endDate'),
        accessor: 'fechaFin',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {!cell.row.values.fechaFin ? (
              <Text style={{ textAlign: 'center' }}>--</Text>
            ) : (
              <Text>{moment(cell.row.values.fechaFin).format('DD-MM-YYYY')}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:icon'),
        accessor: 'icon',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <p>{cell.row.values.icon.descripcion}</p>
          </Stack>
        ),
      },
      {
        Header: t('forms:typeSection'),
        accessor: 'tipoSeccion',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <Text alignContent='center'>{cell.row.values.tipoSeccion.nombre}</Text>
          </Stack>
        ),
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit mb={4} onClick={() => onEditClick(cell.row.values)}></ButtonEdit>
            <ButtonDelete onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ],
    [t, iconTypesRequest],
  )

  //Edit functions
  const [idSectionn, setIdSection] = useState()
  const [idName, setIdName] = useState()

  function onEditClick(idSectionEdit) {
    setIdSection(idSectionEdit.id)
    setIdName(idSectionEdit.descripcion)
    modalDisclosure.onOpen()
  }
  //Hook reset start y end date en picker
  const [resetPicker, setResetPicker] = useState(false)

  return (
    <Box>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={checkDependencies}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.descripcion} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>

      {/* Alert delete dependencias */}
      <AlertDialogSimple
        isOpen={deleteAlert2.show}
        onClose={() => {
          onCloseDeleteAlert()
          onCloseDeleteAlert2()
        }}
        onAccept={() => {
          onCloseDeleteAlert()
          onDelete()
        }}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.descripcion} #${deleteAlert.data.id}`}</b>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            fontSize: '1.2rem',
          }}
        >
          {message}
        </Box>
      </AlertDialogSimple>

      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${idName}`}
        size='lg'
      >
        <CreateOwnSection
          onFinish={() => null}
          idSectionn={idSectionn}
          getSection={getSection}
          onClose={modalDisclosure.onClose}
        />
      </ModalSimple>
      {/* Referencia iconos */}
      <Panel rounded='md' mb='1.5rem'>
        <OwnSectionForm onSubmit={onSubmit} resetPicker={resetPicker} />
      </Panel>

      <Box>
        {!iconTypesRequest.isLoading && !isLoading && sections?.length >= 1 ? (
          <Box>
            <Table columns={columns} data={sections} />
          </Box>
        ) : null}
        {isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!isLoading && sections?.length === 0 && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!isLoading && !sections && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
        {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
      </Box>
    </Box>
  )
}
