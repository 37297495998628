import { theme } from '@chakra-ui/core'

const breakpoints = ['360px', '768px', '1024px', '1440px']
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]

export default {
  ...theme,
  breakpoints,
  colors: {
    ...theme.colors,
    header: '#1e408f',
    blue: {
      50: '#e6efff',
      100: '#bfcff4',
      200: '#97aee7',
      300: '#6f8edc',
      400: '#476ed1',
      500: '#2f54b8',
      600: '#234290',
      700: '#203F8F',
      800: '#0b1c41',
      900: '#02091b',
    },
  },
}
