import React, { useState, useMemo, useEffect } from 'react'
import {
  useDisclosure,
  Stack,
  Button,
  Box,
  SimpleGrid,
  Text,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/core'
import ModalSimple from '../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import { DateRangePicker } from 'react-dates'
import { Heading } from '@chakra-ui/core'
import { PageContainer, Panel } from '../../common/helpers'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserApplicationId, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import Table from '../../common/table'
import { getClaims, getStates, updateConsulta } from '../../../api/mundigea-gw'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import FormInput from '../../forms/form-input'
import SelectAsync from '../../common/select-async'
import { isSuccess } from '../../../helpers/utils'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './keys'
import Pagination from '../../common/pagination'
import { useForm } from 'react-hook-form'
import ClaimsDetail from './details'

export function Reclamos() {
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
    handleResetFilters,
  } = useSectionData()
  const { t } = useTranslation(['consultations', 'comunications', 'common'])
  const { control } = useForm()
  const userCountryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const rolId = useSelector(getUserRolId)
  const permisosUserCountry = rolId == 2 ? true : false
  const appId = useSelector(getUserApplicationId)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const [totalPages, setTotalPages] = useState(0)

  // DATE PICKER
  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: moment(),
    endDate: null,
  })

  const [focusedInput, setFocusedPicker] = useState(null)

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
      }))
    }
  }, [startDate, endDate])

  // CUSTOMER DATA AND STATES
  const [dataStates, setDataStates] = useState([])
  const [selectFecha, setSelectFecha] = useState('')
  const [{ general }, setDataConsultations] = useState({
    general: {},
  })

  // MODAL
  const modalDisclosure = useDisclosure()
  const [visualDetailsExchange, setDetailsExchange] = useState({
    visualDetailsExchange: true,
  })

  // SET DETAILS AND STATES
  function onDetailsClick(row) {
    modalDisclosure.onOpen()
    setDetailsExchange(false)
    let general = {
      datosPersonales: {
        nombre: row.original.nombre,
        apellido: `${row.original.apellidoPaterno} ${row.original.apellidoMaterno}`,
        tipoDocumento: row.original.tipoDocumento,
        numeroDocumento: row.original.numeroDocumento,
        domicilio: row.original.departamento,
        departamento: row.original.departamento,
        provincia: row.original.provincia,
        distrito: row.original.distrito,
        telefonoFijo: row.original.telefonoFijo,
        telefonoCelular: row.original.telefonoCelular,
        email: row.original.email,
      },
      informacionGeneral: {
        bienContratado: row.original.bienContratado,
        monto: row.original.monto,
        descripcion: row.original.comentarios,
      },
      detalleReclamo: {
        tipoReclamo: row.original.tipoReclamo,
        detalleReclamo: row.original.detalleReclamo,
        pedido: row.original.pedido,
      },
      datosApoderado: {
        menorEdad: row.original.menorEdad,
        nombre: row.original.nombreApoderado,
        apellido: `${row.original.apellidoPaternoApoderado} ${row.original.apellidoMaternoApoderado}`,
        tipoDocumento: row.original.tipoDocumentoApoderado,
        numeroDocumento: row.original.documentoApoderado,
        telefonoFijo: row.original.telefonoFijoApoderado,
        telefonoCelular: row.original.telefonoCelularApoderado,
        email: row.original.emailApoderado,
      },
    }

    setDataConsultations(state => ({
      general: general,
    }))
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('comunications:agency') + ' id',
        accessor: 'agenciaId',
      },
      {
        Header: t('consultations:agencyName'),
        accessor: 'nombreAgencia',
      },
      {
        Header: `${t('consultations:name')} y ${t('consultations:lastName')}`,
        accessor: null,
        Cell: ({ cell }) => (
          <Stack>
            <Text>
              {cell.row.original.nombre} {cell.row.original.apellidoMaterno} {cell.row.original.apellidoPaterno}
            </Text>
          </Stack>
        ),
      },
      {
        Header: t('consultations:phone'),
        accessor: 'telefonoCelular',
      },
      {
        Header: t('consultations:email'),
        accessor: 'email',
      },
      /*     {
        Header: t('consultations:state'),
        accessor: null,
        Cell: ({ cell }) => (
          <Box key={cell.row.original.id}>
            <Controller
              isRequired
              control={control}
              name='estado'
              id={cell.row.original.id}
              defaultValue={cell.row.original.estado?.id}
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => {
                    return changeState(e.target.value, cell.row.original)
                  }}
                  name={name}
                  bg={cell.row.original.estado?.color}
                  value={cell.row.original.estado?.id}
                  id={cell.row.original.id}
                >
                  {dataStates.map(x => (
                    <option key={cell.row.original.id} style={{ backgroundColor: x.color }} value={x.id}>
                      {x.descripcion}
                    </option>
                  ))}
                </SelectAsync>
              )}
            />
          </Box>
        ),
      }, */
      {
        Header: t('consultations:date'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack>
            <Text>{new Date(cell.row.original.createdAt).toLocaleDateString()}</Text>
          </Stack>
        ),
      },
      {
        Header: t('consultations:details'),
        accessor: null,
        Cell: ({ cell }) => (
          <Button style={{ padding: '.5rem' }} colorScheme='blue' onClick={() => onDetailsClick(cell.row)}>
            <b style={{ fontSize: '.85rem' }}>
              {t('consultations:view')} {t('common:navbar.claim')}
            </b>
          </Button>
        ),
      },
    ],
    [dataStates],
  )

  // GET CONSULTATIONS
  async function getReclamos(reset = null) {
    filters.paisId = userCountryId
    if (!permisosUserCountry) filters.agenciaId = appId

    const filtersAux = reset ? defaultFiltersValues : filters

    setLastFilters(filtersAux)

    handleSectionData(SECTION_DATA_KEYS.reclamos, {
      ...sectionData.reclamos,
      isLoading: true,
    })

    try {
      const responseReclamos = await getClaims(filtersAux, authToken)
      let reclamos = await responseReclamos.data.data

      setTotalPages(responseReclamos.data.totalPages)

      handleSectionData(SECTION_DATA_KEYS.reclamos, {
        data: reclamos,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function changeState(value, row) {
    try {
      const id = row.id
      const data = { estado: { id: parseInt(value) } }
      const response = await updateConsulta(id, data, authToken)

      const status = isSuccess(response.status)

      if (status) {
        getReclamos()
        return status
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function getEstados() {
    try {
      const response = await getStates(authToken)
      let getEstados = response.data
      setDataStates(getEstados)
    } catch (error) {
      console.log(error)
    }
  }

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getReclamos()
      }
    }
  }

  useEffect(() => {
    getReclamos()
    //getEstados()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
    getReclamos(true)
    setSelectFecha('')
    setRangePicker({ startDate: moment(), endDate: null })
    filters.paisId = userCountryId
  }, [resetFilters])

  return (
    <PageContainer>
      <Box backgroundColor='' py='16px' mb='10px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {t('common:navbar.claims')}
        </Heading>
      </Box>
      <Panel style={{ marginBottom: '1.5rem' }} rounded='md'>
        <SimpleGrid columns={{ sm: 1, md: 5 }} spacing='10px'>
          <Box>
            <DateRangePicker
              showDefaultInputIcon={true}
              isOutsideRange={() => false}
              startDate={startDate}
              startDateId='fecha_inicio'
              endDate={endDate}
              endDateId='fecha_fin'
              focusedInput={focusedInput}
              onDatesChange={({ startDate, endDate }) => {
                handleFilters(FILTER_KEYS.startDate, startDate ? moment(startDate).startOf('d').toDate() : null)
                handleFilters(FILTER_KEYS.endDate, endDate ? moment(endDate).endOf('d').toDate() : null)

                return setRangePicker({ startDate: startDate, endDate: endDate })
              }}
              onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
              small
              orientation='horizontal'
              displayFormat='DD/MM/YYYY'
            />
          </Box>

          <Box>
            <SelectAsync
              value={selectFecha}
              placeholder={t('consultations:date')}
              onChange={e => {
                setSelectFecha(e.target.value)
                handleFilters(FILTER_KEYS.startDate, moment().subtract(e.target.value, 'd').endOf('d').toDate())
                handleFilters(
                  FILTER_KEYS.endDate,
                  e.target.value !== '' ? moment(new Date()).endOf('d').toDate() : null,
                )

                return setRangePicker({
                  startDate: moment().subtract(e.target.value, 'd'),
                  endDate: e.target.value !== '' ? moment(new Date()) : null,
                })
              }}
            >
              <option value={10}>{t('consultations:last10Days')}</option>
              <option value={30}>{t('consultations:lastMonth')}</option>
              <option value={180}>{t('consultations:last6Months')}</option>
            </SelectAsync>
          </Box>

          {permisosUserCountry ? (
            <FormInput
              name='agencia'
              value={filters.nombreAgencia}
              onChange={e => handleFilters(FILTER_KEYS.nombreAgencia, e.target.value)}
              inputProps={{
                placeholder: `${t('consultations:agencyName')} (${t('common:optional')})`,
              }}
            />
          ) : (
            ''
          )}

          {/*      <Box>
            <SelectAsync
              value={filters.tipoConsulta}
              placeholder='Tipo Reclamo'
              onChange={e => {
                return handleFilters(FILTER_KEYS.tipoConsulta, e.target.value)
              }}
            >
              {['Paquete', 'Mapa', 'Banners'].map(item => {
                return <option>{item}</option>
              })}
            </SelectAsync>
          </Box> */}

          <Box style={{ display: 'flex', gap: '1rem' }}>
            <Button variantColor='blue' variant='outline' onClick={onFilter}>
              {t('common:filter')}
            </Button>

            <Button variantColor='blue' variant='outline' onClick={handleResetFilters}>
              {t('comunications:cleanFilter')}
            </Button>
          </Box>
        </SimpleGrid>
      </Panel>
      {!visualDetailsExchange ? (
        <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose} boxShadow='xl' size='6xl'>
          <ModalOverlay />
          <ModalContent style={{ borderRadius: '5px', width: 'fit-content' }}>
            <ModalHeader textTransform='capitalize'>{`${t('consultations:details')} ${t('common:del')} ${t(
              'common:navbar.claim',
            )}`}</ModalHeader>

            <ModalCloseButton />
            <ModalBody pb={6}>
              <ClaimsDetail general={general} btnClose={modalDisclosure.onClose}></ClaimsDetail>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : null}

      {!sectionData.reclamos.isLoading ? <Table columns={columns} data={sectionData.reclamos.data} /> : null}

      {!sectionData.reclamos.isLoading && totalPages ? (
        <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />
      ) : (
        ''
      )}
      {sectionData.reclamos.isLoading ? (
        <Box px='5' py='10' textAlign='center'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Box>
      ) : null}
    </PageContainer>
  )
}
