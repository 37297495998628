import React, { useMemo, useState, useEffect } from 'react'
import { useDisclosure, Stack, Box, Spinner, Text, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  createCategory,
  deleteCategoryDetails,
  getAllCategoryDetails,
} from '../../../../api/web-information'
import { Panel } from '../../../common/helpers'
import CategoryForm from './category-form'
import { getAuthToken } from '../../../../redux/selectors'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import Table from '../../../common/table'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import CreateCategory from './create-category'
import ModalSimple from '../../../common/modal-simple'
import { isSuccess } from '../../../../helpers/utils'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import Pagination from '../../../common/pagination'
import './styles.css'

export default function Category() {
  const { t } = useTranslation(['common', 'forms'])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const [idCategory, setIdCategory] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState()

  //Modal vbles
  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()

  // Load data for the table
  const [{ isLoading, categoryDetails }, setcategoryDetRequest] = useState({
    isLoading: false,
    categoryDetails: [],
  })

  async function getCategoryDetail() {
    setcategoryDetRequest(state => ({
      ...state,
      isLoading: true,
    }))

    try {
      const res = await getAllCategoryDetails({ page })

      setTotalPages(res.data.totalPages)

      if (res.data.totalPages < page) setPage(1)
      setcategoryDetRequest(state => ({
        ...state,
        isLoading: false,
        categoryDetails: res.data.data,
      }))
    } catch (error) {
      console.log(error)

      setcategoryDetRequest(state => ({
        ...state,
        isLoading: false,
        categoryDetails: [],
      }))
    }
  }

  async function handleDelete() {
    try {
      const res = await deleteCategoryDetails(idCategory, authToken)

      if (isSuccess(res.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      }
      modalDisclosure2.onClose()
      getCategoryDetail()
    } catch (error) {
      if (error.response) {
        if(error.response.status === 409 ){
          toast(toastCustomizedError(t('common:notDeleteCategory'),'', 4000))
        }else{
          toast(toastCustomizedError(error.response.data.message))
        }
        modalDisclosure2.onClose()
      }
    }
  }

  //Edit functions
  const [idSectionn, setIdSection] = useState(0)

  function onEditClick(idSectionEdit) {
    let idSection = idSectionEdit
    setIdSection(idSection)
    modalDisclosure.onOpen()
  }
  ///

  useEffect(() => {
    getCategoryDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('forms:name')}`,
        accessor: 'nombre',
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            {cell.row.values.nombre.length > 60 ? (
              <Text>{cell.row.values.nombre.substring(0, 60)}...</Text>
            ) : (
              <Text>{cell.row.values.nombre}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:description'),
        accessor: 'descripcion', // `accessor` es una key del objeto que se obtuvo con el GET de secciones
      },
      {
        Header: `${t('forms:value_type')}`,
        accessor: 'tipoValor',
        Cell: ({ cell }) => (cell.row.values.tipoValor ? t('forms:' + cell.row.values.tipoValor.toLowerCase()) : ''),
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit mb={4} onClick={() => onEditClick(cell.row.values.id)}></ButtonEdit>
            <ButtonDelete
              mb={4}
              onClick={() => {
                setIdCategory(cell.row.values.id)
                setSelectedCategory(cell.row.values.descripcion)
                return modalDisclosure2.onOpen()
              }}
            ></ButtonDelete>
          </Stack>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  ///Create category
  async function createCategories(formData) {
    try {
      const response = await createCategory(formData, authToken)
      getCategoryDetail()

      if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      }
    } catch (error) {
      if (error.response) {
        console.log(error)
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  function onSubmit(datos) {
    createCategories(datos)
  }

  return (
    <Box>
      {/* Edit modal */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${t('web-information:category')}`}
        size='lg'
      >
        <CreateCategory
          onFinish={() => null}
          idSectionn={idSectionn}
          getCategoryDetail={getCategoryDetail}
          onClose={modalDisclosure.onClose}
        />
      </ModalSimple>

      <AlertDialogSimple
        isOpen={modalDisclosure2.isOpen}
        onClose={modalDisclosure2.onClose}
        onAccept={handleDelete}
        title={`${t('common:delete')} ${t('common:category')}`}
        size='lg'
      >
        {' '}
        <p>{`${t('common:deletingCategory')}`}</p>
        {' '}
        <p>{selectedCategory}</p>
      </AlertDialogSimple>

      <Panel rounded='md' mb='1.5rem'>
        <CategoryForm onSubmit={onSubmit} />
      </Panel>
      {!isLoading && categoryDetails.length >= 1 ? <Table columns={columns} data={categoryDetails} /> : null}

      {isLoading && (
        <Box px='5' py='10' textAlign='center'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Box>
      )}
      {!isLoading && categoryDetails.length === 0 && (
        <Text textAlign='center' color='gray.500' px='5' py='10'>
          {t('common:zeroItems')}
        </Text>
      )}
      {!isLoading && !categoryDetails && (
        <Text textAlign='center' color='gray.500' px='5' py='10'>
          {t('common:errorGettingItems')}
        </Text>
      )}
      {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
    </Box>
  )
}
