import React from 'react'
import { Box, Text } from '@chakra-ui/core'

const StatusColor = ({ color, text = '', boxProps }) => {
  return (
    <Box style={{ gap: '0.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center', ...boxProps?.style }}>
      <div style={{ backgroundColor: color, height: '1rem', width: '1rem', borderRadius: '1rem' }} />
      <Text>{text}</Text>
    </Box>
  )
}

export default StatusColor
