import React, { useEffect, useState } from 'react'
import { Box, Button, FormLabel, Input, PseudoBox, SimpleGrid, Stack, useToast } from '@chakra-ui/core'
import FormInput from '../../form-input'
import { useTranslation } from 'react-i18next'
import { deleteGetStatusId, postStatus, putUpdateStatus } from '../../../../api/incentives'
import { isSuccess } from '../../../../helpers/utils'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../../redux/selectors'

function AddStatus({ status, onClose, incentivoId, getStatus, getIncentiv, hasScales }) {
  const { t } = useTranslation(['incentives', 'common'])
  const [edit, setEdit] = useState(false)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const [notas, setNotas] = useState('')
  const [montoParcial, setMontoParcial] = useState(0)
  const [mesesCargados, setMesesCargados] = useState()
  const [cantidadMeses, setCantidadMeses] = useState()
  const [proyeccion, setProyeccion] = useState(0)
  const [isMesesCargadosValid, setIsMesesCargadosValid] = useState(true)
  const [isCantidadMesesValid, setIsCantidadMesesValid] = useState(true)
  const [isMesesCargadosTouched, setIsMesesCargadosTouched] = useState(false)
  const [isCantidadMesesTouched, setIsCantidadMesesTouched] = useState(false)

  async function deleteItem(id) {
    try {
      const response = await deleteGetStatusId(id, authToken)
      setEdit(false)
      toast(
        isSuccess(response.status)
          ? toastCustomizedError(t('incentives:delete_status'))
          : toastCustomizedError(t('incentives:error_delete_status')),
      )
      getIncentiv()
      onClose()
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  async function updateItem(event, id) {
    event.preventDefault()
    try {
      const data = {
        montoParcial,
        cantidadMeses,
        mesesCargados,
        notas,
      }

      const response = await putUpdateStatus(id, data, authToken)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('incentives:edit_status'))
          : toastCustomizedError(t('incentives:error_edit_status')),
      )
      getStatus(incentivoId)
      onClose()
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  async function createItem(event) {
    event.preventDefault()
    try {
      const data = {
        incentivo: {
          id: incentivoId,
        },
        montoParcial,
        mesesCargados,
        cantidadMeses,
        notas,
        fechaIngreso: new Date(),
      }

      const response = await postStatus(data, authToken)

      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('incentives:add_status'))
          : toastCustomizedError(t('incentives:error_add_status')),
      )
      onClose()
      getStatus(incentivoId)
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  const handleIntegerInput = (e, setter, setTouched) => {
    const value = e.target.value
    const sanitizedValue = value.replace(/[^0-9]/g, '')
    setter(parseInt(sanitizedValue) || '')
    setTouched(true)
  }

  const handleEnableEdit = () => {
    setEdit(true)
    setIsMesesCargadosTouched(true)
    setIsCantidadMesesTouched(true)
  }

  useEffect(() => {
    const checkMeses = cantidadMeses > 0 && mesesCargados <= cantidadMeses
    setIsMesesCargadosValid(mesesCargados > 0 && isMesesCargadosTouched)
    setIsCantidadMesesValid(checkMeses && isCantidadMesesTouched)
    if (mesesCargados && cantidadMeses && !checkMeses && isMesesCargadosTouched && isCantidadMesesTouched){
      toast(toastCustomizedError(t('incentives:error_months'), '', 4000))
    }
  }, [mesesCargados, cantidadMeses, isMesesCargadosTouched, isCantidadMesesTouched])

  useEffect(() => {
    if (status) {
      setNotas(status.notas)
      setMontoParcial(status.montoParcial)
      setMesesCargados(status.mesesCargados)
      setCantidadMeses(status.cantidadMeses)
      setProyeccion(status.proyeccion)
    }
  }, [status])

  useEffect(() => {
    setMesesCargados(undefined)
    setCantidadMeses(undefined)
    setIsMesesCargadosTouched(false)
    setIsCantidadMesesTouched(false)
  }, [onClose])

  useEffect(() => {
    console.log('status', status)
  }, [status])

  return (
    <Box>
      {status ? (
        <form onSubmit={event => updateItem(event, status.id)}>
          <Stack>
            <SimpleGrid columns={2} spacing={5}>
              <Box>
                <FormLabel isRequired={edit} htmlFor='cumulativeSale'>
                  {t('incentives:cumulativeSale')}
                </FormLabel>

                <Input
                  style={{
                    paddingRight: '0.1rem',
                  }}
                  onChange={e => setMontoParcial(e.target.value)}
                  isDisabled={!edit}
                  isRequired={edit}
                  name='montoParcial'
                  value={montoParcial}
                />
              </Box>

              <Box>
                <FormLabel isRequired htmlFor='cantidadMeses'>
                  {t('incentives:numberOfMonths')}
                </FormLabel>

                <Input
                  style={{
                    paddingRight: '0.1rem',
                  }}
                  isInvalid={isCantidadMesesTouched && !isCantidadMesesValid}
                  onChange={e => handleIntegerInput(e, setCantidadMeses, setIsCantidadMesesTouched)}
                  step={1}
                  placeholder={t('incentives:numberOfMonths')}
                  isDisabled={!edit}
                  isRequired
                  name='cantidadMeses'
                  value={cantidadMeses}
                />
              </Box>

              <Box>
                <FormLabel isRequired={edit} htmlFor='mesesCargados'>
                  {t('incentives:mese_cargados')}
                </FormLabel>

                <Input
                  style={{
                    paddingRight: '0.1rem',
                  }}
                  isInvalid={isMesesCargadosTouched && !isMesesCargadosValid}
                  onChange={e => handleIntegerInput(e, setMesesCargados, setIsMesesCargadosTouched)}
                  isDisabled={!edit}
                  placeholder={t('incentives:mese_cargados')}
                  isRequired
                  name='mesesCargados'
                  value={mesesCargados}
                />
              </Box>

              <Box>
                <FormLabel htmlFor='proyeccion'>{t('incentives:annualProjection')}</FormLabel>

                <Input
                  style={{
                    paddingRight: '0.1rem',
                  }}
                  isDisabled={true}
                  isRequired
                  name='proyeccion'
                  value={
                    edit && mesesCargados > 0
                      ? ((montoParcial / mesesCargados) * cantidadMeses).toFixed(2)
                      : proyeccion.toFixed(2)
                  }
                />
              </Box>

              <Box style={{ gridColumn: 'span 2' }}>
                <FormLabel isRequired={edit} htmlFor='notas'>
                  {t('incentives:notes')}
                </FormLabel>

                <Input
                  style={{
                    paddingRight: '0.1rem',
                  }}
                  onChange={e => setNotas(e.target.value)}
                  isDisabled={!edit}
                  isRequired={edit}
                  name='notas'
                  value={notas}
                />
              </Box>

              {!edit ? (
                <Box style={{ display: 'flex', gap: '.5rem', justifyContent: 'start', alignItems: 'end' }}>
                  <div className='css-1ywmivd' style={{ cursor: 'pointer' }} onClick={handleEnableEdit}>
                    {t('incentives:edit')}
                  </div>
                  {
                    !hasScales && (
                      <Button variantColor='red' variant='outline' onClick={() => deleteItem(status.id)}>
                        {t('incentives:delete')}
                      </Button>
                    )
                  }
                </Box>
              ) : (
                <Box style={{ display: 'flex', gap: '.5rem', justifyContent: 'start', alignItems: 'end' }}>
                  <Button
                    variantColor='blue'
                    variant='outline'
                    type='submit'
                    isDisabled={!isMesesCargadosValid || !isCantidadMesesValid}
                  >
                    {t('common:save')}
                  </Button>

                  <Button variantColor='blue' variant='outline' onClick={() => onClose()}>
                    {t('common:cancel')}
                  </Button>
                </Box>
              )}
            </SimpleGrid>
          </Stack>
        </form>
      ) : (
        <form onSubmit={createItem}>
          <Stack style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: '16px' }}>
            <Box marginBottom={5}>
              <FormInput
                isRequired
                label={t('incentives:cumulativeSale')}
                name='montoParcial'
                onChange={e => setMontoParcial(e.target.value)}
                inputProps={{
                  placeholder: t('incentives:cumulativeSale'),
                  type: 'number',
                }}
              />
            </Box>

            <Box marginBottom={5}>
              <FormLabel isRequired htmlFor='cantidadMeses'>
                {t('incentives:numberOfMonths')}
              </FormLabel>

              <Input
                style={{
                  paddingRight: '0.1rem',
                  placeholder: t('incentives:numberOfMonths'),
                }}
                isInvalid={isCantidadMesesTouched && !isCantidadMesesValid}
                placeholder={t('incentives:numberOfMonths')}
                onChange={e => handleIntegerInput(e, setCantidadMeses, setIsCantidadMesesTouched)}
                isRequired
                name='cantidadMeses'
                value={cantidadMeses}
              />
            </Box>

            <Box marginBottom={5}>
              <FormLabel isRequired htmlFor='mesesCargados'>
                {t('incentives:mese_cargados')}
              </FormLabel>

              <Input
                style={{
                  paddingRight: '0.1rem',
                }}
                isInvalid={isMesesCargadosTouched && !isMesesCargadosValid}
                onChange={e => handleIntegerInput(e, setMesesCargados, setIsMesesCargadosTouched)}
                isRequired
                placeholder={t('incentives:mese_cargados')}
                name='mesesCargados'
                value={mesesCargados}
              />
            </Box>

            <Box marginBottom={5}>
              <FormInput
                isDisabled={true}
                label={t('incentives:annualProjection')}
                name='proyeccionAnual'
                value={mesesCargados > 0 ? ((montoParcial / mesesCargados) * cantidadMeses).toFixed(2) : 0}
                inputProps={{
                  placeholder: t('incentives:annualProjection'),
                  type: 'number',
                }}
              />
            </Box>

            <Box marginBottom={5} style={{ gridColumn: 'span 2' }}>
              <FormInput
                isRequired
                label={t('incentives:notes')}
                name='notas'
                onChange={e => setNotas(e.target.value)}
                inputProps={{
                  placeholder: t('incentives:notes'),
                }}
              />
            </Box>

            <Button
              style={{ marginTop: '27px', width: '75%' }}
              variantColor='blue'
              variant='outline'
              isDisabled={!isMesesCargadosValid || !isCantidadMesesValid}
              type='submit'
            >
              <Box style={{ paddingTop: '1%' }}>{t('common:save')}</Box>
            </Button>
          </Stack>
        </form>
      )}
    </Box>
  )
}

export default AddStatus
