import React, { useState, useEffect } from 'react'
import { getAuthToken, getUserInformation } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { Button, Image, Tooltip } from '@chakra-ui/core'
import { getLoginUnicos, getObtenerClaves, getParametros, getParametrosId } from '../../../api/loginUnico'
import Autologin from '../../../static/img/auto_login.webp'

function AutoLogin({ proveedorId }) {
  const authToken = useSelector(getAuthToken)
  const userInfo = useSelector(getUserInformation)
  const [code, setCode] = useState({
    loading: false,
  })
  const [funcs, setFuncs] = useState({
    loading: false,
  })
  const [claves, setClaves] = useState(false)
  const [parametros, setParametros] = useState(false)

  async function ejecAutoLogin(a, b, c) {
    const paramById = await getParametrosId(a, authToken)
    let params = paramById.data.valor.split('+')
    let name = claves.filter((x) => b === x.id)
    let proveedorLoginUnico = name[0].proveedorLoginUnico.id
    let funcEject = funcs.other.filter((x) => x.id === proveedorLoginUnico)
    let funcFinal = funcEject[0].script.split('¿')
    let funcReFinal = eval(funcFinal[0])
    return funcReFinal(params[0], params[1], params[2], params[3])
  }

  async function getAutoLogins() {
    try {
      const response = await getLoginUnicos(authToken)
      // eslint-disable-next-line no-irregular-whitespace
      let fil = response.data
      let filtro3 = fil.map((x) => x.script.split('¿'))
      let filtro4 = filtro3.map((x) => eval(eval(x[0])))
      setFuncs({
        test: code.test,
        codec: filtro4,
        codecNumber: filtro3,
        other: fil,

        loading: true,
      })
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  async function cargarClaves() {
    try {
      const response = await getObtenerClaves(authToken)
      setClaves(response.data)
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  async function cargarParametros() {
    try {
      const response = await getParametros()
      setParametros(response.data)
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  useEffect(() => {
    getAutoLogins()
    cargarClaves()
    cargarParametros()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function searchButton(x) {
    if (x.usuarioId === userInfo.id) {
      if (x.proveedorLoginUnico.proveedor.id === proveedorId) {
        return (
          <Tooltip label="Auto Login">
            <Button
              borderColor="#24428E"
              color="#24428E"
              variantColor="blue"
              w="full"
              variant="outline"
              key={x.id}
              onClick={() => ejecAutoLogin(x.id, x.clave.id, x.valor)}
              type="submit"
              style={sideCardButtonStyles(true)}
            >
              <Image
                style={{
                  maxWidth: 'unset',
                  width: '2rem',
                  filter: 'invert(18%) sepia(91%) saturate(1379%) hue-rotate(207deg) brightness(98%) contrast(96%)',
                }}
                src={Autologin}
              />
            </Button>
          </Tooltip>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }

  const sideCardButtonStyles = (last) => {
    const styles = {
      marginBottom: last ? 'unset' : '0.5rem',
      width: '3rem',
      height: '3rem',
    }
    return styles
  }

  return <>{claves === false ? null : parametros === false ? null : parametros.map((x) => searchButton(x))}</>
}

export default AutoLogin
