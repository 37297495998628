import React, { useEffect, useState } from 'react'
import {
  Button,
  SimpleGrid,
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionHeader,
  Box,
  Spinner,
} from '@chakra-ui/core'
import {
  getLoginUnicos,
  getObtenerClaves,
  postCrearParametro,
  getParametros,
  putActualizarParametro,
} from '../../../../api/loginUnico'
import { getProviderByCountry, getAppInformation } from '../../../../api/application'
import { getAuthToken, getToken } from '../../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { useToast } from '@chakra-ui/core'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { isSuccess } from '../../../../helpers/utils'
import { deleteParametro } from '../../../../api/loginUnico'

function AddCredentials(userId) {
  const { t } = useTranslation(['incentives', 'applications', 'auto-login', 'consultations', 'forms'])
  const authToken = useSelector(getAuthToken)
  const token = useSelector(getToken)
  const { appId } = useParams()
  const toast = useToast()
  const [loginsUnicos, setLoginsUnicos] = useState([])
  const [parametros, setParametros] = useState([])
  const [claves, setClaves] = useState([])
  const [isDelete, setDelete] = useState('')
  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')
  const [other, setOther] = useState('')
  const [other2, setOther2] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleUser = ({ target: { value } }) => setUser(value)
  const handlePass = ({ target: { value } }) => setPass(value)
  const handleOther = ({ target: { value } }) => setOther(value)
  const handleOther2 = ({ target: { value } }) => setOther2(value)

  async function ejecAutoLogin(proveedorId, claveProveedorId, userId) {
    const proveedorFilter = loginsUnicos.filter(x => x.id === proveedorId)
    const filteredId = proveedorFilter[0].proveedor.id
    const data = {
      usuarioId: parseInt(userId),
      proveedorLoginUnico: { id: parseInt(proveedorId) },
      clave: { id: parseInt(claveProveedorId) },
      valor: user + '+' + pass + '+' + other + '+' + other2,
      orden: parseInt(filteredId),
    }
    try {
      const response = await postCrearParametro(data, authToken)
      if (response.status) {
        toast(toastCustomizedSuccess(t('auto-login:loginSuccess')))
        setDelete('')
        getAutoLogins()
        cargarParametros()
        cargarClaves()
      }
    } catch (error) {
      console.log(error)
      toast(toastCustomizedError(t('auto-login:wrong')))
    }
  }

  async function deleteParam(clave) {
    try {
      const paramId = getParamId(clave)
      const response = await deleteParametro(paramId, authToken)

      if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        setDelete('')
        getAutoLogins()
        cargarParametros()
        cargarClaves()
      }
    } catch (error) {
      console.log(error)
      toast(toastCustomizedError(t('auto-login:wrong')))
    }
  }

  async function editParams(proveedorId, claveProveedorId, idParam, userId) {
    const proveedorFilter = loginsUnicos.filter(x => x.id === proveedorId)
    const filteredId = proveedorFilter[0].proveedor.id
    let data = {
      usuarioId: userId,
      proveedorLoginUnico: { id: parseInt(proveedorId) },
      clave: { id: parseInt(claveProveedorId) },
      valor: user + '+' + pass + '+' + other + '+' + other2,
      orden: filteredId,
    }
    try {
      const response = await putActualizarParametro(idParam, data, authToken)
      response.status
        ? toast(toastCustomizedSuccess('Se edito correctamente el parametro'))
        : toast(toastCustomizedError(t('auto-login:wrong')))
    } catch (error) {
      console.log(error)
    }
  }

  function place(num, clave) {
    let parametrosFilter = parametros.filter(parametro => parametro.usuarioId === userId.userId)
    let filtro = parametrosFilter.filter(x => x.proveedorLoginUnico?.id === clave.proveedorLoginUnico?.id)

    if (filtro.length !== 0) {
      let nn = filtro[0].valor.split('+')
      return nn[num]
    } else {
      return ''
    }
  }

  function getParamId(val) {
    let filtro = parametros.filter(
      x => x.proveedorLoginUnico?.id === val.proveedorLoginUnico?.id && x.usuarioId === userId.userId,
    )
    if (filtro.length !== 0) {
      let filtroId = filtro[0].id
      return filtroId
    } else {
      return ''
    }
  }

  async function getAutoLogins() {
    try {
      const response = await getLoginUnicos(authToken)
      const logins = response.data
      setLoginsUnicos(logins)
    } catch (error) {
      console.log(error)
    }
  }

  async function cargarParametros() {
    try {
      const response = await getParametros()

      setParametros(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function cargarClaves() {
    try {
      const responses = await getAppInformation(appId, token)
      let paisId = responses.data.paisId
      let data = {
        paisId,
        maxPerPage: 200,
      }

      const response = await getProviderByCountry(data, authToken)
      let sc = []
      response.data.data.map(x => sc.push(x.nombre))
      try {
        const response = await getObtenerClaves(authToken)
        let claves = response.data
        let clavesFilter = claves.filter(c => sc.includes(c.clave))

        setClaves(clavesFilter)
        setIsLoading(false)
      } catch (error) {
        console.log(error)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setIsLoading(true)
    cargarParametros()
    cargarClaves()
    getAutoLogins()
  }, [])

  return (
    <>
      <SimpleGrid spacing={2}>
        {!isLoading && claves.length && !parametros.length ? (
          claves.map(clave => {
            return (
              <div key={clave.proveedorLoginUnico?.id}>
                <Accordion style={{ width: '95%' }}>
                  <Box
                    backgroundColor='gray.50'
                    m='0.2% 3%'
                    color='blue'
                    style={{
                      borderRadius: '5px',
                      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                    }}
                  >
                    <AccordionItem>
                      <AccordionHeader backgroundColor='gray.100' style={{ borderRadius: '5px', border: '' }}>
                        <h1 style={{ margin: '0% 40%', width: '20%' }}>
                          <b>{clave.clave}</b>
                        </h1>
                        <AccordionIcon />
                      </AccordionHeader>
                      {loginsUnicos
                        .filter(b => b.id === clave.proveedorLoginUnico?.id)
                        .map(p => {
                          if (p.id === clave.proveedorLoginUnico?.id) {
                            return (
                              <AccordionPanel>
                                {typeof loginsUnicos
                                  .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                  ?.url.split('+') !== 'undefined' ? (
                                  <div>
                                    <label style={{ margin: '1%' }}>
                                      {
                                        loginsUnicos
                                          .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                          ?.url.split('+')[0]
                                      }
                                    </label>
                                    <input
                                      placeholder={place(0, clave)}
                                      style={{
                                        width: '98%',
                                        height: '30px',
                                        margin: '0% 1% 1%',
                                        backgroundColor: '#edf6f9',
                                        border: '1px solid black',
                                        borderRadius: '5px',
                                      }}
                                      onChange={handleUser}
                                    ></input>
                                  </div>
                                ) : null}
                                {typeof loginsUnicos
                                  .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                  ?.url.split('+')[1] !== 'undefined' ? (
                                  <div>
                                    <label style={{ margin: '1%' }}>
                                      {
                                        loginsUnicos
                                          .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                          ?.url.split('+')[1]
                                      }
                                    </label>
                                    <input
                                      placeholder={place(1, clave)}
                                      style={{
                                        width: '98%',
                                        height: '30px',
                                        margin: '0% 1% 1%',
                                        backgroundColor: '#edf6f9',
                                        border: '1px solid black',
                                        borderRadius: '5px',
                                      }}
                                      onChange={handlePass}
                                    ></input>
                                  </div>
                                ) : null}
                                {typeof loginsUnicos
                                  .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                  ?.url.split('+')[2] !== 'undefined' ? (
                                  <div>
                                    <label style={{ margin: '1%' }}>
                                      {
                                        loginsUnicos
                                          .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                          ?.url.split('+')[2]
                                      }
                                    </label>
                                    <input
                                      placeholder={place(2, clave)}
                                      style={{
                                        width: '98%',
                                        height: '30px',
                                        margin: '0% 1% 1%',
                                        backgroundColor: '#edf6f9',
                                        border: '1px solid black',
                                        borderRadius: '5px',
                                      }}
                                      onChange={handleOther}
                                    ></input>
                                  </div>
                                ) : null}
                                {typeof loginsUnicos
                                  .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                  ?.url.split('+')[3] !== 'undefined' ? (
                                  <div>
                                    <label style={{ margin: '1%' }}>
                                      {
                                        loginsUnicos
                                          .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                          ?.url.split('+')[3]
                                      }
                                    </label>
                                    <input
                                      placeholder={place(2, clave)}
                                      style={{
                                        width: '98%',
                                        height: '30px',
                                        margin: '0% 1% 1%',
                                        backgroundColor: '#edf6f9',
                                        border: '1px solid black',
                                        borderRadius: '5px',
                                      }}
                                      onChange={handleOther2}
                                    ></input>
                                  </div>
                                ) : null}
                                {place(0, clave) === '' ? (
                                  <Button
                                    style={{ margin: '0% 40% 0%', width: '15%' }}
                                    onClick={() =>
                                      ejecAutoLogin(clave.proveedorLoginUnico?.id, clave.id, userId.userId)
                                    }
                                    variantColor='blue'
                                    variant='outline'
                                    type='submit'
                                  >
                                    Cargar
                                  </Button>
                                ) : (
                                  <Button
                                    style={{ margin: '0% 40% 0%', width: '15%' }}
                                    onClick={() =>
                                      editParams(
                                        clave.proveedorLoginUnico?.id,
                                        clave.id,
                                        getParamId(clave),
                                        userId.userId,
                                      )
                                    }
                                    variantColor='blue'
                                    variant='outline'
                                    type='submit'
                                  >
                                    Editar
                                  </Button>
                                )}
                              </AccordionPanel>
                            )
                          }
                        })}
                    </AccordionItem>
                  </Box>
                </Accordion>
              </div>
            )
          })
        ) : !isLoading && claves.length && parametros.length ? (
          claves.map(clave => {
            return (
              <div key={clave.proveedorLoginUnico?.id}>
                <Accordion style={{ width: '95%' }}>
                  <Box
                    backgroundColor='gray.50'
                    m='0.2% 3%'
                    color='blue'
                    style={{
                      borderRadius: '5px',
                      boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
                    }}
                  >
                    <AccordionItem>
                      <AccordionHeader backgroundColor='gray.100' style={{ borderRadius: '5px', border: '' }}>
                        <h1 style={{ margin: '0% 40%', width: '20%' }}>
                          <b>{clave.clave}</b>
                        </h1>
                        <AccordionIcon />
                      </AccordionHeader>
                      {parametros.length &&
                      parametros.find(
                        p =>
                          p.usuarioId === userId.userId && p.proveedorLoginUnico?.id === clave.proveedorLoginUnico?.id,
                      ) ? (
                        <Box
                          style={{
                            display: 'flex',
                            gap: '1.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            minHeight: '9rem',
                            padding: '1.5rem',
                          }}
                        >
                          <h1>{t('applications:alreadyCredentials')}</h1>

                          {isDelete === clave ? (
                            <Box
                              key={clave}
                              style={{
                                backgroundColor: 'white',
                                flexDirection: 'column',
                                gap: '1rem',
                                padding: '1.5rem',
                                display: 'flex',
                                justifyContent: 'center',
                                borderRadius: '10px',
                              }}
                            >
                              {t('common:deleteCredential')}
                              <Box
                                style={{
                                  display: 'flex',
                                  gap: '1rem',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Button
                                  style={{ minWidth: '4rem' }}
                                  onClick={() => deleteParam(clave)}
                                  variantColor='blue'
                                  variant='outline'
                                >
                                  {t('consultations:yes')}
                                </Button>
                                <Button
                                  style={{ minWidth: '4rem' }}
                                  onClick={() => setDelete('')}
                                  variantColor='red'
                                  variant='outline'
                                >
                                  No
                                </Button>
                              </Box>
                            </Box>
                          ) : (
                            <Button
                              key={clave}
                              style={{ fontSize: '.9rem', padding: '.9rem' }}
                              variantColor='red'
                              variant='outline'
                              type='submit'
                              onClick={() => setDelete(clave)}
                            >
                              <Box>
                                {t('incentives:delete')} {t('applications:credential')}
                              </Box>
                            </Button>
                          )}
                        </Box>
                      ) : (
                        loginsUnicos
                          .filter(b => b.id === clave.proveedorLoginUnico?.id)
                          .map(p => {
                            if (p.id === clave.proveedorLoginUnico?.id) {
                              return (
                                <AccordionPanel>
                                  {typeof loginsUnicos
                                    .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                    ?.url.split('+') !== 'undefined' ? (
                                    <div>
                                      <label style={{ margin: '1%' }}>
                                        {
                                          loginsUnicos
                                            .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                            ?.url.split('+')[0]
                                        }
                                      </label>
                                      <input
                                        placeholder={place(0, clave)}
                                        style={{
                                          width: '98%',
                                          height: '30px',
                                          margin: '0% 1% 1%',
                                          backgroundColor: '#edf6f9',
                                          border: '1px solid black',
                                          borderRadius: '5px',
                                        }}
                                        onChange={handleUser}
                                      ></input>
                                    </div>
                                  ) : null}
                                  {typeof loginsUnicos
                                    .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                    ?.url.split('+')[1] !== 'undefined' ? (
                                    <div>
                                      <label style={{ margin: '1%' }}>
                                        {
                                          loginsUnicos
                                            .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                            ?.url.split('+')[1]
                                        }
                                      </label>
                                      <input
                                        placeholder={place(1, clave)}
                                        style={{
                                          width: '98%',
                                          height: '30px',
                                          margin: '0% 1% 1%',
                                          backgroundColor: '#edf6f9',
                                          border: '1px solid black',
                                          borderRadius: '5px',
                                        }}
                                        onChange={handlePass}
                                      ></input>
                                    </div>
                                  ) : null}
                                  {typeof loginsUnicos
                                    .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                    ?.url.split('+')[2] !== 'undefined' ? (
                                    <div>
                                      <label style={{ margin: '1%' }}>
                                        {
                                          loginsUnicos
                                            .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                            ?.url.split('+')[2]
                                        }
                                      </label>
                                      <input
                                        placeholder={place(2, clave)}
                                        style={{
                                          width: '98%',
                                          height: '30px',
                                          margin: '0% 1% 1%',
                                          backgroundColor: '#edf6f9',
                                          border: '1px solid black',
                                          borderRadius: '5px',
                                        }}
                                        onChange={handleOther}
                                      ></input>
                                    </div>
                                  ) : null}
                                  {typeof loginsUnicos
                                    .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                    ?.url.split('+')[3] !== 'undefined' ? (
                                    <div>
                                      <label style={{ margin: '1%' }}>
                                        {
                                          loginsUnicos
                                            .filter(b => b.id === clave.proveedorLoginUnico?.id)[0]
                                            ?.url.split('+')[3]
                                        }
                                      </label>
                                      <input
                                        placeholder={place(2, clave)}
                                        style={{
                                          width: '98%',
                                          height: '30px',
                                          margin: '0% 1% 1%',
                                          backgroundColor: '#edf6f9',
                                          border: '1px solid black',
                                          borderRadius: '5px',
                                        }}
                                        onChange={handleOther2}
                                      ></input>
                                    </div>
                                  ) : null}
                                  {place(0, clave) === '' ? (
                                    <Button
                                      style={{ margin: '0% 40% 0%', width: '15%' }}
                                      onClick={() =>
                                        ejecAutoLogin(clave.proveedorLoginUnico?.id, clave.id, userId.userId)
                                      }
                                      variantColor='blue'
                                      variant='outline'
                                      type='submit'
                                    >
                                      Cargar
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{ margin: '0% 40% 0%', width: '15%' }}
                                      onClick={() =>
                                        editParams(
                                          clave.proveedorLoginUnico?.id,
                                          clave.id,
                                          getParamId(clave),
                                          userId.userId,
                                        )
                                      }
                                      variantColor='blue'
                                      variant='outline'
                                      type='submit'
                                    >
                                      Editar
                                    </Button>
                                  )}
                                </AccordionPanel>
                              )
                            }
                          })
                      )}
                    </AccordionItem>
                  </Box>
                </Accordion>
              </div>
            )
          })
        ) : !isLoading && !claves.length && parametros.length ? (
          <Box>{t('applications:credentialsNoSupplier')}</Box>
        ) : (
          <Box>
            {isLoading && (
              <Box px='5' py='10' textAlign='center'>
                <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
              </Box>
            )}
          </Box>
        )}
      </SimpleGrid>
    </>
  )
}

export default AddCredentials
