import React from 'react'
import { Button, PseudoBox, SimpleGrid, Stack } from '@chakra-ui/core'
import FormInput from '../../form-input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

function AddStatus2({ option1, option2, option3, option4, formData = false, buttonLabel = false, onSubmit }) {
  const { t } = useTranslation(['incentives', 'common'])
  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })

  return (
    <>
      <PseudoBox as='form' onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
            {typeof option4 === 'undefined' || option4 > 0 || option4.incentivoEscala.length === 0 ? (
              <FormInput
                isRequired
                label={t(`incentives:${option1}`)}
                name={option1}
                inputProps={{
                  placeholder: t(`incentives:${option1}`),
                  ref: register,
                  type: 'number',
                }}
              />
            ) : (
              <div></div>
            )}

            <FormInput
              isRequired
              label={t(`incentives:${option2}`)}
              name={option2}
              inputProps={{
                placeholder: t(`incentives:${option2}`),
                ref: register,
              }}
            />

            {option3 !== 'percentAmount' ? (
              <FormInput
                isRequired
                label={t(`incentives:${option3}`)}
                name={option3}
                inputProps={{
                  placeholder: t(`incentives:${option3}`),
                  ref: register,
                  type: 'string',
                }}
              />
            ) : null}

            <Button
              style={{ marginTop: '27px', width: '75%', marginLeft: '8%' }}
              variantColor='blue'
              variant='outline'
              type='submit'
            >
              <div style={{ paddingTop: '1%' }}>{t('common:save')}</div>
            </Button>
          </SimpleGrid>
        </Stack>
      </PseudoBox>
    </>
  )
}

export default AddStatus2
