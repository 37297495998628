import React from 'react'
import MenuForm from '../../forms/menu/menu'
import { getAuthToken, getToken } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { deconstructPermission } from '../../../helpers/permissions-functions'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { UpdateCompanySections } from '../../../api/section'
import { COMPANY_SECTIONS_CREATE } from '../../../helpers/permissions'
import { isSuccess } from '../../../helpers/utils'

export default function CreateMenu({ onFinish = () => null, getCompaniesSection, onClose, ...restProps }) {
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const toast = useToast()

  const handleSubmit = async formData => {
    let sectionId = restProps.dataEditParams.id
    try {
      const sesion = {
        token,
        ...deconstructPermission(COMPANY_SECTIONS_CREATE),
      }
      const data = { sesion, ...formData }
      const response = await UpdateCompanySections(sectionId, data, authToken)
      if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        getCompaniesSection()
        onClose()
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    onFinish()
  }

  return (
    <div>
      <MenuForm
        onSubmit={handleSubmit}
        buttonLabel={t('common:create')}
        dataEditParams={restProps.dataEditParams}
        isLoadingCS={restProps.isLoadingCS}
        CS={restProps.CS}
        isLoadingDS={restProps.isLoadingDS}
        DS={restProps.DS}
        combined={restProps.combined}
      />
    </div>
  )
}
