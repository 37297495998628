import React, { useEffect, useState } from 'react'
import { Box, Spinner, useToast } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getSupplierById } from '../../../../api/intranet/requests'
import { getAuthToken } from '../../../../redux/selectors'
import { useTranslation } from 'react-i18next'
import { Button } from '@chakra-ui/core'
import { updateSupplier } from '../../../../api/intranet/requests'
import { isSuccess } from '../../../../helpers/utils'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default function SupplierAdditionalInfo() {
  const { t } = useTranslation(['applications', 'suppliers', 'forms'])
  const { id: supplierId } = useParams()
  const toast = useToast()
  const authToken = useSelector(getAuthToken)
  const [resetEditor, setResetEditor] = useState(false)
  const [supplierAditionalInfo, setSupplierAditionalInfo] = useState('')
  const [isLoaded, setLoaded] = useState(false)

  const getSupplier = async () => {
    setSupplierAditionalInfo('')
    try {
      const response = await getSupplierById(supplierId, authToken)
      if (response.data.infoAdicional) {
        setSupplierAditionalInfo(response.data.infoAdicional)
      }
      setLoaded(true)
    } catch (error) {
      setSupplierAditionalInfo('')
      setLoaded(false)
      console.log(error)
    }
  }

  const submit = async () => {
    try {
      const data = {
        infoAdicional: supplierAditionalInfo,
      }
      const response = await updateSupplier(supplierId, data, authToken)
      if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      console.log(error)
    }
  }

  var toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  useEffect(() => {
    getSupplier()
  }, [])

  return (
    <Box
      style={{
        borderWidth: 0.5,
        borderColor: '#e5e5e5',
        backgroundColor: 'white',
        padding: '1rem',
        minHeight: '30rem',
        position: 'relative',
      }}
    >
      <Box
        style={{
          zIndex: 0, 
          marginBottom: '55px' 
        }}
      >
      {supplierAditionalInfo.length !== 0 ? (
        isLoaded ? (
          <ReactQuill
          style={{ height: '350px'}}
          className=''
          modules={{ toolbar }}
          value={supplierAditionalInfo}
          onChange={setSupplierAditionalInfo}
        />
        ) : (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )
      ) : (
        <ReactQuill
        style={{ height: '350px'}}
        className=''
        modules={{ toolbar }}
        value={supplierAditionalInfo}
        onChange={setSupplierAditionalInfo}
      />
      )}
      </Box>
      <Button
        style={{ position: 'absolute', bottom: '0', marginBottom: '1rem' }}
        variantColor='blue'
        variant='outline'
        type='submit'
        onClick={submit}
      >
        {t('forms:save')}
      </Button>
    </Box>
  )
}
