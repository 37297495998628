import React, { useMemo, useState, useEffect } from 'react'
import { useDisclosure, Stack, Box, Spinner, Text, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createTypes, deleteTipoEnlace, getAllLinkTypes } from '../../../../api/web-information'
import TypeForm from './type-form'
import { getAuthToken } from '../../../../redux/selectors'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import Table from '../../../common/table'
import { Panel } from '../../../common/helpers'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import CreateType from './create-type'
import ModalSimple from '../../../common/modal-simple'
import { isSuccess } from '../../../../helpers/utils'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import Pagination from '../../../common/pagination'

export default function Type() {
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const [idType, setIdType] = useState(0)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  //Modal vbles
  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()

  // Load data for the table
  const [{ isLoading, linkTypes }, setlinkTypesRequest] = useState({
    isLoading: false,
    linkTypes: [],
  })

  async function getLinkType() {
    setlinkTypesRequest(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllLinkTypes({ page })

      setTotalPages(res.data.totalPages)

      if (res.data.totalPages < page) setPage(1)
      setlinkTypesRequest(state => ({
        ...state,
        isLoading: false,
        linkTypes: res.data.data,
      }))
    } catch (error) {
      console.log(error)

      setlinkTypesRequest(state => ({
        ...state,
        isLoading: false,
        linkTypes: [],
      }))
    }
  }

  //Edit functions
  const [idSectionn, setIdSection] = useState(0)

  function onEditClick(idSectionEdit) {
    let idSection = idSectionEdit
    setIdSection(idSection)
    modalDisclosure.onOpen()
  }

  async function handleDelete() {
    try {
      const res = await deleteTipoEnlace(idType, authToken)

      if (isSuccess(res.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      }
      modalDisclosure2.onClose()
      getLinkType()
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getLinkType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('forms:name')}`,
        accessor: 'nombre',
        Cell: ({ cell }) => (
          <Stack spacing='5px'>
            {cell.row.values.nombre.length > 30 ? (
              <Text>{cell.row.values.nombre.substring(0, 30)}...</Text>
            ) : (
              <Text>{cell.row.values.nombre}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:description'),
        accessor: 'descripcion',
        Cell: ({ cell }) => (
          <Stack spacing='5px'>
            {cell.row.values.descripcion.length > 60 ? (
              <Text>{cell.row.values.descripcion.substring(0, 60)}...</Text>
            ) : (
              <Text>{cell.row.values.descripcion}</Text>
            )}
          </Stack>
        ),
      },
      {
        Header: t('forms:category'),
        accessor: 'categoria.descripcion', // `accessor` es una key del objeto que se obtuvo con el GET de secciones
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit mb={4} onClick={() => onEditClick(cell.row.values.id)}></ButtonEdit>
            <ButtonDelete
              mb={4}
              onClick={() => {
                setIdType(cell.row.values.id)
                return modalDisclosure2.onOpen()
              }}
            ></ButtonDelete>
          </Stack>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  ///Create type
  async function createType(formData) {
    try {
      formData.categoria = { id: parseInt(formData.categoria.id) }
      const response = await createTypes(formData, authToken)
      getLinkType()
      if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      }
    } catch (error) {
      if (error.response) {
        console.log(error)
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  function onSubmit(datos) {
    createType(datos)
  }
  return (
    <Box>
      {/* Edit modal */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${t('web-information:type')}`}
        size='lg'
      >
        <CreateType idSectionn={idSectionn} getLinkType={getLinkType} onClose={modalDisclosure.onClose} />
      </ModalSimple>

      <AlertDialogSimple
        isOpen={modalDisclosure2.isOpen}
        onClose={modalDisclosure2.onClose}
        onAccept={handleDelete}
        title={`${t('common:delete')} ${t('common:type')}`}
        size='lg'
      >
        {' '}
        <p>{`${t('common:deletingType')}`}</p>
      </AlertDialogSimple>

      <Panel rounded='md' mb='1.5rem'>
        <TypeForm onSubmit={onSubmit} />
      </Panel>
      {!isLoading && linkTypes.length >= 1 ? <Table columns={columns} data={linkTypes} /> : null}

      {isLoading && (
        <Box px='5' py='10' textAlign='center'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Box>
      )}
      {!isLoading && linkTypes.length === 0 && (
        <Text textAlign='center' color='gray.500' px='5' py='10'>
          {t('common:zeroItems')}
        </Text>
      )}
      {!isLoading && !linkTypes && (
        <Text textAlign='center' color='gray.500' px='5' py='10'>
          {t('common:errorGettingItems')}
        </Text>
      )}

      {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
    </Box>
  )
}
