import React, { useState, useEffect } from 'react'
import SupplierIncentivesForm from '../../../forms/suppliers/supplier-incentives-form'
import SupplierIncentivesFormEdit from '../../../forms/suppliers/supplier-incentives-form-edit'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import { getToken, getAuthToken } from '../../../../redux/selectors'
import SupplierIncentivesCard from './supplier-incentives-childs.js/supplier-incentives-card'
import { useSelector } from 'react-redux'
import {
  postIncentivesId,
  getIncentivesProviderId,
  postScales,
  postStatus,
  getStatusIncentiveId,
  putUpdateScales,
  deleteIncentive,
  deleteScales,
} from '../../../../api/incentives'
import ModalSimple from '../../../common/modal-simple'
import { Panel } from '../../../common/helpers'
import { isSuccess } from '../../../../helpers/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDisclosure, Button, Box, SimpleGrid } from '@chakra-ui/core'
import { GridItem } from '@chakra-ui/react'
import '../../../styles/suppliers/incentives.css'
import AddStatus2 from '../../../forms/suppliers/supplier-incentives-subforms.js/addStatus2'
import AddStatus from '../../../forms/suppliers/supplier-incentives-subforms.js/addStatus'
import Pagination from '../../../common/pagination'
import AlertDialogSimple from '../../../common/alert-dialog-simple'

function SupplierIncentives() {
  // - GENERAL

  const toast = useToast()
  const { id: supplierId } = useParams()
  const [incentivos, setIncentivos] = useState()
  const { t } = useTranslation(['incentives'])

  // - MODAL HOOKS

  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()
  const modalDisclosure3 = useDisclosure()

  const [inf, setInf] = useState(1)
  const [status, setStatus] = useState()
  const [edit, setEdit] = useState(false)
  const [formData, setFormData] = useState(false)
  const [test, setTest] = useState(1)
  const authToken = useSelector(getAuthToken)
  const token = useSelector(getToken)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [deleting, setDeleting] = useState(undefined)
  const [hasScales, setHasScales] = useState(false)
  // - - - -❗METHODS❗- - - -

  function submitForm(data, startDate, endDate) {
    newStatus(data, startDate, endDate)
  }

  function viewStatus(data, hasScale) {
    getStatus(data)
    setHasScales(hasScale)
    setTest(1)
    setInf(data)
    modalDisclosure3.onOpen()
  }

  function editStatus() {
    modalDisclosure.onOpen()
  }

  function agregarStatusClick(data) {
    setInf(data)
    setTest(2)
    modalDisclosure2.onOpen()
  }

  function agregarEscalaClick(b) {
    setInf(b)
    setTest(3)
    modalDisclosure2.onOpen()
  }

  function editGeneral(data, data2) {
    setInf(data)
    setFormData(data2)
    setTest(4)
    modalDisclosure.onOpen()
  }

  function editRow(data, data2) {
    setFormData(data2)
    setInf(data)
    setTest(5)
    modalDisclosure.onOpen()
  }

  function deleteItem(data, id) {
    switch (true) {
      case data === 'General':
        setInf(id)
        setTest(7)
        break
      case data === 'deleteStatus':
        setInf(id)
        setTest(8)
        break
      default:
        setTest(1)
    }
    modalDisclosure.onOpen()
    setDeleting(incentivos.filter(i => i.id === id))
  }

  console.log(formData)
  // - - - - - ⬜ MODALS - VISTAS ⬜ - - - - -

  function create(title, option1, option2, option3) {
    return (
      <ModalSimple
        boxShadow='xl'
        size='6xl'
        isOpen={modalDisclosure2.isOpen}
        onClose={modalDisclosure2.onClose}
        title={t(`incentives:${title}`)}
      >
        <hr />
        <br />
        <AddStatus2
          option1={option1}
          option2={option2}
          option3={option3}
          option4={inf}
          status={status}
          onSubmit={onClickAddEscala}
        />
      </ModalSimple>
    )
  }

  // - - EDITAR - -

  function editar(title, option1, option2, option3) {
    return (
      <ModalSimple
        boxShadow='xl'
        size='6xl'
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={t(`incentives:${title}`)}
      >
        <hr />
        <br />
        {formData !== false ? <SupplierIncentivesFormEdit formData={formData} onclickUpdate={onclickUpdate} /> : null}
      </ModalSimple>
    )
  }

  // - - BORRAR - -

  function deleteting(title, option1, option2, option3) {
    return title === 'DeleteStatus' ? (
      <AlertDialogSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        onAccept={onclickDelete}
        title={t('incentives:deleteStatus')}
        acceptLabel={t('common:delete')}
        acceptVariantColor='red'
      >
        {t('common:deleting')} <br />
      </AlertDialogSimple>
    ) : (
      <AlertDialogSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        onAccept={onclickDelete}
        title={t('incentives:deleteQuestion')}
        acceptLabel={t('common:delete')}
        acceptVariantColor='red'
      >
        {t('common:deleting')} <br />
        <b>{deleting.length && `${deleting[0].nombre} (#${deleting[0].id})`}</b>
      </AlertDialogSimple>
    )
  }

  // - - - - - 🔴  📞 API CALLS 📞 🔴  - - - - -

  // 🔴🔴 - -📞 POST- CREAR INCENTIVOS 📞- -

  async function newStatus(data, startDate, endDate) {
    const payload = {
      proveedor: { id: parseInt(supplierId) },
      nombre: data.nombre,
      activo: true,
      unidadMedicion: { id: parseInt(data.unidadMedicion.id) },
      fechaInicio: startDate,
      fechaFin: endDate,
      sesion: {
        token: token,
        modulo: '',
        accion: '',
        nombre: '',
      },
    }
    try {
      const response = await postIncentivesId(payload, authToken)
      getIncentiv()
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('incentives:successMessage'))
          : toastCustomizedError(t('incentives:errorMessage')),
      )
    } catch (error) {
      console.log(error)
    }
  }

  // 🔴🔴- -📞 GET - TRAER INCENTIVOS📞- -

  async function getIncentiv() {
    try {
      const response = await getIncentivesProviderId({ proveedorId: supplierId, page, maxPerPage: 12 })

      setTotalPages(response.data.totalPages)
      if (response.data.totalPages === 1) setPage(1)
      if (response.data !== null) {
        setIncentivos(response.data.data)
      }
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  // 🔴🔴 - -📞 PUT BORRAR INCENTIVO 📞 - -

  async function onclickDelete(data) {
    if (deleting.length) {
      try {
        const response = await deleteIncentive(inf, authToken)
        toast(
          response.status
            ? toastCustomizedError(t('incentives:delete_incentive'))
            : toastCustomizedError(t('incentives:error_delete_incentive')),
        )

        getIncentiv()
        setStatus(undefined)
        modalDisclosure.onClose()
      } catch (error) {
        console.log(error)
        modalDisclosure.onClose()
      }
    } else {
      try {
        const response = await deleteScales(inf, authToken)
        setDeleting(undefined)
        toast(
          response.status
            ? toastCustomizedError(t('incentives:delete_status'))
            : toastCustomizedError(t('incentives:error_delete_status')),
        )

        getIncentiv()
        modalDisclosure.onClose()
      } catch (error) {
        console.log(error)
        modalDisclosure.onClose()
      }
    }
  }

  // 🔴🔴  - -📞 ACTUALIZAR INCENTIVO 📞 - -

  async function onclickUpdate(data, a, b) {
    let fechaInicio = a === 'Invalid date' ? formData.fechaInicio : a
    let fechaFin = b === 'Invalid date' ? formData.fechaFin : b
    try {
      let infoenviar = {
        proveedor: { id: parseInt(supplierId) },
        nombre: data.nombre,
        unidadMedicion: { id: parseInt(data.unidadMedicion.id) },
        fechaInicio,
        fechaFin,
      }

      await putUpdateScales(inf, infoenviar, authToken)

      getIncentiv()
      modalDisclosure.onClose()
    } catch (error) {
      console.log(error)
      modalDisclosure.onClose()
    }
  }

  //🔴🔴 - -📞 POST - CREAR ESTATUS Y ESCALA 📞- -

  async function onClickAddEscala(data) {
    if (data.endAmount) {
      try {
        let ui = {
          incentivo: {
            id: inf,
          },
          montoInicial: parseInt(data.initialAmount),
          montoFinal: parseInt(data.endAmount),
          incentivoPorcentaje: data.incentive,
        }
        const response = await postScales(ui, authToken)
        toast(
          isSuccess(response.status)
            ? toastCustomizedSuccess(t('incentives:add_scale'))
            : toastCustomizedError(t('incentives:error_add_scale')),
        )
        getIncentiv()
        modalDisclosure2.onClose()
      } catch (error) {
        console.log(error)
        modalDisclosure2.onClose()
      }
    } else {
      try {
        let ui = {
          incentivo: {
            id: inf,
          },
          montoParcial: parseInt(data.monto_parcial),
          mesesCargados: parseInt(data.mese_cargados),
          notas: data.notas,
          fechaIngreso: new Date(),
        }
        const response = await postStatus(ui, authToken)
        modalDisclosure.onClose()
        toast(
          isSuccess(response.status)
            ? toastCustomizedSuccess(t('incentives:add_status'))
            : toastCustomizedError(t('incentives:error_add_status')),
        )
      } catch (error) {
        console.log(error)
        modalDisclosure.onClose()
      }
    }
  }

  //🔴🔴 - -📞 GET - TRAER ESTATUS 📞- -

  async function getStatus(id) {
    try {
      const response = await getStatusIncentiveId(id, authToken)
      setStatus(response.data)
    } catch (error) {
      console.log('GET INCENTIVES ERROR', error)
    }
  }

  useEffect(() => {
    getIncentiv()
  }, [])

  useEffect(() => {
    getIncentiv()
  }, [page])

  return (
    <Box>
      <ModalSimple
        boxShadow='xl'
        size='2xl'
        isOpen={modalDisclosure3.isOpen}
        onClose={modalDisclosure3.onClose}
        title={edit ? `${t('incentives:edit')} ${t('incentives:status')}` : `${t('incentives:status')}`}
      >
        <hr />

        <br />

        <AddStatus
          status={status}
          onClose={modalDisclosure3.onClose}
          incentivoId={inf}
          getStatus={getStatus}
          getIncentiv={getIncentiv}
          hasScales={hasScales}
        />
      </ModalSimple>
      {/* Form */}

      <Panel mb={6}>
        <SupplierIncentivesForm submitForm={submitForm} />
      </Panel>

      {/* Header */}

      <Box
        style={{ borderRadius: '5px', color: 'white', padding: '.5rem 1rem' }}
        backgroundColor='blue.500'
        className='headerOptions'
      >
        <SimpleGrid columns={{ sm: 1, md: 13 }} style={{ textAlign: 'center' }} spacing={10}>
          <GridItem colSpan={1}>
            <Box fontSize='lg'>ID</Box>
          </GridItem>
          <GridItem style={{ minWidth: '4rem' }} colSpan={1}>
            <Box fontSize='lg'>{t('incentives:status')}</Box>
          </GridItem>
          <GridItem colSpan={2}>
            <Box fontSize='lg'>{t('incentives:name')}</Box>
          </GridItem>
          <GridItem colSpan={3}>
            <Box fontSize='lg'>{t('incentives:period')}</Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Box fontSize='lg'>{t('incentives:unit')}</Box>
          </GridItem>
          <GridItem colSpan={4}>
            <Box fontSize='lg' textAlign='center'>
              {t('incentives:actions')}
            </Box>
          </GridItem>
        </SimpleGrid>
      </Box>

      {/* Acordions */}

      {incentivos &&
        incentivos.map(incentivo => (
          <div key={incentivo.id} className='acordion'>
            <SupplierIncentivesCard
              x={incentivo}
              agregarStatusClick={agregarStatusClick}
              agregarEscalaClick={agregarEscalaClick}
              editGeneral={editGeneral}
              editRow={editRow}
              deleteItem={deleteItem}
              viewStatus={viewStatus}
            />
          </div>
        ))}
      <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />

      {/* Options Modals */}
      {test === 2
        ? create('addStatus', 'monto_parcial', 'notas', 'mese_cargados')
        : test === 3
        ? create('addScale', 'initialAmount', 'endAmount', 'incentive')
        : test === 4
        ? editar('Edición', 'Nombre', 'Periodo', 'Unidad de Medición')
        : test === 5
        ? editar('Edit One Row', 'Nombre', 'Periodo', 'Unidad de Medición')
        : test === 7
        ? deleteting('DeleteIncentive')
        : test === 8
        ? deleteting('DeleteStatus')
        : test === 9
        ? editStatus('addStatus', 'monto_parcial', 'notas', 'mese_cargados')
        : null}
    </Box>
  )
}

export default SupplierIncentives
