import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Checkbox, FormLabel, Grid, Input, InputGroup, Spinner, Stack, Text } from '@chakra-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { TextField } from '@material-ui/core'

import { getAuthToken, getUserCountryId } from '../../../../../redux/selectors'
import { getHotels } from '../../../../../api/intranet/requests'
import { getAllCitiesQuery } from '../../../../../api/geo'
import Table from '../../../../common/table'
import { Panel } from '../../../../common/helpers'
import Pagination from '../../../../common/pagination'
import './hotel-modal.css'

export const HotelSelectorModal = ({ editHotel, setEditHotel, onClose, ...restProps }) => {
  const { t } = useTranslation(['common', 'forms', 'packages'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const [triggerFilter, setTriggerFilter] = useState(0)
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState()
  const handleSetPage = value => setPage(value)

  let language
  switch (countryId) {
    case 1:
      language = 'ar'
      break
    case 3:
      language = 'pt'
      break
    default:
      language = 'en'
      break
  }

  //REQUEST HOTELES
  const [hotelResponse, setHotelResponse] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getHotel(page) {
    setHotelResponse(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getHotels(
        {
          localidadId: selectedCity?.id,
          lenguaje: language,
          name: hotelName,
          pageSize: 12,
          page,
        },
        authToken,
      )
      setHotelResponse(state => ({
        ...state,
        isLoading: false,
        items: (res.status && res.data.data) || [],
      }))
      setTotalPages(res.data.lastPage)
    } catch (error) {
      setHotelResponse(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  const [hotelSelected, setHotelSelected] = useState(editHotel?.hotel)

  function handleSelectedHotelChange(row) {
    setHotelSelected(row)
    setEditHotel({ ...editHotel, hotelId: Number(row.id), hotel: row })
  }

  //TRAER LOCALIDADES / DESTINOS
  const [loc, setLoc] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getLocalidades(city = null) {
    setLoc(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllCitiesQuery({ search: city, origins: true })
      setLoc(state => ({
        ...state,
        isLoading: false,
        items: res.data,
      }))
    } catch (error) {
      setLoc(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  const [selectedCity, setSelectedCity] = useState(undefined)
  const [hotelName, setHotelName] = useState(undefined)

  function handleSearch(e) {
    if (e.target.value.length > 2) {
      getLocalidades(e.target.value)
    } else {
      setLoc({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }
  function handleChangeCity(event, value) {
    setSelectedCity(value)
  }
  function handleChangeHotelName(event, value) {
    setHotelName(event.target.value)
  }

  const cleanFilter = () => {
    setLoc({
      isLoading: false,
      items: [],
      error: false,
    })
    setHotelName(undefined)
    setSelectedCity(undefined)
    setTriggerFilter(state => state + 1)
    setPage(1)
  }

  const columns = useMemo(() => [
    {
      Header: t('forms:origin'),
      accessor: row => {
        return row.localidad.nombre
      },
    },
    {
      Header: t('forms:name'),
      accessor: row => {
        return row.name
      },
    },
    {
      Header: t('common:category'),
      accessor: row => {
        return row.category
      },
    },
    {
      Header: t('forms:select'),
      accessor: null,
      Cell: ({ cell }) => {
        return (
          <Stack spacing='5px' isInline d='flex' mx='1'>
            <Checkbox
              isChecked={cell.row.original.id === hotelSelected?.id}
              onChange={() => handleSelectedHotelChange(cell.row.original)}
            ></Checkbox>
          </Stack>
        )
      },
    },
  ])

  useEffect(() => {
    setPage(1)
    getHotel()
  }, [triggerFilter])

  useEffect(() => {
    getHotel(page)
  }, [page])

  return (
    <Box {...restProps}>
      <Panel rounded='md' mb='1.5rem'>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} mb='2' mt='2'>
          {/* Origen */}
          <Box d='flex' alignItems='center' px='1' py='28px'>
            <Autocomplete
              id='originCode'
              name='originCode'
              options={loc.items}
              getOptionLabel={option => `${option.nombre} (${option.provincia.nombre})`}
              style={{ width: '100%' }}
              size='small'
              value={selectedCity || null}
              onChange={(event, newValue) => {
                handleChangeCity(event, newValue)
              }}
              renderInput={params => (
                <TextField
                  onChange={e => {
                    handleSearch(e)
                  }}
                  {...params}
                  label={t('forms:origin')}
                  variant='outlined'
                />
              )}
            />
          </Box>
          <Box d='flex' alignItems='center' px='1' py='28px'>
              <Input
                value={hotelName || ''}
                onChange={e => handleChangeHotelName(e)}
                px={1}
                placeholder={t('forms:name')}
                name='hotelName'
              />
          </Box>
          <Box d='flex' alignItems='center' px='1'>
            <Button variantColor='blue' variant='outline' onClick={() => getHotel()} mr='2'>
              {t('common:filter')}
            </Button>
            <Button
              variantColor='blue'
              variant='outline'
              onClick={() => {
                cleanFilter()
              }}
            >
              {t('comunications:cleanFilter')}
            </Button>
          </Box>
        </Grid>
      </Panel>
      {hotelResponse.isLoading && (
        <Box px='5' py='10' textAlign='center'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Box>
      )}
      {!hotelResponse.isLoading && hotelResponse.items?.length > 0 && (
        <>
          <Table columns={columns} data={hotelResponse.items} />
          <Pagination currentPage={page} setPage={handleSetPage} totalPages={totalPages} selectPage={false} />
        </>
      )}
      {!hotelResponse.isLoading && hotelResponse.items?.length === 0 && (
        <Text textAlign='center' color='gray.500' px='5' py='10'>
          {t('common:zeroItems')}
        </Text>
      )}
      <Box>
        <Button variantColor='red'
          variant='outline' onClick={() => onClose()}>{t('common:close')}</Button>
      </Box>
    </Box>
  )
}
