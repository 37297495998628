import React, { useState } from 'react'
import { Box, Grid, Tag, Text } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../../../common/helpers'
import RLDD from 'react-list-drag-and-drop/lib/RLDD'
import FormItinerary from './itinerary/form-itinerary'
import { ButtonEdit, ButtonDelete } from '../../../../forms/buttons/edit'
//import ItineraryItem from './itinerary/itinerary-item';

export const PackageItinerary = ({ itineraryList, onChange }) => {
  const { t } = useTranslation(['common', 'forms', 'packages'])

  //MANEJO DE DÍA DE ITINERARIO
  const [edit, setEdit] = useState({
    state: false,
    key: 0,
  })

  function disableEdit() {
    setEdit({
      state: false,
      key: 0,
    })
  }

  function saveItem(updatedItem) {
    const updatedItineraryList = itineraryList.map((item, index) => ({ ...(index === edit.key ? updatedItem : item) }))
    disableEdit()
    onChange(updatedItineraryList)
  }

  function addItem(item) {
    if (item.descripton !== '' && item.title !== '') {
      const orderById = itineraryList.sort((a, b) => a.id - b.id)
      const nextId = orderById.length === 0 ? 0 : orderById[orderById.length - 1].id + 1 // (+1) para generar el proximo mayor id

      const orderByOrder = itineraryList.sort((a, b) => a.order - b.order)
      const nextOrderId = orderByOrder.length === 0 ? 0 : orderByOrder[orderByOrder.length - 1].order + 1 // (+1) para generar el proximo mayor Order

      onChange(
        itineraryList.concat({
          ...item,
          id: nextId,
          order: nextOrderId,
        }),
      )
    }
  }

  function handleOnClickEdit(index) {
    //console.log('index edit',index)
    setEdit({
      state: true,
      key: index,
    })
  }
  function updateOrder(list) {
    return list.map((item, index) => ({ ...item, order: index }))
  }
  function handleChangeListOrder(updatedItems) {
    onChange(updateOrder(updatedItems))
  }

  function handleOnClickDelete(itemId) {
    const listUpdated = itineraryList.filter(item => item.id !== itemId)
    onChange(updateOrder(listUpdated))
  }

  return (
    <Panel mb='8'>
      <Tag width='100%' mb='3' p='3' bg='blue.500' color='white'>
        {t('packages:itinerary')}
      </Tag>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} height='300px'>
        <Box alignItems='center'>
          <FormItinerary
            handleAddItem={addItem}
            handleSaveItem={saveItem}
            handleDiscard={/* () => */ disableEdit /* () */}
            edition={edit}
            itineraryList={itineraryList}
          />
        </Box>
        <Box style={{ overflowY: 'auto' }}>
          <Box display='flex' alignItems='center'>
            <Text fontSize='18px'>{t('packages:listOfLoadedDays')}</Text>
            <Text fontSize='16px' marginLeft='5px'>{`(${t('packages:editTheOrderOfTheDays')})`}</Text>
          </Box>
          <RLDD
            items={itineraryList.sort((a, b) => a.order - b.order)}
            onChange={handleChangeListOrder}
            itemRenderer={(item, index) => {
              return (
                <Grid
                  templateColumns={{ base: 'repeat(1, 1fr)', md: '22% 56% 22%' }}
                  key={index}
                  mt='1'
                  bg={index & 1 ? '' : 'gray.100'}
				  style={{color: '#718096'}}
                >
                  <Text ml='2' my='2' cursor='move'>
                    {item.title.length > 25 ? `${item.title.substring(0, 25)}...` : item.title}
                  </Text>
                  <Text ml='2' my='2' cursor='move'>
                    {item.description.length > 45 ? `${item.description.substring(0, 50)}...` : item.description}
                  </Text>
                  <Box my='2'>
                    <ButtonEdit
                      isDisabled={edit.key === index && edit.state}
                      //onClick={()=>handleOnClickEdit(item.id)}
                      onClick={() => handleOnClickEdit(item.order)}
                      mr='1'
                    />
                    <ButtonDelete isDisabled={edit.state} onClick={() => handleOnClickDelete(item.id)} />
                  </Box>
                  {/*<Divider orientation="horizontal" /> */}
                </Grid>
              )
            }}
          />
        </Box>
      </Grid>
    </Panel>
  )
}
