import React from 'react'

import { SectionDataProvider } from '../../../context/SectionDataContext'
import IncentivesContent from './incentives-content'

const FILTER_KEYS = {
  paisId: 'paisId',
  categoriaId: 'categoriaId',
  proveedorId: 'proveedorId',
  status: 'status',
  nombre: 'nombre',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  incentivos: 'incentivos',
}

const defaultSectionDataValues = {
  incentivos: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFilters = {
  page: 1,
  maxPerPage: 9,
  proveedorId: '',
  categoriaId: '',
  status: true,
  paisId: '',
  nombre: '',
}
const defaultFiltersValues = { ...defaultFilters }

const Incentives = () => (
  <SectionDataProvider defaultFilters={{ ...defaultFiltersValues }} defaultSectionData={defaultSectionDataValues}>
    <IncentivesContent />
  </SectionDataProvider>
)

export { Incentives, FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues, defaultFiltersValues, defaultFilters }
