import React from 'react';
import { Switch, Divider } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';

function AccessEdition({ newPermission, funcCheck }) {
  const { t } = useTranslation(['comunications']);

  return (
    <>
      <Divider
        style={{ borderColor: 'blue', marginLeft: '7%', width: '80%' }}
      />

      <div>
        <Switch
          style={{
            marginLeft: '15%',
            marginRight: '3%',
            padding: '0.5rem',
          }}
          defaultIsChecked={funcCheck(3)}
          onChange={() => newPermission(3, funcCheck(3))}
        ></Switch>
        <b>{t('comunications:directorAgency')}</b>
      </div>

      <Divider
        style={{ borderColor: 'blue', marginLeft: '7%', width: '80%' }}
      />

      <div>
        <Switch
          style={{
            marginLeft: '15%',
            marginRight: '3%',
            padding: '0.5rem',
          }}
          defaultIsChecked={funcCheck(4)}
          onChange={() => newPermission(4, funcCheck(4))}
        ></Switch>
        <b>{t('comunications:agency')}</b>
      </div>

      <Divider
        style={{ borderColor: 'blue', marginLeft: '7%', width: '80%' }}
      />

      <div>
        <Switch
          style={{
            marginLeft: '15%',
            marginRight: '3%',
            padding: '0.5rem',
          }}
          defaultIsChecked={funcCheck(5)}
          onChange={() => newPermission(5, funcCheck(5))}
        ></Switch>
        <b>{t('comunications:counter')}</b>
      </div>

      <Divider
        style={{ borderColor: 'blue', marginLeft: '7%', width: '80%' }}
      />

      <div>
        <Switch
          style={{
            marginLeft: '15%',
            marginRight: '3%',
            padding: '0.5rem',
          }}
          defaultIsChecked={funcCheck(6)}
          onChange={() => newPermission(6, funcCheck(6))}
        ></Switch>
        <b>{t('comunications:adminSite')}</b>
      </div>

      <Divider
        style={{ borderColor: 'blue', marginLeft: '7%', width: '80%' }}
      />

      <div>
        <Switch
          style={{
            marginLeft: '15%',
            marginRight: '3%',
            padding: '0.5rem',
          }}
          defaultIsChecked={funcCheck(7)}
          onChange={() => newPermission(7, funcCheck(7))}
        ></Switch>
        <b>{t('comunications:provider')}</b>
      </div>

      <Divider
        style={{ borderColor: 'blue', marginLeft: '7%', width: '80%' }}
      />
    </>
  );
}

export default AccessEdition;
