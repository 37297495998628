import React, { useState, useEffect } from 'react'
import { PageContainer, Panel } from '../../common/helpers'
import { Heading, useToast, Box, Text, Spinner } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import MenuForm from './menu-form'
import {
  getCompaniesSections,
  createCompanySections,
  getCustomSections,
  getDefaultSections,
} from '../../../api/section'
import { isSuccess } from '../../../helpers/utils'
import { useSelector } from 'react-redux'
import { getToken, getAuthToken, getUserCountryId, getUserApplicationId } from '../../../redux/selectors'
import { deconstructPermission } from '../../../../src/helpers/permissions-functions'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import RenderTable from './render-table'
import AOS from 'aos'
import { getDefaultTemplateId } from '../../../api/intranet/marca-blanca'
export default function Menu() {
  const { t } = useTranslation(['common', 'forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const appId = useSelector(getUserApplicationId)
  const userCountryId = useSelector(getUserCountryId)
  const [defaultTemplateId, setDefaultTemplateId] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [resetOrden, setResetOrden] = useState(false)
  const [combined, setCombined] = useState(0)
  const [sections, setSections] = useState()

  const toast = useToast()

  // Load data for the table
  const [{ isLoading, companiesSections }, setCompSecRequest] = useState({
    isLoading: false,
    companiesSections: [],
  })

  //GET Menus
  async function getCompaniesSection() {
    setCompSecRequest(state => ({
      ...state,
      isLoading: true,
    }))
    await getCompaniesSections({ empresaId: appId, page })
      .then(res => {
        setTotalPages(res.data.totalPages)

        setCompSecRequest(state => ({
          ...state,
          isLoading: false,
          companiesSections: res.data.data,
        }))
      })
      .catch(error => console.log('ErrorCompaniesSections', error))
  }

  //Hooks CS y DS
  const [{ isLoadingCS, CS }, setCSRequest] = useState({
    isLoadingCS: false,
    CS: [],
  })

  const [{ isLoadingDS, DS }, setDSRequest] = useState({
    isLoadingDS: false,
    DS: [],
  })

  async function getSections() {
    //CS
    setCSRequest(state => ({
      ...state,
      isLoadingCS: true,
    }))
    const customSections = await getCustomSections({ empresaId: appId }, authToken)

    setCSRequest(state => ({
      ...state,
      isLoadingCS: false,
      CS: customSections.data,
    }))

    //DS
    let defaultSectionsList = []
    setDSRequest(state => ({
      ...state,
      isLoadingDS: true,
    }))
    await getDefaultSections({ paisId: userCountryId, authToken })
      .then(res => {
        defaultSectionsList = res.data
      })
      .catch(error => console.log('ErrorDS', error))

    // Descarta las DS con active=false
    const activeDSList = defaultSectionsList.filter(section => {
      return section.activo
    })
    setDSRequest(state => ({
      ...state,
      isLoadingDS: false,
      DS: activeDSList,
    }))

    let i = 0
    const miCombined = [...customSections.data, ...activeDSList].map(
      section => (
        i++,
        {
          id: i,
          section: section,
        }
      ),
    )
    setCombined(miCombined)
  }

  async function getDefaultTemplate() {
    const response = await getDefaultTemplateId(authToken)
    const id = response.data.componenteTemplate.componente.id

    setDefaultTemplateId(id)
  }

  useEffect(() => {
    getSections()
    getCompaniesSection()
    getDefaultTemplate()
    AOS.init({
      duration: 1000,
    })
    AOS.refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  ///Create Menu
  async function createMenu(formData) {
    try {
      const sesion = {
        token,
        ...deconstructPermission('marca-blanca-empresa-seccion:crud'),
      }
      const data = { sesion, ...formData }
      const response = await createCompanySections(data, authToken)

      if (isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
        document.getElementById('menu-form').reset()
        setResetOrden(!resetOrden)
        getCompaniesSection()
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  function onSubmit(datos) {
    const seccion = JSON.parse(datos.sectionSelection)
    let formData = {
      empresaId: appId,
      esUrl: datos.urlChecked,
      orden: parseInt(datos.orden),
      activo: datos.activoChecked,
      ubicacionComponenteTemplate: {
        id: defaultTemplateId,
      },
      ...seccion,
    }
    createMenu(formData)
  }

  useEffect(() => {
    if(combined && companiesSections){
      const section = combined.filter(item => {
        const sectionId = item.section.id;
        const existsInSections = companiesSections.some(
          companySection =>
            companySection.seccionDefault?.id === sectionId || companySection.seccionCustom?.id === sectionId
        )
        return !existsInSections
      })
      setSections(section)
    }
  }, [combined, companiesSections])

  return (
    <PageContainer>
      <Box backgroundColor='' py='16px' mb='10px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {t('common:navbar.menu')}
        </Heading>
      </Box>
      {/* Alert delete */}

      {/* Edit modal */}

      <Panel rounded='md' mb={8}>
        <MenuForm
          onSubmit={onSubmit}
          resetOrden={resetOrden}
          isLoadingCS={isLoadingCS}
          CS={CS}
          isLoadingDS={isLoadingDS}
          DS={DS}
          combined={sections}
          getCompaniesSection={getCompaniesSection}
        />
      </Panel>
      {isLoading && (
        <Box px='5' py='10' textAlign='center'>
          <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
        </Box>
      )}
      {!isLoading && companiesSections && (
        <RenderTable
          isLoading={isLoading}
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          companiesSections={companiesSections}
          isLoadingCS={isLoadingCS}
          CS={CS}
          isLoadingDS={isLoadingDS}
          DS={DS}
          combined={combined}
          getCompaniesSection={getCompaniesSection}
        />
      )}
      {!isLoading && companiesSections.length === 0 && (
        <Text textAlign='center' color='gray.500' px='5' py='10'>
          {t('common:zeroItems')}
        </Text>
      )}
      {!isLoading && !companiesSections && (
        <Text textAlign='center' color='gray.500' px='5' py='10'>
          {t('common:errorGettingItems')}
        </Text>
      )}
    </PageContainer>
  )
}
