import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/dist/css/splide.min.css'
import '../styles/events/upcomingEventsSlider.css'
import '../styles/common/custom-splide-styles.css'
import { Flex, Image } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { youtubeParser } from '../../helpers/utils'

const MediaSlider = ({ mediaList }) => {
  const { t } = useTranslation(['comunications'])

  const defaultOptions = {
    padding: {
      left: 12,
      right: 12,
    },
    perPage: 1,
    arrows: true,
    classes: {
      pagination: 'splide__pagination custom-splide-pagination',
    },
  }

  // if (mediaList.length == 0)
  //   return (
  //     <Flex justifyContent='center' alignItems='center'>
  //       {t('comunications:noNews')}
  //     </Flex>
  //   )

  return (
    <Splide aria-label='Slider de medios' options={defaultOptions}>
      {mediaList.map(media => {
        let videoId = ""
        if (media.type === 'video') {
            videoId = youtubeParser(media.link)
        }

        return (
          <SplideSlide key={media.id} aria-label={media.type === 'video' ? 'Video' : 'Imagen'}>
            <Flex justifyContent='center' alignItems='center' background='white'>
              {media.type === 'video' ? (
                <iframe
                  width='1280'
                  height='600'
                  src={`https://youtube.com/embed/${videoId}`}
                  title='Video'
                  frameBorder='0'
                  allow='encrypted-media; web-share'
                  
                ></iframe>
              ) : (
                <Image src={media.link} width='1280px' height='600px' objectFit='fill' />
              )}
            </Flex>
          </SplideSlide>
        )
      })}
    </Splide>
  )
}

export default MediaSlider
