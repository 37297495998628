import { Button, Heading, Image, Flex, Tooltip } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../common/helpers'
import Acuerdos from '../../../static/img/acuerdos.png'
import Incentivos from '../../../static/img/incentivos.png'
import Informacion from '../../../static/img/informacion.png'
import { NavLinkAuthorized } from '../../layout/menu/menu-elements'
import { APPS_LIST, SUPPLIERS_AGREEMENTS_VIEW } from '../../../helpers/permissions'
import './supplier-item.css'
import AutoLogin2 from '../auto-login/auto-login'
import { useSelector } from 'react-redux'
import { getUserRolId } from '../../../redux/selectors'

export default function SupplierItem({ data, handleOpenModal, parametros }) {
  const { t } = useTranslation(['suppliers', 'common'])
  const userRolId = useSelector(getUserRolId)
  const { id, nombre, imagen, web } = data
  let shortName = nombre

  if (nombre.length > 26) {
    shortName = nombre.substring(0, 23) + '...'
  }

  const sideCardButtonStyles = last => {
    const styles = {
      marginBottom: last ? 'unset' : '0.5rem',
      width: '3rem',
      height: '3rem',
    }
    return styles
  }

  return (
    <Panel rounded='md' className='supplier-card'>
      <Flex flexDirection='row'>
        <Image
          style={{
            display: 'flex',
            flex: 1,
            maxWidth: '13.5rem',
            minWidth: '13.5rem',
            minHeight: '13.5rem',
            maxHeight: '13.5rem',
            width: '13.5rem',
            height: '13.5rem',
            border: '1px solid #1F408F22',
            borderRadius: '0.35rem',
            marginRight: '0.5rem',
            objectFit: 'contain',
            padding: '1rem',
          }}
          src={imagen}
          alt={nombre}
        />
        <Flex
          style={{
            flexDirection: 'column',
            width: '3rem',
          }}
        >
          {userRolId !== 5 && (
            <Tooltip label={t('suppliers:agreements')}>
              <Button
                color='#24428E'
                borderColor='#24428E'
                variantColor='blue'
                w='full'
                variant='outline'
                onClick={() => {
                  handleOpenModal({
                    title: `${nombre} ${t('suppliers:agreements')}`,
                    type: 'agreements',
                    id,
                  })
                }}
                style={sideCardButtonStyles()}
              >
                <Image
                  style={{
                    maxWidth: 'unset',
                    width: '2rem',
                    filter: 'invert(82%) sepia(91%) saturate(1379%) hue-rotate(207deg) brightness(98%) contrast(96%)',
                  }}
                  src={Acuerdos}
                />
              </Button>
            </Tooltip>
          )}
          {userRolId !== 5 && (
            <Tooltip label={t('common:information')}>
              <Button
                color='#24428E'
                borderColor='#24428E'
                variantColor='blue'
                w='full'
                variant='outline'
                data-id={id}
                data-name={nombre}
                data-type='information'
                onClick={() => {
                  handleOpenModal({
                    title: `${nombre} ${t('common:information')}`,
                    type: 'information',
                    id,
                  })
                }}
                style={sideCardButtonStyles()}
              >
                <Image
                  style={{
                    maxWidth: 'unset',
                    width: '2rem',
                    filter: 'invert(18%) sepia(91%) saturate(1379%) hue-rotate(207deg) brightness(98%) contrast(96%)',
                  }}
                  src={Informacion}
                />
              </Button>
            </Tooltip>
          )}
          <NavLinkAuthorized style={{ width: '100%' }} to={`/incentives?proveedorId=${id}`} permission={APPS_LIST}>
            <Tooltip label={t('suppliers:incentives')}>
              <Button
                color='#24428E'
                borderColor='#24428E'
                variantColor='blue'
                w='full'
                variant='outline'
                data-id={id}
                data-name={nombre}
                data-type='incentives'
                style={sideCardButtonStyles()}
              >
                <Image
                  style={{
                    maxWidth: 'unset',
                    width: '2rem',
                    filter: 'invert(82%) sepia(91%) saturate(1379%) hue-rotate(207deg) brightness(98%) contrast(96%)',
                  }}
                  src={Incentivos}
                />
              </Button>
            </Tooltip>
          </NavLinkAuthorized>
          {APPS_LIST && SUPPLIERS_AGREEMENTS_VIEW ? (
            <AutoLogin2 parametros={parametros} proveedorId={id} key={id} />
          ) : (
            ''
          )}
        </Flex>
      </Flex>
      {web ? (
        <a href={web} target='_blank' rel='noopener noreferrer' title={nombre}>
          <Heading as='h4' size='sm' my='0.75rem' ml='0.25rem' color='gray.600' textTransform='capitalize'>
            {shortName}
          </Heading>
        </a>
      ) : (
        <Heading as='h4' size='sm' my='0.75rem' ml='0.25rem' color='gray.600' textTransform='capitalize'>
          {nombre}
        </Heading>
      )}
    </Panel>
  )
}
