import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, PseudoBox, FormLabel } from '@chakra-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  getAuthToken,
  getUserCountryId,
  getUserInformation,
} from '../../../../redux/selectors'
import SelectAsync from '../../../common/select-async'
import { getAllIgnoreCategories, getCategoriesIsDefault } from '../../../../api/intranet/marca-blanca/banner'

export default function DefaultCategoryForm({ onSubmit, refetchCategoriesTrigger, ...restProps }) {
  const { handleSubmit, formState, control, reset } = useForm()
  const { t } = useTranslation(['common', 'forms', 'banners'])
  const countryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const user = useSelector(getUserInformation)

  //Traer categorías
  const [request, setRequest] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  const [items, setItems] = useState({
    isLoading: false,
    data: [],
    error: false,
  })

  const [filteredItems, setFilteredItems] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  async function getAllIgnoreCategory(appId) {
    setItems(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getAllIgnoreCategories({empresaId: appId}, authToken)
      setItems(state => ({
        ...state,
        isLoading: false,
        data: res.data || [],
      }))
    } catch (error) {
      setItems(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  async function getIgnoreCategory() {
    setRequest(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getCategoriesIsDefault({ paisId: countryId, esDefault: 'true'}, authToken)
      setRequest(state => ({
        ...state,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setRequest(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  const resetForm = () => {
    setFilteredItems({
      isLoading: false,
      items: [],
      error: false,
    })
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      resetForm()
      reset({})
    }
  }, [formState, reset])

  useEffect(() => {
    if (items?.data && request.items) {
      setFilteredItems(state => ({
        ...state,
        items: request.items?.filter(itemDefault =>
          items.data.every(itemData => itemData.categoriaBanner?.id !== itemDefault.id),
        ),
        isLoading: true,
      }))
    }
  }, [items, request.items])

  const printOption = option => (
    <option key={option.id} value={option.id}>
      {option.nombre}
    </option>
  )

  useEffect(() => {
    getIgnoreCategory()
    getAllIgnoreCategory(user.aplicacion.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchCategoriesTrigger])

  useEffect(() => {
    getIgnoreCategory()
    getAllIgnoreCategory(user.aplicacion.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <PseudoBox as='form' onSubmit={handleSubmit(onSubmit)} {...restProps}>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
        {/* Categoria */}
        <Box /* hidden={countrySelectorConfig.ishidden} */>
          <FormLabel isRequired>Categoría</FormLabel>
          <Controller
            isRequired
            control={control}
            name='category'
            //defaultValue={countrySelectorConfig.defaultValue}
            render={({ onChange, value, name }) => (
              <SelectAsync
                isRequired
                onChange={e => onChange(e.target.value)}
                placeholder={`${t('forms:selectOne')} ${t('forms:category')}`}
                name={name}
                value={value}
                isLoading={request.isLoading}
              >
                {filteredItems.isLoading && filteredItems.items.map(printOption)}
              </SelectAsync>
            )}
          />
        </Box>

        {/* Desactivar Button */}
        <Box d='flex' alignItems='flex-end'>
          <Button variantColor='blue' variant='outline' isLoading={formState.isSubmitting} type='submit'>
            {t('common:deactivate')}
          </Button>
        </Box>
      </Grid>
    </PseudoBox>
  )
}
