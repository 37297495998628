import { Box, Button, Grid, PseudoBox, Checkbox, Text, NumberInput } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelectAsync from '../../common/select-async'
import AlertDialogSimple from '../../../components/common/alert-dialog-simple'

export default function MenuForm({ onSubmit, ...restProps }) {
  const { register, handleSubmit, formState } = useForm()
  const { t } = useTranslation(['common', 'sections'])
  //// Hook Checkboxs
  const [urlChecked, setUrlChecked] = useState()
  const [activoChecked, setActivoChecked] = useState(true)
  ////Prop function OnSubmit
  function onSubmitt(data) {
    if (data.sectionSelection === data.CSprincipalSection) {
      setControlAlert((state) => ({
        ...state,
        show: true,
        data,
      }))
      setControl(true)
      return
    }
    let datos = {
      ...data,
      urlChecked,
      activoChecked,
      orden,
    }
    onSubmit(datos)
    setActivoChecked(true)
    setUrlChecked(undefined)
  }

  useEffect(() => {
    setOrden(0)
  }, [restProps.resetOrden])

  //// Hook control mismo option select
  async function onClose() {
    document.getElementById('menu-form').reset()
    onCloseControlAlert()
  }

  const [controlAlert, setControlAlert] = useState({
    show: false,
    data: null,
  })

  const onCloseControlAlert = () => setControlAlert(state => ({ ...state, show: false }))

  const [control, setControl] = useState(false)

  /// Hook Orden numerico
  const [orden, setOrden] = useState(0)
  const ordenChange = value => setOrden(value)

  useEffect(() => {
    //reset orden max
    if (orden > 99) {
      setOrden(99)
    }
  }, [orden])

  ///Hook value section
  const [disabledSP, setDisabledSP] = useState(true)

  function onChangeSID(e) {
    const selectedIndex = e.target.options.selectedIndex
    let key = e.target.options[selectedIndex].getAttribute('data-key')
    {
      key > 0 ? setDisabledSP(false) : setDisabledSP(true)
    }
  }

  return (
    <PseudoBox
      id='menu-form'
      as='form'
      onSubmit={handleSubmit(d => onSubmitt(d))}
      {...restProps}
      style={{ backgroundColor: 'white', borderRadius: 10 }}
    >
      {/* Alert Control select */}
      <AlertDialogSimple
        control={control}
        isOpen={controlAlert.show}
        onClose={onCloseControlAlert}
        onAccept={onClose}
        acceptLabel={t('common:accept')}
      >
        {t('common:controlSelect')} <br />
      </AlertDialogSimple>

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={4}>
        {/* Secciones custom + default */}
        <Box alignItems='center'>
          <SelectAsync
            isRequired
            placeholder={t('sections:chooseSection')}
            name='sectionSelection'
            onChange={e => {
              onChangeSID(e)
            }}
            nativeProps={{ ref: register }}
            isLoading={restProps.isLoadingCS && restProps.isLoadingDS}
          >
            {!restProps.isLoadingCS &&
              !restProps.isLoadingDS &&
              restProps.combined &&
              restProps.combined.map((type, key) => {
                return (
                  <option
                    key={key}
                    data-key={type.id}
                    value={JSON.stringify(
                      type.section.empresaId
                        ? {
                            esDefault: false,
                            seccionCustom: {
                              id: type.section.id,
                            },
                          }
                        : {
                            esDefault: true,
                            seccionDefault: {
                              id: type.section.id,
                            },
                          },
                    )}
                  >
                    {type.section.nombre ? type.section.nombre : type.section.descripcion}
                  </option>
                )
              })}
          </SelectAsync>
        </Box>

        {/* Orden */}
        <Box d='flex' alignItems='center'>
          <Text mr='3'>{t('common:order')}</Text>
          <NumberInput isRequired value={orden} onChange={ordenChange} defaultValue={0} min={0} max={99} />
        </Box>

        <Box d='flex' justifyContent='flex-start'>
          {/* Es Url */}
          <Box d='flex' justifyContent='flex-start' alignSelf='left'>
            {t('common:internal')}
            <Checkbox ml='0.5rem' isChecked={urlChecked} onChange={e => setUrlChecked(e.target.checked)} />
          </Box>

          {/* Activo */}
          <Box d='flex' ml='2rem' justifyContent='flex-start' alignSelf='left'>
            {t('common:active')}
            <Checkbox ml='0.5rem' isChecked={activoChecked} onChange={e => setActivoChecked(e.target.checked)} />
          </Box>
        </Box>
      </Grid>
      <Box>
        <Button
          //flex="2"
          mt='10'
          maxW={{ base: '100%', md: '120px' }}
          variantColor='blue'
          variant='outline'
          isLoading={formState.isSubmitting}
          type='submit'
        >
          {t('common:save')}
        </Button>
      </Box>
    </PseudoBox>
  )
}
