import React, { useState } from 'react'
import { Button, Box, SimpleGrid, Select, Input } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { PageContainer } from '../../common/helpers'

function EditHome() {
  const { t } = useTranslation(['common'])

  const [userType, setUserType] = useState('')
  const [buttonType, setButtonType] = useState({
    admin: 'outline',
    adminCountry: 'outline',
    directorAgency: 'outline',
    Agencia: 'outline',
    Counter: 'outline',
    adminSite: 'outline',
    provider: 'outline',
  })

  function editUserType(userType) {
    setUserType(userType)

    function changueButton(x) {
      let defaultType = {
        admin: 'outline',
        adminCountry: 'outline',
        directorAgency: 'outline',
        Agencia: 'outline',
        Counter: 'outline',
        adminSite: 'outline',
        provider: 'outline',
      }
      defaultType[userType] = 'solid'
      setButtonType(defaultType)
    }
    switch (true) {
      case userType === 'admin':
        return changueButton('admin')
      case userType === 'adminCountry':
        return changueButton('adminCountry')
      case userType === 'directorAgency':
        return changueButton('directorAgency')
      case userType === 'Agencia':
        return changueButton('Agencia')
      case userType === 'Counter':
        return changueButton('Counter')
      case userType === 'adminSite':
        return changueButton('adminSite')
      case userType === 'provider':
        return changueButton('provider')
    }
  }
  function onChangueOption() {
  }
  function test() {
    return (
      <Select onChange={() => onChangueOption()}>
        <option value="item 1">Item 1</option>
        <option value="item 2">Item 2</option>
        <option value="item 3">Item 3</option>
      </Select>
    )
  }
  return (
    <>
      <PageContainer>
        <Box color="#103059">
          <h1 style={{ fontSize: '2.5rem', padding: '0', marginTop: '0.5rem' }}>
            {t('common:editFavSections')} {userType && '|'} {t(`common:${userType}`)}
          </h1>
        </Box>
        <SimpleGrid
          className="dNone"
          columns={{ base: 1, md: 1 }}
          style={{ fontSize: '2.5rem', padding: '0rem 4rem', marginTop: '0.8rem' }}
          spacing={1}
        >
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Box>
              <Button
                onClick={() => {
                  editUserType('admin')
                }}
                style={{ width: '10rem' }}
                variantColor="blue"
                variant={buttonType.admin}
              >
                {t('common:admin')}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  editUserType('adminCountry')
                }}
                style={{ width: '10rem' }}
                variantColor="blue"
                variant={buttonType.adminCountry}
              >
                {t('common:adminCountry')}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  editUserType('directorAgency')
                }}
                style={{ width: '10rem' }}
                variantColor="blue"
                variant={buttonType.directorAgency}
              >
                {t('common:directorAgency')}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  editUserType('Agencia')
                }}
                style={{ width: '10rem' }}
                variantColor="blue"
                variant={buttonType.Agencia}
              >
                {t('common:agency')}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  editUserType('Counter')
                }}
                style={{ width: '10rem' }}
                variantColor="blue"
                variant={buttonType.Counter}
              >
                {t('common:counter')}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  editUserType('adminSite')
                }}
                style={{ width: '10rem' }}
                variantColor="blue"
                variant={buttonType.adminSite}
              >
                {t('common:adminSite')}
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => {
                  editUserType('provider')
                }}
                style={{ width: '10rem' }}
                variantColor="blue"
                variant={buttonType.provider}
              >
                {t('common:provider')}
              </Button>
            </Box>
          </div>
        </SimpleGrid>
        <SimpleGrid
          className="dNone"
          columns={{ base: 1, md: 1 }}
          style={{ fontSize: '2.5rem', padding: '0rem 4rem', marginTop: '0.8rem' }}
          spacing={1}
        >
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '3rem' }}>
            <div>
              <label style={{ fontSize: '2rem' }}>Position 1</label>
              {test(1)}
            </div>

            <div>
              <label style={{ fontSize: '2rem' }}>Position 2</label>
              {test(2)}
            </div>
            <div>
              <label style={{ fontSize: '2rem' }}>Position 3</label>
              {test(3)}
            </div>
            <div>
              <label style={{ fontSize: '2rem' }}>Position 4</label>
              {test(4)}
            </div>
            <div>
              <label style={{ fontSize: '2rem' }}>Position 5</label>
              {test(5)}
            </div>
          </div>
        </SimpleGrid>
        <div style={{ float: 'right', margin: '4% 5% 0%' }}>
          <Button style={{ height: '2.1rem', padding: '2' }} variantColor="blue">
            {t('common:save')}
          </Button>
        </div>

        <Box color="#103059">
          <h1 style={{ fontSize: '2.5rem', padding: '0', marginTop: '10rem' }}>{t('common:editVideoLink')}</h1>
        </Box>

        <Input
          style={{ margin: '0% 25%', width: '50%', marginTop: '2%' }}
          name="video Link"
          // value="asd"
          // onChange={(e)=>onChangeHotel(e, 1)}
          // px={1}
          // defaultValue={0}
        />
        <div style={{ float: 'right', margin: '4% 5% 0%' }}>
          <Button style={{ height: '2.1rem', padding: '2' }} variantColor="blue">
            {t('common:save')}
          </Button>
        </div>
      </PageContainer>
    </>
  )
}

export default EditHome
