import axios from 'axios'
import {
  configWithAuth,
  getAllSuppliersWithPackagesEndpoint,
  getAllAplicationTypesEndpoint,
  getAllPackagesByProviderEndpoint,
  getIconsPackageEndpoint,
  createPackageEndpoint,
  getPackagesByIdEndpoint,
  updatePackageEndpoint,
  getAllTransportsEndpoint,
  updatePackageEnableEndpoint,
  imageHotelEndpoint,
  getDeletePackages,
  getHotelImagesByIdEndpoint,
  configWithParams,
  agreementEndpoint,
  contactEndpoint,
  officeEndpoint,
  proveedoresEndpoint,
  hotelesEndpoint,
  getAllCategoriesEndpoint,
  deletePackageInformationEndpoint,
  packageInformationEndpoint,
  deletePriceInformationEndpoint,
  getPackagesByQueryEndpoint,
  getAllTripTypesEndpoint,
  getAllBaseTypesEndpoint,
  getAllTaxesEndpoint,
  getTaxes,
} from './index'

const {
  REACT_APP_INTRANET_API_V2,
  REACT_APP_APPLICATIONS_API_V2,
  REACT_APP_PACKAGES_REGIONAL_V3,
} = process.env

export const deletePackage = (id, authToken) =>
  axios.delete(`${REACT_APP_PACKAGES_REGIONAL_V3}${getDeletePackages(id)}`, configWithAuth(authToken))

export const getSuppliers = (data, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}`, configWithParams(data, authToken))

export const createSupplier = (data, authToken) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}`, data, configWithAuth(authToken))

export const deleteSupplier = (id, authToken) =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}/${id}`, configWithAuth(authToken))

export const updateSupplier = (supplierId, data, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}/${supplierId}`, data, configWithAuth(authToken))

export const getSupplierById = (supplierId, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}/${supplierId}`, configWithAuth(authToken))

export const createAgreementSupplier = (data, authToken) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}`, data, configWithAuth(authToken))

export const createContactSupplier = (data, authToken) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${contactEndpoint}`, data, configWithAuth(authToken))

export const createOfficeSupplier = (data, authToken) =>
  axios.post(`${REACT_APP_INTRANET_API_V2}${officeEndpoint}`, data, configWithAuth(authToken))

export const getAgreementsBySupplierId = (data, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}`, configWithParams(data, authToken))

export const getAgreementById = (id, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}/${id}`, configWithParams(authToken))

export const getContactsBySupplierId = (data, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${contactEndpoint}`, configWithParams(data, authToken))

export const getOfficesBySupplierId = (data, authToken) =>
  axios.get(`${REACT_APP_INTRANET_API_V2}${officeEndpoint}`, configWithParams(data, authToken))

  export const deleteOfficesBySupplierId = (data, id, authToken) =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${officeEndpoint}/${id}`, configWithParams(data, authToken))  

export const updateAgreementById = (data, id, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}/${id}`, data, configWithAuth(authToken))

export const deleteAgreementById = (id, authToken) =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${agreementEndpoint}/${id}`, configWithAuth(authToken))

export const updateContactById = (data, id, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${contactEndpoint}/${id}`, data, configWithAuth(authToken))

export const deleteContactById = (data, id, authToken) =>
  axios.delete(`${REACT_APP_INTRANET_API_V2}${contactEndpoint}/${id}`, data, configWithAuth(authToken))

export const updateOfficeById = (data, id, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${officeEndpoint}/${id}`, data, configWithAuth(authToken))

export const updateSupplierById = (data, id, authToken) =>
  axios.put(`${REACT_APP_INTRANET_API_V2}${proveedoresEndpoint}/${id}`, data, configWithAuth(authToken))

export const getAllAplicationTypes = token => {
  return axios.get(`${REACT_APP_APPLICATIONS_API_V2}${getAllAplicationTypesEndpoint}`, configWithAuth(token))
}

export const getAllSuppliers = (token, id) => {
  return axios.get(`${REACT_APP_APPLICATIONS_API_V2}${getAllAplicationTypesEndpoint}/${id}`, configWithAuth(token))
}

// PAQUETES

export const getAllSuppliersWithPackages = authToken => {
  return axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getAllSuppliersWithPackagesEndpoint}`, configWithAuth(authToken))
}

export const getAllPackagesByProvider = (authToken, aplicationId) => {
  return axios.get(
    `${REACT_APP_PACKAGES_REGIONAL_V3}${getAllPackagesByProviderEndpoint(aplicationId)}`,
    configWithAuth(authToken),
  )
}

export const getPackagesById = (authToken, packagesId) => {
  return axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getPackagesByIdEndpoint(packagesId)}`, configWithAuth(authToken))
}

export const getPackagesByQuery = (data) => {
  return axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getPackagesByQueryEndpoint}`, configWithParams(data))
}

export const getIconsPackage = authToken => {
  return axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getIconsPackageEndpoint}`, configWithAuth(authToken))
}

export const createPackage = (data, authToken) =>
  axios.post(`${REACT_APP_PACKAGES_REGIONAL_V3}${createPackageEndpoint}`, data, configWithAuth(authToken))

export const updatePackage = (id, data, authToken) =>
  axios.put(`${REACT_APP_PACKAGES_REGIONAL_V3}${updatePackageEndpoint(id)}`, data, configWithAuth(authToken))

export const getPackageInformationById = (data) => {
  return axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${packageInformationEndpoint}`, configWithParams(data))
}

export const createPackageInformation = (data, authToken) =>
  axios.post(`${REACT_APP_PACKAGES_REGIONAL_V3}${packageInformationEndpoint}`, data, configWithAuth(authToken))

export const deletePackageInformation = (id, authToken) =>
  axios.delete(`${REACT_APP_PACKAGES_REGIONAL_V3}${deletePackageInformationEndpoint(id)}`, configWithAuth(authToken))

export const updatePackageInformation = (id, data, authToken) =>
  axios.put(`${REACT_APP_PACKAGES_REGIONAL_V3}${deletePackageInformationEndpoint(id)}`, data, configWithAuth(authToken))

export const getAllTransports = authToken =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getAllTransportsEndpoint()}`, configWithAuth(authToken))

export const getAllBaseTypes = authToken =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getAllBaseTypesEndpoint()}`, configWithAuth(authToken))

export const getAllTaxes = (data, authToken) =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getAllTaxesEndpoint()}`, configWithParams(data, authToken))

export const getAllTripTypes = authToken =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getAllTripTypesEndpoint()}`, configWithAuth(authToken))

export const updatePackageEnable = (id, data, authToken) =>
  axios.put(`${REACT_APP_PACKAGES_REGIONAL_V3}${updatePackageEnableEndpoint(id)}`, data, configWithAuth(authToken))

export const getHotels = (data, authToken) =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${hotelesEndpoint}`, configWithParams(data, authToken))

export const getHotel = (id, authToken) =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${hotelesEndpoint}/${id}`, configWithAuth(authToken))

export const getHotelNames = (data, authToken) =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${hotelesEndpoint}/hotelname`, configWithParams(data, authToken))

export const createHotel = (data, authToken) =>
  axios.post(`${REACT_APP_PACKAGES_REGIONAL_V3}${hotelesEndpoint}`, data, configWithAuth(authToken))

export const updateHotel = (id, data, authToken) =>
  axios.put(`${REACT_APP_PACKAGES_REGIONAL_V3}${hotelesEndpoint}/${id}`, data, configWithAuth(authToken))

export const deleteHotel = (id, authToken) =>
  axios.delete(`${REACT_APP_PACKAGES_REGIONAL_V3}${hotelesEndpoint}/${id}`, configWithAuth(authToken))

export const imageHotel = (data, authToken) =>
  axios.post(`${REACT_APP_PACKAGES_REGIONAL_V3}${imageHotelEndpoint}`, data, configWithAuth(authToken))

export const getImageHotel = (id, authToken) =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getHotelImagesByIdEndpoint(id)}`, configWithAuth(authToken))

export const getAllCategories = (authToken) =>
  axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getAllCategoriesEndpoint}`, configWithAuth(authToken))

export const deletePriceInformation = (id, authToken) =>
  axios.delete(`${REACT_APP_PACKAGES_REGIONAL_V3}${deletePriceInformationEndpoint(id)}`, configWithAuth(authToken))

export const getTaxesPaginated = (data) => {
  return axios.get(`${REACT_APP_PACKAGES_REGIONAL_V3}${getTaxes}`, configWithParams(data))
}

export const createTaxes = (data, authToken) =>
  axios.post(`${REACT_APP_PACKAGES_REGIONAL_V3}${getTaxes}`, data, configWithAuth(authToken))

export const deleteTaxes = (id, authToken) =>
  axios.delete(`${REACT_APP_PACKAGES_REGIONAL_V3}${getTaxes}/${id}`, configWithAuth(authToken))  
