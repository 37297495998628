import React, { useEffect, useState } from 'react'
import FormInput from '../form-input'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Button, PseudoBox, SimpleGrid, Box, FormLabel } from '@chakra-ui/core'

import SelectAsync from '../../common/select-async'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../redux/selectors'
import { getUnitsMeasurement } from '../../../api/incentives'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'

function SupplierIncentivesFormEdit({ formData = false, buttonLabel = false, onclickUpdate }) {
  // - - - ⭕ GENERALS - - -

  const { t } = useTranslation(['incentives', 'forms', 'exchange', 'comunications'])
  const authToken = useSelector(getAuthToken)
  const [mesure, setMesure] = useState(false)

  // - - - 📥 HOOKS 📥- - -

  const { register, handleSubmit, control, formState, reset } = useForm({
    defaultValues: formData,
  })

  // - - -📆 COMBO DATA PICKER 📆- - -

  const [{ startDate, endDate }, setRangeDate] = useState({
    startDate: moment(formData.fechaInicio).utc(),
    endDate: moment(formData.fechaFin).utc()
  })

  const [focusedInput, setFocusedInput] = useState()
  function setFocusedPicker(focusedInput) {
    setFocusedInput(focusedInput)
  }

  function setRangePicker(startDate, endDate) {
    setRangeDate(state => ({
      startDate,
      endDate,
    }))
  }

  // - - - -📞 API CALLS 📞- - - -

  async function getMeasurement() {
    try {
      const response = await getUnitsMeasurement(authToken)
      let mes = response.data
      setMesure(mes)
    } catch (error) {
      console.log(error)
    }
  }

  // - - - -👨‍👧 INFO SEND - PADRE 👨‍👧- - - -

  function onSubmit(date) {
    let start = moment(startDate).format()
    let end = moment(endDate).format()
    onclickUpdate(date, start, end)
  }

  // - - - - 🏁 USE EFFECTS 🏁 -  - - -

  useEffect(() => {
    getMeasurement()
  }, [])

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({})
    }
  }, [reset, formState])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
        {/* Nombre */}
        <FormInput
          isRequired
          label={t('forms:name')}
          name='nombre'
          inputProps={{
            placeholder: t('forms:name'),
            ref: register,
          }}
        />

        {/* Rango de Fecha */}

        <Box alignItems='center'>
          <FormLabel isRequired>{t('forms:validity')}</FormLabel>
          <DateRangePicker
            showDefaultInputIcon={true}
            isOutsideRange={() => false}
            startDate={startDate}
            startDateId='fechaInicio'
            endDate={endDate}
            endDateId='fechaFin'
            focusedInput={focusedInput}
            onDatesChange={({ startDate, endDate }) =>
              setRangePicker(
                startDate,
                endDate,
              )
            }
            onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
            small
            orientation='horizontal'
            displayFormat='DD/MM/YYYY'
          />
        </Box>

        <Box alignItems='center'>
          {/* Medicion */}
          <FormLabel isRequired>{t('forms:measurementMethod')}</FormLabel>

          <Controller
            control={control}
            name='unidadMedicion.id'
            render={({ onChange, value, name }) => (
              <SelectAsync
                name={name}
                onChange={e => onChange(e.target.value)}
                placeholder={`${t('exchange:currency')}`}
                value={value}
              >
                {mesure !== false
                  ? mesure.map(x => (
                      <option key={x.id} value={x.id}>
                        {x.descripcion} {x.simbolo}
                      </option>
                    ))
                  : null}
              </SelectAsync>
            )}
          />
        </Box>
        <Button style={{ marginTop: '27px', width: '75%' }} variantColor='blue' variant='outline' type='submit'>
          <div style={{ paddingTop: '1%' }}>{t('forms:save')}</div>
        </Button>
      </SimpleGrid>
    </PseudoBox>
  )
}

export default SupplierIncentivesFormEdit
