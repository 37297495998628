import axios from "axios";
import { getStorageToken } from '../helpers/utils'

// Headers

export const configWithToken = (authToken) => {
  const tokenAux = getStorageToken()
  return ({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    ...(tokenAux ? { Authorization: `Bearer ${tokenAux}` } : {}),
  },
})}

// Endpoints

export const getBookingsBySupplierEndpoint = (idAplicacion) =>  `/api/Reservas/obtener-reservas-por-proveedor?idAplicacion=${idAplicacion}`

// Requests GET

export const getBookingsBySupplier = (idAplicacion,authToken) =>
    axios.get(`https://paquetes-dev.travelit.com.ar${getBookingsBySupplierEndpoint(idAplicacion)}`, configWithToken(authToken))

