import { Box, Button, Grid, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAgreementsRanking, getCategoriesOfSuppliers, getProducts } from '../../../api/intranet'
import SelectAsync from '../../common/select-async'
import FormInput from '../../forms/form-input'
import { getAuthToken, getUserApplication, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import CreatableSelect from 'react-select/creatable'
import { getAllCountries } from '../../../api/application'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues } from '.'
import { toastCustomizedError } from '../../common/toast-default-config'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

export default function AgreementsSearchForm({ onFilter, ...restProps }) {
  const { filters, sectionData, handleFilters, handleResetFilters, handleSectionData } = useSectionData()
  const { t } = useTranslation(['common', 'forms', 'suppliers', 'comunications'])
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const toast = useToast()
  const location = useLocation()
  const { paisId: countryId } = useSelector(getUserApplication)
  const [categories, setCategories] = useState({
    data: [],
    isLoading: false,
  })
  const [countries, setCountries] = useState({
    data: [],
    isLoading: false,
  })

  const [newProductValue, setNewProductValue] = useState()

  const getSuppliersCategories = async id => {
    setCategories(({ data }) => ({
      data,
      isLoading: true,
    }))
    try {
      const response = await getCategoriesOfSuppliers(id)
      setCategories(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCategories(() => ({
        data: [],
        isLoading: false,
        error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getCountries = async () => {
    setCountries(({ data }) => ({
      data,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries(authToken)
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(({ data }) => ({
        data,
        isLoading: false,
        error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const handleProductoInputChange = async newValue => {
    if (newValue.length > 2 && filters.paisId) {
      try {
        const { paisId, categoria } = filters
        const response = await getProducts({
          paisId: permisosUser ? paisId : countryId,
          categoria,
          producto: newValue,
        })
        handleSectionData(
          SECTION_DATA_KEYS.productos,
          response.data.map(product => ({ label: product, value: product })),
        )
      } catch (error) {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } else {
      if (!!sectionData.productos.length) {
        handleSectionData(SECTION_DATA_KEYS.productos, defaultSectionDataValues.productos)
      }
    }
  }

  const resetAcuerdos = () => handleSectionData(SECTION_DATA_KEYS.acuerdos, defaultSectionDataValues.acuerdos)

  const handleProductoChange = async () => {
    if (filters.producto && filters.paisId) {
      try {
        const { paisId, categoria, producto } = filters
        const response = await getAgreementsRanking({
          paisId: permisosUser ? paisId : countryId,
          categoria,
          producto,
        })
        handleSectionData(SECTION_DATA_KEYS.acuerdos, response.data)
      } catch (error) {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } else {
      if (!!sectionData.productos.length) {
        resetAcuerdos()
      }
    }
  }

  const handleNameInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      onFilter();
    }
  }

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!permisosUser) {
      handleFilters(FILTER_KEYS.paisId, countryId)
    }
  }, [countries])

  useEffect(() => {
    if (!!filters.paisId) {
      getSuppliersCategories(filters.paisId || userCountryId)
    } else {
      setCategories(state => ({
        ...state,
        data: [],
      }))
      handleFilters(FILTER_KEYS.categoria, undefined)
    }
  }, [filters?.paisId])

  useEffect(() => {
    if (newProductValue) {
      handleProductoInputChange(newProductValue)
    }
  }, [newProductValue])

  useEffect(() => {
    if (filters.producto) {
      handleProductoInputChange(filters.producto)
      handleProductoChange()
    } else {
      resetAcuerdos()
    }
  }, [filters?.producto])

  return (
    <Grid
      templateColumns={{ base: 'repeat(1, 1fr)', md: `${permisosUser ? 'repeat(5, 1fr)' : 'repeat(4, 1fr)'}` }}
      alignItems='flex-end'
      gap={6}
      {...restProps}
    >
      {permisosUser && (
        <Box>
          <SelectAsync
            onChange={({ target }) => {
              handleFilters(FILTER_KEYS.categoria, undefined)
              handleFilters(FILTER_KEYS.paisId, target.value)
            }}
            placeholder={`${t('forms:countries')} - ${t('forms:all')}`}
            value={filters.paisId}
            isLoading={countries.isLoading}
          >
            {countries.data
              .sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {nombre}
                </option>
              ))}
          </SelectAsync>
        </Box>
      )}
      <Box>
        <SelectAsync
          onChange={({ target }) => handleFilters(FILTER_KEYS.categoria, target.value)}
          placeholder={`${t('forms:category')} - ${t('forms:all')}`}
          value={filters?.categoria}
          isLoading={categories.isLoading}
          isDisabled={!filters?.paisId}
        >
          {categories.data.map(({ id, nombre }) => (
            <option key={id} value={id}>
              {t(`suppliers:${nombre}`)}
            </option>
          ))}
        </SelectAsync>
      </Box>
      <FormInput
        name='nombre'
        onChange={({ target }) => handleFilters(FILTER_KEYS.nombre, target.value)}
        onKeyDown={handleNameInputKeyDown}
        value={filters?.nombre}
        inputProps={{
          placeholder: `${t('forms:name')} (${t('common:optional')})`,
        }}
      />
      <CreatableSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            marginTop: '0.25rem',
            height: '2.5rem',
            borderRadius: '0.25rem',
            border: '1px solid',
            color: '#E2E8F0',
            backgroundColor: '#fff',
            appearance: 'none',
            fontSize: '1rem',
            outline: 'none',
          }),
        }}
        id='producto'
        value={filters?.producto ? { label: filters?.producto, value: filters?.producto } : null}
        onChange={selectedOption => {
          !selectedOption
          ? handleFilters(FILTER_KEYS.producto, '')
          : handleFilters(FILTER_KEYS.producto, selectedOption.value)
        }}
        onInputChange={value => setNewProductValue(value)}
        isClearable
        isDisabled={!filters?.paisId}
        placeholder={`${t('forms:product')} (${t('common:optional')})`}
        options={sectionData?.productos}
        allowCreateWhileLoading={false}
        noOptionsMessage={() => null}
        isValidNewOption={() => false}
      />
      <Box>
        <Button
          mr={4}
          flex='1'
          maxW={{ base: '100%', md: '120px' }}
          variantColor='blue'
          variant='outline'
          onClick={onFilter}
        >
          {t('common:filter')}
        </Button>
        <Button flex='2' variant='outline' variantColor='blue' type='button' onClick={handleResetFilters}>
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Grid>
  )
}
