import axios from 'axios'
import { configWithAuth, configWithParams } from '..'

const { REACT_APP_MARCABLANCA_API_V2 } = process.env

//Endpoint
export const getCategoriesBannerEndpoint = `/api/categoria-banners/all`
export const getAllCategoriesEndpoint = (appId, countryId) =>
  `/api/categoria-banners?buscar=0&paisId=${countryId}&empresaId=${appId}`
export const getAllCategoriesEndpointIsDefault = () => '/api/categoria-banners'
export const createCategoryEndpoint = () => '/api/categoria-banners/'
export const updateCategoryIdEndpoint = categoryId => `/api/categoria-banners/${categoryId}`
export const deleteCategoryEndpoint = categoryId => `/api/categoria-banners/${categoryId}`

export const getAllBannersEndpoint = `/api/empresa-banner`
export const getBannersEndpoint = id => `/api/empresa-banner?empresaId=${id}`
export const getBannersbyCategoryEndpoint = categoriaId => `/api/empresa-banner/category/${categoriaId}`
export const createBannerEndpoint = () => '/api/empresa-banner/'
export const updateBannerEndpoint = id => `/api/empresa-banner/${id}`
export const deleteBannerEndpoint = id => `/api/empresa-banner/${id}`

export const getIgnoreCategoriesEndpoint = `/api/empresa-categoria-ignorada/all`
export const getAllIgnoreCategoriesEndpoint = `/api/empresa-categoria-ignorada`
export const createIgnoreCategoryEndpoint = () => '/api/empresa-categoria-ignorada/'
export const deleteIgnoreCategoryEndpoint = id => `/api/empresa-categoria-ignorada/${id}`

export const getDinamicOfferEndpoint = 'https://api.offers.travelit.com.ar/api/package-offers/structure'

export const getUbicationEndpoint = () => '/api/ubicaciones'
export const setUbicationIdEndpoint = id => `/api/ubicaciones${id ? `/${id}` : ''}`
export const setCategoriaUbicacionSeccionIdEndpoint = id => `/api/categoria-ubicacion-seccion${id ? `/${id}` : ''}`
export const getUbicationByIdEndpoint = (id, isDefault) => `/api/categoria-ubicacion-seccion/?categoriaBannerId=${id}`
export const deleteUbicationEndpoint = id => `/api/categoria-ubicacion-seccion/${id}`
export const createUbicationEndpoint = '/api/categoria-ubicacion-seccion/'

//Request
export const getAllCategories = data =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getCategoriesBannerEndpoint}`, configWithParams(data))

export const getCategories = (authToken, appId, countryId) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllCategoriesEndpoint(appId, countryId)}`, configWithAuth(authToken))

export const getCategoriesIsDefault = (data, authToken) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllCategoriesEndpointIsDefault()}`, configWithParams(data, authToken))

export const createCategory = (data, authToken) => {
  return axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createCategoryEndpoint()}`, data, configWithAuth(authToken))
}

export const updateCategoryById = (categoryId, data, authToken) =>
  axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateCategoryIdEndpoint(categoryId)}`, data, configWithAuth(authToken))

export const deleteCategory = (categoryId, authToken) => {
  return axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteCategoryEndpoint(categoryId)}`, configWithAuth(authToken))
}

export const getIgnoreCategories = data =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getIgnoreCategoriesEndpoint}`, configWithParams(data))

export const getAllIgnoreCategories = data =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllIgnoreCategoriesEndpoint}`, configWithParams(data))

export const getAllBanners = data =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getAllBannersEndpoint}`, configWithParams(data))

export const getBannersByCategory = categoriaId =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getBannersbyCategoryEndpoint(categoriaId)}`, configWithAuth())

export const getBanners = (authToken, id) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getBannersEndpoint(id)}`, configWithAuth(authToken))

export const createBanner = (data, authToken) => {
  return axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createBannerEndpoint()}`, data, configWithAuth(authToken))
}

export const updateBannerById = (bannerId, data, authToken) => {
  return axios.put(`${REACT_APP_MARCABLANCA_API_V2}${updateBannerEndpoint(bannerId)}`, data, configWithAuth(authToken))
}

export const deleteBanner = (id, authToken) => {
  return axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteBannerEndpoint(id)}`, configWithAuth(authToken))
}

export const createIgnoreCategory = (data, authToken) => {
  return axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createIgnoreCategoryEndpoint()}`, data, configWithAuth(authToken))
}

export const deleteIgnoreCategory = (id, authToken) => {
  return axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteIgnoreCategoryEndpoint(id)}`, configWithAuth(authToken))
}

export const getDinamicOffers = authToken => axios.get(getDinamicOfferEndpoint, configWithAuth(authToken))

export const getUbication = (authToken, id) =>
  axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getUbicationEndpoint(id)}`, configWithAuth(authToken))

export const getUbicationById = (authToken, id, isDefault) => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${getUbicationByIdEndpoint(id)}`, configWithAuth(authToken))
}

export const deleteUbication = (id, authToken) => {
  return axios.delete(`${REACT_APP_MARCABLANCA_API_V2}${deleteUbicationEndpoint(id)}`, configWithAuth(authToken))
}

export const getUbicationSeccion = data => {
  return axios.get(`${REACT_APP_MARCABLANCA_API_V2}${createUbicationEndpoint}`, configWithParams(data))
}

export const createUbication = (data, authToken) => {
  return axios.post(`${REACT_APP_MARCABLANCA_API_V2}${createUbicationEndpoint}`, data, configWithAuth(authToken))
}

export const patchCategoriaUbicacionSeccionShowState = ({ id, ...data }, authToken) => {
  return axios.put(
    `${REACT_APP_MARCABLANCA_API_V2}${setCategoriaUbicacionSeccionIdEndpoint(id)}`,
    data,
    configWithAuth(authToken),
  )
}
