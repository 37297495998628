import React from 'react'
import { Box } from '@chakra-ui/core'
import './../../styles/home/home.css'
import '../../styles/common/highlight-section-btn.css'
import { PageContainer } from '../../common/helpers'
import { useHistory } from 'react-router-dom'
import Acuerdos from '../../../static/img/acuerdos.svg'
import GeaAcademy from '../../../static/img/gea_academy.svg'
import Dollar from '../../../static/img/logo-dollar.svg'
import Incentivos from '../../../static/img/incentivos.svg'
import Travel from '../../../static/img/logo-travel2.svg'
import Plane from '../../../static/img/logo-plane.svg'
import HighlightSectionBtn from '../../common/highlight-section-btn'
import {
  SUPPLIERS_AGREEMENTS_VIEW,
  COMUNICATIONS_GEA_ACADEMY,
  INCENTIVES,
  COMUNICATIONS_MKT,
} from '../../../helpers/permissions'
import Can from '../../../helpers/verify-permission'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getUserCountryId, getUserRolId } from '../../../redux/selectors'

const FavSections = () => {
  let history = useHistory()
  const { t } = useTranslation(['common', 'applications', 'suppliers', 'exchange'])
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false
  const userCountryId = useSelector(getUserCountryId)

  //Ir a leer la noticias
  const openNewsWindow = x => {
    history.push(`${x}`)
  }

  const buttonsList = [
    {
      onClick: () => openNewsWindow('communications?geaHome'),
      imgSrc: GeaAcademy,
      label: t('common:navbar.geaHome'),
      permission: COMUNICATIONS_GEA_ACADEMY,
    },
    {
      onClick: () => openNewsWindow('suppliers-login?name=travelgea'),
      imgSrc: Travel,
      label: t('common:navbar.travelgea'),
      permission: COMUNICATIONS_MKT,
    },
    {
      onClick: () => openNewsWindow('incentives'),
      imgSrc: Incentivos,
      label: t('suppliers:incentives'),
      permission: INCENTIVES,
    },
    {
      onClick: () => openNewsWindow('suppliers-agreements'),
      imgSrc: Acuerdos,
      label: t('suppliers:agreements'),
      permission: SUPPLIERS_AGREEMENTS_VIEW,
    },
    {
      onClick: () => openNewsWindow('suppliers-login?name=lleego'),
      imgSrc: Plane,
      label: 'Lleego',
    },
  ]

  return (
    <>
      <Box
        className='seccionesDestacadasHome'
      >
        <PageContainer>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              flexWrap: 'no-wrap',
              // margin: '1rem 2rem',
            }}
          >
            {!permisosUser && userCountryId !== 2 && (
              <HighlightSectionBtn
                key={t('exchange:changeType')}
                onClick={() => openNewsWindow('/exchange-type')}
                imgSrc={Dollar}
                label={t('exchange:changeType')}
              />
            )}
            {buttonsList.map(({ onClick, imgSrc, label, permission }) =>
              permission ? (
                <Can
                  key={label}
                  permission={permission}
                  yes={() => <HighlightSectionBtn key={label} onClick={onClick} imgSrc={imgSrc} label={label} />}
                />
              ) : (
                <HighlightSectionBtn key={label} onClick={onClick} imgSrc={imgSrc} label={label} />
              ),
            )}
          </Box>
        </PageContainer>
      </Box>
    </>
  )
}

export default FavSections
