export default {
	appState: {
		user: null
	},
	suppliersState: {
		agreements: { isLoading: true, error: false, items: null },
		contacts: { isLoading: true, error: false, items: null },
		offices: { isLoading: true, error: false, items: null },
	},
	bannersState: {
		categories: { isLoading: true, error: false, items: null },
		allCategories: { isLoading: true, error: false, items: null },
		banners: { isLoading: true, error: false, items: null },
		allBanners: { isLoading: true, error: false, items: null },
		ignoreCategories: { isLoading: true, error: false, items: null },
		ubications: { isLoading: true, error: false, items: null }
	},
	packageState: {
		packages: { isLoading: true, error: false, items: null }
	}
};