import React, { useState } from 'react'
import { Grid, Tag } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../../../common/helpers'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const PackageDescription = ({
  onChangeDescription,
  state = false,
  ...restProps
}) => {
  const { t } = useTranslation(['common', 'forms', 'packages'])

  const [packageDescription, setPackageDescription] = useState(state.paqueteDescripciones[0].description)

  const toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  function handleQuillChange(change) {
    setPackageDescription(change)
    onChangeDescription({ 
      target: {
        name: 'description',
        value: change
      }
    })
  }

  return (
    <Panel mb='8'>
      <Tag width="100%" mb="3" p="3" bg="blue.500" color="white">
        {t('packages:descriptions')}
      </Tag>
      {
      <Grid style={{ height: '500px', display: 'flex', flexDirection: 'column' }}>
        <ReactQuill
          style={{ display: 'flex', flexDirection: 'column', height: '450px' }}
          modules={{ toolbar }}
          value={packageDescription}
          onChange={(e) => handleQuillChange(e)}
        />
      </Grid>
      }
    </Panel>
  )
}
