import React from 'react'
import UserForm from '../../../forms/application/user'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import { createUser } from '../../../../api/application'
import { getToken } from '../../../../redux/selectors'
import { useTranslation } from 'react-i18next'
import { isSuccess } from '../../../../helpers/utils'

export default function CreateUser({ appId, onFinish, ...rest }) {
  const token = useSelector(getToken)
  const toast = useToast()
  const { t } = useTranslation(['forms'])

  const handleSubmit = async data => {
    try {
      data.rol = { id: parseInt(data.rol.id) }
      const response = await createUser(
        {
          ...data,
          id: 0,
          aplicacion: { id: parseInt(appId) },
        },
        token,
      )
      if(isSuccess(response.status)) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')) )
        onFinish()
      }
    } catch (error) {
      if (error.response) {
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  return <UserForm onSubmit={handleSubmit} {...rest} />
}
