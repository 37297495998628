export const currencyOptions = [
  { value: 'ARG', name: 'ARG' },
  { value: 'EUR', name: 'EUR' },
  { value: 'USD', name: 'USD' },
]

export const destinationOptions = [
  { value: 'AFRICA', name: 'AFRICA' },
  { value: 'AMERICA CENTRAL', name: 'AMERICA CENTRAL' },
  { value: 'AMERICA DEL NORTE', name: 'AMERICA DEL NORTE' },
  { value: 'AMERICA DEL SUR', name: 'AMERICA DEL SUR' },
  { value: 'ARGENTINA', name: 'ARGENTINA' },
  { value: 'ASIA', name: 'ASIA' },
  { value: 'EUROPA', name: 'EUROPA' },
  { value: 'EUROPA ESPECIAL', name: 'EUROPA ESPECIAL' },
]
