import React from "react";
import { Box, FormLabel, Input } from "@chakra-ui/core";

export default function FormInput({
	name,
	isRequired = false,
	label = null,
	inputProps = {},
	value,
	maxLength,
	isDisabled = false,
	...rest
}) {
	return (
		<Box {...rest}>
			{label && (
				<FormLabel isRequired={isRequired} htmlFor={name}>{label}</FormLabel>
			)}
			<Input
				style={{
					paddingRight: "0.1rem",
				}}
				isDisabled={isDisabled}
				isRequired={isRequired}
				name={name}
				value={value}
				maxLength={maxLength}
				{...inputProps}
			/>
		</Box>
	);
}