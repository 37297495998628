import React from 'react'

import { SectionDataProvider } from '../../../context/SectionDataContext'
import SuppliersSearch from './supplier-search-contenet'

const FILTER_KEYS = {
  paisId: 'paisId',
  categoria: 'categoria',
  nombre: 'nombre',
  page: 'page',
  maxPerPage: 'maxPerPage',
}

const SECTION_DATA_KEYS = {
  suppliers: 'suppliers',
  categorias: 'categorias',
}

const defaultSectionDataValues = {
  categorias: [],
  suppliers: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFiltersValues = {
  page: 1,
  maxPerPage: 12,
  paisId: '',
  nombre: '',
  categoria: '',
}

const Suppliers = () => (
  <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
    <SuppliersSearch />
  </SectionDataProvider>
)

export { Suppliers, FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues, defaultFiltersValues }
