import React from "react";
import NavBar from "./navbar";
import { Box } from "@chakra-ui/core";
import { useLocation } from 'react-router-dom'
import Footer from './footer'

export default function Layout({ children, ...restProps }) {
	const { pathname } = useLocation()
	return (
		<Box {...restProps}>
			<NavBar />
			{children}
			{pathname === '/' ? <Footer /> : <div className="page-end-spacing" />}
		</Box>
	);
}