import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

const isDev = process.env.REACT_APP_ENV === "development";

const backendOpts = {
	loadPath: "/locales/{{lng}}/{{ns}}.json"
};

i18n
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: false,
		backend: backendOpts,
		browserLanguageDetection: true,
		fallbackLng: isDev ? ["dev"] : [],
		detection: {
			order: ["navigator", "querystring", "cookie", "localStorage", "sessionStorage", "htmlTag", "path", "subdomain"],
		}
	});

export default i18n;