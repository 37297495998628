import React from 'react'
import { useToast } from '@chakra-ui/core'
import { Switch } from 'antd'
import { toastCustomizedError, toastCustomizedSuccess } from './toast-default-config'
import { useCallback, useEffect, useState } from 'react'
import { getAuthToken, getToken } from '../../redux/selectors'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { isSuccess } from '../../helpers/utils'

const RemoteSwitch = ({ initialState, propertyKey, data, apiCall, onSuccess, onError, ...switchProps }) => {
  const { t } = useTranslation(['common'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(!!initialState)
  const toggleState = useCallback(async () => {
    try {
      const response = await apiCall({ ...data, [propertyKey]: !checked }, authToken)
      if (isSuccess(response.status)) {
        if (onSuccess) {
          onSuccess(response)
        } else {
          const msgTitle = t('common:operation_succed')
          toast(toastCustomizedSuccess(msgTitle))
        }
        setChecked(state => !state)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
      setLoading(false)
      if (onError) {
        onError(error)
      } else {
        const msgTitle = t('common:canceled')
        const msgDescription = t('common:generic_error')
        toast(toastCustomizedError(msgTitle, msgDescription))
      }
    }
  }, [checked])

  const toggleTrigger = () => {
    setLoading(true)
  }

  useEffect(() => {
    if (!!loading) {
      toggleState()
    }
  }, [loading])

  return <Switch loading={loading} checked={checked} onClick={toggleTrigger} {...switchProps} />
}

export default RemoteSwitch
