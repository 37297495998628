import React, { useMemo, useState, useEffect } from 'react'
import { Box, Button, Text } from '@chakra-ui/core'
import { Flex, Spacer } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import Table from '../../common/table'
import moment from 'moment'
import { getPackageById } from '../../../api/intranet/marca-blanca'

function DetailsTable(props) {
  const { t } = useTranslation(['consultations', 'comunications'])
  const [paquete, setPaquete] = useState()
  let consulta
  const contacto = [
    {
      nombre: props.general.nombre,
      apellido: props.general.apellido,
      email: props.general.email,
      telefono: props.general.telefono,
    },
  ]
  const getPaquete = async (id) =>{
    if(props.general.tipoConsulta === 'paquete'){
      try {
        const response = await getPackageById(id)
        const destinations = response.data.packageDestinations
        .map(dest => dest.destinationCity.nombreCiudad)
        .join(', ')
        setPaquete([{
          id: props.general.numeroPaquete,
          title: response.data.title,
          origin: response.data.packageOrigins[0].originExporta.nombreCiudad,
          destination: destinations,
          link: props.general.link,
          provider: props.general.provider
        }])
      } catch (error) {
        console.log(error)
      }
    }
  }
if(props.general.banner){
   consulta = [
    {
      id: props.general.banner.id,
      titulo: props.general.banner.titulo,
      descripcion: props.general.banner.descripcion,
      link: props.general.link,
      banner: props.general.banner
    },
  ]
}else if(props.general.tipoConsulta === 'arrepentimiento'){
  consulta = [
    {
      purchaseNumber: props.general.purchaseNumber,
      purchaseDate: moment(props.general.purchaseDate).format('DD/MM/YYYY')
    },
  ]
}else{
  consulta = [
    {
      id: '',
      titulo: '',
      precio: '',
      descripcion: '',
    },
  ]
}

  const columnsContacto = useMemo(
    () => [
            {
        Header: `${t('consultations:name')}`,
        accessor: 'nombre',
      },
      {
        Header: `${t('consultations:lastName')}`,
        accessor: 'apellido',
      },
      {
        Header: t('consultations:email'),
        accessor: 'email',
      },
      {
        Header: t('consultations:phone'),
        accessor: 'telefono',
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  const columnsConsulta = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('comunications:title')}`,
        accessor: 'titulo',
      }, 
      {
        Header: `${t('comunications:description')}`,
        accessor: 'descripcion',
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  const truncateString = (str) => {
    return str.length > 50 ? str.substring(0, 47) + "..." : str
  }
  const columnsBanners = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        Cell: ({ cell }) => (
          <p>
          {cell.row.original.banner.id}
        </p>
        ),
      },
      {
        Header: `${t('comunications:title')}`,
        accessor: 'titulo',
        Cell: ({ cell }) => (
          <p title={cell.row.original.banner.titulo || ''}>
          {truncateString(cell.row.original.banner.titulo)}
        </p>
        ),
      }, 
      {
        Header: `${t('comunications:description')}`,
        accessor: 'descripcion',
        Cell: ({ cell }) => (
          <p title={cell.row.original.banner.descripcion || ''}>
            {truncateString(cell.row.original.banner.descripcion || '')}
          </p>
        ),
      },
      {
        Header: `${t('comunications:provider')}`,
        accessor: 'proveedor',
        Cell: ({ cell }) => (
          <p title={cell.row.original.banner.proveedor || ''}>
            {truncateString(cell.row.original.banner.proveedor || '')}
          </p>
        ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  const columnsArrepentimiento = useMemo(
    () => [
      {
        Header: t('consultations:purchaseNumber'),
        accessor: 'purchaseNumber',
      },
      {
        Header: t('consultations:purchaseDate'),
        accessor: 'purchaseDate',
      }, 
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  const columnsPaquete = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('comunications:title')}`,
        accessor: 'title',
        Cell: ({ value }) => (
          <div>
            <p title={value}>
            {value.length > 40 ? `${value.substring(0, 40)}...` : value}
            </p>
          </div>
        ),
      }, 
      {
        Header: t('forms:origin'),
        accessor: 'origin',
      }, 
      {
        Header: t('forms:destino'),
        accessor: 'destination',
      },
      {
        Header: t('forms:provider'),
        accessor: 'provider',
      },
      {
        Header: `${t('forms:link')}`,
        accessor: 'link',
        Cell: ({ cell }) => {
          const handleLinkClick = () => {
            window.open(cell.row.original.link, '_blank', 'noopener,noreferrer')
          }

          return (
            <Box
              as='button'
              onClick={handleLinkClick}
              style={{
                color: '#3182ce',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              <p>Click aqui</p>
            </Box>
          )
        },
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  useEffect(() => {
      getPaquete(props.general.numeroPaquete)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Box mb='4'>
        <Text fontSize='lg' marginLeft='3'>
          {t('consultations:contactDetails')}
        </Text>
        <Table columns={columnsContacto} data={contacto} />
      </Box>

      {!props.general.esMapa && props.general.tipoConsulta === 'banners' && (
        <Box mb='4'>
          <Text fontSize='lg' marginLeft='3'>
            {t('consultations:dataBanner')}
          </Text>
          <Table columns={columnsBanners} data={consulta} />
        </Box>
      )}

      {props.general.tipoConsulta === 'arrepentimiento' && (
        <Box mb='4'>
          <Text fontSize='lg' marginLeft='3'>
            {t('consultations:claimData')}
          </Text>
          <Table columns={columnsArrepentimiento} data={consulta} />
        </Box>
      )}

      {props.general.tipoConsulta === 'paquete' && paquete && (
        <Box mb='4'>
          <Text fontSize='lg' marginLeft='3'>
            {t('consultations:dataPackage')}
          </Text>
          <Table columns={columnsPaquete} data={paquete} />
        </Box>
      )}

      <Box mb='4'>
        <Text fontSize='lg' marginLeft='3'>
          {t('consultations:comments')}
        </Text>
        <div className='css-1ltsskn'>
          <Text fontSize='sm' padding='3'>
            {props.general.comentarios}
          </Text>
        </div>
      </Box>
      <Flex justify='flex-end'>
        <Button
          mt={6}
          mb={2}
          ml={2}
          variantColor='blue'
          variant='outline'
          type='button'
          colorScheme='blue'
          onClick={() => props.btnClose()}
        >
          {t('common:close')}
        </Button>
      </Flex>
    </div>
  )
}

export default DetailsTable
