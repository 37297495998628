import React, { useEffect, useState } from 'react'
import {
  getAplicacionDetalleByAppId,
  getAppInformation,
  updateAplicacionDetalle,
  updateAppInformation,
} from '../../../../api/application'
import { useTranslation } from 'react-i18next'
import { useToast, Stack, Skeleton, Box } from '@chakra-ui/core'
import { toastCustomizedError, toastCustomizedSuccess } from '../../../common/toast-default-config'
import ApplicationForm from '../../../forms/application/application'
import { useSelector } from 'react-redux'
import { Panel } from '../../../common/helpers'
import { getToken, getUserApplicationId } from '../../../../redux/selectors'
import { getActiveTemplate } from '../../../../api/intranet/marca-blanca'
import { isSuccess } from '../../../../helpers/utils'
import { useParams } from 'react-router-dom'

export default function Information() {
  const UserApplicationId = useSelector(getUserApplicationId)
  let { appId } = useParams()
  const { t } = useTranslation(['common', 'applications', 'forms'])
  const toast = useToast()
  const token = useSelector(getToken)

  const [appInfo, setAppInfo] = useState({
    isLoading: false,
    data: {},
    error: false,
  })

  async function getAppInfo() {
    if (appId === undefined) {
      appId = UserApplicationId
    }
    setAppInfo(state => ({
      ...state,
      isLoading: true,
    }))

    try {
      const app = await getAppInformation(appId, token)
      const appInfo = await getAplicacionDetalleByAppId(appId, token)
      const empresaTemplate = await getActiveTemplate(appId, token);
      setAppInfo(state => ({
        ...state,
        isLoading: false,
        data: mapObjToFormModel(app.data, appInfo.data[0], empresaTemplate?.data[0]),
      }))
    } catch (error) {
      console.log('error', error)
      setAppInfo(state => ({
        ...state,
        isLoading: false,
        error: false,
      }))
    }
  }

  useEffect(() => {
    getAppInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const mapObjToFormModel = (app, detalle, empresaTemplate) => {
    let mappedObj = false
    try {
      mappedObj = {
        nombreFantasia: app.nombreFantasia,
        dominio: app.dominio,
        tipoAplicacion: { id: parseInt(app.tipoAplicacion?.id) },
        paisId: app.paisId,
        comercial: app.comercial,
        rubros: app.rubros,
        detalle: {
          id: detalle.id,
          cuit: detalle.cuit,
          razonSocial: detalle.razonSocial,
          web: detalle.web,
          nombreContacto: detalle.nombreContacto,
          telefonoContacto: detalle.telefonoContacto,
          emailContacto: detalle.emailContacto,
          logoUrl: detalle.logoUrl,
          favIcon: detalle.favIcon,
          legajo: detalle.legajo,
        },
        template: empresaTemplate?.template
      }
    } catch (error) {
      console.log(error)
    }
    return mappedObj
  }

  const handleSubmitForm = async data => {
    typeof appId === 'undefined' ? (appId = UserApplicationId) : (appId = appId)
    const detalle = data.detalle
    detalle.aplicacion = { id: appId }
    if (data.comercial.id === 0 || !data.comercial.id) {
      data.comercial = null
    } else {
      data.comercial = {
        id: parseInt(data.comercial.id),
      }
    }
    let status = false

    try {
      const getDetalle = await getAplicacionDetalleByAppId(appId, token)
      const detalleId = getDetalle.data[0].id
      await updateAplicacionDetalle(detalleId, detalle, token)
      const response = await updateAppInformation({ ...data, activa: true }, appId, token)
      status = isSuccess(response.status)

      if (status) {
        toast(toastCustomizedSuccess(t('forms:actionCompleted')))
      } else {
        toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      }
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }
  return (
    <Box flex='3'>
      <Panel rounded='md'>
        {appInfo.isLoading ? (
          <Stack spacing='10px'>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='100px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
          </Stack>
        ) : (
          <ApplicationForm formData={appInfo.data} onSubmit={handleSubmitForm} buttonLabel={t('common:save')} />
        )}
      </Panel>
    </Box>
  )
}
