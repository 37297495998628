import React, { useState } from "react";
import { Button, useToast, NumberInput } from "@chakra-ui/core"
import '../styles/common/pagination.css'
import { useTranslation } from 'react-i18next'
import { FaArrowRight } from 'react-icons/fa'

const Pagination = ({ currentPage, setPage, totalPages, selectPage}) => {
  const { t } = useTranslation(['common'])
  const pages = []
  if (totalPages > 1) {
    for (let i = 2; i < totalPages; i++) {
      pages.push(i);
    }
  }

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handlePage()
    }
  }

  const toast = useToast()

  const [goToPage, setGoToPage] = useState(undefined)

  const handleGoToPage = ({ target }) => {
    const value = parseInt(target.value)
    if (value > 0 && value <= totalPages) {
      setGoToPage(value)
    } else {
      setGoToPage(undefined)
    }
  }

  const handlePage = () => {
    if (goToPage && (goToPage > 0 && goToPage <= totalPages)) {
      setPage(goToPage)
    } else {
      toast({
        title: "Página inexistente",
        description: `Esta lista tiene ${totalPages} páginas disponibles.`,
        status: "error",
        duration: 6000,
        isClosable: true,
      })
    }
  }

  return (
    <div className="pagination-container">
      <Button
        onClick={() => setPage(1)}
        className={`pagination__btn first ${currentPage === 1 ? 'active' : 'inactive'}`}
      >
        1
      </Button>
      <div className={`pagination__more${currentPage > 4 ? ' show' : ''}`}>...</div>
      {totalPages > 1 && (
        <>
          {pages.map(page => {
            if (page > currentPage - 3 && page < currentPage + 3) {
              return (
                <Button
                  onClick={() => setPage(page)}
                  key={page}
                  className={`pagination__btn ${currentPage === page ? 'active' : 'inactive'}`}
                >
                  {page}
                </Button>
              )
            }
          })}
        </>
      )}
      {totalPages > 1 && (
        <>
          <div className={`pagination__more${currentPage < totalPages - 3 && totalPages > 4 ? ' show' : ''}`}>...</div>
          <Button
            onClick={() => setPage(totalPages)}
            className={`pagination__btn last ${currentPage === totalPages ? 'active' : 'inactive'}`}
          >
            {totalPages}
          </Button>
        </>
      )}
      {totalPages > 7 && selectPage &&(
        <>
          <label htmlFor='page-input' className='pagination__input'>
            {t('common:goToPage')}:
            <input
              id='page-input'
              name='page-input'
              type='number'
              value={goToPage}
              onInput={handleGoToPage}
              placeholder='N°'
              onKeyUp={handleEnterPress}
            />
            <Button
              onClick={handlePage}
              className={`pagination__go last ${currentPage === totalPages ? 'active' : 'inactive'}`}
            >
              <FaArrowRight />
            </Button>
          </label>


        </>
      )}
    </div>
  )
}

export default Pagination