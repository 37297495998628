export const getUserPermissions = state => state.appState.user.permissions;
export const getUserInformation = state => state.appState.user.information;
export const getUserApplication = state => state.appState.user.aplicacion;
export const getUserRolId = state => state.appState?.user?.information?.rol?.id;
export const getUserRolName = state => state.appState.user.information.rol.name;
export const getCountriesState = state =>  state.appState.user.countries;

export const getUserTypeApplicationId = state => state.appState.user.aplicacion.tipoAplicacionId;
export const getUserApplicationId = state => state.appState.user.aplicacion.applicationId;
export const getUserCountryId = state => state.appState.user.aplicacion.paisId;
export const getToken = state => state.appState.user.token;
export const getAuthToken = state => state.appState.user.authtoken;
export const getRefreshToken = state => state.appState.user.refreshToken;
export const getTemplate = state => state.appState.user.template;
export const getUserInff = state => state.appState.user.userInf;
export const getFullUser = state => state.appState.user;

// Suppliers states
export const getAgreementsState = state => state.suppliersState.agreements;
export const getContactsState = state => state.suppliersState.contacts;
export const getOfficesState = state => state.suppliersState.offices;

// Banners states
export const getCategoriesState = state => state.bannersState.categories;
export const getAllCategoriesState = state => state.bannersState.allCategories;
export const getBannersState = state => state.bannersState.banners;
export const getAllBannersState = state => state.bannersState.allBanners;
export const getIgnoreCategoriesState = state => state.bannersState.ignoreCategories;
export const getUbicationsState = state => state.bannersState.ubications;

//Package states
export const getPackageState = state => state.packageState.packages;
