import React, { useRef } from 'react'
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/core'
import './styles.css'
import { useTranslation } from 'react-i18next'

const detailContentPadding = 16

const CustomRowTable = ({ row, columns, expandable, toggleRow, index, expandedRows }) => {
  const { t } = useTranslation(['common'])
  const contentRef = useRef(null)

  return (
    <>
      <Tr className='custom-table__tbody__tr'>
        {columns.map(({ dataIndex, render, key }) => (
          <Td className='custom-table__tbody__tr__td' key={`${row.id}-${key}-cell`}>
            {render ? render(row) : row[dataIndex]}
          </Td>
        ))}
        {!!expandable && (
          <Td className='custom-table__tbody__tr__td'>
            <Button
              aria-label='Expand row'
              width={120}
              variantColor='blue'
              variant='outline'
              onClick={() => toggleRow(index)}
            >
              {expandedRows.includes(index) ? t('common:see_less') : t('common:see_more')}
            </Button>
          </Td>
        )}
      </Tr>
      {!!expandable && (
        <Tr className='custom-table__tbody__details-tr'>
          <Td className='custom-table__tbody__details-tr__td' colSpan={columns.length + 1}>
            <div
              ref={contentRef}
              style={{
                paddingTop: !!expandedRows.includes(index) ? detailContentPadding : 0,
                height: !!expandedRows.includes(index) ? contentRef.current.scrollHeight + detailContentPadding * 2 : 0,
              }}
              className={`custom-table__tbody__details-tr__td__content ${
                !!expandedRows.includes(index) ? 'open-details' : ''
              }`}
            >
              {row.details}
            </div>
          </Td>
        </Tr>
      )}
      <Tr className='custom-table__tbody__tr'>
        <Td className='custom-table__row-divider' colSpan={columns.length + 1} />
      </Tr>
    </>
  )
}

export default CustomRowTable
