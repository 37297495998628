import React from 'react'
import SupplierForm from '../../forms/suppliers/supplier'
import { getAuthToken } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import { createSupplier } from '../../../api/intranet/requests'
import { toastCustomizedSuccess, toastCustomizedError } from '../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { isSuccess } from '../../../helpers/utils'

export default function CreateSupplier({ onFinish }) {
  const { t } = useTranslation(['common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const toast = useToast()
  const handleSubmit = async formData => {
    try {
      const response = await createSupplier(formData, authToken)
      const status = isSuccess(response.status)
      toast(
        status
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
    onFinish()
  }

  return <SupplierForm onSubmit={handleSubmit} buttonLabel={t('common:create')} />
}
