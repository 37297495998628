import React from 'react'
import { SectionDataProvider } from '../../../context/SectionDataContext'
import { Consultations, Reclamos } from '.'

const FILTER_KEYS = {
  paisId: 'paisId',
  agenciaId: 'agenciaId',
  nombreAgencia: 'nombreAgencia',
  startDate: 'startDate',
  endDate: 'endDate',
  page: 'page',
  maxPerPage: 'maxPerPage',
  tipoReclamo: 'tipoReclamo'
}

const SECTION_DATA_KEYS = {
  reclamos: 'reclamos',
}

const defaultSectionDataValues = {
  reclamos: {
    data: [],
    isLoading: true,
    error: null,
  },
}

const defaultFiltersValues = {
  paisId: '',
  agenciaId: '',
  nombreAgencia: '',
  startDate: null,
  endDate: null,
  page: 1,
  maxPerPage: 20,
  tipoReclamo: ''
}

const Reclamo = () => (
  <SectionDataProvider defaultFilters={defaultFiltersValues} defaultSectionData={defaultSectionDataValues}>
    <Reclamos />
  </SectionDataProvider>
)

export { Reclamo, FILTER_KEYS, SECTION_DATA_KEYS, defaultSectionDataValues, defaultFiltersValues }
