import React, { useState, useMemo, useEffect } from 'react'
import { Box, FormLabel, Button, PseudoBox, Grid, Stack } from '@chakra-ui/core'
import FormInput from '../form-input'
import { useToast } from '@chakra-ui/core'
import { toastCustomizedError } from '../../common/toast-default-config'
import { useSelector } from 'react-redux'
import { getAllComercialesByCountryId, getAllRubrosByCountryId } from '../../../api/application'
import { getAllTemplates } from '../../../api/intranet/marca-blanca'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SelectAsync from '../../common/select-async'
import { APPS_CRUD_SELECT_COUNTRY } from '../../../helpers/permissions'
import { usePermissionChecker } from '../../hooks'
import {
  getCountriesState,
  getAuthToken,
  getUserCountryId,
  getUserRolId,
  getUserInformation,
} from '../../../redux/selectors'
import ImageInput from '../../common/image-input'
import MultiSelectMenu from '../../common/multiselect-dropdown'
import { FaExclamationCircle } from 'react-icons/fa'

export default function ApplicationForm({ onSubmit, formData = false, buttonLabel = false }) {
  const { t } = useTranslation(['common', 'forms', 'applications'])
  const userCountryId = useSelector(getUserCountryId)
  const { canDoThis } = usePermissionChecker()
  const [submitBlock, setSubmitBlock] = useState(false)
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId === 2 ? true : false
  const permisosUser = userRolId === 1 || userRolId === 2 || userRolId === 3 ? true : false
  const userDirector = userRolId === 3 ? true : false
  const permisosAdminCountry = userRolId === 1 || userRolId === 2 ? true : false
  const authToken = useSelector(getAuthToken)
  const user = useSelector(getUserInformation)
  const [paisId, setPaisId] = useState(
    formData['paisId'] ? formData['paisId'] : permisosUserCountry ? userCountryId : '',
  )
  const toast = useToast()
  const msgTitle = t('applications:sendProblems')
  const msgDescription = t('applications:inputDomainSpaces')
  const countries = useSelector(getCountriesState)

  const { handleSubmit, register, control, setValue } = useForm({
    defaultValues: formData,
  })
  const [comercial, setComercial] = useState({
    data: [],
    isLoading: true,
  })

  const [templates, setTemplates] = useState({
    data: [],
    isLoading: true,
  })

  const [rubros, setRubros] = useState({
    data: [],
    isLoading: true,
  })

  async function submit(data) {
    let textoAreaDividido = userDirector ? formData.dominio.split(' ') : data.dominio.split(' ')
    let words = textoAreaDividido.length

    if (words === 1) {
      setSubmitBlock(true)
      await onSubmit({
        ...data,
        tipoAplicacion: { id: 3 },
        activa: true,
      })
      setSubmitBlock(false)
    } else toast(toastCustomizedError(msgTitle, msgDescription))
  }

  const getRubros = async () => {
    setRubros(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      let response

      if (paisId) {
        response = await getAllRubrosByCountryId(paisId)
      } else {
        response = {
          data: [],
        }
      }

      setRubros(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getTemplates = async () => {
    setTemplates(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      let response

      if (paisId) {
        response = await getAllTemplates(authToken, paisId)
      } else {
        response = {
          data: [],
        }
      }

      setTemplates(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getComercial = async () => {
    setComercial(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      let response

      if (paisId) {
        response = await getAllComercialesByCountryId(paisId)
      } else {
        response = {
          data: [],
        }
      }

      setComercial(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }
  useEffect(() => {
    getComercial()
    getRubros()
    getTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paisId])

  const countrySelectorConfig = useMemo(() => {
    const formDataOrUseDafult = defaultValue => (formData['paisId'] ? formData['paisId'] : defaultValue)
    return canDoThis(
      APPS_CRUD_SELECT_COUNTRY,
      () => ({
        defaultValue: formDataOrUseDafult(0),
      }),
      () => ({
        defaultValue: formDataOrUseDafult(userCountryId),
      }),
    )
  }, [canDoThis, formData, userCountryId])

  const handleChange = (newValue, onChange) => {
    onChange(newValue)
    setPaisId(newValue)
  }

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(submit)}>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
        <Box>
          <FormLabel htmlFor='paisId' isRequired>
            {t('forms:country')}
          </FormLabel>
          <Controller
            control={control}
            name='paisId'
            defaultValue={countrySelectorConfig.defaultValue}
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => {
                  setValue('comercial.id', '')
                  setValue('rubros', null)
                  return handleChange(e.target.value, onChange)
                }}
                placeholder={t('forms:selectOption')}
                name={name}
                value={paisId}
                isRequired
                isDisabled={!permisosUser || permisosUserCountry}
              >
                {countries
                  ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
                  .map(({ id, nombre }) => (
                    <option key={id} value={id}>
                      {nombre}
                    </option>
                  ))}
              </SelectAsync>
            )}
          />
        </Box>
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='nombreFantasia'
          label={`${t('forms:name')} ${t('forms:agency')}`}
          inputProps={{
            type: 'text',
            placeholder: `${t('forms:agency')} X`,
            ref: register,
          }}
        />
        <Box>
          <FormLabel htmlFor='comercial.id'>{t('applications:commercial')}</FormLabel>
          <Controller
            id='comercial.id'
            control={control}
            name='comercial.id'
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => onChange(e.target.value)}
                placeholder={t('forms:selectOption')}
                name={name}
                isDisabled={!permisosUser}
                value={value}
                isLoading={comercial.isLoading}
              >
                {comercial.data.map(({ id, nombre, apellido }) => (
                  <option key={id} value={id}>
                    {`${truncateText(nombre, 30)} ${truncateText(apellido, 30)}`}
                  </option>
                ))}
              </SelectAsync>
            )}
          />
        </Box>
        <Box>
          <FormLabel htmlFor='rubros'>{t('applications:item')}</FormLabel>
          <Stack position='relative'>
            <Controller
              control={control}
              isDisabled={!permisosUser}
              name='rubros'
              isRequired
              render={({ onChange, value, name }) => {
                let valueAux = !value || value === 0 ? [] : [value]
                let selectedOptions = typeof value === 'number' || !value ? valueAux : value.map(v => v.id)
                return (
                  <MultiSelectMenu
                    change={onChange}
                    name={name}
                    value={value}
                    isDisabled={!permisosUser || rubros.isLoading}
                    label={t('forms:select')}
                    options={rubros.data}
                    selectedOptionsAux={selectedOptions}
                  />
                )
              }}
            />
          </Stack>
        </Box>
        <FormInput
          isRequired
          isDisabled={!permisosAdminCountry}
          name='dominio'
          label={t('forms:domainLabel')}
          inputProps={{
            type: 'text',
            placeholder: t('forms:domain'),
            ref: register,
          }}
        />
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.cuit'
          label={t('applications:cuit')}
          inputProps={{
            type: 'text',
            placeholder: '00-00000000-0',
            ref: register,
          }}
        />
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.razonSocial'
          label={t('applications:businessName')}
          inputProps={{
            type: 'text',
            placeholder: t('applications:businessName'),
            ref: register,
          }}
        />
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.legajo'
          label={t('applications:file')}
          inputProps={{
            type: 'text',
            placeholder: t('applications:file'),
            ref: register,
          }}
        />
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Box style={{ width: '90%' }}>
            <ImageInput
              isDisabled={!permisosUser}
              name='detalle.favIcon'
              label='Favicon'
              setValue={setValue}
              inputProps={{
                type: 'text',
                placeholder: `URL Favicon imagen.ico`,
                ref: register,
              }}
            />
          </Box>
          <Box
            style={{
              width: '10%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormLabel htmlFor='paisId'><br></br></FormLabel>
            <FaExclamationCircle
              title={t('applications:favIconHelp')}
              style={{ color: '#1f408f', cursor: 'pointer' }}
            />
          </Box>
        </Box>
        <ImageInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.logoUrl'
          label={t('applications:logo')}
          setValue={setValue}
          inputProps={{
            type: 'text',
            placeholder: `URL ${t('applications:logo')}`,
            ref: register,
          }}
        />
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.web'
          label='Web'
          inputProps={{
            type: 'text',
            ref: register,
          }}
        />
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.nombreContacto'
          label={`${t('forms:name')} ${t('forms:contact')}`}
          inputProps={{
            type: 'text',
            ref: register,
          }}
        />
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.telefonoContacto'
          label={`${t('forms:phone')} ${t('forms:contact')}`}
          inputProps={{
            type: 'text',
            ref: register,
          }}
        />
        <FormInput
          isRequired
          isDisabled={!permisosUser}
          name='detalle.emailContacto'
          label='Email'
          inputProps={{
            type: 'email',
            ref: register,
          }}
        />
        <Box>
          <FormLabel htmlFor='template.id' isRequired>
            {t('applications:template')}
          </FormLabel>
          <Controller
            id='template.id'
            control={control}
            name='template.id'
            render={({ onChange, value, name }) => (
              <SelectAsync
                onChange={e => onChange(e.target.value)}
                placeholder={t('forms:selectOption')}
                name={name}
                isDisabled={!permisosUser}
                isRequired
                value={value}
                isLoading={templates.isLoading}
              >
                {templates.data.map(({ id, nombre }) => (
                  <option key={id} value={id}>
                    {`${truncateText(nombre, 30)}`}
                  </option>
                ))}
              </SelectAsync>
            )}
          />
        </Box>
      </Grid>
      {permisosUser && (
        <Button
          mt={6}
          variantColor='blue'
          variant='outline'
          type='submit'
          isLoading={submitBlock}
          isDisabled={!permisosUser}
        >
          {buttonLabel || t('common:create')}
        </Button>
      )}
    </PseudoBox>
  )
}
