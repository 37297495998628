import { Select } from "@chakra-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SelectAsync({ isLoading, children, loadingPlaceholder = false, nativeProps = {}, ...restProps }) {
	const { t } = useTranslation(["forms"]);
	return isLoading
		? (
			<Select {...restProps} placeholder={loadingPlaceholder || `${t("forms:loading")} ...`} />
		)
		: (
			<Select {...restProps} {...nativeProps}>
				{children}
			</Select>
		);

}
