import { Box, Image } from '@chakra-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarker,
  FaPhone,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa'
import { goTo } from '../../helpers/utils'
import { PageContainer } from '../common/helpers'
import '../styles/common/footer.css'
import AlertBand from './alert-band'
import { getUserCountryId } from '../../redux/selectors'
import { useSelector } from 'react-redux'

export default function Footer() {
  const { t } = useTranslation(['template'])
  const userCountryId = useSelector(getUserCountryId)

  return (
    <footer className='footer-home'>
      <PageContainer>
        <div className='footer-content'>
          <div className='contact-section'>
            <Box className='section-title'>{t('template:contact')}</Box>
            {userCountryId === 3 ? (
              <div className='content'>
                <a href='https://goo.gl/maps/Pw7N8ZcrKPP4SwaZ8' target='_blank' className='contact-line-br'>
                  <Box style={{ marginTop: '.3rem' }}>
                    <FaMapMarker />
                  </Box>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>Av. Ipiranga, 318</Box>
                    <Box>Bloco A - 5º Andar</Box>
                    <Box>República, São Paulo, Brasil</Box>
                  </Box>
                </a>
                <a href='tel:+54113214-7544' className='contact-line'>
                  <FaPhone />
                  <Box>+55 (11) 3214-7544</Box>
                </a>
                <div className='contact-line'>
                  <Image
                    src='https://geaargentina.com.ar/sitio/wp-content/uploads/2020/02/GEA-LGBT.png'
                    height='1.8rem'
                  />
                  <Box>GEA Diversidad</Box>
                </div>
              </div>
            ) : userCountryId === 2 ? (
              <div className='content'>
                <a href='tel:+51(1)4801590' className='contact-line'>
                  <FaPhone />
                  <Box>+51(1)4801590</Box>
                </a>
                <a href='comercial@grupogea.com.pe' className='contact-line'>
                  <FaEnvelope />
                  <Box>comercial@grupogea.com.pe</Box>
                </a>
                <div className='contact-line'>
                  <Image
                    src='https://geaargentina.com.ar/sitio/wp-content/uploads/2020/02/GEA-LGBT.png'
                    height='1.8rem'
                  />
                  <Box>GEA Diversidad</Box>
                </div>
              </div>
            ) : (
              <div className='content'>
                <a href='https://goo.gl/maps/KYoKUMxyBVk2FjnCA' target='_blank' className='contact-line'>
                  <FaMapMarker />
                  <Box>Talcahuano 833, CABA</Box>
                </a>
                <a href='tel:08002205520' className='contact-line'>
                  <FaPhone />
                  <Box>0800-220-5520</Box>
                </a>
                <a href='tel:+541170785520' className='contact-line'>
                  <FaPhone />
                  <Box>+54 (011) 7078-5520</Box>
                </a>
                <a href='mailto:argentina@grupogea.la' target='_blank' className='contact-line'>
                  <FaEnvelope />
                  <Box>argentina@grupogea.la</Box>
                </a>
                <div className='contact-line'>
                  <Image
                    src='https://geaargentina.com.ar/sitio/wp-content/uploads/2020/02/GEA-LGBT.png'
                    height='1.8rem'
                  />
                  <Box>GEA Diversidad</Box>
                </div>
              </div>
            )}
            {userCountryId !== 3 && userCountryId !== 2 && <AlertBand />}
          </div>
          <div className='social-media-section'>
            <Box>{t('template:follow_us')}</Box>
            {/* <Box className='section-title'>#GEAconVOS</Box> */}
            <div className='icons'>
              <FaFacebook
                onClick={() =>
                  goTo(
                    `${
                      userCountryId === 2
                        ? 'https://www.facebook.com/grupogeaperu'
                        : userCountryId === 3
                        ? 'https://www.facebook.com/grupogeabrasil'
                        : 'https://www.facebook.com/grupogeaargentina/'
                    }`,
                  )
                }
              />
              <FaInstagram
                onClick={() =>
                  goTo(
                    `${
                      userCountryId === 2
                        ? 'https://www.instagram.com/grupogeaperu/'
                        : userCountryId === 3
                        ? 'https://www.instagram.com/grupogeabrasil/'
                        : 'https://www.instagram.com/grupogeaargentina/'
                    }`,
                  )
                }
              />
              <FaLinkedinIn
                onClick={() =>
                  goTo(
                    `${
                      userCountryId === 2
                        ? 'https://www.linkedin.com/company/grupo-gea-per%C3%BA/'
                        : userCountryId === 3
                        ? 'https://br.linkedin.com/company/grupogeabrasil'
                        : 'https://www.linkedin.com/company/grupo-gea-argentina/?originalSubdomain=ar'
                    }`,
                  )
                }
              />
              <FaYoutube
                onClick={() =>
                  goTo(
                    `${
                      userCountryId === 2
                        ? 'https://www.youtube.com/channel/UCVFStOqJYjItvoM4LoRPsqA'
                        : userCountryId === 3
                        ? 'https://www.youtube.com/channel/UCM9FeKhWoWB892Mgr3aeROg'
                        : 'https://www.youtube.com/channel/UCVFStOqJYjItvoM4LoRPsqA'
                    }`,
                  )
                }
              />
              {userCountryId === 3 || userCountryId === 2 ? (
                ''
              ) : (
                <FaTwitter onClick={() => goTo('https://twitter.com/geaargentina')} />
              )}
            </div>
          </div>
        </div>
      </PageContainer>
    </footer>
  )
}
