import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormLabel,
  Grid,
  NumberInput,
  Tab,
  TabList,
  TabPanel,
  useToast,
  TabPanels,
  Tabs,
  Switch,
  Tag,
  Link,
} from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../../common/helpers'
import moment from 'moment'
import { Prices } from './Prices'
import { Hotels } from './Hotels'
import { Trips } from './Trips'
import { DateRangePicker } from 'react-dates'
import { Link as RouterLink } from 'react-router-dom'

import { toastCustomizedError } from '../../../common/toast-default-config'

//for date picker
import 'react-dates/initialize'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

export const DeparturesForm = ({ departureData, setDeparture, addDeparture, isEdit, onDiscard }) => {
  const { t } = useTranslation(['common', 'forms', 'packages', 'comunications'])

  const toast = useToast()
  const errTItle = t('common:error-ocurred')
  const errDescription = t('packages:noDateSelected')
  const errDescriptionPrice = t('packages:noPriceSelected')
  const [trigerSave, setTrigerSave] = useState(0)
  const [activeSubmit, setActiveSubmit] = useState(true)
  const [cancel, setCancel] = useState(0)

  // true for checked and show the range picker
  const [rangePickerCheck, setRangePickerCheck] = useState(
    departureData
      ? departureData?.operationDateSince?.toString() === departureData?.operationDateUntil?.toString()
      : false,
  )

  // - HOOKS for the DobleDatePicker
  const [{ startDate, endDate }, setRangeDate] = useState({
    startDate: departureData
      ? departureData.operationDateSince
        ? moment(departureData.operationDateSince)
        : moment()
      : moment(),
    endDate: departureData
      ? departureData.operationDateUntil
        ? moment(departureData.operationDateUntil)
        : moment()
      : moment(),
  })

  if (
    departureData?.operationDateSince &&
    departureData?.operationDateUntil &&
    startDate?.toISOString() != moment(departureData.operationDateSince).toISOString() &&
    endDate?.toISOString() != moment(departureData.operationDateUntil).toISOString()
  ) {
    setRangeDate({
      startDate: moment(departureData.operationDateSince),
      endDate: moment(departureData.operationDateUntil),
    })
  }

  const [focusedInput, setFocusedInput] = useState(null)

  // - - -❗ METHODS ❗- - -

  function handleRangePickerChange(change) {
    if (!change) {
      setRangeDate({ startDate: startDate, endDate: startDate })
      setDeparture({
        ...departureData,
        operationDateSince: moment(startDate).toISOString(),
        operationDateUntil: moment(startDate).toISOString(),
      })
    }
    setRangePickerCheck(change)
  }

  function setFocusedPicker(focusedInput) {
    setFocusedInput(() => focusedInput)
  }
  function setRangePicker({ startDate, endDate }) {
    setRangeDate({ startDate, endDate })
    setDeparture({
      ...departureData,
      operationDateSince: moment(startDate).toISOString(),
      operationDateUntil: moment(endDate).toISOString(),
    })
  }

  // HOOKS for the SingleDatePicker
  function onChangeDate(date) {
    setDeparture({
      ...departureData,
      operationDateSince: moment(date).toISOString(),
      operationDateUntil: moment(date).toISOString(),
    })
    setRangeDate({ startDate: date, endDate: date })
  }

  //ARMAR UN ESTADO PARA PRECIOS, UNO PARA HOTELES Y UNO PARA TRAMOS
  function onChange(item, type, idEdit) {
    if (idEdit === -1) {
      setDeparture({
        ...departureData,
        [type]: departureData[type].concat(item),
      })
    } else {
      departureData[type][idEdit] = item
      setDeparture({
        ...departureData,
      })
    }
  }

  function onChangeHoteles(item, idx) {
    if (idx === -1) {
      setDeparture({
        ...departureData,
        hotelesInformacion: departureData.hotelesInformacion.concat(item),
      })
    } else {
      departureData.hotelesInformacion[idx] = item
      setDeparture({
        ...departureData,
      })
    }
  }

  function onChangeInput(value, name) {
    setDeparture({
      ...departureData,
      [name]: value,
    })
  }

  function onDelete(itemId, name) {
    switch (name) {
      case 'hotelesInformacion':
        departureData[name].splice(itemId, 1)
        break
      case 'precioInformacion':
        departureData[name] = departureData[name].filter(i => i.baseType.id !== itemId)
        break
      case 'trips':
        departureData[name] = departureData[name].filter(i => i.tripType.id !== itemId)
        break
    }
    setDeparture({
      ...departureData,
    })
  }

  //AGREGAR SALIDA - Validar campos obligatorios
  function onAdd() {
    if (startDate && endDate) {
      if (typeof departureData.precioInformacion[0] === 'undefined' && departureData?.trips?.length) {
        toast(toastCustomizedError(errTItle, errDescriptionPrice))
      } else {
        addDeparture(departureData)
        setDeparture({
          ...departureData,
          hotelesInformacion: [],
          precioInformacion: [],
          trips: [],
        })
        setTrigerSave(trigerSave + 1)
      }
    } else {
      toast(toastCustomizedError(errTItle, errDescription))
    }
    setDeparture({
      ...departureData,
      availability: '',
      hotelesInformacion: [],
      precioInformacion: [],
      trips: [],
    })
    setRangeDate({ startDate: startDate, endDate: startDate })
  }

  function onCancel() {
    onDiscard()
    setCancel(cancel+ 1)
  }

  useEffect(() => {
    setCancel(cancel + 1)
    if (departureData.precioInformacion.length) {
      setActiveSubmit(false)
    } else {
      setActiveSubmit(true)
    }
  }, [departureData])

  return (
    <Panel>
      <Tag width='100%' mb='3' p='3' bg='blue.500' color='white'>
        {t('packages:departures')} - {t('packages:requiredData')}
      </Tag>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: '10% 66%' }} gap={3}>
        {/* Cupos */}
        <Box>
          <FormLabel style={{ paddingLeft: '5px' }}>{t('forms:quotas')}</FormLabel>
          <NumberInput
            className='noArrows'
            px={1}
            min={0}
            max={500}
            name='availability'
            style={{ top: '8px' }}
            value={departureData.availability | 0}
            onChange={e => onChangeInput(e, 'availability')}
          />
        </Box>

        {/* Fecha de salida */}
        <Box>
          <FormLabel isRequired style={{ paddingLeft: '8px' }}>
            {t('forms:departureDate')}
          </FormLabel>
          <Box style={{ padding: 4, margin: 4, display: 'flex', alignItems: 'center' }}>
            {!rangePickerCheck ? (
              <SingleDatePicker
                displayFormat='DD/MM/YYYY'
                date={startDate}
                onDateChange={date => onChangeDate(date)}
                focused={focusedInput}
                onFocusChange={() => setFocusedInput(!focusedInput)}
                id='operationDate'
                orientation='horizontal'
                required
                small
              />
            ) : (
              <Box maxW={'300px'}>
                <DateRangePicker
                  required
                  startDate={startDate}
                  startDateId='fecha_inicio'
                  endDate={endDate}
                  endDateId='fecha_fin'
                  focusedInput={focusedInput}
                  onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate, endDate })}
                  onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
                  orientation='horizontal'
                  displayFormat='DD/MM/YYYY'
                  small
                />
              </Box>
            )}
            <FormLabel style={{ paddingLeft: '8px' }}>{t('forms:dateRange')}</FormLabel>
            <Switch
              size='sm'
              style={{ padding: 3, alignItems: 'center' }}
              onChange={() => handleRangePickerChange(!rangePickerCheck)}
              isChecked={rangePickerCheck}
            />
          </Box>
        </Box>
      </Grid>

      <Tabs mt='5' mb='2' variant='enclosed'>
        <TabList>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>{t('packages:transportations')}</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>{t('packages:hotels')}</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>{t('packages:prices')}</Tab>
        </TabList>
        <TabPanels mt='2'>
          <TabPanel mb='8px'>
            <Trips
              onChange={onChange}
              departureData={departureData}
              onDelete={onDelete}
              trigerSave={trigerSave}
              cancel={cancel}
            />
          </TabPanel>
          <TabPanel mb='8px'>
            <Hotels
              onChange={onChangeHoteles}
              departureData={departureData}
              onDelete={onDelete}
              trigerSave={trigerSave}
              cancel={cancel}
            />
          </TabPanel>
          <TabPanel mb='8px'>
            <Prices
              onChange={onChange}
              departureData={departureData}
              onDelete={onDelete}
              trigerSave={trigerSave}
              cancel={cancel}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Box style={{ display: 'flex', gap: '1rem', marginTop: '20px' }}>
        <Button variantColor='blue' variant='outline' size='md' type='button' onClick={onAdd} isDisabled={activeSubmit}>
          {isEdit ? t('packages:saveChanges') : t('packages:createOutput')}
        </Button>
        <Button variantColor='red' variant='outline' size='md' type='button' onClick={onCancel}>
          {t('common:cancel')}
        </Button>
        <Link as={RouterLink} to='/packages'>
          <Button variantColor='blue' variant='outline' size='md'>
            {t('comunications:getback')}
          </Button>
        </Link>
      </Box>
    </Panel>
  )
}
