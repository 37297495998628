import React from "react";
import { useSelector } from "react-redux";
import { getUserPermissions } from "../redux/selectors";
import NoMatch from "./no-march";

export const CheckPermissions = ({ render: Component, permission, ...props }) => {
	const userPermissions = useSelector(state => getUserPermissions(state)); 
	const defaultRedirection = <NoMatch  {...props} />;

	try {
		if (userPermissions.includes(permission)) {
			return (
				<Component {...props} />
			);
		}
	} catch {
		return defaultRedirection;
	}
	return defaultRedirection;
};

export default CheckPermissions;