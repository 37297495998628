import React, {useState, useMemo, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useDisclosure, Stack, Spinner, Button, Box, Text } from "@chakra-ui/core";
import { Heading } from "@chakra-ui/core";
import DetailsBooking from "./detailsBooking";
import { PageContainer } from "../../common/helpers";
import ModalSimple from "../../common/modal-simple";
import { useSelector } from "react-redux";
import {getBookingsBySupplier} from "../../../api/packages-gw";
import Table from "../../common/table";
import { getAuthToken, getUserApplicationId } from "../../../redux/selectors";
import moment from "moment";
import AOS from "aos";
export function Bookings() {

	const { t } = useTranslation(["bookings"]);


	const estadoReservas = [
		{"id": 1, "name": "Pendiente"},
		{"id": 2, "name": "Pendiente"},
		{"id": 3, "name": "Aprobado"},
		{"id": 4, "name": "Cancelado"},
		{"id": 5, "name": "Rechazado"},
		{"id": 6, "name": "Error"}];


	// - - - 📥 HOOKS 📥- - -

	const appId = useSelector(getUserApplicationId);
	const authToken = useSelector(getAuthToken);

	// - COMPONENT INFORMATION -
	const [ dataBookings, setDataBookings] = useState([]);
	const [ dataBookingsSelect, setDataBookingsSelect] = useState([]);

	// - MODAL -
	const modalDisclosure = useDisclosure();
	const [visualEditExchange, setEditExchange] = useState({
		visualEditExchange: true, });
	const [loading, setLoading] = useState(true);


	// - - -❗ METHODS ❗- - -

	function onEditClick(dat){
		// console.log("Toda la Data para Props",dat)
		modalDisclosure.onOpen();
		setEditExchange(false);
		let dataProps = dat.original;
		setDataBookingsSelect(dataProps);

	}

	function dateFuction(date){
		let dato = date.original.dataBookings.estadoReserva;
		return estadoReservas.map(x=> dato === x.id ? x.name : undefined);

	}

	function dateRef(date){
		return(date.original.dataBookings.estadoReserva >= 4 ? "" : date.original.dataBookings.referenciaPaquete);


	}
	function formatDate(date){
		return moment(date).format("YYYY-MM-DD");
	}


	// - - - 📋 TABLE 📋- - -

	const columns = useMemo(() => [
		{
			Header: "Id",
			accessor: "dataBookings.id",
		},
		{
			Header: t("bookings:state"),
			accessor: "dataBookings.stateBookings",
			Cell: ({ cell }) => (
				<Stack>
					<Text>
						{dateFuction(cell.row)}
					</Text>
				</Stack>
			)
		},
		{
			Header: t("bookings:operatorResponsible"),
			accessor: "dataBookingsDetails.paquete.operadorResponsable.nombre",
		},
		{
			Header: t("bookings:packageTitle"),
			accessor: "dataBookingsDetails.titulo",
		},
		{
			Header: t("bookings:reference"),
			accessor: "dataBookings.referenciaPaquete",
			Cell: ({ cell }) => (
				<Stack>
					<Text>
						{dateRef(cell.row)}
					</Text>
				</Stack>
			)
		},
		{
			Header: t("bookings:dateUpload"),
			accessor: "dataBookings.fechaCarga",
			Cell: ({ cell }) => (
				<Stack>
					<Text>
						{/* {formatDate(date) } */}
						{formatDate(cell.row.original.dataBookings.fechaCarga)}
					</Text>
				</Stack>
			)
		},
		{
			Header: t("bookings:dateDeparture"),
			accessor: "dataBookings.dateDeparture",
			Cell: ({ cell }) => (
				<Stack>
					<Text>
						{/* {formatDate(date) } */}
						{formatDate(cell.row.original.dataBookings.fechaSalida)}
					</Text>
				</Stack>
			)

		},
		{
			Header: t("bookings:buyerEmail"),
			accessor: "dataBookings.emailComprador",
		},
		{
			Header: t("bookings:amount"),
			accessor: "dataBookings.monto",
		},

		{
			Header: t("bookings:details"),
			accessor: "null",
			Cell: ({ cell }) => (
				<Stack spacing="5px" isInline>
					<Button
						width="70%"
						left="10%"
						colorScheme="blue"
						onClick={() => onEditClick(cell.row)}

					>
						<b>{t("bookings:view")}</b>
					</Button>
				</Stack>
			)
		},
	], []);


	// - - - -📞 API CALLS 📞- - - -

	async function getBookings(){
		try{
			const response = await getBookingsBySupplier(appId, authToken);

			let detallePaquete = response.data.object.map(function(x){
				let dataBookingsDetails = JSON.parse(x.detallePaquete);
				let dataBookings = x;
				return{dataBookingsDetails, dataBookings};});
			//  let detallePaquetee = JSON.parse(detallePaquete[0].preReserva)
			//  console.log("detallePaquetee",detallePaquetee)

			//   let preReserva = detallePaquete.map(
			//       function(x){if(typeof x.preReserva === 'object'){return 2}else{return x}}
			//       )
			// console.log(detallePaquete)

			let detallePaqueteReverse = detallePaquete.reverse(x=>x.estado.consultaId);
			setDataBookings(detallePaqueteReverse);
			setLoading(true);

		}
		catch(error){
			console.log(error);
		}
	}

	function searchBookings(){
		getBookings();
		setLoading(false);
	}

	useEffect(() => {
		AOS.init({
		});
		AOS.refresh();
	}, []);


	return (
		<PageContainer>
			<Box backgroundColor='' py="16px" color="#103059">
				<Heading style={{fontSize: "1.8rem"}} mb={0}>{t("comunications:Comunications")}</Heading>
			</Box>
			<Button
				my={5}
				variantColor="blue"
				onClick={() => searchBookings()}
			>
				{t("bookings:search")}{" "}{t("bookings:bookings")}
			</Button>

			{!visualEditExchange ? (
				<ModalSimple
					boxShadow="xl"
					size="6xl"
					isOpen={modalDisclosure.isOpen}
					onClose={modalDisclosure.onClose}
					title={`${t("bookings:details")} ${t("bookings:bookings")} `}

				>
					<DetailsBooking
						dataBookingsSelect={dataBookingsSelect}
						btnClose={modalDisclosure.onClose}

					></DetailsBooking>



				</ModalSimple>

			) : null}



			<Table
				columns={columns}
				data={dataBookings}
			/>

			{!loading ? (
				<Box px="5" py="10" textAlign="center">
					<Spinner
						thickness="4px"
						speed="0.65s"
						emptyColor="gray.200"
						color="blue.500"
						size="xl"
					/>
				</Box>) : null}


		</PageContainer>

	);
}
