import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DateRangePicker } from 'react-dates'
import {
  Button,
  Box,
  Heading,
  useDisclosure,
  Text,
  Stack,
  Link,
  Grid,
  Spinner,
  useToast,
  Image,
  Select,
  Switch,
} from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, Link as RouterLink, useLocation } from 'react-router-dom'
import { PageContainer } from '../../common/helpers'
import { Panel } from '../../common/helpers'
import ModalSimple from '../../common/modal-simple'
import CreateCategories from '../communications/createCategories'
import {
  actualizarComunicados,
  crearComunicados,
  crearPermisosPorRol,
  deleteComunicado,
  eliminarPermisosPorRolId,
  obtenerCategorias,
  obtenerComunicadosFiltered,
  obtenerPermisosPorRol,
} from '../../../api/communications'
import { getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import moment from 'moment'
import Table from '../../common/table'
import { ButtonDelete, ButtonEdit, CommonButton } from '../../forms/buttons/edit'
import SelectAsync from '../../common/select-async'
import CreateCommunications from './create'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import { COMUNICATIONS_CRUD } from '../../../helpers/permissions'
import Can from '../../../helpers/verify-permission'
import AccessEdition from '../../forms/comunications/accessEdition'
import { isSuccess } from '../../../helpers/utils'
import Pagination from '../../common/pagination'
import FormInput from '../../forms/form-input'
import { getAllCountries } from '../../../api/application'
import CreateNews from './create/createNewsModal'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

const Calendar = () => {
  const { t } = useTranslation(['comunications', 'common', 'forms'])
  const authToken = useSelector(getAuthToken)
  const userRolId = useSelector(getUserRolId)
  const permisosUser = userRolId == 1 ? true : false
  const permisosUserCountry = userRolId == 2 ? true : false
  const userCountryId = useSelector(getUserCountryId)
  const [recordToEdit, setRecordToEdit] = useState(undefined)
  const [ids, setIds] = useState(false)
  const [rolls, setRolls] = useState(false)
  const [permisosPorRol, setPermisosPorRol] = useState([])
  const [countries, setCountries] = useState(null)
  const [countryAdmin, setCountryAdmin] = useState(0)
  const [cleanedFilters, setCleanedFilters] = useState(false)
  const [htmlContent, setHtmlContent] = useState('')
  const [titleHtml, setTitleHtml] = useState('')

  const [communications, setCommunications] = useState([])

  const urlRender = data => (
    <Stack isInline spacing='4px'>
      <Link href={data.cell.row.values.url} color='blue.500' target='_blank'>
        {data.cell.row.values.subtitulo}
      </Link>
    </Stack>
  )

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const maxPerPage = 6
  // Acceder a los permisos
  const editAccess = id => {
    categoriasPorId(id.id)
    setIds(id)
    editPermissionsDisclosure.onOpen()
  }

  // Checks permisos
  const funcCheck = id => {
    if (rolls !== false) {
      return rolls.some(x => x.rolId == id)
    }
    return false
  }

  // Crear / Eliminar Permiso
  const newPermission = async (a, b) => {
    if (b == false) {
      let data = {
        rolId: a,
        comunicados: { id: ids.id },
      }
      try {
        const resp = await crearPermisosPorRol(data, authToken)
        if (resp.status === 201) {
        } else {
          console.log('error')
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      let permisoId = rolls.find(x => x.rolId == a)

      try {
        const resp = await eliminarPermisosPorRolId(permisoId?.id, authToken)

        if (resp.status == 204) {
        } else {
          console.log('error')
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  async function newsDestacada(data) {
    let destacado = data.destacado == true ? false : data.destacado == false ? true : null
    let id = data.id

    let body = {
      asunto: data.asunto,
      titulo: data.titulo,
      subtitulo: data.subtitulo,
      imagenUrl: data.imagenUrl,
      descripcion: data.descripcion,
      comunicadoHtml: data.comunicadoHtml,
      categoriasComunicados: { id: data.categoriasComunicados.id },
      destacado: destacado,
      tipoComunicado: { id: data.tipoComunicado.id },
      tipoEnvio: { id: data.tipoEnvio.id },
      url: data.url,
      fecha: data.fecha,
    }

    try {
      const resp = await actualizarComunicados(id, body, authToken)

      if (isSuccess(resp.status)) {
        toast(toastCustomizedSuccess(`${t('comunications:editSucess')}`))
        setPage(page)
      } else {
        toast(toastCustomizedError(`${t('comunications:inputError')}`))
      }
    } catch (err) {
      console.log(err)
    }
  }

  //Cerrar Modal Permisos
  const closeModalPermisos = () => {
    editPermissionsDisclosure.onClose()
    setRolls(false)
  }

  // Obtener Categorias por Id
  const categoriasPorId = async id => {
    try {
      const resp = await obtenerPermisosPorRol(authToken)
      const rollsByComunicadoId = resp.data.filter(x => x.comunicadosId == id)
      setRolls(rollsByComunicadoId)
    } catch (err) {
      console.log(err)
    }
  }

  const obtenerPermisos = async () => {
    try {
      const resp = await obtenerPermisosPorRol(authToken)

      setPermisosPorRol(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  const getCountries = async () => {
    try {
      const response = await getAllCountries(authToken)
      setCountries(() => response.data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const standardColumns = () => [
    {
      Header: t('comunications:date'),
      accessor: 'fecha',
      Cell: ({ cell }) => (
        <Stack spacing='5px' isInline>
          <div
            style={{
              marginTop: '',
              border: '2.5px solid #585859',
              width: '5rem',
              height: '5rem',
              backgroundColor: 'transparent',
            }}
          >
            <Box>
              <Box style={{ display: 'grid', placeContent: 'center' }}>
                <Text
                  style={{
                    fontSize: '1rem',
                    marginTop: '0.5rem',
                    color: '#585859',
                    textTransform: 'uppercase',
                  }}
                >
                  {moment(cell.row.values.fecha).format('MMM')}
                </Text>
              </Box>
              <Box style={{ display: 'grid', placeContent: 'center' }}>
                <p className='dateNumberCardCommunication'>
                  <b>{moment(cell.row.values.fecha).format('DD')}</b>
                </p>
              </Box>
            </Box>
          </div>
        </Stack>
      ),
    },
    {
      Header: t('forms:name'),
      accessor: 'titulo',
    },
    {
      Header: `${t('common:type')} ${t('comunications:category')}`,
      accessor: 'categoriasComunicados.descripcion',
    },
    {
      Header: t('forms:link'),
      accessor: 'subtitulo',
      Cell: urlRender,
    },
    {
      Header: t('comunications:details'),
      accessor: 'descripcion',
    },
    {
      Header: '',
      accessor: 'url',
      Cell: () => '',
    },
    {
      Header: t('comunications:country'),
      accessor: 'categoriasComunicados.paisId',
      Cell: ({ cell }) => (
        <p>{countries?.find(c => c.id === cell.row.values['categoriasComunicados.paisId'])?.nombre}</p>
      ),
    },
    {
      Header: t('common:actions'),
      accessor: 'id',
      Cell: data => {
        return (
          <Stack isInline spacing='4px'>
            {(permisosUser || permisosUserCountry) && (
              <CommonButton
                onClick={() => {
                  editAccess(data.data.find(item => item.id === data.cell.row.values.id))
                }}
                label={t('comunications:permits')}
              />
            )}
            {(permisosUser || permisosUserCountry) && (
              <Stack isInline spacing='4px'>
                <ButtonEdit onClick={() => handleEdit(data.data.find(item => item.id === data.cell.row.values.id))} />
                <ButtonDelete onClick={() => deleteCommunication(data.cell.row.values.id)} />
              </Stack>
            )}
          </Stack>
        )
      },
    },
  ]

  const [selectedImg, setSelectedImg] = useState(undefined)

  useEffect(() => {
    if (selectedImg) {
      imageViewDisclosure.onOpen()
    }
  }, [selectedImg])

  const onCloseImageModal = () => {
    if (selectedImg) {
      setSelectedImg(undefined)
    }
    imageViewDisclosure.onClose()
  }

  const withImgPreviewColumns = () => { 
    let columns = [
    {
      Header: t('forms:image'),
      accessor: 'imagenUrl',
      Cell: ({ value }) => {
        return (
          <Image
            style={{
              marginRight: 'none',
              borderRadius: '.25rem',
              height: '6rem',
              width: '8rem',
              minWidth: '8rem',
              objectFit: 'cover',
              cursor: 'zoom-in',
            }}
            onClick={() => setSelectedImg(value)}
            src={value}
          />
        )
      },
    },
    {
      Header: t('comunications:date'),
      accessor: 'fecha',
      Cell: ({ cell }) => moment(cell.row.values.fecha).format('DD-MM-YYYY'),
    },
    {
      Header: `${t('comunications:type')} ${t('comunications:category')}`,
      accessor: 'categoriasComunicados.descripcion',
    },
    {
      Header: `${t('forms:link')} / ${t('forms:html')}`,
      accessor: row => {
        if (row.url) {
          return (
            <Link href={row.url} color='blue.500' target='_blank' >
              {row.subtitulo}
            </Link>
          )
        } else if (row.tituloHtml) {
          const handleHtmlClick = row => {
            setHtmlContent(row.comunicadoHtml)
            setTitleHtml(row.tituloHtml)
          }
          return (
            <>
              <Link color='blue.500' onClick={() => handleHtmlClick(row)}>
                {row.tituloHtml}
              </Link>
            </>
          )
        }
      },
    },
    {
      Header: '',
      accessor: 'url',
      Cell: () => '',
    },
    {
      Header: t('forms:country'),
      accessor: 'categoriasComunicados.paisId',
      Cell: ({ cell }) => (
        <p>{countries?.find(c => c.id === cell.row.values['categoriasComunicados.paisId'])?.nombre}</p>
      ),
    },
    {
      Header: permisosUser || permisosUserCountry ? t('common:actions') : '',
      accessor: 'id',
      Cell: data =>
        (permisosUser || permisosUserCountry) && (
          <Stack key={data.cell.row.values.id} isInline spacing='4px'>
            <CommonButton
              onClick={() => {
                editAccess(data.data.find(item => item.id === data.cell.row.values.id))
              }}
              label={t('comunications:permits')}
            />
            <ButtonEdit onClick={() => handleEdit(data.data.find(item => item.id === data.cell.row.values.id))} />
            <ButtonDelete onClick={() => deleteCommunication(data.cell.row.values.id)} />
          </Stack>
        ),
    },
    {
      Header: permisosUser || permisosUserCountry ? t('common:outst') : '',
      accessor: 'e',
      Cell: ({ cell }) =>
        (permisosUser || permisosUserCountry) && (
          <Stack key={cell.row.values.id} isInline spacing='4px'>
            <Switch
              style={{ padding: '0.5rem' }}
              defaultIsChecked={cell.row.original.destacado}
              onChange={e => newsDestacada(cell.row.original)}
            ></Switch>
          </Stack>
        ),
    } 
  ]
  if (typeSlug === 'geaHome') {
    columns = columns.filter((column, index) => index !== columns.length -  1)
  }

  return columns
}

  
  const returnTypeObject = (typeSlug = '') => {
    switch (typeSlug) {
      case 'news':
        return {
          id: 1,
          slug: typeSlug,
          title: t('common:navbar.news'),
          label: t('comunications:createNews'),
          edit: `${t('comunications:save')} ${t('comunications:new')}`,
          columns: withImgPreviewColumns,
        }
      case 'events':
        return {
          id: 2,
          slug: typeSlug,
          title: t('common:navbar.events'),
          label: t('comunications:createEvent'),
          edit: `${t('comunications:save')} ${t('comunications:event')}`,
          columns: standardColumns,
        }
      case 'geaHome':
        return {
          id: 3,
          slug: typeSlug,
          title: t('common:navbar.geaHome'),
          label: t('comunications:createVideo'),
          edit: `${t('comunications:save')} ${t('comunications:new')}`,
          columns: withImgPreviewColumns,
        }
      case 'capacitations':
        return {
          id: 4,
          slug: typeSlug,
          title: t('comunications:Capacitation'),
          label: t('comunications:createCapacitation'),
          edit: `${t('comunications:save')} ${t('comunications:cap')}`,
          columns: standardColumns,
        }
    }
  }

  const toast = useToast()
  let location = useLocation()
  let history = useHistory()
  const typeSlug = location.search.replace(/^./, '')
  const type = returnTypeObject(typeSlug)

  const createCategoriesDisclosure = useDisclosure()
  const createComunicationDisclosure = useDisclosure()
  const createNewsDisclosure = useDisclosure()
  const imageViewDisclosure = useDisclosure()
  const editPermissionsDisclosure = useDisclosure()

  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [categorySelected, setCategorySelected] = useState('')
  const [subtituloSearch, setSubtituloSearch] = useState('')
  const [focusedInput, setFocusedInput] = useState({
    focusedInput: null,
  })

  const [{ startDate, endDate }, setRangeDate] = useState({
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    getCommunications()
  }, [typeSlug, page])

  useEffect(() => {
    obtenerCategoriasFunc()
  }, [typeSlug])

  useEffect(() => {
    cleanFilters()
  }, [typeSlug])

  const setRangePicker = ({ startDate, endDate }) => {
    setRangeDate({ startDate, endDate })
  }

  const handleSubmit = () => {
    page !== 1 ? setPage(1) : getCommunications()
  }

  const cleanFilters = () => {
    setCategorySelected('')
    setSubtituloSearch('')
    setCountryAdmin(0)
    setPage(1)
    setRangeDate({
      startDate: null,
      endDate: null,
    })
    setCleanedFilters(true)
    setRecordToEdit(undefined)
  }

  useEffect(() => {
    if (cleanedFilters) {
      getCommunications()
    }
  }, [cleanedFilters])

  const setFocusedPicker = focusedInput => {
    setFocusedInput(focusedInput)
  }

  const obtenerCategoriasFunc = async () => {
    try {
      const searchCountry = permisosUser ? '' : userCountryId
      const res = await obtenerCategorias(searchCountry, authToken)
      const filterSplit = res?.data?.map(x => {
        const category = x.descripcion.split('/')
        return { value: x.id, label: category[0], type: category[1] }
      })
      const filterCategories = filterSplit.filter(category => {
        if (category.type === type.slug) {
          return category
        }
      })
      setCategories(filterCategories)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteCommunication = async id => {
    try {
      await deleteComunicado(id, authToken)
      getCommunications()
      cleanFilters()
      toast(toastCustomizedSuccess(t('forms:actionCompleted')))
    } catch (err) {
      console.log(err)
    }
  }

  const openNewsWindow = id => {
    history.push(`/newsGEA?${id}?${type.slug}`)
  }

  const columns = React.useMemo(type.columns, [t, typeSlug, countries])

  const getCommunications = async () => {
    try {
      const data = {
        tipo: typeSlug,
        page,
        maxPerPage,
        startDate: startDate ? moment(startDate).startOf('d').toDate() : null,
        endDate: endDate ? moment(endDate).endOf('d').toDate() : null,
        categoriaComunicadoId: categorySelected,
        paisId: permisosUser ? countryAdmin : userCountryId,
        subtitulo: subtituloSearch,
        rolId: userRolId,
      }
      const res = await obtenerComunicadosFiltered(authToken, data)
      setTotalPages(res.data.totalPages)
      setCommunications(res.data.data)
      setIsLoading(false)
      setCleanedFilters(false)
    } catch (err) {
      console.log(err)
    }
  }

  async function permisoMaster(comunicadoId) {
    let data = {
      rolId: 1,
      comunicados: { id: comunicadoId },
    }
    try {
      const resp = await crearPermisosPorRol(data, authToken)
      if (isSuccess(resp.status)) {
        obtenerPermisos()
        return true
      } else {
        console.log('error')
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function permisoAdminCountry(comunicadoId) {
    let data = {
      rolId: 2,
      comunicados: { id: comunicadoId },
    }
    try {
      const resp = await crearPermisosPorRol(data, authToken)
      if (isSuccess(resp.status)) {
        obtenerPermisos()
        return true
      }
    } catch (err) {
      console.log(err)
    }
  }
  async function padre(e, edit) {
    try {
      if (edit) {
        const resp = await actualizarComunicados(edit, e, authToken)

        if (isSuccess(resp.status)) {
          toast(toastCustomizedSuccess(`${t('comunications:editSucess')}`))
          createComunicationDisclosure.onClose()
          createNewsDisclosure.onClose()
          await getCommunications()
          cleanFilters()
        } else {
          toast(toastCustomizedError(`${t('comunications:inputError')}`))
        }
      } else {
        const resp = await crearComunicados(e, authToken)
        if (isSuccess(resp.status)) {
          toast(toastCustomizedSuccess(`${t('comunications:newSucess')}`))
          permisoMaster(resp.data.id)
          permisoAdminCountry(resp.data.id)
          createComunicationDisclosure.onClose()
          createNewsDisclosure.onClose()
          await getCommunications()
        } else {
          toast(toastCustomizedError(`${t('comunications:inputError')}`))
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleEdit = record => {
    setRecordToEdit(record)
  }

  useEffect(() => {
    if (recordToEdit) {
      if (recordToEdit?.tipoComunicado?.id === 1) {
        createNewsDisclosure.onOpen()
      } else {
        createComunicationDisclosure.onOpen()
      }
    }
  }, [recordToEdit])

  const onCloseCommunicationsModal = () => {
    if (recordToEdit) {
      setRecordToEdit(undefined)
    }
    createComunicationDisclosure.onClose()
  }

  const onCloseCreateNewsModal = () => {
    if (recordToEdit) {
      setRecordToEdit(undefined)
    }
    createNewsDisclosure.onClose()
  }

  const onCloseCategoryModal = async () => {
    createCategoriesDisclosure.onClose()
    obtenerCategoriasFunc()
    await getCommunications()
  }

  function handleClickCreateComunication() {
    switch (type.slug) {
      case 'news':
        createNewsDisclosure.onOpen()
        break
      default:
        createComunicationDisclosure.onOpen()
        break
    }
  }

  return (
    <>
      {htmlContent ? (
        <Box py='20px' pb='0.5%' color='#103059'>
          <PageContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Heading style={{ fontSize: '2rem', color: '#103059', marginBottom: '2rem' }}>{titleHtml}</Heading>
              <Button variantColor='blue' variant='outline' size='md' onClick={() => setHtmlContent('')}>
                {t('comunications:getback')}
              </Button>
            </div>
            <Box style={{ backgroundColor: 'white', borderRadius: '10px' }}>
              <ReactQuill value={htmlContent} readOnly theme='bubble' />
            </Box>
          </PageContainer>
        </Box>
      ) : (
        <PageContainer>
          <Box backgroundColor='' py='16px' color='#103059'>
            <Heading style={{ fontSize: '1.8rem' }} mb={0}>
              {type.title}
            </Heading>
          </Box>
          <Can
            permission={COMUNICATIONS_CRUD}
            yes={() => (
              <Button mr={3} my={5} variantColor='blue' onClick={() => handleClickCreateComunication()} type='button'>
                {type.label}
              </Button>
            )}
          />
          <Can
            permission={COMUNICATIONS_CRUD}
            yes={() => (
              <Button my={5} variantColor='blue' onClick={() => createCategoriesDisclosure.onOpen()} type='button'>
                {t('comunications:createCategories')}
              </Button>
            )}
          />
          <Panel rounded='md' mb='1.5rem'>
            <Grid
              templateColumns={{
                base: 'repeat(1, 1fr)',
                md: `${
                  type.slug === 'news'
                    ? permisosUser
                      ? 'repeat(5, 1fr)'
                      : 'repeat(4, 1fr)'
                    : permisosUser
                    ? 'repeat(4, 1fr)'
                    : 'repeat(3, 1fr)'
                }`,
              }}
              gap={6}
            >
              <Box className='date-wrapper'>
                <DateRangePicker
                  isOutsideRange={() => false}
                  startDate={startDate} // momentPropTypes.momentObj or null,
                  startDateId='fecha_inicio' // PropTypes.string.isRequired,
                  endDate={endDate} // momentPropTypes.momentObj or null,
                  endDateId='fecha_fin' // PropTypes.string.isRequired,
                  onDatesChange={dates => setRangePicker(dates)} // PropTypes.func.isRequired,
                  focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={focusedInput => setFocusedPicker(focusedInput)} // PropTypes.func.isRequired,
                  block
                  small
                  orientation='horizontal'
                  displayFormat='DD-MM-YYYY'
                />
              </Box>

              <Box>
                <SelectAsync
                  onChange={e => setCategorySelected(e.target.value)}
                  placeholder={`${t('forms:categories')}`}
                  name={'categories'}
                  value={categorySelected}
                >
                  {categories.map((category, index) => (
                    <option key={index} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </SelectAsync>
              </Box>
              {type.slug === 'news' ? (
                <Box>
                  <FormInput
                    name='clue'
                    inputProps={{
                      placeholder: `${t('forms:name')} ${t('forms:link')} (${t('common:optional')})`,
                    }}
                    value={subtituloSearch}
                    onChange={e => setSubtituloSearch(e.target.value)}
                  />
                </Box>
              ) : (
                ''
              )}
              {permisosUser && countries && (
                <Box>
                  <Select
                    onChange={e => setCountryAdmin(e.target.value)}
                    placeholder={t('forms:countries')}
                    name='country'
                    value={countryAdmin}
                  >
                    {countries
                      .sort((a, b) => a.nombre.localeCompare(b.nombre))
                      .map(({ id, nombre }) => (
                        <option key={id} value={id}>
                          {nombre}
                        </option>
                      ))}
                  </Select>
                </Box>
              )}
              <Box>
                <Button
                  flex='2'
                  variant='outline'
                  variantColor='blue'
                  type='button'
                  mr={4}
                  onClick={() => handleSubmit()}
                >
                  {t('common:filter')}
                </Button>
                <Button
                  flex='2'
                  variant='outline'
                  variantColor='blue'
                  type='button'
                  onClick={() => {
                    cleanFilters()
                  }}
                >
                  {t('comunications:cleanFilter')}
                </Button>
              </Box>
            </Grid>
          </Panel>
          {!isLoading && communications?.length ? (
            <Table columns={columns} data={communications} />
          ) : (
            <Box bg='white' mb='20px'>
              {isLoading && (
                <Box px='5' py='10' textAlign='center'>
                  <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
                </Box>
              )}
              {!isLoading && !communications.length && (
                <Text textAlign='center' color='gray.500' px='5' py='10'>
                  {t('common:zeroItems')}
                </Text>
              )}
              {!isLoading && !communications && (
                <Text textAlign='center' color='gray.500' px='5' py='10'>
                  {t('common:errorGettingItems')}
                </Text>
              )}
            </Box>
          )}
          {!isLoading && totalPages > 0 && <Pagination currentPage={page} setPage={setPage} totalPages={totalPages} />}
        </PageContainer>
      )}
      <ModalSimple
        isOpen={createCategoriesDisclosure.isOpen}
        onClose={onCloseCategoryModal}
        title={t('comunications:createCategories')}
        size='3xl'
      >
        <CreateCategories communications={communications} countries={countries} />
      </ModalSimple>
      <ModalSimple
        isOpen={createComunicationDisclosure.isOpen}
        onClose={onCloseCommunicationsModal}
        title={type.label}
        size='4xl'
      >
        <CreateCommunications
          recordToEdit={recordToEdit}
          padre={padre}
          type={type}
          getCommunications={getCommunications}
        />
      </ModalSimple>
      <ModalSimple isOpen={createNewsDisclosure.isOpen} onClose={onCloseCreateNewsModal} title={type.label} size='4xl'>
        <CreateNews recordToEdit={recordToEdit} padre={padre} type={type} getCommunications={getCommunications} />
      </ModalSimple>
      <ModalSimple isOpen={imageViewDisclosure.isOpen} onClose={onCloseImageModal} size='3xl'>
        <Image
          style={{
            margin: '3rem auto 0 auto',
            borderRadius: '.25rem',
            height: 'auto',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
          src={selectedImg}
        />
      </ModalSimple>
      {/* Modal Permisos */}
      {rolls !== false ? (
        <ModalSimple isOpen={editPermissionsDisclosure.isOpen} title={`${t('comunications:permits')}`} size='xl'>
          <AccessEdition funcCheck={funcCheck} newPermission={newPermission}></AccessEdition>
          <div style={{ float: 'right', margin: '4% 0% 0%' }}>
            <Button
              style={{ height: '2.1rem', padding: '-2' }}
              onClick={() => {
                closeModalPermisos()
              }}
              variantColor='blue'
            >
              {t('comunications:close')}
            </Button>
          </div>
          <div style={{ position: 'absolute', top: '0rem', right: '0.1rem' }}>
            <Box style={{ backgroundColor: 'white', width: '3rem', height: '3rem', zIndex: '22' }}></Box>
          </div>
        </ModalSimple>
      ) : null}
    </>
  )
}

export default Calendar
