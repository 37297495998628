import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button, FormLabel, Grid, Stack, Text, NumberInput, Divider, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import SelectAsync from '../../../common/select-async'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import { getAllBaseTypes, getAllTaxes } from '../../../../api/intranet/requests'
import { useSelector } from 'react-redux'
import { getAuthToken, getUserCountryId } from '../../../../redux/selectors'
import { getCurrencies } from '../../../../api/application'
import Table from '../../../common/table'
import { toastCustomizedError } from '../../../common/toast-default-config'
import FormInput from '../../../forms/form-input'
import { MdBuild } from 'react-icons/md'

export const Prices = ({ onChange, departureData, onDelete, trigerSave, cancel }) => {
  const { t } = useTranslation(['common', 'forms', 'packages', 'bookings'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const [taxBase, setTaxBase] = useState(0)
  const [amount, setAmount] = useState(0)
  const [price, setPrice] = useState(0)
  const [enableAddPrice, setEnableAddPrice] = useState(false)
  const toast = useToast()
  const [taxFree, setTaxFree] = useState(0)
  const [taxName, setTaxName] = useState('')
  const [availableTaxes, setAvailableTaxes] = useState()
  const [originalData, setOriginalData] = useState()
  const [idEdit, setIdEdit] = useState(-1)
  const [readOnly, setReadOnly] = useState(false)

  const [editPrice, setEditPrice] = useState({
    baseType: { id: undefined },
    currency: { id: undefined },
    amount: '',
    taxes: [],
    taxBase,
    taxFree: 0,
    taxName,
  })

  const [baseTypes, setBaseTypes] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getBaseTypes() {
    setBaseTypes({ ...baseTypes, isLoading: true })
    try {
      const { data } = await getAllBaseTypes(authToken)
      setBaseTypes({ ...baseTypes, isLoading: false, items: data.data || [] })
    } catch {
      setBaseTypes({ ...baseTypes, isLoading: false, error: true })
    }
  }
  const filteredBaseTypes = {
    items: baseTypes.items.filter(
      i => !departureData?.precioInformacion?.map(precio => precio?.baseType?.id).includes(i.id),
    ),
  }
  const [currencies, setCurrencies] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  async function getAllCurrencies() {
    setCurrencies({ ...currencies, isLoading: true })
    try {
      const { data } = await getCurrencies(authToken)
      setCurrencies({ ...currencies, isLoading: false, items: data || [] })
    } catch (error) {
      setCurrencies({ ...currencies, isLoading: false, error: true })
    }
  }
  const [taxes, setTaxes] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  const [selectedTax, setSelectedTax] = useState()
  async function getTaxes() {
    setTaxes({ ...taxes, isLoading: true })
    try {
      const { data } = await getAllTaxes({ countryId, pageSize: 500 }, authToken)
      setTaxes({ ...taxes, isLoading: false, items: data.data || [] })
      setAvailableTaxes(data.data)
    } catch (error) {
      setTaxes({ ...taxes, isLoading: false, error: true })
    }
  }
  useEffect(() => {
    getBaseTypes()
    getAllCurrencies()
    getTaxes()
  }, [])

  function onChangeSelectedBaseType(target) {
    setEditPrice({
      ...editPrice,
      baseTypeId: Number(target.value),
      baseType: baseTypes.items.find(b => b.id.toString() === target.value.toString()),
    })
  }

  function onChangeSelectedCurrency(target) {
    setEditPrice({
      ...editPrice,
      currencyId: Number(target.value),
      currency: currencies.items.find(c => c.id.toString() === target.value.toString()),
    })
  }

  function onChangeSelectedTax(target) {
    setSelectedTax({
      taxId: Number(target.value),
      tax: taxes.items.find(t => t.id.toString() === target.value.toString()),
    })
    setAmount(0)
  }

  function onChangePrice(value) {
    if (value <= 9999999999999) {
      setEditPrice({
        ...editPrice,
        amount: value,
      })
      setPrice(value)
    }
  }

  function onChangeTaxFree(value) {
    if (value <= 9999999999999) {
      setEditPrice({
        ...editPrice,
        taxFree: value,
      })
      setTaxFree(value)
    }
  }

  function onChangeTaxBase(value) {
    if (value <= 9999999999999) {
      setEditPrice({
        ...editPrice,
        taxBase: value,
      })
      setTaxBase(value)
    }
  }

  function onChangeTaxName(e) {
    setEditPrice({
      ...editPrice,
      taxName: e.target.value,
    })
    setTaxName(e.target.value)
  }

  function handleAddPrice() {
    onChange(editPrice, 'precioInformacion', idEdit)
    setAvailableTaxes(taxes.items)
    cleanForm()
  }

  function onPriceEditClick(index, data) {
    setEditPrice({ ...data })
    setIdEdit(index)
    setPrice(data.amount)
    setTaxBase(data.taxBase)
    setTaxName(data.taxName)
    setTaxFree(data.taxFree)
    if (departureData.precioInformacion[index].taxes.length > 0) {
      setAvailableTaxes(
        taxes.items.filter(tax => {
          return !departureData.precioInformacion[index].taxes.some(existingTax => existingTax.tax.id === tax.id)
        }),
      )
    }
    setReadOnly(data?.id ? false : true)
  }

  function cleanForm() {
    setEditPrice({
      baseType: { id: undefined },
      currency: { id: undefined },
      amount: '',
      taxes: [],
    })
    setIdEdit(-1)
    setAmount(0)
    setTaxBase(0)
    setPrice(0)
    setTaxFree(0)
    setTaxName('')
    setReadOnly(false)
    filteredBaseTypes.items = baseTypes.items
    setAvailableTaxes(taxes.items)
  }

  function handleAddTax() {
    setEditPrice({ ...editPrice, taxes: editPrice.taxes.concat({ tax: selectedTax.tax }) })
    setAvailableTaxes(availableTaxes.filter(tax => tax.id !== selectedTax.taxId))
    setSelectedTax(null)
    setAmount(0)
  }

  function onDeleteTaxDetail(idx) {
    const copy = editPrice.taxes
    copy.splice(idx, 1)
    setEditPrice({ ...editPrice, taxes: copy })
    setAvailableTaxes(
      taxes.items.filter(tax => {
        return !editPrice.taxes.some(existingTax => existingTax.tax.id === tax.id)
      }),
    )
  }

  const columnsPricesTable = useMemo(() => [
    {
      Header: t('packages:rate'),
      accessor: row => {
        return row?.baseType?.description
      },
    },
    {
      Header: t('forms:currency'),
      accessor: row => {
        return row?.currency?.codigo
      },
    },
    {
      Header: t('bookings:price'),
      accessor: 'amount',
    },
    {
      Header: t('forms:taxFree'),
      accessor: 'taxFree',
      accessor: row => {
        return row?.taxFree ? row?.taxFree : 0
      },
    },
    {
      Header: t('packages:taxBase'),
      accessor: row => {
        return row?.taxBase
      },
    },
    {
      Header: t('forms:taxes'),
      accessor: row => {
        let taxTotal = 0
        const t = row?.taxes?.forEach(precioInformacionTax => {
          switch (precioInformacionTax.tax?.taxType) {
            case 'FIXED_AMOUNT':
              taxTotal += precioInformacionTax.tax?.taxAmount
              break
            case 'PORCENTAGE': {
              if (row.taxBase) {
                taxTotal += (row.taxBase * precioInformacionTax.tax?.taxAmount) / 100
              } else {
                taxTotal += (row.amount * precioInformacionTax.tax?.taxAmount) / 100
              }
            }
            default:
              {
              }
              break
          }
        })
        return +taxTotal.toFixed(2)
      },
    },
    {
      Header: t('packages:totalAmount'),
      accessor: null,
      Cell: ({ cell }) => {
        return (
          +(cell.row.values.amount +
          cell.row.values[t('forms:taxes')] +
          cell.row.values[t('forms:taxFree')]).toFixed(2)
        )
      },
    },
    {
      Header: t('common:actions'),
      accessor: null,
      Cell: ({ cell }) => {
        return (
          <Stack align={'center'} spacing='5px' isInline d='flex' mx='1'>
            <ButtonEdit
              icon={cell.row.original.id ? MdBuild : undefined}
              customLabel={cell.row.original.id ? false : t('packages:seePreview')}
              onClick={() => {
                return onPriceEditClick(cell.row.index, cell.row.original)
              }}
            />
            <ButtonDelete
              onClick={() => {
                if (idEdit === -1 || readOnly) {
                  cleanForm()
                  onDelete(cell.row.original.baseType.id, 'precioInformacion')
                  setAvailableTaxes(
                    taxes.items.filter(tax => {
                      return !editPrice.taxes.some(existingTax => existingTax.tax.id === tax.id)
                    }),
                  )
                } else {
                  toast(toastCustomizedError(t('packages:notDelete')))
                }
              }}
            />
          </Stack>
        )
      },
    },
  ])

  const columnsTaxesTable = useMemo(
    () => [
      {
        Header: t('packages:taxName'),
        accessor: row => {
          return row?.tax?.taxName
        },
      },
      {
        Header: t('packages:taxType'),
        accessor: row => {
          return t(`packages:${row?.tax?.taxType}`)
        },
      },
      {
        Header: t('forms:value'),
        accessor: row => {
          return row?.tax?.taxAmount
        },
      },
      {
        Header: t('packages:taxAmount'),
        accessor: row => {
          switch (row?.tax?.taxType) {
            case 'PORCENTAGE': {
              if (editPrice.taxBase) {
                return +((editPrice.taxBase * row?.tax?.taxAmount) / 100).toFixed(2)
              } else {
                return +((editPrice.amount * row?.tax?.taxAmount) / 100).toFixed(2)
              }
            }
            case 'FIXED_AMOUNT':
              return +(row.tax?.taxAmount).toFixed(2)
          }
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => {
          return (
            <Stack align={'center'} spacing='5px' isInline d='flex' mx='1'>
              <ButtonDelete isDisabled={readOnly} onClick={() => onDeleteTaxDetail(cell.row.index, cell.row.original)} />
            </Stack>
          )
        },
      },
    ],
    [editPrice.amount, editPrice.taxes.length, readOnly],
  )

  useEffect(() => {
    if (!originalData && !!departureData.precioInformacion.length) {
      setOriginalData(JSON.parse(JSON.stringify(departureData)))
    }
  }, [departureData])

  useEffect(() => {
    if (
      editPrice.baseType?.id &&
      editPrice.currency?.id &&
      price &&
      !(selectedTax?.taxId && selectedTax?.tax?.taxType)
    ) {
      setEnableAddPrice(true)
    } else if (editPrice.baseType?.id && editPrice.currency?.id && editPrice.amount && editPrice.taxes.length) {
      setEnableAddPrice(true)
    } else {
      setEnableAddPrice(false)
    }
  }, [editPrice, price, amount, selectedTax?.taxId])

  useEffect(() => {
    cleanForm()
  }, [trigerSave, cancel])

  return (
    <Box mt='3'>
      <>
        {!!departureData?.precioInformacion?.length && (
          <Box mb='20px' mt='20px'>
            <Table columns={columnsPricesTable} data={departureData?.precioInformacion} />
          </Box>
        )}
        {!departureData?.precioInformacion?.length && (
          <Box bg='white' mb='20px' mt='8'>
            <Text textAlign={'center'} color='gray.500' px='5' py='5'>
              {t('common:zeroItems')}
            </Text>
          </Box>
        )}

        <Divider />
        <Text fontWeight='bold' style={{ paddingBottom: '10px', paddingTop: '10px' }}>
          {t('forms:prices')}
        </Text>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}>
          {/* Tipo de tarifas */}
          <Box>
            <FormLabel isRequired mr='1'>
              {t('packages:rate')}
            </FormLabel>
            <SelectAsync
              onChange={({ target }) => onChangeSelectedBaseType(target)}
              placeholder={`${t('forms:selectOption')}`}
              value={editPrice?.baseType?.id | undefined}
              isRequired={true}
              isDisabled={readOnly}
            >
              {idEdit < 0 &&
                filteredBaseTypes.items &&
                filteredBaseTypes.items?.map(b => (
                  <option key={b.id} value={b.id}>
                    {b.description}
                  </option>
                ))}
              {idEdit > -1 &&
                baseTypes.items &&
                baseTypes.items?.map(b => (
                  <option key={b.id} value={b.id}>
                    {b.description}
                  </option>
                ))}
            </SelectAsync>
          </Box>
          <Box ml='12px'>
            <FormLabel isRequired>{t('forms:currency')}</FormLabel>
            <SelectAsync
              onChange={({ target }) => onChangeSelectedCurrency(target)}
              placeholder={`${t('forms:selectOption')}`}
              value={editPrice?.currency?.id | undefined}
              isRequired
              isDisabled={readOnly}
            >
              {currencies.items &&
                currencies.items?.map(c => (
                  <option value={c.id} key={c.id}>
                    {c.codigo}
                  </option>
                ))}
            </SelectAsync>
          </Box>
          <Box ml='12px'>
            <Box alignItems='center' mb='4'>
              <FormLabel isRequired mr='1'>
                {t('forms:price')}
              </FormLabel>
              <NumberInput
                className='noArrows'
                precision={2}
                max={9999999999999}
                name='price'
                placeholder={t('forms:price')}
                onChange={e => {
                  onChangePrice(e)
                }}
                value={price}
                isDisabled={readOnly}
              />
            </Box>
          </Box>
        </Grid>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}>
          <Box alignItems='center'>
            <FormLabel mr='1'>{t('forms:name')}</FormLabel>
            <FormInput
              name='taxName'
              onChange={e => onChangeTaxName(e)}
              value={taxName}
              isDisabled={readOnly}
              inputProps={{
                type: 'text',
                length: '20',
              }}
            />
          </Box>
          <Box alignItems='center' mb='4' ml='12px'>
            <FormLabel mr='1'>{t('forms:taxFree')}</FormLabel>
            <NumberInput
              className='noArrows'
              precision={2}
              max={9999999999999}
              name='taxFree'
              placeholder={t('forms:taxFree')}
              onChange={e => {
                onChangeTaxFree(e)
              }}
              value={taxFree}
              isDisabled={readOnly}
            />
          </Box>
        </Grid>
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(5, 1fr)' }}>
          <Box>
            <Box alignItems='center' mb='4'>
              <FormLabel mr='1'>{t('packages:taxBase')}</FormLabel>
              <NumberInput
                className='noArrows'
                precision={2}
                max={9999999999999}
                name='taxBase'
                placeholder={t('packages:taxBase')}
                onChange={e => {
                  onChangeTaxBase(e)
                }}
                value={taxBase}
                isDisabled={readOnly}
              />
            </Box>
          </Box>
          <Box ml='12px'>
            <FormLabel>{t('bookings:takes')}</FormLabel>
            <SelectAsync
              onChange={({ target }) => onChangeSelectedTax(target)}
              placeholder={`${t('forms:selectOption')}`}
              value={selectedTax?.taxId | undefined}
              isDisabled={readOnly}
            >
              {taxes.items &&
                availableTaxes?.map(tax => (
                  <option value={tax.id} key={tax.id}>
                    {tax.taxName}
                  </option>
                ))}
            </SelectAsync>
          </Box>
          <Box pt={'29px'} ml='12px'>
            <Button
              variantColor='blue'
              variant='outline'
              size='md'
              onClick={() => handleAddTax()}
              isDisabled={!selectedTax?.taxId || readOnly}
            >
              {t('packages:addTax')}
            </Button>
          </Box>
        </Grid>
        {/* TABLA DE IMPUESTOS */}
        {!!editPrice?.taxes?.length && <Table columns={columnsTaxesTable} data={editPrice?.taxes} />}

        {!editPrice?.taxes?.length && (
          <Box bg='white' mb='20px' mt='20px'>
            <Text textAlign={'center'} color='gray.500' px='5' py='5'>
              {t('packages:zeroTaxes')}
            </Text>
          </Box>
        )}

        <Divider />
        <Box style={{ display: 'flex', gap: '1rem' }} mt='20px' mb='20px'>
          <Button
            variantColor='blue'
            variant='outline'
            size='md'
            onClick={() => handleAddPrice()}
            isDisabled={!enableAddPrice || readOnly}
          >
            {idEdit === -1 ? t('common:addPrice') : t('common:editPrice')}
          </Button>
          <Button
            variantColor='red'
            variant='outline'
            onClick={() => {
              cleanForm()
            }}
          >
            {t('forms:discard')}
          </Button>
        </Box>
      </>
      {idEdit !== -1 && !readOnly && (
        <>
          <span style={{ color: 'red', fontSize: '0.8rem' }}>*</span>
          <span style={{ color: 'black', fontSize: '0.8rem' }}>{t('packages:confirmSave')}</span>
        </>
      )}
      <Divider />
    </Box>
  )
}
