import React, { useEffect, useState, useMemo } from 'react'
import { FormLabel, Text, Grid, Spinner, Tag, Box, Button, Stack } from '@chakra-ui/core'
import { GridItem } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAuthToken } from '../../../../../redux/selectors'
import FormInput from '../../../../forms/form-input'
import SelectAsync from '../../../../common/select-async'
import { getCityByCode, getAllCitiesQuery } from '../../../../../api/geo'
import { getCurrencies } from '../../../../../api/application'
import { Select } from 'antd'
import '../../../../styles/common/adjust-ant-styles.css'
import { isSuccess } from '../../../../../helpers/utils'
import { PublicationDates } from '../components/publication-dates'
import { Panel } from '../../../../common/helpers'
import { ButtonDelete } from '../../../../forms/buttons/edit'
import Table from '../../../../common/table'

export const GeneralInformation = ({
  onChangeBasicData,
  onChangeBasicDatanDestinations,
  state = false,
  setState,
  packageId,
  selectedDestinations,
  onchangeDates,
}) => {
  const { t } = useTranslation(['common', 'forms', 'packages'])
  const { register, control } = useForm({
    defaultValues: {
      title: state.title,
      totalNights: state.totalNights,
    },
  })

  const { Option } = Select

  const authToken = useSelector(getAuthToken)

  //TRAER LOCALIDADES / DESTINOS
  const [cityOrigins, setCityOrigins] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  const [searchedDestinations, setSearchedDestinations] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  const [selectedUnaddedDestinations, setSelectedUnaddedDestinations] = useState([])
  const [cityDestinations, setCityDestinations] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  function onSelectedDestinationChange(selectedCities) {
    setSelectedUnaddedDestinations(selectedCities)
    setCityOrigins({
      isLoading: false,
      items: [],
      error: false,
    })
  }

  function onSelectedOriginChange(selectedOrigin) {
    onChangeBasicData({ name: 'originCode', value: selectedOrigin })
    setSearchedDestinations({
      isLoading: false,
      items: [],
      error: false,
    })
  }

  function addDestinations() {
    onChangeBasicDatanDestinations(selectedDestinations.concat(selectedUnaddedDestinations))
    setSelectedUnaddedDestinations([])
    setSearchedDestinations({
      isLoading: false,
      items: [],
      error: false,
    })
  }

  function removeDestination(nombre) {
    onChangeBasicDatanDestinations(selectedDestinations.filter(destination => destination !== nombre))
  }

  function onchangeDates({ dateSince, dateUntil }) {
    setState(state => ({
      ...state,
      dateSince,
      dateUntil,
    }))
  }

  async function getCityOrigins(city) {
    setCityOrigins(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const citiesToExclude = []
      if (state.originCode) {
        citiesToExclude.push(state.originCode)
      }
      if (selectedDestinations?.length) {
        citiesToExclude.push(...selectedDestinations)
      }
      if (selectedUnaddedDestinations?.length) {
        citiesToExclude.push(...selectedUnaddedDestinations)
      }
      const res = await getAllCitiesQuery({
        search: city,
        origins: true,
        exclude: citiesToExclude?.length ? citiesToExclude : null,
      })
      setCityOrigins(() => ({
        error: false,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setCityOrigins(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }

  async function getCitiesDestinationByCode(cityCodes) {
    setSettingPrevDestinations(true)
    setCityDestinations(state => ({
      ...state,
      items: [],
      isLoading: true,
    }))
    try {
      const destinationsAux = await Promise.all(
        cityCodes.map(async selectedDestination => {
          const response = await getCityByCode(selectedDestination, authToken)
          if (isSuccess(response.status)) {
            return response.data[0]
          }
        }),
      )
      setCityDestinations(() => ({
        error: false,
        isLoading: false,
        items: destinationsAux || [],
      }))
    } catch (error) {
      setCityDestinations(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
    setSettingPrevDestinations(false)
  }

  async function getCityOriginByCode(cityCode) {
    setSettingPrevOrigins(true)
    setCityOrigins(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getCityByCode(cityCode, authToken)
      if (isSuccess(res.status)) {
        setCityOrigins(() => ({
          error: false,
          isLoading: false,
          items: res.data || [],
        }))
      }
    } catch (error) {
      setCityOrigins(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
    setSettingPrevOrigins(false)
  }

  const [settingPrevDestinations, setSettingPrevDestinations] = useState(false)
  const [settingPrevOrigins, setSettingPrevOrigins] = useState(false)

  useEffect(() => {
    getCitiesDestinationByCode(selectedDestinations)
  }, [selectedDestinations])

  useEffect(() => {
    if (!packageId) {
      setSettingPrevDestinations(false)
      setSettingPrevOrigins(false)
    } else {
      getCityOriginByCode(state.originCode)
    }
  }, [packageId])

  async function getCityDestinations(city) {
    setSearchedDestinations(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const citiesToExclude = []
      if (state.originCode) {
        citiesToExclude.push(state.originCode)
      }
      if (selectedDestinations?.length) {
        citiesToExclude.push(...selectedDestinations)
      }
      if (selectedUnaddedDestinations?.length) {
        citiesToExclude.push(...selectedUnaddedDestinations)
      }
      const res = await getAllCitiesQuery({
        search: city,
        destinations: true,
        exclude: citiesToExclude?.length ? citiesToExclude : null,
      })
      setSearchedDestinations(() => ({
        error: false,
        isLoading: false,
        items: res.data || [],
      }))
    } catch (error) {
      setSearchedDestinations(() => ({
        items: [],
        isLoading: false,
        error: true,
      }))
    }
  }

  function handleSearchOrigins(value) {
    if (value.length > 2) {
      getCityOrigins(value)
    } else {
      setCityOrigins({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  function handleSearchDestinations(value) {
    if (value.length > 2) {
      getCityDestinations(value)
    } else {
      setSearchedDestinations({
        isLoading: false,
        items: [],
        error: false,
      })
    }
  }

  // - - - - - CURRENCY SECTION - - - - -

  const [currencies, setCurrencies] = useState([])
  async function getAllCurrencies() {
    try {
      const responses = await getCurrencies(authToken)
      setCurrencies(responses.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getAllCurrencies()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: t('packages:destinations'),
        accessor: row => {
          let cellValue = `${row.nombre} - (${row.provincia.nombre} | ${row.provincia.pais.nombre || ''})`
          if (cellValue.length > 100) {
            cellValue = `${cellValue.substring(0, 100)}...`
          }
          return cellValue
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => {
          return (
             <ButtonDelete onClick={() => removeDestination(cell.row.original.nombre)} />
          )
        }
      }
    ],
    [selectedDestinations],
  )

  return (
    <Panel mb='8' height='500px'>
      <Tag width='100%' mb='3' p='3' bg='blue.500' color='white'>
        {t('packages:generalInformation')} - {t('packages:requiredData')}
      </Tag>

      <Grid
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
        templateRows={'repeat(4, 1fr)'}
        gap='0.5rem 1rem'
      >
        {/* Titulo */}
        <GridItem colSpan={1}>
          <FormLabel isRequired mr='1'>
            {t('forms:title')}
          </FormLabel>
          <FormInput
            name='title'
            isRequired
            value={state.title || undefined}
            maxLength={100}
            inputProps={{
              type: 'text',
              placeholder: `${t('forms:title')}`,
              ref: register,
            }}
            onChange={({ target }) => onChangeBasicData(target)}
          />
        </GridItem>
        {/* Destinos */}
        <GridItem colSpan={1} rowSpan={4}>
          <FormLabel isRequired>{t('packages:destinations')}</FormLabel>
          <Grid templateColumns={'repeat(5, 1fr)'} gap='0.5rem' paddingBottom={5}>
            <GridItem colSpan={4}>
              {settingPrevDestinations ? (
                <Spinner display='block' />
              ) : (
                <Select
                  style={{ display: 'block' }}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  mode='multiple'
                  id='destinationCode'
                  name='destinationCode'
                  showSearch
                  onChange={change => onSelectedDestinationChange(change)}
                  placeholder='Buscar'
                  value={selectedUnaddedDestinations}
                  filterOption={false}
                  onSearch={handleSearchDestinations}
                  notFoundContent={null}
                  status={!cityDestinations?.items?.length ? 'error' : null}
                >
                  {searchedDestinations.items?.map(({ id, nombre, provincia }) => (
                    <Option key={id} value={nombre}>
                      {`${nombre} - (${provincia.nombre} | ${provincia.pais.nombre || ''})`}
                    </Option>
                  ))}
                </Select>
              )}
            </GridItem>
            <GridItem colSpan={1}>
              <Button variantColor='blue' variant='outline' onClick={() => addDestinations()}>
                Agregar
              </Button>
            </GridItem>
          </Grid>
          <Box display='grid' height='300px'>
            <Table columns={columns} data={cityDestinations.items} />
          </Box>
        </GridItem>
        <PublicationDates packageId={packageId} onchangeDates={onchangeDates} state={state} />

        <GridItem colSpan={1}>
          <Grid templateColumns={'repeat(3, 1fr)'} gap={6}>
            {/* Cantidad de noches */}
            <GridItem colSpan={1}>
              <FormLabel isRequired mr='1'>
                {t('forms:totalNights')}
              </FormLabel>
              <FormInput
                name='totalNights'
                value={state.totalNights ? state.totalNights : ''}
                isRequired
                inputProps={{
                  type: 'number',
                  placeholder: t('forms:totalNights'),
                  ref: register,
                  min: '1',
                  max: '100',
                }}
                onChange={({ target }) => onChangeBasicData(target)}
              />
            </GridItem>
            {/* Edad máxima de menores */}
            <GridItem colSpan={1}>
              <FormLabel isRequired mr='1'>
                {t('forms:maxChildAge')}
              </FormLabel>
              <FormInput
                name='maxChildAge'
                isRequired
                value={state.maxChildAge ? state.maxChildAge : ''}
                inputProps={{
                  type: 'number',
                  placeholder: `${t('forms:maxChildAge')}`,
                  ref: register,
                  min: '1',
                  max: '100',
                }}
                onChange={({ target }) => onChangeBasicData(target)}
              />
            </GridItem>
            {/*Moneda*/}
            <GridItem colSpan={1} alignContent={'end'} display='grid'>
              <FormLabel isRequired>{t('forms:currency')}</FormLabel>
              <Controller
                isRequired
                control={control}
                name='currency_id'
                render={({ value, name }) => (
                  <SelectAsync
                    onChange={({ target }) => onChangeBasicData(target)}
                    placeholder={`${t('forms:selectOne')} ${t('forms:currency')}`}
                    name={name}
                    value={state.currency_id ? state.currency_id : value}
                    isRequired
                  >
                    {currencies.map((x, key) => (
                      <option value={x.id} key={key}>
                        {x.codigo}
                      </option>
                    ))}
                  </SelectAsync>
                )}
              />
            </GridItem>
          </Grid>
        </GridItem>
        {/* Origen */}
        <GridItem colSpan={1}>
          <FormLabel isRequired>{t('forms:origin')}</FormLabel>
          {settingPrevOrigins ? (
            <Spinner display='block' />
          ) : (
            <Select
              style={{ display: 'block' }}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              id='originCode'
              name='originCode'
              showSearch
              onChange={value => {
                onSelectedOriginChange(value)
              }}
              placeholder='Buscar'
              allowClear
              value={state.originCode || undefined}
              filterOption={false}
              onSearch={handleSearchOrigins}
              notFoundContent={null}
            >
              {cityOrigins.items?.map(({ id, nombre, provincia }) => (
                <Option key={id} value={nombre}>
                  {`${nombre} - (${provincia.nombre} | ${provincia.pais.nombre || ''})`}
                </Option>
              ))}
            </Select>
          )}
        </GridItem>
      </Grid>
    </Panel>
  )
}
