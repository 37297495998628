import { Box, Button, Flex, Grid, Select, useToast } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS } from './localidad-section-data'
import FormInput from '../../forms/form-input'
import { getAllCountries } from '../../../api/application'
import { toastCustomizedError } from '../../common/toast-default-config'
import SelectAsync from '../../common/select-async'
import { getProvinciasByPaisId } from '../../../api/geo'

export default function LocalidadSearchForm({ onFilter, ...restProps }) {
  const { filters, handleFilters, handleResetFilters } = useSectionData()
  const { t } = useTranslation(['common', 'forms', 'consultations', 'comunications'])
  const toast = useToast()
  const [paisId, setPaisId] = useState('')
  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
  })
  const [province, setProvince] = useState({
    data: [],
    isLoading: false,
  })

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries()
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const getProvincias = async id => {
    setProvince(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getProvinciasByPaisId({ paisId: id })
      setProvince(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setProvince(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  const cleanFilter = () => {
    setProvince(prevState => ({
      ...prevState,
      data: [],
    }))
  }

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Flex  style={{gap: '1rem' }} {...restProps}>
      <Box flex='1'>
        <SelectAsync
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.paisId, target.value)
            getProvincias(target.value)
          }}
          placeholder={`${t('forms:countries')} - ${t('forms:all')}`}
          value={filters.paisId}
          isLoading={countries.isLoading}
        >
          {countries.data
            .sort((a, b) => a.nombre.localeCompare(b.nombre))
            .map(({ id, nombre }) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
        </SelectAsync>
      </Box>
      <Box flex='1'>
        <SelectAsync
          onChange={({ target }) => {
            handleFilters(FILTER_KEYS.provinciaId, target.value)
          }}
          placeholder={`${t('forms:province')} - ${t('forms:all')}`}
          value={filters.provinciaId}
          isLoading={province.isLoading}
        >
          {province.data
            .sort((a, b) => a.nombre.localeCompare(b.nombre))
            .map(({ id, nombre }) => (
              <option key={id} value={id}>
                {nombre}
              </option>
            ))}
        </SelectAsync>
      </Box>
      <Box flex='1'>
      <FormInput
        onChange={e => handleFilters(FILTER_KEYS.nombre, e.target.value)}
        value={filters.nombre}
        name='nombre'
        inputProps={{
          placeholder: `${t('forms:location')} (${t('common:optional')})`,
        }}
      />
      </Box>

      <Box style={{display: 'flex', gap: '1rem' }} flex='0.7'>
        <Button maxW={{ base: '100%', md: '120px' }} variantColor='blue' variant='outline' onClick={onFilter}>
          {t('common:filter')}
        </Button>

        <Button
          variantColor='blue'
          variant='outline'
          onClick={() => {
            handleResetFilters()
            cleanFilter()
          }}
        >
          {t('comunications:cleanFilter')}
        </Button>
      </Box>
    </Flex>
  )
}
