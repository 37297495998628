import React, { useEffect, useState } from 'react'
import { obtenerCategorias, crearCategoria, actualizarCategoria, deleteCategoria } from '../../../api/communications'
import { getAuthToken, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import { useSelector } from 'react-redux'
import { Box, Button, Text } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { Accordion, AccordionItem, AccordionHeader, AccordionPanel, Icon, Spinner } from '@chakra-ui/core'
import { useLocation } from 'react-router-dom'
import SelectAsync from '../../common/select-async'

const CreateCategories = ({ communications, countries }) => {
  let location = useLocation()
  const slug = location.search.replace(/^./, '')
  const { t } = useTranslation(['comunications, forms'])
  const authToken = useSelector(getAuthToken)
  const userCountryId = useSelector(getUserCountryId)
  const userRolId = useSelector(getUserRolId)
  const permisosUserCountry = userRolId == 2 ? true : false
  const [categories, setCategories] = useState(false)
  const [countryEdit, setCountryEdit] = useState(false)
  const [descriptionEdit, setDescriptionEdit] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [country, setCountry] = useState()
  const [descripcion, setDescripcion] = useState(null)
  const [descripcionEdit, setDescripcionEdit] = useState(null)
  const [editCountry, setEditCountry] = useState(null)
  
  const clearForm = () => {
    setDescripcion('')
    setCountry(permisosUserCountry ? userCountryId : '')
    setDescriptionEdit(false)
  }

  async function getCategorias() {
    try {
      const resp = await obtenerCategorias('', authToken)
      let filterDate = resp.data.filter(x => x.fechaBaja === null)
      let filterSplit = filterDate.filter(x => x.descripcion.split('/')[1] === slug)

      if (permisosUserCountry) {
        let filterCountry = filterSplit.filter(c => c.paisId === userCountryId)
        setCategories(filterCountry)
      } else {
        setCategories(filterSplit)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function createCategory() {
    let data = {
      color: '#919191',
      descripcion: descripcion + '/' + slug,
      paisId: permisosUserCountry ? parseInt(userCountryId) : parseInt(country),
      activo: true,
    }
    try {
      setCategories(false)
      await crearCategoria(data, authToken)
      clearForm()
      getCategorias()
    } catch (error) {
      console.log(error)
    }
  }

  async function editCategory(id, paisId, description) {
    if (expanded) {
      setCountryEdit(false)
      setDescriptionEdit(false)
    }

    let data = {
      color: '',
      descripcion: descriptionEdit ? descripcionEdit + '/' + slug : description + '/' + slug,
      paisId: editCountry ? parseInt(editCountry) : parseInt(paisId),
      activo: true,
    }
    try {
      setCategories(false)
      await actualizarCategoria(id, data, authToken)
      clearForm()
      getCategorias()
    } catch (error) {
      console.log(error)
    }
  }

  async function deleteCategory(id) {
    try {
      await deleteCategoria(id, authToken)
      getCategorias()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCategorias()
  }, [])

  return (
    <form>
      <Box
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr .5fr .2fr',
          alignItems: 'center',
          gap: '.6rem',
          marginBottom: '1rem',
        }}
      >
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <label htmlFor='category-name'>{t('forms:name')}</label>
          <input
            style={{
              flex: 1,
              border: '1px solid black',
              borderRadius: '5px',
              padding: '0.45rem',
              backgroundColor: 'white',
            }}
            onChange={e => setDescripcion(e.target.value)}
            type='text'
            name="category-name"
            value={descripcion}
          />
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <label>{t('forms:country')}</label>
          <SelectAsync
            onChange={e => {
              return setCountry(e.target.value)
            }}
            value={permisosUserCountry ? userCountryId : country}
            placeholder={t('forms:select')}
            isLoading={countries.isLoading}
            isRequired
            isDisabled={permisosUserCountry}
          >
            {countries
              .sort((a, b) => a.nombre.localeCompare(b.nombre))
              .map(({ id, nombre }) => (
                <option key={id} value={id}>
                  {nombre}
                </option>
              ))}
          </SelectAsync>
        </Box>
        <Button
          style={{ padding: '1rem', marginTop: '1.3rem' }}
          variantColor='blue'
          variant='outline'
          isDisabled={ !country || !descripcion }
          onClick={() => createCategory()}
        >
          {t('comunications:create')}
        </Button>
      </Box>
      <Text fontSize='lg' style={{ padding: '0.5rem 0.5rem 1rem' }}>
        {t('comunications:currentCategories')}
      </Text>
      {categories !== false ? (
        categories.map(x => (
          <Accordion allowMultiple key={x.id}>
            <AccordionItem key={x.id}>
              {({ isExpanded }) => (
                <>
                  <AccordionHeader key={x.id} onClick={setExpanded(true)}>
                    <Box flex='1' textAlign='left'>
                      {x.descripcion.split('/')[0]}
                    </Box>
                    <Icon size='12px' name={isExpanded ? 'minus' : 'add'} />
                  </AccordionHeader>
                  <AccordionPanel>
                    <Box
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '.6fr .4fr .5fr',
                        alignItems: 'center',
                        gap: '.6rem',
                        marginBottom: '1rem',
                      }}
                    >
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>
                          {t('comunications:edit')} {t('forms:name')}
                        </label>
                        <input
                          style={{
                            flex: 1,
                            border: '1px solid black',
                            borderRadius: '5px',
                            padding: '0.3rem',
                            backgroundColor: 'white',
                          }}
                          defaultValue={x.descripcion.split('/')[0]}
                          onChange={e => {
                            setDescriptionEdit(true)
                            return setDescripcionEdit(e.target.value)
                          }}
                        />
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <label>
                          {t('comunications:edit')} {t('forms:country')}
                        </label>
                        <SelectAsync
                          style={{
                            height: '2.3rem',
                          }}
                          onChange={e => {
                            setCountryEdit(true)
                            return setEditCountry(e.target.value)
                          }}
                          placeholder={t('forms:select')}
                          isLoading={countries.isLoading}
                          isDisabled={communications.find(c => c.categoriasComunicados.id === x.id) || permisosUserCountry}
                          defaultValue={x.paisId}
                          isRequired
                        >
                          {countries
                            .sort((a, b) => a.nombre.localeCompare(b.nombre))
                            .map(({ id, nombre }) => (
                              <option key={id} value={id}>
                                {nombre}
                              </option>
                            ))}
                        </SelectAsync>
                      </Box>
                      <Box style={{ display: 'flex', gap: '.5rem', justifyContent: 'center', marginTop: '1.3rem' }}>
                        <Button
                          style={{ padding: '1rem' }}
                          size='sm'
                          variantColor='blue'
                          variant='outline'
                          alignSelf='center'
                          onClick={() => editCategory(x.id, x.paisId, x.descripcion)}
                        >
                          {t('comunications:save')}
                        </Button>

                        <Button
                          key={x.id}
                          style={{ padding: '1rem' }}
                          size='sm'
                          variantColor='red'
                          alignSelf='center'
                          isDisabled={communications.find(c => c.categoriasComunicados.id === x.id)}
                          onClick={() => deleteCategory(x.id)}
                        >
                          {t('comunications:delete')}
                        </Button>
                      </Box>
                      <Box style={{ gridColumn: '3' }}>
                        {communications.find(c => c.categoriasComunicados.id === x.id) ? (
                          <Text
                            style={{
                              maxWidth: '15rem',
                              textAlign: 'center',
                              fontSize: 'small',
                              display: 'flex',
                              justifyContent: 'end',
                              margin: 'auto',
                              marginTop: '1rem',
                            }}
                          >
                            {t('comunications:categoryWarning')}
                          </Text>
                        ) : (
                          ''
                        )}
                      </Box>
                    </Box>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        ))
      ) : (
        <div style={{ margin: '0% 45%' }}>
          <Spinner />
        </div>
      )}
    </form>
  )
}

export default CreateCategories
