import React, { useEffect, useMemo, useState } from 'react'
import { Box, Skeleton, Spinner, Stack, Text, useDisclosure, useToast } from '@chakra-ui/core'
import { toastCustomizedSuccess, toastCustomizedError } from '../../../common/toast-default-config'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  createBranchOffice,
  getAppInformation,
  deleteBranchOffices,
  updateBranchOfficesById,
  getBranchOfficesByAppId,
} from '../../../../api/application'
import { getAuthToken, getToken, getUserRolId } from '../../../../redux/selectors'
import { Panel } from '../../../common/helpers'
import { BranchOfficesForm } from '../../../forms/application/BranchOfficesForm'
import Table from '../../../common/table'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import ModalSimple from '../../../common/modal-simple'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import { useParams } from 'react-router-dom'
import { isSuccess } from '../../../../helpers/utils'
import Pagination from '../../../common/pagination'

export const BranchOffices = () => {
  const { appId } = useParams()
  const { t } = useTranslation(['common', 'applications', 'forms'])
  const toast = useToast()
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const modalDisclosure = useDisclosure()
  const userRolId = useSelector(getUserRolId)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const permisosUser = userRolId === 1 || userRolId === 2 || userRolId === 3 ? true : false
  const appInformation = []
  const [request, setRequest] = useState({
    isLoading: false,
    items: [],
    error: false,
  })
  const [paisIdAplicationState, setpaisIdAplicationState] = useState()

  async function getAppInf() {
    try {
      const res = await getAppInformation(parseInt(appId), token)
      let paisIdAplication = res.data.paisId
      setpaisIdAplicationState(paisIdAplication)
      getBranchOffices(paisIdAplication)
    } catch (error) {
      console.log(error)
    }
  }

  async function getBranchOffices() {
    setRequest(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const res = await getBranchOfficesByAppId({aplicacionId: appId, page, maxPerPage: 12}, token)
      const sucursales = res.data
      setTotalPages(res.data.totalPages)
      if (res.data.totalPages === 1) setPage(1)
      setRequest(state => ({
        ...state,
        isLoading: false,
        items: sucursales || [],
      }))
    } catch (error) {
      if (error.response.status === 404) {
        setRequest(state => ({
          ...state,
          isLoading: false,
          error: true,
          items: [],
        }))
      }

      setRequest(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  useEffect(() => {
    getAppInf()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 
  useEffect(() => {
    getAppInf()
  }, [page])

  //CREAR SUCURSALES
  function onSubmit(datos) {
    let formData = {
      aplicacion: { id: parseInt(appId) },
      paisId: paisIdAplicationState,
      provinciaId: Number(datos.provincia),
      localidadId: Number(datos.localidad),
      direccion: datos.direccion,
      latitud: parseFloat(datos.latitud),
      longitud: parseFloat(datos.longitud),
      telefono: datos.telefono,
      horario: datos.horario,
      email: datos.email,
      casaCentral: datos.isCasaCentral,
      nombre: datos.nombre,
    }
    submitBranchOffices(formData)
  }
  async function submitBranchOffices(formData) {
    try {
      const data = { token, ...formData }
      const response = await createBranchOffice(data, authToken)
      getBranchOffices(paisIdAplicationState)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(error)
    }
  }

  //EDIT FUNCTIONS
  const [dataEdit, setDataEdit] = useState({})
  const [branchOfficesId, setBranchOfficesId] = useState()

  function onEditClick(id, data) {
    setBranchOfficesId(id)
    setDataEdit(data)
    modalDisclosure.onOpen()
  }

  function onSubmitUpdate(formData) {
    let data = {
      aplicacion: { id: parseInt(appId) },
      paisId: paisIdAplicationState,
      provinciaId: Number(formData.provincia),
      localidadId: Number(formData.localidad),
      direccion: formData.direccion,
      latitud: parseFloat(formData.latitud),
      longitud: parseFloat(formData.longitud),
      telefono: formData.telefono,
      horario: formData.horario,
      email: formData.email,
      casaCentral: formData.isCasaCentral,
      nombre: formData.nombre,
    }
    updateBranchOffices(branchOfficesId, data)
  }

  async function updateBranchOffices(branchOfficesId, formData) {
    try {
      const data = { token, ...formData }

      const response = await updateBranchOfficesById(branchOfficesId, data, token)
      if (response.status) {
        getBranchOffices()
        modalDisclosure.onClose()
      }
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
    } catch (error) {
      toast(error)
    }
  }

  //DELETE FUNCTIONS
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })

  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
    setPage(1)
  }

  async function onDelete() {
    try {
      const response = await deleteBranchOffices(deleteAlert.data.id, token)
      if (response.status) {
        getBranchOffices()
        onCloseDeleteAlert()
        toast(
          isSuccess(response.status)
            ? toastCustomizedSuccess(t('forms:actionCompleted'))
            : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
        )
      }
    } catch (error) {
      console.log('error', error)
      toast(error)
    }
  }

  //DATOS TABLA
  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: `${t('applications:province')}`,
        accessor: 'provinciaId',
      },
      {
        Header: `${t('applications:location')}`,
        accessor: 'localidadId',
      },
      {
        Header: t('forms:address'),
        accessor: 'direccion',
      },
      {
        Header: t('applications:phone'),
        accessor: 'telefono',
      },
      {
        Header: t('applications:hour'),
        accessor: 'horario',
      },
      {
        Header: t('applications:email'),
        accessor: 'email',
      },
      {
        Header: t('forms:centralHouse'),
        accessor: row => {
          if (row.casaCentral === true) {
            return 'SI'
          } else {
            return 'NO'
          }
        },
      },
      {
        Header: t('forms:name'),
        accessor: 'nombre',
      },
      {
        Header: permisosUser ? t('common:actions') : '',
        accessor: permisosUser ? null : 'e',
        Cell: ({ cell }) =>
          permisosUser && (
            <Stack mx='1' spacing='4px' isInline d='flex' justifyContent='center'>
              <ButtonEdit
                mb={4}
                onClick={() => {
                  return onEditClick(cell.row.values.id, cell.row.original)
                }}
              ></ButtonEdit>

              <ButtonDelete onClick={() => prepareDelete(cell.row.original)} />
            </Stack>
          ),
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    [t],
  )

  return (
    <Box flex='1' style={{ paddingRight: '1rem' }}>
      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.nombre} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>

      {/* Modal de Edición */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('common:edit')} ${t('common:agency')}`}
        size='lg'
      >
        <BranchOfficesForm buttonLabel={t('common:save')} formData={dataEdit} onSubmit={onSubmitUpdate} />
      </ModalSimple>

      {/* Formulario */}
      {permisosUser && <Panel rounded='md'>
        {request.isLoading ? (
          <Stack spacing='10px'>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='100px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
            <Stack spacing='5px'>
              <Skeleton height='20px' maxW='150px' />
              <Skeleton height='40px' />
            </Stack>
          </Stack>
        ) : (
          <BranchOfficesForm formData={appInformation} onSubmit={onSubmit} buttonLabel={t('common:save')} />
        )}
      </Panel>}

      {/* Tabla */}
      {!request.isLoading && request.items.data && (
        <Box mt='8'>
          <Table columns={columns} data={request.items.data} />
        </Box>
      )}
      <Box bg='white' mb='20px' mt='8'>
        {request.isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!request.isLoading && !request.error && !request.items && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!request.isLoading && !request.items && request.error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
      </Box>
      {!request.isLoading && request.items.totalPages && (
          <Pagination currentPage={page} setPage={setPage} totalPages={request.items.totalPages} />
        )}

    </Box>
  )
}
