import React, { useState, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createComercial } from '../../../api/application'
import { Stack, Box, FormLabel, SimpleGrid, Button, useToast, Image } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import FormInput from '../../forms/form-input'
import { isSuccess } from '../../../helpers/utils'
import SelectAsync from '../../common/select-async'
import { getCountriesState } from '../../../redux/selectors'
import { useSelector } from 'react-redux'

export default function CreateComercialForm({ onFinish }) {
  const { t } = useTranslation(['forms', 'applications', 'login', 'common', 'forms'])
  const [submitBlock, setSubmitBlock] = useState(false)
  const [countrySelected, setCountrySelected] = useState(true)
  const { handleSubmit, register, control } = useForm({})
  const toast = useToast()
  const [paisId, setPaisId] = useState('')
  const countries = useSelector(getCountriesState)

  const { REACT_APP_CLOUDINARY_CLOUD_NAME, REACT_APP_CLOUDINARY_PRESET, REACT_APP_CLOUDINARY_FOLDER } = process.env
  const img = 'https://res.cloudinary.com/travelit/image/upload/v1699363666/img-test/qwyflmg2nuyob9rpkzbh.jpg'
  const [imagState, setImagState] = useState('')

  const myCropWidget = useMemo(() => {
    return window?.cloudinary?.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        uploadPreset: REACT_APP_CLOUDINARY_PRESET,
        folder: REACT_APP_CLOUDINARY_FOLDER,
        cropping: true,
        cropping_responsive: false,
        cropping_aspect_ratio: 1,
        cropping_default_selection_ratio: 1,
        cropping_show_dimensions: true,
        cropping_responsive: false,
        showSkipCropButton: false,
        multiple: false,
      },
      (error, result) => {
        if (error) {
          alert('Cloudinary service unavailable')
        }
        checkUploadResult(result)
      },
    )
  }, [])

  function checkUploadResult(resultEvent) {
    if (resultEvent.event === 'success') {
      const img = resultEvent.info.secure_url
      setImagState(img)
    }
  }

  const submitComercial = async data => {
    const newData = {
      ...data,
      imagenContacto: imagState,
    }
    try {
      const response = await createComercial(newData)
      toast(
        isSuccess(response.status)
          ? toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')),
      )
      onFinish()
    } catch (error) {
      if (error.response) {
        toast(toastCustomizedError(error.response.data.message))
      }
    }
  }

  function removeImage() {
    setImagState('')
  }

  const submit = async data => {
    setSubmitBlock(true)
    await submitComercial(data)
    setSubmitBlock(false)
  }

  const handleChange = (newValue, onChange) => {
    onChange(newValue)
    setPaisId(newValue)
    setCountrySelected(false)
  }

  function showWidget() {
    myCropWidget.open()
  }

  return (
    <form style={{ display: 'grid' }} autoComplete='off' onSubmit={handleSubmit(submit)}>
      <Stack autoComplete='off'>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={2}>
          <Box>
            <FormLabel htmlFor='pais_id' isRequired>
              {t('forms:country')}
            </FormLabel>
            <Controller
              control={control}
              name='paisId'
              render={({ onChange, value, name }) => (
                <SelectAsync
                  onChange={e => handleChange(e.target.value, onChange)}
                  placeholder={t('forms:selectOption')}
                  name={name}
                  value={paisId}
                  isRequired
                >
                  {countries
                    ?.sort((a, b) => a.nombre.localeCompare(b.nombre))
                    .map(({ id, nombre }) => (
                      <option key={id} value={id}>
                        {nombre}
                      </option>
                    ))}
                </SelectAsync>
              )}
            />
          </Box>
          <FormInput
            isRequired
            name='nombre'
            label={t('forms:name')}
            inputProps={{
              placeholder: t('forms:name'),
              ref: register,
              maxLength: 100,
            }}
          />
          <FormInput
            isRequired
            name='apellido'
            label={t('forms:lastname')}
            inputProps={{
              placeholder: t('forms:lastname'),
              ref: register,
              maxLength: 100,
            }}
          />
          <FormInput
            name='emailContacto'
            label={t('applications:email')}
            inputProps={{
              placeholder: t('applications:email'),
              ref: register,
              maxLength: 100,
              type: 'email',
            }}
          />
          <FormInput
            name='telefonoContacto'
            label={t('applications:phone')}
            inputProps={{
              placeholder: t('applications:phone'),
              ref: register,
              maxLength: 100,
              type: 'number',
            }}
          />
          <FormInput
            name='celularContacto'
            label={t('consultations:cell')}
            inputProps={{
              placeholder: t('consultations:cell'),
              ref: register,
              maxLength: 100,
              type: 'number',
            }}
          />
          <Box display='flex' flexDirection='column'>
            <Box>
              <FormLabel htmlFor='imagen'>{t('forms:image')}</FormLabel>
            </Box>
            <Box position='relative'>
              <Image src={imagState ? imagState : img} boxSize='2.5rem' objectFit='cover' mb={2} />
              {imagState && (
                <Button position='absolute' top='0' right='0' size='sm' onClick={removeImage} borderRadius='50%'>
                  X
                </Button>
              )}
            </Box>
            <Button
              name='imagenUrl'
              size='md'
              onClick={showWidget}
              variantColor='blue'
              borderRadius='0 0 0.25rem 0.25rem'
            >
              {t('comunications:uploadImage')}
            </Button>
          </Box>
        </SimpleGrid>
      </Stack>
      <Button
        style={{ width: 'fit-content', justifySelf: 'end' }}
        mt={4}
        variantColor='blue'
        variant='outline'
        type='submit'
        isLoading={submitBlock}
      >
        {t('common:create')}
      </Button>
    </form>
  )
}
