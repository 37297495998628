import React, { useState, useEffect } from 'react'
import { PageContainer } from '../../common/helpers'
import { Button, Box, SimpleGrid, Link, Image, CloseButton, useToast } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Panel } from '../../common/helpers'
import { ListItem, UnorderedList } from '@chakra-ui/react'
import ModalSimple from '../../common/modal-simple'
import CreateCategories from '../communications/createCategories'
import {
  getAuthToken,
  getToken,
  getUserCountryId,
  getUserRolId,
} from '../../../redux/selectors'
import { useDisclosure } from '@chakra-ui/core'
import { GridItem } from '@chakra-ui/react'
import {
  obtenerComunicados,
  obtenerCategorias,
  actualizarComunicados,
  obtenerPermisosPorRol,
  obtenerPermisosPorRolId,
  crearPermisosPorRol,
  eliminarPermisosPorRolId,
  deleteComunicado,
} from '../../../api/communications'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { MdBuild } from 'react-icons/md'
import GeaHomeForm from '../../forms/comunications/geaHomeForm'
import AccessEdition from '../../forms/comunications/accessEdition'
import { toastCustomizedError, toastCustomizedSuccess } from '../../common/toast-default-config'
import { COMUNICATIONS_CRUD } from '../../../helpers/permissions'
import { deconstructPermission } from '../../../helpers/permissions-functions'
import '../../styles/comunications/training.css'
import '../../styles/comunications/comunications.css'

function GeaInHome() {
  // - - General - -

  const userCountryId = useSelector(getUserCountryId)
  const authToken = useSelector(getAuthToken)
  const token = useSelector(getToken)
  const toast = useToast()
  const { t } = useTranslation(['comunications', 'common'])
  const userRolId = useSelector(getUserRolId)
  let permisosUser = userRolId == 1 || userRolId == 2 ? true : false

  // - - 🖥️ URL-Params 🖥️ - -

  let history = useHistory()

  // - - 🖥️ URL-Params 🖥️ - -

  const modalDisclosure = useDisclosure()
  const modalDisclosure2 = useDisclosure()
  const modalDisclosure3 = useDisclosure()
  const modalDisclosure4 = useDisclosure()
  const modalDisclosure5 = useDisclosure()

  // - - ❗ States ❗ - -
  const [permisos, setPermisos] = useState([])
  const [formData, setFormData] = useState(false)
  const [linkvideo, setLinkvideo] = useState(false)
  const [events, setEvents] = useState([])
  const [categoriesEvents, setCategoriesEvents] = useState(false)
  const [eventsAll, setEventsAll] = useState([])
  const [heading, setHeading] = useState(' ')
  const [allCategories, setAllCategories] = useState(false)
  const [ids, setIds] = useState(false)
  const [rolls, setRollds] = useState(false)

  // - - ❗ Methods ❗ - -

  //Ir a Crear Noticia
  function goToCreate() {
    history.push('/communications?training')
  }

  // Eliminar Noticia
  function deleteComunitacion(id) {
    setIds(id)
    modalDisclosure4.onOpen()
  }

  // Acceder a los permisos
  function editAccess(id) {
    categoriasPorId(id.id)
    setIds(id)
    modalDisclosure3.onOpen()
  }

  // Checks permisos
  function funcCheck(id) {
    if (rolls !== false) {
      return rolls.filter((x) => x.rolId == id)
    }
  }

  // Editar Noticia
  function goToEdit(x) {
    setFormData(x)
    modalDisclosure2.onOpen()
  }

  //Cerrar Modal Permisos
  function closeModalPermisos() {
    modalDisclosure3.onClose()
    setRollds(false)
  }

  // Crear-Editar-Eliminar Categorias
  function createCategories() {
    modalDisclosure.onOpen()
  }

  // VerVideo
  function seeVideo(x) {
    setLinkvideo(x.subtitulo.slice(17))
    modalDisclosure5.onOpen()
  }

  // ParseCategoria
  function createCategorias(idCat) {
    if (allCategories == false) {
      return null
    } else {
      let cat = allCategories.filter((x) => x.id === idCat.categoriasComunicados.id)
      function finalSplit(x) {
        let categorie = x.split('/')
        return categorie[0]
      }
      return finalSplit(cat[0].descripcion)
    }
  }

  function seeOption(b) {
    function separar(i) {
      let o = i.split('/')
      return o[0]
    }
    let cat = allCategories.filter((x) => separar(x.descripcion) == b[0])
    let u = eventsAll.filter((x) => x.categoriasComunicados.id === cat[0].id)
    setEvents(u)
    let cat2 = cat[0].descripcion.split('/')
    setHeading(` | ${cat2[0]}`)
  }

  // - - - -📞❗ API CALLS ❗📞- - - -

  // Obtener Permisos de Usuario
  async function obtenerPermisos() {
    try {
      const resp = await obtenerPermisosPorRolId(userRolId, authToken)
      getEvents(resp.data)
      setPermisos(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  // Obtener Noticias
  async function getEvents(permisos) {
    // Fecha inicio: Ultimos 3 años
    let fechaInicio = moment()
      .subtract(3, 'year')
      .format('YYYY-MM-DD')
    // Fecha fin: Proximos 3 meses
    let fechaFin = moment()
      .add(3, 'month')
      .format('YYYY-MM-DD')
    try {
      const resp = await obtenerComunicados(authToken)
      const filterCategory = resp.data.data.filter((x) => x.tipoComunicado.id === 3)
      let filterDate = filterCategory.filter((x) => x.fechaBaja == null)
      let perm = permisos
      perm.filter((x) => x.rolId == userRolId)
      function filters(id) {
        let i = perm.map((x) => x.comunicadosId == id)
        let k = i.filter((x) => x == true)
        if (k == '') {
          return false
        } else {
          return true
        }
      }

      let filterPermisos = filterDate.map((x) => (filters(x.id) == true ? x : null))
      let filterPermisos2 = filterPermisos.filter((x) => x !== null)
      let filterLastDat = filterPermisos2.sort((datoPrev, datoNext) => {
        return (
          new Date(moment(datoPrev.fecha).format('YYYY-MM-DD')).getTime() <
          new Date(moment(datoNext.fecha).format('YYYY-MM-DD')).getTime()
        )
      })
      setEvents(filterLastDat)
      setEventsAll(filterLastDat)
    } catch (err) {
      console.log(err)
    }
  }

  // Obtener Categorias por Id
  async function categoriasPorId(id) {
    try {
      const resp = await obtenerPermisosPorRol(0, id, authToken)
      setRollds(resp.data)
    } catch (err) {
      console.log(err)
    }
  }

  // ParseCategoria
  function createCategorias(idCat, b) {
    let cat = allCategories.filter((x) => x.id == idCat.categoriasComunicados.id)
    if (b == 'color') {
      return cat[0].color
    } else {
      let category = cat[0].descripcion.split('/')
      return category[0]
    }
  }

  // Editar Noticia
  const sesion = {
    token,
    ...deconstructPermission(COMUNICATIONS_CRUD),
  }

  async function editFunc(x) {
    let id = formData.id
    let data = {
      asunto: x.asunto,
      titulo: x.titulo,
      subtitulo: x.subtitulo,
      imagenUrl: x.imagenUrl,
      descripcion: x.descripcion,
      comunicadoHtml: x.comunicadoHtml,
      categoriasComunicados: { id: x.categoriasComunicados.id },
      destacado: x.destacado,
      tipoComunicado: { id: x.tipoComunicado.id },
      url: x.url,
      fecha: x.fecha,
      sesion,
    }

    try {
      const resp = await actualizarComunicados(id, data, authToken)
      toast(toastCustomizedSuccess(`${t('comunications:editSucess')}`))

      modalDisclosure2.onClose()
      obtenerPermisos()
    } catch (err) {
      toast(toastCustomizedError(`${t('comunications:editError')}`))

      console.log(err)
    }
  }

  // Eliminar Noticia
  async function deleteNew() {
    try {
      await deleteComunicado(ids, authToken)
      getEvents(permisos)
      modalDisclosure4.onClose()
    } catch (err) {
      console.log(err)
    }
  }

  // Crear / Eliminar Permiso
  async function newPermission(a, b) {
    const sesion = {
      token,
      ...deconstructPermission(COMUNICATIONS_CRUD),
    }
    if (b == false) {
      let data = {
        rolId: a,
        comunicados: { id: ids.id },
        sesion,
      }
      try {
        const resp = await crearPermisosPorRol(data, authToken)
        if (resp.status == 200) {
        } else {
          console.log('error')
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      let permisoId = rolls.filter((x) => x.rolId == a)
      let permisoDelete = permisoId[0].id

      try {
        const resp = await eliminarPermisosPorRolId(permisoDelete, authToken)
        if (resp.status == 200) {
        } else {
          console.log('error')
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  // Obtener Categorias de Noticias
  async function obtenerCategoriasFunc() {
    try {
      const resp = await obtenerCategorias(userCountryId, authToken)
      let filterDate = resp.data.filter((x) => x.fechaBaja == null)
      setAllCategories(filterDate)
      let filterSplit = filterDate.map((x) => x.descripcion.split('/'))
      let filterCategorieEvents = filterSplit.filter((x) => x[1] == 'training')

      setCategoriesEvents(filterCategorieEvents)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    obtenerPermisos()
    obtenerCategoriasFunc()
  }, [])

  return (
    <>
      {/* Heading */}
      <PageContainer>
        <SimpleGrid columns={{ sm: 1, md: 2 }} gap={2} style={{ margin: '0rem', padding: '0' }}>
          <Box color="#103059">
            <h1 style={{ fontSize: '2.5rem', padding: '0', marginTop: '0.5rem' }}>
              {t('comunications:geaInHome')}
              {t(`comunications:${heading}`)}
            </h1>
          </Box>
          {permisosUser === true ? (
            <SimpleGrid
              className="dNone"
              columns={{ base: 1, md: 1 }}
              style={{ fontSize: '2.5rem', padding: '0', marginTop: '0.8rem' }}
              spacing={8}
            >
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Box>
                  <Button
                    style={{ width: '12rem' }}
                    onClick={() => {
                      goToCreate()
                    }}
                    variantColor="blue"
                    variant="outline"
                  >
                    {t('comunications:createNews')}
                  </Button>
                </Box>
                <Box>
                  <Button
                    style={{ width: '12rem' }}
                    variant="outline"
                    onClick={() => {
                      createCategories()
                    }}
                    variantColor="blue"
                  >
                    {t('comunications:createCategories')}
                  </Button>
                </Box>
              </div>
            </SimpleGrid>
          ) : null}
        </SimpleGrid>
      </PageContainer>

      {/* Pannel MKT */}
      <ModalSimple
        isOpen={modalDisclosure.isOpen}
        onClose={modalDisclosure.onClose}
        title={`${t('comunications:createCategories')}`}
        size="lg"
      >
        <CreateCategories onClose={modalDisclosure.onClose}></CreateCategories>
      </ModalSimple>

      {/* Section Page Gea in Home */}
      <PageContainer>
        <SimpleGrid columns={{ sm: 1, md: 4 }} spacing={2} style={{ margin: '2rem 1rem 2rem' }}>
          <GridItem className="dNone" colSpan={1} rowSpan={10} style={{ width: '100%', marginLeft: '0% 5%' }}>
            <Panel style={{ width: '95%' }}>
              <Box>
                <p style={{ fontSize: '1.2rem', color: '#103059' }}>{t('comunications:categories')}:</p>
                <UnorderedList style={{ margin: '10% 10% 2%' }}>
                  {categoriesEvents == false
                    ? null
                    : categoriesEvents.map((x) => (
                        <ListItem key={x.id} style={{ margin: '2% 1%' }}>
                          <Link
                            _hover={{ fontWeight: 'semibold' }}
                            style={{ fontSize: '1rem', textTransform: 'uppercase' }}
                            onClick={() => {
                              seeOption(x)
                            }}
                          >
                            {x[0]}
                          </Link>
                        </ListItem>
                      ))}
                </UnorderedList>
              </Box>
              <Box>
                {/* <Button
                        style={{width:"75%", height:"2.2rem", margin:"-5% 10% 0%",backgroundColor:"#103059", color:"white"}}
                        onClick={()=>{cleanAll()}}
                        variantColor="blue"
                        >{t("comunications:cleanFilter")}</Button> */}
              </Box>
            </Panel>
          </GridItem>
          <GridItem colSpan={3} className="cardTraining">
            <div>
              {events.map((x) => (
                <div key={x.id}>
                  <Box style={{ display: 'flex', height: '10%' }}>
                    <Box
                      className="dNone"
                      borderWidth="1px"
                      rounded="lg"
                      overflow="hidden"
                      style={{ width: '20rem', height: '9.5rem' }}
                    >
                      <div style={{ position: 'relative' }}>
                        {permisosUser === true ? (
                          <div className="btnEditAcces">
                            <Button
                              style={{ background: 'rgba(0,0,0,0.2)', height: '2.1rem', padding: '-2' }}
                              onClick={() => {
                                editAccess(x)
                              }}
                              variantColor="blue"
                            >
                              {t('comunications:permits')}
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      <Image style={{ width: '100%', height: '95%' }} src={x.imagenUrl} />
                      <Box style={{ height: '0.6rem', backgroundColor: `${createCategorias(x, 'color')}` }}></Box>
                    </Box>

                    <Box style={{ width: '100%', paddingLeft: '1%' }}>
                      <div>
                        <Box>
                          <p className="trainingTitleCard">
                            <b>{x.titulo}</b>
                          </p>
                        </Box>
                      </div>
                      <div>
                        <Box
                          fontSize="1.1rem"
                          style={{ padding: '0rem 1.3rem', margin: '0 ', color: '#555555', lineHeight: '1.5rem' }}
                        >
                          <b>
                            {t('comunications:category')}: {createCategorias(x)}
                          </b>
                        </Box>
                      </div>

                      <Box className="trainingDateCard">
                        <b>
                          {t('comunications:broadcast')}: {moment(x.fecha).format('dddd')} de {moment(x.fecha).format('MMMM')} de{' '}
                          {moment(x.fecha).format('YYYY')}
                        </b>
                      </Box>
                    </Box>
                    <Box style={{ width: '10%' }}>
                      {permisosUser === true ? (
                        <div className="dNone" style={{ position: 'relative' }}>
                          <div style={{ position: 'absolute', top: '0.2rem', right: '0.2rem' }}>
                            <CloseButton
                              size="md"
                              style={{ color: '#A52422', backgroundColor: 'rgba(0,0,0,0.2' }}
                              onClick={() => {
                                deleteComunitacion(x.id)
                              }}
                              variantColor="blue"
                            >
                              {t('comunications:eliminar')}
                            </CloseButton>
                          </div>
                          <div style={{ position: 'absolute', top: '0rem', right: '3.5rem' }}>
                            <Button
                              onClick={() => {
                                goToEdit(x)
                              }}
                              rightIcon={MdBuild}
                              style={{ backgroundColor: 'transparent', color: 'gray', marginRight: '-1rem', paddingRight: 'px' }}
                            ></Button>
                          </div>
                        </div>
                      ) : null}
                    </Box>
                  </Box>

                  <div style={{ position: 'relative' }}>
                    <div className="btnSeeVideo">
                      <Button variantColor="blue" onClick={() => seeVideo(x)}>
                        <p className="btnSeeVideoDetails">{t('comunications:seeVideo')}</p>
                      </Button>
                    </div>
                  </div>
                  <hr className="dividerGeaInHome"></hr>
                  {/* Modal Video */}

                  <ModalSimple
                    isOpen={modalDisclosure5.isOpen}
                    onClose={modalDisclosure5.onClose}
                    title={`${t('comunications:Video')}`}
                    size="3xl"
                  >
                    {linkvideo == false ? null : (
                      <iframe
                        width="100%"
                        height="415"
                        src={`https://www.youtube.com/embed/${linkvideo}`}
                        title="YouTube video player"
                        frameborder="1"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture."
                        allowFullScreen
                        webkitallowfullscreen
                        mozallowfullscreen
                      ></iframe>
                    )}
                  </ModalSimple>
                </div>
              ))}
            </div>
          </GridItem>
        </SimpleGrid>
      </PageContainer>

      {/* Modal Edicion */}
      {formData == false ? null : (
        <ModalSimple
          isOpen={modalDisclosure2.isOpen}
          onClose={modalDisclosure2.onClose}
          title={`${t('comunications:EditNew')}`}
          size="6xl"
        >
          <GeaHomeForm padre={editFunc} formData={formData} section={'news'}></GeaHomeForm>
        </ModalSimple>
      )}

      {/* Modal Delete */}
      <ModalSimple
        isOpen={modalDisclosure4.isOpen}
        onClose={modalDisclosure4.onClose}
        title={`${t('comunications:deleteItem')}`}
        size="xl"
      >
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            style={{ width: '12rem' }}
            onClick={() => {
              deleteNew()
            }}
            variantColor="red"
          >
            {t('common:cancel')}
          </Button>
          <Button style={{ width: '12rem' }} onClick={() => modalDisclosure4.onClose()} variant="outline" variantColor="blue">
            {t('comunications:Cancelar')}
          </Button>
        </div>
      </ModalSimple>

      {/* Modal Permisos */}
      {rolls !== false ? (
        <ModalSimple isOpen={modalDisclosure3.isOpen} title={`${t('comunications:permits')}`} size="xl">
          <ModalSimple isOpen={modalDisclosure3.isOpen} title={`${t('comunications:permits')}`} size="xl">
            <AccessEdition funcCheck={funcCheck} newPermission={newPermission}></AccessEdition>

            <div style={{ float: 'right', margin: '4% 0% 0%' }}>
              <Button
                style={{ height: '2.1rem', padding: '-2' }}
                onClick={() => {
                  closeModalPermisos()
                }}
                variantColor="blue"
              >
                {t('comunications:close')}
              </Button>
            </div>
            <div style={{ position: 'absolute', top: '0rem', right: '0.1rem' }}>
              <Box style={{ backgroundColor: 'white', width: '3rem', height: '3rem', zIndex: '22' }}></Box>
            </div>
          </ModalSimple>
        </ModalSimple>
      ) : null}
    </>
  )
}

export default GeaInHome
