import React, { useState, useEffect } from 'react'
import { IoMdTrash } from 'react-icons/io'
import { Box, Stack, Button, useDisclosure, useToast, Spinner, Text } from '@chakra-ui/core'
import { useTranslation } from 'react-i18next'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from './impuesto-section-data'
import AlertDialogSimple from '../../common/alert-dialog-simple'
import ModalSimple from '../../common/modal-simple'
import Table from '../../common/table'
import { toastCustomizedError } from '../../common/toast-default-config'
import { Panel } from '../../common/helpers'
import { useSelector } from 'react-redux'
import { getAuthToken, getCountriesState, getUserCountryId, getUserRolId } from '../../../redux/selectors'
import ImpuestoSearchForm from './impuesto-search-form'
import CreateImpuestoForm from './create-impuesto'
import { deleteTaxes, getTaxesPaginated } from '../../../api/intranet/requests'
import { useSectionData } from '../../../hooks/useSectionData'
import Pagination from '../../common/pagination'

export const Impuesto = () => {
  const { t } = useTranslation(['applications', 'common', 'forms', 'login', 'comunications'])
  const modalEditDisclosure = useDisclosure()
  const rolId = useSelector(getUserRolId)
  const permisosUserCountry = rolId == 2 ? true : false
  const userCountryId = useSelector(getUserCountryId)
  const modalEditDisclosure2 = useDisclosure()
  const [totalPages, setTotalPages] = useState(0)
  const authToken = useSelector(getAuthToken)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)
  const toast = useToast()
  const countries = useSelector(getCountriesState)
  const [dataImpuestoAlert, setDeleteImpuestoAlert] = useState({
    show: false,
    dataImpuesto: null,
  })

  const {
    filters,
    handleFilters,
    sectionData,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const onCloseAlertDelete = () => setDeleteImpuestoAlert(state => ({ ...state, show: false }))

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: 'taxName',
        Cell: ({ cell }) => <p>{cell.row.values.taxName}</p>,
      },
      {
        Header: t('comunications:type'),
        accessor: 'taxType',
        Cell: ({ cell }) => {
          const taxType = cell.row.values.taxType
          const translatedType = taxType === 'PORCENTAGE' ? t('packages:PORCENTAGE') : t('forms:amount')
          return <p>{translatedType}</p>
        },
      },
      {
        Header: t('forms:value'),
        accessor: 'taxAmount',
        Cell: ({ cell }) => <p>{cell.row.values.taxAmount}</p>,
      },
      {
        Header: t('comunications:country'),
        accessor: 'countryId',
        Cell: ({ cell }) => <p>{countries?.find(c => c.id === cell.row.values.countryId)?.nombre}</p>,
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => {
          return (
            <Stack
              style={{ display: 'grid', gap: '.5rem', gridTemplateColumns: 'repeat(2, 1fr)', maxWidth: '10rem' }}
              spacing='4px'
              isInline
            >
              <Button
                leftIcon={IoMdTrash}
                variantColor='red'
                variant='outline'
                size='xs'
                onClick={() => prepareDeleteImpuesto(cell.row.original)}
              >
                {t('common:delete')}
              </Button>
            </Stack>
          )
        },
      },
    ]
  }, [modalEditDisclosure, t])

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getImpuesto()
      }
    }
  }

  const getImpuesto = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)
    if(permisosUserCountry){
      console.log('entra al if de country id');
      filters.countryId = userCountryId
    }

    handleSectionData(SECTION_DATA_KEYS.impuesto, {
      ...sectionData.impuesto,
      isLoading: true,
    })

    try {
      console.log('try', filtersAux)
      const response = await getTaxesPaginated(filtersAux)
      setTotalPages(response.data.lastPage)
      handleSectionData(SECTION_DATA_KEYS.impuesto, {
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.impuesto, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  function prepareDeleteImpuesto(dataImpuesto) {
    setDeleteImpuestoAlert(state => ({
      ...state,
      show: true,
      dataImpuesto,
    }))
  }

  const handleDeleteImpuesto = async () => {
    const { dataImpuesto } = dataImpuestoAlert
    try {
      await deleteTaxes(dataImpuesto.id, authToken)
      getImpuesto()
      toast({
        title: t('forms:actionCompleted'),
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
    } catch (err) {
      if (err.response.status == 403) {
        toast({
          title: t('packages:associatedDependencies'),
          status: 'error',
          duration: 3000,
        })
      }
    }
    setDeleteImpuestoAlert(state => ({
      ...state,
      show: false,
      dataImpuesto: null,
    }))
    handleSetPage(1)
  }

  useEffect(() => {
      getImpuesto()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page])

  useEffect(() => {
    handleResetSectionData()
    if(permisosUserCountry){
      getImpuesto()
    }else{
      getImpuesto(true)
    }
  }, [resetFilters])

  const deleteImpuestoString = `${t('common:delete')} ${t('consultations:taxes')}`
  const createImpuestoString = `${t('common:create')} ${t('consultations:taxes')}`

  return (
    <Box flex='2' style={{ paddingRight: '1rem' }}>
      <>
        <AlertDialogSimple
          isOpen={dataImpuestoAlert.show}
          onClose={onCloseAlertDelete}
          onAccept={handleDeleteImpuesto}
          title={deleteImpuestoString}
          acceptLabel={t('common:delete')}
          acceptVariantColor='red'
        >
          <p>
            {dataImpuestoAlert.dataImpuesto &&
              `${dataImpuestoAlert.dataImpuesto.id} ${dataImpuestoAlert.dataImpuesto.taxName}`}
          </p>
        </AlertDialogSimple>

        <ModalSimple
          isOpen={modalEditDisclosure2.isOpen}
          onClose={modalEditDisclosure2.onClose}
          title={createImpuestoString}
          size='800px'
          style={{ borderRadius: '5px' }}
        >
          <CreateImpuestoForm
            onFinish={() => {
              modalEditDisclosure2.onClose()
              return getImpuesto()
            }}
          />
        </ModalSimple>

        <Button mb={6} variantColor='blue' onClick={() => modalEditDisclosure2.onOpen()}>
          {t('common:create')} {t('consultations:taxes')}
        </Button>
        <Panel rounded='md' mb='1.5rem'>
          <ImpuestoSearchForm onFilter={onFilter} />
        </Panel>
        <Box>
          {sectionData.impuesto.isLoading && (
            <Box px='5' py='10' textAlign='center'>
              <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
            </Box>
          )}

          {!sectionData.impuesto.isLoading && sectionData.impuesto.data && sectionData.impuesto.data.length > 0 ? (
            <>
              <Table columns={columns} data={sectionData?.impuesto?.data} />
              {totalPages && <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />}
            </>
          ) : (
            <Text textAlign='center' color='gray.500' px='5' py='10'>
              {t('common:zeroItems')}
            </Text>
          )}
        </Box>
      </>
    </Box>
  )
}
