import React, { useEffect, useState } from 'react'
import { PageContainer, Panel } from '../../common/helpers'
import ModalSimple from '../../common/modal-simple'
import { useTranslation } from 'react-i18next'
import { useAsyncToastNotification } from '../../hooks'
import Table from '../../common/table'
import { useSelector } from 'react-redux'
import { getAllCities } from '../../../api/geo'
import { getAuthToken, getCountriesState, getUserCountryId } from '../../../redux/selectors'
import { Stack, Box, Text, Spinner, useDisclosure, Heading, Button, useToast } from '@chakra-ui/core'
import { ButtonDelete, ButtonEdit } from '../../forms/buttons/edit'
import AlertDialogSimple from '../../../components/common/alert-dialog-simple'
import { useMemo } from 'react'
import { toastError } from '../../common/toast-default-config'
import { getHotels, deleteHotel } from '../../../api/intranet/requests'
import Pagination from '../../common/pagination'

//Material ui
import { HotelForm } from '../../forms/hotels/hotelForm'
import AOS from 'aos'
import HotelsSearchForm from './hotels-search-form'
import { useSectionData } from '../../../hooks/useSectionData'
import { FILTER_KEYS, SECTION_DATA_KEYS, defaultFiltersValues } from '.'

export const ListHotels = () => {
  const {
    filters,
    sectionData,
    handleFilters,
    handleSectionData,
    resetFilters,
    lastFilters,
    setLastFilters,
    handleResetSectionData,
  } = useSectionData()

  const { t } = useTranslation(['common', 'forms', 'hotels'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const toast = useToast()
  const countries = useSelector(getCountriesState)

  const [totalPages, setTotalPages] = useState(0)
  const handleSetPage = value => handleFilters(FILTER_KEYS.page, value)

  const [loc, setLoc] = useState({
    isLoading: false,
    items: [],
    error: false,
  })

  //Modal vbles
  const modalHotelDisclosure = useDisclosure()

  let language
  switch (countryId) {
    case 1:
      language = 'es'
      break
    case 3:
      language = 'pt'
      break
    default:
      language = 'en'
      break
  }

  const getFilteredHotels = async (reset = false) => {
    const filtersAux = reset ? defaultFiltersValues : filters
    setLastFilters(filtersAux)
    handleSectionData(SECTION_DATA_KEYS.hotels, {
      ...sectionData.hotels,
      isLoading: true,
    })
    try {
      let response
      response = await getHotels(
        {
          ...filtersAux,
        },
        authToken,
      )
      setTotalPages(response.data.lastPage)
      handleSectionData(SECTION_DATA_KEYS.hotels, {
        data: response.data.data,
        isLoading: false,
        error: null,
      })
    } catch (error) {
      handleSectionData(SECTION_DATA_KEYS.hotels, {
        data: [],
        isLoading: false,
        error: error,
      })
      toast(toastError)
    }
  }

  async function getLocalidades() {
    try {
      const res = await getAllCities('')
      setLoc(state => ({
        ...state,
        isLoading: false,
        items: (res.data && res.data) || [],
      }))
    } catch (error) {
      setLoc(state => ({
        ...state,
        isLoading: false,
        error: true,
      }))
    }
  }

  //EDIT FUNCTIONS
  const [idHotel, setIdHotel] = useState(0)

  const onEditClick = id => {
    setIdHotel(id)
  }

  useEffect(() => {
    idHotel && modalHotelDisclosure.onOpen()
  }, [idHotel])

  const handleOpenCreateHotel = () => {
    modalHotelDisclosure.onOpen()
  }

  //DELETE FUNCTIONS
  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })
  const { asyncNotificationWrapper } = useAsyncToastNotification()

  const onCloseDeleteAlert = () => {
    setDeleteAlert(state => ({ ...state, show: false }))
  }

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      data,
      show: true,
    }))
  }

  async function onDelete() {
    await asyncNotificationWrapper(async () => {
      const response = await deleteHotel(deleteAlert.data.id, authToken)
      getFilteredHotels(filters.localidadId)
      return response.status
    })
    onCloseDeleteAlert()
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:country'),
        accessor: 'paisId',
        Cell: ({ cell }) => <p>{countries?.find(c => c.id === cell.row.values.paisId)?.nombre}</p>,
      },
      {
        Header: `${t('forms:name')}`,
        accessor: 'name',
      },
      {
        Header: `${t('forms:countryDestination')}`,
        accessor: 'paisNombre',
      },
      {
        Header: `${t('forms:destination')}`,
        accessor: 'localidadNombre',
      },
      {
        Header: t('forms:category'),
        accessor: 'category',
        Cell: ({ cell }) => {
          const category = cell.row.values.category
          return category !== null && category !== 0 ? category : 0
        },
      },
      {
        Header: t('common:actions'),
        accessor: null,
        Cell: ({ cell }) => (
          <Stack spacing='5px' isInline>
            <ButtonEdit
              mb={4}
              onClick={() => {
                onEditClick(cell.row.values.id)
              }}
            />

            <ButtonDelete
              onClick={() => {
                return prepareDelete(cell.row.original)
              }}
            />
          </Stack>
        ),
      },
    ],
    [t, countries],
  )

  const onFilter = () => {
    if (!!Object.entries(filters).filter(([key, value]) => !lastFilters[key] || lastFilters[key] !== value).length) {
      if (filters.page !== 1) {
        handleSetPage(1)
      } else {
        getFilteredHotels()
      }
    }
  }

  useEffect(() => {
    getLocalidades()
    getFilteredHotels()
    AOS.init({
      duration: 1000,
    })
    AOS.refresh()
  }, [])

  useEffect(() => {
    getFilteredHotels()
  }, [filters.page])

  useEffect(() => {
    if (resetFilters !== 0) {
      handleResetSectionData()
      getFilteredHotels(true)
    }
  }, [resetFilters])

  const onClose = () => {
    if (idHotel) {
      setIdHotel(0)
    }
    modalHotelDisclosure.onClose()
  }

  return (
    <PageContainer>
      <Box backgroundColor='' py='16px' color='#103059'>
        <Heading style={{ fontSize: '1.8rem' }} mb={0}>
          {t('common:listHotel')}
        </Heading>
      </Box>
      <Button my={5} variantColor='blue' onClick={handleOpenCreateHotel}>
        {t('common:create')}
      </Button>
      <Panel rounded='md' mb='1.5rem'>
        <HotelsSearchForm onFilter={onFilter} />
      </Panel>

      {/* Alert delete */}
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        acceptLabel={t('common:delete')}
      >
        {t('common:deleting')} <br />
        <b>{deleteAlert.data && `${deleteAlert.data.name} #${deleteAlert.data.id}`}</b>
      </AlertDialogSimple>

      {/* Tabla */}
      {!sectionData.isLoading ? (
        <>
          <Table columns={columns} data={sectionData.hotels.data} />
        </>
      ) : (
        ''
      )}
      {!!totalPages && <Pagination currentPage={filters.page} setPage={handleSetPage} totalPages={totalPages} />}
      <Box bg='white' mb='20px' mt='8'>
        {sectionData.isLoading && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!sectionData.isLoading && !sectionData.error && sectionData.hotels.data.length === 0 && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:zeroItems')}
          </Text>
        )}
        {!sectionData.isLoading && !sectionData.data && sectionData.error && (
          <Text textAlign='center' color='gray.500' px='5' py='10'>
            {t('common:errorGettingItems')}
          </Text>
        )}
      </Box>
      <ModalSimple
        isOpen={modalHotelDisclosure.isOpen}
        onClose={onClose}
        title={!!idHotel ? t('hotels:editHotel') : t('common:newHotel')}
        size='900px'
      >
        <HotelForm idHotel={idHotel} onClose={onClose} refetch={getFilteredHotels} />
      </ModalSimple>
    </PageContainer>
  )
}
