import React from 'react'
import { PageContainer } from "../../common/helpers";
import { Button, Box, SimpleGrid,Heading, Spinner } from "@chakra-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Panel } from "../../common/helpers";
import { useToast,useDisclosure } from "@chakra-ui/core";
import ModalSimple from "../../common/modal-simple";
import CreateCategories from '../communications/createCategories'

const Calendar = () => {
	let history = useHistory();
	const modalDisclosure = useDisclosure();

	const { t } = useTranslation(["comunications"]);

	function goToEdit(){
		history.push('/communications?news')
	}
	function instrucciones(){
		modalDisclosure.onOpen()
	}


	return (
		<>
			<Box backgroundColor='' py="10px" pb="0.5%" mt="" color="#103059">
				<PageContainer >
					<Heading style={{fontSize:"1.8rem"}}>{t("incentives:Comunicaciones")}</Heading>
				</PageContainer>
			</Box>
			<Panel style={{margin:"0.5rem 2rem", height:"5rem"}}>
				<SimpleGrid columns={{ base: 1, md: 1}} spacing={8}>
					<div style={{display:'flex', justifyContent:'space-around'}}>
						<Box>
							<Button
								style={{width:"12rem"}}
								onClick={()=>{goToEdit()}}
								variantColor="blue"
							>{t("comunications:createNews")}</Button>
						</Box>
						<Box>
							<Button
								style={{width:"12rem"}}
								onClick={()=>{instrucciones()}}
								variantColor="blue"
							>{t("comunications:createCategories")}</Button>
						</Box>
					</div>
				</SimpleGrid>
			</Panel>

			<ModalSimple
				isOpen={modalDisclosure.isOpen}
				onClose={modalDisclosure.onClose}
				title={`${t("comunications:createCategories")}`}
				size="lg"
			>
				<CreateCategories
					onClose={modalDisclosure.onClose}>
				</CreateCategories>
			</ModalSimple>
		</>
	)
}

export default Calendar
