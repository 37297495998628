import React, { useEffect, useState } from 'react'
import Menu, { MenuButton, MenuList, MenuOptionGroup, MenuItemOption } from '@chakra-ui/core/dist/Menu'
import MenuButtonProps from '@chakra-ui/core/dist/Menu'
import { Button } from '@chakra-ui/core'

const MultiSelectMenu = ({ change, label, options, buttonProps = MenuButtonProps, isDisabled, selectedOptionsAux }) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedOptionsAux)
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...'
    }
    return text
  }

  useEffect(() => {
    setSelectedOptions(selectedOptionsAux)
  }, [selectedOptionsAux])

  useEffect(() => {
    setSelectedOptions(selectedOptionsAux)
  }, [selectedOptionsAux])

  return (
    <Menu name='rubros' closeOnSelect={false}>
      {({ onClose }) => (
        <>
          <MenuButton
            type='button'
            backgroundColor={selectedOptions.length ? 'gray.200' : 'white'}
            color='gray.600'
            borderColor='gray.200'
            borderWidth={1}
            p={2}
            px={4}
            borderRadius='5px'
            _focus={{
              outline: 'none',
            }}
            {...buttonProps}
          >
            {`${label}${selectedOptions.length > 0 ? ` (${selectedOptions.length})` : ''}`}
          </MenuButton>
          <MenuList placement='bottom' onClick={e => e.preventDefault()} zIndex={'dropdown'} maxHeight="40vh" overflowY="auto">
            <MenuOptionGroup
              title={undefined}
              defaultValue={selectedOptions}
              type='checkbox'
              name='rubros'
              placement='bottom'
              onChange={values => {
                const rubros = values.map(v => ({ id: v }))
                change(rubros)
                setSelectedOptions(values)
              }}
            >
              {options.map(option => {
                return (
                  <MenuItemOption key={option.id} type='button' value={option.id} isDisabled={isDisabled}>
                    {truncateText(option.nombre, 30)}
                  </MenuItemOption>
                )
              })}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

MultiSelectMenu.displayName = 'MultiSelectMenu'

export default MultiSelectMenu
