import { useToast } from '@chakra-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { configWithAuth } from '../../api/intranet'
import { deconstructPermission } from '../../helpers/permissions-functions'
import { getToken } from '../../redux/selectors'
import { toastCustomizedError, toastCustomizedSuccess, toastError } from '../common/toast-default-config'
import { useTranslation } from 'react-i18next'

export function useAsyncStateWithAction(action, selector) {
  const dispatch = useDispatch()
  const { error, isLoading, items } = useSelector(selector)

  function update() {
    dispatch(action)
  }

  useEffect(() => {
    if (!items) {
      dispatch(action)
    }
  }, [action, dispatch, items])
  return { error, isLoading, items, update }
}

export function useFormControls() {
  const [isSubmitBlocked, setSubmitBlock] = useState(false)

  const submitWrapper = async submitFunction => {
    setSubmitBlock(true)
    await submitFunction()
    setSubmitBlock(false)
  }

  return { isSubmitBlocked, submitWrapper }
}

export function useAsyncToastNotification(toastSuccessConfig, toastErrorConfig) {
  const toast = useToast()
  const { t } = useTranslation(['forms'])

  async function asyncNotificationWrapper(request) {
    try {
      const status = await request()
      toast(
        status
          ? toastSuccessConfig || toastCustomizedSuccess(t('forms:actionCompleted'))
          : toastErrorConfig || toastError,
      )
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.error('error', error)
        // eslint-disable-next-line no-console
      }
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  return { asyncNotificationWrapper }
}

export function usePermission() {
  const token = useSelector(getToken)

  function addPermissions(data, stringPermission = '') {
    const permissionDeconstruct = deconstructPermission(stringPermission)
    return {
      ...data,
      sesion: {
        ...permissionDeconstruct,
        token,
      },
    }
  }

  return { addPermissions }
}

export function usePermissionChecker() {
  const permissions = useSelector(state => state.appState.user.permissions)

  function existInPermission(permissionsToCheck) {
    if (Array.isArray(permissionsToCheck)) {
      return permissionsToCheck.some(permissionToCheck => permissions.includes(permissionToCheck))
    } else {
      if (permissions.includes(permissionsToCheck)) {
        // action is in user's permissions
        return true
      }
    }
    // no coincidence
    return false
  }

  function canDoThis(permissions, yes = () => {}, no = () => {}) {
    return existInPermission(permissions) ? yes() : no()
  }

  return { canDoThis }
}
export function useConfigWithAuth() {
  const authToken = useSelector(getToken)
  const configAuth = useMemo(() => configWithAuth(authToken), [authToken])

  return { configAuth }
}

/* export function useCheckSession() {
	const token = useSelector(getToken);
	const dispatch = useDispatch();

	useEffect(() => {
		async function check() {
			const res = await validateLogin(token);
			if (!res.data.status) {
				dispatch(logOut());
				window.location.replace("/");
			}
		}
		check();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
} */
