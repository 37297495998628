import React, { useEffect, useMemo, useState } from 'react'
import { Box, Spinner, Stack, useDisclosure } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createContactSupplier, deleteContactById, updateContactById } from '../../../../api/intranet/requests'
import { getContacts } from '../../../../redux/actions/suppliers-actions'
import { getAuthToken, getContactsState } from '../../../../redux/selectors'
import { Panel } from '../../../common/helpers'
import ModalSimple from '../../../common/modal-simple'
import Table from '../../../common/table'
import { ButtonDelete, ButtonEdit } from '../../../forms/buttons/edit'
import SupplierContactForm from '../../../forms/suppliers/supplier-contact-form'
import { useAsyncStateWithAction, useAsyncToastNotification, usePermission } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import AlertDialogSimple from '../../../common/alert-dialog-simple'
import { SUPPLIERS_EDIT } from '../../../../helpers/permissions'
import Pagination from '../../../common/pagination'

export default function SupplierContacts() {
  const { t } = useTranslation(['common', 'suppliers', 'forms'])
  const { id: supplierId } = useParams()
  const [dataEditing, setDataEditing] = useState(false)
  const authToken = useSelector(getAuthToken)
  const maxPerPage = 10
  const [page, setPage] = useState(1)
  const dispatchedGetContacts = dispatch => dispatch(getContacts(supplierId, maxPerPage, page))
  const { error, isLoading, items, update } = useAsyncStateWithAction(dispatchedGetContacts, getContactsState)

  const { asyncNotificationWrapper } = useAsyncToastNotification()
  const { addPermissions } = usePermission()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [deleteAlert, setDeleteAlert] = useState({
    show: false,
    data: null,
  })

  const onCloseDeleteAlert = () => setDeleteAlert(state => ({ ...state, show: false }))

  const columns = useMemo(() => {
    function onClickEdit(data) {
      setDataEditing(data)
      onOpen()
    }
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: t('forms:name'),
        accessor: 'nombre',
      },
      {
        Header: t('suppliers:company_section'),
        accessor: 'area',
      },
      {
        Header: t('suppliers:position'),
        accessor: 'cargo',
      },
      {
        Header: t('forms:phone'),
        accessor: 'telefono',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: t('common:actions'),
        Cell: ({ cell }) => (
          <Stack spacing={2} isInline>
            <ButtonEdit onClick={() => onClickEdit(cell.row.original)} />
            <ButtonDelete variantColor='red' onClick={() => prepareDelete(cell.row.original)} />
          </Stack>
        ),
      },
    ]
  }, [onOpen, t])

  function prepareDelete(data) {
    setDeleteAlert(state => ({
      ...state,
      show: true,
      data,
    }))
  }

  async function onSubmit(formData, e) {
    await asyncNotificationWrapper(async () => {
      const d = {
        ...formData,
        activo: true,
        proveedor: { id: parseInt(supplierId) },
      }

      const data = addPermissions(d, 'proveedores:editar')
      const res = await createContactSupplier(data, authToken)

      if (res.status) {
        e.target.reset()
        update()
      }

      return res.status
    })
  }

  async function onSubmitUpdate(formData, e) {
    await asyncNotificationWrapper(async () => {
      const d = {
        ...formData,
        activo: true,
        proveedor: { id: parseInt(supplierId) },
      }

      const data = addPermissions(d, SUPPLIERS_EDIT)

      const res = await updateContactById(data, dataEditing.id, authToken)

      if (res.status) {
        e.target.reset()
        update()
        onClose()
      }

      return res.status
    })
  }

  async function onDelete() {
    await asyncNotificationWrapper(async () => {
      onCloseDeleteAlert()
      const d = {
        ...deleteAlert.data,
        activo: false,
        proveedor: { id: parseInt(supplierId) },
      }

      const data = addPermissions(d, SUPPLIERS_EDIT)
      const res = await deleteContactById(data, deleteAlert.data.id, authToken)

      if (res.status) {
        update()
      }

      return res.status
    })
  }

  const deleteString = `${t('common:delete')} ${t('suppliers:contact').toLowerCase()}`

  useEffect(() => {
    update()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <Box>
      <AlertDialogSimple
        isOpen={deleteAlert.show}
        onClose={onCloseDeleteAlert}
        onAccept={onDelete}
        title={deleteString}
        acceptLabel={t('common:delete')}
        acceptVariantColor='red'
      >
        <p style={{ fontSize: '1.2rem' }}>{deleteAlert.data && `${deleteAlert.data.nombre}`}</p>
      </AlertDialogSimple>
      <ModalSimple title={t('common:edit')} size='800px' onClose={onClose} isOpen={isOpen}>
        <SupplierContactForm key='edit-form' formData={dataEditing} onSubmit={onSubmitUpdate} />
      </ModalSimple>
      <Stack spacing='20px'>
        <Panel>
          <SupplierContactForm key='create-form' onSubmit={onSubmit} />
        </Panel>
        {isLoading  && !items?.data?.length && (
          <Box px='5' py='10' textAlign='center'>
            <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' />
          </Box>
        )}
        {!isLoading && !error && items?.data?.length && <Table columns={columns} data={items?.data} />}
        {!isLoading && items?.totalPages && (
          <Pagination currentPage={page} setPage={setPage} totalPages={items?.totalPages} />
        )}
      </Stack>
    </Box>
  )
}
