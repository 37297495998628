import React from "react";
import PropTypes from "prop-types";
import { Link, ListItem } from "@chakra-ui/core";
import { Link as RouterLink } from "react-router-dom";

export const CommonLink = ({ children, ...restProps }) => (
	<Link as={RouterLink} {...restProps}>
		{children}
	</Link>
);

export const ListItemRoute = ({ children, to, linkProps, ...restProps }) => {
	const isActive = Array.isArray(to)
		? to.find(route => route === window.location.pathname)
		: to === window.location.pathname;
	return (
		<ListItem {...restProps}>
			<CommonLink
				to={to}
				color={isActive ? "blue.600" : "blue.600"}
				fontWeight={isActive ? "600" : "400"}
				{...linkProps}
			>
				{children}
			</CommonLink>
		</ListItem>
	);
};

ListItemRoute.propsTypes = {
	to: PropTypes.string.isRequired,
	linkProps: PropTypes.object,
};

CommonLink.propsTypes = {
	children: PropTypes.node.isRequired,
};
