export const toastError = {
	title: "Hubo un error, y no se pudo completar la acción",
	description: "Si persiste contactar con soporte",
	status: "error",
	duration: 2000,
	isClosable: true,
};

export const toastSuccess = {
	title: "Acción completada correctamente",
	status: "success",
	duration: 2300,
	isClosable: true,
};

export function toastCustomizedSuccess(msgTitle) {
	let data = {
		title: msgTitle,
		status: "success",
		duration: 2300,
		isClosable: true,
	};
	return data
};

export function toastCustomizedError(msgTitle, msgDescription, duration) {
	let data = {
		title: msgTitle,
		description: msgDescription,
		status: "error",
		duration: duration ? duration : 2300,
		isClosable: true,
	};
	return data
};

export function toastCustomized(msgTitle, msgDescription, status, duration) {
	let data = {
		title: msgTitle,
		description: msgDescription,
		status: status,
		duration: duration,
		isClosable: true,
	};
	return data
};

