import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Flex, FormControl, FormLabel, Input, Button, CircularProgress, Image, useToast } from '@chakra-ui/core'
import { login } from '../../../redux/actions/app-actions'
import ErrorMessage from '../../alert/error-message'
import { useTranslation } from 'react-i18next'
import BackgroundLogin from '../../../static/img/bg-login.jpg'
import BackgroundLoginArgPer from '../../../static/img/login_Intranet.jpg'
import { PageContainer } from '../../common/helpers'
import LogoGea from '../../../static/img/logo-gea.png'
import { forgotPassRequest, recoveryPassRequest } from '../../../api/application'
import { isSuccess } from '../../../helpers/utils'
import { toastCustomizedError } from '../../common/toast-default-config'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import InfoMessage from '../../info-success/info-message'
import SuccessMessage from '../../alert/success-message'
import '../../styles/common/common.css'

export default function Login() {
  const { t } = useTranslation(['login', 'forms'])
  const dispath = useDispatch()
  const [username, setUsername] = useState('')
  const [user, setUser] = useState(undefined)
  const [pass, setPass] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [recoveryInstance, setRecoveryInstance] = useState(false)
  const [forgotInstance, setForgotInstance] = useState(false)
  const [newPass, setNewPass] = useState(undefined)
  const [confirmPass, setConfirmPass] = useState(undefined)
  const toast = useToast()
  const [token, setToken] = useState(undefined)
  const [isHumanUser, setIsHumanUser] = useState(null)
  const [clientIp, setClientIp] = useState(null)
  const [userGeo, setUserGeo] = useState({
    country: '',
    lat: '',
    lng: '',
    loading: false
  })
  const [img, setImg ] = useState()
  const [showImage, setShowImage] = useState(false)
  const { REACT_APP_IPSTACK_ROUTE, REACT_APP_IPSTACK_API_KEY } = process.env

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    location.search && setToken(location.search.split('=')[1])
  }, [])

  useEffect(() => {
    if (token) {
      // eslint-disable-next-line no-restricted-globals
      if (location.pathname === '/login/change-password') {
        setRecoveryInstance(true)
      } else {
        setRecoveryInstance(false)
      }
    }
  }, [token])

  const handleSubmit = async event => {
    event.preventDefault()
    setIsLoading(true)
    if (isHumanUser) {
      try {
        await dispath(login({ user, pass }))
        setIsLoading(false)
      } catch (error) {
        setError(error.message)
        setIsLoading(false)
      }
    }
  }

  const toLogin = () => {
    setRecoveryInstance(false)
    setError(undefined)
    setTimeleft(undefined)
    // eslint-disable-next-line no-restricted-globals
    location.href = '/'
  }

  const [timeleft, setTimeleft] = useState(undefined)

  useEffect(() => {
    if (timeleft > 0) {
      setTimeout(() => setTimeleft(timeleft - 1), 1000)
    } else if (timeleft === 0) {
      toLogin()
    }
  }, [timeleft])

  const forgotPassword = async event => {
    event.preventDefault()
    setIsLoading(true)
    try {
      const forgotPassResponse = await forgotPassRequest(username)
      setIsLoading(false)
      if (isSuccess(forgotPassResponse.status)) {
        setError('')
        setSuccess(`${t('forms:emailSent')} ${t('forms:expiry2')}`)
      }
      setUsername('')
    } catch (error) {
      setError(t('forms:failedPasswordAction'))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
      setIsLoading(false)
      setUsername('')
    }
  }

  const handleRecovery = async event => {
    event.preventDefault()
    if (newPass && newPass === confirmPass) {
      setIsLoading(true)
      try {
        const recoveryPassResponse = await recoveryPassRequest({ token, pass: newPass })
        setIsLoading(false)
        if (isSuccess(recoveryPassResponse.status)) {
          setError('')
          setTimeleft(4)
        }
      } catch (error) {
        setError(t('forms:failedPasswordAction'))
        setNewPass(undefined)
        setConfirmPass(undefined)
        setIsLoading(false)
      }
    } else {
      setError(t('forms:passwordDontMatch'))
      setNewPass(undefined)
      setConfirmPass(undefined)
    }
  }
  const getClientPublicIp = async () =>  {
    const ipifyResponse = await fetch('https://api.ipify.org?format=text', {
      method: 'GET',
    })
    return await ipifyResponse.text()
  }

  useEffect(() => {
    const fetchClientIp = async () => {
      try {
        const ip = await getClientPublicIp()
        setClientIp(ip);
      } catch (error) {
        console.error('Error obteniendo la dirección IP del cliente:', error)
      }
    }
    fetchClientIp()
  }, [])
  
  useEffect(() => {
    const fetchData = async () => {
      if (clientIp) {
        try {
          const ipStackResponse = await fetch(
            `${REACT_APP_IPSTACK_ROUTE}/${clientIp}?access_key=${REACT_APP_IPSTACK_API_KEY}`
          )
          const ipStackData = await ipStackResponse.json()
          setUserGeo({
            country: ipStackData.country_name ? ipStackData.country_name : '',
            lat: ipStackData.latitude ? ipStackData.latitude : '',
            lng: ipStackData.longitude ? ipStackData.longitude : '',
            loading: true
          })
        } catch (error) {
          console.error('Error al obtener la información de la dirección IP:', error)
        }
      }
    }
    fetchData()
  }, [clientIp])

  useEffect(() => {
    if (userGeo.loading && clientIp) {
      userGeo.country.toLowerCase().includes("argentina") || userGeo.country.toLowerCase().includes("peru") ? setImg(BackgroundLoginArgPer) : setImg(BackgroundLogin)
      setShowImage(true)
    }
  }, [userGeo, clientIp])

  return (
    <Box height='full'>
      <Flex
        width='full'
        align='flex-start'
        justifyContent='center'
        pt='190px'
        height='full'
        backgroundImage={showImage ? `url('${img}')` : 'none'}
        backgroundSize='cover'
        backgroundPosition='center'
      >
        <Box p={8} maxWidth='500px' borderWidth={1} borderRadius={8} bg='white' boxShadow='lg'>
          <Box mt={4} mb={2} textAlign='left'>
            {recoveryInstance ? (
              <form onSubmit={handleRecovery}>
                {error && <ErrorMessage message={error} />}
                {timeleft && (
                  <InfoMessage
                    message={`¡Su contraseña ha sido creada con éxito!. Se redirigirá en ${timeleft} segundos.`}
                  />
                )}
                <FormControl isRequired mt={6}>
                  <FormLabel>{t('login:new_password')}</FormLabel>
                  <Input
                    type='password'
                    placeholder='*******'
                    size='lg'
                    name='new'
                    value={newPass}
                    onChange={event => setNewPass(event.target.value)}
                  />
                </FormControl>
                <FormControl isRequired mt={6}>
                  <FormLabel>{t('login:confirm_password')}</FormLabel>
                  <Input
                    type='password'
                    placeholder='*******'
                    size='lg'
                    name='confirm'
                    value={confirmPass}
                    onChange={event => setConfirmPass(event.target.value)}
                  />
                </FormControl>
                <Button
                  variantColor='blue'
                  variant='outline'
                  type='submit'
                  width='full'
                  mt={6}
                  isDisabled={isLoading || !(newPass && newPass === confirmPass)}
                >
                  {isLoading ? (
                    <CircularProgress isIndeterminate size='24px' color='blue' />
                  ) : (
                    t('login:change_password')
                  )}
                </Button>
              </form>
            ) : forgotInstance ? (
              <Box style={{ maxWidth: '300px' }}>
                {error && <ErrorMessage message={error} />}

                {success && (
                  <Box>
                    <SuccessMessage message={success} />
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        fontSize: '.9rem',
                        gap: '.6rem',
                        color: '#2f54b8',
                      }}
                    >
                      <p
                        onClick={e => {
                          setSuccess(false)
                          setError(false)
                          setUsername('')
                          setForgotInstance(true)
                        }}
                        className='pointer'
                      >
                        No me llegó el correo electronico. Reenviar.
                      </p>
                    </Box>
                  </Box>
                )}

                {!error && !success ? (
                  <Box style={{ display: 'flex', flexDirection: 'column', maxWidth: '300px', margin: 'auto' }}>
                    <p style={{ textAlign: 'center' }}>{t('forms:writeUser')}</p>
                    <FormLabel isRequired mt={6}>
                      {t('login:user')}
                    </FormLabel>
                    <Input
                      placeholder={t('login:user')}
                      size='lg'
                      name='username'
                      value={username}
                      onChange={event => setUsername(event.target.value)}
                    />
                    <Box style={{ display: 'flex', gap: '1rem' }} mt={6}>
                      <Button
                        onClick={e => forgotPassword(e)}
                        variantColor='blue'
                        variant='outline'
                        type='submit'
                        width='full'
                        isDisabled={!username}
                      >
                        Enviar
                      </Button>
                      <Button
                        onClick={() => {
                          setForgotInstance(false)
                          setUsername('')
                          setError('')
                          setSuccess('')
                        }}
                        variantColor='blue'
                        variant='outline'
                        width='full'
                      >
                        Cancelar
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  ''
                )}
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                {error && <ErrorMessage message={error} />}
                <FormControl isRequired>
                  <FormLabel>{t('login:user')}</FormLabel>
                  <Input
                    placeholder={t('login:user')}
                    size='lg'
                    name='user'
                    value={user}
                    onChange={event => setUser(event.target.value)}
                  />
                </FormControl>
                <FormControl isRequired mt={6}>
                  <FormLabel>{t('login:password')}</FormLabel>
                  <Input
                    type='password'
                    placeholder='*******'
                    size='lg'
                    name='pass'
                    value={pass}
                    onChange={event => setPass(event.target.value)}
                  />
                </FormControl>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    justifyContent: 'flex-end',
                    fontSize: '.9rem',
                    gap: '.6rem',
                    color: '#2f54b8',
                  }}
                >
                  <div>
                    <GoogleReCaptcha
                      onVerify={captchaToken => {
                        setIsHumanUser(!!captchaToken)
                      }}
                    />
                  </div>
                  <Button
                    variantColor='blue'
                    variant='outline'
                    type='submit'
                    width='full'
                    isDisabled={isLoading || !isHumanUser || !(pass && user)}
                    style={{ marginTop: '1rem' }}
                  >
                    {isLoading ? <CircularProgress isIndeterminate size='24px' color='blue' /> : t('login:login')}
                  </Button>

                  <p 
                    onClick={e => {
                      setError(undefined)
                      setForgotInstance(true)
                    }}
                    className='pointer'>
                    Olvidé mi contraseña
                  </p>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
