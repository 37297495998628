import React, { useEffect, useState } from 'react'
import { Box, Heading } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAuthToken, getUserCountryId } from '../../../redux/selectors'
import { getAppsGea } from '../../../api/application'
import ReactHtmlParser from 'react-html-parser'
import { PageContainer } from '../../common/helpers'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

export const GrupoGea = () => {
  const { t } = useTranslation(['common', 'forms', 'exchange', 'consultations', 'comunications'])
  const authToken = useSelector(getAuthToken)
  const countryId = useSelector(getUserCountryId)
  const [grupoGea, setGrupoGea] = useState([])

  async function getGrupoGea() {
    try {
      const res = await getAppsGea(authToken, { paisId: countryId, nombre: 'Grupo Gea' })
      if (res) setGrupoGea(res.data)
    } catch (error) {
      if (error.message) {
        console.log(error.message)
      }
    }
  }

  useEffect(() => {
    getGrupoGea()
  }, [])

  return (
    <Box py='10px' pb='0.5%' color='#103059'>
      <PageContainer>
        <Heading style={{ fontSize: '2rem', color: '#103059', marginBottom: '2rem' }}>
          {t('common:navbar.grupoGea')}
        </Heading>
        <Box style={{ backgroundColor: 'white', padding: '3rem', borderRadius: '10px' }}>
          <ReactQuill value={grupoGea.contenidoHtml} readOnly={true} theme={'bubble'} />
          {/* {ReactHtmlParser(grupoGea.contenidoHtml)} */}
        </Box>
      </PageContainer>
    </Box>
  )
}
