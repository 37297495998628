import { Box, Button, FormLabel, PseudoBox, Stack, Text, useToast } from '@chakra-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getAllCountries } from '../../../api/application'
import { iconInstance, getDefaultSectionById, getSectionTypesEndpoint, sectionInstance } from '../../../api/section'
import useFetch from '../../../api/useFetch'
import { getToken, getAuthToken, getUserCountryId } from '../../../redux/selectors'
import { useConfigWithAuth, useFormControls, usePermissionChecker } from '../../hooks'
import FormInput from '../form-input'
import { toastCustomizedError } from '../../common/toast-default-config'
import { DateRangePicker } from 'react-dates'
import moment from 'moment'
import { DEFAULT_SECTIONS_CREATE_COUTRY } from '../../../helpers/permissions'
import SelectAsync from '../../common/select-async'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

export default function DefaultSectionForm({ onSubmit, formData = false, buttonLabel = false, ...restProps }) {
  const { t } = useTranslation(['forms'])
  const token = useSelector(getToken)
  const authToken = useSelector(getAuthToken)
  const { canDoThis } = usePermissionChecker()
  const { configAuth } = useConfigWithAuth()
  const { isSubmitBlocked } = useFormControls()
  const [contentValue, setContentValue] = useState(undefined)
  const { register, handleSubmit } = useForm({
    defaultValues: formData,
  })
  const userCountryId = useSelector(getUserCountryId)
  const [{ startDate, endDate }, setRangePicker] = useState({
    startDate: moment(),
    endDate: null,
  })
  const [focusedInput, setFocusedPicker] = useState(null)
  const toast = useToast()
  const [countries, setCountries] = useState({
    data: [],
    isLoading: true,
    error: null,
  })
  const [countryValue, setCountryV] = useState()
  const [nameValue, setNameV] = useState()
  const [iconValue, setIconV] = useState()
  const [urlValue, setUrlV] = useState()
  const [selectedSectionType, setSelectedSectionType] = useState(undefined)

  const sectionTypesRequest = useFetch({
    api: sectionInstance,
    method: 'get',
    url: getSectionTypesEndpoint(),
    config: configAuth,
  })

  const getCountries = async () => {
    setCountries(state => ({
      ...state,
      isLoading: true,
    }))
    try {
      const response = await getAllCountries(token)
      setCountries(() => ({
        data: response.data,
        isLoading: false,
      }))
    } catch (error) {
      setCountries(state => ({
        ...state,
        error: error,
      }))
      toast(toastCustomizedError(t('forms:actionUncompleted'), t('forms:support')))
    }
  }

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const iconTypesRequest = useFetch({
    api: iconInstance,
    method: 'get',
    url: '/api/iconos',
    config: configAuth,
  })

  const countrySelectorConfig = useMemo(
    () =>
      canDoThis(
        DEFAULT_SECTIONS_CREATE_COUTRY,
        () => ({
          ishidden: false,
          defaultValue: 0,
        }),
        () => ({
          ishidden: true,
          defaultValue: userCountryId,
        }),
      ),
    [canDoThis, userCountryId],
  )

  useEffect(() => {
    if (startDate === null) {
      setRangePicker(state => ({
        ...state,
        startDate: moment(),
      }))
    }
  }, [startDate, endDate])

  function loadValuesInputs(miSection) {
    countrySelectorConfig.ishidden ? setCountryV(userCountryId) : setCountryV(miSection.paisId)
    setNameV(miSection.nombre)
    setRangePicker(state => ({
      ...state,
      startDate: moment(miSection.fechaInicio),
      endDate: moment(miSection.fechaFin),
    }))
    setIconV(miSection.icon?.id)
    setUrlV(miSection.url)
    setSelectedSectionType(miSection.tipoSeccion?.nombre)
    setContentValue(miSection.contenido)
  }

  //// Load section por id
  const [{ isLoading, section }, setSectionIdRequest] = useState({
    isLoading: true,
    section: {},
  })

  async function getSection(sectionId) {
    let miSection = {}

    setSectionIdRequest(state => ({
      ...state,
      isLoading: true,
    }))
    await getDefaultSectionById(sectionId, authToken)
      .then(res => {
        miSection = res.data
      })
      .catch(error => console.log('ErrorSectionById', error))
    setSectionIdRequest(state => ({
      ...state,
      isLoading: false,
      section: miSection,
    }))
    loadValuesInputs(miSection)
  }

  var toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    ['link', 'image', 'video'],

    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
  ]

  function onSubmitt(datos) {
    const tipoSeccionSeleccionada = sectionTypesRequest.response.find(section => section.nombre === selectedSectionType)
    let formData = {}
    if (!countrySelectorConfig.ishidden) {
      if (endDate === null) {
        formData = {
          paisId: Number(countryValue),
          nombre: datos.sectionName,
          descripcion: datos.sectionName,
          fechaInicio: startDate._d,
          fechaFin: (function () {
            if (endDate._d === null) {
              return ''
            } else {
              return endDate._d
            }
          })(),
          icon: { id: Number(datos.icon) },
          url: urlValue,
          activo: true,
          tipoSeccion: { id: tipoSeccionSeleccionada.id },
          contenido: contentValue,
        }
      } else {
        formData = {
          paisId: Number(countryValue),
          nombre: datos.sectionName,
          descripcion: datos.sectionName,
          fechaInicio: startDate._d,
          fechaFin: (function () {
            if (endDate._d === 'Invalid date') {
              return ''
            } else {
              return endDate._d
            }
          })(),
          icon: { id: Number(datos.icon) },
          url: urlValue,
          activo: true,
          tipoSeccion: { id: tipoSeccionSeleccionada.id },
          contenido: contentValue,
        }
      }
    } else {
      if (endDate === null) {
        formData = {
          paisId: userCountryId,
          nombre: datos.sectionName,
          descripcion: datos.sectionName,
          fechaInicio: startDate._d,
          // fecha_fin: null,
          fechaFin: (function () {
            if (endDate._d === null) {
              return ''
            } else {
              return endDate._d
            }
          })(),
          icon: { id: Number(datos.icon) },
          url: urlValue,
          activo: true,
          tipoSeccion: { id: tipoSeccionSeleccionada.id },
          contenido: contentValue,
        }
      } else {
        formData = {
          paisId: userCountryId,
          nombre: datos.sectionName,
          descripcion: datos.sectionName,
          fechaInicio: startDate._d,
          fechaFin: (function () {
            if (endDate._d === 'Invalid date') {
              return ''
            } else {
              return endDate._d
            }
          })(),
          icon: { id: Number(datos.icon) },
          url: urlValue,
          activo: true,
          tipoSeccion: { id: tipoSeccionSeleccionada.id },
          contenido: contentValue,
        }
      }
    }

    onSubmit(formData)
  }

  useEffect(() => {
    getSection(restProps.idSectionn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PseudoBox as='form' onSubmit={handleSubmit(d => onSubmitt(d))} {...restProps}>
      {!isLoading && section && (
        <Stack>
          {/* Pais */}
          <Box hidden={countrySelectorConfig.ishidden}>
            <FormLabel isRequired>{t('forms:country')}</FormLabel>
            <SelectAsync
              isRequired={!countrySelectorConfig.ishidden}
              onChange={e => setCountryV(e.target.value)}
              placeholder={`${t('forms:country')}`}
              name='country'
              value={countryValue}
              isLoading={countries.isLoading}
            >
              {countries.data
                .sort((a, b) => a.nombre.localeCompare(b.nombre))
                .map(({ id, nombre }) => (
                  <option key={id} value={id}>
                    {nombre}
                  </option>
                ))}
            </SelectAsync>
          </Box>
          <Box>
            <FormLabel htmlFor='sectionType' isRequired>
              {t('forms:typeSection')}
            </FormLabel>
            <SelectAsync
              isRequired
              placeholder={t('sections:typeSection')}
              name='sectionType'
              value={selectedSectionType}
              nativeProps={{ ref: register }}
              isLoading={sectionTypesRequest.isLoading}
              disabled={section.categoriaUbicacion ? true : false }
              onChange={e => {
                if (e.target.value === 'BANNER') {
                  setUrlV('')
                  setContentValue(undefined)
                }
                setSelectedSectionType(e.target.value)
              }}
            >
              {sectionTypesRequest.response &&
                sectionTypesRequest.response.map(type => (
                  <option key={type.id} value={type.nombre}>
                    {type.nombre}
                  </option>
                ))}
            </SelectAsync>
          </Box>
          {/* Nombre */}
          <FormInput
            isRequired
            maxLength='255'
            label={t('forms:name')}
            name='sectionName'
            value={nameValue}
            inputProps={{ placeholder: `${t('sections:name')}`, ref: register }}
            onChange={e => setNameV(e.target.value)}
          />
          {/* data picker */}
          <Box>
            <Box w='auto' mr='16px'>
              <Text>{t('forms:rangePicker')}</Text>
            </Box>
            <Box>
              <DateRangePicker
                id='dates'
                startDateId='fechaInicio'
                endDateId='fechaFin'
                focusedInput={focusedInput}
                startDate={startDate}
                endDate={endDate}
                onDatesChange={({ startDate, endDate }) => setRangePicker({ startDate: startDate, endDate: endDate })}
                onFocusChange={focusedInput => setFocusedPicker(focusedInput)}
                small
                displayFormat='DD/MM/YYYY'
              />
            </Box>
          </Box>
          {/* Icono */}
          <Box>
            <FormLabel htmlFor='categoria_id' isRequired>
              {t('forms:icon')}
            </FormLabel>
            <SelectAsync
              isRequired
              placeholder={t('sections:icon')}
              name='icon'
              value={iconValue}
              nativeProps={{ ref: register }}
              isLoading={iconTypesRequest.isLoading}
              onChange={e => {
                const newIconValue = e.target.value
                setContentValue(undefined)
                setIconV(newIconValue)
              }}
            >
              {iconTypesRequest.response &&
                iconTypesRequest.response.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.descripcion}
                  </option>
                ))}
            </SelectAsync>
          </Box>
          {/* URL */}
          {selectedSectionType === 'URL' && (
            <FormInput
              isRequired
              label={t('forms:url')}
              name='urlName'
              value={urlValue}
              inputProps={{
                placeholder: `${t('forms:urlPlaceholder')}`,
                ref: register,
              }}
              onChange={e => {
                setContentValue('')
                const newUrlValue = e.target.value
                setUrlV(newUrlValue)
              }}
            />
          )}

          {selectedSectionType === 'HTML' && contentValue !== undefined && (
            // editor html
            <Box>
              <FormLabel>HTML</FormLabel>
              <Box
                style={{
                  borderWidth: 0.5,
                  borderColor: '#e5e5e5',
                }}
              >
                <ReactQuill
                  style={{ display: 'flex', flexDirection: 'column', height: '460px', marginBottom: '30px' }}
                  className=''
                  modules={{ toolbar }}
                  value={contentValue}
                  onChange={(value, delta, source, editor) => {
                    setContentValue(value)
                    setUrlV('')
                  }}
                />
              </Box>
            </Box>
          )}

          <Button mt={6} variantColor='blue' variant='outline' type='submit' isLoading={isSubmitBlocked}>
            {t('forms:save')}
          </Button>
        </Stack>
      )}
    </PseudoBox>
  )
}
